import React from 'react'
import { ACTIONS } from '../../services/Reducers/PlanningReducer'
import { User } from 'iconoir-react'
import { SortRunsByGroupAndDepartureTime } from '../../tools/Sort'
import SortedGroupedRunsCard from '../App/Schedule/Sorted/Mobile/SortedGroupedRunsCard'
import PcSortedGroupedRunsCard from '../App/Schedule/Sorted/Pc/PcSortedGroupedRunsCard'
import SortedRunCard from '../App/Schedule/Sorted/Mobile/SortedRunCard'
import PcSortedRunCard from '../App/Schedule/Sorted/Pc/PcSortedRunCard'

const GlobalPlanningViewModal = ({
    planning,
    dispatch,
    showRunObjectColors,
}) => {
    const closeGlobalView = () => {
        dispatch({
            type: ACTIONS.SET_OPEN_GLOBAL_VIEW,
            payload: false,
        })
    }
    return (
        <div className="fullscreen-dashboard-planning fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50 px-2 md:px-6 lg:px-12">
            <div className="bg-color-light-gray min-h-action-modal max-height-90 flex min-w-full flex-col overflow-y-scroll rounded-lg pb-20">
                <div className="mb-10 flex w-full items-center border-b px-4 py-4 text-xl lg:px-8">
                    <div className="font-bold">Vue globale du planning</div>
                    <svg
                        className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 18"
                        onClick={closeGlobalView}
                    >
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                    </svg>
                </div>
                <div className="flex flex-col space-y-8">
                    {planning.teams.map((team) => (
                        <div>
                            <div className="mx-4 mb-6 flex flex-row items-center justify-between border-b px-2 pb-2">
                                <div className="flex items-center">
                                    <User />
                                    <span className="px-1 font-bold">
                                        {team.names}
                                    </span>
                                    <span>({team.vehicle})</span>
                                </div>
                                <div className="flex hidden items-center space-x-4 text-sm lg:block">
                                    <span>
                                        Premier transport : {team.firstRunHour}
                                    </span>
                                    <span>
                                        Dernier transport : {team.lastRunHour}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col space-y-4 px-4 lg:space-y-1">
                                {SortRunsByGroupAndDepartureTime(team.runs).map(
                                    (run) => {
                                        if (run.groupedRun) {
                                            return (
                                                <div>
                                                    <div className="block lg:hidden">
                                                        <SortedGroupedRunsCard
                                                            run={run}
                                                            onCardClicked={
                                                                false
                                                            }
                                                            onInfoClicked={
                                                                false
                                                            }
                                                            fromGlobalView={
                                                                true
                                                            }
                                                            showRunObjectColors={
                                                                showRunObjectColors
                                                            }
                                                        />
                                                    </div>
                                                    <div className="hidden lg:block">
                                                        <PcSortedGroupedRunsCard
                                                            run={run}
                                                            onCardClicked={
                                                                false
                                                            }
                                                            onInfoClicked={
                                                                false
                                                            }
                                                            fromGlobalView={
                                                                true
                                                            }
                                                            showRunObjectColors={
                                                                showRunObjectColors
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div>
                                                    <div className="block lg:hidden">
                                                        <SortedRunCard
                                                            run={run}
                                                            onCardClicked={
                                                                false
                                                            }
                                                            onInfoClicked={
                                                                false
                                                            }
                                                            fromGlobalView={
                                                                true
                                                            }
                                                            showRunObjectColors={
                                                                showRunObjectColors
                                                            }
                                                        />
                                                    </div>
                                                    <div className="hidden lg:block">
                                                        <PcSortedRunCard
                                                            run={run}
                                                            onCardClicked={
                                                                false
                                                            }
                                                            onInfoClicked={
                                                                false
                                                            }
                                                            fromGlobalView={
                                                                true
                                                            }
                                                            showRunObjectColors={
                                                                showRunObjectColors
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    }
                                )}
                            </div>
                        </div>
                    ))}
                    <div>
                        <div className="mx-4 mb-6 flex flex-row items-center justify-between border-b px-2 pb-2">
                            <div className="flex items-center">
                                <User />
                                <span className="px-1 font-bold">
                                    Transport non assignés
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col space-y-4 px-4 lg:space-y-1">
                            {planning.unassignedRuns.map((runs) =>
                                SortRunsByGroupAndDepartureTime(runs).map(
                                    (run) => {
                                        return (
                                            <div>
                                                <div className="block lg:hidden">
                                                    <SortedRunCard
                                                        run={run}
                                                        onCardClicked={false}
                                                        onInfoClicked={false}
                                                        fromGlobalView={true}
                                                        showRunObjectColors={
                                                            showRunObjectColors
                                                        }
                                                    />
                                                </div>
                                                <div className="hidden lg:block">
                                                    <PcSortedRunCard
                                                        run={run}
                                                        onCardClicked={false}
                                                        onInfoClicked={false}
                                                        fromGlobalView={true}
                                                        showRunObjectColors={
                                                            showRunObjectColors
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )
                                    }
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GlobalPlanningViewModal
