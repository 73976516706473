export const ERROR_ACTIONS = {
    SET_ERROR: 'SET_ERROR',
    CLEAR_ERROR: 'CLEAR_ERROR',
    CLEAR_ALL_ERRORS: 'CLEAR_ALL_ERRORS',
}

export const ErrorReducer = (errors, action) => {
    switch (action.type) {
        case ERROR_ACTIONS.SET_ERROR:
            return [...errors, newError(action.payload)]
        case ERROR_ACTIONS.CLEAR_ERROR:
            return errors.filter((error) => error.id !== action.payload.id)
        case ERROR_ACTIONS.CLEAR_ALL_ERRORS:
            return []
        default:
            return errors
    }
}

function newError(payload) {
    return {
        id: payload.id,
        message: payload.message,
    }
}
