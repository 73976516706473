import React from 'react'
import { getTextAndBackgroundColor, RunStatus } from '../../services/RunStatus'

export const LegendModal = ({ setShowLegendModal }) => (
    <div className="flex items-center justify-center fixed left-0 bottom-0 px-2 md:px-6 pb-20 w-full h-full bg-gray-500 bg-opacity-50 text-gray-700 modal">
        <div
            className={`flex flex-col bg-white rounded-lg justify-between max-w-2xl space-y-4 p-2 px-4 w-full`}
        >
            <div className={'flex items-center border-b text-xl py-4'}>
                <div className="font-bold">Légende</div>
                <svg
                    className="ml-auto fill-current text-gray-700 w-6 h-6 cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    onClick={() => setShowLegendModal(false)}
                >
                    <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                </svg>
            </div>
            <div className="flex flex-col space-y-12 p-2">
                {RunStatus.map((status) => (
                    <div className="flex w-full items-center space-x-8 ">
                        <div>
                            <p
                                className={`flex px-2 inline-flex max-w-full justify-center
                 leading-5 font-semibold rounded-full text-base w-24 ${getTextAndBackgroundColor(
                     status.value
                 )}`}
                            >
                                {status.label}
                            </p>
                        </div>
                        <div>{status.description}</div>
                    </div>
                ))}
                <div className="flex w-full items-center space-x-8">
                    <div>
                        <i
                            className="h-5 flex px-2 inline-flex max-w-full justify-center
                 leading-5 font-semibold rounded-full text-base w-24 stripeBgMin  "
                        />
                    </div>
                    <div>
                        Bandes grises correspondant a un transport partagé a un
                        partenaire
                    </div>
                </div>
            </div>
        </div>
    </div>
)
