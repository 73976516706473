import StatCard from './StatCard'
import { ReactComponent as Cpam } from '../../../../files/cpam.svg'
import { Bank, Community, Euro, Healthcare } from 'iconoir-react'
import { formatEuro } from '../Manage'

export function calculateTotalCPAM(lotData) {
    return lotData.cpamInvoices.reduce((acc, lot) => {
        return acc + (lot.payingCenterAmount || 0)
    }, 0)
}

export function calculateTotalComplementaire(lotData) {
    return lotData.cpamInvoices.reduce((acc, lot) => {
        return acc + (lot.healthComplementaryAmount || 0)
    }, 0)
}

function calculateTotal100Percent(lotData) {
    return lotData.cpamInvoices.reduce((acc, invoice) => {
        return acc + (invoice.prescription?.rate === '100' ? 1 : 0)
    }, 0)
}

const LotStats = ({ lotData }) => {
    const totalCPAM = calculateTotalCPAM(lotData) // Votre logique ici
    const totalComplementaire = calculateTotalComplementaire(lotData)
    const total100Percent = calculateTotal100Percent(lotData)
    const totalNot100Percent = lotData.cpamInvoices.length - total100Percent
    const totalGeneral = totalCPAM + totalComplementaire

    return (
        <div className="grid gap-4 sm:grid-cols-3">
            <StatCard
                title="CPAM"
                value={formatEuro(totalCPAM)}
                subtitle={`${total100Percent} facture(s) en 100%`}
                icon={<Cpam height={36} width={36} />}
            />
            <StatCard
                title="AMC/Assuré"
                value={formatEuro(totalComplementaire)}
                subtitle={`${totalNot100Percent} facture(s) en 55%`}
                icon={<Healthcare height={36} width={36} />}
            />
            <StatCard
                title="Montant général"
                value={formatEuro(totalGeneral)}
                subtitle={`Pour un total de ${lotData.cpamInvoices.length} facture(s)`}
                icon={<Bank height={36} width={36} />}
            />
        </div>
    )
}

export default LotStats
