import { concat } from '../pathsAndFilters'
import { formatHydraData } from '../../api'
import { apiClient } from '../apiClient'

const PATH_KEY = '/series'

export const Series = {
    fetchAll: async (search, archived) => {
        let path = '/get_series'
        if (search) path = concat(path, 'search=' + search)
        if (archived) path = concat(path, 'archived=' + archived)
        return formatHydraData(await apiClient.get(path))
    },
}
