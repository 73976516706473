export const SOCIETY_ACTIONS = {
    UPDATE_ADDRESS_LABEL: 'updateAddressLabel',
    SET_ADDRESS: 'setAddress',
    SET_LABEL: 'setLabel',
    SET_PHONE_NUMBER: 'setPhoneNumber',
    SET_MAIL: 'setMail',
    SET_SIRET: 'setSiret',
    SET_VAT_NUMBER: 'setVATNumber',
    GET_SOCIETY: 'getSociety',
    DELETE_ADDRESS: 'deleteAddress',
    SET_ACCOUNT_HOLDER_NAME: 'setAccountHolderName',
    SET_IBAN: 'setIban',
    SET_BIC: 'setBic',
    SET_INVOICE_NOTE: 'setInvoiceNote',
    SET_ID_EMETTEUR: 'setIdEmetteur',
    SET_MDP_EMETTEUR: 'setMdpEmetteur',
}

export const getInitialState = () => {
    return {
        id: null,
        label: null,
        address: null,
        addressLabel: null,
        phoneNumber: null,
        siret: null,
        mail: null,
        plan: null,
        VATNumber: null,
        IBAN: null,
        BIC: null,
        accountHolderName: null,
        invoiceNote: null,
        idEmetteur: null,
        mdpEmetteur: null,
    }
}

export const SocietyReducer = (state, action) => {
    switch (action.type) {
        case SOCIETY_ACTIONS.GET_SOCIETY:
            return {
                ...state,
                label: action.payload.label,
                address: action.payload?.address,
                addressLabel: action.payload?.address?.googlePrediction,
                phoneNumber: action.payload.phoneNumber
                    ?.replace(/[^\dA-Z]/g, '')
                    .replace(/(.{2})/g, '$1 ')
                    .substring(0, 15)
                    .trim(),
                siret: action.payload.siret,
                mail: action.payload.mail,
                id: action.payload.id,
                plan: action.payload.plan,
                VATNumber: action.payload.VATNumber,
                IBAN: action.payload.IBAN,
                BIC: action.payload.BIC,
                accountHolderName: action.payload.accountHolderName,
                invoiceNote: action.payload.invoiceNote,
                idEmetteur: action.payload.idEmetteur,
                mdpEmetteur: action.payload.mdpEmetteur,
            }
        case SOCIETY_ACTIONS.SET_ADDRESS:
            return {
                ...state,
                address: action.payload,
                addressLabel: action.payload.googlePrediction,
            }
        case SOCIETY_ACTIONS.UPDATE_ADDRESS_LABEL:
            return {
                ...state,
                addressLabel: {
                    ...state.addressLabel,
                    value: {
                        ...state.addressLabel?.value,
                        description: action.payload.description,
                    },
                },
            }
        case SOCIETY_ACTIONS.SET_LABEL:
            return {
                ...state,
                label: action.payload,
            }
        case SOCIETY_ACTIONS.SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload
                    ?.replace(/[^\dA-Z]/g, '')
                    .replace(/(.{2})/g, '$1 ')
                    .substring(0, 15)
                    .trim(),
            }
        case SOCIETY_ACTIONS.SET_MAIL:
            return {
                ...state,
                mail: action.payload,
            }
        case SOCIETY_ACTIONS.SET_VAT_NUMBER:
            return {
                ...state,
                VATNumber: action.payload.substring(0, 13).trim(),
            }
        case SOCIETY_ACTIONS.SET_SIRET:
            return {
                ...state,
                siret: action.payload.replace(/\D/g, '').substring(0, 14),
            }
        case SOCIETY_ACTIONS.DELETE_ADDRESS:
            return {
                ...state,
                addressLabel: {
                    ...state.addressLabel,
                    value: {
                        description: '',
                    },
                },
            }
        case SOCIETY_ACTIONS.SET_ACCOUNT_HOLDER_NAME:
            return {
                ...state,
                accountHolderName: action.payload,
            }
        case SOCIETY_ACTIONS.SET_IBAN:
            return {
                ...state,
                IBAN: action.payload,
            }
        case SOCIETY_ACTIONS.SET_BIC:
            return {
                ...state,
                BIC: action.payload,
            }
        case SOCIETY_ACTIONS.SET_INVOICE_NOTE:
            return {
                ...state,
                invoiceNote:
                    action.payload.split(/\n/).length <= 6 &&
                    action.payload.length <= 400
                        ? action.payload
                        : state.invoiceNote,
            }
        case SOCIETY_ACTIONS.SET_ID_EMETTEUR:
            return {
                ...state,
                idEmetteur: action.payload,
            }
        case SOCIETY_ACTIONS.SET_MDP_EMETTEUR:
            return {
                ...state,
                mdpEmetteur: action.payload,
            }
        default:
            throw new Error('Action non reconnue')
    }
}
