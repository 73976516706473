import React from 'react'
import dayjs from 'dayjs'
import { formatDateLocal } from '../../../../services/dateUtils'

const ObjectAndDateFirstFormat = ({ row }) => {
    return (
        <div className="w-40 max-w-40 truncate px-6">
            <div className="flex justify-between truncate">
                <div className="flex flex-col truncate">
                    <div className="truncate text-sm font-semibold">
                        {row.runObject.label}
                    </div>
                    <div>{formatDateLocal(row.date)}</div>
                </div>
            </div>
        </div>
    )
}

export default ObjectAndDateFirstFormat
