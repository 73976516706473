import React, { useEffect, useState } from 'react'
import API from '../../../services/api'
import NoData from '../NoData'
import Loader from '../../Commons/Loaders/Loader'
import UserCardTable from './UserCardTable'
import { toast } from 'react-toastify'

const User = ({ search }) => {
    const [rows, setRows] = useState([])
    const [totalItems, setTotalItems] = useState(1)
    const [firstLoading, setFirstLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const formattedData = (data) => {
        return data.map((user) => {
            const roles = user.roles.toString()
            let roleLabels = roles.includes('ADMIN')
                ? 'Administrateur'
                : 'Chauffeur'
            return {
                ...user,
                fullname: user.firstname + ' ' + user.lastname,
                roleLabels: roleLabels,
            }
        })
    }

    function fetchUsersList() {
        setLoading(true)
        API.Users.list(search, '', '', null, null, true).then((response) => {
            response.json().then((data) => {
                setRows(formattedData(data['hydra:member']))
                setTotalItems(data['hydra:totalItems'])
                setFirstLoading(false)
                setLoading(false)
            })
        })
    }

    useEffect(() => {
        fetchUsersList()
    }, [search])

    const handleDeleteUser = (id) => {
        API.Users.delete(id)
            .then((response) => {
                toast.success('Utilisateur supprimé.')
                fetchUsersList()
            })
            .catch((error) => {
                toast.warn(
                    "Impossible de supprimer l'utilisateur car il a été utilisé."
                )
            })
    }

    return (
        <div>
            {firstLoading ? (
                <Loader />
            ) : rows.length > 0 ? (
                <UserCardTable
                    rows={rows}
                    totalItems={totalItems}
                    handleDeleteElement={handleDeleteUser}
                    loading={loading}
                    itemName="Utilisateurs"
                />
            ) : (
                <NoData message="Aucun utilisateur renseigné" />
            )}
        </div>
    )
}

export default User
