import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SelectDropDownList from '../../../Commons/DropDownLists/SelectDropDownList'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'

const SubscriptionData = ({
    society,
    freeTryDaysLeft,
    handleSmsPlan,
    nbSmsOptions,
    nbSmsValue,
    setNbSmsValue,
}) => {
    let history = useNavigate()
    const [showSharing, setShowSharing] = useState(false)

    return (
        <div>
            {society.plan.id === 'free' ? (
                <div>
                    <h1 className="pb-4 text-xl font-bold text-light-dark-green">
                        Essai gratuit de 14 jours
                    </h1>
                    <div>
                        {freeTryDaysLeft <= 0
                            ? 'Votre essai gratuit est terminé'
                            : `Jours restants : ${freeTryDaysLeft}`}
                    </div>
                    <div className="pt-4">
                        <PrimaryButton
                            label="Mettre à jour mon plan"
                            title="Mettre à jour mon plan"
                            action={() =>
                                history('/parametres/abonnement/plans')
                            }
                        />
                    </div>
                </div>
            ) : (
                <div>
                    <h1 className="pb-4 text-xl font-bold text-light-dark-green">
                        Abonnement {society.plan.label}
                    </h1>
                    {society.plan.id === 'unlimited' ? (
                        <div>Plus d'informations à venir...</div>
                    ) : (
                        <div>
                            <div className="pb-2">
                                Vous disposez de{' '}
                                <span className="font-bold">
                                    {society.plan.nbOfferedSms}
                                </span>{' '}
                                partages par sms par mois compris dans votre
                                abonnement. Il vous en reste{' '}
                                {society.nbOfferedSms}.
                            </div>
                            {society.nbPaidSms > 0 ? (
                                <div>
                                    <div>
                                        Il vous reste {society.nbPaidSms} sms
                                        que vous avez ajouté manuellement.
                                    </div>
                                    <span className="italic">
                                        Ces Sms ne sont pas réinitialisés chaque
                                        mois.
                                    </span>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div className="pt-4">
                                {!showSharing ? (
                                    <PrimaryButton
                                        label="Ajouter des crédits"
                                        title="Ajouter des crédits"
                                        action={() => setShowSharing(true)}
                                    />
                                ) : (
                                    <div className="flex flex-col">
                                        <div className="mr-auto pb-2">
                                            <SelectDropDownList
                                                icon=""
                                                label="Nombre de partage par sms à ajouter"
                                                value={nbSmsValue}
                                                options={nbSmsOptions}
                                                handleOptionChange={(value) =>
                                                    setNbSmsValue(value)
                                                }
                                                isSearchable={false}
                                                isClearable={false}
                                                isDisabled={false}
                                                isMulti={false}
                                                showLabel={true}
                                            />
                                        </div>
                                        <div className="mr-auto">
                                            <PrimaryButton
                                                label="Ajouter des crédits"
                                                title="Ajouter des crédits"
                                                action={handleSmsPlan}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default SubscriptionData
