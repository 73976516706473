import VehicleColors from '../VehicleColors'
import UserRoles from '../UserRoles'
import VehicleTypes from '../VehicleTypes'

export const ACTIONS = {
    SET_PARAMETERS: 'setParameters',
    SET_STEPS: 'setSteps',
    SET_STEP_TO_SHOW: 'setStepToShow',
    SET_PARAMETERS_USERS_SHOW_ALL_RUNS: 'setParametersUsersShowAllRuns',
    SET_PARAMETERS_USERS_SHOW_UNASSIGNED_RUNS:
        'setParametersUsersShowUnassignedRuns',
    SET_SELECTED_VEHICLE_TYPES: 'setSelectedVehicleTypes',
    SET_SELECTED_VEHICLE_TYPE: 'setSelectedVehicleType',
    SET_VEHICLE_LICENSE_PLATE: 'setVehicleLicensePlate',
    SET_VEHICLE_LABEL: 'setVehicleLabel',
    SET_VEHICLE_TYPE: 'setVehicleType',
    SET_VEHICLE_COLOR: 'setVehicleColor',
    SET_VEHICLE_ID: 'setVehicleId',
    SET_USER_FIRSTNAME: 'setUserFirstname',
    SET_USER_LASTNAME: 'setUserLastname',
    SET_USER_PHONE: 'setUserPhone',
    SET_USER_EMAIL: 'setUserEmail',
    SET_USER_ROLES: 'setUserRoles',
    SET_USER_VEHICLE: 'setUserVehicle',
    SET_USER_ID: 'setUserId',
    SET_DATE: 'setDate',
    SET_TEAM_VEHICLE: 'setTeamVehicle',
    SET_TEAM_USER: 'setTeamUser',
    SET_TEAM_ID: 'setTeamId',
    SET_LOADING: 'setLoading',
}

export const getInitialState = ({}) => {
    return {
        loading: false,
        date: new Date(),
        parameters: {},
        vehicle: {
            id: null,
            '@id': null,
            licensePlate: null,
            label: null,
            color: VehicleColors[2],
            actif: true,
            type: VehicleTypes[0],
        },
        user: {
            id: null,
            '@id': null,
            firstname: '',
            lastname: '',
            phone: null,
            email: '',
            selectedRoles: UserRoles.filter(
                (role) => role.value === 'ROLE_USER'
            ),
            selectedVehicle: null,
        },
        team: {
            id: null,
            '@id': null,
            selectedVehicle: null,
            selectedUsers: [],
            date: new Date(),
        },
        vehicleOptions: [],
        userOptions: [],
        steps: [
            {
                id: 1,
                activeLine: true,
                activeRound: true,
                show: true,
                displayed: true,
            },
            {
                id: 2,
                show: false,
                displayed: false,
            },
            {
                id: 3,
                show: false,
                displayed: true,
            },
            {
                id: 4,
                show: false,
                displayed: false,
            },
            {
                id: 5,
                show: false,
                displayed: true,
            },
            {
                id: 6,
                show: false,
                displayed: false,
            },
            {
                id: 7,
                show: false,
                displayed: true,
            },
            {
                id: 8,
                show: false,
                displayed: false,
            },
            {
                id: 9,
                show: false,
                displayed: true,
            },
            {
                id: 10,
                show: false,
                displayed: true,
            },
        ],
        stepToShow: 1,
    }
}

export const WelcomeModalReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_PARAMETERS:
            return {
                ...state,
                parameters: action.payload,
            }
        case ACTIONS.SET_PARAMETERS_USERS_SHOW_ALL_RUNS:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    usersShowAllRuns: action.payload,
                    usersShowUnassignedRuns: action.payload
                        ? false
                        : state.usersShowUnassignedRuns,
                },
            }
        case ACTIONS.SET_PARAMETERS_USERS_SHOW_UNASSIGNED_RUNS:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    usersShowUnassignedRuns: action.payload,
                    usersShowAllRuns: action.payload
                        ? false
                        : state.usersShowAllRuns,
                },
            }

        case ACTIONS.SET_SELECTED_VEHICLE_TYPES:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    vehicleOptions: action.payload.vehicleOptions,
                    defaultVehiclesTypes: action.payload.defaultVehiclesTypes,
                    defaultVehicleType: action.payload.defaultVehicleType,
                },
                vehicle: {
                    ...state.vehicle,
                    type: action.payload.defaultVehicleType,
                },
            }
        case ACTIONS.SET_SELECTED_VEHICLE_TYPE:
            return {
                ...state,
                parameters: {
                    ...state.parameters,
                    defaultVehicleType: action.payload,
                },
                vehicle: {
                    ...state.vehicle,
                    type: action.payload,
                },
            }
        case ACTIONS.SET_STEPS:
            return {
                ...state,
                steps: action.payload,
            }
        case ACTIONS.SET_STEP_TO_SHOW:
            return {
                ...state,
                stepToShow: action.payload,
            }
        case ACTIONS.SET_VEHICLE_LICENSE_PLATE:
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    licensePlate: action.payload,
                },
            }
        case ACTIONS.SET_VEHICLE_LABEL:
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    label: action.payload,
                },
            }
        case ACTIONS.SET_VEHICLE_TYPE:
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    type: action.payload,
                },
            }
        case ACTIONS.SET_VEHICLE_COLOR:
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    color: action.payload,
                },
            }
        case ACTIONS.SET_VEHICLE_ID:
            return {
                ...state,
                vehicle: {
                    ...state.vehicle,
                    id: action.payload.id,
                    '@id': action.payload['@id'],
                },
                vehicleOptions: [
                    {
                        value: action.payload['@id'],
                        label: action.payload.label
                            ? action.payload.label
                            : action.payload.licensePlate,
                    },
                ],
                user: {
                    ...state.user,
                    selectedVehicle: {
                        value: action.payload['@id'],
                        label: action.payload.label
                            ? action.payload.label
                            : action.payload.licensePlate,
                    },
                },
                team: {
                    ...state.team,
                    selectedVehicle: {
                        value: action.payload['@id'],
                        label: action.payload.label
                            ? action.payload.label
                            : action.payload.licensePlate,
                    },
                },
            }
        case ACTIONS.SET_USER_FIRSTNAME:
            return {
                ...state,
                user: {
                    ...state.user,
                    firstname: action.payload,
                },
            }
        case ACTIONS.SET_USER_LASTNAME:
            return {
                ...state,
                user: {
                    ...state.user,
                    lastname: action.payload,
                },
            }
        case ACTIONS.SET_USER_PHONE:
            return {
                ...state,
                user: {
                    ...state.user,
                    phone: action.payload,
                },
            }
        case ACTIONS.SET_USER_EMAIL:
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.payload,
                },
            }
        case ACTIONS.SET_USER_ROLES:
            return {
                ...state,
                user: {
                    ...state.user,
                    selectedRoles: action.payload,
                },
            }
        case ACTIONS.SET_USER_VEHICLE:
            return {
                ...state,
                user: {
                    ...state.user,
                    selectedVehicle: action.payload,
                },
            }
        case ACTIONS.SET_USER_ID:
            return {
                ...state,
                user: {
                    ...state.user,
                    id: action.payload.id,
                    '@id': action.payload['@id'],
                },
                userOptions: [
                    {
                        value: action.payload['@id'],
                        label: `${action.payload.lastname} ${action.payload.firstname}`,
                    },
                ],
                team: {
                    ...state.team,
                    user: {
                        value: action.payload['@id'],
                        label: `${action.payload.lastname} ${action.payload.firstname}`,
                    },
                },
            }
        case ACTIONS.SET_TEAM_VEHICLE:
            return {
                ...state,
                team: {
                    ...state.team,
                    selectedVehicle: action.payload,
                },
            }
        case ACTIONS.SET_TEAM_USER:
            return {
                ...state,
                team: {
                    ...state.team,
                    selectedUsers: [action.payload],
                },
            }
        case ACTIONS.SET_TEAM_ID:
            return {
                ...state,
                team: {
                    ...state.team,
                    id: action.payload.id,
                    '@id': action.payload['@id'],
                },
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        default:
            throw new Error('Action non reconnue')
    }
}
