import React from 'react'
import ClassicInput from './Inputs/ClassicInput'
import { Contactless, Home, Megaphone, Plus, Trash } from 'iconoir-react'
import PrimaryButton from './Buttons/PrimaryButton'

const PatientNotifications = ({ patient, dispatch, PATIENT_ACTIONS }) => {
    return (
        <div className="col-span-2 pb-4">
            <div className="flex w-full justify-between border-b-2">
                <div className="flex items-center pb-5 text-xl font-bold">
                    <h2 className={'mr-2'}>Contacts enregistrées</h2>
                    <Megaphone />
                </div>
                <PrimaryButton
                    label="Contact"
                    title="Contact"
                    action={() => {
                        dispatch({
                            type: PATIENT_ACTIONS.ADD_CONTACT,
                        })
                    }}
                    icon={<Plus className="text-xl" />}
                />
            </div>
            {patient.patientsContacts?.length > 0 ? (
                <div className="flex w-full flex-col">
                    {patient.patientsContacts.map((contact, index) => (
                        <div key={index} className="flex w-full flex-col py-4">
                            <div className="flex w-full flex-col space-y-2">
                                <div className="flex w-full  flex-col justify-between space-y-4 lg:flex-row lg:items-center lg:space-y-0">
                                    <ClassicInput
                                        value={contact.label}
                                        label={`Contact ${index + 1} `}
                                        placeholder="Mr. Dupont.."
                                        className="w-full lg:w-auto"
                                        showLabel={true}
                                        setValue={(e) =>
                                            dispatch({
                                                type: PATIENT_ACTIONS.UPDATE_CONTACT_INFO,
                                                payload: {
                                                    ...contact,
                                                    label: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                    <ClassicInput
                                        value={contact.phoneNumber}
                                        label={`Téléphone`}
                                        placeholder="Numéro de contact"
                                        type="tel"
                                        className="w-full lg:w-auto"
                                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                        required
                                        showLabel={true}
                                        setValue={(e) =>
                                            dispatch({
                                                type: PATIENT_ACTIONS.UPDATE_CONTACT_INFO,
                                                payload: {
                                                    ...contact,
                                                    phoneNumber: e.target.value,
                                                },
                                            })
                                        }
                                    />
                                    <i
                                        onClick={() => {
                                            dispatch({
                                                type: PATIENT_ACTIONS.REMOVE_CONTACT,
                                                payload: contact['@id'],
                                            })
                                        }}
                                        className={`fas fa-minus-circle hidden cursor-pointer pt-5 text-red-600 lg:block`}
                                    />
                                    <button
                                        className={`block lg:hidden ${
                                            contact.id === ' Invoice_Line_1 ' &&
                                            ' invisible'
                                        } `}
                                        onClick={() => {
                                            dispatch({
                                                type: PATIENT_ACTIONS.REMOVE_CONTACT,
                                                payload: contact['@id'],
                                            })
                                        }}
                                    >
                                        <div className=" rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700">
                                            Supprimer
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="flex w-full italic">
                    Ce {patient.isPatient ? 'patient ' : 'client '}
                    n'a pas encore de contact paramétré
                </p>
            )}
        </div>
    )
}

export default PatientNotifications
