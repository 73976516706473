import { apiClient, withErrorHandling } from '../apiClient'
import { formatHydraData } from '../../api'

const PATH_KEY = '/users'

export const User = {
    fetchAll: async () => {
        return formatHydraData(await apiClient.get(PATH_KEY))
    },
    fetchOne: async (id) => {
        return formatHydraData(await apiClient.get(`${PATH_KEY}/${id}`))
    },
    create: withErrorHandling(async (user) => {
        return await apiClient.post(PATH_KEY, user)
    }),
    update: withErrorHandling(async (user) => {
        return await apiClient.put(`${PATH_KEY}/${user.id}`, user)
    }),
    delete: withErrorHandling(async (id) => {
        return await apiClient.delete(`${PATH_KEY}/${id}`)
    }),
}
