import React from 'react'

const DepartureOrder = ({ row }) => {
    return (
        <div className="w-12 px-6">
            <div className="flex items-center align-middle font-bold">
                {row.departureOrder}
            </div>
        </div>
    )
}

export default DepartureOrder
