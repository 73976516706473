import ClassicInput from '../../../Commons/Inputs/ClassicInput'
import React, { useEffect, useReducer, useRef } from 'react'
import {
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../../services/Reducers/AddressAutoCompleteReducer'
import { HEALTH_COMPLEMENTARY_ACTIONS } from '../Reducers/HealthComplementaryFormReducer'
import AutoComplete from '../../AutoComplete'

const HealthComplementaryForm = ({
    healthComplementary,
    dispatch,
    addressItem = null,
}) => {
    console.log(addressItem)
    const addressRef = useRef(null)
    const [address, addressDispatch] = useReducer(
        AddressAutoCompleteReducer,
        getAddressInitialState({ addressItem })
    )

    useEffect(() => {
        dispatch({
            type: HEALTH_COMPLEMENTARY_ACTIONS.SET_ADDRESS,
            payload: address,
        })
    }, [address])

    return (
        <div className={'grid w-full grid-cols-2 gap-8 px-5 py-10'}>
            <ClassicInput
                label="Nom"
                className="w-full"
                value={healthComplementary.label}
                setValue={(e) =>
                    dispatch({
                        type: HEALTH_COMPLEMENTARY_ACTIONS.SET_LABEL,
                        payload: e.target.value,
                    })
                }
                id="complementaryHealthName"
                placeholder="Nom"
                showLabel={true}
            />
            <ClassicInput
                label="Numéro AMC"
                className="w-full"
                value={healthComplementary.numId}
                setValue={(e) =>
                    dispatch({
                        type: HEALTH_COMPLEMENTARY_ACTIONS.SET_NUM_ID,
                        payload: e.target.value,
                    })
                }
                id="complementaryHealthNumber"
                placeholder="Numéro"
                showLabel={true}
            />
            <div className={'col-span-2 w-full pb-5'}>
                <AutoComplete
                    dispatch={addressDispatch}
                    address={address}
                    label="Adresse"
                    ref={addressRef}
                />
            </div>
        </div>
    )
}

export default HealthComplementaryForm
