import React, { useState } from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import SelectDateRangePicker from '../../Commons/SelectDateRangePicker'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import { toast } from 'react-toastify'
import { Plus } from 'iconoir-react'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'

const InvoiceFilter = ({
    search,
    setSearch,
    archived,
    setArchived,
    setOpenFilterModal,
    isQuote,
}) => {
    const navigate = useNavigate()
    const checkIfAbleToInvoice = () => {
        if (localStorage.getItem('isAbleToInvoice') === 'true') {
            navigate(`/facturation/${isQuote ? 'devis' : 'factures'}/0`)
        } else {
            toast.info(
                "Veuillez d'abord renseigner dans vos paramètres de société toutes les informations nécessaires pour pouvoir facturer."
            )
        }
    }
    return (
        <div className="grid grid-cols-2 items-center justify-between gap-4 px-2 pb-2 pt-2 lg:grid-cols-4 ">
            <div className="col-span-1 flex items-center justify-center">
                <i className="fa fa-search pr-2" />
                <ClassicInput
                    label="Rechercher"
                    value={search}
                    setValue={(e) => {
                        setSearch(e.target.value)
                    }}
                    id="search"
                    placeholder="Rechercher..."
                />
            </div>
            <div className="col-span-1 flex justify-center">
                <ClassicSwitch
                    label="Archives"
                    value={archived}
                    setValue={() => {
                        setArchived(!archived)
                    }}
                    info={true}
                    infoLabel={'Afficher la liste des factures archivées'}
                />
            </div>
            <div className="col-span-1 col-start-2 flex justify-center lg:col-start-auto lg:justify-end ">
                <PrimaryButton
                    label={`${isQuote ? 'Nouveau devis' : 'Nouvelle facture'}`}
                    title={`${isQuote ? 'Nouveau devis' : 'Nouvelle facture'}`}
                    action={() => checkIfAbleToInvoice()}
                    icon={<Plus className="text-xl" />}
                    hiddenLabelOnMobile={true}
                />
            </div>
        </div>
    )
}

export default InvoiceFilter
