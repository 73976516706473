import { InvoiceActions, InvoiceStatusDisplay } from './InvoiceHeaderPart'
import { checkAbleToInvoice } from '../../../../../services/InvoiceUtils'
import { Printer, User } from 'iconoir-react'
import CalendarDatePicker from '../../../../Commons/CalendarDatePickers/CalendarDatePicker'
import SecondaryButton from '../../../../Commons/Buttons/SecondaryButton'
import React, { useState } from 'react'

const CpamInvoiceHeader = ({
    invoice,
    handleSuspendInvoiceCpam,
    ACTION,
    showInvoiceAction = true,
    patient,
    dateOfBirth,
    dispatch,
    prescription = null,
    run = null,
    handlePrintInvoice,
    checkAndHandleInvoiceForm = null,
}) => {
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)
    const [openMobileSelectDatePicker, setOpenMobileSelectDatePicker] =
        useState(false)
    return (
        <>
            <div
                className={
                    'flex items-center justify-between px-2 py-5 sm:px-0'
                }
            >
                <div className={'flex flex-col'}>
                    <InvoiceStatusDisplay
                        invoice={invoice}
                        handleSuspendedInvoice={handleSuspendInvoiceCpam}
                    />
                </div>
                {showInvoiceAction ? (
                    <InvoiceActions
                        patient={patient}
                        invoice={invoice}
                        dispatch={dispatch}
                        action={ACTION}
                        dateOfBirth={dateOfBirth}
                        checkAbleToInvoice={checkAbleToInvoice}
                        prescription={prescription}
                        run={run}
                        checkAndHandleInvoiceForm={checkAndHandleInvoiceForm}
                    />
                ) : null}
            </div>

            <div
                className={
                    'flex flex-wrap items-center justify-between space-y-2 px-2 py-2 '
                }
            >
                <>
                    <div className="hidden sm:flex">
                        <div
                            className={
                                'flex items-center rounded-full bg-blue-100 p-2'
                            }
                        >
                            <User className={'mr-2'} />
                            <p>
                                {patient.lastname} {patient.firstname}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="hidden pr-4 sm:flex">
                            <CalendarDatePicker
                                label={'Date de facturation'}
                                openSelectDatePicker={openSelectDatePicker}
                                setOpenSelectDatePicker={
                                    setOpenSelectDatePicker
                                }
                                date={invoice.date}
                                setDate={(e) =>
                                    dispatch({
                                        type: ACTION.SET_DATE,
                                        payload: e,
                                    })
                                }
                                customDisplay={'dddd D MMM YYYY'}
                                fullWidth={true}
                                fontSize={'text-md'}
                                mobileLeftWidth={true}
                            />
                        </div>
                        <div className="flex sm:hidden">
                            <CalendarDatePicker
                                label={'Date de facturation'}
                                openSelectDatePicker={
                                    openMobileSelectDatePicker
                                }
                                setOpenSelectDatePicker={
                                    setOpenMobileSelectDatePicker
                                }
                                date={invoice.date}
                                setDate={(e) =>
                                    dispatch({
                                        type: ACTION.SET_DATE,
                                        payload: e,
                                    })
                                }
                                customDisplay={'d MMM YYYY'}
                                fullWidth={false}
                                fontSize={'text-md'}
                                mobileLeftWidth={true}
                            />
                        </div>
                    </div>
                    {invoice.id ? (
                        <SecondaryButton
                            icon={<Printer />}
                            action={handlePrintInvoice}
                            label={'Imprimer'}
                        ></SecondaryButton>
                    ) : null}
                </>
                {invoice.cpamInvoiceRejectionDetails?.length > 0 ? (
                    <SecondaryButton
                        label={'Historique des rejets'}
                        action={() => {
                            dispatch({
                                type: ACTION.SET_SHOW_REJECT_HISTORY,
                                payload: true,
                            })
                        }}
                    />
                ) : null}
            </div>
        </>
    )
}

export default CpamInvoiceHeader
