import React, { useEffect } from 'react'
import { ACTIONS } from '../../../../services/Reducers/PlanningReducer'
import TeamsList from './TeamsList'
import RunsContainer from './RunsContainer'
import Loader from '../../../Commons/Loaders/Loader'
import GlobalPlanningViewModal from '../../../Modal/GlobalPlanningViewModal'
import API from '../../../../services/api'
import { saveAs } from 'file-saver'
import dayjs from 'dayjs'
import { usePlanningContext } from '../PlanningContext'

const PlanningSorted = ({ useOnClickAddMenuOutside, showRunObjectColors }) => {
    const {
        planning,
        dispatch,
        reRender,
        handleClearTeamAndUnassignedRun,
        totalRunsTeam,
        handleOpenRunManageModal,
        unassignSelectedTeamsRuns,
        handleTeamClicked,
        handleGroupIconClicked,
        handleInformationRunModalOpen,
        handlePdfGeneration,
        handleOpenCompetitionModal,
        handleDisabledButtons,
    } = usePlanningContext()

    useEffect(() => {
        handleClearTeamAndUnassignedRun()
    }, [])

    const handleSelectedTeam = (team) => {
        dispatch({ type: ACTIONS.SET_SELECTED_TEAM, payload: team })
    }
    const handleSelectedUnassignedRuns = (value) => {
        dispatch({ type: ACTIONS.SET_SELECTED_UNASSIGNED_RUNS, payload: value })
    }

    const handleGlobalView = () => {
        dispatch({
            type: ACTIONS.SET_OPEN_GLOBAL_VIEW,
            payload: !planning.openGlobalView,
        })
    }

    return (
        <div>
            {planning.loading ? (
                <Loader />
            ) : (
                <div className="pb-20">
                    {!planning.selectedTeam &&
                    !planning.selectedUnassignedRuns ? (
                        <TeamsList
                            handleSelectedTeam={handleSelectedTeam}
                            planning={planning}
                            totalRunsTeam={totalRunsTeam}
                            handleSelectedUnassignedRuns={
                                handleSelectedUnassignedRuns
                            }
                            handleGlobalView={handleGlobalView}
                            handlePdfGeneration={handlePdfGeneration}
                        />
                    ) : (
                        <RunsContainer
                            planning={planning}
                            handleClearTeamAndUnassignedRun={
                                handleClearTeamAndUnassignedRun
                            }
                            handleSelectedUnassignedRuns={
                                handleSelectedUnassignedRuns
                            }
                            totalRunsTeam={totalRunsTeam}
                            dispatch={dispatch}
                            reRender={reRender}
                            handleOpenRunManageModal={handleOpenRunManageModal}
                            unassignSelectedTeamsRuns={
                                unassignSelectedTeamsRuns
                            }
                            handleTeamClicked={handleTeamClicked}
                            handleGroupIconClicked={handleGroupIconClicked}
                            handleInformationRunModalOpen={
                                handleInformationRunModalOpen
                            }
                            handleGlobalView={handleGlobalView}
                            useOnClickAddMenuOutside={useOnClickAddMenuOutside}
                            handlePdfGeneration={handlePdfGeneration}
                            showRunObjectColors={showRunObjectColors}
                            handleOpenCompetitionModal={
                                handleOpenCompetitionModal
                            }
                            handleDisabledButtons={handleDisabledButtons}
                        />
                    )}
                    {planning.openGlobalView && (
                        <GlobalPlanningViewModal
                            planning={planning}
                            dispatch={dispatch}
                            showRunObjectColors={showRunObjectColors}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

export default PlanningSorted
