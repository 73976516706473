import { toast } from 'react-toastify'

export const handleApiError = async (response) => {
    if (!response.ok) {
        const errorData = await response.json()
        console.log(errorData)
        if (response.status === 401) {
            window.location.href = '/' // redirect // redirect
        } else if (errorData['hydra:description'] !== undefined) {
            toast.error(errorData['hydra:description'])
        } else {
            toast.error(errorData['error'])
        }
        throw new Error(errorData['hydra:description'])
    }
    return response.json()
}

export const handleApiErrorWithoutJsonResponse = async (response) => {
    if (!response.ok) {
        const errorData = await response.json()
        if (response.status === 401) {
            window.location.href = '/' // redirect
        } else {
            toast.error(errorData['hydra:description'])
        }
        throw new Error(errorData['hydra:description'])
    }
}

export const handleDeleteApiError = async (response) => {
    if (response.status !== 204) {
        console.log(response)
        const errorData = await response.json()
        if (response.status === 401) {
            window.location.href = '/' // redirect
        } else {
            toast.error(errorData['hydra:description'])
        }
        throw new Error(errorData['hydra:description'])
    } else {
        return response
    }
}
