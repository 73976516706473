import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import PartnerForm from './PartnerForm'
import Partner from './Partner'
import ClassicFilter from '../../Commons/ClassicFilter'

const PartnerContainer = (props) => {
    const [search, setSearch] = useState('')
    return (
        <Routes>
            <Route path=":id" element={<PartnerForm />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <ClassicFilter
                            {...props}
                            search={search}
                            setSearch={setSearch}
                            icon={'fa-hands-helping '}
                            title={'Nouveau partenaire'}
                            link={'/ressources/partenaires'}
                        />
                        <Partner
                            {...props}
                            search={search}
                            setSearch={setSearch}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default PartnerContainer
