import ClassicSwitch from '../../../../Commons/Inputs/ClassicSwitch'
import ClassicInputV2 from '../../../../Commons/Inputs/ClassicInputV2'
import React from 'react'
import { RUN_INVOICE_FORM_DETAILS_ACTIONS } from './RunInvoiceFormDetailsReducer'

const MinimumPerceptionSection = ({ dispatch, invoiceCpamForm }) => {
    return (
        <>
            <div className="flex items-center justify-between space-x-2 pb-4">
                <div className={'flex w-4/5 flex-col'}>
                    <p className="text-lg font-semibold">
                        Minimum de perception
                    </p>
                    <p className="">
                        Est-ce que le transport fait est inférieur au minimum de
                        perception ?
                    </p>
                </div>
                <div className={'w-1/5'}>
                    <ClassicSwitch
                        setValue={() =>
                            dispatch({
                                type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_IS_MINIMUM_PERCEPTION,
                                payload: !invoiceCpamForm.isMinimumPerception,
                            })
                        }
                        value={invoiceCpamForm.isMinimumPerception}
                    />
                </div>
            </div>
            {invoiceCpamForm.isMinimumPerception ? (
                <div className={'pb-4'}>
                    <div className="col-span-2 flex justify-between space-x-5">
                        <ClassicInputV2
                            label="Montant du forfait"
                            className="w-full"
                            value={invoiceCpamForm.minimumPerceptionAmount}
                            setValue={(e) =>
                                dispatch({
                                    type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_MINIMUM_PERCEPTION_AMOUNT,
                                    payload: e.target.value,
                                })
                            }
                            placeholder="0"
                            showLabel={true}
                        />
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default MinimumPerceptionSection
