import { Download } from 'iconoir-react'
import React from 'react'
import { ReactComponent as InlineButtonLoader } from '../../../files/inlineButtonLoader.svg'

const DownloadButton = ({ action, loading }) => {
    return (
        <div
            className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border-2 border-secondary-color pb-1 text-xl text-secondary-color hover:border-primary-color hover:text-primary-color"
            onClick={action}
        >
            {loading ? <InlineButtonLoader /> : <Download />}
        </div>
    )
}

export default DownloadButton
