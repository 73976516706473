import React from 'react'
import WelcomeTemplate from './WelcomeTemplate'
import { ReactComponent as Team } from '../../../files/team.svg'

const WelcomeTeams = ({ welcome, nextStep, previousStep }) => {
    const content = () => {
        return (
            <div className="flex h-full flex-col space-y-4">
                <div>
                    Comme dit précédemment, les équipes sont composées d'un ou
                    plusieurs chauffeurs associé(s) à un véhicule.{' '}
                </div>
                <div>
                    Notre première équipe sera donc composée de{' '}
                    {welcome.user.firstname} et du véhicule{' '}
                    {welcome.vehicle.label
                        ? welcome.vehicle.label
                        : welcome.vehicle.licensePlate}
                    .
                </div>

                <div className="flex justify-center pb-10 pt-4">
                    <Team />
                </div>
                <div>
                    Les équipes sont créées pour un jour donné. Chaque jour,
                    elles sont automatiquement créées suivant une configuration
                    d'équipes par défaut que vous pouvez modifier à tout moment.
                    L'équipe que vous allez ajouter sera ajoutée comme
                    configuration par défaut.
                </div>
                <div>Allons voir cela sur le prochain écran.</div>
            </div>
        )
    }
    return (
        <WelcomeTemplate
            nextStep={nextStep}
            previousStep={previousStep}
            content={content()}
            title="Équipes"
        />
    )
}

export default WelcomeTeams
