import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Plus } from 'iconoir-react'
import PrimaryButton from './PrimaryButton'
import SecondaryButton from './SecondaryButton'

const SendFormButtons = ({
    handleForm,
    disabledSubmit = false,
    cancelAction = null,
    buttonLabel = null,
    hideSubmitButton = false,
    loader = false,
}) => {
    let history = useNavigate()
    const { id } = useParams()

    return (
        <div className="mt-auto w-full">
            <div className="px-2 pb-20 text-xl lg:px-10 lg:pb-2 lg:text-base">
                <hr className="pb-5" />
                <div className="flex justify-between">
                    <SecondaryButton
                        label="Annuler"
                        title="Annuler"
                        action={() =>
                            !cancelAction ? history(-1) : cancelAction()
                        }
                        disabled={disabledSubmit}
                    />
                    {!hideSubmitButton ? (
                        <div className="pr-2">
                            {buttonLabel ? (
                                <PrimaryButton
                                    label={buttonLabel}
                                    title={buttonLabel}
                                    action={handleForm}
                                    disabled={disabledSubmit}
                                    loader={loader}
                                />
                            ) : (
                                <PrimaryButton
                                    label={id !== '0' ? 'Modifier' : 'Valider'}
                                    title={id !== '0' ? 'Modifier' : 'Valider'}
                                    action={handleForm}
                                    disabled={disabledSubmit}
                                    loader={loader}
                                />
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    )
}

export default SendFormButtons
