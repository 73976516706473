export const BirthRank = [
    { value: 1, label: '1 seul (non-jumeau)' },
    { value: 2, label: '2 jumeaux' },
    { value: 3, label: '3 triplets' },
    { value: 4, label: '4 quadruplés' },
    { value: 5, label: '5 quintuplés' },
    { value: 6, label: '6 sextuplés' },
    { value: 7, label: '7 septuplés' },
    { value: 8, label: '8 octuplés' },
    { value: 9, label: '9 étranger' },
]
