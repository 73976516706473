import React from 'react'

const Alert = ({ title, text, goTo, lead, clearError, shadow }) => {
    let color = 'red'
    if (title === 'Attention') {
        color = 'yellow'
    } else if (title === 'Info') {
        color = 'blue'
    }
    const content =
        title === 'Attention' ? (
            text
        ) : (
            <span
                className="font-normal  max-w-full flex-initial"
                dangerouslySetInnerHTML={{ __html: text }}
            />
        )

    return (
        <div
            className={`border-l-4 bg-white rounded-xl border-${color}-500 p-4 ${
                shadow ? 'shadow-mobile' : ''
            }`}
        >
            <div className="flex items-center">
                <div>
                    <i
                        className={`fas fa-exclamation-circle text-xl text-${color}-500 items-center justify-center pr-4`}
                    />
                </div>
                <div>
                    <div className="font-bold text-gray-700">{title}</div>
                    <div className="inline-block text-gray-700 ">
                        {content}
                        {lead !== 0 && lead !== undefined && (
                            <a
                                onClick={goTo}
                                className={
                                    'font-semibold text-light-dark-green cursor-pointer'
                                }
                            >
                                transport aller
                            </a>
                        )}
                    </div>
                </div>
                {(title === 'Info' ||
                    title === 'Erreur' ||
                    title === 'Attention') &&
                    clearError && (
                        <div className="pl-2 flex flex-auto flex-row-reverse">
                            <button onClick={clearError}>
                                <i className="fas fa-times" />
                            </button>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default Alert
