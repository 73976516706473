import { formatHydraData } from '../../api'
import { apiClient, withErrorHandling } from '../apiClient'

const PATH_KEY = '/b2_files'

export const Noemie = {
    fetchAll: async (archived) => {
        let path = PATH_KEY + '?pagination=false'
        if (archived) {
            path += '&archived=true'
        } else {
            path += '&archived=false'
        }
        return formatHydraData(await apiClient.get(path))
    },
    print: withErrorHandling(async (id) => {
        return await apiClient.get('/pdf_cpam/' + id)
    }),
}
