import { Xmark, Car, User } from 'iconoir-react'
import { ACTIONS as TEAMS_ACTIONS } from '../../../services/Reducers/TeamsReducer'
import React, { useEffect, useReducer } from 'react'
import {
    getInitialState,
    TeamReducer,
    ACTIONS,
} from '../../../services/Reducers/TeamReducer'
import API from '../../../services/api'
import {
    ERROR_ACTIONS,
    ErrorReducer,
} from '../../../services/Reducers/ErrorReducer'
import * as dayjs from 'dayjs'
import { toast } from 'react-toastify'
import SelectDropDownListV3 from '../../Commons/DropDownLists/SelectDropDownListV3'
import Error from '../../Commons/Error'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import Loader from '../../Commons/Loaders/Loader'
import { formatDateToApi } from '../../../services/dateUtils'

const Manage = ({
    teams,
    teamsDispatch,
    fetchMasterRunsList,
    date,
    fetchDefaultMasterRunsList,
}) => {
    const [team, dispatch] = useReducer(TeamReducer, { date }, getInitialState)
    const [errors, dispatchError] = useReducer(ErrorReducer, [])

    const closeModal = () => {
        teamsDispatch({
            type: TEAMS_ACTIONS.SET_IS_OPENED_MANAGE,
            payload: false,
        })
    }

    const handleVehicle = (event) => {
        if (event.label.split('-')[0] !== 'Ambulance ') {
            handleIsDisabled(true)
            handleParamedicTwo(null)
        } else {
            handleIsDisabled(false)
        }
        handleSelectedVehicle(event)
    }

    const fetchVehicleList = () => {
        API.Vehicles.list(null, null, false, null).then((response) => {
            response.json().then((data) => {
                const vehicles = data['hydra:member'].map((vehicle) => {
                    return {
                        value: vehicle['@id'],
                        label: vehicle.type.label + ' - ' + vehicle.label,
                    }
                })
                handleVehicles(vehicles)
            })
        })
    }

    const fetchUsersList = () => {
        API.Users.list(null, null, null, null, null, true).then((response) => {
            response.json().then((data) => {
                const users = data['hydra:member'].map((user) => {
                    return {
                        value: user['@id'],
                        label: `${user.firstname} ${user.lastname}`,
                    }
                })
                handleUsers(users)
                handleUsersNotSelected(users)
            })
        })
    }

    const checkErrors = (selectedUsers) => {
        let printedErrors = []
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })
        if (selectedUsers.length === 0) {
            printedErrors.push({
                id: 'emptyDrivers',
                message: 'Veuillez sélectionner au moins un chauffeur.',
            })
        }

        if (!team.selectedVehicle) {
            printedErrors.push({
                id: 'vehicleNotSelected',
                message: 'Veuillez assigner un véhicule.',
            })
        }

        if (printedErrors.length === 0) {
            return false
        } else {
            printedErrors.map((error) => {
                dispatchError({
                    type: ERROR_ACTIONS.SET_ERROR,
                    payload: error,
                })
            })
            return true
        }
    }

    const handleForm = () => {
        let selectedUsers = []
        if (team.paramedicOne) selectedUsers.push(team.paramedicOne)
        if (team.paramedicTwo) selectedUsers.push(team.paramedicTwo)
        if (!checkErrors(selectedUsers)) {
            const data = {
                vehicle: team.selectedVehicle.value,
                comments: team.comments,
                pm: true,
                users: selectedUsers.map((user) => {
                    return user.value
                }),
                date: formatDateToApi(team.date, true),
            }

            if (teams.defaultTeamsView) {
                if (teams.teamId === 0) {
                    data.position = teams.rows.length + 1
                    API.DefaultMasterRuns.post(data)
                        .then((response) => {
                            toast.success('Équipe par défaut créée.')
                            fetchDefaultMasterRunsList(false)
                            closeModal()
                        })
                        .catch((error) => {
                            toast.error("Une erreur s'est produite.")
                        })
                } else {
                    API.DefaultMasterRuns.put(teams.teamId, data)
                        .then((response) => {
                            toast.success('Équipe par défaut modifiée.')
                            fetchDefaultMasterRunsList(false)
                            closeModal()
                        })
                        .catch((error) => {
                            toast.error("Une erreur s'est produite.")
                        })
                }
            } else {
                if (teams.teamId === 0) {
                    data.position = teams.rows.length + 1

                    API.MasterRuns.post(data)
                        .then((response) => {
                            if (response.status === 201) {
                                toast.success('Équipe créée.')
                                fetchMasterRunsList(false)
                                closeModal()
                            } else {
                                toast.error("Une erreur s'est produite.")
                            }
                        })
                        .catch((error) => {
                            toast.error("Une erreur s'est produite.")
                        })
                } else {
                    API.MasterRuns.put(teams.teamId, data)
                        .then((response) => {
                            toast.success('Équipe modifiée.')
                            fetchMasterRunsList(false)
                            closeModal()
                        })
                        .catch((error) => {
                            toast.error("Une erreur s'est produite.")
                        })
                }
            }
        }
    }

    const getTeam = () => {
        API.MasterRuns.get(teams.teamId).then((response) => {
            response.json().then((data) => {
                const team = {
                    paramedicOne: null,
                    paramedicTwo: null,
                    vehicle: {
                        label: `${data.vehicle.type.label} - ${data.vehicle.label}`,
                        value: data.vehicle['@id'],
                    },
                    date: new Date(data.date),
                    position: data.position,
                }

                team.selectedVehicle = team.vehicle

                if (data.users) {
                    team.paramedicOne = {
                        label: `${data.users[0].firstname} ${data.users[0].lastname}`,
                        value: data.users[0]['@id'],
                    }
                    if (data.users.length > 1) {
                        team.paramedicTwo = {
                            label: `${data.users[1].firstname} ${data.users[1].lastname}`,
                            value: data.users[1]['@id'],
                        }
                    } else {
                        team.isDisabled = true
                    }
                }
                handleTeam(team)
            })
        })
    }
    const getDefaultTeam = () => {
        API.DefaultMasterRuns.get(teams.teamId).then((response) => {
            response.json().then((data) => {
                const team = {
                    paramedicOne: null,
                    paramedicTwo: null,
                    vehicle: {
                        label: `${data.vehicle.type.label} - ${data.vehicle.label}`,
                        value: data.vehicle['@id'],
                    },
                    position: data.position,
                }

                team.selectedVehicle = team.vehicle

                if (data.users) {
                    team.paramedicOne = {
                        label: `${data.users[0].firstname} ${data.users[0].lastname}`,
                        value: data.users[0]['@id'],
                    }
                    if (data.users.length > 1) {
                        team.paramedicTwo = {
                            label: `${data.users[1].firstname} ${data.users[1].lastname}`,
                            value: data.users[1]['@id'],
                        }
                    } else {
                        team.isDisabled = true
                    }
                }
                handleTeam(team)
            })
        })
    }

    useEffect(() => {
        if (teams.teamId !== 0) {
            if (teams.defaultTeamsView) {
                getDefaultTeam()
            } else {
                getTeam()
            }
        } else {
            handleLoading(false)
        }

        fetchUsersList()
        fetchVehicleList()
    }, [])

    const handleDate = (value) => {
        dispatch({ type: ACTIONS.SET_DATE, payload: value })
    }

    const handleTeam = (team) => {
        dispatch({ type: ACTIONS.SET_TEAM, payload: team })
    }

    const handleOpenSelectDatePicker = (value) => {
        dispatch({ type: ACTIONS.SET_OPEN_SELECT_DATE_PICKER, payload: value })
    }

    const handleVehicles = (value) => {
        dispatch({ type: ACTIONS.SET_VEHICLES, payload: value })
    }

    const handleSelectedVehicle = (event) => {
        dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE, payload: event })
    }

    const handleUsers = (value) => {
        dispatch({ type: ACTIONS.SET_USERS, payload: value })
    }

    const handleUsersNotSelected = (value) => {
        dispatch({ type: ACTIONS.SET_USERS_NOT_SELECTED, payload: value })
    }

    const handleParamedicOne = (event) => {
        dispatch({ type: ACTIONS.SET_PARAMEDIC_ONE, payload: event })
    }

    const handleParamedicTwo = (event) => {
        dispatch({ type: ACTIONS.SET_PARAMEDIC_TWO, payload: event })
    }

    const handleIsDisabled = (value) => {
        dispatch({ type: ACTIONS.SET_IS_DISABLED, payload: value })
    }

    const handleLoading = (value) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: value })
    }

    return (
        <div className="modal fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50 px-2 pb-20 text-gray-700 md:px-6">
            <div className="shadow-modal invisible-scrollbar min-h-add-team-modal flex w-full max-w-xl flex-col overflow-y-auto rounded-lg bg-white p-6">
                {team.loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="flex w-full items-center justify-between pb-6">
                            <h1 className="text-lg font-semibold">
                                {teams.teamId === 0
                                    ? `Nouvelle équipe${
                                          teams.defaultTeamsView
                                              ? ' par défaut'
                                              : ''
                                      }`
                                    : "Modification d'équipe"}
                            </h1>
                            <Xmark
                                className="cursor-pointer text-2xl"
                                onClick={() => {
                                    teamsDispatch({
                                        type: TEAMS_ACTIONS.SET_IS_OPENED_MANAGE,
                                        payload: false,
                                    })
                                }}
                            />
                        </div>
                        <div className="my-auto flex flex-col space-y-4 px-6 pb-10">
                            <SelectDropDownListV3
                                maxMenuHeight={
                                    window.innerHeight < 1000 ? 180 : 220
                                }
                                icon={<Car />}
                                id="ambu"
                                label="Assigner un véhicule"
                                options={team.vehicles}
                                value={team.selectedVehicle}
                                handleOptionChange={handleVehicle}
                                isClearable={false}
                                isSearchable={false}
                                isDisabled={false}
                                showLabel={true}
                            />
                            <SelectDropDownListV3
                                maxMenuHeight={
                                    window.innerHeight < 1000 ? 180 : 220
                                }
                                icon={<User />}
                                id="user1"
                                label="Personne 1 "
                                options={team.usersNotSelected}
                                value={team.paramedicOne}
                                handleOptionChange={handleParamedicOne}
                                isClearable={false}
                                isSearchable={false}
                                showLabel={true}
                                isDisabled={false}
                            />
                            <SelectDropDownListV3
                                maxMenuHeight={
                                    window.innerHeight < 1000 ? 180 : 220
                                }
                                icon={<User />}
                                id="user2"
                                label="Personne 2"
                                options={team.usersNotSelected}
                                value={team.paramedicTwo}
                                handleOptionChange={handleParamedicTwo}
                                isSearchable={false}
                                isClearable={false}
                                showLabel={true}
                                isDisabled={team.isDisabled}
                                menuPlacement="top"
                            />
                        </div>
                        <div className="mt-auto flex justify-between">
                            <SecondaryButton
                                label="Annuler"
                                title="Annuler"
                                action={closeModal}
                            />
                            <div className="pr-2">
                                <PrimaryButton
                                    label={
                                        teams.teamId !== 0
                                            ? 'Modifier'
                                            : 'Valider'
                                    }
                                    title={
                                        teams.teamId !== 0
                                            ? 'Modifier'
                                            : 'Valider'
                                    }
                                    action={handleForm}
                                />
                            </div>
                        </div>
                    </>
                )}
                <div className="fixed right-0 top-0 z-50 mr-5 flex flex flex-col space-y-2 text-gray-700">
                    {errors.map((error) => {
                        return (
                            <Error
                                key={error.id}
                                error={error}
                                dispatch={dispatchError}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default Manage
