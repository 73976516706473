import classNames from 'classnames'
import { MoreVert } from 'iconoir-react'
import DefaultOptions from './DefaultOptions'
import React, { useEffect, useRef } from 'react'
import { ACTIONS } from '../../services/Reducers/OptionsReducer'
import TOOLS from '../../services/tools'
import NewMobileOption from './NewMobileOption'

const Options = ({
    dispatch,
    options,
    row,
    handleModalDelete = null,
    historyPush,
    handleHideTeam,
    isHidedTeam,
}) => {
    const optionsRef = useRef()
    const handleRowClick = (event, rowId) => {
        event.stopPropagation()
        dispatch({ type: ACTIONS.SET_SELECTED_ROW_ID, payload: rowId })
    }

    TOOLS.useOnClickOutside(optionsRef, () => {
        dispatch({ type: ACTIONS.USE_ON_CLICK_OUTSIDE })
    })

    return (
        <>
            <div
                className={classNames(
                    'hidden h-10 cursor-pointer rounded pt-2 lg:block',
                    {
                        options: row.id !== options.selectedRowId,
                    }
                )}
                onClick={(e) => handleRowClick(e, row.id)}
            >
                <MoreVert
                    className={classNames('rotate-90 text-xl', {
                        hidden: options.showOptions,
                    })}
                />
                <div className="absolute right-0">
                    {row.id === options.selectedRowId &&
                        options.showOptions && (
                            <DefaultOptions
                                rowId={options.selectedRowId}
                                handleModalDelete={handleModalDelete}
                                historyPush={historyPush}
                                optionsRef={optionsRef}
                                historyPushUpdate={historyPush}
                                handleHideTeam={handleHideTeam}
                                isHidedTeam={isHidedTeam}
                            />
                        )}
                </div>
            </div>
            {options.showMobileOptions && (
                <div className="absolute">
                    <NewMobileOption
                        rowId={options.selectedRowId}
                        handleModalDelete={handleModalDelete}
                        historyPush={historyPush}
                        historyPushCopy={null}
                        historyPushUpdate={historyPush}
                        options={options}
                        dispatch={dispatch}
                        handleHideTeam={handleHideTeam}
                        isHidedTeam={isHidedTeam}
                    />
                </div>
            )}
        </>
    )
}

export default Options
