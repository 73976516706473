import React from 'react'

const isValidDate = (date) => date instanceof Date && !isNaN(date)

const getStoredDate = (key, defaultValue) => {
    try {
        const storedValue = window.localStorage.getItem(key)
        if (storedValue === null) {
            return new Date(defaultValue)
        }
        const parsedDate = new Date(storedValue)
        return isValidDate(parsedDate) ? parsedDate : new Date(defaultValue)
    } catch (error) {
        console.error('Error retrieving date from localStorage:', error)
        return new Date(defaultValue)
    }
}

const setStoredDate = (key, value) => {
    try {
        if (!isValidDate(value)) {
            throw new Error('Invalid date provided to setStoredDate')
        }
        window.localStorage.setItem(key, value.toISOString())
    } catch (error) {
        console.error('Error storing date in localStorage:', error)
    }
}

const useStickyState = (defaultValue, key, processValue = (value) => value) => {
    const [value, setValue] = React.useState(() => {
        const initialValue = getStoredDate(key, defaultValue)
        return processValue(initialValue)
    })

    React.useEffect(() => {
        if (isValidDate(value)) {
            setStoredDate(key, value)
        }
    }, [key, value])

    return [value, setValue]
}

export const useStickyDateState = (defaultValue, key) =>
    useStickyState(defaultValue, key)

export const useStickyEndDateState = (defaultValue, startDateKey, endDateKey) =>
    useStickyState(defaultValue, endDateKey, (endDate) => {
        const startDate = getStoredDate(startDateKey, defaultValue)
        return endDate < startDate ? startDate : endDate
    })
