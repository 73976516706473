import React, { useReducer, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    getPayingCenterInitialState,
    PAYING_CENTER_FORM_ACTIONS as PAYING_CENTER_ACTIONS,
    PayingCenterFormReducer,
} from '../Regulation/Reducers/PayingCenterFormReducer'
import { PayingCenters } from '../../../services/API/Entities/payingCenters'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'
import PayingCenterForm from '../Regulation/Forms/PayingCenterForm'
import { toast } from 'react-toastify'
import NoData from '../NoData'

const PayingCenterWrapper = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const queryClient = useQueryClient()

    const {
        data: payingCenter,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['payingCenter', id],
        queryFn: () => PayingCenters.fetchOne(id),
        enabled: !!id,
    })

    const [payingCenterForm, dispatch] = useReducer(
        PayingCenterFormReducer,
        { undefined },
        getPayingCenterInitialState
    )

    const payingCenterMutation = useMutation({
        mutationFn: () => PayingCenters.put(payingCenterForm),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['payingCenter', id] })
            toast.success('Le centre payeur a été modifié')
            navigate(-1)
        },
    })
    const handlePayingCenterModalForm = async () => {
        payingCenterMutation.mutate(payingCenterForm)
    }

    useEffect(() => {
        if (payingCenter) {
            dispatch({
                type: PAYING_CENTER_ACTIONS.FETCH_PAYING_CENTER,
                payload: payingCenter,
            })
        }
    }, [payingCenter])

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return (
            <NoData message="Une erreur s'est produite lors du chargement des caisses." />
        )
    }

    if (payingCenter.length === 0)
        return <NoData message="Aucune caisse renseignée pour le moment" />

    return (
        <>
            <PayingCenterForm
                payingCenter={payingCenterForm}
                dispatch={dispatch}
                addressItem={payingCenter.address}
            />
            <SendFormButtons handleForm={handlePayingCenterModalForm} />
        </>
    )
}

export default PayingCenterWrapper
