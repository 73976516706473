import React from 'react'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import RunSelectStatus from '../../Commons/DropDownLists/RunSelectStatus'
import { RunStatus } from '../../../services/RunStatus'

const SelectStatusModal = ({
    handleForm,
    handleCloseModal,
    statusForm,
    setStatusForm,
}) => {
    return (
        <div className="modal fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="min-h-action-modal mx-2 flex flex-col rounded-lg bg-white p-2 text-lg sm:mx-0 sm:w-1/3 lg:text-base">
                <div className="flex flex-col items-start  p-2">
                    <div className="flex w-full pb-4 text-3xl ">
                        <div className="flex space-x-4 text-center font-semibold">
                            Sélectionner un statut
                        </div>
                    </div>
                    <RunSelectStatus
                        status={statusForm}
                        statusOptions={RunStatus}
                        handleStatusChange={setStatusForm}
                    />
                </div>
                <SendFormButtons
                    handleForm={handleForm}
                    cancelAction={handleCloseModal}
                    // disabledSubmit={disabledSubmit}
                />
            </div>
        </div>
    )
}

export default SelectStatusModal
