import React, { useState } from 'react'
import ClassicInputV2 from '../../../Commons/Inputs/ClassicInputV2'
import { PEC_ACTIONS } from '../../../../services/Reducers/PecReducer'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'

export const PecLoginModal = ({ dispatch, pec }) => {
    // login modal using dispatch
    const [passwordShown, setPasswordShown] = useState(false)
    return (
        <div className="fixed bottom-0 left-0 z-40 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="min-h-action-modal mx-2 flex flex-col items-center rounded-lg bg-white p-2 text-lg sm:mx-0 sm:w-1/3 lg:text-base">
                <div className="flex h-full w-full flex-col p-2 text-xl">
                    <div className="flex justify-between text-gray-700">
                        <div className="flex flex-col">
                            <div className="font-bold">
                                Démarrez une session PEC+ de 30 minutes
                            </div>
                            <div className=" text-sm">
                                (À chaque nouvelle demande PEC+, votre session
                                est prolongée de 30 minutes.)
                            </div>
                        </div>
                        <svg
                            className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() =>
                                dispatch({
                                    type: PEC_ACTIONS.OPEN_MODAL,
                                })
                            }
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                </div>
                <div className="flex w-full flex-col space-y-4 p-4">
                    <ClassicInputV2
                        showLabel={true}
                        label={'Identifiant'}
                        value={pec.identifiant}
                        setValue={(e) =>
                            dispatch({
                                type: PEC_ACTIONS.SET_IDENTIFIANT,
                                payload: e.target.value,
                            })
                        }
                    />
                    <ClassicInputV2
                        showLabel={true}
                        label={'Mot de passe'}
                        value={pec.password}
                        type={passwordShown ? 'text' : 'password'}
                        setValue={(e) =>
                            dispatch({
                                type: PEC_ACTIONS.SET_PASSWORD,
                                payload: e.target.value,
                            })
                        }
                    />
                    <div
                        className="flex cursor-pointer pb-8"
                        onClick={() => setPasswordShown(!passwordShown)}
                    >
                        <input
                            type="checkbox"
                            className=""
                            checked={passwordShown}
                        />
                        <p className="pl-2 text-xl lg:text-base">
                            Afficher le mot de passe
                        </p>
                    </div>
                </div>
                <div className="mt-auto flex w-full justify-between ">
                    <SecondaryButton
                        label="Annuler"
                        title="Annuler"
                        action={() =>
                            dispatch({
                                type: PEC_ACTIONS.OPEN_MODAL,
                            })
                        }
                    />
                    <PrimaryButton
                        label="Connexion"
                        title="Connexion"
                        action={() =>
                            dispatch({
                                type: PEC_ACTIONS.START_SESSION,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    )
}
