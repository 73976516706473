import { createContext, useContext, useState } from 'react'
import API from '../services/api'

export const UserContext = createContext(null)

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null)

    const login = async (email, password) => {
        try {
            const response = await API.Authentication.handleLogin(
                email,
                password
            )
            const userData = await response.json()
            setUser(userData) // Update user state here
            return userData
        } catch (error) {
            console.error('Login failed:', error)
            throw error
        }
    }

    const logout = () => {
        setUser(null) // Reset user state to null on logout
    }

    const value = {
        user, // Use the state variable user here
        login,
        logout,
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext)
