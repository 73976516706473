import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import ClassicTextarea from '../../../Commons/Inputs/ClassicTextarea'
import SharingOptions from '../../../../services/SharingOptions'
import DeleteButton from '../../../Commons/Buttons/DeleteButton'
import { formatName } from '../../../../tools/Utility'
import { TRANSPORT_ACTIONS } from '../Reducers/TransportFormReducer'
import CustomRadioButton from '../../../Commons/CustomRadioButton'
import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'
import React from 'react'

const ShareForm = ({ run, dispatch, handleRemoveShare }) => {
    return (
        <div className="flex flex-col space-y-4">
            <h1 className="pl-4 pt-10 text-2xl font-semibold lg:pl-0">
                Échangez des transports avec vos partenaires
            </h1>
            {/* add a new layer to inform new feature is available*/}
            <div className="max-w-3xl rounded-md border-l-4 border-secondary-color bg-secondary-color/10 p-4 shadow-md">
                <div className="flex  space-x-4">
                    <div className="flex-shrink-0">
                        <i className="fas fa-info-circle text-2xl text-secondary-color"></i>
                    </div>
                    <div>
                        <p className="mb-1 font-semibold text-secondary-color">
                            Nouvelle fonctionnalité disponible
                        </p>
                        <p className="text-md  leading-relaxed text-gray-600">
                            Vous et vos partenaires disposant d’un compte Heroad
                            pouvez désormais intégrer directement les transports
                            que vous partagez dans vos applications respectives
                            !
                        </p>
                        <ul className="mt-2 list-inside list-disc  text-gray-600">
                            <li>Info du transport</li>
                            <li>Caisse du patient</li>
                            <li>Prescription</li>
                            <li>Fiche patient</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="rounded-xl bg-white p-6 shadow-md">
                <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
                    <div>
                        <SelectDropDownListV2
                            label={'Partenaire'}
                            showLabel={true}
                            icon={'fa-hands-helping'}
                            value={formatName(run.partner)}
                            options={run.partners}
                            handleOptionChange={(e) =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.SET_PARTNER,
                                    payload: e,
                                })
                            }
                            isDisabled={run.isRoundTripReturn || !!run.sharedAt}
                            isClearable={!run.sharedAt}
                            isSearchable={true}
                        />
                    </div>
                    <div className="flex items-center justify-center space-x-6">
                        <CustomRadioButton
                            label="Transport à donner"
                            id="donner"
                            value="donner"
                            name="donner"
                            checked={run.isGiven}
                            onChange={() =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.IS_GIVEN_BY_PARTNER,
                                    payload: true,
                                })
                            }
                            disabled={!!run.sharedAt}
                        />
                        <CustomRadioButton
                            label="Transport reçu"
                            id="recevoir"
                            value="recevoir"
                            name="recevoir"
                            checked={!run.isGiven}
                            onChange={() =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.IS_GIVEN_BY_PARTNER,
                                    payload: false,
                                })
                            }
                            disabled={!!run.sharedAt}
                        />
                    </div>
                </div>
                {run.runType.value > 2 ? (
                    <div className="mt-6 flex w-full items-center justify-between space-x-4 lg:w-1/2 ">
                        <div className={'flex w-3/4 flex-col lg:w-1/2'}>
                            <label className="block pb-2 text-base font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                                Aller-retour
                            </label>
                            <p className="">
                                Partager le trajet aller et retour avec votre
                                partenaire ?
                            </p>
                        </div>
                        <div
                            className={
                                'flex w-1/4 items-center justify-center lg:w-1/2'
                            }
                        >
                            <ClassicSwitch
                                value={run.shareRoundTrip}
                                setValue={() =>
                                    dispatch({
                                        type: TRANSPORT_ACTIONS.SET_SHARE_ROUNDTRIP,
                                        payload: !run.shareRoundTrip,
                                    })
                                }
                                disabled={
                                    run.isRoundTripReturn ||
                                    !!run.sharedAt ||
                                    !run.isGiven
                                }
                            />
                        </div>
                    </div>
                ) : null}
                <div className="mt-6 grid grid-cols-1 gap-6 lg:grid-cols-2">
                    <div>
                        <SelectDropDownListV2
                            label={'Méthode de partage'}
                            showLabel={true}
                            icon={'fa-paper-plane'}
                            value={run.sharingOption}
                            options={SharingOptions}
                            handleOptionChange={(e) =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.SET_SHARING_OPTION,
                                    payload: e,
                                })
                            }
                            isDisabled={
                                run.isRoundTripReturn ||
                                !!run.sharedAt ||
                                !run.isGiven
                            }
                        />
                    </div>
                    <div>
                        <ClassicTextarea
                            setValue={(e) =>
                                dispatch({
                                    type: TRANSPORT_ACTIONS.SET_NOTE,
                                    payload: e.target.value,
                                })
                            }
                            value={run.note}
                            label={'Note de partage'}
                        />
                    </div>
                </div>
            </div>

            <div className="flex w-full items-center justify-between px-2 py-5 lg:px-5">
                {run.partner && run.sharedAt ? (
                    <DeleteButton
                        label="Retirer le partage"
                        title="Retirer le partage"
                        action={handleRemoveShare}
                    />
                ) : null}
            </div>
        </div>
    )
}

export default ShareForm
