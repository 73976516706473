import { apiClient, withErrorHandling } from '../apiClient'
import { formatHydraData } from '../../api'
import { formatDateYYYYMMDD } from '../../dateUtils'

const PATH_KEY = '/driver'
export const Driver = {
    fetchOneRun: withErrorHandling(async (id) => {
        return await apiClient.get(`${PATH_KEY}/fetch_run/${id}`)
    }),
    fetchAllRuns: withErrorHandling(async (date) => {
        const formattedDate = formatDateYYYYMMDD(date)
        const path = `${PATH_KEY}/fetch_all?date=${formattedDate}`

        return formatHydraData(await apiClient.get(path))
    }),
    invalidateRun: withErrorHandling(async (id) => {
        return await apiClient.get(`${PATH_KEY}/invalidate_run/${id}`)
    }),
}
