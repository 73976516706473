export const BENEFICIARY_ACTIONS = {
    SET_BENEFICIARY: 'SET_BENEFICIARY',
    SET_BENEFICIARY_SEARCH_INPUT: 'SET_BENEFICIARY_SEARCH_INPUT',
    SET_FIRSTNAME: 'SET_FIRSTNAME',
    SET_LASTNAME: 'SET_LASTNAME',
    SELECTED_BENEFICIARY: 'SELECTED_BENEFICIARY',
    SET_BENEFICIARY_LINK: 'SET_BENEFICIARY_LINK',
}

export const BeneficiaryFormReducer = (state, action) => {
    switch (action.type) {
        case BENEFICIARY_ACTIONS.SET_BENEFICIARY:
            return {
                ...state,
                beneficiary: action.payload,
            }
        case BENEFICIARY_ACTIONS.SELECTED_BENEFICIARY:
            return {
                ...state,
                selectedBeneficiary: action.payload,
                firstname: '',
                lastname: '',
            }
        case BENEFICIARY_ACTIONS.SET_BENEFICIARY_SEARCH_INPUT:
            return {
                ...state,
                beneficiaries: action.payload,
            }
        case BENEFICIARY_ACTIONS.SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload,
            }
        case BENEFICIARY_ACTIONS.SET_BENEFICIARY_LINK:
            console.log('action.payload', action.payload)
            return {
                ...state,
                beneficiaryLink: action.payload,
            }
        case BENEFICIARY_ACTIONS.SET_LASTNAME:
            return {
                ...state,
                lastname: action.payload,
            }
        default:
            return state
    }
}

export const getBeneficiaryInitialState = ({ beneficiary }) => {
    if (beneficiary) {
        return {
            beneficiarySearchInput: '',
            beneficiaries: [],
            selectedBeneficiary: beneficiary,
        }
    } else {
        return {
            beneficiarySearchInput: '',
            beneficiaries: [],
        }
    }
}
