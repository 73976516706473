export const PRACTITIONER_FORM_ACTIONS = {
    SET_FIRSTNAME: 'SET_FIRSTNAME',
    SET_LASTNAME: 'SET_LASTNAME',
    SET_ESTABLISHMENT_LABEL: 'SET_ESTABLISHMENT_LABEL',
    SET_NUM_RPPS: 'SET_NUM_RPPS',
    SET_CODE_SPECIALITE: 'SET_CODE_SPECIALITE',
    SET_NUM_FINESS: 'SET_NUM_FINESS',
    SET_IS_ESTABLISHMENT: 'SET_IS_ESTABLISHMENT',
    FETCH_PRACTITIONER: 'FETCH_PRACTITIONER',
    SET_SHOW_MODAL: 'SET_PRACTITIONER_SHOW_MODAL',
    SET_ESTABLISHMENT: 'SET_ESTABLISHMENT',
}

// TODO AJOUT DES ESTABLISHMENT
export const getPractitionerInitialState = ({ practitioner }) => {
    if (practitioner) {
        return {
            isEstablishment: !!practitioner.label,
            ...practitioner,
        }
    }
    return {
        firstname: '',
        lastname: '',
        label: '',
        numRPPS: '',
        codeSpecialite: '',
        numFiness: '',
        isEstablishment: false,
        showModal: false,
        PractitionerList: [],
        establishment: null,
    }
}

export const PractitionerFormReducer = (state, action) => {
    switch (action.type) {
        case PRACTITIONER_FORM_ACTIONS.SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload,
                label: null,
            }
        case PRACTITIONER_FORM_ACTIONS.SET_LASTNAME:
            return {
                ...state,
                lastname: action.payload,
                label: null,
            }
        case PRACTITIONER_FORM_ACTIONS.SET_ESTABLISHMENT_LABEL:
            return {
                ...state,
                label: action.payload,
                lastname: null,
                firstname: null,
            }
        case PRACTITIONER_FORM_ACTIONS.SET_NUM_RPPS:
            return {
                ...state,
                numRPPS: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .substring(0, 11),
            }
        case PRACTITIONER_FORM_ACTIONS.SET_CODE_SPECIALITE:
            return {
                ...state,
                codeSpecialite: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .substring(0, 2),
            }
        case PRACTITIONER_FORM_ACTIONS.SET_NUM_FINESS:
            return {
                ...state,
                numFiness: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .substring(0, 9),
            }
        case PRACTITIONER_FORM_ACTIONS.SET_IS_ESTABLISHMENT:
            return {
                ...state,
                isEstablishment: action.payload,
            }
        case PRACTITIONER_FORM_ACTIONS.FETCH_PRACTITIONER:
            if (!action.payload)
                return getPractitionerInitialState({ undefined })
            return {
                ...state,
                ...action.payload,
                isEstablishment: !!action.payload.label,
            }
        case PRACTITIONER_FORM_ACTIONS.SET_SHOW_MODAL:
            return {
                ...state,
                showModal: action.payload,
            }
        case PRACTITIONER_FORM_ACTIONS.SET_ESTABLISHMENT:
            if (action.payload) {
                const isEstablishment = state.isEstablishment
                const establishment = action.payload.object

                if (isEstablishment) {
                    return {
                        ...state,
                        establishment: establishment,
                        label: establishment.companyName || '',
                        numFiness: establishment.finnessLegalEntityNumber || '',
                    }
                } else {
                    return {
                        ...state,
                        establishment: action.payload,
                        firstname: establishment.exerciseFirstname || '',
                        lastname: establishment.exerciseName || '',
                        numFiness: establishment.finnessNumber || '',
                        numRPPS: establishment.identifier || '',
                    }
                }
            } else {
                return {
                    ...state,
                    establishment: null,
                }
            }
        default:
            return state
    }
}
