export const PaymentMethods = [
    {
        value: 'Chèque',
        label: 'Chèque',
        ['@id']: '/run_objects/Course payante chèque',
    },
    {
        value: 'Espèces',
        label: 'Espèces',
        ['@id']: '/run_objects/Course payante espèces',
    },
    {
        value: 'Virement',
        label: 'Virement',
        ['@id']: '/run_objects/Course payante virement',
    },
    {
        value: 'Carte bancaire',
        label: 'Carte bancaire',
        ['@id']: '/run_objects/Course payante carte bancaire',
    },
]

export const FreeInvoicePaymentMethods = [
    {
        value: 'empty',
        label: 'Non spécifiée',
    },
    {
        value: 'Chèque',
        label: 'Chèque',
    },
    {
        value: 'Espèces',
        label: 'Espèces',
    },
    {
        value: 'Virement',
        label: 'Virement',
    },
    {
        value: 'Carte bancaire',
        label: 'Carte bancaire',
    },
]
