import React, { useState, useEffect, useRef } from 'react'
import { NavArrowDown } from 'iconoir-react'

const getColorStyles = (status, colorConfig) => {
    const defaultStyle = 'text-gray-500 bg-gray-100'
    return colorConfig[status.toLowerCase()] || defaultStyle
}

const getHoverStyle = (status, hoverConfig) => {
    const defaultHover = 'hover:bg-gray-100'
    return hoverConfig[status.toLowerCase()] || defaultHover
}

const StatusSelector = ({
    currentStatus,
    options,
    onChange,
    colorConfig,
    hoverConfig,
    width = 'w-48',
    isPending = false,
    withBorder = true,
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () =>
            document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const currentOption = options.find(
        (option) => option.value === currentStatus
    )
    const borderStyle = withBorder ? 'border-2' : ''

    return (
        <div ref={dropdownRef} className="relative inline-block text-left">
            <button
                disabled={isPending}
                className={`text-md align-center flex justify-center rounded-full px-4 py-1 font-bold transition-all ${width} ${borderStyle} ${getColorStyles(
                    currentOption?.label || currentStatus,
                    colorConfig
                )} ${
                    isPending
                        ? 'cursor-not-allowed opacity-70'
                        : 'hover:scale-[1.02]'
                }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <div className="flex items-center gap-2">
                    {isPending ? (
                        <svg
                            className="h-5 w-5 animate-spin "
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    ) : (
                        <p>{currentOption?.label || currentStatus}</p>
                    )}
                </div>
                <NavArrowDown className="ml-auto flex" />
            </button>

            {isOpen && !isPending && (
                <div className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {options.map((option) => (
                            <button
                                key={option.value}
                                className={`w-full px-2 py-2 text-left ${getHoverStyle(
                                    option.value,
                                    hoverConfig
                                )}`}
                                role="menuitem"
                                onClick={() => {
                                    onChange(option.label)
                                    setIsOpen(false)
                                }}
                            >
                                <span
                                    className={`flex w-full rounded p-2 font-semibold ${getColorStyles(
                                        option.label,
                                        colorConfig
                                    )}`}
                                >
                                    {option.label}
                                </span>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default StatusSelector
