import React from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import ClassicDatePicker from '../../Commons/ClassicDatePicker'
import { useNavigate } from 'react-router-dom'

const RegulationSeriesFilter = ({ search, setSearch, seriesId }) => {
    const history = useNavigate()

    const resetSeriesFilter = () => {
        history.replace({
            pathname: '/transport',
        })
    }

    return (
        <div className="grid grid-cols-4 gap-2 border-b py-4 text-gray-800">
            <div className="col-span-1">
                <div className="inline-block w-1/12">
                    <i className="fas fa-search" />
                </div>
                <div className="inline-block w-3/4">
                    <ClassicInput
                        label="Rechercher"
                        value={search}
                        setValue={(e) => {
                            setSearch(e.target.value)
                        }}
                        id="search"
                        placeholder="Rechercher"
                    />
                </div>
            </div>
            <div className="col-span-1" onClick={resetSeriesFilter}>
                <button className="rounded-full border bg-light-dark-green px-4 py-2 text-sm text-white hover:bg-dark-green">
                    <i className="fas fa-times fa-lg pr-2" />
                    <span className="pb-1">{`Série #${seriesId}`}</span>
                </button>
            </div>
        </div>
    )
}

export default RegulationSeriesFilter
