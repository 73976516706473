import { apiClient, withErrorHandling } from '../apiClient'
import { concat } from '../pathsAndFilters'
import { formatHydraData } from '../../api'
import { formatNameForSelect } from '../../../tools/Utility'

const PATH_KEY = '/patients'

export const Patient = {
    fetchAll: async (pagination, itemsPerPage, search) => {
        let path = PATH_KEY
        if (typeof pagination === 'boolean')
            path = concat(path, 'pagination=' + pagination)
        if (itemsPerPage) path = concat(path, 'itemsPerPage=' + itemsPerPage)
        if (search) path = concat(path, 'search=' + search)
        return formatHydraData(await apiClient.get(path))
    },
    fetchAllForDebounceSearch: async (search) => {
        const patients = await Patient.fetchAll(true, 5, search)
        return patients?.map((patient) => formatNameForSelect(patient))
    },
    requestPecInfo: withErrorHandling(async (pecInfo) => {
        const response = await apiClient.post('/patient/pec', pecInfo)
        return formatHydraData(response)
    }),
    fetchOne: async (id) => {
        return formatHydraData(await apiClient.get(`${PATH_KEY}/${id}`))
    },
}
