import React, { useEffect, useReducer, useState } from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import API from '../../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import Loader from '../../Commons/Loaders/Loader'
import Error from '../../Commons/Error'
import {
    ERROR_ACTIONS,
    ErrorReducer,
} from '../../../services/Reducers/ErrorReducer'
import {
    ESTABLISHMENT_ACTIONS,
    EstablishmentReducer,
    getInitialState,
} from '../../../services/Reducers/EstablishmentReducer'
import ClassicTextarea from '../../Commons/Inputs/ClassicTextarea'
import ManageTitle from '../../Commons/ManageTitle'
import {
    ADDRESS_ACTIONS,
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../services/Reducers/AddressAutoCompleteReducer'
import { toast } from 'react-toastify'
import AutoComplete from '../AutoComplete'

const Manage = () => {
    const [errors, dispatchError] = useReducer(ErrorReducer, [])
    let history = useNavigate()
    const { id } = useParams()
    const [label] = useState(
        id != 0
            ? `Modification de l'établissement`
            : "Ajout d'un nouvel établissement"
    )

    const [establishment, dispatch] = useReducer(
        EstablishmentReducer,
        undefined,
        getInitialState
    )

    const [address, addressDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )

    const checkErrors = () => {
        let printedErrors = []
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })
        establishment.address = address
        if (!establishment.label) {
            printedErrors.push({
                id: 'bad label',
                message: 'Veuillez renseigner un label.',
            })
        }
        if (!establishment.address.city) {
            printedErrors.push({
                id: 'bad address',
                message: 'Veuillez renseigner une ville.',
            })
        }

        if (printedErrors.length === 0) {
            return false
        } else {
            printedErrors.map((error) => {
                dispatchError({
                    type: ERROR_ACTIONS.SET_ERROR,
                    payload: error,
                })
            })
            return true
        }
    }

    const handleForm = () => {
        if (!checkErrors()) {
            delete address.id
            delete address['@id']
            establishment.address = address
            if (id == 0) {
                API.Establishments.addEstablishment(establishment)
                    .then((response) => {
                        toast.success('Etablissement ajouté avec succès.')
                        historyPush()
                    })
                    .catch(() => {
                        toast.error('Veuillez contacter un administrateur.')
                    })
            } else {
                API.Establishments.put(id, establishment)
                    .then((response) => {
                        toast.success('Etablissement mis à jour avec succès.')
                        historyPush()
                    })
                    .catch((error) => {
                        if (error.json) {
                            error.json().then((data) => {})
                        }
                    })
            }
        }
    }

    const historyPush = () => {
        history('/facturation/etablissements')
    }

    const getEstablishment = () => {
        API.Establishments.get(id).then((response) => {
            response.json().then((data) => {
                dispatch({
                    type: ESTABLISHMENT_ACTIONS.GET_ESTABLISHMENT,
                    payload: data,
                })
                addressDispatch({
                    type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                    payload: data.address,
                })
            })
        })
    }

    useEffect(() => {
        if (id != 0) {
            getEstablishment()
        } else {
            dispatch({
                type: ESTABLISHMENT_ACTIONS.SET_LOADER,
                payload: false,
            })
        }
    }, [])

    return (
        <div className="min-height-90-x container mx-auto flex flex-col pb-20 pt-5 text-lg text-gray-800 lg:pb-0 lg:text-sm">
            {establishment.loading ? (
                <Loader />
            ) : (
                <div className="flex w-full flex-col rounded-xl bg-white px-5 py-10 shadow-md">
                    <ManageTitle label={label} />
                    <div className="px-5 lg:px-20">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 ">
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Label"
                                    value={establishment.label}
                                    setValue={(e) =>
                                        dispatch({
                                            type: ESTABLISHMENT_ACTIONS.SET_LABEL,
                                            payload: e.target.value,
                                        })
                                    }
                                    id="label"
                                    placeholder="Saint-Louis"
                                    showLabel={true}
                                    required={true}
                                    className="w-full"
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Téléphone"
                                    value={establishment.phoneNumber}
                                    setValue={(e) =>
                                        dispatch({
                                            type: ESTABLISHMENT_ACTIONS.SET_PHONE_NUMBER,
                                            payload: e.target.value,
                                        })
                                    }
                                    id="phoneNumber"
                                    placeholder="XXXXXXXXXX"
                                    showLabel={true}
                                    className="w-full"
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicTextarea
                                    label="Description"
                                    value={establishment.description}
                                    setValue={(e) =>
                                        dispatch({
                                            type: ESTABLISHMENT_ACTIONS.SET_DESCRIPTION,
                                            payload: e.target.value,
                                        })
                                    }
                                    placeholder=""
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Email"
                                    type="email"
                                    value={establishment.mail}
                                    setValue={(e) =>
                                        dispatch({
                                            type: ESTABLISHMENT_ACTIONS.SET_MAIL,
                                            payload: e.target.value,
                                        })
                                    }
                                    id="phoneNumber"
                                    placeholder="@"
                                    showLabel={true}
                                    className={'w-full'}
                                />
                            </div>
                        </div>
                        <div className="flex w-full flex-col items-center pt-5 lg:flex-row">
                            <AutoComplete
                                address={address}
                                dispatch={addressDispatch}
                                label={'Adresse'}
                            />
                        </div>
                    </div>
                    <div className="fixed right-0 top-0 z-50 mr-5 flex flex flex-col space-y-2 text-gray-700">
                        {errors.map((error) => {
                            return (
                                <Error
                                    key={error.id}
                                    error={error}
                                    dispatch={dispatchError}
                                />
                            )
                        })}
                    </div>
                </div>
            )}
            <SendFormButtons
                historyPush={historyPush}
                handleForm={handleForm}
            />
        </div>
    )
}

export default Manage
