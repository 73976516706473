import SortedGroupedRunsCard from './Mobile/SortedGroupedRunsCard'
import SortedRunCard from './Mobile/SortedRunCard'
import React from 'react'
import { toast } from 'react-toastify'
import { ACTIONS } from '../../../../services/Reducers/PlanningReducer'
import { SortRunsByGroupAndDepartureTime } from '../../../../tools/Sort'
import PcSortedRunCard from './Pc/PcSortedRunCard'

const UnassignedRunsList = ({
    planning,
    totalRunsTeam,
    onInfoClicked,
    dispatch,
    showRunObjectColors,
}) => {
    const onCardClicked = (run) => {
        //unassigned run
        dispatch({
            type: ACTIONS.SET_UNASSIGNED_RUN_SELECTED,
            payload: {
                id: run.id,
                selected: !run.selected,
            },
        })
    }
    return (
        <div className="flex flex-col space-y-8 px-6">
            <div className="flex flex-col space-y-4 lg:space-y-1">
                {planning.unassignedRuns.map((runs) =>
                    SortRunsByGroupAndDepartureTime(runs).map((run) => {
                        return (
                            <div>
                                <div className="block lg:hidden">
                                    <SortedRunCard
                                        run={run}
                                        onCardClicked={onCardClicked}
                                        onInfoClicked={onInfoClicked}
                                        showRunObjectColors={
                                            showRunObjectColors
                                        }
                                    />
                                </div>
                                <div className="hidden lg:block">
                                    <PcSortedRunCard
                                        run={run}
                                        onCardClicked={onCardClicked}
                                        onInfoClicked={onInfoClicked}
                                        showRunObjectColors={
                                            showRunObjectColors
                                        }
                                    />
                                </div>
                            </div>
                        )
                    })
                )}
            </div>
        </div>
    )
}

export default UnassignedRunsList
