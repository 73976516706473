import TimeField from 'react-simple-timefield'
import dayjs from 'dayjs'
import { RUNINFO_ACTIONS } from '../../../services/Reducers/RunInformationReducer'
import classNames from 'classnames'
import SimpleSelectDropDownList from '../../Commons/SimpleSelectDropDownList'
import { Documents } from '../../../services/Documents'
import { PaymentMethods } from '../../../services/PaymentMethods'
import { NumberInput } from '../../Commons/NumberInput'
import { formatPrice } from '../../../tools/Utility'
import TextAreaForms from '../../Commons/FormTextArea'
import DeleteButton from '../../Commons/Buttons/DeleteButton'
import React from 'react'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import { EditPencil } from 'iconoir-react'
import Loader from '../../Commons/Loaders/Loader'
import { formatTimeInput } from '../../../services/dateUtils'

const OptionForm = ({
    dispatch,
    isLoadingSignature,
    runInfo,
    changeRunStatus,
    isEffectiveArrivingTimeBeforeEffectiveDepartureTime,
}) => {
    return (
        <div className="mx-5 mb-5 flex w-11/12 shrink-0 snap-center snap-always flex-col space-y-10 overflow-y-scroll rounded-xl bg-white p-5 shadow-mobile">
            <div className="flex w-full items-center  justify-between pb-5 ">
                <p className="font-bold text-gray-600">Options</p>
                <i className="fas fa-info fa-lg pl-4" />
            </div>
            <div className="flex w-full justify-between">
                <div className="flex flex-col items-center pr-4">
                    <p className="text-lg font-bold text-gray-600">
                        Départ effectif
                    </p>
                    <TimeField
                        value={formatTimeInput(runInfo.effectiveDepartureTime)}
                        onChange={(e) =>
                            dispatch({
                                type: RUNINFO_ACTIONS.SET_EFFECTIVE_DEPARTURE_TIME,
                                payload: e.target.value,
                            })
                        }
                        input={
                            <input
                                className={`h-10 w-full border-b-2 text-center leading-tight hover:border-lighter-blue-green focus:border-lighter-blue-green  focus:outline-none
                                               `}
                            />
                        }
                        colon=":"
                    />
                </div>

                <div className="flex flex-col items-center ">
                    <p className="text-lg font-bold text-gray-600">
                        Arrivée effective
                    </p>
                    <TimeField
                        value={formatTimeInput(runInfo.effectiveArrivingTime)}
                        onChange={(e) =>
                            dispatch({
                                type: RUNINFO_ACTIONS.SET_EFFECTIVE_ARRIVING_TIME,
                                payload: e.target.value,
                            })
                        }
                        input={
                            <input
                                className={classNames(
                                    'h-10 w-full border-b-2 text-center leading-tight',
                                    {
                                        'border-red-500':
                                            isEffectiveArrivingTimeBeforeEffectiveDepartureTime(),
                                        'hover:border-lighter-blue-green focus:border-lighter-blue-green  focus:outline-none':
                                            !isEffectiveArrivingTimeBeforeEffectiveDepartureTime(),
                                    }
                                )}
                            />
                        }
                        colon=":"
                    />
                </div>
            </div>
            <div className="flex flex-col">
                <p className="pb-1 font-bold text-gray-600">Documents</p>
                <SimpleSelectDropDownList
                    options={Documents}
                    value={runInfo.documents}
                    handleOptionChange={(e) => {
                        dispatch({
                            type: RUNINFO_ACTIONS.SET_DOCUMENTS,
                            payload: e,
                        })
                    }}
                    isClearable={false}
                    isSearchable={true}
                />
            </div>
            {runInfo.runObject['@id'].includes('Course') && (
                <div className="flex flex-col ">
                    <p className="pb-1 font-bold text-gray-600">
                        Méthode de paiement
                    </p>
                    <SimpleSelectDropDownList
                        options={PaymentMethods}
                        value={runInfo.paymentMethod}
                        handleOptionChange={(e) => {
                            dispatch({
                                type: RUNINFO_ACTIONS.SET_PAYMENT_METHOD,
                                payload: e,
                            })
                        }}
                        isSearchable={true}
                    />
                    <p className="pb-1 pt-6 font-bold text-gray-600">Montant</p>
                    <div className="flex  items-center  pl-4">
                        <NumberInput
                            value={runInfo.amount}
                            placeholder={'00.00'}
                            handleOnChange={(e) => {
                                dispatch({
                                    type: RUNINFO_ACTIONS.SET_AMOUNT,
                                    payload: formatPrice(
                                        e.target.value,
                                        runInfo.amount
                                    ),
                                })
                            }}
                        />
                        <span>€</span>
                    </div>
                </div>
            )}
            {runInfo.signature ? (
                <div className="mt-4">
                    <p className="mb-2 font-bold text-gray-600">
                        Signature du patient
                    </p>
                    <div className="flex flex-col items-center rounded border border-gray-300">
                        <img
                            src={runInfo.signature}
                            alt="Signature du patient"
                            className="h-40 max-w-full "
                        />
                        <p className="mt-2 text-lg text-gray-600">
                            Signé le {runInfo.signatureDate} à{' '}
                            {runInfo.signatureLocation}
                        </p>
                    </div>
                </div>
            ) : null}
            <SecondaryButton
                label={`${
                    runInfo.signature ? 'Modifier la ' : 'Apposer'
                } signature`}
                action={() =>
                    dispatch({
                        type: RUNINFO_ACTIONS.SHOW_SIGNATURE_FORM,
                        payload: true,
                    })
                }
                fullWidth={true}
                icon={<EditPencil />}
            />
            <div className="flex flex-col ">
                <p className="pb-1 font-bold text-gray-600">Commentaire</p>
                <TextAreaForms
                    value={runInfo.userComments}
                    s
                    placeholder={'Un autre rendez-vous à noter ?'}
                    onChange={(e) => {
                        dispatch({
                            type: RUNINFO_ACTIONS.SET_USER_COMMENTS,
                            payload: e.target.value,
                        })
                    }}
                />
            </div>
            {runInfo.status.id === 'done' && (
                <DeleteButton
                    label="Invalider le transport"
                    title="Invalider le transport"
                    action={changeRunStatus}
                    mobileSize={true}
                />
            )}
        </div>
    )
}

export default OptionForm
