import React, { useEffect, useRef } from 'react'
import { Doughnut } from 'react-chartjs-2'

const DoughnutChart = ({
    labels,
    data,
    backgroundColor,
    hoverBackgroundColor,
    cutout,
    aspectRatio,
}) => {
    const chartData = {
        labels: labels,
        datasets: [
            {
                data: data,
                backgroundColor: backgroundColor,
                hoverBackgroundColor: hoverBackgroundColor,
                cutout: cutout,
            },
        ],
    }

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                display: false,
            },
        },
        aspectRatio: aspectRatio,
    }

    const centerTextPlugin = {
        id: 'centerText',
        afterDatasetsDraw: function (chart) {
            const ctx = chart.ctx

            const word1 = chart.data.datasets[0].data.reduce((a, b) => a + b, 0)
            const word2 = 'Transports'

            ctx.font = '12px Inter'
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            const centerX =
                chart.chartArea.left +
                (chart.chartArea.right - chart.chartArea.left) / 2
            const centerY =
                chart.chartArea.top +
                (chart.chartArea.bottom - chart.chartArea.top) / 2

            ctx.fillText(word1, centerX, centerY - 10) // Décaler légèrement vers le haut
            ctx.fillText(word2, centerX, centerY + 10) // Décaler légèrement vers le bas
        },
    }

    return (
        <div>
            <Doughnut
                data={chartData}
                options={options}
                plugins={[centerTextPlugin]}
            />
        </div>
    )
}

export default DoughnutChart
