import classNames from 'classnames'
import React from 'react'

const PlanningDashboardTimeLine = ({
    dashboard,
    timesIntervalSizeStyle,
    fixedTimes,
}) => {
    return (
        <>
            {dashboard.times.map((time, index) => {
                return (
                    <div
                        key={index}
                        style={timesIntervalSizeStyle()}
                        className={classNames('relative', {
                            'bg-zinc-50 py-2': fixedTimes,
                        })}
                    >
                        <div className={'font-bold'}> {time.label}</div>
                        <div className="ml-1-4rem h-3 border-l-2 border-dark-green" />
                    </div>
                )
            })}
            ,
        </>
    )
}

export default PlanningDashboardTimeLine
