import React from 'react'

const StandardPlanCard = ({ handlePlan, plan }) => {
    return (
        <div
            className="flex flex-col w-full mx-auto rounded-xl drop-shadow-lg bg-white transition duration-150
         ease-in-out transform hover:-translate-y-1 min-h-pricing-card"
        >
            <div className="flex flex-col pt-12 pb-4 px-6">
                <h3 className="text-3xl font-semibold">Standard</h3>
                <div className="text-lg">4 véhicules inclus</div>
                <div className="pt-4">
                    <span className="text-5xl font-semibold pr-2"> 139 €</span>{' '}
                    HT / mois
                </div>
                <button
                    onClick={() => handlePlan(plan)}
                    className="mt-4 font-semibold rounded-lg py-2 w-full text-lg
                        bg-heroad-pink text-center text-white transition duration-150
         ease-in-out transform hover:-translate-y-1"
                >
                    C'est parti !
                </button>
                <div className="pt-4 w-full text-left font-semibold">
                    <ul className="flex flex-col space-y-4">
                        <li>
                            <div className="flex">
                                <i className="fas fa-check fa-sm mr-2 mt-1"></i>
                                <p>Interface de régulation</p>
                            </div>
                        </li>
                        <li>
                            <div className="flex">
                                <i className="fa fa-check fa-sm mr-2 mt-1"></i>
                                <p>
                                    Partage de{' '}
                                    <strong className="font-semibold">
                                        100
                                    </strong>{' '}
                                    courses par mois gratuitement par SMS
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="flex">
                                <i className="fa fa-plus fa-sm mr-2 mt-1"></i>
                                <p>Télétransmission</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default StandardPlanCard
