import React, { useEffect, useState } from 'react'
import API from '../../../services/api'
import NoData from '../NoData'
import EstablishmentCardTable from './EstablishmentCardTable'

const Establishment = (props) => {
    const [rows, setRows] = useState([])
    const [totalItems, setTotalItems] = useState('')
    const [page, setPage] = useState('')

    const formattedData = (data) => {
        return data.map((establishment) => {
            let id = establishment['id']
            let label = establishment['label']
            let phoneNumber = establishment['phoneNumber']
            let mail = establishment['mail']
            let address = `${establishment['address']['street']}, ${
                establishment['address']['secondaryStreet']
                    ? establishment['address']['secondaryStreet'] + ','
                    : ''
            } ${establishment['address']['zipCode']} ${
                establishment['address']['city']
            }`

            return {
                label: label,
                phoneNumber: phoneNumber,
                address: address,
                mail: mail,
                id: id,
            }
        })
    }
    function fetchHospitalList() {
        API.Establishments.list(props.search).then((response) => {
            response.json().then((data) => {
                setRows(formattedData(data['hydra:member']))
                setTotalItems(data['hydra:totalItems'])
            })
        })
    }

    const handleDeleteHospital = (id) => {
        API.Establishments.delete(id).then((response) => {
            fetchHospitalList()
        })
    }

    useEffect(() => {
        fetchHospitalList()
    }, [])

    useEffect(() => {
        fetchHospitalList()
    }, [props.search, page])

    return (
        <div>
            {rows.length > 0 ? (
                <EstablishmentCardTable
                    rows={rows}
                    setPage={setPage}
                    page={page}
                    totalItems={totalItems}
                    handleDeleteElement={handleDeleteHospital}
                    loading={props.loading}
                    lastPage={props.lastPage}
                />
            ) : (
                <NoData message="Aucun établissement ou tuteur renseigné" />
            )}
        </div>
    )
}

export default Establishment
