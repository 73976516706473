// components/MobileConfiguratorDisplayed.jsx
import React, { memo } from 'react'
import Patient from '../App/Regulation/RunListComponents/Patient'
import Vehicle from '../App/Regulation/RunListComponents/Vehicle'
import TimesAndTimeValidation from '../App/Regulation/RunListComponents/TimesAndTimeValidation'
import Times from '../App/Regulation/RunListComponents/Times'
import Status from '../App/Regulation/RunListComponents/Status'
import Team from '../App/Regulation/RunListComponents/Team'
import Return from '../App/Regulation/RunListComponents/Return'
import DepositLocationMobile from '../App/Regulation/RunListComponents/DepositLocationMobile'
import ObjectMobile from '../App/Regulation/RunListComponents/ObjectMobile'
import DateMobileFirstFormat from '../App/Regulation/RunListComponents/DateMobileFirstFormat'
import DateMobileSecondFormat from '../App/Regulation/RunListComponents/DateMobileSecondFormat'
import RunNumberInSeries from '../App/Regulation/RunListComponents/RunNumberInSeries'
import DepartureOrder from '../App/Regulation/RunListComponents/DepartureOrder'

const MOBILE_COMPONENT_MAP = {
    patient: (props) => <Patient {...props} />,
    vehicle: (props) => <Vehicle {...props} />,
    timesAndTimeValidation: (props) => <TimesAndTimeValidation {...props} />,
    timesOnly: (props) => <Times {...props} />,
    depositLocation: (props) => <DepositLocationMobile {...props} />,
    return: (props) => <Return {...props} />,
    object: (props) => <ObjectMobile {...props} />,
    dateFirstFormat: (props) => <DateMobileFirstFormat {...props} />,
    dateSecondFormat: (props) => <DateMobileSecondFormat {...props} />,
    team: (props) => <Team {...props} />,
    status: (props) => <Status {...props} />,
    runNumberInSeries: (props) => <RunNumberInSeries {...props} />,
    departureOrder: (props) => <DepartureOrder {...props} />,
}

const MobileConfiguratorDisplayed = memo(
    ({ componentType, row, isSocietyTaxi }) => {
        const Component = MOBILE_COMPONENT_MAP[componentType]

        if (!Component) {
            console.warn(`No component found for type: ${componentType}`)
            return null
        }

        return <Component row={row} isSocietyTaxi={isSocietyTaxi} />
    }
)

MobileConfiguratorDisplayed.displayName = 'MobileConfiguratorDisplayed'

export default MobileConfiguratorDisplayed
