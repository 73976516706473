import React, { useRef } from 'react'
import { DATE_ACTIONS } from '../../../services/Reducers/DateReducer'
import { hasOneDateParts, isDateValid } from '../../../services/DateService'

export const FormDateInput = ({
    isLunarDate = false,
    isBirthDate = true,
    date,
    dispatch,
    label,
    handleOnChange = null,
}) => {
    const monthInputRef = useRef()
    const yearInputRef = useRef()
    const dayInputRef = useRef()

    const validateDay = (day) => {
        if (day.length > 2) return false
        const dayNum = parseInt(day, 10)
        if (isLunarDate) {
            return (dayNum >= 0 && dayNum <= 99) || day === ''
        } else {
            return (dayNum >= 0 && dayNum <= 31) || day === ''
        }
    }

    const validateMonth = (month) => {
        if (month.length > 2) return false
        const monthNum = parseInt(month, 10)
        if (isLunarDate) {
            return (monthNum >= 0 && monthNum <= 99) || month === ''
        } else {
            return (monthNum >= 0 && monthNum <= 12) || month === ''
        }
    }

    const validateYear = (year) => {
        return year.length <= 4
    }

    const handleDayChange = (event) => {
        if (handleOnChange) {
            handleOnChange()
        }
        const value = event.target.value.replace(/\D+/g, '')
        if (validateDay(value)) {
            dispatch({ type: DATE_ACTIONS.SET_DAY, payload: value })
            if (value.length === 2) {
                monthInputRef.current.focus()
            }
        }
    }

    const handleMonthChange = (event) => {
        if (handleOnChange) {
            handleOnChange()
        }
        const value = event.target.value.replace(/\D+/g, '')
        if (validateMonth(value)) {
            dispatch({ type: DATE_ACTIONS.SET_MONTH, payload: value })
            if (value.length === 2) {
                yearInputRef.current.focus()
            } else if (value.length === 0) {
                dayInputRef.current.focus()
            }
        }
    }

    const handleYearChange = (event) => {
        if (handleOnChange) {
            handleOnChange()
        }
        const value = event.target.value.replace(/\D+/g, '')
        if (validateYear(value)) {
            dispatch({ type: DATE_ACTIONS.SET_YEAR, payload: value })
            if (value.length === 0) {
                monthInputRef.current.focus()
            }
        }
    }

    const handleKeyDown = (event, inputRef) => {
        if (handleOnChange) {
            handleOnChange()
        }
        if (event.keyCode === 8 && inputRef.current.value === '') {
            // code de la touche "backspace" et champ courant est vide
            const inputs = [dayInputRef, monthInputRef, yearInputRef]
            const index = inputs.indexOf(inputRef)
            if (index > 0) {
                // s'il existe un input précédent
                inputs[index - 1].current.focus() // met le focus sur l'input précédent
            }
        }
    }

    const handleFocus = (event) => {
        event.target.select()
    }

    return (
        <div className={'flex w-full flex-col'}>
            {label && (
                <div className="block pb-1 text-base font-bold uppercase  tracking-wide text-gray-700 lg:text-xs">
                    <p>{label}</p>
                </div>
            )}
            <div
                className={`flex h-14 w-full max-w-[18rem] items-center rounded-md border px-2 py-2 text-center lg:h-10 ${
                    hasOneDateParts(date)
                        ? !isDateValid(
                              date,
                              isLunarDate ? false : isBirthDate,
                              isLunarDate
                          )
                            ? 'border-red-500'
                            : 'border-gray-300'
                        : 'border-gray-300'
                }`}
            >
                <input
                    className="date-part focus:outline-none"
                    type="text"
                    value={date.day}
                    onChange={handleDayChange}
                    onFocus={handleFocus}
                    ref={dayInputRef}
                    maxLength={2}
                    inputMode="numeric"
                    placeholder="JJ"
                />
                <i className={'iconoir-slash text-2xl'} />
                <input
                    ref={monthInputRef}
                    className="date-part focus:outline-none"
                    type="text"
                    value={date.month}
                    onChange={handleMonthChange}
                    onKeyDown={(event) => handleKeyDown(event, monthInputRef)}
                    onFocus={handleFocus}
                    maxLength={2}
                    inputMode="numeric"
                    placeholder="MM"
                />
                <i className={'iconoir-slash pl-1 text-2xl'} />
                <input
                    ref={yearInputRef}
                    className="w-16 pl-1 focus:outline-none lg:w-12"
                    inputMode="numeric"
                    value={date.year}
                    onKeyDown={(event) => handleKeyDown(event, yearInputRef)}
                    onChange={handleYearChange}
                    onFocus={handleFocus}
                    maxLength={4}
                    pattern="\d*"
                    placeholder="AAAA"
                />
            </div>
        </div>
    )
}

export default FormDateInput
