export const RunTypes = [
    {
        value: 1,
        label: 'Aller simple',
    },
    {
        value: 2,
        label: 'Retour simple',
    },
    {
        value: 3,
        label: 'Aller-retour direct',
    },
    {
        value: 4,
        label: 'Aller-retour indirect',
    },
]

export default RunTypes
