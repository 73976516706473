import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import PractitionerListComponent from './PractitionerListComponent'
import PractitionerWrapper from './PractitionerWrapper'

const PractitionerContainer = () => {
    const [search, setSearch] = useState('')
    return (
        <Routes>
            <Route path=":id" element={<PractitionerWrapper />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <PractitionerListComponent
                            search={search}
                            setSearch={setSearch}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default PractitionerContainer
