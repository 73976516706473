import React from 'react'
import WelcomeTemplate from './WelcomeTemplate'
import { ReactComponent as Parameters } from '../../../files/parameters.svg'

const WelcomeParameters = ({ welcome, previousStep, nextStep }) => {
    const content = () => {
        return (
            <div className="h-full flex flex-col">
                <div className="flex flex-col space-y-4">
                    <div>
                        Sur Heroad, vous pouvez paramétrer votre compte comme
                        vous le souhaitez. Plusieurs choix s'offrent à vous :
                    </div>
                    <div className="indent-4 flex flex-col space-y-2">
                        <div>
                            <span className="font-bold">1.</span> Vos chauffeurs
                            peuvent visualiser les plannings de leurs collègues
                            ou seulement les leurs.
                        </div>
                        <div>
                            <span className="font-bold">2.</span> Vos chauffeurs
                            peuvent visualiser les transports non assignés.
                        </div>
                        <div>
                            <span className="font-bold">3.</span> Sélectionner
                            uniquement les véhicules dont vous disposez dans
                            votre société.
                        </div>
                        <div>
                            <span className="font-bold">4.</span> Choisir votre
                            type de véhicule que vous utilisez le plus !
                        </div>
                    </div>
                    <div className="flex justify-center py-6">
                        <Parameters />
                    </div>
                    <div className="flex justify-end">À vous de jouer !</div>
                </div>
            </div>
        )
    }
    return (
        <WelcomeTemplate
            nextStep={nextStep}
            previousStep={previousStep}
            content={content()}
            title="Paramètres"
            label="Modifier mes paramètres"
        />
    )
}

export default WelcomeParameters
