import React from 'react'
import { Plus } from 'iconoir-react'
import Select, { components } from 'react-select'
import SecondaryButton from '../Buttons/SecondaryButton'

const SelectDropDownListV2 = ({
    error,
    isMulti,
    isSearchable,
    value,
    isClearable,
    isDisabled = false,
    handleOptionChange,
    id,
    options,
    label,
    handleSearchInputPatientChange,
    icon,
    addOption,
    placeholder,
    addIcon = <Plus />,
}) => {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            boxShadow: 'none',
            border: `1px solid ${error ? '#ef4444' : '#cbd3d3'}`,
            '&:hover': { borderColor: '#80B0C2' },
            borderRadius: '0.375rem',
            cursor: 'pointer',
            borderColor: '#80B0C2',
            display: 'flex',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            backgroundColor: state.isSelected
                ? '#36aba4'
                : state.isFocused
                ? '#bde4dd'
                : 'inherit',
            '&:active': { backgroundColor: '#7AC8C1' },
        }),
    }

    const { Option, SingleValue } = components
    const IconOption = (props) => {
        return (
            <Option {...props}>
                {props.data.icon ? (
                    <div className="flex items-center space-x-2">
                        <div>{props.data.icon}</div>
                        <div>{props.data.label}</div>
                    </div>
                ) : (
                    props.data.label
                )}
            </Option>
        )
    }

    const IconValue = (props) => {
        return (
            <SingleValue {...props}>
                {props.data.icon ? (
                    <div className="flex items-center space-x-2">
                        <div>{props.data.icon}</div>
                        <div>{props.data.label}</div>
                    </div>
                ) : (
                    props.data.label
                )}
            </SingleValue>
        )
    }

    return (
        <div
            className={`relative w-full ${
                addOption && !isDisabled && 'pr-14 lg:pr-14'
            }`}
        >
            <div className="flex items-center space-x-2 pb-2">
                {icon && (
                    <i
                        className={`fa ml-2 ${icon} min-w-icon text-2xl lg:text-lg`}
                    />
                )}
                {label && (
                    <label className="block text-base font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                        {label}
                    </label>
                )}
            </div>
            <Select
                maxMenuHeight={220}
                className="basic-single"
                classNamePrefix="select"
                styles={customStyles}
                isMulti={isMulti}
                isSearchable={isSearchable}
                value={value}
                isClearable={isClearable}
                isDisabled={isDisabled}
                onChange={handleOptionChange}
                noOptionsMessage={() => 'Tapez pour rechercher'}
                name="selectDropdown"
                id={id}
                options={options}
                placeholder={`${placeholder ? placeholder : label}...`}
                onInputChange={handleSearchInputPatientChange}
                isOptionDisabled={(option) => option.isDisabled}
                components={{ Option: IconOption, SingleValue: IconValue }}
            />
            {addOption && !isDisabled && (
                <div
                    className="absolute bottom-0 right-0 flex h-20 w-12 cursor-pointer items-center justify-center pl-2 pt-4 lg:pt-8"
                    onClick={addOption}
                >
                    <div className={'pr-2 pt-2'}>
                        <SecondaryButton
                            title={'Ajouter un patient'}
                            action={addOption}
                            icon={addIcon}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

export default SelectDropDownListV2
