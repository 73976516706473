import React from 'react'
import { ACTIONS } from '../../../services/Reducers/PlanningReducer'
import classNames from 'classnames'
import { User } from 'iconoir-react'

const SelectPlanningTeamsModal = ({
    planning,
    dispatch,
    handleTeamClicked,
    totalRunsTeam,
    groupSelection = false,
}) => {
    const closeTeamsListModal = () => {
        dispatch({ type: ACTIONS.CLOSE_TEAMS_LIST_MODAL })
    }

    return (
        <div className="fixed left-0 bottom-0 z-40 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50 sm:pl-10">
            <div className="min-h-action-modal mx-2 flex w-full flex-col divide-y divide-gray-100 rounded-lg bg-white p-4 md:w-2/3">
                <div className="flex flex-row items-center justify-between pb-4 ">
                    <div className="text-xl font-semibold">
                        {groupSelection
                            ? 'Grouper les transports'
                            : 'Assigner les transports'}
                    </div>
                    <svg
                        className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 18"
                        onClick={closeTeamsListModal}
                    >
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                    </svg>
                </div>
                {planning.teams
                    .filter(
                        (team) =>
                            !team.isPartner &&
                            (!planning.selectedTeam ||
                                team.id !== planning.selectedTeam.id)
                    )
                    .map((team) => (
                        <div
                            className="flex cursor-pointer flex-row flex-wrap items-center justify-between py-4 px-2 pb-2 hover:bg-gray-100"
                            onClick={() => {
                                closeTeamsListModal()
                                handleTeamClicked(team)
                            }}
                        >
                            <div className="flex items-center truncate lg:w-2/4">
                                <User />
                                <span className="truncate px-1 text-lg font-bold md:text-base">
                                    {team.names}
                                </span>
                                <span className="hidden lg:flex">
                                    ({team.vehicle})
                                </span>
                            </div>
                            <div className="flex items-center justify-center md:text-sm lg:w-1/4">
                                {totalRunsTeam(team)} transports
                            </div>
                            <div className="flex flex-row space-x-4 pt-4 md:text-sm lg:w-1/4 lg:flex-col lg:space-x-0 lg:pt-0">
                                <div>
                                    Premier transport : {team.firstRunHour}
                                </div>
                                <div>
                                    Dernier transport : {team.lastRunHour}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}

export default SelectPlanningTeamsModal
