import React from 'react'
import classNames from 'classnames'

const SecondaryButton = ({
    icon = null,
    action,
    label,
    hiddenLabelOnMobile = false,
    active = false,
    title = null,
    disabled = false,
    mobileSize = false,
    fullWidth = false,
    loader = false,
}) => {
    return (
        <button
            className={classNames(
                'flex h-10 flex-row items-center rounded-lg border-2 px-4 py-2 font-semibold',
                {
                    'border-secondary-color text-secondary-color':
                        !active && !disabled,
                    'hover:border-primary-color hover:text-primary-color':
                        !active && !disabled,
                    'border-primary-color text-primary-color': active,
                    'cursor-not-allowed border-disabled-color text-disabled-color':
                        disabled,
                    'space-x-2': label,
                    'h-14 w-full justify-center py-4': mobileSize,
                    'w-full justify-center py-4': fullWidth,
                }
            )}
            title={title}
            onClick={() => action()}
            disabled={disabled}
        >
            {icon}
            <span
                className={classNames('flex items-center', {
                    'hidden sm:flex': hiddenLabelOnMobile && icon,
                })}
            >
                <span className={`${loader && 'pr-2'}`}>{label}</span>
                {loader && (
                    <svg
                        className="h-5 w-5 animate-spin "
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                        ></circle>
                        <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                    </svg>
                )}
            </span>
        </button>
    )
}

export default SecondaryButton
