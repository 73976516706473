import React from 'react'

const RunNumberInSeries = ({ row, isComputer }) => {
    const RunNumberInSeriesDisplayed = () => {
        if (row.series && row.seriesIndex && row.series.nbRuns) {
            return `${row.seriesIndex}/${row.series.nbRuns}`
        }
        return ''
    }

    return (
        <div
            className={`flex w-1/12 justify-center font-bold ${
                isComputer ? 'text-base' : 'text-xs'
            }`}
        >
            {RunNumberInSeriesDisplayed()}
        </div>
    )
}

export default RunNumberInSeries
