import React from 'react'
import SecondaryButton from '../Commons/Buttons/SecondaryButton'
import DeleteButton from '../Commons/Buttons/DeleteButton'

const ActionModal = ({
    setModalOpen,
    label,
    warningLabel,
    action,
    labelAction,
    actionButton,
    secondaryActionButton = 'Annuler',
    secondaryAction = () => setModalOpen(false),
}) => {
    let color
    if (
        actionButton === 'Activer' ||
        actionButton === 'Continuer' ||
        actionButton === 'Grouper'
    ) {
        color = 'green'
    } else {
        color = 'red'
    }
    return (
        <div className="modal fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="min-h-action-modal mx-2 flex flex-col items-center rounded-lg bg-white p-2 text-lg sm:mx-0 sm:w-1/3 lg:text-base">
                <div className="flex h-full w-full flex-col p-2 text-xl">
                    <div className="flex justify-between font-bold text-gray-700">
                        <div>{labelAction}</div>
                        <svg
                            className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() => setModalOpen(false)}
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                </div>
                <div className="my-auto flex w-full flex-col p-2">
                    {warningLabel && warningLabel !== '' && (
                        <div className="pb-1 text-red-500">
                            <i className="fas fa-exclamation-circle pr-2" />
                            {warningLabel}
                        </div>
                    )}
                    <p>{label}</p>
                </div>
                <div className="mt-auto flex w-full justify-between">
                    <SecondaryButton
                        title={secondaryActionButton}
                        label={secondaryActionButton}
                        hiddenLabelOnMobile={false}
                        action={secondaryAction}
                    />
                    <DeleteButton
                        title={actionButton}
                        label={actionButton}
                        action={action}
                        hiddenLabelOnMobile={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default ActionModal
