import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import API from '../services/api'
import { Partner } from '../services/API/Entities/partner'
import { User } from '../services/API/Entities/users'
import { Patient as Patients } from '../services/API/Entities/patient'

export const FILTER_QUERY_KEYS = {
    PATIENTS: 'patients',
    PARTNERS: 'partners',
    USERS: 'users',
    TYPES: 'types',
    OBJECTS: 'runObjects',
    STATUSES: 'statuses',
}

const formatOptions = (list) => {
    return list.map((option) => ({
        value: option['@id'],
        label: option.label || `${option.firstname} ${option.lastname}`,
        data: option,
    }))
}

export const useRegulationPopUpQueries = () => {
    const [searchTerm, setSearchTerm] = useState('')
    const [debouncedSearch] = useDebounce(searchTerm, 500)

    const patientsQuery = useQuery({
        queryKey: [FILTER_QUERY_KEYS.PATIENTS, debouncedSearch],
        queryFn: async () => {
            if (!debouncedSearch) return []
            return await Patients.fetchAllForDebounceSearch(debouncedSearch)
        },
        enabled: !!debouncedSearch,
    })

    // Partners Query
    const partnersQuery = useQuery({
        queryKey: [FILTER_QUERY_KEYS.PARTNERS],
        queryFn: async () => {
            const response = await Partner.fetchAll()
            return formatOptions(response)
        },
    })

    // Users Query
    const usersQuery = useQuery({
        queryKey: [FILTER_QUERY_KEYS.USERS],
        queryFn: async () => {
            const response = await User.fetchAll()
            return formatOptions(response)
        },
    })

    // Types Query
    const typesQuery = useQuery({
        queryKey: [FILTER_QUERY_KEYS.TYPES],
        queryFn: async () => {
            const response = await API.Types.list()
            const data = await response.json()
            return formatOptions(data['hydra:member'])
        },
    })

    // Objects Query
    const objectsQuery = useQuery({
        queryKey: [FILTER_QUERY_KEYS.OBJECTS],
        queryFn: async () => {
            const response = await API.RunObject.list()
            const data = await response.json()
            return formatOptions(data['hydra:member'])
        },
    })

    // Statuses Query
    const statusesQuery = useQuery({
        queryKey: [FILTER_QUERY_KEYS.STATUSES],
        queryFn: async () => {
            const response = await API.Status.list()
            const data = await response.json()
            return formatOptions(data['hydra:member'])
        },
    })

    return {
        patientsQuery,
        partnersQuery,
        usersQuery,
        typesQuery,
        objectsQuery,
        statusesQuery,
        setSearchTerm,
        searchTerm,
    }
}
