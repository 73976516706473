import React, { useState } from 'react'
import { ACTIONS } from '../../../services/Reducers/WelcomeModalReducer'
import API from '../../../services/api'
import WelcomeTitle from '../../Commons/WelcomeTitle'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { Car, User } from 'iconoir-react'
import SelectDropDownListV3 from '../../Commons/DropDownLists/SelectDropDownListV3'

const WelcomeTeamManage = ({
    welcome,
    dispatch,
    previousStep,
    nextStep,
    handleLoading,
}) => {
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)

    const handleDate = (value) => {
        dispatch({ type: ACTIONS.SET_DATE, payload: value })
    }

    const handleVehicle = (value) => {
        dispatch({ type: ACTIONS.SET_TEAM_VEHICLE, payload: value })
    }

    const handleUser = (value) => {
        dispatch({ type: ACTIONS.SET_TEAM_USER, payload: value })
    }

    const handleTeamId = (team) => {
        dispatch({ type: ACTIONS.SET_TEAM_ID, payload: team })
    }

    const handleAddTeam = () => {
        handleLoading(true)
        const data = {
            vehicle: welcome.team.selectedVehicle.value,
            pm: true,
            users: [welcome.team.user.value],
        }

        if (!welcome.team['id']) {
            API.DefaultMasterRuns.postFromWelcomeModal(data)
                .then((teamData) => {
                    handleLoading(false)
                    handleTeamId(teamData['masterRun'])
                    nextStep()
                })
                .catch((error) => {})
        } else {
            API.DefaultMasterRuns.putFromWelcomeModal(
                welcome.team.id
                    ? welcome.team.id
                    : welcome.team['@id'].split('/')[2],
                data
            )
                .then((teamData) => {
                    handleLoading(false)
                    handleTeamId(teamData['masterRun'])
                    nextStep()
                })
                .catch((error) => {})
        }
    }

    return (
        <div className="">
            <div className="width-child-container-welcome-modal flex flex-col rounded-xl bg-white px-4 pt-6 shadow-md lg:px-16">
                <WelcomeTitle label="Nouvelle équipe par défaut" />
                <div className="flex flex-col space-y-6 pt-6">
                    <div className="w-3/4 lg:w-1/2">
                        <SelectDropDownListV3
                            icon={<Car />}
                            id="ambu"
                            label="Assigner un véhicule"
                            options={welcome.vehicleOptions}
                            value={welcome.team.selectedVehicle}
                            handleOptionChange={(e) => handleVehicle(e)}
                            isClearable={false}
                            isSearchable={false}
                            isDisabled={false}
                            showLabel={true}
                        />
                    </div>
                    <div className="w-3/4 lg:w-1/2">
                        <SelectDropDownListV3
                            maxMenuHeight={
                                window.innerHeight < 1000 ? 180 : 220
                            }
                            icon={<User />}
                            id="user1"
                            label="Chauffeur"
                            options={welcome.userOptions}
                            value={welcome.team.user}
                            handleOptionChange={(e) => handleUser(e)}
                            isClearable={false}
                            isSearchable={false}
                            showLabel={true}
                            isDisabled={false}
                        />
                    </div>
                </div>
            </div>
            <div className="flex w-full items-center justify-between py-4">
                <SecondaryButton
                    label="Précédent"
                    title="Précédent"
                    action={previousStep}
                    hiddenLabelOnMobile={false}
                />
                <PrimaryButton
                    label="Suivant"
                    title="Suivant"
                    action={handleAddTeam}
                    hiddenLabelOnMobile={false}
                    loader={welcome.loading}
                />
            </div>
        </div>
    )
}

export default WelcomeTeamManage
