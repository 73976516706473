import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import React, { useState } from 'react'
import SecondaryButton from '../Commons/Buttons/SecondaryButton'
import { useMutation } from '@tanstack/react-query'

const API_ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT

const checkUserUsernameExistsAndFirstConnection = async ({ username }) => {
    const response = await fetch(
        `${API_ENTRYPOINT}/check_username_exists_and_first_connection`,
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username }),
        }
    )

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
    }

    return await response.json()
}

const choosePassword = async ({ username, password }) => {
    const response = await fetch(`${API_ENTRYPOINT}/registration`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
    })

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
    }

    return await response.json()
}

export const SignInFirstConnection = ({
    passwordRef,
    password,
    setPassword,
    passwordShown,
    setPasswordShown,
    error,
    setError,
    handleLogin,
    loading,
    username,
    usernameRef,
    setUsername,
    setFirstConnection,
}) => {
    const [choosePasswordView, setChoosePasswordView] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const confirmPasswordRef = React.useRef(confirmPassword)

    const {
        mutate: checkUsernameMutate,
        isPending: checkedUsernameIsPending,
        error: checkUsernameError,
        data: checkUsernameData,
    } = useMutation({
        mutationFn: checkUserUsernameExistsAndFirstConnection,
        mutationKey: 'checkUserUsernameExists',
        onSuccess: (data) => {
            // Actions à effectuer en cas de succès
            console.log('DATA', data)
            if (data.exists) {
                setChoosePasswordView(true)
            } else {
                setError(
                    'Aucun nouvel utilisateur trouvé avec cet identifiant. Veuillez réessayer.'
                )
            }
        },
        onError: (error) => {
            // Actions à effectuer en cas d'échec
            setError(error)
            console.error('Erreur lors de la mutation:', error)
        },
    })

    const {
        mutate: choosePasswordMutate,
        isPending: choosePasswordIsPending,
        error: choosePasswordError,
        data: choosePasswordData,
    } = useMutation({
        mutationFn: choosePassword,
        mutationKey: 'choosePassword',
        onSuccess: () => {
            handleLogin()
        },
        onError: (error) => {
            // Actions à effectuer en cas d'échec
            setError(error)
            console.error('Erreur lors de la mutation:', error)
        },
    })

    const firstSignInAction = () => {
        setError('')
        if (choosePasswordView) {
            if (password !== confirmPassword) {
                setError('Les mots de passe ne correspondent pas.')
                return
            } else if (password.length < 8) {
                setError('Le mot de passe doit contenir au moins 8 caractères')
                return
            }
            handleUserPassword()
        } else {
            if (username === '') {
                setError('Veuillez renseigner un identifiant.')
            } else {
                checkUserUsernameExists()
            }
        }
    }
    function checkUserUsernameExists() {
        checkUsernameMutate({ username: username })
    }

    function handleUserPassword() {
        choosePasswordMutate({ username: username, password })
    }

    return (
        <div>
            <div className="pb-8 text-3xl font-bold lg:text-2xl">
                {choosePasswordView
                    ? 'Choisissez votre mot de passe'
                    : 'Votre identifiant'}
            </div>
            {!choosePasswordView ? (
                <div className="mb-6 text-xl lg:text-base">
                    <label className="mb-2 block font-bold" htmlFor="username">
                        Email ou numéro de téléphone
                    </label>
                    <input
                        className={`block h-12 w-full appearance-none rounded border pl-4 leading-tight focus:border-lighter-blue-green focus:outline-none lg:h-10
                     ${error ? 'border-red-500' : ''}`}
                        id="username"
                        type="username"
                        name="username"
                        value={username}
                        onChange={(e) => {
                            usernameRef.current = e.target.value
                            setUsername(e.target.value)
                        }}
                    />
                </div>
            ) : (
                <>
                    <div className="mb-4 text-xl lg:text-base">
                        <label
                            className="mb-2 block  font-bold"
                            htmlFor="password"
                        >
                            Mot de passe
                        </label>
                        <input
                            value={password}
                            onChange={(e) => {
                                passwordRef.current = e.target.value
                                setPassword(e.target.value)
                            }}
                            className={`block h-12 w-full appearance-none rounded border pl-4 leading-tight focus:border-lighter-blue-green focus:outline-none lg:h-10
                   ${error ? 'border-red-500' : ''}`}
                            id="password"
                            type={passwordShown ? 'text' : 'password'}
                            name="password"
                        />
                    </div>
                    <div className="mb-4 text-xl lg:text-base">
                        <label
                            className="mb-2 block  font-bold"
                            htmlFor="password"
                        >
                            Confirmer le mot de passe
                        </label>
                        <input
                            value={confirmPassword}
                            onChange={(e) => {
                                confirmPasswordRef.current = e.target.value
                                setConfirmPassword(e.target.value)
                            }}
                            className={`block h-12 w-full appearance-none rounded border pl-4 leading-tight focus:border-lighter-blue-green focus:outline-none lg:h-10
                   ${error ? 'border-red-500' : ''}`}
                            id="password"
                            type={passwordShown ? 'text' : 'password'}
                            name="confirmPassword"
                        />
                    </div>
                    <div
                        className="flex pb-8"
                        onClick={() => setPasswordShown(!passwordShown)}
                    >
                        <input
                            type="checkbox"
                            className=""
                            checked={passwordShown}
                        />
                        <p className="pl-2 text-xl lg:text-base">
                            Afficher les mots de passe
                        </p>
                    </div>
                </>
            )}
            <div className="pb-2 text-xl font-bold text-red-500 lg:text-base">
                {error && <p className="py-1">{error}</p>}
            </div>
            <div className="mb-5 flex gap-6">
                <div className="w-1/2">
                    <SecondaryButton
                        action={() => {
                            if (!choosePasswordView) {
                                setFirstConnection(false)
                                setError('')
                            } else {
                                setChoosePasswordView(false)
                                setError('')
                            }
                        }}
                        label="Retour"
                        title="Retour"
                        hiddenLabelOnMobile={false}
                        fullWidth={true}
                    />
                </div>
                <div className="w-1/2">
                    <PrimaryButton
                        action={firstSignInAction}
                        label="Continuer"
                        title="Se connecter"
                        loader={checkedUsernameIsPending}
                        hiddenLabelOnMobile={false}
                        fullWidth={true}
                    />
                </div>
            </div>
        </div>
    )
}
