import React from 'react'
import { AddressItem } from './AddressItem'
import PrimaryButton from './Buttons/PrimaryButton'
import { Home, HomeUser, HouseRooms, Plus } from 'iconoir-react'

const PatientAddresses = ({ patient, dispatch, PATIENT_ACTIONS }) => {
    return (
        <div className="col-span-2 pb-4 pt-4">
            <div className="flex w-full justify-between border-b-2">
                <div className="flex items-center pb-5 text-xl font-bold">
                    <h2 className={'mr-2'}>Adresses enregistrées</h2>
                    <Home />
                </div>
                <PrimaryButton
                    label="Adresse"
                    title="Adresse"
                    action={() => {
                        dispatch({
                            type: PATIENT_ACTIONS.ADD_ADDRESS,
                        })
                    }}
                    icon={<Plus className="text-xl" />}
                />
            </div>
            {patient.patientsAddresses?.length > 0 ? (
                <div className="flex w-full flex-col">
                    {patient.patientsAddresses.map((patientAddress, index) => (
                        <div key={index} className="flex w-full flex-col py-4">
                            <AddressItem
                                patientAddress={patientAddress}
                                dispatch={dispatch}
                                index={index}
                                PATIENT_ACTIONS={PATIENT_ACTIONS}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <p className="flex w-full italic">
                    Ce {patient.isPatient ? 'patient ' : 'client '} n'a pas
                    encore d'adresse enregistrée
                </p>
            )}
        </div>
    )
}

export default PatientAddresses
