export const ADDRESS_ACTIONS = {
    CLICK_ON_PREDICTION: 'ADD_ADDRESS',
    UPDATE_ADDRESS_FIELD: 'UPDATE_ADDRESS_FIELD',
    SHOW_PREDICTIONS: 'SHOW_PREDICTIONS',
    DELETE_ADDRESS: 'DELETE_ADDRESS',
    UPDATE_STREET: 'UPDATE_STREET',
    UPDATE_SECONDARY_STREET: 'UPDATE_SECONDARY_STREET',
    UPDATE_CITY: 'UPDATE_CITY',
    UPDATE_COUNTY: 'UPDATE_COUNTY',
    UPDATE_ZIP_CODE: 'UPDATE_ZIP_CODE',
    UPDATE_LABEL: 'UPDATE_LABEL',
    SET_ADDRESS: 'SET_ADDRESS',
    UPDATE_INSEE: 'UPDATE_INSEE',
}

export const getAddressInitialState = ({ addressItem }) => {
    if (addressItem) {
        return {
            ...addressItem,
            inputValue: formatAddressLabel(addressItem),
            showPredictions: false,
            addressHasChanged: false,
        }
    } else {
        return {
            inputValue: '',
            label: '',
            city: '',
            zipCode: '',
            finalStreet: '',
            county: '',
            country: '',
            administrative: '',
            showPredictions: false,
            secondaryStreet: '',
            street: '',
            addressHasChanged: false,
            codeInsee: '',
        }
    }
}

export const formatAddressLabel = (state) => {
    if (state) {
        if (state.label === state.city) {
            state.label = ''
        }

        const { label, street, secondaryStreet, city, zipCode } = state
        return [label, street, secondaryStreet, city, zipCode]
            .filter(Boolean)
            .join(', ')
    } else {
        return ''
    }
}

export const AddressAutoCompleteReducer = (state = {}, action) => {
    switch (action.type) {
        case ADDRESS_ACTIONS.CLICK_ON_PREDICTION:
            return {
                ...action.payload,
                inputValue: formatAddressLabel(action.payload),
                showPredictions: false,
                addressHasChanged: false,
            }
        case ADDRESS_ACTIONS.UPDATE_ADDRESS_FIELD:
            return {
                ...state,
                inputValue: action.payload,
                showPredictions: true,
                addressHasChanged: formatAddressLabel(state) !== action.payload,
            }
        case ADDRESS_ACTIONS.SHOW_PREDICTIONS:
            return {
                ...state,
                showPredictions: action.payload,
            }
        case ADDRESS_ACTIONS.DELETE_ADDRESS:
            return {
                inputValue: '',
                label: '',
                city: '',
                street: '',
                zipCode: '',
                finalStreet: '',
                county: '',
                country: '',
                administrative: '',
                showPredictions: false,
                secondaryStreet: '',
                codeInsee: '',
            }
        case ADDRESS_ACTIONS.UPDATE_STREET:
            state.street = action.payload
            return {
                ...state,
                street: action.payload,
                inputValue: formatAddressLabel(state),
            }
        case ADDRESS_ACTIONS.UPDATE_SECONDARY_STREET:
            state.secondaryStreet = action.payload
            return {
                ...state,
                secondaryStreet: action.payload,
                inputValue: formatAddressLabel(state),
            }
        case ADDRESS_ACTIONS.UPDATE_CITY:
            state.city = action.payload
            return {
                ...state,
                city: action.payload,
                inputValue: formatAddressLabel(state),
            }
        case ADDRESS_ACTIONS.UPDATE_COUNTY:
            state.county = action.payload
            return {
                ...state,
                county: action.payload,
                inputValue: formatAddressLabel(state),
            }
        case ADDRESS_ACTIONS.UPDATE_ZIP_CODE:
            state.zipCode = action.payload
            return {
                ...state,
                zipCode: action.payload,
                inputValue: formatAddressLabel(state),
            }
        case ADDRESS_ACTIONS.UPDATE_LABEL:
            state.label = action.payload
            return {
                ...state,
                label: action.payload,
                inputValue: formatAddressLabel(state),
            }
        case ADDRESS_ACTIONS.UPDATE_INSEE:
            state.codeInsee = action.payload
            return {
                ...state,
                codeInsee: action.payload,
                inputValue: formatAddressLabel(state),
            }
        default:
            return state
    }
}
