import { concat } from '../pathsAndFilters'
import { formatHydraData } from '../../api'
import { apiClient, withErrorHandling } from '../apiClient'
import { Practitioners } from './practitioners'
import { toast } from 'react-toastify'

const PATH_KEY = '/free_invoices'
export const FreeInvoice = {
    fetchAll: async (search, status, archived, isQuote) => {
        let path = PATH_KEY + '?pagination=false'
        if (search) path += `&search=${search}`
        if (!status) {
            if (archived) {
                path += '&archived=true'
            } else {
                path = concat(path, 'archived=false')
            }
        }
        if (isQuote) {
            path += '&isInvoice=false'
        } else {
            path += '&isInvoice=true'
        }
        return formatHydraData(await apiClient.get(path))
    },
    fetchOne: async (id) => {
        return formatHydraData(await apiClient.get(`${PATH_KEY}/${id}`))
    },
    post: withErrorHandling(async (freeInvoiceForm) => {
        return await apiClient.post(`${PATH_KEY}/create`, freeInvoiceForm)
    }),
    put: withErrorHandling(async (id, freeInvoiceForm) => {
        return await apiClient.put(`${PATH_KEY}/${id}`, freeInvoiceForm)
    }),
    delete: withErrorHandling(async (id) => {
        return await apiClient.delete(`${PATH_KEY}/${id}`)
    }),
    updateStatus: withErrorHandling(async (params) => {
        const { id, status } = params
        return await apiClient.put(`${PATH_KEY}/status/${id}`, { status })
    }),
    updateArchive: withErrorHandling(async (id) => {
        return await apiClient.put(`${PATH_KEY}/archive/${id}`)
    }),
}

export const handleFreeInvoiceForm = async (freeInvoiceForm) => {
    try {
        if (freeInvoiceForm.id) {
            const data = await FreeInvoice.put(
                freeInvoiceForm.id,
                freeInvoiceForm
            )
            toast.success('Facture modifiée avec succès')
            return data
        } else {
            const data = await FreeInvoice.post(freeInvoiceForm)
            toast.success('Facture ajoutée avec succès')
            return data
        }
    } catch (error) {
        // L'erreur est déjà gérée dans les fonctions post et put
        throw error
    }
}
