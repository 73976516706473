import React, { useEffect, useRef, useState } from 'react'
import API from '../../services/api'
import Loader from '../Commons/Loaders/Loader'
import { toast } from 'react-toastify'
import PushNotif from '../PushNotif'

const Profile = () => {
    const [userRole, setUserRole] = useState('')
    const [user, setUser] = useState({})

    const [password, setPassword] = useState('')
    const [passwordValidation, setPasswordValidation] = useState('')
    const passwordRef = useRef(password)
    const passwordConfirmRef = useRef(passwordValidation)
    const [passwordShown, setPasswordShown] = useState(false)

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')

    const getUser = () => {
        setLoading(true)
        API.Users.get(localStorage.getItem('id')).then((response) => {
            response.json().then((data) => {
                setUser(data)
                if (data['roles'].some((r) => r === 'ROLE_ADMIN')) {
                    setUserRole('Administrateur')
                } else {
                    setUserRole('Chauffeur')
                }
                setLoading(false)
            })
        })
    }

    const handleForm = () => {
        setError('')
        const data = {
            plainPassword: password,
        }
        if (password.length < 8) {
            setError('Votre mot de passe doit faire au moins 8 charactères.')
        } else if (!(password === passwordValidation)) {
            setError('Les mots de passe doivent être identiques.')
        } else {
            API.Users.put(localStorage.getItem('id'), data)
                .then((response) => {
                    toast.success('Le mot de passe a bien été changé.')
                })
                .catch((error) => setError(error))
        }
    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className="py-10 px-2 text-lg lg:text-base">
                    <div className="flex flex-col space-y-5 pb-10">
                        <div className="rounded-xl bg-white p-5 shadow-md lg:p-10 ">
                            <div className="flex items-center pb-5">
                                <i className="iconoir-profile-circle pr-2 text-4xl " />
                                <p className="text-xl ">Mes informations</p>
                            </div>
                            <div className="pl-2 text-xl lg:text-base">
                                <div className="font-bold capitalize">
                                    {user.firstname} {user.lastname}
                                </div>
                                <div className="pb-2 italic">{userRole}</div>
                                <div className=" pb-2">{user.phoneNumber}</div>
                                <div className=" pb-10">{user.email}</div>
                            </div>
                        </div>
                        <div className="rounded-xl bg-white p-5 shadow-md lg:p-10 ">
                            <div className="flex items-center pb-5">
                                <i className="iconoir-lock pr-2 text-4xl " />
                                <p className="text-xl ">
                                    Changement de mot de passe
                                </p>
                            </div>
                            <div className="mb-4 max-w-sm pl-2">
                                <label
                                    className="mb-2 block text-base font-bold text-gray-600 lg:text-sm"
                                    htmlFor="password"
                                >
                                    Nouveau
                                </label>
                                <input
                                    value={password}
                                    onChange={(e) => {
                                        passwordRef.current = e.target.value
                                        setPassword(e.target.value)
                                    }}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-600 focus:border-lighter-blue-green focus:outline-none
                   ${error ? 'border-red-500' : ''}`}
                                    id="password"
                                    type={passwordShown ? 'text' : 'password'}
                                    name="password"
                                />
                            </div>
                            <div className="mb-4 max-w-sm pl-2">
                                <label
                                    className="mb-2 block text-base font-bold text-gray-600 lg:text-sm"
                                    htmlFor="password"
                                >
                                    Confirmation
                                </label>
                                <input
                                    value={passwordValidation}
                                    onChange={(e) => {
                                        passwordConfirmRef.current =
                                            e.target.value
                                        setPasswordValidation(e.target.value)
                                    }}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-600 focus:border-lighter-blue-green focus:outline-none
                   ${error ? 'border-red-500' : ''}`}
                                    id="password"
                                    type={passwordShown ? 'text' : 'password'}
                                    name="password"
                                />
                            </div>
                            <div
                                className="flex pb-8 pl-2"
                                onClick={() => setPasswordShown(!passwordShown)}
                            >
                                <input
                                    type="checkbox"
                                    className=""
                                    checked={passwordShown}
                                />
                                <p className="pl-2 text-base lg:text-sm">
                                    Afficher
                                </p>
                            </div>

                            <div className="pb-2 pl-2 text-base font-bold text-red-500 lg:text-sm">
                                {error && <p className="py-1">{error}</p>}
                            </div>
                            <div className="w-full pl-2 lg:max-w-sm">
                                <button
                                    className={`w-full rounded bg-light-dark-green px-4 py-2 font-bold text-white hover:bg-dark-green`}
                                    onClick={handleForm}
                                >
                                    Modifier le mot de passe
                                </button>
                            </div>
                        </div>
                        <div className="rounded-xl bg-white p-5 shadow-md lg:p-10 ">
                            <div className="flex items-center pb-5">
                                <i className={`iconoir-bell pr-2 text-4xl  `} />
                                <p className="text-xl ">
                                    Gestion des notifications
                                </p>
                            </div>
                            <div className={'p-2'}>
                                <div className="rounded-lg border-l-4 border-blue-400 bg-blue-100 p-4 shadow-md">
                                    <div className="flex items-center">
                                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                                            <div>
                                                <h2 className="mb-2 text-lg font-medium">
                                                    Conditions pour recevoir une
                                                    notification push :
                                                </h2>
                                                <ul className="list-inside list-disc">
                                                    <li className="mb-2">
                                                        Autoriser les pop-up de
                                                        notifications
                                                    </li>
                                                    <li className="mb-2">
                                                        Avoir l'état
                                                        <span className="rounded-full pl-2 font-bold  text-green-500">
                                                            Actives
                                                        </span>
                                                    </li>
                                                    <li className="mb-2">
                                                        Bien recevoir la
                                                        notification de
                                                        bienvenue sur tous les
                                                        appareils connectés
                                                    </li>
                                                    <li className="mb-2">
                                                        Android et iPhone :
                                                        Utiliser obligatoirement
                                                        l'application mobile
                                                        téléchargée sur l'écran
                                                        d'accueil
                                                    </li>
                                                    <li className="mb-2">
                                                        iPhone : Installer la
                                                        version iOS 16.4 ou une
                                                        version ultérieure et
                                                        avoir Safari à jour
                                                    </li>
                                                    <li className="mb-2">
                                                        Android : Avoir votre
                                                        navigateur à jour
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <h2 className="mb-2 text-lg font-medium">
                                                    Tests en conditions réelles
                                                    :
                                                </h2>
                                                <ul className="list-inside list-disc">
                                                    <li className="mb-2">
                                                        Une notification
                                                        apparaît uniquement si
                                                        un changement
                                                        d'affectation est
                                                        effectué pour le jour
                                                        actuel
                                                    </li>
                                                    <li className="mb-2">
                                                        Les notifications ne
                                                        s'envoient qu'à la
                                                        personne à qui est
                                                        affecté/désaffecté un
                                                        transport
                                                    </li>
                                                    <li className="mb-2">
                                                        Une assignation d'un
                                                        transport à soi-même ne
                                                        crée pas de notification
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'flex items-center pt-4'}>
                                        <a className="iconoir-warning-triangle pr-2 text-2xl" />
                                        <p>
                                            N'oubliez pas d'activer manuellement
                                            les notifications sur chaque nouvel
                                            appareil.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="pl-2 pt-4">
                                <PushNotif />
                                <div className="pt-2 text-base font-bold text-red-500 lg:text-sm "></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Profile
