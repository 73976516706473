import React, { useEffect, useState } from 'react'
import API from '../../../services/api'
import NoData from '../NoData'
import Loader from '../../Commons/Loaders/Loader'
import useConfiguratorStore from '../../../stores/Configurator'
import PatientCardTable from './PatientCardTable'
import { toast } from 'react-toastify'

const Patient = ({ search, page, setPage }) => {
    const [rows, setRows] = useState([])
    const [totalItems, setTotalItems] = useState(0)
    const [firstLoading, setFirstLoading] = useState(true)
    const [loading, setLoading] = useState(true)
    const formatPatients = (patients) => {
        return patients.map((patient) => {
            const address = patient.patientsAddresses
            const defaultAddress = address.find((a) => a.defaultAddress)
            return {
                ...patient,
                defaultAddress,
            }
        })
    }

    function fetchPatientsList() {
        setLoading(true)
        API.Patients.list(search, page, null, 50).then((response) => {
            response.json().then((data) => {
                const formattedPatients = formatPatients(data['hydra:member'])
                setRows(formattedPatients)
                setTotalItems(data['hydra:totalItems'])
                setLoading(false)
                setFirstLoading(false)
            })
        })
    }

    useEffect(() => {
        fetchPatientsList()
    }, [search, page])

    const handleDeletePatient = (id) => {
        API.Patients.delete(id)
            .then((response) => {
                toast.success('Fiche supprimée.')
                fetchPatientsList()
            })
            .catch((error) => {
                toast.warn(
                    'Impossible de supprimer la fiche car elle a été utilisé dans au moins un transport.'
                )
            })
    }

    return (
        <div>
            {firstLoading ? (
                <Loader />
            ) : rows.length > 0 ? (
                <PatientCardTable
                    rows={rows}
                    totalItems={totalItems}
                    handleDeleteElement={handleDeletePatient}
                    loading={loading}
                    setPage={setPage}
                    page={page}
                />
            ) : (
                <NoData message="Aucune fiche disponible" />
            )}
        </div>
    )
}

export default Patient
