import React, { useEffect } from 'react'
import { InfoCircle } from 'iconoir-react'
import classNames from 'classnames'
import { RunObjectColors } from '../../../../../services/RunObjectColors'

// MOBILE
const ShuffledRunCard = ({
    run,
    onCardClicked,
    onInfoClicked,
    fromGlobalView = false,
    showRunObjectColors,
}) => {
    return (
        <div
            className={classNames(
                `sorted-run-car-grid grid rounded-lg border-l-4 shadow-lg ${run.statusBorderColor}`,
                {
                    stripeBg: run.partner && run.isGiven,
                    stripeBg_canceled:
                        run.status.id === 'canceled' && !run.selected,
                    'bg-white text-gray-800':
                        (!run.selected && !showRunObjectColors) ||
                        fromGlobalView,
                    'bg-light-dark-green text-white':
                        run.selected && !fromGlobalView,
                    [`${RunObjectColors[run.runObject.color]}`]:
                        showRunObjectColors && !run.selected,
                }
            )}
        >
            <div
                className="col-span-1 flex flex-row items-center py-4"
                onClick={() => {
                    if (!fromGlobalView) {
                        onCardClicked(run)
                    }
                }}
            >
                <div className="flex w-full flex-row">
                    <div className="flex w-2/5 flex-col pl-4 text-lg">
                        <div className="">{run.departureTimeHour}</div>
                        <div className="uppercase">
                            {run.masterRun ? (
                                <span
                                    className="rounded-full px-2 py-1 font-bold text-white"
                                    style={{
                                        backgroundColor: run.masterRun
                                            ? run.masterRun.vehicle.color
                                            : '#6B7280',
                                    }}
                                >
                                    {run.masterRun.users[0].firstname.substring(
                                        0,
                                        1
                                    )}
                                    {run.masterRun.users[0].lastname.substring(
                                        0,
                                        1
                                    )}
                                </span>
                            ) : run.partner && run.isGiven ? (
                                <span className="pl-2 font-bold ">
                                    {run.partner.firstname.substring(0, 1)}
                                    {run.partner.lastname.substring(0, 1)}
                                </span>
                            ) : (
                                <span className="pl-2">N/A</span>
                            )}
                        </div>
                        <div className="">{run.arrivingTimeHour}</div>
                    </div>
                    <span className="w-/5 flex items-center truncate font-bold">
                        {run.patientName}
                    </span>
                </div>
            </div>
            {!fromGlobalView && (
                <div
                    className="col-span-1 flex w-full items-center justify-center py-6 pr-4"
                    onClick={() => {
                        onInfoClicked(run)
                    }}
                >
                    <div className="text-3xl">
                        <InfoCircle />
                    </div>
                </div>
            )}
        </div>
    )
}

export default ShuffledRunCard
