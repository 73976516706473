export const InvoiceStatus = [
    {
        value: 'Créée',
        label: 'Créée',
        ['@id']: '/invoice_statuses/Créée',
    },
    {
        value: 'Transmise',
        label: 'Transmise',
        ['@id']: '/invoice_statuses/Transmise',
    },
    {
        value: 'Réglée',
        label: 'Réglée',
        ['@id']: '/invoice_statuses/Réglée',
    },
    {
        value: 'Annulée',
        label: 'Annulée',
        ['@id']: '/invoice_statuses/Annulée',
    },
]

export const CpamInvoiceStatus = [
    {
        value: 'Créée',
        label: 'Créée',
        ['@id']: '/cpam_invoice_statuses/Créée',
        color: 'bg-gray-200 text-gray-800 font-bold',
        border: 'border-l-8 border-gray-400',
    },
    {
        value: 'Transmise',
        label: 'Transmise',
        ['@id']: '/cpam_invoice_statuses/Transmise',
        color: 'bg-fuchsia-200 text-fuchsia-500 font-bold',
        border: 'border-l-8 border-fuchsia-400',
    },
    {
        value: 'Réglée',
        label: 'Réglée',
        ['@id']: '/cpam_invoice_statuses/Réglée',
        color: 'bg-green-200 text-green-800 font-bold',
        border: 'border-l-8 border-green-400',
        textColor: 'text-green-800 font-bold',
    },
    {
        value: 'Annulée',
        label: 'Annulée',
        ['@id']: '/cpam_invoice_statuses/Annulée',
        color: 'bg-orange-200 text-orange-800 font-bold',
        border: 'border-l-8 border-orange-400',
    },
    {
        value: 'Rejetée',
        label: 'Rejetée',
        ['@id']: '/cpam_invoice_statuses/Rejetée',
        color: 'bg-red-200 text-red-600 font-bold',
        border: 'border-l-8 border-red-600',
    },
    {
        value: 'Réglée AMO',
        label: 'Réglée AMO',
        ['@id']: '/cpam_invoice_statuses/Réglée AMO',
        color: 'bg-blue-200 text-blue-800 font-bold',
        border: 'border-l-8 border-blue-400',
        textColor: 'text-blue-800',
    },
    {
        value: 'Cloturée',
        label: 'Cloturée',
        ['@id']: '/cpam_invoice_statuses/Cloturée',
        color: 'bg-purple-200 text-purple-800 font-bold',
        border: 'border-l-8 border-purple-400',
    },
    {
        value: 'Suspendue',
        label: 'Suspendue',
        ['@id']: '/cpam_invoice_statuses/Suspendue',
        color: 'bg-orange-200 text-orange-800 font-bold',
        border: 'border-l-8 border-orange-400',
    },
]

export const LotStatus = [
    {
        value: 'Créé',
        label: 'Créé',
        ['@id']: '/cpam_invoice_group_statuses/Créé',
        color: 'bg-gray-200 text-gray-800 font-bold',
        border: 'border-l-8 border-gray-400',
    },
    {
        value: 'Transmis',
        label: 'Transmis',
        ['@id']: '/cpam_invoice_group_statuses/Transmis',
        color: 'bg-fuchsia-200 text-fuchsia-800 font-bold',
        border: 'border-l-8 border-fuchsia-400',
    },
    {
        value: 'Réglé',
        label: 'Réglé',
        ['@id']: '/cpam_invoice_group_statuses/Réglé',
        color: 'bg-green-200 text-green-800 font-bold',
        border: 'border-l-8 border-green-400',
        textColor: 'text-green-800 font-bold',
    },
    {
        value: 'Rejeté',
        label: 'Rejeté',
        ['@id']: '/cpam_invoice_group_statuses/Rejeté',
        color: 'bg-red-200 text-red-600 font-bold',
        border: 'border-l-8 border-red-600',
    },
    {
        value: 'Annulé',
        label: 'Annulé',
        ['@id']: '/cpam_invoice_group_statuses/Annulé',
        color: 'bg-orange-200 text-orange-800 font-bold',
        border: 'border-l-8 border-orange-400',
    },
    {
        value: 'Réglé AMO',
        label: 'Réglé AMO',
        ['@id']: '/cpam_invoice_group_statuses/Réglé AMO',
        color: 'bg-blue-200 text-blue-800 font-bold',
        border: 'border-l-8 border-blue-400',
        textColor: 'text-blue-800',
    },
    {
        value: 'Cloturé',
        label: 'Cloturé',
        ['@id']: '/cpam_invoice_group_statuses/Cloturé',
        color: 'bg-purple-200 text-purple-800 font-bold',
        border: 'border-l-8 border-purple-400',
    },
]
