import { formatAddressLabel } from '../../../services/Reducers/AddressAutoCompleteReducer'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { RUNINFO_ACTIONS } from '../../../services/Reducers/RunInformationReducer'
import dayjs from 'dayjs'
import { UserCircle } from 'iconoir-react'
import { openMaps } from '../../../services/address'
import React from 'react'
import SignatureForm from './SignatureForm'
import { formatTimeLocal } from '../../../services/dateUtils'

const TransportInfo = ({
    runInfo,
    unlimitedSms,
    pickUpRef,
    depositRef,
    dispatch,
    handleForm,
    handleCopyClick,
}) => {
    return (
        <div className="mx-5 mb-5 flex w-11/12 shrink-0 snap-center snap-always flex-col overflow-y-scroll rounded-xl bg-white p-5 shadow-mobile">
            <div className="flex w-full items-center  justify-between pb-5 ">
                <p className="font-bold text-gray-600">Transport</p>
                <i className="fas fa-car fa-lg pl-4" />
                <p className="font-bold text-gray-600">
                    {runInfo.isRoundTrip ? 'A/R' : ''}
                    {runInfo.isReturnPath ? ' Retour' : ' Aller'}
                </p>
            </div>
            <div>
                <textarea
                    className="copy"
                    autoCorrect="off"
                    autoCapitalize="off"
                    readOnly
                    tabIndex="-1"
                    autoComplete="off"
                    ref={pickUpRef}
                    value={formatAddressLabel(runInfo.pickUpLocation)}
                />
                <textarea
                    className="copy"
                    autoCorrect="off"
                    autoCapitalize="off"
                    readOnly
                    tabIndex="-1"
                    autoComplete="off"
                    ref={depositRef}
                    value={formatAddressLabel(runInfo.depositLocation)}
                />
            </div>
            {runInfo.status.id === 'todo' ||
            (unlimitedSms &&
                runInfo.patient.patientsContacts.length > 0 &&
                runInfo.status.id !== 'canceled') ? (
                <div className="flex justify-center py-4">
                    <PrimaryButton
                        label={
                            !runInfo.isTakenInCharge
                                ? 'Confirmer la prise en charge'
                                : 'Renvoyer la confirmation'
                        }
                        title={
                            !runInfo.isTakenInCharge
                                ? 'Confirmer la prise en charge'
                                : 'Renvoyer la confirmation'
                        }
                        action={() => {
                            if (
                                unlimitedSms &&
                                runInfo.patient.patientsContacts.length > 0
                            ) {
                                dispatch({
                                    type: RUNINFO_ACTIONS.SHOW_CONTACT_MODAL,
                                })
                            } else {
                                handleForm('ongoing')
                            }
                        }}
                        mobileSize={true}
                    />
                </div>
            ) : runInfo.status.id === 'canceled' ? null : (
                <div className="flex items-center justify-center py-4">
                    <span
                        className={` flex  items-center justify-center rounded-full bg-green-200 px-2 py-1 text-xl font-bold text-green-600`}
                    >
                        Prise en charge confirmée
                    </span>
                </div>
            )}
            <div className="flex w-full justify-between border-b py-4">
                <div className="flex flex-col items-center  ">
                    <p className="text-lg font-bold text-gray-600">
                        Heure de départ
                    </p>
                    <p className="text-center">
                        {formatTimeLocal(runInfo.departureTime)}
                    </p>
                </div>
                <div className="pr-2 pt-8 font-bold text-gray-600">-></div>
                <div className="flex flex-col items-center ">
                    <p className="text-lg font-bold text-gray-600">
                        Heure d'arrivée
                    </p>
                    <p className="text-center">
                        {formatTimeLocal(runInfo.arrivingTime)}
                    </p>
                </div>
            </div>
            <div className={'mt-4 flex pt-2'}>
                <UserCircle className={'mr-2'} />
                <p>
                    {runInfo.patient.lastname} - {runInfo.patient.firstname}{' '}
                </p>
            </div>
            <div className="flex flex-col py-4">
                <div className={'flex items-center justify-between'}>
                    <p className="font-bold text-gray-600">Lieu de départ</p>
                    <button
                        onClick={() => handleCopyClick(pickUpRef)}
                        className="far fa-clone rounded-lg p-2 text-3xl focus:outline-none"
                    />
                </div>
                <p className="cursor-pointer">
                    {runInfo.pickUpLocation.street
                        ? runInfo.pickUpLocation.street + ', '
                        : ''}
                    {runInfo.pickUpLocation.secondaryStreet
                        ? runInfo.pickUpLocation.secondaryStreet + ', '
                        : ''}
                    <span className="underline">
                        {runInfo.pickUpLocation.city}
                    </span>
                </p>

                {runInfo.pickUpLocation.label &&
                    runInfo.pickUpLocation.label !==
                        runInfo.pickUpLocation.city && (
                        <p>{runInfo.pickUpLocation.label} </p>
                    )}
                <div className="flex pt-4 ">
                    <button
                        className="w-full items-center rounded-lg border py-2 text-xl"
                        onClick={() => openMaps(runInfo.pickUpLocation)}
                    >
                        <i className={'fab fa-waze pr-4 text-2xl'} />
                        Voir l'itinéraire
                    </button>
                </div>
            </div>
            <div className="flex flex-col space-y-1 py-4">
                <div className={'flex items-center justify-between'}>
                    <p className="font-bold text-gray-600">Lieu d'arrivée</p>
                    <button
                        onClick={() => handleCopyClick(depositRef, false)}
                        className="far fa-clone rounded-lg p-2 text-3xl focus:outline-none"
                    />
                </div>

                <p className="cursor-pointer">
                    {runInfo.depositLocation.street
                        ? runInfo.depositLocation.street + ', '
                        : ''}
                    {runInfo.depositLocation.secondaryStreet
                        ? runInfo.depositLocation.secondaryStreet + ', '
                        : ''}
                    <span className="underline">
                        {runInfo.depositLocation.city}
                    </span>
                </p>
                {runInfo.depositLocation.label &&
                    runInfo.depositLocation.label !==
                        runInfo.depositLocation.city && (
                        <p>{runInfo.depositLocation.label} </p>
                    )}
                <div className="flex pt-4 ">
                    <button
                        className="w-full items-center rounded-lg border py-2 text-xl"
                        onClick={() => openMaps(runInfo.depositLocation)}
                    >
                        <i className={'fab fa-waze pr-4 text-2xl'} />
                        Voir l'itinéraire
                    </button>
                </div>
            </div>
            <div className="flex flex-col space-y-1 py-4">
                <p className="font-bold text-gray-600">Objet</p>
                <p>{runInfo.runObject.label}</p>
            </div>
            {runInfo.series && (
                <div className="flex flex-col space-y-1">
                    <div className="flex justify-between ">
                        <p className="font-bold text-gray-600">Série</p>
                        <p>
                            {runInfo.seriesIndex}/{runInfo.series.nbRuns}
                        </p>
                    </div>
                    <div> {runInfo.series.description}</div>
                </div>
            )}
            {runInfo.comments && (
                <div className="flex flex-col space-y-1 py-2">
                    <p className="font-bold text-gray-600">Commentaire</p>
                    <p>{runInfo.comments}</p>
                </div>
            )}
            {runInfo.partner && (
                <div className="flex flex-col space-y-1 py-2">
                    <p className="font-bold text-gray-600">
                        Transport donné par
                    </p>
                    <p>
                        {runInfo.partner.lastname} {runInfo.partner.firstname}
                    </p>
                </div>
            )}
        </div>
    )
}

export default TransportInfo
