import UppercaseLabel from '../../../Commons/UppercaseLabel'
import React from 'react'
import DayConfigurator from './DayConfigurator'

const DaysConfigurator = ({
    series,
    dispatch,
    depositAddress,
    pickUpAddress,
}) => {
    return (
        <div className="flex flex-col space-y-4">
            <UppercaseLabel label="Jours" />
            {series.days.map((day, index) => (
                <div className="w-full space-y-2 rounded border p-4 shadow">
                    <DayConfigurator
                        series={series}
                        day={day}
                        dispatch={dispatch}
                        index={index}
                        globalRunDepositAddress={depositAddress}
                        globalRunPickUpAddress={pickUpAddress}
                    />
                </div>
            ))}
        </div>
    )
}

export default DaysConfigurator
