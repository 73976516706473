import { Route, Routes, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import PartnerRunsList from './PartnerRunsList'
import PartnerConsult from './PartnerConsult'
import { ReactComponent as Logo } from '../../files/HEROAD2.svg'

const PartnerAccessContainer = () => {
    const { token } = useParams()

    return (
        <div className="flex h-screen w-full flex-col overflow-y-scroll bg-gray-200 text-gray-700">
            <div className="mobile-header flex items-center justify-between bg-light-dark-green px-4 py-2 text-xl text-white">
                <Logo className={'w-40'} height={40} />
                <div className="flex ">
                    <i className="fa fa-hand-pointer pr-1" />
                    <a
                        href="https://heroad.io"
                        target="_blank"
                        className="hover:underline"
                    >
                        Découvrir <span className="font-bold">Heroad</span>
                    </a>
                </div>
            </div>

            <Routes>
                <Route path=":id" element={<PartnerConsult token={token} />} />
                <Route
                    path="/"
                    element={
                        <PartnerRunsList isSocietyTaxi={true} token={token} />
                    }
                />
            </Routes>
        </div>
    )
}

export default PartnerAccessContainer
