import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useNavigate, useLocation } from 'react-router-dom'
import SelectDateRangePicker from '../Commons/SelectDateRangePicker'
import Loader from '../Commons/Loaders/Loader'
import API from '../../services/api'
import { formatData } from '../../services/FormatRuns'
import { getBorderColor } from '../../services/RunStatus'
import { ReactComponent as Logo } from '../../files/logo_heroad_white.svg'
import { ReactComponent as LogoTypo } from '../../files/heroad.svg'
import {
    formatDateLocal,
    formatDateYYYYMMDD,
    formatTimeLocal,
} from '../../services/dateUtils'

const PartnerRunsList = ({ isSocietyTaxi, token }) => {
    const [loading, setLoading] = useState(true)
    const [partner, setPartner] = useState(null)
    const [rows, setRows] = useState([])
    const firstDay = dayjs().startOf('month').format('YYYY-MM-DD')
    const lastDay = dayjs().endOf('month').format('YYYY-MM-DD')
    const [startDate, setStartDate] = useState(firstDay)
    const [endDate, setEndDate] = useState(lastDay)
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)
    const history = useNavigate()
    const location = useLocation()
    const getPartnerAcces = () => {
        setLoading(true)
        API.Partners.Access(
            token,
            formatDateYYYYMMDD(startDate),
            formatDateYYYYMMDD(endDate)
        )
            .then((response) => {
                response.json().then((data) => {
                    setPartner(data.partner)
                    setRows(formatData(data.runs))
                    setLoading(false)
                })
            })
            .catch((error) => {
                if (error.json) {
                    error.json().then((data) => {
                        setLoading(false)
                    })
                }
            })
    }

    useEffect(() => {
        if (token != null) {
            getPartnerAcces()
        }
    }, [startDate, endDate])

    const historyPush = (id) => {
        history(location.pathname + '/' + id)
    }

    return (
        <div className={'flex h-screen flex-col overflow-y-auto'}>
            <div className="container mx-auto  rounded-xl bg-gray-100 px-2 py-10">
                <div className="flex justify-between  rounded-full">
                    <div className="flex w-48 max-w-full items-center rounded-full border-2 bg-white px-3 py-2 shadow-md">
                        <div className="flex flex-col pr-3 ">
                            <i className="far fa-user-circle text-4xl font-light " />
                        </div>
                        <div className="flex flex-col">
                            <h2 className="font-lighter  ">
                                Bonjour
                                <span
                                    role="img"
                                    aria-label="wave-hand"
                                    className="pl-2 sm:text-sm lg:text-xl"
                                >
                                    👋
                                </span>
                            </h2>
                            <span className="font-bold lg:text-lg">
                                {partner?.firstname}
                            </span>
                        </div>
                    </div>
                    <div className="relative flex items-center rounded-full">
                        <SelectDateRangePicker
                            openSelectDatePicker={openSelectDatePicker}
                            setOpenSelectDatePicker={setOpenSelectDatePicker}
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            topRight={true}
                            setAction={getPartnerAcces}
                        />
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <div className="flex justify-center py-5 text-sm font-light lg:text-base">
                            <p className="pr-1 font-bold">{rows.length}</p>
                            {rows.length <= 1
                                ? 'transport assigné'
                                : 'transports assignés'}
                        </div>
                        {rows.length > 0 ? (
                            <div className="flex flex-col space-y-4">
                                {rows.map((row) => (
                                    <div
                                        className="parents relative flex w-full "
                                        key={row.id}
                                        onClick={() => historyPush(row.id)}
                                    >
                                        <div
                                            className={`grid w-full cursor-pointer select-none rounded-md  border-l-8 bg-white shadow-mobile lg:select-auto ${getBorderColor(
                                                row.status.id
                                            )}`}
                                        >
                                            <div
                                                className={`h-42 parents row-span-1 flex  grid-rows-1 flex-wrap items-center justify-between px-2 py-1 text-sm sm:text-base `}
                                            >
                                                <div className="w-1/2 truncate  lg:w-1/4">
                                                    <div className="truncate text-sm font-semibold">
                                                        {row.fullname}
                                                    </div>
                                                    <div className="truncate text-sm font-light">
                                                        {row.address}
                                                    </div>
                                                </div>
                                                <div className="hidden w-1/4 max-w-xs pl-5 text-sm lg:block lg:w-1/6">
                                                    <div className="flex justify-between">
                                                        <div className="flex flex-col">
                                                            <div className="truncate font-semibold">
                                                                {
                                                                    row
                                                                        .runObject
                                                                        .label
                                                                }
                                                            </div>
                                                            <div>
                                                                {formatDateLocal(
                                                                    row.date
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="flex items-center align-middle">
                                                            <i
                                                                className={`${
                                                                    row.isReturnPath
                                                                        ? 'far fa-registered fa-lg '
                                                                        : 'hidden'
                                                                }`}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex w-1/6 flex-col justify-center px-2 text-xs lg:flex-row  lg:px-6 ">
                                                    <div className="flex justify-center">
                                                        <span
                                                            className={`flex rounded-full px-2 text-xs font-semibold leading-5 bg-${
                                                                row.type
                                                                    .label ===
                                                                    'VSL' &&
                                                                isSocietyTaxi
                                                                    ? 'orange-100 text-orange-600'
                                                                    : row.type
                                                                          .label ===
                                                                      'Ambulance'
                                                                    ? 'red-100 text-red-600'
                                                                    : row.type
                                                                          .label ===
                                                                      'VSL'
                                                                    ? 'blue-100 text-blue-800'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {row.type.label ===
                                                                'VSL' &&
                                                            isSocietyTaxi
                                                                ? 'Taxi'
                                                                : row.type
                                                                      .label}
                                                        </span>
                                                    </div>
                                                    <div className="flex justify-center pt-1 lg:hidden">
                                                        {formatDateLocal(
                                                            row.date
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="flex hidden w-1/2 text-sm lg:block lg:w-1/4">
                                                    <div className="truncate py-1">
                                                        <i className="fas fa-map-marker-alt pr-2" />
                                                        <span className="">
                                                            {row.depositLocation
                                                                .label != null
                                                                ? row
                                                                      .depositLocation
                                                                      .label
                                                                : `${
                                                                      row
                                                                          .depositLocation
                                                                          .street
                                                                          ? row
                                                                                .depositLocation
                                                                                .street +
                                                                            ', '
                                                                          : ''
                                                                  }
                                                          ${
                                                              row
                                                                  .depositLocation
                                                                  .city
                                                          }`}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex w-1/6 flex-col items-center  justify-center align-middle  text-base lg:flex-row lg:px-2">
                                                    <span className="mt-2 flex flex-col items-center justify-center  rounded-md px-2 text-sm font-semibold text-gray-800 lg:mt-0 lg:flex-row lg:border lg:bg-gray-300">
                                                        <div className=" lg:p-0 ">
                                                            {formatTimeLocal(
                                                                row.departureTime
                                                            )}
                                                        </div>
                                                        <div className="hidden lg:block">
                                                            -
                                                        </div>
                                                        <div>
                                                            {formatTimeLocal(
                                                                row.arrivingTime
                                                            )}
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="block flex w-1/2 max-w-xs lg:hidden lg:w-1/4">
                                                    <div className="truncate">
                                                        <i className="fas fa-map-marker-alt pr-2" />
                                                        <span className="truncate">
                                                            {row.depositLocation
                                                                .label != null
                                                                ? row
                                                                      .depositLocation
                                                                      .label
                                                                : `${
                                                                      row
                                                                          .depositLocation
                                                                          .street
                                                                          ? row
                                                                                .depositLocation
                                                                                .street +
                                                                            ', '
                                                                          : ''
                                                                  }
                                                          ${
                                                              row
                                                                  .depositLocation
                                                                  .city
                                                          }`}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="flex  w-1/2 items-center justify-between  pl-10 lg:hidden lg:w-1/4">
                                                    <div className="truncate">
                                                        <i
                                                            className={`${
                                                                row.isReturnPath
                                                                    ? 'far fa-registered pr-2 '
                                                                    : 'hidden'
                                                            }`}
                                                        />
                                                        {row.runObject.label}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div
                                className="flex w-full cursor-pointer items-center justify-center p-2 hover:font-bold"
                                onClick={() =>
                                    setOpenSelectDatePicker(
                                        !openSelectDatePicker
                                    )
                                }
                            >
                                <div className="flex underline">
                                    Changer la date
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="mt-auto flex flex-col bg-light-dark-green px-2 py-10 text-white ">
                <div className="mx-auto grid w-full max-w-5xl  items-center  gap-2  py-4  ">
                    <div className=" flex flex-col items-center justify-center space-y-4 text-center ">
                        <Logo width={50} height={50} />
                        <LogoTypo width={400} height={20} />
                    </div>
                    <div className="flex justify-center space-x-8 pt-4  text-white lg:text-xl">
                        <a href="https://www.facebook.com/heroadSAS">
                            <i className="fab fa-facebook" alt="facebook" />
                        </a>
                        <a href="https://www.youtube.com/@heroad_regulation">
                            <i className="fab fa-youtube" />
                        </a>
                        <a href="https://www.linkedin.com/company/heroad-fr/">
                            <i className="fab fa-linkedin" />
                        </a>
                    </div>
                </div>
            </div>
            <div className=" flex bg-light-dark-green pb-5 text-base  text-white">
                <p className="mx-auto flex w-full justify-center">
                    Tous droits réservés - Heroad © 2024
                </p>
            </div>
        </div>
    )
}

export default PartnerRunsList
