import ClassicInputV2 from '../../../Commons/Inputs/ClassicInputV2'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import { formatName, formatRunObject } from '../../../../tools/Utility'
import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'
import WaitingTimeInput from '../../../Commons/Inputs/WaitingTimeInput'
import AddressesInline from '../../../Commons/AddressesInline'
import { Search } from 'iconoir-react'
import ClassicTextarea from '../../../Commons/Inputs/ClassicTextarea'
import API from '../../../../services/api'
import { debounce } from '../../../../services/debounce'
import { ADDRESS_ACTIONS } from '../../../../services/Reducers/AddressAutoCompleteReducer'
import { SERIES_ACTIONS } from '../../../../services/Reducers/SeriesReducer'
import React from 'react'
import { DATE_ACTIONS } from '../../../../services/Reducers/DateReducer'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { PATIENT_FORM_ACTIONS } from '../../Regulation/Reducers/PatientFormReducer'
import ModalSkeleton from '../../../Modal/ModalSkeleton'
import PatientFormContent from '../../Patient/PatientFormContent'
import { handlePatientForm } from '../../Patient/handlePatientForm'

const GlobalRunInformations = ({
    series,
    dispatch,
    depositAddress,
    depositDispatch,
    pickUpDispatch,
    inputDeposit,
    pickUpAddress,
    inputPickUp,
    datePatientDispatch,
    patientForm,
    datePatientInput,
}) => {
    const handlePatientChange = (e) => {
        dispatch({
            type: SERIES_ACTIONS.SET_PATIENT,
            payload: e,
        })

        const defaultPickUpAddress = e?.patient.defaultPickUpAddress
            ? e.patient.defaultPickUpAddress
            : series.globalRunInformations.pickUpLocation
        const defaultDepositAddress = e?.patient.defaultDepositAddress
            ? e.patient.defaultDepositAddress
            : series.globalRunInformations.depositLocation

        pickUpDispatch({
            type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
            payload: defaultPickUpAddress,
        })
        depositDispatch({
            type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
            payload: defaultDepositAddress,
        })
        if (e) {
            dispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: e.patient,
            })
            datePatientDispatch({
                type: DATE_ACTIONS.SET_DATE,
                payload: e.patient.dateOfBirth,
            })
        } else {
            dispatch({
                type: PATIENT_FORM_ACTIONS.CLEAR_PATIENT,
            })
        }
    }

    const searchPatient = debounce((e) => {
        if (e !== '') {
            API.Patients.list(e).then((response) => {
                response.json().then((data) => {
                    const patientList = data['hydra:member'].map((patient) => {
                        return {
                            value: patient['@id'],
                            label: patient.firstname + ' ' + patient.lastname,
                            patient: patient,
                        }
                    })
                    dispatch({
                        type: SERIES_ACTIONS.SET_PATIENT_SEARCH_INPUT,
                        payload: patientList,
                    })
                })
            })
        }
    }, 500)

    const fetchDistanceData = (manualGet = false) => {
        if (pickUpAddress.city && depositAddress.city) {
            if (!series.globalRunInformations.kilometer || manualGet)
                API.Distance.get(pickUpAddress.city, depositAddress.city).then(
                    (response) => {
                        response.json().then((data) => {
                            const distance = data['hydra:member'][0]
                            if (distance) {
                                dispatch({
                                    type: SERIES_ACTIONS.SET_KILOMETER,
                                    payload: distance.kilometer.toString(),
                                })
                            } else {
                                dispatch({
                                    type: SERIES_ACTIONS.SET_DISTANCE_NOT_FOUND,
                                })
                            }
                        })
                    }
                )
        }
    }

    const handleSeriesPatientModalForm = async () => {
        const formResponse = await handlePatientForm(
            patientForm,
            datePatientInput
        )
        if (formResponse.success) {
            dispatch({
                type: SERIES_ACTIONS.UPDATE_PATIENT,
                payload: formResponse.data,
            })
            dispatch({
                type: SERIES_ACTIONS.SHOW_PATIENT_MODAL,
                payload: false,
            })
            dispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: formResponse.data,
            })
        }
    }

    const handleCancelModal = () => {
        dispatch({
            type: SERIES_ACTIONS.SHOW_PATIENT_MODAL,
            payload: false,
        })
    }

    return (
        <div className="min-h-row-date flex w-full flex-col flex-wrap space-y-5 rounded bg-white p-5 shadow-md">
            <div className="flex flex-col lg:w-1/2">
                <ClassicInputV2
                    value={series.label}
                    label="Label"
                    setValue={(e) => {
                        dispatch({
                            type: SERIES_ACTIONS.SET_LABEL,
                            payload: e.target.value,
                        })
                    }}
                />
            </div>
            <div className="flex flex-col lg:w-1/2">
                <SelectDropDownListV2
                    label={'Personne à transporter'}
                    showLabel={true}
                    placeholder={'Sélectionner une personne à transporter'}
                    icon={'fa-user'}
                    value={formatName(series.patient)}
                    handleOptionChange={(e) => handlePatientChange(e)}
                    options={series.patients}
                    handleSearchInputPatientChange={(e) => searchPatient(e)}
                    isClearable={true}
                    isSearchable={true}
                    addOption={() =>
                        dispatch({
                            type: SERIES_ACTIONS.SHOW_PATIENT_MODAL,
                            payload: true,
                        })
                    }
                />
            </div>
            <div className="flex flex-col items-center justify-center space-y-4 lg:flex-row lg:space-y-0">
                <div className="w-full lg:w-1/2">
                    <SelectDropDownListV2
                        label={'Objet des transports'}
                        placeholder={'Sélectionner une objet de transport'}
                        showLabel={true}
                        icon={'fa-bullseye'}
                        options={series.runObjects}
                        value={formatRunObject(series.runObject)}
                        handleOptionChange={(e) =>
                            dispatch({
                                type: SERIES_ACTIONS.SET_RUN_OBJECT,
                                payload: e,
                            })
                        }
                        isSearchable={true}
                    />
                </div>
                <div className="flex w-full justify-around lg:w-1/2 lg:justify-center">
                    <div className="min-w-select-types flex">
                        <ClassicSwitch
                            label="Montant des transports"
                            value={series.globalRunInformations.showAmount}
                            setValue={() =>
                                dispatch({
                                    type: SERIES_ACTIONS.SHOW_AMOUNT,
                                    payload:
                                        !series.globalRunInformations
                                            .showAmount,
                                })
                            }
                            info={true}
                            infoLabel={
                                'Permet de rentrer le montant prévisionnel de chaque transport'
                            }
                        />
                    </div>
                    {series.globalRunInformations.showAmount && (
                        <WaitingTimeInput
                            label="Montant €"
                            type="text"
                            value={series.globalRunInformations.amount}
                            required={true}
                            setValue={(e) =>
                                dispatch({
                                    type: SERIES_ACTIONS.SET_AMOUNT,
                                    payload: e.target.value,
                                })
                            }
                            placeholder="00.00"
                            id="montant"
                            showLabel={true}
                        />
                    )}
                </div>
            </div>
            <div className="flex flex-col lg:w-1/2">
                <SelectDropDownListV2
                    label={'Transport'}
                    icon={'fa-ambulance'}
                    placeholder={'Sélectionner un type de transport'}
                    options={series.vehiclesTypes}
                    value={series.globalRunInformations.type}
                    handleOptionChange={(e) =>
                        dispatch({
                            type: SERIES_ACTIONS.SET_VEHICLE_TYPE,
                            payload: e,
                        })
                    }
                    isClearable={false}
                    isSearchable={true}
                />
            </div>
            <div className="min-h-row-date flex w-full">
                <AddressesInline
                    depositAddress={depositAddress}
                    depositDispatch={depositDispatch}
                    pickUpDispatch={pickUpDispatch}
                    inputDeposit={inputDeposit}
                    series={series}
                    pickUpAddress={pickUpAddress}
                    inputPickUp={inputPickUp}
                />
            </div>
            <div className="flex flex-col space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0">
                <div className="flex lg:w-1/2">
                    <ClassicTextarea
                        setValue={(e) =>
                            dispatch({
                                type: SERIES_ACTIONS.SET_COMMENTS,
                                payload: e.target.value,
                            })
                        }
                        value={series.comments}
                        label={'Commentaire'}
                    />
                </div>
                <div className="flex items-center md:w-1/4 md:justify-center md:space-x-4">
                    <WaitingTimeInput
                        label="Kilomètre"
                        type="text"
                        value={series.kilometer}
                        required={true}
                        setValue={(e) =>
                            dispatch({
                                type: SERIES_ACTIONS.SET_KILOMETER,
                                payload: e.target.value,
                            })
                        }
                        placeholder="100"
                        id="kilometer"
                        showLabel={true}
                    />
                    <div
                        className={`flex ${
                            series.distanceNotFound && 'flex-col'
                        } items-center`}
                    >
                        <SecondaryButton
                            title="Rechercher le trajet dans l'historique"
                            action={() => fetchDistanceData(true)}
                            icon={<Search />}
                        />
                        {series.distanceNotFound && (
                            <p className={'text-xs text-red-500'}>
                                Trajet non répertorié
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {series.showPatientModal && (
                <ModalSkeleton
                    label={`${
                        patientForm.id ? 'Modifier' : 'Ajouter'
                    } un patient`}
                    componentProps={{
                        patient: patientForm,
                        dispatch: dispatch,
                        date: datePatientInput,
                        dateDispatch: datePatientDispatch,
                        fromModal: true,
                    }}
                    Component={PatientFormContent}
                    handleForm={handleSeriesPatientModalForm}
                    handleCloseModal={handleCancelModal}
                />
            )}
        </div>
    )
}

export default GlobalRunInformations
