import { formatHydraData } from '../../api'
import { apiClient, withErrorHandling } from '../apiClient'
import { toast } from 'react-toastify'
import { removeIdFromAddress } from '../../../tools/Utility'

const PATH_KEY = '/establishments'

export const Establishments = {
    fetchAll: async (search) => {
        let path = PATH_KEY + '?pagination=false'
        if (search) path += `&search=${search}`
        return formatHydraData(await apiClient.get(path))
    },
    fetchOne: async (id) => {
        return formatHydraData(await apiClient.get(`${PATH_KEY}/${id}`))
    },
    post: withErrorHandling(async (establishmentForm) => {
        return await apiClient.post(PATH_KEY, establishmentForm)
    }),
    put: withErrorHandling(async (establishmentForm) => {
        const { id } = establishmentForm
        return await apiClient.put(`${PATH_KEY}/${id}`, establishmentForm)
    }),
}

export const handleEstablishmentForm = async (establishmentForm) => {
    let result = { success: false, data: null }
    try {
        if (!validateForm(establishmentForm)) return result
        establishmentForm.address = removeIdFromAddress(
            establishmentForm.address
        )
        console.log(establishmentForm)
        if (establishmentForm.id) {
            result.data = await Establishments.put(establishmentForm)
            toast.success('Etablissement modifié avec succès')
        } else {
            result.data = await Establishments.post(establishmentForm)
            toast.success('Etablissement ajouté avec succès')
        }
        result.success = true
    } catch (error) {
        // L'erreur est déjà gérée dans les fonctions post et put
        throw error
    }
    return result
}

const validateForm = (establishmentForm) => {
    const errors = []

    if (!establishmentForm.label) {
        errors.push('Veuillez renseigner un label.')
    }
    if (!establishmentForm.address?.city) {
        errors.push('Veuillez renseigner une ville.')
    }

    errors.forEach((error) => toast.error(error))
    return errors.length === 0
}
