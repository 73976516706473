import { concat, dateBetween } from '../pathsAndFilters'
import { apiClient } from '../apiClient'

const PATH_KEY = '/plannings'
export const Plannings = {
    fetchAll: async (between, locked) => {
        let path = PATH_KEY + '?pagination=false'
        if (between) path = dateBetween(path, between)
        if (locked != null) path = concat(path, 'isLocked=' + locked)

        return apiClient.get(path)
    },
    fetchAllLockedForMonth: async (date) => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)

        return await Plannings.fetchAll(
            {
                strictlyAfter: firstDay.toISOString().split('T')[0],
                strictlyBefore: lastDay.toISOString().split('T')[0],
            },
            true
        )
    },
    get: async (id) => {
        return apiClient.get(`${PATH_KEY}/${id}`)
    },
    post: async (data) => {
        return apiClient.post(PATH_KEY, data)
    },
}
