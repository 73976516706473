import { apiClient, withErrorHandling } from '../apiClient'

const PATH_KEY = '/runs'

export const Run = {
    deleteRun: withErrorHandling(async (id) => {
        return await apiClient.delete(`${PATH_KEY}/${id}`)
    }),
    fetchOne: withErrorHandling(async (id) => {
        return await apiClient.get(`${PATH_KEY}/${id}`)
    }),
    downloadSignaturePdf: withErrorHandling(async (params) => {
        const { id } = params
        return await apiClient.put(`${PATH_KEY}/pdf_signature/${id}`, params)
    }),
    removeSharedRun: withErrorHandling(async (id) => {
        return await apiClient.put(`/remove_shared_run/${id}`)
    }),
    exportRuns: withErrorHandling(async (params) => {
        return await apiClient.post(`${PATH_KEY}/export`, params)
    }),
    updateRunsStatus: withErrorHandling(async (data) => {
        return await apiClient.put(`${PATH_KEY}/status`, data)
    }),
}
