import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js/auto'

const RevenueChart = ({ data }) => {
    const fullMonths = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
    ]
    const shortMonths = [
        'Jan',
        'Fév',
        'Mar',
        'Avr',
        'Mai',
        'Juin',
        'Juil',
        'Août',
        'Sep',
        'Oct',
        'Nov',
        'Déc',
    ]
    const values = fullMonths.map((month) => data[month] || 0)

    const chartRef = useRef(null)
    const chartInstance = useRef(null)

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy()
        }

        const myChartRef = chartRef.current.getContext('2d')
        const gradient = myChartRef.createLinearGradient(0, 0, 0, 400)
        gradient.addColorStop(0, 'rgba(147,92,241,0.7)')
        gradient.addColorStop(1, 'rgba(217,184,246,0.7)')

        chartInstance.current = new Chart(myChartRef, {
            type: 'line',
            data: {
                labels: shortMonths,
                datasets: [
                    {
                        label: "Chiffre d'affaire",
                        data: values,
                        backgroundColor: gradient,
                        borderWidth: 2,
                        fill: true,
                        tension: 0.1,
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false, // Cacher les labels en haut du graphique
                    },
                    tooltip: {
                        backgroundColor: 'rgb(255,255,255)', // Couleur de fond du tooltip
                        borderColor: 'rgb(214,214,214)', // Couleur de la bordure du tooltip
                        borderWidth: 1, // Largeur de la bordure du tooltip
                        mode: 'index',
                        intersect: false,
                        titleColor: 'rgb(107,114,128)',
                        bodyColor: 'rgb(107,114,128)',
                        titleFont: {
                            family: 'Inter, sans-serif', // Changer la police du titre du tooltip
                            size: 20, // Changer la taille du texte du titre du tooltip
                            weight: 600, // Changer le poids du texte du titre du tooltip
                            height: 1.2, // Changer la hauteur du texte du titre du tooltip
                        },
                        bodyFont: {
                            family: 'Inter, sans-serif', // Changer la police du corps du tooltip
                            size: 16, // Changer la taille du texte du corps du tooltip
                            weight: 'normal', // Changer le poids du texte du corps du tooltip
                        },
                        footerFont: {
                            family: 'Inter, sans-serif', // Changer la police du pied du tooltip
                            size: 16, // Changer la taille du texte du pied du tooltip
                            weight: 'normal', // Changer le poids du texte du pied du tooltip
                        },
                        callbacks: {
                            title: function (context) {
                                return fullMonths[context[0].dataIndex]
                            },
                            label: function (context) {
                                let label = context.dataset.label || ''
                                if (label) {
                                    label += ': '
                                }
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('fr-FR', {
                                        style: 'currency',
                                        currency: 'EUR',
                                    }).format(context.parsed.y)
                                }
                                return label
                            },
                        },
                    },
                },
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            font: {
                                size: 12,
                                family: 'Arial, sans-serif',
                            },
                            color: '#666',
                        },
                    },
                    y: {
                        stacked: true,
                        font: {
                            size: 12,
                            family: 'Arial, sans-serif',
                        },
                        color: '#666',
                    },
                },
            },
        })

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy()
            }
        }
    }, [data])

    return (
        <div className="rounded-lg bg-white p-4 shadow-lg">
            <canvas ref={chartRef} />
        </div>
    )
}

export default RevenueChart
