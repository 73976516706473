import React from 'react'

const ClassicInput = (props) => {
    return (
        <div className={`h-full ${props.className}`}>
            {props.icon ? (
                <div className="inline-block w-5 sm:w-10 text-gray-800">
                    <i className={props.icon} />
                </div>
            ) : (
                ''
            )}
            <div className={`inline-block ${props.className}`}>
                <label
                    className={`block uppercase tracking-wide text-gray-700 text-base lg:text-xs font-bold pb-1 ${
                        props.showLabel ? '' : 'hidden '
                    }  ${props.classNameLabel}`}
                    htmlFor={props.id}
                >
                    {props.label}
                </label>
                <input
                    className={`appearance-none block w-full text-gray-700 h-14  lg:h-10 pl-4 leading-tight border rounded  focus:border-lighter-blue-green hover:border-lighter-blue-green focus:outline-none
                  ${props.error ? 'border-red-500' : ''}`}
                    id={props.id}
                    type={props.type}
                    pattern={props.pattern}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.setValue}
                    required={props.required}
                    min={props.min}
                    max={props.max}
                    maxLength={props.maxLength}
                    disabled={props.disable}
                />
            </div>
        </div>
    )
}

export default ClassicInput
