import React, { useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLongPress } from 'use-long-press'
import TOOLS from '../../../services/tools'
import MobileOption from '../../Commons/MobileOption'
import DefaultOptions from '../../Commons/DefaultOptions'
import Loader from '../../Commons/Loaders/Loader'
import { statusColorConfig } from './Free/FreeInvoiceForm'
import ActionModal from '../../Modal/ActionModal'

const StatusBadge = ({ status }) => {
    const badgeColor = statusColorConfig[status.toLowerCase()] || 'bg-gray-400'

    return (
        <span
            className={`inline-flex w-32 items-center justify-center rounded-full px-2 py-1 text-sm font-medium  ${badgeColor}`}
        >
            {status}
        </span>
    )
}

const InvoiceList = ({
    invoices,
    loading,
    totalItems,
    handleDelete,
    updateArchive,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [modalOpen, setModalOpen] = useState(false)
    const [modalArchivedOpen, setModalArchivedOpen] = useState(false)
    const [label, setLabel] = useState('')
    const [labelAction, setLabelAction] = useState('')
    const [actionButton, setActionButton] = useState('')
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(null)
    const optionsRef = useRef()

    TOOLS.useOnClickOutside(optionsRef, () => setShowOptions(false))

    const navigateToInvoice = (id) =>
        navigate(`${location.pathname}/${id}?consultation=true`)
    const navigateToUpdate = (id) => navigate(`${location.pathname}/${id}`)
    const navigateToCopy = (id) => navigate(`${location.pathname}/0?copy=${id}`)

    const bind = useLongPress(() => {}, {
        onStart: (event) => setSelectedRowId(parseInt(event.currentTarget.id)),
        onFinish: () => setShowMobileOptions(true),
        onCancel: () => {
            setSelectedRowId(null)
            setShowMobileOptions(false)
        },
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'touch',
    })

    const handleOptionsClick = (e, invoiceId) => {
        e.stopPropagation()
        setShowOptions(true)
        setSelectedRowId(invoiceId)
    }

    const handleAction = () => {
        setModalOpen(false)
        handleDelete(selectedRowId)
    }

    const handleArchived = () => {
        setModalArchivedOpen(false)
        updateArchive(selectedRowId.id)
    }

    const handleModalArchived = (rowId) => {
        const invoice = invoices.find((row) => row.id === rowId)
        setSelectedRowId(invoice)

        if (invoice.archived) {
            let label = `Êtes-vous sûr de vouloir retirer la facture des archives ?`
            setLabel(label)
            setActionButton('Désarchiver')
            setLabelAction('Désarchiver la facture')
        } else {
            let label = `Êtes-vous sûr de vouloir archiver la facture ?`
            setLabel(label)
            setActionButton('Archiver')
            setLabelAction('Archiver la facture')
        }
        setModalArchivedOpen(true)
    }

    const handleModalDelete = (rowId) => {
        let row = invoices.find((r) => r.id === rowId)
        setLabel(
            `Êtes-vous sûr de vouloir supprimer ${
                row.isInvoice ? 'cette facture' : 'ce devis'
            } ?`
        )
        setActionButton('Supprimer')
        setLabelAction('Suppression')
        setModalOpen(true)
    }

    if (loading) return <Loader />

    return (
        <div className="px-2 xl:pt-5">
            <div className="flex py-2">
                <div className="ml-auto text-xs font-bold">
                    {totalItems} Factures
                </div>
            </div>

            <div className="flex w-full flex-col space-y-2 lg:space-y-0">
                {invoices.map((invoice) => (
                    <div
                        className="parents relative flex w-full pb-2"
                        key={invoice.id}
                        {...bind}
                        onClick={() => navigateToInvoice(invoice.id)}
                    >
                        <div className="w-full cursor-pointer select-none rounded bg-white shadow-md lg:select-auto">
                            <div className="space-y-2 p-4 lg:grid lg:grid-cols-5 lg:items-center lg:gap-4 lg:space-y-0">
                                <div className="truncate">
                                    <span className="text-sm lg:text-base">
                                        {invoice.title}
                                    </span>
                                </div>
                                <div className="flex flex-col truncate">
                                    <span className="text-sm lg:text-base">
                                        {invoice.number}
                                    </span>
                                </div>
                                {invoice.patient ? (
                                    <div className="truncate">
                                        <span className="text-sm font-semibold lg:text-base">
                                            {invoice.patient.lastname}{' '}
                                            {invoice.patient.firstname}
                                        </span>
                                    </div>
                                ) : (
                                    <div className="truncate">
                                        <span className="text-sm font-semibold lg:text-base">
                                            {invoice.establishment.label}
                                        </span>
                                    </div>
                                )}
                                <div className="text-center">
                                    <span className="text-sm font-semibold lg:text-base">
                                        {invoice.totalIncludingTaxes.toFixed(2)}{' '}
                                        €
                                    </span>
                                </div>
                                <div className="flex justify-center">
                                    <StatusBadge status={invoice.status} />
                                </div>
                            </div>
                        </div>
                        <div
                            className={`absolute right-0 hidden h-10 cursor-pointer rounded pt-2 xl:block   ${
                                invoice.id === selectedRowId ? null : 'options '
                            } `}
                            onClick={(e) => {
                                e.stopPropagation()
                                setShowOptions(true)
                                setSelectedRowId(invoice.id)
                            }}
                        >
                            <i
                                className={`fas fa-ellipsis-h w-20 pr-4 pt-1 text-right ${
                                    showOptions ? 'hidden' : ''
                                } `}
                            />
                            {invoice.id === selectedRowId && showOptions && (
                                <DefaultOptions
                                    setShowMobileOptions={setShowOptions}
                                    rowId={selectedRowId}
                                    optionsRef={optionsRef}
                                    historyPushCopy={navigateToCopy}
                                    historyPushUpdate={navigateToUpdate}
                                    historyPush={navigateToInvoice}
                                    handleModalArchived={handleModalArchived}
                                    handleModalDelete={handleModalDelete}
                                    view={true}
                                />
                            )}
                        </div>
                    </div>
                ))}
            </div>
            {showMobileOptions && (
                <MobileOption
                    setShowMobileOptions={setShowMobileOptions}
                    rowId={selectedRowId}
                    handleModalDelete={handleModalDelete}
                    handleModalArchived={handleModalArchived}
                    historyPushCopy={navigateToCopy}
                    historyPushUpdate={navigateToUpdate}
                    historyPush={navigateToInvoice}
                    view={true}
                />
            )}
            {modalOpen ? (
                <ActionModal
                    setModalOpen={setModalOpen}
                    label={label}
                    action={handleAction}
                    labelAction={labelAction}
                    actionButton={actionButton}
                />
            ) : null}
            {modalArchivedOpen ? (
                <ActionModal
                    setModalOpen={setModalArchivedOpen}
                    label={label}
                    action={handleArchived}
                    labelAction={labelAction}
                    actionButton={actionButton}
                />
            ) : null}
        </div>
    )
}

export default InvoiceList
