import React, { useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import { Calendar } from 'react-date-range'
import { fr } from 'date-fns/locale'
import classNames from 'classnames'
import { NavArrowDown } from 'iconoir-react'
import { format } from 'date-fns'
import Lottie from 'lottie-react'
import animationData from '../../../files/animations/lock.json'

const useOnClickUserMenuOutside = (ref, outsideHandler, clickHandler) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                // clickHandler(event)
            } else {
                outsideHandler(event)
            }
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, outsideHandler, clickHandler])
}

const CalendarDatePickerV2 = ({
    openSelectDatePicker,
    setOpenSelectDatePicker,
    isPlanningLocked,
    isFirstLoadingPlanning = true,
    handleLockPlanningAnimation,
    plannings,
    date,
    setDate,
    label,
    reversedOpening,
    lockRef,
}) => {
    const handleSelect = (d) => {
        setDate(d)
        setOpenSelectDatePicker(false)
    }

    function customDayContent(day) {
        // si le jour est le 7 novembre 2023
        // check if day is included in plannings
        const planning = plannings?.find((p) => {
            return dayjs(day).isSame(dayjs(p.date), 'day') && p.isLocked
        })
        if (dayjs(day).isSame('2023-11-07', 'day')) {
            console.log(planning)
        }

        // check if the day is the day selected
        const isSelected = dayjs(day).isSame(dayjs(date), 'day')

        return (
            <div>
                <span
                    className={`!font-semibold ${
                        planning && !isSelected ? '!text-green-500' : ''
                    }`}
                >
                    {format(day, 'd')}
                </span>
            </div>
        )
    }

    const datePickerRef = useRef()

    useOnClickUserMenuOutside(
        datePickerRef,
        () => setOpenSelectDatePicker(false),
        () => setOpenSelectDatePicker(true)
    )

    return (
        <div className="relative flex flex-col text-black">
            {label && (
                <label
                    className={
                        'block pb-2 text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs'
                    }
                >
                    {label}
                </label>
            )}
            {openSelectDatePicker && (
                <div
                    className={classNames('absolute z-50 ', {
                        'bottom-0': reversedOpening,
                    })}
                    ref={datePickerRef}
                >
                    <Calendar
                        date={date}
                        dayContentRenderer={customDayContent}
                        onChange={handleSelect}
                        className="mobileRdrCalendarWrapper"
                        locale={fr}
                        color={'#8aafb0'}
                    />
                </div>
            )}
            <div className={'flex'}>
                <button
                    onClick={() =>
                        setOpenSelectDatePicker(!openSelectDatePicker)
                    }
                    className="flex w-64 cursor-pointer flex-row text-2xl font-semibold capitalize"
                >
                    {dayjs(date).format('dddd D MMMM')} <NavArrowDown />
                </button>
                {!isFirstLoadingPlanning && (
                    <button onClick={handleLockPlanningAnimation}>
                        <Lottie
                            animationData={animationData}
                            onDOMLoaded={() => {
                                if (isPlanningLocked) {
                                    lockRef.current?.playSegments(
                                        [80, 140],
                                        true
                                    )
                                } else {
                                    lockRef.current?.playSegments([0, 60], true)
                                }
                            }}
                            loop={false}
                            lottieRef={lockRef}
                            style={{ width: 35, height: 35 }}
                        />
                    </button>
                )}
            </div>
        </div>
    )
}

export default CalendarDatePickerV2
