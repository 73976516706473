import FormSwitch from '../../Commons/Buttons/FormSwitch'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import SelectDropDownListV2 from '../../Commons/DropDownLists/SelectDropDownListV2'
import { BirthRank } from '../../../services/BirthRank'
import ClassicTextarea from '../../Commons/Inputs/ClassicTextarea'
import PatientAddresses from '../../Commons/PatientAdresses'
import PatientNotifications from '../../Commons/PatientNotifications'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import React, { useReducer } from 'react'
import FormDateInput from '../../Commons/Inputs/FormDateInput'
import HealthComplementaryForm from '../Regulation/Forms/HealthComplementaryForm'
import {
    getComplementaryInitialState,
    HEALTH_COMPLEMENTARY_ACTIONS,
    healthComplementaryFormReducer,
} from '../Regulation/Reducers/HealthComplementaryFormReducer'
import { CheckCircle, Healthcare, Lock, User, UserSquare } from 'iconoir-react'
import ClassicInputV2 from '../../Commons/Inputs/ClassicInputV2'
import { PATIENT_FORM_ACTIONS } from '../Regulation/Reducers/PatientFormReducer'
import { handleHealthComplementaryForm } from '../Regulation/FormHandlers/handleHealthComplementaryForm'
import { formatSocialNumber } from './PEC/PecModal'
import ModalSkeleton from '../../Modal/ModalSkeleton'
import { toast } from 'react-toastify'
import {
    getPayingCenterInitialState,
    PAYING_CENTER_FORM_ACTIONS,
    PayingCenterFormReducer,
} from '../Regulation/Reducers/PayingCenterFormReducer'
import BeneficiaryForm from './BeneficiaryForm'
import {
    handleBeneficiaryForm,
    removeBeneficiary,
} from '../../../services/patient'
import { getKeyType } from '../../../tools/Utility'
import {
    getDateReducerFormatForApi,
    isDateValid,
} from '../../../services/DateService'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Patient } from '../../../services/API/Entities/patient'
import PECBanner from './PEC/PECBanner'
import PayingCenterList from '../Regulation/Forms/PayingCenterList'
import { PayingCenters } from '../../../services/API/Entities/payingCenters'
import {
    BeneficiaryFormReducer,
    getBeneficiaryInitialState,
} from '../../../services/Reducers/BeneficiaryReducer'

export const formatCaisseLabel = (caisse) => {
    if (caisse?.label) {
        return {
            label: `${caisse.label}  ${caisse.codeRegime} ${caisse.organismeDestinataire} ${caisse.codeCentreGestionnaire} `,
            value: caisse['@id'],
            object: caisse,
            id: caisse.id,
            '@id': caisse['@id'] || `/paying_centers/${caisse.id}`,
        }
    }
}

const PatientFormContent = ({
    fromModal,
    dispatch,
    patient,
    dateDispatch,
    date,
    id,
    handleShowPatientRuns,
}) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [showBanner, setShowBanner] = React.useState(false)
    const [patientInfo, setPatientInfo] = React.useState(null)
    const [beneficiaryForm, beneficiaryDispatch] = React.useReducer(
        BeneficiaryFormReducer,
        { beneficiary: patient.beneficiary },
        getBeneficiaryInitialState
    )

    const { mutate: postPecPlusMutation, isPending: isPecLoading } =
        useMutation({
            mutationFn: Patient.requestPecInfo,
            onSuccess: (data) => handlePecInfo(data),
            onError: (error) => {
                console.log(error)
            },
        })

    const handlePecInfo = (pecInfo) => {
        if (pecInfo.error) {
            toast.info(pecInfo.error.message)
        } else {
            setShowBanner(true)
            setPatientInfo(pecInfo.patientInfo)
            if (pecInfo.patientInfo.cmu) {
                handleHeatlhComplementaryChange(pecInfo.patientInfo.cmu, 'cmu')
            }
            if (pecInfo.patientInfo.ame) {
                handleHeatlhComplementaryChange(pecInfo.patientInfo.ame, 'ame')
            }
            if (pecInfo.patientInfo.payingCenter) {
                handlePayingCenterChange(
                    formatCaisseLabel(pecInfo.patientInfo.payingCenter)
                )
            }
            if (pecInfo.patientInfo.ald) {
                dispatch({
                    type: PATIENT_FORM_ACTIONS.SET_IS_ALD,
                    payload: true,
                })
            }
        }
    }

    const { mutate: postPayingCenter, isPending: isPendingPayingCenter } =
        useMutation({
            mutationFn: PayingCenters.post,
            onSuccess: (data) => {
                handlePayingCenterChange(formatCaisseLabel(data))
                handleClosePayingCenterModal()
                toast.success('Centre payeur ajouté avec succès')
            },
            onError: (error) => {
                console.log(error)
                toast.error(`${error.message}`)
            },
        })

    const { data: payingCenters } = useQuery({
        queryKey: ['payingCenterList'],
        queryFn: PayingCenters.fetchAllList,
    })

    const label =
        id != 0 ? 'Modification de la fiche' : "Ajout d'une nouvelle fiche"
    let initHealthComplementary = patient.healthComplementary
    if (patient.healthComplementary?.object) {
        initHealthComplementary = patient.healthComplementary.object
    }

    const [healthComplementary, healthComplementaryDispatch] = useReducer(
        healthComplementaryFormReducer,
        { initHealthComplementary },
        getComplementaryInitialState
    )

    let payingCenter = patient.payingCenter
    if (patient.payingCenter?.object) {
        payingCenter = patient.payingCenter.object
    }

    const [payingCenterForm, payingCenterDispatch] = useReducer(
        PayingCenterFormReducer,
        { payingCenter },
        getPayingCenterInitialState
    )

    const handleHealthComplementaryModalForm = async () => {
        setIsLoading(true)
        const formResponse = await handleHealthComplementaryForm(
            healthComplementary
        )
        if (formResponse.success) {
            handleCloseHealthComplementaryModal()
            dispatch({
                type: PATIENT_FORM_ACTIONS.SET_HEALTH_COMPLEMENTARY,
                payload: formResponse.data,
            })
            healthComplementaryDispatch({
                type: HEALTH_COMPLEMENTARY_ACTIONS.FETCH_HEALTH_COMPLEMENTARY,
                payload: formResponse.data,
            })
        }
        setIsLoading(false)
    }
    const handleClosePayingCenterModal = () => {
        dispatch({
            type: PATIENT_FORM_ACTIONS.SHOW_PAYING_CENTER_MODAL,
            payload: false,
        })
    }

    const handleShowBeneficiaryModal = () => {
        dispatch({
            type: PATIENT_FORM_ACTIONS.SHOW_BENEFICIARY_MODAL,
        })
    }

    const handleCloseHealthComplementaryModal = () => {
        dispatch({
            type: PATIENT_FORM_ACTIONS.SHOW_HEALTH_COMPLEMENTARY_MODAL,
            payload: false,
        })
    }

    const handleHeatlhComplementaryChange = (e, special = null) => {
        if (e) {
            let complementaire = e
            if (e.object) {
                complementaire = e.object
            }
            if (!patient.id) {
                if (special === 'ame') {
                    //    get health complementary form options
                    complementaire = patient.healthComplementaryOptions.find(
                        (option) => option.object.numId === '75500017'
                    )
                } else if (special === 'cmu') {
                    //    get health complementary form options
                    complementaire = patient.healthComplementaryOptions.find(
                        (option) => option.object.numId === '99999997'
                    )
                }
            }
            dispatch({
                type: PATIENT_FORM_ACTIONS.SET_HEALTH_COMPLEMENTARY,
                payload: complementaire,
            })
            healthComplementaryDispatch({
                type: HEALTH_COMPLEMENTARY_ACTIONS.FETCH_HEALTH_COMPLEMENTARY,
                payload: complementaire,
            })
        } else {
            dispatch({
                type: PATIENT_FORM_ACTIONS.SET_HEALTH_COMPLEMENTARY,
                payload: null,
            })
            healthComplementaryDispatch({
                type: HEALTH_COMPLEMENTARY_ACTIONS.CLEAR_INPUT,
            })
        }
    }

    const handlePayingCenterChange = (e) => {
        if (e) {
            dispatch({
                type: PATIENT_FORM_ACTIONS.SET_PAYING_CENTER,
                payload: e,
            })
            payingCenterDispatch({
                type: PAYING_CENTER_FORM_ACTIONS.FETCH_PAYING_CENTER,
                payload: e.object,
            })
        } else {
            dispatch({
                type: PATIENT_FORM_ACTIONS.SET_PAYING_CENTER,
                payload: null,
            })
            payingCenterDispatch({
                type: PAYING_CENTER_FORM_ACTIONS.CLEAR_INPUT,
            })
        }
    }

    const handleBeneficiaryModalForm = async () => {
        setIsLoading(true)
        const formBeneficiaryResponse = await handleBeneficiaryForm(
            patient,
            beneficiaryForm
        )
        if (formBeneficiaryResponse.success) {
            dispatch({
                type: PATIENT_FORM_ACTIONS.SET_PARENT,
                payload: formBeneficiaryResponse.data,
            })
        }
        setIsLoading(false)
        handleShowBeneficiaryModal()
    }

    const handleAddNewBeneficiary = (e) => {
        if (e) {
            // check if the beneficiary is already in the list
            const isBeneficiaryAlreadyInList = patient.beneficiaries?.find(
                (beneficiary) => beneficiary['@id'] === e.value
            )

            // check if the beneficiary is not the patient himself
            if (e.value !== patient['@id'] && !isBeneficiaryAlreadyInList) {
                dispatch({
                    type: PATIENT_FORM_ACTIONS.ADD_FORM_BENEFICIARY,
                    payload: e.patient,
                })
            } else {
                toast.info('Le bénéficiaire est déjà dans la liste')
            }
        } else {
            dispatch({
                type: PATIENT_FORM_ACTIONS.ADD_FORM_BENEFICIARY,
                payload: null,
            })
        }
    }

    const handleSelectBeneficiaryLink = (e) => {
        dispatch({
            type: PATIENT_FORM_ACTIONS.SELECT_BENEFICIARY_LINK,
            payload: e,
        })
    }

    const handleRemoveBeneficiary = async (selectedBeneficiary) => {
        const response = await removeBeneficiary(patient, selectedBeneficiary)
        dispatch({
            type: PATIENT_FORM_ACTIONS.GET_PATIENT,
            payload: response.data,
        })
        handleShowBeneficiaryModal()
    }

    const checkPecErrors = (patient, date) => {
        const errorMessages = []
        let socialNumber = ''

        if (patient.socialNumber && patient.socialNumber.length >= 13) {
            if (patient.socialNumber.length === 13) {
                socialNumber =
                    patient.socialNumber + getKeyType(patient.socialNumber)
            } else if (patient.socialNumber.length === 15) {
                socialNumber = patient.socialNumber
            }
        } else {
            errorMessages.push('Le numéro de sécurité sociale est incorrect')
        }

        if (!isDateValid(date)) {
            errorMessages.push('La date de naissance est incorrecte')
        }

        const hasError = errorMessages.length > 0

        return { hasError, errorMessages, socialNumber }
    }

    const handlePecPlus = (patient, date) => {
        const { hasError, errorMessages, socialNumber } = checkPecErrors(
            patient,
            date
        )

        if (hasError) {
            toast.error(errorMessages.join(' et '), { autoClose: false })
        } else {
            dispatch({
                type: PATIENT_FORM_ACTIONS.SET_SOCIAL_NUMBER,
                payload: socialNumber,
            })

            const dateToSend = getDateReducerFormatForApi(date, true)
            const data = {
                socialNumber: socialNumber,
                date: dateToSend,
                // Ajoutez d'autres champs nécessaires ici
            }
            if (data.socialNumber && data.date) {
                postPecPlusMutation(data)
            } else {
                toast.error('Erreur veuillez contacter un administrateur')
            }
        }
    }

    const handleSelectPayingCenter = async (payingCenterFromList) => {
        const data = {
            label: payingCenterFromList.label,
            codeRegime: payingCenterFromList.codeRegime,
            codeCaisse: payingCenterFromList.codeCaisse,
            organismeDestinataire: payingCenterFromList.organismeDestinataire,
            codeCentreGestionnaire: payingCenterFromList.codeCentreGestionnaire,
            codeCentreInformatique: payingCenterFromList.codeCentreInformatique,
        }

        await postPayingCenter(data)
    }

    return (
        <div className="flex w-full flex-col ">
            <PECBanner
                patientInfo={patientInfo}
                setShowBanner={setShowBanner}
                showBanner={showBanner}
            />
            <div className="flex w-full items-center justify-between px-2">
                <h1 className="py-10 pl-4 text-2xl font-semibold lg:pl-0 ">
                    {!fromModal && label}
                </h1>
                <div>
                    <FormSwitch
                        leftLabel="Patient"
                        rightLabel="Client"
                        valueLeft={patient.isPatient}
                        valueRight={!patient.isPatient}
                        onClickLeft={() => {
                            dispatch({
                                type: PATIENT_FORM_ACTIONS.IS_PATIENT,
                                payload: true,
                            })
                        }}
                        onClickRight={() => {
                            dispatch({
                                type: PATIENT_FORM_ACTIONS.IS_PATIENT,
                                payload: false,
                            })
                        }}
                    />
                </div>
            </div>
            <div className="w-full flex-col rounded-xl bg-white px-5 py-5 text-lg shadow-md lg:px-7 lg:text-base">
                <div className="flex flex-col space-y-8 sm:grid sm:grid-cols-2 sm:gap-4 lg:space-y-0">
                    <div className="col-span-1 flex items-center space-x-2  pb-5 text-xl font-bold">
                        <p>Information générale </p> <UserSquare />
                    </div>
                    <div className={'col-span-1 ml-auto'}>
                        {patient.isPatient && (
                            <PrimaryButton
                                label={'Consulter PEC+'}
                                action={() => handlePecPlus(patient, date)}
                                loader={isPecLoading}
                                customIconClass={'pr-2'}
                                icon={
                                    checkPecErrors(patient, date).hasError ? (
                                        <Lock />
                                    ) : (
                                        <CheckCircle />
                                    )
                                }
                            />
                        )}
                    </div>
                    <div className="col-span-1 flex w-full ">
                        <ClassicInput
                            label="Prénom *"
                            className="w-full"
                            value={patient.firstname}
                            setValue={(e) =>
                                dispatch({
                                    type: PATIENT_FORM_ACTIONS.SET_FIRSTNAME,
                                    payload: e.target.value,
                                })
                            }
                            id="firstname"
                            placeholder="Jane"
                            showLabel={true}
                        />
                    </div>
                    <div className="col-span-1 ">
                        <ClassicInput
                            label="Nom de famille *"
                            value={patient.lastname}
                            className="w-full"
                            setValue={(e) =>
                                dispatch({
                                    type: PATIENT_FORM_ACTIONS.SET_LASTNAME,
                                    payload: e.target.value,
                                })
                            }
                            id="lastname"
                            placeholder="Dupont"
                            showLabel={true}
                        />
                    </div>
                    <div className="col-span-1  ">
                        <ClassicInput
                            label="Téléphone"
                            className="w-full"
                            value={patient.phoneNumber}
                            setValue={(e) =>
                                dispatch({
                                    type: PATIENT_FORM_ACTIONS.SET_PHONE_NUMBER,
                                    payload: e.target.value,
                                })
                            }
                            id="phone"
                            placeholder="Téléphone"
                            showLabel={true}
                        />
                    </div>
                    {patient.isPatient && (
                        <>
                            <div className="col-span-1 ">
                                <ClassicInput
                                    label="Numéro de sécurité sociale"
                                    className="w-full"
                                    pattern="[A-Za-z0-9]{1,15}"
                                    value={formatSocialNumber(
                                        patient.socialNumber
                                    )}
                                    setValue={(e) =>
                                        dispatch({
                                            type: PATIENT_FORM_ACTIONS.SET_SOCIAL_NUMBER,
                                            payload: e.target.value,
                                        })
                                    }
                                    id="socialSecurityNumber"
                                    placeholder={'Numéro de sécurité sociale'}
                                    showLabel={true}
                                />
                            </div>
                            <div className={'w-full '}>
                                <FormDateInput
                                    isBirthDate={true}
                                    dispatch={dateDispatch}
                                    date={date}
                                    label={'Date de naissance'}
                                    isLunarDate={patient.isLunarDate}
                                />
                            </div>
                            <div className="col-span-1 w-full lg:w-1/2">
                                <ClassicSwitch
                                    label="Date étrangère"
                                    value={patient.isLunarDate}
                                    setValue={() =>
                                        dispatch({
                                            type: PATIENT_FORM_ACTIONS.SET_IS_LUNAR_DATE,
                                            payload: !patient.isLunarDate,
                                        })
                                    }
                                    info={true}
                                    infoLabel={
                                        'Permet de rentrer des dates non classique'
                                    }
                                />
                            </div>
                            <div className="flex w-full space-x-4 pb-10  lg:justify-center lg:pb-0 ">
                                <SelectDropDownListV2
                                    label="Caisse"
                                    options={patient.payingCentersOptions}
                                    value={patient.payingCenter}
                                    handleOptionChange={
                                        handlePayingCenterChange
                                    }
                                    addOption={() =>
                                        dispatch({
                                            type: PATIENT_FORM_ACTIONS.SHOW_PAYING_CENTER_MODAL,
                                            payload: true,
                                        })
                                    }
                                    isClearable={true}
                                />
                            </div>
                            <div className="flex flex-col lg:flex-row lg:items-center">
                                <SelectDropDownListV2
                                    placeholder={'Sélectionner un rang'}
                                    label={'Rang de naissance'}
                                    options={BirthRank}
                                    value={patient.birthRank}
                                    handleOptionChange={(e) =>
                                        dispatch({
                                            type: PATIENT_FORM_ACTIONS.SET_BIRTH_RANK,
                                            payload: e,
                                        })
                                    }
                                    isClearable={false}
                                />
                            </div>
                            <div className="col-span-2 flex items-center justify-between space-x-4 py-4 ">
                                <div className={'flex w-3/4 flex-col lg:w-1/2'}>
                                    <div className={'flex flex-col'}>
                                        <p className="text-lg font-semibold">
                                            ALD ou 100%
                                        </p>
                                        <p className="">
                                            Le patient est-il en ALD ou en 100%
                                            ?
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        'flex w-1/4 items-center justify-center lg:w-1/2'
                                    }
                                >
                                    <ClassicSwitch
                                        setValue={() =>
                                            dispatch({
                                                type: PATIENT_FORM_ACTIONS.SET_IS_ALD,
                                                payload: !patient.isAld,
                                            })
                                        }
                                        value={patient.isAld}
                                    />
                                </div>
                            </div>
                        </>
                    )}
                    <div className="col-span-1 w-full pb-4 pt-2">
                        <ClassicTextarea
                            value={patient.description}
                            className="w-full"
                            setValue={(e) =>
                                dispatch({
                                    type: PATIENT_FORM_ACTIONS.SET_DESCRIPTION,
                                    payload: e.target.value,
                                })
                            }
                            showLabel={true}
                            label="Commentaire"
                        />
                    </div>
                    {patient.beneficiaries?.length === 0 &&
                    !patient.parent &&
                    patient.isPatient &&
                    patient.firstname &&
                    patient.lastname ? (
                        <div className="col-span-1 w-full pb-4 pt-2">
                            <PrimaryButton
                                label={'Rattacher le patient'}
                                title={'Rattacher le patient'}
                                action={handleShowBeneficiaryModal}
                            />
                            {patient.beneficiaries?.length > 0 && (
                                <div className="flex w-full space-x-2 pt-4">
                                    {patient.beneficiaries.map(
                                        (beneficiary) => (
                                            <button
                                                className={'flex'}
                                                key={beneficiary.id}
                                                onClick={() => {
                                                    dispatch({
                                                        type: PATIENT_FORM_ACTIONS.SELECT_BENEFICIARY,
                                                        payload: beneficiary,
                                                    })
                                                }}
                                            >
                                                <div
                                                    className={
                                                        'flex items-center rounded-full border border-secondary-color p-2 hover:scale-105'
                                                    }
                                                >
                                                    <User className={'mr-2'} />
                                                    <p>
                                                        {beneficiary.lastname}{' '}
                                                        {beneficiary.firstname}
                                                    </p>
                                                </div>
                                            </button>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    ) : null}
                    {patient.beneficiaries &&
                    patient.beneficiaries.length > 0 ? (
                        <div className="col-span-1 w-full pb-4 pt-2">
                            <h2 className={'pb-3 text-xl font-semibold'}>
                                Bénéficiaires rattachés :
                            </h2>
                            <div className="flex w-full space-x-2 pt-4">
                                {patient.beneficiaries.map((beneficiary) => (
                                    <button
                                        className={'flex'}
                                        key={beneficiary.id}
                                        onClick={() => {
                                            dispatch({
                                                type: PATIENT_FORM_ACTIONS.SELECT_BENEFICIARY,
                                                payload: beneficiary,
                                            })
                                        }}
                                    >
                                        <div
                                            className={
                                                'flex items-center rounded-full border border-secondary-color p-2 hover:scale-105'
                                            }
                                        >
                                            <User className={'mr-2'} />
                                            <p>
                                                {beneficiary.lastname}{' '}
                                                {beneficiary.firstname}
                                            </p>
                                        </div>
                                    </button>
                                ))}
                            </div>
                        </div>
                    ) : null}
                    {patient.parent ? (
                        <div className="col-span-1 w-full pb-4 pt-2">
                            <h2 className={'pb-3 text-xl font-semibold'}>
                                Patient rattaché à :
                            </h2>
                            <button
                                className={'flex'}
                                onClick={() => {
                                    dispatch({
                                        type: PATIENT_FORM_ACTIONS.SELECT_BENEFICIARY,
                                        payload: patient.parent,
                                    })
                                }}
                            >
                                <div
                                    className={
                                        'flex items-center rounded-full border border-secondary-color p-2 hover:scale-105'
                                    }
                                >
                                    <User className={'mr-2'} />
                                    <p>
                                        {patient.parent.lastname}{' '}
                                        {patient.parent.firstname}
                                    </p>
                                </div>
                            </button>
                            <div className={'ml-2 flex items-center'}>
                                <i className={'fa fa-link mr-2'} />
                                <p>{patient.beneficiaryLink}</p>
                            </div>
                        </div>
                    ) : null}
                    {patient.isPatient ? (
                        <>
                            <div className="col-span-2 flex items-center space-x-2 border-b-2 pb-5 text-xl font-bold">
                                <p>Complémentaire santé </p>{' '}
                                <Healthcare width={40} height={40} />
                            </div>
                            <SelectDropDownListV2
                                label={'Complémentaire Santé'}
                                placeholder={'complémentaire...'}
                                options={patient.healthComplementaryOptions}
                                value={patient.healthComplementary}
                                isClearable={true}
                                handleOptionChange={
                                    handleHeatlhComplementaryChange
                                }
                                addOption={() => {
                                    if (!healthComplementary.special) {
                                        dispatch({
                                            type: PATIENT_FORM_ACTIONS.SHOW_HEALTH_COMPLEMENTARY_MODAL,
                                            payload: true,
                                        })
                                    } else {
                                        toast.info(
                                            'Impossible de modifier cette complémentaire santé'
                                        )
                                    }
                                }}
                            />
                            <ClassicInputV2
                                label="Numéro assuré"
                                showLabel={true}
                                className="w-full "
                                value={patient.membershipNumber}
                                setValue={(e) =>
                                    dispatch({
                                        type: PATIENT_FORM_ACTIONS.SET_MEMBERSHIP_NUMBER,
                                        payload: e.target.value,
                                    })
                                }
                            />
                            <div className="col-span-2 flex items-center justify-between space-x-4 py-4 ">
                                <div className={'flex w-3/4 flex-col lg:w-1/2'}>
                                    <div className={'flex flex-col'}>
                                        <p className="text-lg font-semibold">
                                            Top mutualiste
                                        </p>
                                        <p className="">
                                            Faut-il activer le top M en cas tier
                                            payant ? ex : la caisse du patient
                                            et la complémentaire font partie de
                                            la même entité (MGEN)
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={
                                        'flex w-1/4 items-center justify-center lg:w-1/2'
                                    }
                                >
                                    <ClassicSwitch
                                        setValue={() =>
                                            dispatch({
                                                type: PATIENT_FORM_ACTIONS.SET_TOP_MUTUALISTE,
                                                payload:
                                                    !patient.isTopMutualiste,
                                            })
                                        }
                                        value={patient.isTopMutualiste}
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                    <PatientAddresses
                        patient={patient}
                        dispatch={dispatch}
                        PATIENT_ACTIONS={PATIENT_FORM_ACTIONS}
                    />
                    <PatientNotifications
                        patient={patient}
                        dispatch={dispatch}
                        PATIENT_ACTIONS={PATIENT_FORM_ACTIONS}
                    />
                    {parseInt(id) !== 0 && handleShowPatientRuns ? (
                        <div className="col-span-2 w-full pb-4">
                            <PrimaryButton
                                label={`
                                          Transport du
                                        ${
                                            patient.isPatient
                                                ? 'patient'
                                                : 'client'
                                        }`}
                                title={`
                                          Transport du
                                        ${
                                            patient.isPatient
                                                ? 'patient'
                                                : 'client'
                                        }`}
                                action={handleShowPatientRuns}
                            />
                        </div>
                    ) : null}
                </div>
            </div>
            {patient.showHealthComplementaryModal && (
                <ModalSkeleton
                    label={`${
                        healthComplementary['@id'] ? 'Modifier' : 'Ajouter'
                    } une complémentaire santé`}
                    handleCloseModal={handleCloseHealthComplementaryModal}
                    Component={HealthComplementaryForm}
                    componentProps={{
                        healthComplementary,
                        dispatch: healthComplementaryDispatch,
                        addressItem: healthComplementary.address,
                    }}
                    handleForm={handleHealthComplementaryModalForm}
                    buttonLabel={
                        healthComplementary['@id'] ? 'Modifier' : 'Ajouter'
                    }
                    disabledSubmit={isLoading}
                />
            )}
            {patient.showPayingCenterModal ? (
                <PayingCenterList
                    payingCenters={payingCenters}
                    handleCloseModal={handleClosePayingCenterModal}
                    handleSelectPayingCenter={handleSelectPayingCenter}
                    isPendingPayinCenter={isPendingPayingCenter}
                />
            ) : null}
            {patient.showBeneficiaryModal ? (
                <ModalSkeleton
                    handleCloseModal={handleShowBeneficiaryModal}
                    label={`Modifier le bénéficiaire`}
                    Component={BeneficiaryForm}
                    componentProps={{
                        beneficiary: patient.selectedBeneficiary,
                        handleSelectBeneficiaryLink,
                        handleAddNewBeneficiary,
                        handleRemoveBeneficiary,
                        beneficiaryForm,
                        dispatch: beneficiaryDispatch,
                    }}
                    buttonLabel={'Rattacher'}
                    disabledSubmit={isLoading}
                    hideSubmitButton={!!patient.selectedBeneficiary}
                    handleForm={handleBeneficiaryModalForm}
                />
            ) : null}
        </div>
    )
}
export default PatientFormContent
