import { RegimeList } from '../../../../services/RegimeList'
export const getRegimeLabel = (codeRegime) => {
    const regime = RegimeList.find((regime) => regime.value === codeRegime)
    return regime ? regime.label : codeRegime
}
const PayingCenter = ({ payingCenter, isSelected, onSelect }) => {
    return (
        <tr
            className={`${
                isSelected ? 'bg-light-verdois/50' : 'hover:bg-gray-50'
            } cursor-pointer`}
            onClick={onSelect}
        >
            <td className="group relative whitespace-nowrap px-6 py-4">
                <span className="truncate-label inline-block max-w-52">
                    {payingCenter.label}
                </span>
                <span className="absolute left-0 top-full z-10 hidden min-w-max rounded bg-gray-800 p-2 text-white group-hover:block">
                    {payingCenter.label}
                </span>
            </td>
            <td className="hidden whitespace-nowrap px-2 py-4 lg:block">
                {payingCenter.organismeDestinataire}
            </td>
            <td className="group relative whitespace-nowrap px-6 py-4">
                <span className="truncate-label inline-block max-w-xs">
                    {getRegimeLabel(payingCenter.codeRegime)}
                </span>
                <span className="absolute left-0 top-full z-10 hidden min-w-max rounded bg-gray-800 p-2 text-white group-hover:block">
                    {getRegimeLabel(payingCenter.codeRegime)}
                </span>
            </td>
            <td className="hidden whitespace-nowrap px-2 py-4 lg:block">
                {payingCenter.codeCentreGestionnaire}
            </td>
        </tr>
    )
}

export default PayingCenter
