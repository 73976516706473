import React from 'react'
import useConfiguratorStore from '../../stores/Configurator'

const Notification = () => {
    const show = useConfiguratorStore((state) => state.show)
    const popUpText = useConfiguratorStore((state) => state.popUpText)
    const notificationType = useConfiguratorStore(
        (state) => state.notificationType
    )
    const setShow = useConfiguratorStore((state) => state.setShow)

    // information
    let color = 'blue'
    let icon = 'info'
    if (notificationType === 'Succès') {
        color = 'green'
        icon = 'check'
    } else if (notificationType === 'Attention') {
        color = 'yellow'
        icon = 'exclamation'
    } else if (notificationType === 'Erreur') {
        color = 'red'
        icon = 'times'
    }

    return (
        <div
            className={`fixed top-0 right-0 mr-5 z-1000 flex text-gray-700 popup ${show}
            `}
        >
            <div
                className={`modal-shadow rounded-modal bg-white w-full w-notif transform ${
                    show && 'translate-y-6 duration-700'
                }`}
            >
                <div className="flex flex-col w-full">
                    <div className="flex border-b text-sm">
                        <div className="flex items-center justify-between p-2 px-4 w-full">
                            <div className="flex items-center p-2 px-4">
                                <i
                                    className={`fas fa-${icon}-circle text-2xl text-${color}-500 items-center justify-center pr-4`}
                                />
                                <div className="flex font-semibold text-gray-700">
                                    {notificationType}
                                </div>
                            </div>
                            <div>
                                <i
                                    className="fas fa-times text-xl cursor-pointer text-gray-700 items-center justify-center pr-4"
                                    onClick={() => setShow('')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center text-sm">
                        <div className="p-4">
                            <div
                                className="inline-block "
                                dangerouslySetInnerHTML={{
                                    __html: popUpText,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={`w-full h-1 border-line bg-${color}-600`}>
                    <div
                        className={`bg-${color}-500 h-full pl-1 border-line ${
                            show && 'animate-progress'
                        }`}
                    />
                </div>
            </div>
        </div>
    )
}

export default Notification
