import ClassicInput from '../../Commons/Inputs/ClassicInput'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import { TwitterPicker } from 'react-color'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { ACTIONS } from '../../../services/Reducers/WelcomeModalReducer'
import VehicleColors from '../../../services/VehicleColors'
import API from '../../../services/api'
import WelcomeTitle from '../../Commons/WelcomeTitle'
import PreviousButton from '../../Commons/PreviousButton'
import NextButton from '../../Commons/NextButton'
import {
    ERROR_ACTIONS,
    ErrorReducer,
} from '../../../services/Reducers/ErrorReducer'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'

const WelcomeVehicleManage = ({
    welcome,
    dispatch,
    previousStep,
    nextStep,
    dispatchError,
    handleLoading,
}) => {
    const colorsPopupRef = useRef()
    const [openColorChoices, setOpenColorChoices] = useState(false)

    const handleLicensePlate = (value) => {
        dispatch({ type: ACTIONS.SET_VEHICLE_LICENSE_PLATE, payload: value })
    }

    const handleLabel = (value) => {
        dispatch({ type: ACTIONS.SET_VEHICLE_LABEL, payload: value })
    }

    const handleType = (e) => {
        dispatch({ type: ACTIONS.SET_VEHICLE_TYPE, payload: e })
    }

    const handleColor = (color) => {
        dispatch({ type: ACTIONS.SET_VEHICLE_COLOR, payload: color.hex })
        setOpenColorChoices(false)
    }

    const handleVehicleId = (vehicle) => {
        dispatch({ type: ACTIONS.SET_VEHICLE_ID, payload: vehicle })
    }

    const checkErrors = () => {
        let printedErrors = []
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })
        if (
            !welcome.vehicle.licensePlate ||
            welcome.vehicle.licensePlate.trim() === ''
        ) {
            printedErrors.push({
                id: 'emptyLicensePlate',
                message: "Veuillez renseigner une plaque d'immatriculation.",
            })
        }

        if (printedErrors.length === 0) {
            return false
        } else {
            printedErrors.map((error) => {
                dispatchError({
                    type: ERROR_ACTIONS.SET_ERROR,
                    payload: error,
                })
            })
            return true
        }
    }

    const handleAddVehicle = () => {
        if (!checkErrors()) {
            handleLoading(true)
            const data = {
                licensePlate: welcome.vehicle.licensePlate,
                type: welcome.vehicle.type['@id'],
                active: true,
                label: welcome.vehicle.label,
                color: welcome.vehicle.color,
            }
            if (welcome.vehicle.id === null) {
                API.Vehicles.addVehicle(data)
                    .then((response) => {
                        response.json().then((vehicle) => {
                            handleLoading(false)
                            handleVehicleId(vehicle)
                            nextStep()
                        })
                    })
                    .catch((error) => {})
            } else {
                API.Vehicles.put(welcome.vehicle.id, data)
                    .then((response) => {
                        response.json().then((vehicle) => {
                            handleLoading(false)
                            handleVehicleId(vehicle)
                            nextStep()
                        })
                    })
                    .catch((error) => {})
            }
        }
    }

    return (
        <div>
            <div className="width-child-container-welcome-modal flex flex-col rounded-xl bg-white px-4 pt-6 shadow-md lg:px-16">
                <WelcomeTitle label="Nouveau véhicule" />
                <div className="height-31-rem flex flex-col pt-10">
                    <div className="sm:grid sm:grid-cols-2 sm:gap-4 ">
                        <div className="col-span-1 py-2">
                            <ClassicInput
                                label="Immatriculation *"
                                value={welcome.vehicle.licensePlate}
                                setValue={(e) =>
                                    handleLicensePlate(e.target.value)
                                }
                                required={true}
                                id="licensePlate"
                                placeholder="AV-153-XC"
                                showLabel={true}
                            />
                        </div>
                        <div className="col-span-1 py-2">
                            <ClassicInput
                                label="Label"
                                value={welcome.vehicle.label}
                                required={true}
                                setValue={(e) => handleLabel(e.target.value)}
                                id="label"
                                placeholder="Chevrolet rouge"
                                showLabel={true}
                            />
                        </div>
                        <div className="col-span-1 py-2">
                            <SelectDropDownList
                                icon=""
                                label="Type *"
                                options={
                                    welcome.parameters.defaultVehiclesTypes
                                }
                                value={welcome.vehicle.type}
                                id={0}
                                handleOptionChange={(e) => handleType(e)}
                                isDisabled={false}
                                showLabel={true}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col-span-1 py-2">
                            <label className="block text-xs font-bold uppercase text-gray-700">
                                Couleur du véhicule
                            </label>
                            <div
                                className="h-10 w-10 cursor-pointer rounded-xl border"
                                style={{
                                    backgroundColor: welcome.vehicle.color,
                                }}
                                onClick={() =>
                                    setOpenColorChoices(!openColorChoices)
                                }
                            />
                            {openColorChoices ? (
                                <div className="absolute" ref={colorsPopupRef}>
                                    <TwitterPicker
                                        colors={VehicleColors}
                                        onChangeComplete={(color) =>
                                            handleColor(color)
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full items-center justify-between py-4">
                <SecondaryButton
                    label="Précédent"
                    title="Précédent"
                    action={previousStep}
                    hiddenLabelOnMobile={false}
                />
                <PrimaryButton
                    label="Suivant"
                    title="Suivant"
                    action={handleAddVehicle}
                    hiddenLabelOnMobile={false}
                    loader={welcome.loading}
                />
            </div>
        </div>
    )
}

export default WelcomeVehicleManage
