import { findColorByTarifType } from '../../../../../services/InvoiceUtils'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import {
    formatDateLocal,
    formatTimeLocal,
} from '../../../../../services/dateUtils'

const PriceBasisPanel = ({ priceBasis }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    priceBasis.priceBasisLines = priceBasis.priceBasisLines.sort((a, b) => {
        if (a.tarifType < b.tarifType) {
            return -1
        }
        if (a.tarifType > b.tarifType) {
            return 1
        }
        return 0
    })
    return (
        <div className="mx-auto max-w-4xl rounded-lg bg-white py-2 sm:p-5 sm:py-0">
            <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={'flex w-full items-center justify-between border-b '}
            >
                <h2 className=" text-xl font-semibold text-gray-800">
                    Détail du tarif {priceBasis.label}
                </h2>
                <div>
                    {isExpanded ? (
                        <ChevronUpIcon className={'h-10 w-10'} />
                    ) : (
                        <ChevronDownIcon className={'h-10 w-10'} />
                    )}
                </div>
            </button>
            {isExpanded && (
                <>
                    <div className="mb-4 border-b py-4">
                        <p className="mb-1">
                            <strong>Convention:</strong> {priceBasis.convention}
                        </p>
                        <p className="mb-1">
                            <strong>Prise en charge:</strong>{' '}
                            {priceBasis.pecAmount.toFixed(2)} €
                        </p>
                        <p className="mb-1">
                            <strong>Période:</strong>
                            {formatDateLocal(priceBasis.dateFrom)} au{' '}
                            {formatDateLocal(priceBasis.dateTo)}
                        </p>
                        <p className="mb-1">
                            <strong>Prix horaire en attente:</strong>{' '}
                            {priceBasis.waitingHourlyRate.toFixed(2)} €
                        </p>
                        <p className="mb-1">
                            <strong>Prix horaire en journée:</strong>{' '}
                            {priceBasis.dayTimeHourlyRate.toFixed(2)} €
                        </p>
                        <p className="mb-1">
                            <strong>Prix horaire de nuit:</strong>{' '}
                            {priceBasis.nightTimeHourlyRate.toFixed(2)} €
                        </p>
                    </div>
                    {priceBasis.forfaits.length > 0 ? (
                        <div className="grid gap-4 border-b pb-4 md:grid-cols-2">
                            {priceBasis.forfaits.map((forfait) => (
                                <div key={forfait.id}>
                                    <p className="flex justify-between text-gray-600">
                                        <strong>
                                            De {forfait.minDistance} à{' '}
                                            {forfait.maxDistance} km{' '}
                                        </strong>
                                        <strong>{forfait.price} €</strong>
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <h3 className="pb-2 text-lg font-semibold text-gray-800">
                        Minimum perception
                    </h3>
                    {priceBasis.minimumPerceptions.length > 0 ? (
                        <div className="grid gap-4 border-b pb-4 md:grid-cols-2">
                            {priceBasis.minimumPerceptions.map((perception) => (
                                <div key={perception.id}>
                                    <p className="flex flex-wrap justify-between text-gray-600">
                                        <strong className={'flex'}>
                                            {perception.startFrom} -{' '}
                                            {perception.endAt}{' '}
                                            {perception.label
                                                ? `(${perception.label})`
                                                : ''}
                                        </strong>
                                        <strong>{perception.price} €</strong>
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div
                        className={`grid gap-4 md:grid-cols-2 ${
                            priceBasis.forfaits.length > 0 ||
                            priceBasis.minimumPerceptions.length > 0
                                ? 'pt-4'
                                : ''
                        } `}
                    >
                        {priceBasis.priceBasisLines.map((line) => (
                            <div
                                key={line.id}
                                className="rounded-lg bg-gray-50 p-4 shadow"
                            >
                                <div
                                    className={`h-6 w-6 rounded-full border text-center ${findColorByTarifType(
                                        line.tarifType
                                    )}`}
                                >
                                    {line.tarifType}
                                </div>
                                <p className="pt-2 text-gray-600">
                                    <strong>Prix:</strong> {line.price} €
                                </p>
                                <p className="text-gray-600">
                                    <strong>Horaire:</strong>{' '}
                                    {formatTimeLocal(line.startFrom)} à{' '}
                                    {formatTimeLocal(line.endAt)}
                                </p>
                                <p className="text-gray-600">
                                    <strong>En charge:</strong>{' '}
                                    {line.inCharge ? 'Oui' : 'Non'}
                                </p>
                                <p className="text-gray-600">
                                    <strong>Remise:</strong> {line.discount}%
                                </p>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    )
}

export default PriceBasisPanel
