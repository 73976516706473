import WelcomeTitle from '../../Commons/WelcomeTitle'
import PreviousButton from '../../Commons/PreviousButton'
import NextButton from '../../Commons/NextButton'
import React from 'react'
import classNames from 'classnames'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'

const WelcomeTemplate = ({
    title,
    content,
    previousStep,
    nextStep,
    label,
    lastView,
}) => {
    return (
        <div className="flex w-full flex-col items-center">
            <div className="width-child-container-welcome-modal rounded-xl bg-white px-4 py-6 shadow-md lg:px-16">
                <WelcomeTitle label={title} />
                <div className="height-31-rem overflow-y-scroll">{content}</div>
            </div>
            <div
                className={classNames('flex w-full items-center py-4', {
                    'justify-end': !previousStep,
                    'justify-between': previousStep,
                })}
            >
                {previousStep && (
                    <SecondaryButton
                        label="Précédent"
                        title="Précédent"
                        action={previousStep}
                        hiddenLabelOnMobile={false}
                    />
                )}

                <PrimaryButton
                    label={
                        lastView
                            ? "Accéder à l'application"
                            : label
                            ? label
                            : 'Suivant'
                    }
                    title={
                        lastView
                            ? "Accéder à l'application"
                            : label
                            ? label
                            : 'Suivant'
                    }
                    action={nextStep}
                    hiddenLabelOnMobile={false}
                />
            </div>
        </div>
    )
}

export default WelcomeTemplate
