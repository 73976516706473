import React, { useEffect } from 'react'
import { formatRunData } from '../../../services/FormatRuns'
import { INVOICE_FILTER_ACTIONS } from '../../../services/Reducers/InvoiceFilterReducer'
import LoaderModal from '../../Commons/Loaders/LoaderModal'
import * as dayjs from 'dayjs'
import API from '../../../services/api'
import useConfiguratorStore from '../../../stores/Configurator'
import { useNavigate } from 'react-router-dom'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'

export default function FilterAnnexes({
    invoiceFilter,
    dispatch,
    setOpenFilterModal,
}) {
    const setInvoiceRuns = useConfiguratorStore((state) => state.setInvoiceRuns)
    const history = useNavigate()
    const getRuns = () => {
        const between = {
            strictlyBefore: dayjs(invoiceFilter.dateTo).format('YYYY-MM-DD'),
            strictlyAfter: dayjs(invoiceFilter.dateFrom).format('YYYY-MM-DD'),
        }

        API.Runs.list(
            null,
            null,
            null,
            null,
            invoiceFilter.patient.value,
            null,
            null,
            300,
            null,
            null,
            null,
            null,
            invoiceFilter.series ? null : between,
            null,
            null,
            null,
            invoiceFilter.series
                ? invoiceFilter.series.value.split('/').reverse()[0]
                : null,
            null,
            null,
            null,
            invoiceFilter.object ? invoiceFilter.object.value : null
        ).then((data) => {
            setInvoiceRuns(data['hydra:member'])
            dispatch({
                type: INVOICE_FILTER_ACTIONS.SET_ANNEXES,
                payload: data['hydra:member'],
            })
        })
    }
    useEffect(() => {
        getRuns()
    }, [])

    return (
        <div className="shadow-modal mx-auto mt-20 flex w-full max-w-7xl flex-col rounded-lg bg-white">
            <div className="flex flex-col items-start p-4 lg:p-8">
                <div className="flex w-full items-center border-b pb-4 text-xl">
                    <div className="flex space-x-4 text-center font-semibold ">
                        Annexes ({invoiceFilter.annexes.length})
                    </div>
                    <svg
                        className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 18"
                        onClick={() => {
                            setOpenFilterModal(false)
                        }}
                    >
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                    </svg>
                </div>
                {invoiceFilter.loading ? (
                    <div className="min-h-modal flex w-full items-center justify-center">
                        <LoaderModal customWidth={'w-8'} customHeight={'h-8'} />
                    </div>
                ) : invoiceFilter.annexes.length > 0 ? (
                    <div className="invisible-scrollbar min-h-modal flex w-full flex-col items-start space-y-2 overflow-x-auto overflow-y-auto p-2 lg:p-4">
                        {formatRunData(invoiceFilter.annexes).map((annexe) => (
                            <div
                                key={annexe.id}
                                className="grid w-full grid-cols-2 gap-4 border-b p-2  lg:grid-flow-col lg:grid-cols-7 lg:p-4 "
                            >
                                <div>{annexe.date}</div>
                                <div>{annexe.patient}</div>
                                <div>{annexe.horaire}</div>
                                <div className="flex flex-col">
                                    <div>{annexe.equipe}</div>
                                    <div>{annexe.vehicle}</div>
                                </div>
                                <div className="flex flex-col">
                                    <div>{annexe.pickUpLocation}.</div>
                                    <div>{annexe.depositLocation}.</div>
                                </div>
                                <div className="flex flex-col lg:text-center">
                                    <div>{annexe.objet}</div>
                                    <div>{annexe.isReturnPath}</div>
                                </div>
                                {/*{invoiceFilter.annexes.length > 1 && (*/}
                                <div className="flex items-center">
                                    <span
                                        className={`flex inline-flex w-32 max-w-full justify-center rounded-full px-2 text-base font-semibold leading-5 text-white bg-${
                                            annexe.status === 'Fait'
                                                ? 'green-500 '
                                                : annexe.status === 'A Faire'
                                                ? 'gray-500 '
                                                : annexe.status === 'Facturé'
                                                ? 'purple-100 text-purple-600 '
                                                : 'orange-100 text-orange-600  '
                                        }`}
                                    >
                                        {annexe.status}
                                    </span>
                                    <svg
                                        className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 18 18"
                                        onClick={() => {
                                            dispatch({
                                                type: INVOICE_FILTER_ACTIONS.REMOVE_ANNEXE,
                                                payload: {
                                                    id: annexe.id,
                                                },
                                            })
                                        }}
                                    >
                                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                                    </svg>
                                </div>
                                {/*)}*/}
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="min-h-modal flex w-full flex-col items-center justify-center p-4">
                        <p
                            className={
                                'pb-4 text-lg font-bold text-light-dark-green'
                            }
                        >
                            Impossible de trouver des courses correspondant à
                            votre recherche
                        </p>
                        <button
                            className="ml-2 rounded border border-dark-green bg-transparent px-4 py-2 font-semibold text-dark-green hover:border-transparent hover:bg-light-blue-green hover:text-white"
                            onClick={() => {
                                dispatch({
                                    type: INVOICE_FILTER_ACTIONS.SWITCH_STEP,
                                    payload: 1,
                                })
                            }}
                        >
                            <i className="fas fa-chevron-left pr-2" /> Précédent
                        </button>
                    </div>
                )}
                <div className="mt-auto flex w-full justify-between border-t pt-5">
                    <SecondaryButton
                        label="Précédent"
                        title="Précédent"
                        action={() => {
                            dispatch({
                                type: INVOICE_FILTER_ACTIONS.SWITCH_STEP,
                                payload: 1,
                            })
                        }}
                    />
                    <PrimaryButton
                        label="Continuer vers la facturation"
                        title="Continuer vers la facturation"
                        action={() => {
                            setInvoiceRuns(invoiceFilter.annexes)
                            setOpenFilterModal(false)
                            history('/facturation/factures/0')
                        }}
                        disabled={
                            !invoiceFilter.patient ||
                            invoiceFilter.annexes.length === 0
                        }
                    />
                </div>
            </div>
        </div>
    )
}
