import ClassicInputV2 from '../../../Commons/Inputs/ClassicInputV2'
import { PRACTITIONER_FORM_ACTIONS } from '../Reducers/PractitionerFormReducer'
import FormSwitch from '../../../Commons/Buttons/FormSwitch'
import React from 'react'
import EstablishmentListDropdown from '../../Establishment/EstablishmentListDropdown'
import { toast } from 'react-toastify'

const PractitionerForm = ({ practitioner, dispatch }) => {
    return (
        <div className="flex w-full flex-col py-10">
            <div className="flex flex-col px-5 pb-10 lg:px-20">
                <div className={'pb-10'}>
                    <FormSwitch
                        id="practitioner"
                        valueLeft={!practitioner.isEstablishment}
                        leftLabel={'Praticien'}
                        rightLabel={'Établissement'}
                        valueRight={practitioner.isEstablishment}
                        onClickLeft={() =>
                            dispatch({
                                type: PRACTITIONER_FORM_ACTIONS.SET_IS_ESTABLISHMENT,
                                payload: false,
                            })
                        }
                        onClickRight={() =>
                            dispatch({
                                type: PRACTITIONER_FORM_ACTIONS.SET_IS_ESTABLISHMENT,
                                payload: true,
                            })
                        }
                    />
                </div>
                <div className="flex w-full pb-10 ">
                    <EstablishmentListDropdown
                        label={`${
                            practitioner.isEstablishment
                                ? 'Etablissement'
                                : 'Praticien'
                        } via annuaire.sante.fr`}
                        isEstablishment={practitioner.isEstablishment}
                        value={practitioner.establishment}
                        onChange={(selectedEstablishment) => {
                            if (selectedEstablishment) {
                                dispatch({
                                    type: PRACTITIONER_FORM_ACTIONS.SET_ESTABLISHMENT,
                                    payload: selectedEstablishment,
                                })
                                if (
                                    !selectedEstablishment.object
                                        .finnessLegalEntityNumber &&
                                    !selectedEstablishment.object.finnessNumber
                                ) {
                                    toast.info('Aucun numéro FINESS trouvé')
                                }
                            } else {
                                dispatch({
                                    type: PRACTITIONER_FORM_ACTIONS.SET_ESTABLISHMENT,
                                    payload: null,
                                })
                            }
                        }}
                    />
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {!practitioner.isEstablishment ? (
                        <>
                            <ClassicInputV2
                                label={'Nom'}
                                value={practitioner.lastname}
                                setValue={(e) =>
                                    dispatch({
                                        type: PRACTITIONER_FORM_ACTIONS.SET_LASTNAME,
                                        payload: e.target.value,
                                    })
                                }
                            />
                            <ClassicInputV2
                                label={'Prénom'}
                                value={practitioner.firstname}
                                setValue={(e) =>
                                    dispatch({
                                        type: PRACTITIONER_FORM_ACTIONS.SET_FIRSTNAME,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </>
                    ) : (
                        <div className={'col-span-2'}>
                            <ClassicInputV2
                                label={'Label'}
                                value={practitioner.label}
                                setValue={(e) =>
                                    dispatch({
                                        type: PRACTITIONER_FORM_ACTIONS.SET_ESTABLISHMENT_LABEL,
                                        payload: e.target.value,
                                    })
                                }
                            />
                        </div>
                    )}
                    <ClassicInputV2
                        label={'Numéro FINESS'}
                        value={practitioner.numFiness}
                        setValue={(e) =>
                            dispatch({
                                type: PRACTITIONER_FORM_ACTIONS.SET_NUM_FINESS,
                                payload: e.target.value,
                            })
                        }
                    />
                    <ClassicInputV2
                        label={'RPPS (Facultatif)'}
                        value={practitioner.numRPPS}
                        setValue={(e) =>
                            dispatch({
                                type: PRACTITIONER_FORM_ACTIONS.SET_NUM_RPPS,
                                payload: e.target.value,
                            })
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default PractitionerForm
