import React from 'react'

const Return = ({ row }) => {
    return (
        <div className="w-12 px-6">
            <div className="flex items-center align-middle ">
                <i
                    className={`${
                        row.isReturnPath
                            ? 'far fa-registered fa-lg  '
                            : 'hidden'
                    }`}
                />
            </div>
        </div>
    )
}

export default Return
