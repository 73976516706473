import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js/auto'

const TransportStatusChart = ({ data }) => {
    const chartRef = useRef(null)
    const chartInstance = useRef(null)

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy()
        }

        const fullLabels = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
        ]

        const myChartRef = chartRef.current.getContext('2d')

        chartInstance.current = new Chart(myChartRef, {
            type: 'bar',
            data: {
                labels: [
                    'Jan',
                    'Fév',
                    'Mar',
                    'Avr',
                    'Mai',
                    'Juin',
                    'Juil',
                    'Août',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Déc',
                ],
                datasets: [
                    {
                        label: 'Clôturé',
                        data: data.closed,
                        backgroundColor: 'rgb(168,85,247,0.7)',
                        borderRadius: 5,
                    },
                    {
                        label: 'Fait',
                        data: data.done,
                        backgroundColor: 'rgb(34,197,94,0.7)',
                        borderRadius: 5,
                    },
                    {
                        label: 'Facturé',
                        data: data.charged,
                        backgroundColor: 'rgb(184,147,117)',
                        borderRadius: 5,
                    },
                    {
                        label: 'En cours',
                        data: data.ongoing,
                        backgroundColor: 'rgb(59,130,246,0.7)',
                        borderRadius: 5,
                    },
                    {
                        label: 'Transféré',
                        data: data.transmitted,
                        backgroundColor: 'rgba(244,131,182,0.67)',
                        borderRadius: 5,
                    },
                    {
                        label: 'A faire',
                        data: data.todo,
                        backgroundColor: 'rgb(107,114,128,0.7)',
                        borderRadius: 5,
                    },
                    {
                        label: 'Annulé',
                        data: data.canceled,
                        backgroundColor: 'rgba(255,115,31,0.7)',
                        borderRadius: 5,
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false, // Cacher les labels en haut du graphique
                    },
                    tooltip: {
                        backgroundColor: 'rgb(255,255,255)', // Couleur de fond du tooltip
                        borderColor: 'rgb(214,214,214)', // Couleur de la bordure du tooltip
                        borderWidth: 1, // Largeur de la bordure du tooltip
                        mode: 'index',
                        intersect: false,
                        titleColor: 'rgb(107,114,128)',
                        bodyColor: 'rgb(107,114,128)',
                        footerColor: 'rgb(107,114,128)',
                        titleFont: {
                            family: 'Inter, sans-serif', // Changer la police du titre du tooltip
                            size: 20, // Changer la taille du texte du titre du tooltip
                            weight: 600, // Changer le poids du texte du titre du tooltip
                            height: 1.2, // Changer la hauteur du texte du titre du tooltip
                        },
                        bodyFont: {
                            family: 'Inter, sans-serif', // Changer la police du corps du tooltip
                            size: 16, // Changer la taille du texte du corps du tooltip
                            weight: 'normal', // Changer le poids du texte du corps du tooltip
                        },
                        footerFont: {
                            family: 'Inter, sans-serif', // Changer la police du pied du tooltip
                            size: 16, // Changer la taille du texte du pied du tooltip
                            weight: 'normal', // Changer le poids du texte du pied du tooltip
                        },
                        callbacks: {
                            title: (tooltipItems) => {
                                // tooltipItems is an array of tooltip items
                                // use the first item's index to get the full label
                                const index = tooltipItems[0].dataIndex
                                return fullLabels[index]
                            },
                            footer: (tooltipItems) => {
                                const total = tooltipItems.reduce(
                                    (accumulator, tooltipItem) => {
                                        return (
                                            accumulator + tooltipItem.parsed.y
                                        )
                                    },
                                    0
                                )
                                return 'Total: ' + total
                            },
                        },
                    },
                },
                responsive: true,
                scales: {
                    x: {
                        stacked: true,
                        grid: {
                            display: false,
                        },
                        ticks: {
                            font: {
                                size: 12,
                                family: 'Arial, sans-serif',
                            },
                            color: '#666',
                        },
                    },
                    y: {
                        stacked: true,
                        ticks: {
                            font: {
                                size: 12,
                                family: 'Arial, sans-serif',
                            },
                            color: '#666',
                        },
                    },
                },
            },
        })

        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy()
            }
        }
    }, [data])

    return (
        <div className="rounded-lg bg-white p-4 shadow-lg">
            <canvas ref={chartRef} />
        </div>
    )
}

export default TransportStatusChart
