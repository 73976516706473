import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import { Bank, SendEuros } from 'iconoir-react'
import React from 'react'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'

const StatCard = ({
    title,
    value,
    subtitle,
    icon,
    handleAction,
    actionLabel,
    actionIcon,
    primary = false,
}) => {
    return (
        <div className="w-full rounded-lg border bg-white p-4 shadow-md">
            <div className="flex items-center justify-between">
                <div>
                    <h2 className="text-xl font-bold">{title}</h2>
                    <h1 className="text-2xl">{value}</h1>
                    <p className="text-gray-600">{subtitle}</p>
                </div>
                <div>{icon}</div>
            </div>
            {handleAction && primary ? (
                <div className={'pt-2  '}>
                    <PrimaryButton
                        label={actionLabel}
                        fullWidth={false}
                        action={handleAction}
                        icon={actionIcon}
                    />
                </div>
            ) : handleAction ? (
                <div className={'pt-2  '}>
                    <SecondaryButton
                        label={actionLabel}
                        fullWidth={false}
                        action={handleAction}
                        icon={actionIcon}
                    />
                </div>
            ) : null}
        </div>
    )
}

export default StatCard
