import dayjs from 'dayjs'
import { UserXmark } from 'iconoir-react'

export const RemovedTransportSharedNotifiation = ({ shareNotification }) => {
    return (
        <div className="flex w-full items-center">
            <UserXmark className={'min-w-2-5 h-6 w-6 text-red-500'} />
            <div className="flex flex-col pl-2 pr-1">
                <div className="text-notif wrapper flex text-left text-gray-700">
                    <a className={'font-bold'}>Retrait de partage {` `}</a>
                    <a>{shareNotification.text}</a>
                </div>
                <p className="text-left text-xs text-gray-500">
                    {dayjs(shareNotification.createdAt).fromNow()}
                </p>
            </div>
            {!shareNotification.seen && (
                <div className="ml-auto">
                    <div className={`h-2 w-2 rounded-full bg-blue-500`} />
                </div>
            )}
        </div>
    )
}
