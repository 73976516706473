import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import HealthComplementaryWrapper from './HealthComplementaryWrapper'
import HealthComplementaryListComponent from './HealtComplementaryListComponent'

const HealthComplementaryContainer = () => {
    const [search, setSearch] = useState('')
    return (
        <Routes>
            <Route path=":id" element={<HealthComplementaryWrapper />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <HealthComplementaryListComponent
                            search={search}
                            setSearch={setSearch}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default HealthComplementaryContainer
