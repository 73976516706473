import { Route, Routes } from 'react-router-dom'
import LotForm from '../Lots/LotForm'
import React from 'react'
import NoemieList from './NoemieList'
import NoemieForm from './NoemieForm'

const NoemieContainer = () => {
    return (
        <Routes>
            <Route path=":id" element={<NoemieForm />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <NoemieList />
                    </div>
                }
            />
        </Routes>
    )
}

export default NoemieContainer
