import React, { useReducer, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PractitionerForm from '../Regulation/Forms/PractitionerForm'
import {
    getPractitionerInitialState,
    PRACTITIONER_FORM_ACTIONS,
    PractitionerFormReducer,
} from '../Regulation/Reducers/PractitionerFormReducer'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
    handlePractitionerForm,
    Practitioners,
} from '../../../services/API/Entities/practitioners'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'
import { toast } from 'react-toastify'
import NoData from '../NoData'

const PractitionerWrapper = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const queryClient = useQueryClient()

    const {
        data: practitioner,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['practitioner', id],
        queryFn: () => Practitioners.fetchOne(id),
        enabled: !!id,
    })

    const [practitionerForm, dispatch] = useReducer(
        PractitionerFormReducer,
        { undefined },
        getPractitionerInitialState
    )

    const practitionerMutation = useMutation({
        mutationFn: () => handlePractitionerForm(practitionerForm),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ['practitioner', id] })
            toast.success('Le praticien a été modifié')
            navigate(-1)
        },
    })

    useEffect(() => {
        if (practitioner) {
            dispatch({
                type: PRACTITIONER_FORM_ACTIONS.FETCH_PRACTITIONER,
                payload: practitioner,
            })
        }
    }, [practitioner])

    const handlePractitionerModalForm = async () => {
        practitionerMutation.mutate(practitionerForm)
    }

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return <NoData message="Une erreur s'est produite." />
    }
    return (
        <>
            <PractitionerForm
                practitioner={practitionerForm}
                dispatch={dispatch}
            />
            <SendFormButtons handleForm={handlePractitionerModalForm} />
        </>
    )
}

export default PractitionerWrapper
