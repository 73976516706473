import { ADDRESS_ACTIONS } from '../../services/Reducers/AddressAutoCompleteReducer'
import React from 'react'
import { DataTransferBoth } from 'iconoir-react'
import AutoComplete from '../App/AutoComplete'

const AddressesInline = ({
    series,
    pickUpDispatch,
    pickUpAddress,
    inputPickUp,
    depositAddress,
    depositDispatch,
    inputDeposit,
}) => {
    return (
        <div className="flex w-full flex-col items-center space-x-4 lg:flex-row lg:items-start">
            <AutoComplete
                dispatch={pickUpDispatch}
                address={pickUpAddress}
                savedAddresses={
                    series.patient?.patientsAddresses
                        ? series.patient?.patientsAddresses
                        : []
                }
                label="Départ"
                ref={inputPickUp}
            />
            <DataTransferBoth
                className="mt-2 rotate-90 cursor-pointer text-5xl hover:text-light-dark-green"
                onClick={() => {
                    pickUpDispatch({
                        type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                        payload: depositAddress,
                    })
                    depositDispatch({
                        type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                        payload: pickUpAddress,
                    })
                }}
            />
            <AutoComplete
                dispatch={depositDispatch}
                address={depositAddress}
                savedAddresses={
                    series.patient?.patientsAddresses
                        ? series.patient?.patientsAddresses
                        : []
                }
                label={'Destination'}
                ref={inputDeposit}
            />
        </div>
    )
}

export default AddressesInline
