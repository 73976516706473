export const ACTIONS = {
    SET_SELECTED_ROW_ID: 'SetSelectedRowId',
    SET_DELETE_MODAL: 'SetDeleteModal',
    USE_ON_CLICK_OUTSIDE: 'UseOnClickOutside',
    MOBILE_SET_SELECTED_ROW_ID: 'MobileSetSelectedRowId',
    SET_SHOW_MOBILE_OPTIONS: 'SetShowMobileOptions',
    CANCEL_SHOW_OPTIONS: 'CancelShowOptions',
    SET_MODAL_OPEN: 'SetModalOpen',
}

export const getInitialState = () => {
    return {
        showOptions: false,
        modalOpen: false,
        styleId: null,
        selectedRowId: null,
        label: '',
        labelAction: '',
        actionButton: '',
        showMobileOptions: false,
    }
}

export const OptionsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_SELECTED_ROW_ID:
            return {
                ...state,
                showOptions: true,
                selectedRowId: action.payload,
                styleId: action.payload,
            }
        case ACTIONS.MOBILE_SET_SELECTED_ROW_ID:
            return {
                ...state,
                selectedRowId: action.payload,
                styleId: action.payload,
            }
        case ACTIONS.SET_DELETE_MODAL:
            return {
                ...state,
                label: `Êtes-vous sûr de vouloir supprimer l'équipe : ${action.payload} ?`,
                actionButton: 'Supprimer',
                labelAction: 'Suppression',
                modalOpen: true,
            }
        case ACTIONS.USE_ON_CLICK_OUTSIDE:
            return {
                ...state,
                showOptions: false,
                styleId: null,
            }
        case ACTIONS.SET_SHOW_MOBILE_OPTIONS:
            return {
                ...state,
                showMobileOptions: !state.showMobileOptions,
            }
        case ACTIONS.CANCEL_SHOW_OPTIONS:
            return {
                ...state,
                showMobileOptions: false,
                showOptions: false,
                selectedRowId: null,
                styleId: null,
            }
        case ACTIONS.SET_MODAL_OPEN:
            return {
                ...state,
                modalOpen: action.payload,
            }
        default:
            throw new Error('Action non reconnue')
    }
}
