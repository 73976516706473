import ReactPaginate from 'react-paginate'
import React from 'react'

const CardPagination = ({ totalPages, handlePageClick, totalItems, title }) => {
    return (
        <div className="w-full text-sm text-base pb-2 hidden lg:block">
            <div className="flex text-center mx-auto pb-2">
                <ReactPaginate
                    previousLabel=<i className="fas fa-chevron-left" />
                    nextLabel=<i className="fas fa-chevron-right" />
                    pageCount={totalPages}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'pagination__link--active'}
                />
                <div className="font-bold text-xs w-56">
                    <span>
                        Total : {totalItems} {title}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default CardPagination
