import React from 'react'
import { useQuery } from '@tanstack/react-query'
import NoData from '../../NoData'
import FilterContainer from '../FilterContainer'
import LotListComponent from './LotListComponent'
import { SkeletonLoaderCards } from '../../../Commons/Loaders/SkeletonLoaderCards'
import { Lot } from '../../../../services/API/Entities/lot'

const LotList = ({
    dateFrom,
    transferGroup,
    setAddToTransferGroup,
    dateTo,
    search,
    archived,
    openFilterModal,
    setOpenFilterModal,
    isReloading,
}) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ['lots', search, dateFrom, dateTo, archived, isReloading],
        queryFn: () => Lot.fetchAll(archived),
        select: (data) => ({
            lots: data,
            totalItems: data.length,
        }),
    })

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return (
            <NoData message="Une erreur s'est produite lors du chargement des caisses." />
        )
    }
    if (data?.lots.length === 0)
        return <NoData message="Aucun lot disponible" />

    return (
        <div>
            <LotListComponent
                transferGroup={transferGroup}
                setAddToTransferGroup={setAddToTransferGroup}
                lots={data.lots}
                totalItems={data.totalItems}
                loading={isLoading}
                openFilterModal={openFilterModal}
                setOpenFilterModal={setOpenFilterModal}
            />
            {openFilterModal && (
                <FilterContainer
                    transferGroup={transferGroup}
                    setOpenFilterModal={setOpenFilterModal}
                    openFilterModal={openFilterModal}
                />
            )}
        </div>
    )
}

export default LotList
