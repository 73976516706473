export const handleCreateGroups = (team, runsToAdd) => {
    //objectif créer les groupes en fonction des horaires
    //retourner que les nouveaux groupes

    //groupes existants
    const existingGroups = team.runs
        .filter((r) => r.groupedRun)
        .map((r) =>
            r.groupedRuns.map((gr) => {
                return gr
            })
        )

    //trouver si dans les runs à ajouter il y a des groupes et les ajouter aux groupes existants
    if (runsToAdd.some((r) => r.departureOrder)) {
        const firstRunsOfGroups = runsToAdd.filter(
            (r) => r.departureOrder === 1
        )
        firstRunsOfGroups.forEach((firstRunOfGroup) => {
            const group = [
                firstRunOfGroup,
                ...runsToAdd.filter(
                    (r) =>
                        r.departureOrder &&
                        r.departureOrder !== 1 &&
                        r.parent &&
                        r.parent.id === firstRunOfGroup.id
                ),
            ]
            existingGroups.push(group)
        })
    }

    const teamsRuns = flatGroupedRunsAndRuns(team.runs)
    // let teamsRunsToGroup = [...teamsRuns]
    // runsToAdd.forEach((runToAdd) => {
    //     const matchedTeamsRuns = teamsRuns.filter(
    //         (teamRun) =>
    //             (runToAdd.departureTimeHour >= teamRun.departureTimeHour &&
    //                 runToAdd.departureTimeHour <=
    //                     teamRun.arrivingTimeHour) ||
    //             (runToAdd.arrivingTimeHour >= teamRun.departureTimeHour &&
    //                 runToAdd.arrivingTimeHour <= teamRun.arrivingTimeHour)
    //     )
    //     teamsRunsToGroup = [...teamsRunsToGroup, ...matchedTeamsRuns]
    // })
    // teamsRunsToGroup = [...new Set(teamsRunsToGroup)]

    // const allRuns = [...teamsRunsToGroup, ...runsToAdd]

    //trier les courses de sorte à avoir en premier les courses groupées
    const allRuns = [...teamsRuns, ...runsToAdd]
        .sort((a, b) => b.departureOrder - a.departureOrder)
        .reverse()

    const groups = []

    while (allRuns.length) {
        const group = []
        const indexes = []

        for (let i = 0; i < allRuns.length; i++) {
            if (i === 0) {
                group.push(allRuns[i])
                indexes.push(i)

                // regarder si c'est une course d'un groupe
                if (allRuns[i].departureOrder) {
                    // si oui, on recrée le groupe
                    const allReadyGroupedRuns = retrieveGroupFromRun(
                        allRuns[i],
                        allRuns
                    )

                    allReadyGroupedRuns.forEach((gr) => {
                        const findRun = allRuns.find((r) => r.id === gr.id)
                        const index = allRuns.indexOf(findRun)
                        group.push(allRuns[index])
                        indexes.push(index)
                    })
                }
            } else if (!allRuns[i].departureOrder) {
                if (
                    group.some(
                        (groupedRun) =>
                            (allRuns[i].departureTimeHour >=
                                groupedRun.departureTimeHour &&
                                allRuns[i].departureTimeHour <=
                                    groupedRun.arrivingTimeHour) ||
                            (allRuns[i].arrivingTimeHour >=
                                groupedRun.departureTimeHour &&
                                allRuns[i].arrivingTimeHour <=
                                    groupedRun.arrivingTimeHour) ||
                            (groupedRun.departureTimeHour >=
                                allRuns[i].departureTimeHour &&
                                groupedRun.departureTimeHour <=
                                    allRuns[i].arrivingTimeHour) ||
                            (groupedRun.arrivingTimeHour >=
                                allRuns[i].departureTimeHour &&
                                groupedRun.arrivingTimeHour <=
                                    allRuns[i].arrivingTimeHour)
                    )
                ) {
                    group.push(allRuns[i])
                    indexes.push(i)
                }
            } else {
                if (
                    !group.some(
                        (groupedRun) => groupedRun.id === allRuns[i].id
                    ) &&
                    group.some(
                        (groupedRun) =>
                            (allRuns[i].departureTimeHour >=
                                groupedRun.departureTimeHour &&
                                allRuns[i].departureTimeHour <=
                                    groupedRun.arrivingTimeHour) ||
                            (allRuns[i].arrivingTimeHour >=
                                groupedRun.departureTimeHour &&
                                allRuns[i].arrivingTimeHour <=
                                    groupedRun.arrivingTimeHour) ||
                            (groupedRun.departureTimeHour >=
                                allRuns[i].departureTimeHour &&
                                groupedRun.departureTimeHour <=
                                    allRuns[i].arrivingTimeHour) ||
                            (groupedRun.arrivingTimeHour >=
                                allRuns[i].departureTimeHour &&
                                groupedRun.arrivingTimeHour <=
                                    allRuns[i].arrivingTimeHour)
                    )
                ) {
                    group.push(allRuns[i])
                    indexes.push(i)

                    //on recrée le groupe
                    const allReadyGroupedRuns = retrieveGroupFromRun(
                        allRuns[i],
                        allRuns
                    )

                    allReadyGroupedRuns.forEach((gr) => {
                        const findRun = allRuns.find((r) => r.id === gr.id)
                        const index = allRuns.indexOf(findRun)
                        group.push(allRuns[index])
                        indexes.push(index)
                    })
                    break
                }
            }
        }

        if (group.length > 1) {
            let alreadyExistingGroup = false
            existingGroups.forEach((existingGroup) => {
                let difference = group.filter((x) => !existingGroup.includes(x))
                if (difference.length === 0) {
                    alreadyExistingGroup = true
                }
            })
            if (!alreadyExistingGroup) {
                groups.push(group)
            }
        }

        indexes
            .sort((a, b) => a - b)
            .reverse()
            .forEach((index) => {
                allRuns.splice(index, 1)
            })
    }

    return groups
}

const retrieveGroupFromRun = (run, allRuns) => {
    let allReadyGroupedRuns = []
    if (run.departureOrder === 1) {
        allReadyGroupedRuns = allRuns.filter(
            (r) =>
                r.id !== run.id &&
                r.departureOrder &&
                r.parent &&
                r.parent.id === run.id
        )
    } else {
        const parentRun = allRuns.find(
            (r) =>
                r.id !== run.id &&
                r.departureOrder &&
                run.parent &&
                r.id === run.parent.id
        )

        const otherChildRuns = allRuns.filter(
            (r) =>
                r.id !== run.id &&
                r.departureOrder &&
                r.parent &&
                r.parent.id === parentRun.id
        )
        allReadyGroupedRuns = [parentRun, ...otherChildRuns]
    }
    return allReadyGroupedRuns
}

export const flatGroupedRunsAndRuns = (runs) => {
    const flatRuns = runs.flatMap((r) => {
        if (r.groupedRun) {
            return r.groupedRuns.flatMap((groupedRun) => {
                return groupedRun
            })
        } else {
            return r
        }
    })
    return flatRuns.sort(
        (a, b) => new Date(a.departureTime) - new Date(b.departureTime)
    )
}
