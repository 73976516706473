import React, { useEffect, useState } from 'react'
import SimpleSelectDropDownList from '../Commons/SimpleSelectDropDownList'
import API from '../../services/api'
import { useDebounce } from 'use-debounce'
import ModalTextArea from '../Commons/ModalTextArea'
import Alert from '../Commons/Alert'
import SharingOptions from '../../services/SharingOptions'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import DeleteButton from '../Commons/Buttons/DeleteButton'
import SecondaryButton from '../Commons/Buttons/SecondaryButton'
import { ShareAndroid } from 'iconoir-react'

const ShareModal = ({
    setShareModalOpen,
    sharedRun,
    handleActionShareRun,
    setPartner,
    partner,
    note,
    setNote,
    isClearablePartner,
    setIsClearablePartner,
    setHasChanged,
    handleRemoveShare,
    setShareOption,
    shareOption,
}) => {
    const [searchPartner, setSearchPartner] = useState('')
    const [error, setError] = useState('')
    const [alert, setAlert] = useState('')
    const [debouncedSearchPartner] = useDebounce(searchPartner, 300)
    const checkErrors = () => {
        let msg = ''
        if (partner === null) {
            msg = '<div>Veuillez sélectionner un partenaire</div>'
        } else if (sharedRun.partner) {
            if ('/' + partner.value === sharedRun.partner['@id']) {
                msg =
                    '<div>Ce transport a déjà été partagé avec ce partenaire</div>'
            }
        }

        if (msg !== '') {
            setError(msg)
            return true
        }
        return false
    }
    const [partners, setPartners] = useState()
    const handleSearchInputPartnerChange = (event) => {
        setSearchPartner(event)
    }

    function clearError() {
        setError(null)
    }

    function clearAlert() {
        setAlert(null)
    }

    function fetchPartnersList(search = null) {
        API.Partners.list(search, null).then((response) => {
            response.json().then((data) => {
                const partnersList = data['hydra:member'].map((partner) => {
                    return {
                        value: partner['@id'],
                        label: partner.firstname + ' ' + partner.lastname,
                        email: partner.email,
                    }
                })
                setPartners(partnersList)
            })
        })
    }

    const handlePartnerChange = (event) => {
        if (event != null) {
            setIsClearablePartner(true)
            setPartner(event)
            if (partner !== event) {
                setHasChanged(true)
            }
        } else {
            setIsClearablePartner(false)
            setHasChanged(true)
            setPartner(null)
        }
    }

    const handleOptionChange = (event) => {
        if (event != null) {
            setShareOption(event)
        } else {
            setShareOption(null)
        }
    }

    const checkAlert = () => {
        let msg = ''
        if (sharedRun.directReturn) {
            msg = "Ce transport fait partie d'un retour direct - "
        }
        if (sharedRun.team) {
            msg += 'Vous avez déjà assigné ce transport à une équipe'
        }

        if (msg !== '') {
            setAlert(msg)
            return true
        }
        return false
    }

    useEffect(() => {
        checkAlert()
        if (debouncedSearchPartner) {
            fetchPartnersList(debouncedSearchPartner)
        }
    }, [debouncedSearchPartner])

    return (
        <div className="fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50 text-gray-700">
            <div className="mx-2 flex w-full flex-col space-y-4 rounded-lg bg-white px-4 py-4 sm:mx-0 lg:w-2/3 xl:w-1/3">
                <div className="flex  w-full border-b-2 pb-4 lg:items-center">
                    <div className="flex w-full  items-center  justify-between font-bold">
                        <div className="flex items-center text-xl lg:text-xl xl:text-2xl ">
                            <p className="pr-2">Partage</p>
                            <i className="fas fa-share-alt" />
                        </div>
                        {sharedRun.partner && (
                            <DeleteButton
                                label="Retirer le partage"
                                title="Retirer le partage"
                                action={() => {
                                    setShareModalOpen(false)
                                    handleRemoveShare(sharedRun.id)
                                }}
                            />
                        )}
                    </div>
                </div>
                {alert && (
                    <Alert
                        title="Attention"
                        text={alert}
                        clearError={clearAlert}
                        shadow={true}
                    />
                )}
                {error && (
                    <Alert
                        title="Erreur"
                        text={error}
                        clearError={clearError}
                        shadow={true}
                    />
                )}

                <div className="flex w-full flex-col items-center lg:flex-row">
                    <div className="w-full pb-2 text-lg font-semibold lg:w-1/3 lg:p-0">
                        Transport
                    </div>
                    <div className="flex w-full justify-between lg:w-2/3">
                        <div className=" w-1/2  truncate">
                            <i className="fa fa-user pr-1" />
                            {sharedRun.fullname}
                        </div>
                        <div className="  w-1/2  truncate">
                            <i className="fa fa-clock pr-1" />
                            {sharedRun.heure}
                        </div>
                    </div>
                </div>

                <div className="flex w-full flex-col items-center lg:flex-row">
                    <div className="w-full text-lg font-semibold lg:w-1/3">
                        Partenaire
                    </div>
                    <div className="flex w-full items-center lg:w-2/3">
                        <SimpleSelectDropDownList
                            label="Partenaire"
                            options={partners}
                            value={partner}
                            isClearable={isClearablePartner}
                            handleOptionChange={handlePartnerChange}
                            isDisabled={false}
                            handleSearchInputPatientChange={
                                handleSearchInputPartnerChange
                            }
                        />
                    </div>
                </div>

                <div className="w-full">
                    <ModalTextArea
                        label={'Note'}
                        value={note}
                        setValue={(e) => {
                            setNote(e.target.value)
                            setHasChanged(true)
                        }}
                        placeholder={'Notes importantes liées au transport...'}
                    />
                </div>
                <div className="flex w-full flex-col items-center py-4 lg:flex-row">
                    <div className="w-full text-lg font-semibold lg:w-1/3">
                        Méthode de partage
                    </div>
                    <div className="flex w-full items-center lg:w-2/3">
                        <SimpleSelectDropDownList
                            label="WhatsApp / Sms / Email"
                            options={SharingOptions}
                            value={shareOption}
                            handleOptionChange={handleOptionChange}
                            isDisabled={false}
                        />
                    </div>
                </div>
                <div className="border-t-2">
                    <div className="flex justify-between pt-4">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            action={() => setShareModalOpen(false)}
                        />
                        <PrimaryButton
                            label="Partager"
                            title="Partager"
                            action={() => {
                                if (!checkErrors()) {
                                    handleActionShareRun()
                                }
                            }}
                            icon={<ShareAndroid />}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShareModal
