const SkeletonCard = () => {
    return (
        <div className="mb-2 flex animate-pulse items-center justify-between rounded-md border border-gray-300 p-2 shadow">
            <div className="flex flex-col space-y-4">
                <div className="h-4 w-24 rounded bg-gray-300"></div>
                <div className="h-4 w-32 rounded bg-gray-300"></div>
                <div className="h-4 w-40 rounded bg-gray-300"></div>
            </div>
            <div className="flex flex-col items-center space-y-2">
                <div className="h-4 w-28 rounded bg-gray-300"></div>
                <div className="h-6 w-36 rounded-full bg-gray-300"></div>
                <div className="h-4 w-16 rounded bg-gray-300"></div>
            </div>
            <div className="flex flex-col items-end space-y-2">
                <div className="h-4 w-20 rounded bg-gray-300"></div>
                <div className="h-4 w-16 rounded bg-gray-300"></div>
                <div className="h-4 w-12 rounded bg-gray-300"></div>
            </div>
        </div>
    )
}

export default SkeletonCard
