import { formatHydraData } from '../../api'
import { apiClient } from '../apiClient'

const PATH_KEY = '/cpam_invoice_groups'
export const Lot = {
    fetchAll: async (archived) => {
        let path = PATH_KEY + '?pagination=false'
        if (archived) {
            path += '&archived=true'
        } else {
            path += '&archived=false'
        }
        // if (archived) {
        //     path += '&statusFilter=Cloturé'
        // } else {
        //     path = concat(path, 'excludeStatus=Cloturé')
        // }
        return formatHydraData(await apiClient.get(path))
    },
}
