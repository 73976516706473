import React, { useReducer } from 'react'
import {
    InvoiceFilterInitialState,
    InvoiceFilterReducer,
} from '../../../services/Reducers/InvoiceFilterReducer'
import FilterPatient from './FilterPatient'
import FilterAnnexes from './FilterAnnexes'
import {
    useStickyDateState,
    useStickyEndDateState,
} from '../../../hooks/LocalStorageDatesHooks'

const FilterContainer = ({ openFilterModal, setOpenFilterModal }) => {
    const [dateFrom, setDateFrom] = useStickyDateState(new Date(), 'storedDate')
    const [dateTo, setDateTo] = useStickyEndDateState(
        new Date(),
        'storedDate',
        'storedEndDate'
    )
    const [invoiceFilter, dispatch] = useReducer(
        InvoiceFilterReducer,
        { dateFrom, dateTo },
        InvoiceFilterInitialState
    )

    return (
        <div
            className={`flex items-center justify-center fixed left-0 bottom-0 pb-20 w-full h-full bg-gray-500 bg-opacity-50 text-gray-700 modal px-5`}
        >
            {openFilterModal && invoiceFilter.step === 1 && (
                <FilterPatient
                    dispatch={dispatch}
                    invoiceFilter={invoiceFilter}
                    openFilterModal={openFilterModal}
                    setOpenFilterModal={setOpenFilterModal}
                    setDateFrom={setDateFrom}
                    setDateTo={setDateTo}
                />
            )}
            {openFilterModal && invoiceFilter.step === 2 && (
                <FilterAnnexes
                    dispatch={dispatch}
                    invoiceFilter={invoiceFilter}
                    openFilterModal={openFilterModal}
                    setOpenFilterModal={setOpenFilterModal}
                />
            )}
        </div>
    )
}

export default FilterContainer
