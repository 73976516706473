import React from 'react'

const TabButton = ({ action, label, value, icon = null }) => {
    return (
        <button
            className={`min-w-8 cursor-pointer border-b-2 hover:border-primary-color ${
                value
                    ? 'duration-400 border-primary-color ease-in-out'
                    : 'border-transparent duration-700 ease-out'
            } `}
            onClick={() => action(false)}
        >
            {icon ? (
                <div className="flex items-center justify-center space-x-2">
                    {icon}
                    <span>{label}</span>
                </div>
            ) : (
                label
            )}
        </button>
    )
}

export default TabButton
