import { createContext, useContext, useEffect, useRef, useState } from 'react'

export const WebsocketContext = createContext(false, null, () => {})
//                                            ready, value, send

// Make sure to put WebsocketProvider higher up in
// the component tree than any consumers.
export const WebsocketProvider = ({ children }) => {
    const [isReady, setIsReady] = useState(false)
    const [val, setVal] = useState(null)
    const user = localStorage.getItem('id')
    const ws = useRef(null)

    useEffect(() => {
        // STAGING
        //   const socket = new WebSocket(
        //       'wss://py0njs6113.execute-api.eu-west-3.amazonaws.com/staging/?user=' +
        //           user
        //   )
        // PROD
        if (window.location.hostname !== 'localhost') {
            const socket = new WebSocket(
                'wss://9sbpx2k98h.execute-api.eu-west-3.amazonaws.com/prod/?user=' +
                    user
            )

            socket.onopen = () => setIsReady(true)
            socket.onclose = () => {
                setIsReady(false)
            }
            socket.onmessage = (event) => setVal(event.data)

            ws.current = socket

            return () => {
                socket.close()
            }
        }
    }, [])
    const ret = [isReady, val, ws.current?.send.bind(ws.current)]

    return (
        <WebsocketContext.Provider value={ret}>
            {children}
        </WebsocketContext.Provider>
    )
}

export const WsConsumer = ({ setSocketNotification, setText, popUp }) => {
    const [ready, val, send] = useContext(WebsocketContext) // use it just like a hook
    useEffect(() => {
        if (val && ready) {
            popUp(val)
            setSocketNotification(true)
            setText(val)
        }
    }, [val, ready])
}
