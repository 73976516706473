import React from 'react'
import { findColorByTarifType } from '../../../../../services/InvoiceUtils'
import { formatPrice } from '../../../../../tools/Utility'
import ClassicInputV2 from '../../../../Commons/Inputs/ClassicInputV2'
import { RUN_INVOICE_FORM_DETAILS_ACTIONS } from './RunInvoiceFormDetailsReducer'

const TarifSection = ({ dispatch, invoiceCpamForm }) => {
    return (
        <div className="grid grid-cols-2 gap-8">
            <div className={'col-span-2 text-lg font-semibold'}>
                Définition du kilométrage en fonction du tarif
            </div>
            <div className={'flex flex-col'}>
                <div className={'flex w-1/2 space-x-2'}>
                    <p className={'font-semibold'}>Tarif</p>
                    <div
                        className={`h-6 w-6 rounded-full border text-center ${findColorByTarifType(
                            'A'
                        )}`}
                    >
                        A
                    </div>
                </div>
                <ClassicInputV2
                    type="text"
                    value={invoiceCpamForm.kilometerA}
                    setValue={(e) => {
                        dispatch({
                            type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_KILOMETER_A,
                            payload: formatPrice(
                                e.target.value,
                                invoiceCpamForm.kilometerA
                            ),
                        })
                    }}
                    placeholder="0"
                    id="kilometerA"
                    ghostLabel={'Km'}
                />
            </div>
            <div className={'flex flex-col'}>
                <div className={'flex w-1/2 space-x-2'}>
                    <p className={'font-semibold'}>Tarif</p>
                    <div
                        className={`h-6 w-6 rounded-full border text-center ${findColorByTarifType(
                            'B'
                        )}`}
                    >
                        B
                    </div>
                </div>
                <ClassicInputV2
                    type="text"
                    value={invoiceCpamForm.kilometerB}
                    setValue={(e) =>
                        dispatch({
                            type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_KILOMETER_B,
                            payload: formatPrice(
                                e.target.value,
                                invoiceCpamForm.kilometerB
                            ),
                        })
                    }
                    placeholder="0"
                    id="kilometerB"
                    ghostLabel={'Km'}
                />
            </div>
            <div className={'flex flex-col'}>
                <div className={'flex w-1/2 space-x-2'}>
                    <p className={'font-semibold'}>Tarif</p>
                    <div
                        className={`h-6 w-6 rounded-full border text-center ${findColorByTarifType(
                            'C'
                        )}`}
                    >
                        C
                    </div>
                </div>
                <ClassicInputV2
                    type="text"
                    value={invoiceCpamForm.kilometerC}
                    setValue={(e) =>
                        dispatch({
                            type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_KILOMETER_C,
                            payload: formatPrice(
                                e.target.value,
                                invoiceCpamForm.kilometerC
                            ),
                        })
                    }
                    placeholder="0"
                    id="kilometerC"
                    ghostLabel={'Km'}
                />
            </div>
            <div className={'flex flex-col'}>
                <div className={'flex w-1/2 space-x-2'}>
                    <p className={'font-semibold'}>Tarif</p>
                    <div
                        className={`h-6 w-6 rounded-full border text-center ${findColorByTarifType(
                            'D'
                        )}`}
                    >
                        D
                    </div>
                </div>
                <ClassicInputV2
                    type="text"
                    value={invoiceCpamForm.kilometerD}
                    setValue={(e) =>
                        dispatch({
                            type: RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_KILOMETER_D,
                            payload: formatPrice(
                                e.target.value,
                                invoiceCpamForm.kilometerD
                            ),
                        })
                    }
                    placeholder="0"
                    id="kilometerD"
                    ghostLabel={'Km'}
                />
            </div>
        </div>
    )
}

export default TarifSection
