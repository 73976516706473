export const ACTIONS = {
    SET_OPEN_SELECT_DATE_PICKER: 'setOpenSelectDatePicker',
    SET_DATE_TO: 'setDateTo',
    SET_DATE_FROM: 'setDateFrom',
}

export const getInitialState = ({ date }) => {
    return {
        openSelectDatePicker: false,
        dateFrom: date,
        dateTo: date,
    }
}

export const DateRangeReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_OPEN_SELECT_DATE_PICKER:
            return {
                ...state,
                openSelectDatePicker: action.payload,
            }
        case ACTIONS.SET_DATE_TO:
            return {
                ...state,
                dateTo: action.payload,
            }
        case ACTIONS.SET_DATE_FROM:
            return {
                ...state,
                dateFrom:
                    action.payload < new Date() ? new Date() : action.payload,
                dateTo: action.payload < new Date() ? new Date() : state.dateTo,
            }

        default:
            throw new Error('Action non reconnue')
    }
}
