import React from 'react'

const WaitingTimeInput = (props) => {
    return (
        <div className="h-full">
            {props.icon ? (
                <div className="inline-block w-10">
                    <i className={props.icon} />
                </div>
            ) : (
                ''
            )}
            <div className="inline-block">
                <label
                    className={`block uppercase tracking-wide text-gray-700 text-base lg:text-xs font-bold ${
                        props.showLabel ? '' : 'hidden'
                    }`}
                    htmlFor={props.id}
                >
                    {props.label}
                </label>
                <input
                    id={props.id}
                    className={`w-20 appearance-none block text-gray-700 h-14 text-xl lg:text-base lg:h-10 pl-4 leading-tight border-b-2 focus:outline-none focus:border-lighter-blue-green
                 ${props.error ? 'border-red-500' : ''}`}
                    type={props.type}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={props.setValue}
                    required={props.required}
                    min={props.min}
                    max={props.max}
                    inputMode="numeric"
                    disabled={props.disable}
                    pattern={props.pattern}
                    onFocus={(e) => e.target.select()}
                />
            </div>
        </div>
    )
}

export default WaitingTimeInput
