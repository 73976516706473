export const ACTIONS = {
    SET_CUSTOMER: 'setCustomer',
    SET_EMAIL: 'setEmail',
    SET_FIRSTNAME: 'setFirstname',
    SET_LASTNAME: 'setLastname',
    SET_PHONE: 'setPhone',
    SET_SIREN: 'setSiren',
    SET_NB_VEHICLES: 'setNbVehicles',
    SET_SOCIETY_NAME: 'setSocietyName',
    SET_ADDRESS: 'setAddress',
    UPDATE_ADDRESS_LABEL: 'updateAddressLabel',
    DELETE_ADDRESS: 'deleteAddress',
    SET_PRODUCTS: 'setProducts',
    SET_PRODUCT: 'setProduct',
    SET_PAYMENT_LINK: 'setPaymentLink',
    SET_LOADING: 'setLoading',
    SET_ANNUEL_PAYMENT: 'setAnnualPayment',
    SET_STRIPE_CLIENT_SECRET_KEY_AND_CUSTOMER_ID:
        'setStripeClientSecretKeyAndCustomerId',
    SET_SEPA_FILLED_OUT: 'setSepaFilledOut',
    SET_SIGNUP_DONE: 'setSignupDone',
}

export const getInitialState = () => {
    return {
        firstname: null,
        lastname: null,
        email: null,
        phone: '',
        siren: '',
        nbVehicles: '',
        societyName: null,
        address: null,
        addressLabel: null,
        products: [],
        product: null,
        paymentLink: null,
        loading: false,
        annualPayment: false,
        stripeClientSecretKey: null,
        sepaFilledOut: false,
        customerId: '',
        signUpDone: false,
    }
}

export const SalesReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_CUSTOMER:
            return {
                ...state,
            }
        case ACTIONS.SET_EMAIL:
            return {
                ...state,
                email: action.payload,
                validEmail: emailIsValid(action.payload),
            }
        case ACTIONS.SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload,
            }
        case ACTIONS.SET_LASTNAME:
            return {
                ...state,
                lastname: action.payload,
            }
        case ACTIONS.SET_PHONE:
            return {
                ...state,
                phone: action.payload,
            }
        case ACTIONS.SET_SIREN:
            return {
                ...state,
                siren: action.payload,
            }
        case ACTIONS.SET_SOCIETY_NAME:
            return {
                ...state,
                societyName: action.payload,
            }
        case ACTIONS.SET_NB_VEHICLES:
            return {
                ...state,
                nbVehicles: action.payload,
            }
        case ACTIONS.SET_ADDRESS:
            return {
                ...state,
                address: action.payload,
                addressLabel: action.payload.googlePrediction,
            }
        case ACTIONS.UPDATE_ADDRESS_LABEL:
            return {
                ...state,
                addressLabel: {
                    ...state.addressLabel,
                    value: {
                        ...state.addressLabel?.value,
                        description: action.payload.description,
                    },
                },
            }
        case ACTIONS.DELETE_ADDRESS:
            return {
                ...state,
                addressLabel: {
                    ...state.addressLabel,
                    value: {
                        description: '',
                    },
                },
            }
        case ACTIONS.SET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                product: action.payload[0],
            }
        case ACTIONS.SET_PRODUCT:
            return {
                ...state,
                product: action.payload,
            }
        case ACTIONS.SET_PAYMENT_LINK:
            return {
                ...state,
                loading: false,
                paymentLink: action.payload,
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case ACTIONS.SET_ANNUEL_PAYMENT:
            return {
                ...state,
                annualPayment: action.payload,
            }
        case ACTIONS.SET_STRIPE_CLIENT_SECRET_KEY_AND_CUSTOMER_ID:
            return {
                ...state,
                stripeClientSecretKey: action.payload.stripeClientSecretKey,
                customerId: action.payload.customerId,
            }
        case ACTIONS.SET_SEPA_FILLED_OUT:
            return {
                ...state,
                sepaFilledOut: action.payload,
            }
        case ACTIONS.SET_SIGNUP_DONE:
            return {
                ...state,
                signUpDone: action.payload,
            }
        default:
            throw new Error('Action non reconnue')
    }
}

const emailIsValid = (email) => {
    return /\S+@\S+\.\S+/.test(email)
}
