import React, { useState } from 'react'
import useSignupStore from '../../../stores/Signup'
import { useNavigate, useLocation } from 'react-router-dom'
import API from '../../../services/api'
import jwt from 'jwt-decode'
import Loader from '../../Commons/Loaders/Loader'
import { emailIsValid } from '../../../tools/Utility'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'

const SignupFormUser = () => {
    let history = useNavigate()
    let location = useLocation()

    const password = useSignupStore((state) => state.password)
    const setPassword = useSignupStore((state) => state.setPassword)
    const passwordConfirmation = useSignupStore(
        (state) => state.passwordConfirmation
    )
    const setPasswordConfirmation = useSignupStore(
        (state) => state.setPasswordConfirmation
    )

    const isFree = location.search.includes('?free=true')
    isFree && localStorage.setItem('isFree', 'true')
    const setError = useSignupStore((state) => state.setError)
    const error = useSignupStore((state) => state.error)
    const email = useSignupStore((state) => state.email)
    const setEmail = useSignupStore((state) => state.setEmail)
    const firstname = useSignupStore((state) => state.firstname)
    const setFirstname = useSignupStore((state) => state.setFirstname)
    const lastname = useSignupStore((state) => state.lastname)
    const setLastname = useSignupStore((state) => state.setLastname)
    const phone = useSignupStore((state) => state.phone)
    const setPhone = useSignupStore((state) => state.setPhone)
    const existingProcess = useSignupStore((state) => state.existingProcess)
    const setExistingProcess = useSignupStore(
        (state) => state.setExistingProcess
    )
    const [emailError, setEmailError] = useState(false)
    const [loading, setLoading] = useState(false)

    const historyPush = () => {
        if (!checkErrors()) {
            handleForm()
        }
    }

    const handleForm = () => {
        setLoading(true)
        const data = {
            email: email,
            firstname: firstname,
            lastname: lastname,
            phone: phone,
        }
        if (localStorage.getItem('id') && existingProcess) {
            API.Users.putWithoutSociety(localStorage.getItem('id'), data)
                .then((response) => {
                    response.json().then((token) => {
                        localStorage.setItem('token', token.token)
                        const user = jwt(token.token)
                        localStorage.setItem('email', user.email)
                        localStorage.setItem('firstname', user.firstname)

                        history('/inscription/societe')
                    })
                })
                .catch((error) => {
                    if (error.json) {
                        error.json().then((data) => {
                            setEmailError(true)
                            setError(data.error)
                            setLoading(false)
                        })
                    }
                })
        } else {
            data['password'] = password
            API.Authentication.handleSignUpUser(data)
                .then((response) => {
                    response.json().then((token) => {
                        localStorage.setItem('token', token.token)
                        const user = jwt(token.token)
                        localStorage.setItem('id', user.id)
                        localStorage.setItem('email', user.email)
                        localStorage.setItem('firstname', user.firstname)

                        const role = user.roles.some((r) => r.includes('ADMIN'))
                            ? 'ADMIN'
                            : 'USER'
                        localStorage.setItem('role', role)
                        setExistingProcess(true)
                        history('/inscription/societe')
                    })
                })
                .catch((error) => {
                    if (error.json) {
                        error.json().then((data) => {
                            setEmailError(true)
                            setError(data.error)
                            setLoading(false)
                        })
                    }
                })
        }
    }

    const checkErrors = () => {
        setLoading(true)
        setError(false)
        setEmailError(false)

        let msg = ''

        if (
            !firstname ||
            !lastname ||
            !phone ||
            (!existingProcess && !password)
        ) {
            msg += '<div> Veuillez renseigner les champs obligatoires.</div>'
        }

        if (!emailIsValid(email)) {
            msg += '<div> Veuillez renseigner un email valide.</div>'
        }
        if (!existingProcess && password.length < 8) {
            msg +=
                '<div> Votre mot de passe doit faire au moins 8 charactères.</div>'
        }
        if (!existingProcess && password !== passwordConfirmation) {
            msg += '<div> Les mots de passe doivent être identiques.</div>'
        }

        if (phone.length !== 10) {
            msg +=
                '<div> Veuillez renseigner un numéro de téléphone valide.</div>'
        }

        setError(msg)
        setLoading(false)
        return msg !== ''
    }

    const handlePhoneNumber = (value) => {
        setPhone(value.replace(/\D/g, '').substring(0, 10))
    }

    return (
        <div>
            <div className="mx-auto w-full max-w-xl">
                <div className="py-5 text-3xl text-gray-700">
                    Informations personnelles
                </div>
            </div>
            <div className="mx-auto w-full max-w-xl">
                <div className="flex rounded-md bg-white p-4 shadow-md">
                    {loading ? (
                        <Loader />
                    ) : (
                        <div className="mx-auto flex w-full max-w-md flex-col space-y-4  py-5">
                            {emailError && (
                                <div>
                                    <button
                                        className="text-blue-600 hover:underline"
                                        onClick={() => history('/')}
                                    >
                                        Me connecter
                                    </button>
                                </div>
                            )}
                            <div className="w-full">
                                <label
                                    className="mb-2 block text-sm font-bold text-gray-700"
                                    htmlFor="email"
                                >
                                    Email
                                </label>
                                <input
                                    value={email}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                        !emailIsValid(email) && error
                                            ? 'border-red-500'
                                            : 'border-gray-300'
                                    }`}
                                    id="email"
                                    type="text"
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="john.doe@gmail.com"
                                />
                            </div>
                            <div className="w-full">
                                <label
                                    className="mb-2 block text-sm font-bold text-gray-700"
                                    htmlFor="firstname"
                                >
                                    Prénom
                                </label>
                                <input
                                    value={firstname}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                        !firstname && error
                                            ? 'border-red-500'
                                            : 'border-gray-300'
                                    }`}
                                    id="firstname"
                                    type="text"
                                    name="firstname"
                                    onChange={(e) =>
                                        setFirstname(e.target.value)
                                    }
                                    placeholder="John"
                                />
                            </div>
                            <div className="w-full">
                                <label
                                    className="mb-2 block text-sm font-bold text-gray-700"
                                    htmlFor="lastname"
                                >
                                    Nom
                                </label>
                                <input
                                    value={lastname}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                        !lastname && error
                                            ? 'border-red-500'
                                            : 'border-gray-300'
                                    }`}
                                    id="lastname"
                                    type="text"
                                    name="lastname"
                                    onChange={(e) =>
                                        setLastname(e.target.value)
                                    }
                                    placeholder="Doe"
                                />
                            </div>
                            <div className="w-full">
                                <label
                                    className="mb-2 block text-sm font-bold text-gray-700"
                                    htmlFor="phone"
                                >
                                    Téléphone
                                </label>
                                <input
                                    value={phone}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                        phone.length !== 10 && error
                                            ? 'border-red-500'
                                            : 'border-gray-300'
                                    }`}
                                    id="phone"
                                    type="text"
                                    name="phone"
                                    onChange={(e) =>
                                        handlePhoneNumber(e.target.value)
                                    }
                                    placeholder="xxxxxxxxxx"
                                />
                            </div>
                            {!existingProcess && (
                                <div>
                                    <div className="mb-4 w-full">
                                        <label
                                            className="mb-2 block text-sm font-bold text-gray-700"
                                            htmlFor="password"
                                        >
                                            Mot de passe
                                        </label>
                                        <input
                                            value={password}
                                            className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                                (!password ||
                                                    password !==
                                                        passwordConfirmation) &&
                                                error
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                            id="password"
                                            type="password"
                                            name="password"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            placeholder="*********"
                                        />
                                    </div>
                                    <div className="w-full">
                                        <label
                                            className="mb-2 block text-sm font-bold text-gray-700"
                                            htmlFor="password"
                                        >
                                            Confirmation de mot de passe
                                        </label>
                                        <input
                                            value={passwordConfirmation}
                                            onChange={(e) =>
                                                setPasswordConfirmation(
                                                    e.target.value
                                                )
                                            }
                                            className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                                (!passwordConfirmation ||
                                                    password !==
                                                        passwordConfirmation) &&
                                                error
                                                    ? 'border-red-500'
                                                    : 'border-gray-300'
                                            }`}
                                            id="passwordConfirmation"
                                            type="password"
                                            name="passwordConfirmation"
                                            placeholder="*********"
                                        />
                                    </div>
                                    <div className="w-full text-xs italic text-red-500">
                                        <p>
                                            Votre mot de passe doit faire au
                                            moins 8 charactères
                                        </p>
                                    </div>
                                </div>
                            )}
                            <div className="w-full pt-2">
                                <PrimaryButton
                                    label="Continuer"
                                    title="Continuer"
                                    action={historyPush}
                                    hiddenLabelOnMobile={false}
                                    fullWidth={true}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SignupFormUser
