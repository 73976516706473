import React from 'react'
import NoData from '../NoData'
import InvoiceList from './InvoiceList'
import FilterContainer from './FilterContainer'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'
import { FreeInvoice } from '../../../services/API/Entities/freeInvoice'
import { toast } from 'react-toastify'

const Invoice = ({
    search,
    archived,
    openFilterModal,
    setOpenFilterModal,
    isQuote,
}) => {
    const queryClient = useQueryClient()
    const {
        data: invoices,
        isLoading,
        error,
    } = useQuery({
        queryKey: ['freeInvoices', search, archived, isQuote],
        queryFn: () => FreeInvoice.fetchAll(search, null, archived, isQuote),
        keepPreviousData: true,
    })

    const updateArchive = async (id) => {
        await FreeInvoice.updateArchive(id)
        queryClient.invalidateQueries('invoices')
    }

    const handleDelete = async (id) => {
        await FreeInvoice.delete(id)
        queryClient.invalidateQueries('invoices')
    }

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (error) {
        return <NoData message={'Une erreur est survenue'} />
    }

    return (
        <div className="p-4">
            {invoices.length > 0 ? (
                <InvoiceList
                    invoices={invoices}
                    totalItems={invoices.length}
                    loading={isLoading}
                    handleDelete={handleDelete}
                    updateArchive={updateArchive}
                    openFilterModal={openFilterModal}
                    setOpenFilterModal={setOpenFilterModal}
                />
            ) : (
                <NoData
                    message={`Aucune ${
                        !isQuote ? 'facture' : 'devis'
                    } disponible`}
                />
            )}
            {openFilterModal && (
                <FilterContainer
                    setOpenFilterModal={setOpenFilterModal}
                    openFilterModal={openFilterModal}
                />
            )}
        </div>
    )
}

export default Invoice
