import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver'
import API from '../services/api'
import { formatDateLocal, formatDateYYYYMMDD } from '../services/dateUtils'
import { RunStatus } from '../services/RunStatus'
import { Run } from '../services/API/Entities/run'
import { REGULATION_KEYS } from './useRunsList'

export const useRegulationFilter = ({
    search,
    dateFrom,
    dateTo,
    patient,
    partner,
    type,
    status,
    object,
    user,
    excludeStatus,
    setStatus,
    transferGroup,
    updateTransferGroup,
    setSelectAll,
    setTransferLabel,
    showStatusModal,
    setShowStatusModal,
    statusForm,
}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const queryClient = useQueryClient()

    const [showPopUpFilter, setShowPopUpFilter] = useState(false)
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)
    const [loading, setLoading] = useState(false)

    const isTransferActive = location.search.includes('?statut=')

    // Handle Transfer Mutation
    const transferMutation = useMutation({
        mutationFn: (data) => Run.updateRunsStatus(data),
        onSuccess: () => {
            toast.success('Statuts mis à jour')
            navigate({
                pathname: '/transport',
            })
            setShowStatusModal(false)
            updateTransferGroup([])
            setSelectAll(false)
            queryClient.invalidateQueries({ queryKey: REGULATION_KEYS.LIST })
        },
        onError: (error) => {
            if (error.json) {
                error.json().then((data) => {
                    toast.error(data.error)
                })
            }
        },
    })

    // Export CSV Mutation
    const exportMutation = useMutation({
        mutationFn: async (params) => {
            const response = await API.Export.getRuns(...params)
            return response.blob()
        },
        onSuccess: (blob) => {
            saveAs(blob, `heroad-export-${formatDateLocal(new Date())}.csv`)
            setLoading(false)
        },
        onError: () => {
            setLoading(false)
            toast.error("Erreur lors de l'export")
        },
    })

    const handleTransfer = () => {
        setTransferLabel('Sélection')
        if (isTransferActive && transferGroup.length > 0) {
            setShowStatusModal(true)
        } else {
            navigate({
                pathname: '/transport',
                search: '?statut=true',
            })
        }
    }

    const handleTransferForm = () => {
        const formattedRuns = transferGroup.map((run) => run['@id'])
        const data = {
            status: statusForm,
            runs: formattedRuns,
        }
        transferMutation.mutate(data)
    }

    const handleExport = () => {
        const between = {
            strictlyBefore: formatDateYYYYMMDD(dateTo),
            strictlyAfter: formatDateYYYYMMDD(dateFrom),
        }
        setLoading(true)

        exportMutation.mutate([
            search,
            null,
            patient?.value || '',
            between,
            type?.value || '',
            status?.value || '',
            object?.value || '',
            user?.value || '',
            partner?.value || '',
            excludeStatus?.value || '',
        ])
    }

    const handleCancelTransfer = () => {
        updateTransferGroup([])
        setStatus(null)
        setSelectAll(false)
        setTransferLabel('Statut')
        navigate({ pathname: '/transport' })
    }

    const checkActiveFilter = () =>
        patient ||
        object ||
        partner ||
        status ||
        excludeStatus ||
        user ||
        dateTo === null ||
        type

    return {
        showPopUpFilter,
        setShowPopUpFilter,
        openSelectDatePicker,
        setOpenSelectDatePicker,
        loading,
        isTransferActive,
        handleTransfer,
        handleExport,
        handleCancelTransfer,
        checkActiveFilter,
        transferMutation,
        exportMutation,
        handleTransferForm,
    }
}
