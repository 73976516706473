import React from 'react'

const ClassicTextarea = ({
    id,
    label,
    placeholder,
    setValue,
    value,
    className,
}) => {
    return (
        <div className="w-full">
            <label
                className="block uppercase tracking-wide text-gray-700 text-base lg:text-xs font-bold mb-2"
                htmlFor={id}
            >
                {label}
            </label>
            <textarea
                className={` appearance-none block w-full text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none border-gray-300 focus:border-lighter-blue-green hover:border-lighter-blue-green
                  resize-none ${className} `}
                id={id}
                placeholder={placeholder}
                onChange={setValue}
                value={value}
            />
        </div>
    )
}

export default ClassicTextarea
