export const PEC_ACTIONS = {
    SET_TRANSPORT: 'setTransport',
    SET_TRANSPORT_OPTIONS: 'setTransportOptions',
    SET_PEC_ERROR: 'setPecError',
    SET_LOADING: 'setLoading',
    SET_RESULT: 'setResult',
    SET_DATE_ATMP: 'setDateATMP',
    SET_AT_NUMBER: 'setAtNumber',
    SET_MOTIF: 'setMotif',
    IS_PEC_100: 'isPec100',
    SET_IDENTIFIANT: 'setIdentifiant',
    SET_PASSWORD: 'setPassword',
    OPEN_MODAL: 'openModal',
    START_SESSION: 'startSession',
    SET_WRONG_IDS: 'setWrongIds',
}

export const getPecInitialState = ({ vehicleType }) => {
    return {
        transport: vehicleType,
        transportOptions: [],
        error: null,
        loading: false,
        result: null,
        motif: '',
        dateATMP: null,
        dateRealisation: null,
        isPec100: false,
        atNumber: null,
        identifiant: '',
        password: '',
        showLoginModal: false,
        sessionStartAt: null,
        wrongIds: false,
    }
}

export const PecReducer = (state, action) => {
    switch (action.type) {
        case PEC_ACTIONS.SET_TRANSPORT: {
            return {
                ...state,
                transport: action.payload,
            }
        }
        case PEC_ACTIONS.SET_TRANSPORT_OPTIONS: {
            return {
                ...state,
                transportOptions: action.payload,
            }
        }
        case PEC_ACTIONS.SET_PEC_ERROR: {
            return {
                ...state,
                error: action.payload,
            }
        }
        case PEC_ACTIONS.SET_LOADING: {
            return {
                ...state,
                loading: action.payload,
            }
        }
        case PEC_ACTIONS.SET_RESULT: {
            localStorage.setItem(
                'pecSessionStartedAt',
                new Date().toISOString()
            )
            return {
                ...state,
                result: action.payload,
                loading: false,
            }
        }
        case PEC_ACTIONS.SET_DATE_ATMP: {
            return {
                ...state,
                dateATMP: action.payload,
                atNumber: null,
            }
        }
        case PEC_ACTIONS.SET_AT_NUMBER: {
            return {
                ...state,
                dateATMP: null,
                atNumber: action.payload,
            }
        }
        case PEC_ACTIONS.SET_MOTIF: {
            action.payload = action.payload ? action.payload : []
            return {
                ...state,
                motif: action.payload,
            }
        }
        case PEC_ACTIONS.IS_PEC_100: {
            return {
                ...state,
                isPec100: action.payload,
            }
        }
        case PEC_ACTIONS.SET_IDENTIFIANT: {
            localStorage.setItem('pecSessionId', action.payload)
            return {
                ...state,
                identifiant: action.payload,
            }
        }
        case PEC_ACTIONS.SET_PASSWORD: {
            localStorage.setItem('pecSessionPwd', action.payload)
            return {
                ...state,
                password: action.payload,
            }
        }
        case PEC_ACTIONS.OPEN_MODAL: {
            return {
                ...state,
                showLoginModal: !state.showLoginModal,
            }
        }
        case PEC_ACTIONS.START_SESSION: {
            localStorage.setItem(
                'pecSessionStartedAt',
                new Date().toISOString()
            )
            return {
                ...state,
                sessionStartAt: new Date(),
                showLoginModal: !state.showLoginModal,
                wrongIds: false,
            }
        }
        case PEC_ACTIONS.SET_WRONG_IDS: {
            return {
                ...state,
                wrongIds: action.payload,
                showLoginModal: !state.showLoginModal,
            }
        }
        default:
            return state
    }
}

export const PecTransportType = [
    {
        value: 'VSL',
        label: 'VSL',
    },
    {
        value: 'TXI',
        label: 'Taxi',
    },
    {
        value: 'ABA/VSL',
        label: 'Ambulance/VSL',
    },
    {
        value: 'ABA',
        label: 'Ambulance',
    },
]

export const PecMotifsType = [
    {
        value: 'AT',
        label: 'Arrêt de travail',
    },
    {
        value: 'MP',
        label: 'Maladie professionnelle',
    },
    {
        value: 'ALD',
        label: 'Affection longue durée',
    },
    {
        value: 'L115',
        label: 'L115 Pension militaire',
    },
]
