import React from 'react'

const Vehicle = ({ row, isComputer }) => {
    return (
        <div
            className={`flex flex-col justify-center ${
                isComputer
                    ? 'flex-row px-6 text-base pt-0'
                    : 'px-2 text-xs pt-2'
            }`}
        >
            <div className="flex justify-center">
                <span
                    className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        row.type.label === 'Ambulance'
                            ? 'bg-red-100 text-red-600'
                            : row.type.label === 'VSL'
                            ? 'bg-blue-100 text-blue-600'
                            : row.type.label === 'VTC'
                            ? 'bg-slate-300 text-slate-600'
                            : row.type.label === 'VTM'
                            ? 'bg-emerald-200 text-emerald-600'
                            : row.type.label === 'Taxi'
                            ? 'bg-orange-100 text-orange-600'
                            : 'bg-blue-100 text-blue-600'
                    }`}
                >
                    {row.type.label}
                </span>
            </div>
        </div>
    )
}

export default Vehicle
