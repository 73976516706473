import { apiClient, withErrorHandling } from '../apiClient'
import { formatHydraData } from '../../api'

export const Society = {
    fetchParameters: async () => {
        return formatHydraData(await apiClient.get(`/fetch_parameters`))
    },
    getSociety: withErrorHandling(async (id) => {
        return formatHydraData(await apiClient.get(`/societies/${id}`))
    }),
}
