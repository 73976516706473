export const RegimeList = [
    { value: '01', label: 'Régime général' },
    { value: '02', label: 'MSA Régime agricole' },
    { value: '03', label: 'RSI ou caisse des indépendants' },
    { value: '04', label: 'SNCF' },
    { value: '05', label: 'RATP' },
    { value: '08', label: 'CNMSS Militaires de carrière' },
    { value: '09', label: 'Personnel de la banque de france' },
    { value: '15', label: 'Clerc et notaire' },
    { value: '17', label: "Caisse des Français de l'étranger" },
    { value: '91', label: 'MGEN' },
    {
        value: '90',
        label: 'CRPCEN Caisse de retraite et de prévoyance des clercs et employés de notaires',
    },
    { value: '92', label: 'Mutuelle Générale' },
    { value: '93', label: 'MGP Mutuelle Générale de la Police' },
    { value: '94', label: 'Fédération Fonctionnaires SLI' },
    { value: '96', label: 'Mutuelle de la Marine' },
    { value: '95', label: 'MNH' },
    { value: '99', label: 'Autres mutuelles' },
]
