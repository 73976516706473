import React from 'react'

const ObjectMobile = ({ row }) => {
    return (
        <div className="flex w-40 max-w-40 items-center">
            <div className="truncate">{row.runObject.label}</div>
            {row.userComments && row.isUserCommentsRed ? (
                <i className="fas fa-comment-slash fa-lg px-2" />
            ) : (
                row.userComments && <i className="far fa-comment fa-lg px-2" />
            )}
        </div>
    )
}

export default ObjectMobile
