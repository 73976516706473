import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'
import API from '../../../services/api'
import SignupFormUser from './SignupFormUser'
import useSignupStore from '../../../stores/Signup'
import Plans from './Plans'
import OrderRecap from './OrderRecap'
import Alert from '../../Commons/Alert'
import { ReactComponent as Heroad } from '../../../files/HEROAD_signup.svg'
import { ReactComponent as HeroadMobile } from '../../../files/HEROAD_mobile_signup.svg'
import { ReactComponent as LogoIcon } from '../../../files/logo_heroad_signup.svg'
import { ReactComponent as LogoMobileIcon } from '../../../files/logo_heroad_mobile_signup.svg'
import SignupFormSociety from './SignupFormSociety'
import SignupCancel from './SignupCancel'
import SignupSuccess from './SignupSuccess'
import Loader from '../../Commons/Loaders/Loader'
import { DeviceManager } from '../../../DeviceManager'

const SignupContainer = () => {
    let history = useNavigate()
    const setExistingProcess = useSignupStore(
        (state) => state.setExistingProcess
    )
    const isFree = localStorage.getItem('isFree')
    const error = useSignupStore((state) => state.error)
    const setError = useSignupStore((state) => state.setError)
    const storeCleared = useSignupStore((state) => state.storeCleared)
    const setEmail = useSignupStore((state) => state.setEmail)
    const setFirstname = useSignupStore((state) => state.setFirstname)
    const setLastname = useSignupStore((state) => state.setLastname)
    const setPhone = useSignupStore((state) => state.setPhone)
    const setSocietyName = useSignupStore((state) => state.setSocietyName)
    const setSiren = useSignupStore((state) => state.setSiren)
    const setPlan = useSignupStore((state) => state.setPlan)
    const setStoreCleared = useSignupStore((state) => state.setStoreCleared)
    const setNbVehicles = useSignupStore((state) => state.setNbVehicles)

    const [loading, setLoading] = useState(true)

    const clearError = () => {
        setError(null)
    }

    const location = useLocation()

    const getUser = () => {
        setLoading(true)
        if (location.pathname === '/inscription') {
            DeviceManager.clearAllExceptDeviceId()
            setLoading(false)
        } else if (localStorage.getItem('id') && storeCleared) {
            setStoreCleared(false)
            API.Users.getWithoutSociety(localStorage.getItem('id')).then(
                (response) =>
                    response.json().then((data) => {
                        setExistingProcess(true)
                        setEmail(data.user.email)
                        setFirstname(data.user.firstname)
                        setLastname(data.user.lastname)
                        setPhone(data.user.phoneNumber)
                        if (data.user.society) {
                            setSocietyName(data.user.society.label)
                            setSiren(data.user.society.siren)
                            setNbVehicles(data.user.society.nbVehicles)
                        }
                        if (data.user.society && data.user.society.plan) {
                            setPlan(data.user.society.plan)
                        }

                        //url redirection
                        if (
                            history.location.pathname !== '/inscription/cancel'
                        ) {
                            if (
                                !data.user.email ||
                                !data.user.firstname ||
                                !data.user.lastname ||
                                !data.user.phoneNumber
                            ) {
                                if (isFree) {
                                    history('/inscription?free=true')
                                } else {
                                    history('/inscription')
                                }
                            } else if (!data.user.society) {
                                history('/inscription/societe')
                            } else {
                                if (!data.user.society.plan) {
                                    history('/inscription/plans')
                                } else if (
                                    !data.user.society.registrationPassed
                                ) {
                                    history('/inscription/recapitulatif')
                                }
                            }
                        }

                        setLoading(false)
                    })
            )
        } else {
            setLoading(false)
            history('/inscription')
        }
    }

    useEffect(() => {
        history('/')
        getUser()
    }, [])

    return (
        <div className="invisible-scrollbar bg-color-light-gray h-screen overflow-y-auto">
            <div className="bg-light-dark-green pl-6">
                <div className="flex items-center py-4">
                    <div className="sm:pl-20">
                        <div className="hidden lg:block">
                            <Heroad />
                        </div>
                        <div className="block lg:hidden ">
                            <HeroadMobile />
                        </div>
                        <h2 className="pt-4 text-xs uppercase text-white lg:text-sm">
                            Votre régulation connectée
                        </h2>
                    </div>
                    <div className="logo-svg">
                        <div className="hidden lg:block">
                            <LogoIcon />
                        </div>
                        <div className="block lg:hidden ">
                            <LogoMobileIcon />
                        </div>
                    </div>
                </div>
            </div>
            {error && (
                <div className="mx-auto w-full max-w-2xl p-4 ">
                    <Alert
                        title="Erreur"
                        text={error}
                        clearError={clearError}
                        shadow={true}
                    />
                </div>
            )}

            <div className="w-full">
                {loading ? (
                    <Loader />
                ) : (
                    <Routes>
                        <Route path="/" render={() => <SignupFormUser />} />
                        <Route
                            path="/societe"
                            render={() => <SignupFormSociety />}
                        />
                        <Route path="/plans" render={() => <Plans />} />
                        <Route
                            path="/recapitulatif"
                            render={() => <OrderRecap />}
                        />
                        <Route
                            path="/success"
                            render={() => <SignupSuccess />}
                        />
                        <Route
                            exact
                            path="/inscription/cancel"
                            render={() => <SignupCancel />}
                        />
                    </Routes>
                )}
            </div>
        </div>
    )
}

export default SignupContainer
