import { ABATEMENT } from '../../../../../services/abatement'

export const RUN_INVOICE_FORM_DETAILS_ACTIONS = {
    SET_KILOMETER_A: 'SET_KILOMETER_A',
    SET_KILOMETER_B: 'SET_KILOMETER_B',
    SET_KILOMETER_C: 'SET_KILOMETER_C',
    SET_KILOMETER_D: 'SET_KILOMETER_D',
    SET_SUPPLEMENTS: 'SET_SUPPLEMENTS',
    SET_IS_FORFAIT: 'SET_IS_FORFAIT',
    SET_IS_MINIMUM_PERCEPTION: 'SET_IS_MINIMUM_PERCEPTION',
    SET_MINIMUM_PERCEPTION_AMOUNT: 'SET_MINIMUM_PERCEPTION_AMOUNT',
    SET_RUN: 'SET_RUN',
    SET_SHARED_RUN: 'SET_SHARED_RUN',
    SET_NB_PEOPLE: 'SET_NB_PEOPLE',
    SET_ABATEMENT: 'SET_ABATEMENT',
    SET_FORFAIT_AMOUNT: 'SET_FORFAIT_AMOUNT',
    ADD_SUPPLEMENT_LINE: 'ADD_SUPPLEMENT_LINE',
    REMOVE_SUPPLEMENT_LINE: 'REMOVE_SUPPLEMENT_LINE',
    UPDATE_SUPPLEMENT_LINE: 'UPDATE_SUPPLEMENT_LINE',
}

export const getRunInvoiceFormDetailsInitialState = ({
    runInvoiceDetails,
    runId,
    runDate,
    kilometer,
    firstRunOfDirectReturn,
    directReturn,
    arrivingTime,
    departureTime,
    waitingTime,
    isReturnPath,
    amount,
    nbPeople,
    isShared,
}) => {
    // 1 récupération depuis le premier fetch
    if (runInvoiceDetails && runId && directReturn !== undefined) {
        console.log('cas 1 runInvoiceDetails', runInvoiceDetails)
        const abatementSelectObject = runInvoiceDetails?.abatement
            ? ABATEMENT.find(
                  (item) => item.value === runInvoiceDetails.abatement
              )
            : null
        return {
            ...runInvoiceDetails,
            isForfait: runInvoiceDetails?.forfaitAmount > 0,
            isMinimumPerception: runInvoiceDetails?.minimumPerceptionAmount > 0,
            abatement: runInvoiceDetails?.abatement?.value
                ? runInvoiceDetails?.abatement
                : abatementSelectObject,
            runId: runId,
            waitingTime: waitingTime,
            directReturn: directReturn,
            isReturnPath: isReturnPath,
            firstRunOfDirectReturn: firstRunOfDirectReturn,
            amount: amount,
            nbPeople: nbPeople,
            isShared: isShared,
        }
        // 2 récupération depuis la modale de modification
    } else if (runInvoiceDetails && runId) {
        console.log('cas 2 runInvoiceDetails', runInvoiceDetails, nbPeople)
        const abatementSelectObject = runInvoiceDetails?.abatement
            ? ABATEMENT.find(
                  (item) => item.value === runInvoiceDetails.abatement
              )
            : null
        // initialisation de l'index des supplements pour la modification avec l'id
        const runInvoiceSupplements =
            runInvoiceDetails?.runInvoiceSupplements?.map((supplement) => {
                // check si id est undefined et si oui le remplace par la valeur de l'index
                return {
                    ...supplement,
                    index: supplement.id || supplement.index,
                }
            })
        return {
            ...runInvoiceDetails,
            runInvoiceSupplements: runInvoiceSupplements,
            isForfait: runInvoiceDetails?.forfaitAmount > 0,
            isMinimumPerception: runInvoiceDetails?.minimumPerceptionAmount > 0,
            isShared: runInvoiceDetails?.nbPeople > 1,
            abatement: runInvoiceDetails?.abatement?.value
                ? runInvoiceDetails?.abatement
                : abatementSelectObject,
            runId: runId,
        }
        // 3 Création
    } else {
        console.log('NEW runInvoiceDetails', runInvoiceDetails)
        return {
            id: null,
            isReturnPath: isReturnPath,
            kilometerA: null,
            kilometerB: null,
            kilometerC: null,
            kilometerD: null,
            totalKilometer: kilometer,
            totalDiscount: null,
            totalAmount: null,
            discounts: [],
            details: [],
            runInvoiceSupplements: [],
            pecAmount: null,
            isForfait: runInvoiceDetails?.forfaitAmount > 0,
            isMinimumPerception: runInvoiceDetails?.minimumPerceptionAmount > 0,
            minimumPerceptionAmount:
                runInvoiceDetails?.minimumPerceptionAmount || null,
            nbPeople: nbPeople,
            abatement: null,
            forfaitAmount: runInvoiceDetails?.forfaitAmount || null,
            isShared: isShared,
            runId: runId,
            date: runDate,
            firstRunOfDirectReturn: firstRunOfDirectReturn,
            directReturn: directReturn,
            arrivingTime: arrivingTime,
            departureTime: departureTime,
            waitingTime: waitingTime,
            totalBeforeAbatement: null,
            isHorokilometrique: false,
            amount: amount,
        }
    }
}

export const RunInvoiceFormDetailsReducer = (state, action) => {
    switch (action.type) {
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_KILOMETER_A:
            return {
                ...state,
                kilometerA: parseFloat(action.payload),
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_KILOMETER_B:
            return {
                ...state,
                kilometerB: parseFloat(action.payload),
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_KILOMETER_C:
            return {
                ...state,
                kilometerC: parseFloat(action.payload),
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_KILOMETER_D:
            return {
                ...state,
                kilometerD: parseFloat(action.payload),
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_SUPPLEMENTS:
            return {
                ...state,
                runInvoiceSupplements: action.payload,
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_IS_FORFAIT:
            return {
                ...state,
                forfaitAmount: null,
                isForfait: action.payload,
                isMinimumPerception: false,
                minimumPerceptionAmount: null,
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_RUN:
            return {
                ...state,
                run: action.payload,
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_SHARED_RUN:
            if (!action.payload) {
                return {
                    ...state,
                    nbPeople: 1,
                    abatement: ABATEMENT[0],
                    isShared: action.payload,
                }
            } else {
                return {
                    ...state,
                    nbPeople: 2,
                    abatement: ABATEMENT[0],
                    isShared: action.payload,
                }
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_NB_PEOPLE:
            return {
                ...state,
                nbPeople: action.payload.replace(/[^0-9.]+/g, ''),
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_ABATEMENT:
            return {
                ...state,
                abatement: action.payload,
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_FORFAIT_AMOUNT:
            return {
                ...state,
                forfaitAmount: action.payload,
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_IS_MINIMUM_PERCEPTION:
            return {
                ...state,
                minimumPerceptionAmount: null,
                isMinimumPerception: action.payload,
                forfaitAmount: null,
                isForfait: false,
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.SET_MINIMUM_PERCEPTION_AMOUNT:
            return {
                ...state,
                minimumPerceptionAmount: action.payload,
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.ADD_SUPPLEMENT_LINE:
            return {
                ...state,
                runInvoiceSupplements: [
                    ...state.runInvoiceSupplements,
                    newSupplementLine(state.runInvoiceSupplements.length + 1),
                ],
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.REMOVE_SUPPLEMENT_LINE:
            return {
                ...state,
                runInvoiceSupplements: state.runInvoiceSupplements.filter(
                    (supplement) => supplement.index !== action.payload
                ),
            }
        case RUN_INVOICE_FORM_DETAILS_ACTIONS.UPDATE_SUPPLEMENT_LINE:
            console.log('UPDATE_SUPPLEMENT_LINE', action.payload, state)
            const runInvoiceSupplements = state.runInvoiceSupplements.map(
                (supplement) => {
                    // check if index is undefined and if it is, replace it with value id
                    if (supplement.index === action.payload.index) {
                        return {
                            ...supplement,
                            ...action.payload,
                            price: action.payload.price,
                        }
                    }
                    return supplement
                }
            )
            console.log('runInvoiceSupplements', runInvoiceSupplements)
            return {
                ...state,
                runInvoiceSupplements: runInvoiceSupplements,
            }
        default:
            return state
    }
}

const newSupplementLine = (id) => {
    return {
        index: id,
        label: '',
        price: '',
        isDiscounted: false,
    }
}
