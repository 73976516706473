import React from 'react'
import PrimaryButton from './Buttons/PrimaryButton'

const Success = ({ goTo, buttonLabel }) => {
    return (
        <div className="h-screen">
            <div className="bg-white p-6 md:mx-auto">
                <div className="text-center">
                    <i className="fas fa-check-circle fa-5x my-6 text-green-600" />
                    <h3 className="text-center text-base font-semibold text-gray-900 md:text-2xl">
                        Paiement réussi !
                    </h3>
                    <p className="my-2 text-gray-600">
                        Merci pour la confiance que vous nous portez.
                    </p>
                    <div className="py-10 text-center">
                        <PrimaryButton
                            action={goTo}
                            label={buttonLabel}
                            title={buttonLabel}
                            hiddenLabelOnMobile={false}
                            fullWidth={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Success
