import create from 'zustand'
import * as dayjs from 'dayjs'
import dayjsPluginUTC from 'dayjs-plugin-utc'

dayjs.extend(dayjsPluginUTC)

const useConfiguratorStore = create((set) => ({
    activeUsers: [],
    selectedActiveUsers: [],
    error: '',
    parameters: {
        departureMargin: { value: 5, label: '5 minutes' },
        arrivingMargin: { value: 15, label: '15 minutes' },
        arrivingEarlyMargin: { value: 20, label: '20 minutes' },
        arrivingLateMargin: { value: 5, label: '5 minutes' },
        departureWaitingMargin: { value: 30, label: '30 minutes' },
        nbGathering: { value: 2, label: '2 personnes' },
    },
    show: '',
    popUpText: 'Supprimé',
    notificationType: 'information',
    planningMasterRuns: [],
    teamsRuns: {},
    filterPatient: {},
    filterHealthComplementary: {},
    filterOutstandingBalance: {},
    filterPartner: {},
    invoiceRuns: [],
    setActiveUsers: (users) => set({ activeUsers: users }),
    setParameters: (updatedParameters) =>
        set((state) => ({
            parameters: {
                ...state.parameters,
                ...updatedParameters,
            },
        })),
    setError: (error) => set({ error: error }),
    setShow: (show) => set({ show: show }),
    setPopUpText: (popUpText) => set({ popUpText: popUpText }),
    setNotificationType: (notificationType) =>
        set({ notificationType: notificationType }),
    setFilterPatient: (filterPatient) => set({ filterPatient: filterPatient }),
    setFilterPartner: (filterPartner) => set({ filterPartner: filterPartner }),
    setFilterHealthComplementary: (filterHealthComplementary) =>
        set({ filterHealthComplementary: filterHealthComplementary }),
    setFilterOutstandingBalance: (filterOutstandingBalance) =>
        set({ filterOutstandingBalance: filterOutstandingBalance }),
    setSelectedActiveUsers: (activeUser) =>
        set((state) => ({
            selectedActiveUsers: [...state.selectedActiveUsers, activeUser],
        })),
    updateSelectedActiveUsers: (selectedActiveUsers) =>
        set({ selectedActiveUsers: selectedActiveUsers }),
    setPlanningMasterRuns: (masterRuns) =>
        set({ planningMasterRuns: masterRuns }),
    setTeamsRuns: (updatedTeamsRuns) =>
        set((state) => ({
            teamsRuns: {
                ...state.teamsRuns,
                ...updatedTeamsRuns,
            },
        })),
    lastUsedDate: new Date(dayjs().add(1, 'day')),
    setLastUsedDate: (lastUsedDate) => set({ lastUsedDate: lastUsedDate }),
    seriesFirstRunDate: new Date(),
    setSeriesFirstRunDate: (seriesFirstRunDate) =>
        set({ seriesFirstRunDate: seriesFirstRunDate }),
    isFromSeries: '',
    setIsFromSeries: (isFromSeries) => set({ isFromSeries: isFromSeries }),
    setInvoiceRuns: (invoiceRuns) => set({ invoiceRuns: invoiceRuns }),
}))

export default useConfiguratorStore
