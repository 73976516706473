import { v4 as uuidv4 } from 'uuid'
import { BuildVersion } from './BuildVersion'

export class DeviceManager {
    static DEVICE_ID_KEY = 'deviceId'

    static getDeviceId() {
        let deviceId = localStorage.getItem(this.DEVICE_ID_KEY)
        if (!deviceId) {
            deviceId = this.generateDeviceId()
            this.saveDeviceId(deviceId)
        }
        return deviceId
    }

    static generateDeviceId() {
        return uuidv4()
    }

    static saveDeviceId(deviceId) {
        localStorage.setItem(this.DEVICE_ID_KEY, deviceId)
    }

    static clearAllExceptDeviceId() {
        const deviceId = this.getDeviceId()
        localStorage.clear()
        this.saveDeviceId(deviceId)
    }

    static getDeviceInfo() {
        const userAgent = navigator.userAgent
        let deviceType = 'unknown'
        let deviceOs = 'unknown'

        // Déterminer le type d'appareil
        if (/Mobi|Android/i.test(userAgent)) {
            deviceType = 'mobile'
        } else if (/Tablet|iPad/i.test(userAgent)) {
            deviceType = 'tablet'
        } else {
            deviceType = 'desktop'
        }

        // Déterminer l'OS
        if (/Win/i.test(userAgent)) {
            deviceOs = 'Windows'
        } else if (/Mac/i.test(userAgent)) {
            deviceOs = 'MacOS'
        } else if (/Linux/i.test(userAgent)) {
            deviceOs = 'Linux'
        } else if (/Android/i.test(userAgent)) {
            deviceOs = 'Android'
        } else if (/iOS/i.test(userAgent)) {
            deviceOs = 'iOS'
        }

        return {
            deviceType,
            deviceOs,
            appVersion: BuildVersion,
        }
    }
}
