import React, { useEffect, useReducer } from 'react'
import API from '../../../services/api'
import { useStickyDateState } from '../../../hooks/LocalStorageDatesHooks'
import * as dayjs from 'dayjs'
import TeamFilter from './TeamFilter'
import TeamListContainer from './TeamListContainer'
import {
    TeamsReducer,
    getInitialState,
    ACTIONS,
} from '../../../services/Reducers/TeamsReducer'
import { useDebounce } from '../../../hooks/useDebounce'
import Manage from './Manage'
import useUpdateEffect from '../../../hooks/useUpdateEffect'
import { formatDateYYYYMMDD } from '../../../services/dateUtils'

const formatData = (masterRuns) => {
    return masterRuns
        .sort((a, b) => a.position - b.position)
        .map((masterRun) => {
            let usernames =
                masterRun.users && masterRun.users.length
                    ? masterRun.users.reduce((team, user, index) => {
                          const name = `${user.firstname} ${user.lastname}`
                          return index ? `${team} - ${name}` : name
                      }, '')
                    : "Pas d'équipe"

            return {
                ...masterRun,
                usernames: usernames,
                nbRuns: masterRun.runs.length,
            }
        })
}

const formatDefaultMasterRunData = (defaultMasterRuns) => {
    return defaultMasterRuns
        .sort((a, b) => a.position - b.position)
        .map((defaultMasterRun) => {
            let usernames =
                defaultMasterRun.users && defaultMasterRun.users.length
                    ? defaultMasterRun.users.reduce((team, user, index) => {
                          const name = `${user.firstname} ${user.lastname}`
                          return index ? `${team} - ${name}` : name
                      }, '')
                    : "Pas d'équipe"

            return {
                ...defaultMasterRun,
                usernames: usernames,
                nbRuns: 0,
            }
        })
}

const Teams = () => {
    const [dateFilter, setDateFilter] = useStickyDateState(
        new Date(dayjs().add(1, 'day')),
        'storedDate'
    )

    const [teams, dispatch] = useReducer(TeamsReducer, {}, getInitialState)

    const handleLoading = (value) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: value })
    }

    function fetchMasterRunsList(loading = true) {
        if (loading) {
            handleLoading(true)
        }

        API.MasterRuns.list(teams.search, dateFilter, null, false).then(
            (data) => {
                const formattedTeams = formatData(data['hydra:member'])

                if (formattedTeams.length) {
                    dispatch({
                        type: ACTIONS.SET_TEAMS,
                        payload: formattedTeams,
                    })
                } else {
                    setUp()
                }
            }
        )
    }

    function fetchDefaultMasterRunsList(loading = true) {
        if (loading) {
            handleLoading(true)
        }

        API.DefaultMasterRuns.list(teams.search).then((response) => {
            response.json().then((data) => {
                const formattedTeams = formatDefaultMasterRunData(
                    data['hydra:member']
                )
                dispatch({
                    type: ACTIONS.SET_TEAMS,
                    payload: formattedTeams,
                })
                if (!formattedTeams.length) {
                    dispatch({
                        type: ACTIONS.SET_ERROR_MESSAGE,
                        payload: 'Aucune équipe par défaut.',
                    })
                }
            })
        })
    }

    useDebounce(
        () =>
            teams.defaultTeamsView
                ? fetchDefaultMasterRunsList()
                : fetchMasterRunsList(),
        800,
        [teams.search]
    )

    useUpdateEffect(() => {
        if (!teams.defaultTeamsView) {
            fetchMasterRunsList()
        }
    }, [teams.defaultTeamsView])

    useEffect(() => {
        fetchMasterRunsList()
    }, [dateFilter])

    useEffect(() => {
        if (teams.defaultTeamsView) {
            // active default teams view
            fetchDefaultMasterRunsList()
        }
    }, [teams.defaultTeamsView])

    const handleDeleteTeam = (id) => {
        if (teams.defaultTeamsView) {
            API.DefaultMasterRuns.delete(id).then((response) => {
                fetchDefaultMasterRunsList()
            })
        } else {
            API.MasterRuns.delete(id).then((response) => {
                fetchMasterRunsList()
            })
        }
    }
    const setUp = () => {
        handleLoading(true)

        const data = {
            society: localStorage.getItem('society'),
            date: formatDateYYYYMMDD(dateFilter),
        }
        API.Planning.setUp(data)
            .then((response) => {
                console.log(response)

                fetchMasterRunsList()
            })
            .catch((error) => {
                if (error.json) {
                    error.json().then((data) => {
                        dispatch({
                            type: ACTIONS.SET_ERROR_MESSAGE_AND_CLEAR_TEAMS,
                            payload: data['error'],
                        })
                    })
                }
            })
    }

    return (
        <div>
            <TeamFilter
                teams={teams}
                dispatch={dispatch}
                dateFilter={dateFilter}
                setDateFilter={setDateFilter}
            />
            <TeamListContainer
                teams={teams}
                dispatch={dispatch}
                handleDeleteElement={handleDeleteTeam}
                fetchMasterRunsList={fetchMasterRunsList}
                fetchDefaultMasterRunsList={fetchDefaultMasterRunsList}
                dateFilter={dateFilter}
            />
            {teams.isOpenedManage && (
                <Manage
                    teams={teams}
                    teamsDispatch={dispatch}
                    fetchMasterRunsList={fetchMasterRunsList}
                    fetchDefaultMasterRunsList={fetchDefaultMasterRunsList}
                    date={dateFilter}
                />
            )}
        </div>
    )
}

export default Teams
