import { Route, Routes } from 'react-router-dom'
import React, { useState } from 'react'
import {
    useStickyDateState,
    useStickyEndDateState,
} from '../../../../hooks/LocalStorageDatesHooks'
import LotList from './LotList'
import LotFilter from './LotFilter'
import LotForm from './LotForm'

const LotContainer = () => {
    const [search, setSearch] = useState('')
    const [archived, setArchived] = useState(false)
    const [transferGroup, setTransferGroup] = useState([])
    const [firstLoading, setFirstLoading] = useState(true)
    const [isReloading, setIsReloading] = useState(false)
    const [dateFrom, setDateFrom] = useStickyDateState(new Date(), 'storedDate')
    const [dateTo, setDateTo] = useStickyEndDateState(
        new Date(),
        'storedDate',
        'storedEndDate'
    )
    const [openFilterModal, setOpenFilterModal] = useState(false)

    return (
        <Routes>
            <Route path=":id" element={<LotForm />} />
            <Route
                exact
                path="/"
                element={
                    <div className="pb-10">
                        <LotFilter
                            transferGroup={transferGroup}
                            setTransferGroup={setTransferGroup}
                            search={search}
                            setSearch={setSearch}
                            dateFrom={dateFrom}
                            setDateFrom={setDateFrom}
                            setDateTo={setDateTo}
                            dateTo={dateTo}
                            setArchived={setArchived}
                            archived={archived}
                            setOpenFilterModal={setOpenFilterModal}
                            setFirstLoading={setFirstLoading}
                            setIsReloading={setIsReloading}
                            isReloading={isReloading}
                        />
                        <LotList
                            transferGroup={transferGroup}
                            firstLoading={firstLoading}
                            setFirstLoading={setFirstLoading}
                            isReloading={isReloading}
                            setAddToTransferGroup={setTransferGroup}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            search={search}
                            archived={archived}
                            openFilterModal={openFilterModal}
                            setOpenFilterModal={setOpenFilterModal}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default LotContainer
