import {
    getModalInitialState,
    ModalFormReducer,
} from '../../Regulation/Reducers/ModalReducer'
import {
    getPecInitialState,
    PecReducer,
    PecTransportType,
} from '../../../../services/Reducers/PecReducer'
import {
    getPatientFormInitialState,
    PatientFormReducer,
} from '../../Regulation/Reducers/PatientFormReducer'
import {
    getInitialState,
    SeriesReducer,
} from '../../../../services/Reducers/SeriesReducer'
import {
    getPrescriptionFormInitialState,
    PrescriptionFormReducer,
} from '../../Regulation/Reducers/PrescriptionFormReducer'
import {
    getPractitionerInitialState,
    PractitionerFormReducer,
} from '../../Regulation/Reducers/PractitionerFormReducer'
import {
    getPayingCenterInitialState,
    PayingCenterFormReducer,
} from '../../Regulation/Reducers/PayingCenterFormReducer'
import {
    getSeriesInvoiceFormInitialState,
    SeriesCpamInvoiceFormReducer,
} from './SeriesCpamInvoiceFormReducer'

export const getSeriesGlobalInitialState = ({ date }) => {
    const vehicleType = PecTransportType[1]
    return {
        series: getInitialState({ date }),
        patientForm: getPatientFormInitialState({ undefined }),
        modalReducer: getModalInitialState(), // ACTION MODAL
        prescriptionForm: getPrescriptionFormInitialState({ undefined }),
        practitionerForm: getPractitionerInitialState({ undefined }), // ACTION MODAL
        payingCenterForm: getPayingCenterInitialState({ undefined }), // ACTION MODAL
        pecReducer: getPecInitialState({ vehicleType }),
        seriesCpamInvoiceForm: getSeriesInvoiceFormInitialState(),
    }
}

export function SeriesGlobalFormReducer(state, action) {
    return {
        series: SeriesReducer(state.series, action),
        patientForm: PatientFormReducer(state.patientForm, action),
        modalReducer: ModalFormReducer(state.modalReducer, action),
        practitionerForm: PractitionerFormReducer(
            state.practitionerForm,
            action
        ),
        payingCenterForm: PayingCenterFormReducer(
            state.payingCenterForm,
            action
        ),
        pecReducer: PecReducer(state.pecReducer, action),
        prescriptionForm: PrescriptionFormReducer(
            state.prescriptionForm,
            action
        ),
        seriesCpamInvoiceForm: SeriesCpamInvoiceFormReducer(
            state.seriesCpamInvoiceForm,
            action
        ),
    }
}
