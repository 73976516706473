import { toast } from 'react-toastify'
import API from '../../../../services/api'

export const checkHealthComplementaryForm = (healthComplementary) => {
    let msg = []
    if (!healthComplementary.label) {
        msg.push('Veuillez renseigner un label.')
    }
    if (msg.length > 0) {
        msg.forEach((e) => {
            toast.error(e)
        })
        return true
    }
}

export const handleHealthComplementaryForm = async (healthComplementary) => {
    let result = { success: false, data: null }
    let msg = ''
    if (!checkHealthComplementaryForm(healthComplementary)) {
        try {
            let response
            if (healthComplementary.id) {
                response = await API.HealthComplementary.put(
                    healthComplementary.id,
                    healthComplementary
                )
                msg = 'Complémentaire santé modifié avec succès'
            } else {
                response = await API.HealthComplementary.post(
                    healthComplementary
                )
                msg = 'Complémentaire santé ajoutée avec succès'
            }
            if (response.ok) {
                const jsonData = await response.json()
                toast.success(msg)
                result.success = true
                result.data = jsonData
            }
        } catch (error) {
            if (error.json) {
                const data = await error.json()
                toast.error(data['hydra:description'])
            } else {
                toast.error('Erreur, veuillez contacter un administrateur')
            }
            result.success = false
            result.data = null
        }
    }
    return result
}
