import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { HealthComplementaries } from '../../../services/API/Entities/healthComplementaries'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'
import NoData from '../NoData'
import StyledInput from 'components/Commons/StyledInput'
import { Lock } from 'iconoir-react'

const HealthComplementaryListComponent = ({ search, setSearch }) => {
    const navigate = useNavigate()
    const [searchTerm, setSearchTerm] = useState('')

    const {
        data: healthComplementaries = [],
        isLoading,
        isError,
    } = useQuery({
        queryKey: ['healthComplementaries', search],
        queryFn: () => HealthComplementaries.fetchAll(search),
    })

    const filteredHealthComplementaries = healthComplementaries.filter((complementary) =>
        Object.entries(complementary).some(([key, value]) => {
            if (key === 'id') return false
            if (value == null) return false
            return value
                .toString()
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        })
    )

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return (
            <NoData
                message="Une erreur s'est produite lors du chargement des complémentaires santé."
                action={() => {
                    navigate('/ressources/complementaires/0')
                }}
                labelAction="Ajouter une nouvelle complémentaire santé"
            />
        )
    }

    if (healthComplementaries.length === 0)
        return <NoData message="Aucune complémentaire santé disponible"
                action={() => {
                    navigate('/ressources/complementaires/0')
                }}
                labelAction="Ajouter une nouvelle complémentaire santé"
            />
            
           

    return (
        <div className="space-y-6">
            <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold">
                    Liste des complémentaires santé
                </h1>
                <div className="flex items-center space-x-4">
                    <StyledInput
                        id="search"
                        type="text"
                        placeholder="Rechercher..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="flex items-center">
                        <p className="mr-1 text-sm text-gray-500">Total</p>
                        <p className="text-sm font-bold text-gray-500">
                            {healthComplementaries.length}
                        </p>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
            {filteredHealthComplementaries.map((complementary) => (
                <div
                    key={complementary.id}
                    onClick={() => {
                        if (!complementary.special) {
                            navigate(`/ressources/complementaires/${complementary.id}`)
                        }
                    }}
                    className={`cursor-pointer rounded-lg bg-white p-6 shadow-md transition-shadow duration-300 hover:shadow-lg ${
                        complementary.special ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                >
                    <div className="flex flex-col space-y-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0">
                        <div className="flex-1">
                            <p className="flex items-center font-bold">
                                {complementary.label}
                                {complementary.special && (
                                    <Lock className="ml-2 inline-block" />
                                )}
                            </p>
                        </div>
                        <div className="flex-1">
                            <p className="text-sm font-medium">
                                {complementary.numId}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
            </div>
        </div>
    )
}

export default HealthComplementaryListComponent