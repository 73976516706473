import create from 'zustand'

const useUserAccessStore = create((set) => ({
    onlyUserRuns: true,
    showUnassignedRuns: false,
    setOnlyUserRuns: (onlyUserRuns) => set({ onlyUserRuns: onlyUserRuns }),
    setShowUnassignedRuns: (showUnassignedRuns) =>
        set({ showUnassignedRuns: showUnassignedRuns }),
}))

export default useUserAccessStore
