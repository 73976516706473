import React, { memo } from 'react'
import Patient from '../App/Regulation/RunListComponents/Patient'
import ObjectAndDateFirstFormat from '../App/Regulation/RunListComponents/ObjectAndDateFirstFormat'
import ObjectAndDateSecondFormat from '../App/Regulation/RunListComponents/ObjectAndDateSecondFormat'
import Vehicle from '../App/Regulation/RunListComponents/Vehicle'
import DepositLocation from '../App/Regulation/RunListComponents/DepositLocation'
import TimesAndTimeValidation from '../App/Regulation/RunListComponents/TimesAndTimeValidation'
import Times from '../App/Regulation/RunListComponents/Times'
import Status from '../App/Regulation/RunListComponents/Status'
import Team from '../App/Regulation/RunListComponents/Team'
import Return from '../App/Regulation/RunListComponents/Return'
import RunNumberInSeries from '../App/Regulation/RunListComponents/RunNumberInSeries'
import DepartureOrder from '../App/Regulation/RunListComponents/DepartureOrder'

const COMPONENT_MAP = {
    patient: (props) => <Patient {...props} isComputer={true} />,
    objectAndDateFirstFormat: (props) => (
        <ObjectAndDateFirstFormat {...props} />
    ),
    objectAndDateSecondFormat: (props) => (
        <ObjectAndDateSecondFormat {...props} />
    ),
    vehicle: (props) => <Vehicle {...props} isComputer={true} />,
    depositLocation: (props) => (
        <DepositLocation {...props} isComputer={true} />
    ),
    timesAndTimeValidation: (props) => (
        <TimesAndTimeValidation {...props} isComputer={true} />
    ),
    timesOnly: (props) => <Times {...props} isComputer={true} />,
    status: (props) => <Status {...props} isComputer={true} />,
    team: (props) => <Team {...props} isComputer={true} />,
    return: (props) => <Return {...props} />,
    runNumberInSeries: (props) => (
        <RunNumberInSeries {...props} isComputer={true} />
    ),
    departureOrder: (props) => <DepartureOrder {...props} isComputer={true} />,
}

const ComputerConfiguratorDisplayed = memo(
    ({ componentType, row, isSocietyTaxi }) => {
        const Component = COMPONENT_MAP[componentType]

        if (!Component) {
            console.warn(`No component found for type: ${componentType}`)
            return null
        }

        return <Component row={row} isSocietyTaxi={isSocietyTaxi} />
    }
)

ComputerConfiguratorDisplayed.displayName = 'ComputerConfiguratorDisplayed'

export default ComputerConfiguratorDisplayed
