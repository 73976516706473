import React, { useEffect, useReducer } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import Loader from '../../Commons/Loaders/Loader'
import useConfiguratorStore from '../../../stores/Configurator'
import {
    ACTIONS,
    PartnerReducer,
    getInitialState,
} from '../../../services/Reducers/PartnerReducer'
import API from '../../../services/api'
import StyledInput from '../../Commons/StyledInput'
import { Car, SendDiagonal } from 'iconoir-react'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import { Partner } from '../../../services/API/Entities/partner'

const PartnerForm = () => {
    const [partner, dispatch] = useReducer(PartnerReducer, [], getInitialState)
    const setFilterPartner = useConfiguratorStore(
        (state) => state.setFilterPartner
    )
    const setFilterPatient = useConfiguratorStore(
        (state) => state.setFilterPatient
    )
    const navigate = useNavigate()
    const { id } = useParams()
    const parsedId = parseInt(id)
    const queryClient = useQueryClient()

    const { isLoading, data } = useQuery({
        queryKey: ['partner', parsedId],
        queryFn: () => Partner.fetchOne(parsedId),
        enabled: parsedId !== 0,
    })

    useEffect(() => {
        if (data) {
            const partnerData = {
                firstname: data.firstname || '',
                lastname: data.lastname || '',
                email: data.email || '',
                description: data.description || '',
                phoneNumber: data.phoneNumber
                    ? data.phoneNumber.replace(/\s+/g, '')
                    : '',
                idEmetteur: data.idEmetteur || '',
            }
            dispatch({ type: ACTIONS.SET_PARTNER, payload: partnerData })
        }
    }, [data])

    const mutation = useMutation({
        mutationFn: (data) =>
            parsedId === 0
                ? API.Partners.addPartner(data)
                : API.Partners.put(parsedId, data),
        onSuccess: () => {
            queryClient.invalidateQueries(['partner'])
            toast.success(
                parsedId === 0 ? 'Partenaire ajouté.' : 'Partenaire modifié.'
            )
            navigate(-1)
        },
        onError: () => {
            toast.error('Une erreur est survenue.')
        },
    })

    const checkErrors = () => {
        let hasError = false
        if (partner.firstname.trim() === '') {
            toast.error('Veuillez renseigner un prénom')
            hasError = true
        }
        if (partner.lastname.trim() === '') {
            toast.error('Veuillez renseigner un nom.')
            hasError = true
        }
        if (
            partner.email &&
            partner.email.trim() !== '' &&
            !partner.email.includes('@')
        ) {
            toast.error('Veuillez renseigner un email valide.')
            hasError = true
        }
        if (partner.phoneNumber.trim() === '') {
            toast.error('Veuillez renseigner un numéro de téléphone.')
            hasError = true
        } else if (partner.phoneNumber.trim().length !== 10) {
            toast.error('Veuillez renseigner un numéro de téléphone valide.')
            hasError = true
        }

        if (partner.idEmetteur && partner.idEmetteur.length !== 9) {
            toast.error('Veuillez renseigner un numéro PS valide.')
            hasError = true
        }
        return hasError
    }

    const handleForm = () => {
        if (!checkErrors()) {
            mutation.mutate(partner)
        }
    }

    const handleShowPartnersRuns = () => {
        setFilterPartner({
            value: `/ressources/partners/${parsedId}`,
            label: `${partner.firstname} ${partner.lastname}`,
        })
        setFilterPatient({})
        navigate('/transport')
    }

    if (isLoading) return <Loader />

    return (
        <div className="max-w-5xl lg:mt-20">
            <div className={'mb-6 flex items-center'}>
                <SendDiagonal className={'mr-2'} />
                <h2 className=" text-2xl font-bold text-gray-800">
                    {parsedId === 0
                        ? 'Ajouter un partenaire'
                        : 'Modifier un partenaire'}
                </h2>
            </div>
            <form className="space-y-6 rounded-lg bg-white  p-6 shadow-md">
                <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
                    <StyledInput
                        label="Prénom"
                        id="firstname"
                        value={partner.firstname}
                        onChange={(e) =>
                            dispatch({
                                type: ACTIONS.SET_FIRSTNAME,
                                payload: e.target.value,
                            })
                        }
                        placeholder="Jane"
                        required
                    />
                    <StyledInput
                        label="Nom de famille"
                        id="lastname"
                        value={partner.lastname}
                        onChange={(e) =>
                            dispatch({
                                type: ACTIONS.SET_LASTNAME,
                                payload: e.target.value,
                            })
                        }
                        placeholder="Dupont"
                        required
                    />
                    <StyledInput
                        label="Téléphone"
                        id="phone"
                        type="tel"
                        maxLength="10"
                        value={partner.phoneNumber}
                        onChange={(e) =>
                            dispatch({
                                type: ACTIONS.SET_PHONE,
                                payload: e.target.value,
                            })
                        }
                        placeholder="XXXXXXXXXX"
                    />
                    <StyledInput
                        label="Email"
                        id="email"
                        type="email"
                        value={partner.email}
                        onChange={(e) =>
                            dispatch({
                                type: ACTIONS.SET_EMAIL,
                                payload: e.target.value,
                            })
                        }
                        placeholder="email@example.com"
                    />
                    <div
                        className={
                            'col-span-2 rounded-lg bg-secondary-color/20 p-2'
                        }
                    >
                        <div className="flex  space-x-4">
                            <div className="flex-shrink-0">
                                <i className="fas fa-info-circle text-2xl text-secondary-color"></i>
                            </div>
                            <div>
                                <p className="mb-1 font-semibold text-secondary-color">
                                    Partage de transport Heroad
                                </p>
                                <p className="text-md text-gray-600">
                                    Renseigner le numéro PS ou ADELI de votre
                                    partenaire est obligatoire pour automatiser
                                    les transferts de transports entre clients
                                    Heroad.
                                </p>
                            </div>
                        </div>
                    </div>
                    <StyledInput
                        label="Numéro PS (ADELI) 9 chiffres"
                        id="numPs"
                        value={partner.idEmetteur}
                        onChange={(e) =>
                            dispatch({
                                type: ACTIONS.SET_ID_EMETTEUR,
                                payload: e.target.value,
                            })
                        }
                        placeholder="xxxxxxxxx"
                    />
                </div>

                <div>
                    <label
                        htmlFor="comment"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Commentaire
                    </label>
                    <textarea
                        id="comment"
                        name="comment"
                        rows="4"
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-secondary-color focus:outline-none focus:ring-secondary-color"
                        placeholder="Ajoutez vos commentaires ici..."
                        value={partner.description}
                        onChange={(e) =>
                            dispatch({
                                type: ACTIONS.SET_DESCRIPTION,
                                payload: e.target.value,
                            })
                        }
                    ></textarea>
                </div>

                <div className="flex items-center justify-between">
                    {parsedId !== 0 && (
                        <SecondaryButton
                            label={'Transports du partenaire'}
                            action={handleShowPartnersRuns}
                            icon={<Car />}
                        />
                    )}
                </div>
            </form>
            <p className="my-4 text-sm text-gray-500">
                <span className="text-red-500">*</span> Champs obligatoires, au
                moins le numéro de téléphone ou le email doit être renseigné.
            </p>
            <SendFormButtons handleForm={handleForm} />
        </div>
    )
}

export default PartnerForm
