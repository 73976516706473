import React from 'react'

const UnlimitedPlanCard = ({ handlePlan, plan }) => {
    return (
        <div
            className="flex flex-col w-full mx-auto rounded bg-white border border-t-8 border-heroad-pink transition duration-150
         ease-in-out transform hover:-translate-y-1 min-h-pricing-card"
        >
            <div className="flex flex-col pt-8 sm:pt-16 pb-4 sm:pb-8 items-center px-4 text-center">
                <h1 className="text-3xl font-bold text-heroad-pink">
                    Illimité
                </h1>
                <div className="text-xl">Véhicules illimités</div>
                <div className="pt-4 text-4xl font-bold text-heroad-pink">
                    399 €
                </div>
                <div className="text-xs">HT/mois</div>
                <div className="font-bold">
                    Sans engagement, résiliez simplement et sans frais
                </div>
                <button
                    onClick={() => handlePlan(plan)}
                    className="mt-4 font-bold rounded-lg py-2 w-full text-lg
                        bg-heroad-pink text-center text-white transition duration-150
         ease-in-out transform hover:-translate-y-1"
                >
                    Choisir le plan illimité
                </button>
                <div className="pt-4 w-full text-left">
                    <ul className="flex flex-col space-y-2">
                        <li>
                            <div className="flex">
                                <i className="fas fa-arrow-left fa-sm text-heroad-pink mr-2 mt-1"></i>
                                <div>Fonctionnalités du plan expert</div>
                            </div>
                        </li>
                        <li>
                            <div className="flex">
                                <i className="fa fa-plus fa-sm text-heroad-pink mr-2 mt-1"></i>
                                <div>
                                    Partage{' '}
                                    <span className="font-bold text-heroad-pink">
                                        illimité
                                    </span>{' '}
                                    des courses par SMS
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="flex">
                                <i className="fa fa-plus fa-sm text-heroad-pink mr-2 mt-1"></i>
                                <div>
                                    Un nombre de{' '}
                                    <span className="font-bold text-heroad-pink">
                                        véhicules illimité
                                    </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default UnlimitedPlanCard
