import { formatItemForSelect } from '../../../../tools/Utility'
import {
    NatureAssurances,
    Taux,
    Exonerations,
} from '../../../../services/Prescription'

export const PRESCRIPTION_FORM_ACTIONS = {
    SET_NATURE_ASSURANCE: 'SET_NATURE_ASSURANCE',
    SET_RATE: 'SET_RATE',
    SET_EXONERATION: 'SET_EXONERATION',
    SET_IS_SANITARY: 'SET_IS_SANITARY',
    SET_PRACTITIONER: 'SET_PRACTITIONER',
    SET_PEC_RESPONSE: 'SET_PEC_RESPONSE',
    SET_PRACTITIONERS_OPTIONS: 'SET_PRACTITIONERS_OPTIONS',
    FETCH_PRESCRIPTION: 'FETCH_PRESCRIPTION',
    SET_WORK_ACCIDENT_NUMBER: 'SET_WORK_ACCIDENT_NUMBER',
    SET_IS_TPMR: 'SET_IS_TPMR',
    SET_RATE_OPTIONS: 'SET_RATE_OPTIONS',
    SET_IS_PRIOR_APPROVAL: 'SET_IS_PRIOR_APPROVAL',
    PREFILL_PRESCRIPTION_FORM: 'PREFILL_PRESCRIPTION_FORM',
    SET_ESTABLISHMENT: 'SET_ESTABLISHMENT',
}

export const getPrescriptionFormInitialState = ({ prescription }) => {
    if (prescription) {
        let practitionersOptions = prescription.practitionersOptions
        return {
            ...prescription,
            id: prescription.id,
            practitioner: prescription.practitioner,
            date: prescription.date,
            natureAssurance: formatItemForSelect(
                prescription.natureAssurance,
                NatureAssurances
            ),
            rate: formatItemForSelect(prescription.rate, Taux),
            exoTicketModerateur: formatItemForSelect(
                prescription.exoTicketModerateur,
                Exonerations
            ),
            practitionersOptions: practitionersOptions
                ? practitionersOptions
                : [],
            rateOptions: Taux,
        }
    } else {
        return {
            id: null,
            date: '',
            natureAssurance: '',
            rate: null,
            exoTicketModerateur: '',
            isSanitary: false,
            practitioner: {},
            practitionersOptions: [],
            workAccidentNumber: '',
            isTPMR: false,
            isPriorApproval: false,
            rateOptions: Taux,
        }
    }
}

export const PrescriptionFormReducer = (state, action) => {
    switch (action.type) {
        case PRESCRIPTION_FORM_ACTIONS.SET_PEC_RESPONSE: {
            return {
                ...state,
                ...action.payload,
                rate: formatItemForSelect(action.payload.rate?.value, Taux),
                natureAssurance: formatItemForSelect(
                    action.payload.natureAssurance.value,
                    NatureAssurances
                ),
                exoTicketModerateur: formatItemForSelect(
                    action.payload.exoneration.value,
                    Exonerations
                ),
            }
        }
        case PRESCRIPTION_FORM_ACTIONS.FETCH_PRESCRIPTION: {
            if (!action.payload)
                return getPrescriptionFormInitialState({ undefined })
            return {
                ...state,
                ...action.payload,
                id: action.payload['@id'],
                practitioner: action.payload.practitioner,
                date: action.payload.date,
                natureAssurance: formatItemForSelect(
                    action.payload.natureAssurance,
                    NatureAssurances
                ),
                rate: formatItemForSelect(action.payload.rate, Taux),
                exoTicketModerateur: formatItemForSelect(
                    action.payload.exoTicketModerateur,
                    Exonerations
                ),
                endDate: action.payload.endDate,
                startDate: action.payload.startDate,
                isPriorApproval: !!action.payload.priorApprovalDate,
                rateOptions: Taux,
            }
        }
        case PRESCRIPTION_FORM_ACTIONS.SET_NATURE_ASSURANCE:
            return {
                ...state,
                natureAssurance: action.payload,
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_RATE:
            return {
                ...state,
                rate: action.payload,
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_EXONERATION:
            let ald = null
            if (action.payload.value === '4') {
                ald = formatItemForSelect('100', Taux)
            }
            return {
                ...state,
                exoTicketModerateur: action.payload,
                rate: ald ? ald : state.rate,
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_PRACTITIONER:
            return {
                ...state,
                practitioner: action.payload ? action.payload : null,
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_PRACTITIONERS_OPTIONS:
            return {
                ...state,
                practitionersOptions: action.payload,
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_WORK_ACCIDENT_NUMBER:
            return {
                ...state,
                // only have 8 max characters
                workAccidentNumber: action.payload?.substring(0, 9),
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_IS_TPMR:
            return {
                ...state,
                isTPMR: action.payload,
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_RATE_OPTIONS:
            return {
                ...state,
                rateOptions: action.payload,
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_IS_PRIOR_APPROVAL:
            return {
                ...state,
                isPriorApproval: action.payload,
            }
        case PRESCRIPTION_FORM_ACTIONS.PREFILL_PRESCRIPTION_FORM:
            return {
                ...state,
                rate: action.payload
                    ? Taux.find((rate) => rate.value === '100')
                    : null,
                exoTicketModerateur: action.payload
                    ? Exonerations.find((exo) => exo.value === '4')
                    : null,
            }
        case PRESCRIPTION_FORM_ACTIONS.SET_ESTABLISHMENT:
            return {
                ...state,
                establishment: action.payload,
            }
        default:
            return state
    }
}
