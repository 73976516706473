import API from './api'
import { Documents } from './Documents'
import { ADDRESS_ACTIONS } from './Reducers/AddressAutoCompleteReducer'
import { toast } from 'react-toastify'
import { DATE_ACTIONS } from './Reducers/DateReducer'
import { TRANSPORT_ACTIONS } from '../components/App/Regulation/Reducers/TransportFormReducer'
import { PATIENT_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PatientFormReducer'
import { PRESCRIPTION_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PrescriptionFormReducer'
import { PRACTITIONER_FORM_ACTIONS } from '../components/App/Regulation/Reducers/PractitionerFormReducer'
import { INVOICE_CPAM_ACTIONS } from '../components/App/Regulation/Reducers/InvoiceCpamReducer'
import { getRunInvoiceFormDetailsFromRuns } from './InvoiceUtils'
import { checkVehicleConventionDates } from '../components/App/Regulation/FormHandlers/handleInvoiceForm'
import dayjs from 'dayjs'
import {
    formatDateFromApiToDateObject,
    removeTimeZoneToDate,
} from './dateUtils'
import { ReactComponent as Logo } from '../files/logo_heroad.svg'
import { Society } from './API/Entities/society'
import { isDateValid } from './DateService'

const getReturnType = (run) => {
    switch (true) {
        case !run.isRoundTrip && !run.isReturnPath:
            return {
                value: 1,
                label: 'Aller simple',
            }
        case !run.isRoundTrip && run.isReturnPath:
            return {
                value: 2,
                label: 'Retour simple',
            }
        case run.directReturn:
            return {
                value: 3,
                label: 'Aller-retour direct',
            }
        case run.isRoundTrip && !run.directReturn:
            return {
                value: 4,
                label: 'Aller-retour indirect',
            }
    }
}

export const isArtisan = localStorage.getItem('isArtisan') === 'true'

export const isRunAbleToBeTransferred = (run) => {
    return run.status.id !== 'closed'
}

const hasValidZipCode = (location) => {
    return location.zipCode && location.zipCode.trim() !== ''
}

const hasValidCity = (location) => {
    return location.city && location.city.trim() !== ''
}

const hasValidPatientAddress = (patient) => {
    return (
        patient.patientsAddresses.length > 0 &&
        patient.patientsAddresses[0]?.address?.city
    )
}

const hasValidPatientInfo = (patient, dateOfBirth) => {
    return patient.socialNumber && isDateValid(dateOfBirth)
}

const isNotPaidRun = (run) => {
    return !run.runObject.label.startsWith('Course payante')
}

const hasValidRunInfo = (run) => {
    run.masterRun = run.team ? run.team : run.masterRun
    return (
        run.masterRun &&
        new Date() > new Date(run.arrivingTime) &&
        run.kilometer
    )
}

export const isRunAbleToBeInvoiced = (run, patient, dateOfBirth) => {
    return (
        hasValidRunInfo(run) &&
        hasValidZipCode(run.pickUpLocation) &&
        hasValidCity(run.pickUpLocation) &&
        hasValidZipCode(run.depositLocation) &&
        hasValidCity(run.depositLocation) &&
        hasValidPatientAddress(patient) &&
        hasValidPatientInfo(patient, dateOfBirth) &&
        isNotPaidRun(run)
    )
}
export const getInvoiceErrorMessage = (run, patient, dateOfBirth) => {
    const msg = []
    if (!hasValidRunInfo(run)) {
        msg.push('Informations du transport invalides')
    }
    if (!hasValidZipCode(run.pickUpLocation)) {
        msg.push('Code postal de lieu de prise en charge invalide')
    }
    if (!hasValidCity(run.pickUpLocation)) {
        msg.push('Ville de lieu de prise en charge invalide')
    }
    if (!hasValidZipCode(run.depositLocation)) {
        msg.push("Code postal du lieu d'arrivée invalide")
    }
    if (!hasValidCity(run.depositLocation)) {
        msg.push("Ville du lieu d'arrivée invalide")
    }
    if (!hasValidPatientAddress(patient)) {
        msg.push('Adresse du patient invalide')
    }
    if (!hasValidPatientInfo(patient, dateOfBirth)) {
        msg.push('Informations du patient invalides')
    }
    if (!isNotPaidRun(run)) {
        msg.push('La course est une course payante')
    }
    checkVehicleConventionDates(run, msg, run.masterRun)

    return msg
}

export const isRunAbleToBeInvoicedPrompt = (run, patient, dateOfBirth) => {
    const msg = getInvoiceErrorMessage(run, patient, dateOfBirth)
    if (msg.length > 0) {
        msg.forEach((e) => {
            toast.error(e, { autoClose: false })
        })
        return false
    }

    return true
}

const fetchRunObjects = async (dispatch) => {
    const response = await API.RunObject.list()
    const data = await response.json()
    const runObjectList = data['hydra:member'].map((runObject) => {
        return {
            ...runObject,
            value: runObject.label,
        }
    })
    dispatch({
        type: TRANSPORT_ACTIONS.SET_RUN_OBJECTS,
        payload: runObjectList,
    })
}

const setTeams = (dispatch, data) => {
    const teams = data['hydra:member'].map((m) => {
        return {
            value: m['@id'],
            vehicle: m.vehicle,
            label: `${m.vehicle.label} - ${m.users.map(
                (u, index) =>
                    `${index === 0 && m.users.length > 1 ? ' ' : ''} ${
                        u.firstname
                    } ${u.lastname}`
            )}`,
            runs: m.runs,
            ['@id']: m['@id'],
        }
    })
    dispatch({
        type: TRANSPORT_ACTIONS.SET_TEAMS,
        payload: teams,
    })
}

export const fetchTeamsList = async (dispatch, run) => {
    const data = await API.MasterRuns.list(null, run.date)
    if (data['hydra:member'].length === 0) {
        console.log(run.date)
        //generate teams
        const data = {
            society: localStorage.getItem('society'),
            date: dayjs(run.date).format('YYYY-MM-DD'),
        }
        await API.Planning.setUp(data).then((data) => {
            API.MasterRuns.list(null, run.date).then((da) => {
                setTeams(dispatch, da)
                if (isArtisan) {
                    dispatch({
                        type: TRANSPORT_ACTIONS.SET_TEAM,
                        payload: data['hydra:member'][0],
                    })
                }
            })
        })
    } else {
        if (isArtisan && data['hydra:member'].length > 0) {
            dispatch({
                type: TRANSPORT_ACTIONS.SET_TEAM,
                payload: data['hydra:member'][0],
            })
        }
        setTeams(dispatch, data)
    }
}

const fetchTeamParameters = async (dispatch) => {
    const response = await Society.fetchParameters()

    return dispatch({
        type: TRANSPORT_ACTIONS.SET_PARAMETERS,
        payload: response,
    })
}

const fetchPartnersList = async (dispatch) => {
    const response = await API.Partners.list(null)
    const data = await response.json()
    const partnersList = data['hydra:member'].map((partner) => {
        return {
            value: partner['@id'],
            label: partner.firstname + ' ' + partner.lastname,
            partner: partner,
            icon: partner.heroad ? <Logo width="19px" height="19px" /> : null,
        }
    })
    dispatch({
        type: TRANSPORT_ACTIONS.SET_PARTNERS,
        payload: partnersList,
    })
}

const FetchRunsInformations = {
    getRun: async (
        id,
        dispatch,
        run,
        location,
        pickUpDispatch,
        pickUpAddress,
        depositAddress,
        depositDispatch,
        dateDispatch,
        prsDateDispatch,
        dateMaternityDispatch,
        workAccidentDateDispatch,
        priorApprovalDateDispatch
    ) => {
        Promise.all([
            fetchTeamParameters(dispatch),
            fetchRunObjects(dispatch),
            fetchPartnersList(dispatch),
        ])
            .then(() => {
                const urlParams = new URLSearchParams(location.search)
                const isCopy = urlParams.get('copy')
                let idToGet = isCopy || (id != 0 ? id : null)

                if (idToGet) {
                    API.Runs.get(idToGet).then((data) => {
                        let linkedRun
                        if (!isCopy) {
                            const runs = [data]
                            linkedRun = data.return
                                ? data.return
                                : data.lead
                                ? data.lead
                                : null

                            if (linkedRun) {
                                runs.push(linkedRun)
                            }

                            const runInvoiceDetails =
                                getRunInvoiceFormDetailsFromRuns(runs)

                            const invoiceDetails = {
                                ...data.cpamInvoice,
                                runsInvoiceFormsDetails: runInvoiceDetails,
                            }

                            dispatch({
                                type: INVOICE_CPAM_ACTIONS.FETCH_INVOICE_CPAM,
                                payload: invoiceDetails,
                            })
                        }

                        dispatch({
                            type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                            payload: data.patient,
                        })
                        pickUpDispatch({
                            type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                            payload: data.pickUpLocation,
                        })
                        depositDispatch({
                            type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                            payload: data.depositLocation,
                        })
                        dateDispatch({
                            type: DATE_ACTIONS.SET_DATE,
                            payload: data.patient.dateOfBirth
                                ? data.patient.dateOfBirth
                                : data.patient.stringDateOfBirth,
                        })
                        // prescriptions dispatch
                        prsDateDispatch({
                            type: DATE_ACTIONS.SET_DATE,
                            payload: data.prsDate,
                        })
                        dateMaternityDispatch({
                            type: DATE_ACTIONS.SET_DATE,
                            payload: data.prescription?.dateMaternity,
                        })
                        workAccidentDateDispatch({
                            type: DATE_ACTIONS.SET_DATE,
                            payload: data.prescription?.workAccidentDate,
                        })
                        priorApprovalDateDispatch({
                            type: DATE_ACTIONS.SET_DATE,
                            payload: data.prescription?.priorApprovalDate,
                        })
                        dispatch({
                            type: PRESCRIPTION_FORM_ACTIONS.FETCH_PRESCRIPTION,
                            payload: data.prescription,
                        })
                        dispatch({
                            type: PRACTITIONER_FORM_ACTIONS.FETCH_PRACTITIONER,
                            payload: data.prescription?.practitioner,
                        })

                        dispatch({
                            type: TRANSPORT_ACTIONS.SET_RUN,
                            payload: {
                                id: !isCopy ? data.id : null,
                                returnId:
                                    data.return && !isCopy
                                        ? data.return.id
                                        : null,
                                date: formatDateFromApiToDateObject(data.date),
                                isRoundTrip: data.isRoundTrip,
                                isReturnPath: data.isReturnPath,
                                directReturn: data.directReturn,
                                arrivingTime: removeTimeZoneToDate(
                                    data.arrivingTime
                                ),
                                departureTime: removeTimeZoneToDate(
                                    data.departureTime
                                ),
                                returnTime: data.return
                                    ? removeTimeZoneToDate(
                                          data.return.departureTime
                                      )
                                    : null,
                                runType: getReturnType(data),
                                oldRunType: getReturnType(data),
                                series: data.series,
                                vehicleType: data.type,
                                marginTime: data.margin,
                                parametersMarginTime: run.parametersMarginTime,
                                showMargin:
                                    data.margin !== run.parametersMarginTime,
                                waitingTime: data.waitingTime
                                    ? data.waitingTime
                                    : 0,
                                patient: data.patient,
                                partner: data.partner ? data.partner : null,
                                runObject: data.runObject,
                                showAmount: !!data.amount,
                                amount: data.amount,
                                pickUpLocation: data.pickUpLocation,
                                depositLocation: data.depositLocation,
                                newPickUpLocationPrediction:
                                    data.pickUpLocation.googlePrediction,
                                newDepositLocationPrediction:
                                    data.depositLocation.googlePrediction,
                                team: data.masterRun
                                    ? {
                                          value: data.masterRun['@id'],
                                          vehicle: data.masterRun.vehicle,
                                          label: `${
                                              data.masterRun.vehicle.label
                                          } - ${data.masterRun.users.map(
                                              (u, index) =>
                                                  `${
                                                      index === 0 &&
                                                      data.masterRun.users
                                                          .length > 1
                                                          ? ' '
                                                          : ''
                                                  } ${u.firstname} ${
                                                      u.lastname
                                                  }`
                                          )}`,
                                      }
                                    : null,
                                comments: data.comments,
                                isGiven:
                                    data.isGiven === null
                                        ? true
                                        : data.isGiven === false
                                        ? data.isGiven
                                        : true,
                                sharedAt: data.sharedAt,
                                showSharingOptions: !!data.partner,
                                note: data.note,
                                sharingOption:
                                    data.sharingOption?.label === 'empty'
                                        ? {
                                              label: '🔕 Ne pas notifier',
                                              value: 'empty',
                                          }
                                        : data.sharingOption
                                        ? {
                                              label: data.sharingOption?.label,
                                              value: data.sharingOption?.label,
                                          }
                                        : null,
                                leadId: data.lead?.id,
                                isDisabled:
                                    getReturnType(data).value > 2 &&
                                    data.isReturnPath,
                                status: data.status.label,
                                loading: false,
                                approximateTime: data.approximateTime,
                                isCPAMInvoice: data.isCpamInvoice,
                                prsNumber: data.prsNumber,
                                kilometer: data.kilometer,
                                prsDate: formatDateFromApiToDateObject(
                                    data.prsDate,
                                    true
                                ),
                                runInvoiceDetails: data.runInvoiceDetails,
                                showPrescriptionDetails: !!data.kilometer,
                                linkedRunStatus: linkedRun?.status?.label,
                                linkedRun: linkedRun,
                                signatureLocation:
                                    data.signature?.location ||
                                    data.depositLocation.city,
                                signatureDate: data.signature?.date
                                    ? dayjs(
                                          formatDateFromApiToDateObject(
                                              data.signature.date
                                          )
                                      ).format('DD/MM/YYYY')
                                    : dayjs(
                                          formatDateFromApiToDateObject(
                                              data.date
                                          )
                                      ).format('DD/MM/YYYY'),
                                signature: data.signature?.image,
                                signatureId: data.signature?.id,
                                effectiveArrivingTime: removeTimeZoneToDate(
                                    data.effectiveArrivingTime,
                                    true
                                ),
                                effectiveDepartureTime: removeTimeZoneToDate(
                                    data.effectiveDepartureTime,
                                    true
                                ),
                                documents: data.documents
                                    ? data.documents
                                    : Documents[0],
                                userComments: data.userComments,
                            },
                        })
                    })
                } else {
                    dispatch({
                        type: TRANSPORT_ACTIONS.SET_LOADING,
                        payload: false,
                    })
                }
            })
            .catch((error) => {
                // Gérer les erreurs ici
                console.error(
                    "Une erreur s'est produite lors de l'exécution des requêtes",
                    error
                )
            })
    },
    getPartnerRunInfo: async (id, dispatch, run, action, token) => {
        if (id) {
            API.Partners.getRun(token, id)
                .then((data) => {
                    let paymentMethod = {
                        value: 'Course payante',
                        label: 'Course payante',
                        ['@id']: '/run_objects/Course payante',
                    }
                    if (
                        data.runObject.label.startsWith('Course payante') &&
                        data.runObject.label !== 'Course payante'
                    ) {
                        let splitedPaiementLabel =
                            data.runObject.label.split(' ')
                        let paiementLabel =
                            splitedPaiementLabel[2].charAt(0).toUpperCase() +
                            splitedPaiementLabel[2].slice(1)
                        paiementLabel = splitedPaiementLabel[3]
                            ? paiementLabel + ' ' + splitedPaiementLabel[3]
                            : paiementLabel
                        paymentMethod = {
                            value: paiementLabel,
                            label: paiementLabel,
                            ['@id']: data.runObject['@id'],
                        }
                    }

                    dispatch({
                        type: action.GET_RUN_INFO,
                        payload: {
                            id: data.id,
                            dateString: dayjs(data.date).format('YYYY-MM-DD'),
                            patient: data.patient,
                            runObject: data.runObject,
                            arrivingTime: removeTimeZoneToDate(
                                data.arrivingTime
                            ),
                            departureTime: removeTimeZoneToDate(
                                data.departureTime
                            ),
                            pickUpLocation: data.pickUpLocation,
                            depositLocation: data.depositLocation,
                            isReturnPath: data.isReturnPath,
                            isRoundTrip: data.isRoundTrip,
                            loading: false,
                            series: data.series,
                            seriesIndex: data.seriesIndex,
                            comments: data.comments,
                            partner: data.partner,
                            patientsAddresses: data.patient.patientsAddresses[0]
                                ? data.patient.patientsAddresses.sort(
                                      (a, b) => {
                                          return a.id - b.id
                                      }
                                  )
                                : null,
                            patientsContacts: data.patient.patientsContacts[0]
                                ? data.patient.patientsContacts.sort((a, b) => {
                                      return a.id - b.id
                                  })
                                : null,
                            showModal: false,
                            showContactModal: false,
                            contactAll: false,
                            documents: data.documents
                                ? data.documents
                                : Documents[0],
                            paymentMethod: paymentMethod,
                            amount: data.amount,
                            effectiveArrivingTime: removeTimeZoneToDate(
                                data.effectiveArrivingTime,
                                true
                            ),
                            effectiveDepartureTime: removeTimeZoneToDate(
                                data.effectiveDepartureTime,
                                true
                            ),
                            status: data.status,
                            userComments: data.userComments,
                            personToContact: [],
                            isTakenInCharge: data.isTakenInCharge,
                        },
                    })
                })
                .catch((error) => {
                    dispatch({
                        type: action.SET_ERROR_FOR_PARTNER,
                        payload: true,
                    })
                })
        } else {
            dispatch({
                type: action.SET_LOADING,
                payload: false,
            })
        }
    },

    // fetchDistanceData: async (run, dispatch, action) => {
    //     const response = await API.Distance.get(
    //         run.pickUpAddress.city,
    //         run.depositAddress.city
    //     )
    //     const data = await response.json()
    //     dispatch({
    //         type: action,
    //         payload: data,
    //     })
    // },
}

export default FetchRunsInformations
