import RunsList from './RunsList'
import UnassignedRunsList from './UnassignedRunsList'
import { toast } from 'react-toastify'
import { ACTIONS } from '../../../../services/Reducers/PlanningReducer'
import RunModalContainer from '../../../Modal/RunModal/RunModalContainer'
import React, { useRef } from 'react'
import {
    Download,
    Minus,
    NavArrowLeft,
    PerspectiveView,
    Plus,
    User,
} from 'iconoir-react'
import SelectPlanningTeamsModal from '../../../Modal/PlanningModal/SelectPlanningTeamsModal'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import DownloadButton from '../../../Commons/Buttons/DownloadButton'

const RunsContainer = ({
    planning,
    totalRunsTeam,
    handleClearTeamAndUnassignedRun,
    handleSelectedTeam,
    handleOpenRunManageModal,
    reRender,
    dispatch,
    unassignSelectedTeamsRuns,
    areSelectedTeamsRuns,
    handleTeamClicked,
    handleGroupIconClicked,
    handleInformationRunModalOpen,
    handleGlobalView,
    useOnClickAddMenuOutside,
    handlePdfGeneration,
    showRunObjectColors,
    handleOpenCompetitionModal,
    handleDisabledButtons,
}) => {
    const onInfoClicked = (run) => {
        dispatch({
            type: ACTIONS.SET_INFO_CARD_CLICKED,
            payload: {
                run: run,
                seriesId: run.series ? run.series.id : 0,
            },
        })
    }

    const atLeastOneTeamRunSelected = () => {
        return (
            planning.selectedTeam &&
            planning.selectedTeam.runs.some((r) => r.selected)
        )
    }

    const atLeastTwoTeamRunsSelected = () => {
        return (
            planning.selectedTeam &&
            planning.selectedTeam.runs.filter((run) => run.selected).length >= 2
        )
    }

    const atLeastOneUnassignedRunSelected = () => {
        let someAreSelected = false
        planning.unassignedRuns.map((runs) => {
            runs.map((run) => {
                if (run.selected) {
                    someAreSelected = true
                }
            })
        })
        return someAreSelected
    }

    const openTeamsList = () => {
        dispatch({ type: ACTIONS.OPEN_TEAMS_LIST, payload: true })
    }

    return (
        <div>
            <div className="flex flex-col space-y-8">
                <div className="flex flex-row items-center justify-center pl-6 pr-8 pt-10">
                    <div className="">
                        <SecondaryButton
                            icon={<NavArrowLeft />}
                            action={handleClearTeamAndUnassignedRun}
                            label="Retour"
                        />
                    </div>
                    <div className="flex w-full flex-row justify-end space-x-4 px-3 font-bold">
                        <SecondaryButton
                            icon={<PerspectiveView />}
                            action={handleGlobalView}
                            label="Vue globale"
                        />
                        <DownloadButton
                            action={handlePdfGeneration}
                            loading={planning.downloadButtonLoading}
                        />
                    </div>
                </div>
                {planning.selectedTeam ? (
                    <div className="flex flex-col space-y-4 pb-20 lg:pb-0">
                        <div className="mx-4 flex flex-row items-center justify-between pl-2 pr-10">
                            <div className="flex items-center">
                                <User />
                                <span className="px-1 font-bold">
                                    {planning.selectedTeam.names}
                                </span>
                                <span className="hidden md:flex">
                                    ({planning.selectedTeam.vehicle})
                                </span>
                            </div>
                            <div className="">
                                {totalRunsTeam(planning.selectedTeam)}{' '}
                                transports
                            </div>
                        </div>
                        <RunsList
                            planning={planning}
                            totalRunsTeam={totalRunsTeam}
                            onInfoClicked={onInfoClicked}
                            dispatch={dispatch}
                            showRunObjectColors={showRunObjectColors}
                        />
                    </div>
                ) : (
                    <UnassignedRunsList
                        planning={planning}
                        totalRunsTeam={totalRunsTeam}
                        onInfoClicked={onInfoClicked}
                        dispatch={dispatch}
                        showRunObjectColors={showRunObjectColors}
                    />
                )}
                <div className="absolute bottom-0 flex w-full flex-row items-center justify-center space-x-4 pb-10 text-5xl lg:right-0 lg:justify-end lg:pr-20 lg:text-3xl">
                    {atLeastTwoTeamRunsSelected() && (
                        <div
                            className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-light-dark-green text-white hover:scale-110 hover:bg-dark-green lg:h-16 lg:w-16"
                            onClick={() =>
                                handleGroupIconClicked(planning.selectedTeam)
                            }
                        >
                            <i className="fas fa-layer-group" />
                        </div>
                    )}
                    {(atLeastOneTeamRunSelected() ||
                        atLeastOneUnassignedRunSelected()) && (
                        <div
                            className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-light-dark-green text-white hover:scale-110 hover:bg-dark-green lg:h-16 lg:w-16"
                            onClick={openTeamsList}
                        >
                            <Plus />
                        </div>
                    )}

                    {atLeastOneTeamRunSelected() && (
                        <div
                            className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-light-dark-green text-white hover:scale-110 hover:bg-dark-green lg:h-16 lg:w-16"
                            onClick={() =>
                                unassignSelectedTeamsRuns(planning.selectedTeam)
                            }
                        >
                            <Minus />
                        </div>
                    )}
                </div>
            </div>
            <div>
                {planning.informationRunModalOpen && (
                    <RunModalContainer
                        setModalOpen={handleInformationRunModalOpen}
                        run={planning.clickedRun}
                        masterRunsList={planning.teams.filter(
                            (team) => !team.isPartner
                        )}
                        statusList={planning.statusList}
                        reRender={reRender}
                        setOpenRunManageModal={handleOpenRunManageModal}
                        planning={planning}
                        setOpenCompetitionModal={handleOpenCompetitionModal}
                        setDisabledButtons={handleDisabledButtons}
                        disabledButtons={planning.disabledButtons}
                    />
                )}
                {planning.openTeamsList && (
                    <SelectPlanningTeamsModal
                        planning={planning}
                        dispatch={dispatch}
                        handleTeamClicked={handleTeamClicked}
                        totalRunsTeam={totalRunsTeam}
                    />
                )}
            </div>
        </div>
    )
}

export default RunsContainer
