import React from 'react'

const ClassicSwitchV2 = ({
    id,
    label,
    setValue,
    value,
    info,
    infoLabel,
    disabled,
    labelUppercase = true,
}) => {
    return (
        <div className="grid grid-cols-1">
            <label
                className={`col-span-1 mb-2 font-semibold text-gray-700 ${
                    labelUppercase
                        ? ' text-sm uppercase lg:text-xs'
                        : 'text-base'
                }`}
                htmlFor={id}
            >
                {label}
            </label>
            <div className="col-span-1 flex items-center ">
                {info && (
                    <i
                        className="far fa-question-circle fa-lg cursor-pointer pr-2"
                        title={infoLabel}
                    />
                )}

                <div
                    className={`flex rounded-full border ${
                        disabled ? 'cursor-auto' : 'cursor-pointer'
                    }  w-20 lg:w-12 ${
                        value
                            ? 'justify-end border-primary-color bg-primary-color'
                            : 'border-grey justify-start bg-gray-400'
                    }`}
                    onClick={setValue}
                >
                    <span className="shadow-inner h-8 w-8 rounded-full bg-white shadow lg:h-6 lg:w-6"></span>
                </div>
            </div>
        </div>
    )
}

export default ClassicSwitchV2
