import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import gtag from 'ga-gtag'
import Success from '../../Commons/Success'

const SignupSuccess = () => {
    let history = useNavigate()

    useEffect(() => {
        gtag('event', 'conversion', {
            send_to: 'AW-10784756791/ZTu2CIDx9aYDELeoyZYo',
            page_title: 'Abonnement pro',
        })
    }, [])

    const goToApp = () => {
        history('/transport')
    }

    return <Success goTo={goToApp} buttonLabel="Accéder à l'applicaton" />
}

export default SignupSuccess
