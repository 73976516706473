import React from 'react'
import ManageDashboardContainer from '../App/Series/Manage/ManageDashboardContainer'

const ManageSeriesModal = ({
    dateFrom,
    setOpenSeriesManageModal,
    setOpenNewSeriesManageModal,
    reRender,
    seriesId,
}) => {
    return (
        <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-600 bg-opacity-50 fullscreen-dashboard-planning px-2 md:px-6 lg:px-12">
            <div className="flex flex-col bg-color-light-gray rounded-lg min-h-action-modal items-center max-height-90 overflow-y-scroll min-w-full">
                <ManageDashboardContainer
                    key={1}
                    date={dateFrom}
                    setOpenSeriesManageModal={setOpenSeriesManageModal}
                    setOpenNewSeriesManageModal={setOpenNewSeriesManageModal}
                    reRender={reRender}
                    seriesId={seriesId}
                />
            </div>
        </div>
    )
}

export default ManageSeriesModal
