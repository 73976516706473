import { ReactComponent as WhatsApp } from '../files/whatsapp.svg'
import { BellOff, Mail, Message, Lock } from 'iconoir-react'
import { ReactComponent as Logo } from '../files/logo_heroad.svg'

const isPremium = localStorage.getItem('premium') === 'false'

export const SharingOptions = [
    {
        value: 'mail',
        icon: <Mail width="19px" />,
        label: 'Mail',
        ['@id']: '/sharing_options/mail',
    },
    {
        value: 'SMS',
        icon: isPremium ? <Lock width="19px" /> : <Message width="19px" />,
        label: 'SMS',
        isDisabled: isPremium,
        ['@id']: '/sharing_options/SMS',
    },
    {
        value: 'whatsapp',
        icon: isPremium ? <Lock width="19px" /> : <WhatsApp />,
        label: 'WhatsApp',
        isDisabled: isPremium,
        ['@id']: '/sharing_options/whatsapp',
    },
    {
        value: 'empty',
        icon: <BellOff width="19px" />,
        label: 'Ne pas notifier',
        ['@id']: '/sharing_options/empty',
    },
    {
        value: 'heroad',
        icon: <Logo width="19px" height="19px" />,
        label: 'Heroad',
        ['@id']: '/sharing_options/heroad',
    },
]

export const SharingOptionsByType = {
    mail: SharingOptions[0],
    sms: SharingOptions[1],
    whatsapp: SharingOptions[2],
    empty: SharingOptions[3],
    heroad: SharingOptions[4],
}

export const SharingOptionsFilters = [
    {
        value: 1,
        label: 'Transports partagés uniquement',
        filtre: 'exists[partner]=true',
    },
    {
        value: 2,
        label: 'Transports donnés uniquement',
        filtre: 'isGiven=true',
    },
    {
        value: 3,
        label: 'Transports reçus uniquement',
        filtre: 'isGiven=false',
    },
]

export default SharingOptions
