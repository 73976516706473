import React, { useState } from 'react'
import Dashboard from './components/App/Dashboard'
import Login from './components/Auth/SignInContainer'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import * as dayjs from 'dayjs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/fr'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'
import 'react-date-range/dist/styles.css' // main css file
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-range/dist/theme/default.css'
import Registration from './components/Auth/Registration'
import PartnerAccessContainer from './components/PartnerAccess/PartnerAccessContainer'
import SignupContainer from './components/Auth/Registration/SignupContainer'
import SalesContainer from './components/App/Sales/SalesContainer'
import Notification from './components/App/Notification'
import { WebsocketProvider, WsConsumer } from './context/WebsocketContext'
import { toast, ToastContainer } from 'react-toastify'
import { ParametersProvider } from './context/ParametersContext'

dayjs.locale('fr')
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)
dayjs.extend(weekday)

const queryClient = new QueryClient()
const App = () => {
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState(null)
    const [socketNotification, setSocketNotification] = useState(false)

    const popUp = (text) => {
        toast.success(text)
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ParametersProvider>
                {loading ? (
                    <div
                        className={`fixed left-0 top-0 z-0 block h-full w-full bg-white opacity-75 ${loading}`}
                    >
                        <span className="top relative top-1/2 mx-auto my-0 block h-0 w-0 text-light-dark-green">
                            <span className="generation text-5xl font-bold">
                                Chargement
                            </span>
                            <i className="fas fa-circle-notch fa-spin fa-5x" />
                        </span>
                    </div>
                ) : (
                    <Routes>
                        <Route
                            path="*"
                            element={
                                <WebsocketProvider>
                                    <WsConsumer
                                        socketNotification={socketNotification}
                                        setText={setText}
                                        setSocketNotification={
                                            setSocketNotification
                                        }
                                        popUp={popUp}
                                    />
                                    <Dashboard
                                        socketNotification={socketNotification}
                                        setSocketNotification={
                                            setSocketNotification
                                        }
                                    />
                                </WebsocketProvider>
                            }
                        />
                        <Route path="/" element={<Login />} />
                        <Route
                            path="/registration/:token"
                            element={<Registration />}
                        />
                        <Route
                            path="/inscription/*"
                            element={<SignupContainer />}
                        />
                        <Route path="/ventes" element={<SalesContainer />} />
                        <Route
                            path="/private/:token/courses/*"
                            element={<PartnerAccessContainer />}
                        />
                    </Routes>
                )}
                <Notification />
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    className={
                        'text-2xl font-semibold sm:text-lg sm:font-normal'
                    }
                    // bodyClassName={() => 'text-xl font-white font-med block p-3'}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <ReactQueryDevtools initialIsOpen={false} />
            </ParametersProvider>
        </QueryClientProvider>
    )
}

export default App
