import {
    getTransportInitialState,
    TransportFormReducer,
} from './TransportFormReducer'
import {
    getPatientFormInitialState,
    PatientFormReducer,
} from './PatientFormReducer'
import { getModalInitialState, ModalFormReducer } from './ModalReducer'
import {
    getPecInitialState,
    PecReducer,
    PecTransportType,
} from '../../../../services/Reducers/PecReducer'
import {
    getPrescriptionFormInitialState,
    PrescriptionFormReducer,
} from './PrescriptionFormReducer'
import {
    getPayingCenterInitialState,
    PayingCenterFormReducer,
} from './PayingCenterFormReducer'
import {
    getPractitionerInitialState,
    PractitionerFormReducer,
} from './PractitionerFormReducer'
import {
    getInvoiceFormInitialState,
    InvoiceCpamReducer,
} from './InvoiceCpamReducer'

export const getGlobalInitialState = ({ date }) => {
    const vehicleType = PecTransportType[1]
    return {
        patientForm: getPatientFormInitialState({ undefined }),
        transportForm: getTransportInitialState({ date }),
        modalReducer: getModalInitialState(), // ACTION MODAL
        prescriptionForm: getPrescriptionFormInitialState({ undefined }),
        payingCenterForm: getPayingCenterInitialState({ undefined }),
        practitionerForm: getPractitionerInitialState({ undefined }),
        pecReducer: getPecInitialState({ vehicleType }),
        invoiceCpamForm: getInvoiceFormInitialState({ undefined }),
    }
}

export function GlobalFormReducer(state, action) {
    return {
        transportForm: TransportFormReducer(state.transportForm, action),
        patientForm: PatientFormReducer(state.patientForm, action),
        modalReducer: ModalFormReducer(state.modalReducer, action),
        pecReducer: PecReducer(state.pecReducer, action),
        payingCenterForm: PayingCenterFormReducer(
            state.payingCenterForm,
            action
        ),
        practitionerForm: PractitionerFormReducer(
            state.practitionerForm,
            action
        ),
        prescriptionForm: PrescriptionFormReducer(
            state.prescriptionForm,
            action
        ),
        invoiceCpamForm: InvoiceCpamReducer(state.invoiceCpamForm, action),
    }
}
