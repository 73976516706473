import React, { useEffect, useState } from 'react'
import API from '../../../../services/api'
import Loader from '../../../Commons/Loaders/Loader'
import * as dayjs from 'dayjs'
import { Route, Routes, useNavigate } from 'react-router-dom'
import SubscriptionPlans from './SubscriptionPlans'
import SubscriptionData from './SubscriptionData'
import SubscriptionRecap from './SubscriptionRecap'
import Alert from '../../../Commons/Alert'
import SubscriptionCancel from './SubscriptionCancel'
import SubscriptionSuccess from './SubscriptionSuccess'

const SubscriptionContainer = () => {
    const [society, setSociety] = useState(null)
    const [loading, setLoading] = useState(true)
    const [freeTryDaysLeft, setFreeTryDaysLeft] = useState(0)
    const [plans, setPlans] = useState([])
    const [plan, setPlan] = useState(null)
    const [acceptedUsedTerms, setAcceptedUsedTerms] = useState(false)
    const [error, setError] = useState(false)

    const nbSmsOptions = [
        { value: 50, label: '50 sms', id: '50' },
        { value: 100, label: '100 sms', id: '100' },
        { value: 200, label: '200 sms', id: '200' },
    ]

    const [nbSmsValue, setNbSmsValue] = useState(nbSmsOptions[0])

    let history = useNavigate()
    const getSociety = () => {
        const society = localStorage.getItem('society')
            ? localStorage.getItem('society')
            : null
        if (society) {
            API.Society.get(society.replace(/\D/g, '')).then((response) => {
                response.json().then((data) => {
                    setSociety(data)
                    if (data.plan.id === 'free') {
                        const daysDiff = dayjs().diff(
                            dayjs(data.lastUpdatedPlanAt),
                            'days'
                        )

                        const daysLeft = 14 - daysDiff

                        setFreeTryDaysLeft(daysLeft)
                    } else {
                        setLoading(false)
                    }
                    getPlans()
                })
            })
        }
    }

    useEffect(() => {
        getSociety()
    }, [])

    const getPlans = () => {
        API.Plans.get().then((response) => {
            response.json().then((data) => {
                setPlans(data['hydra:member'])
                setLoading(false)
            })
        })
    }

    const handlePlan = (plan) => {
        setPlan(plan)
        history('/parametres/abonnement/plan/recapitulatif')
    }

    const handleSmsPlan = () => {
        setPlan(plans.find((p) => p.id === nbSmsValue.id))
        history('/parametres/abonnement/credit/recapitulatif')
    }

    const checkAcceptedUsedTerms = () => {
        if (!acceptedUsedTerms) {
            setError(
                "Veuillez acceptez nos conditions générale de vente et d'utilisation"
            )
            setLoading(false)
            return false
        }
        return true
    }

    const goToStripe = (subscription) => {
        setLoading(true)
        if (checkAcceptedUsedTerms()) {
            const data = {
                priceId: plan.stripePriceId,
                userId: localStorage.getItem('id'),
                societyId: localStorage.getItem('society').replace(/\D/g, ''),
                fromApp: true,
                planIri: plan['@id'],
            }
            API.Stripe.CreateCheckout(data, subscription).then((response) =>
                response.json().then((data) => {
                    window.location.href = data.session.url
                })
            )
        }
    }

    const clearError = () => {
        setError(null)
    }

    return (
        <div className="px-6 pt-6 text-lg lg:text-base">
            {loading ? (
                <Loader />
            ) : (
                <div>
                    {error && (
                        <div className="w-full p-4 lg:pl-20  ">
                            <Alert
                                title="Erreur"
                                text={error}
                                clearError={clearError}
                            />
                        </div>
                    )}
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <SubscriptionData
                                    freeTryDaysLeft={freeTryDaysLeft}
                                    society={society}
                                    handleSmsPlan={handleSmsPlan}
                                    nbSmsOptions={nbSmsOptions}
                                    nbSmsValue={nbSmsValue}
                                    setNbSmsValue={setNbSmsValue}
                                />
                            }
                        />
                        <Route
                            path="/plans/*"
                            element={
                                <SubscriptionPlans
                                    handlePlan={handlePlan}
                                    plans={plans}
                                />
                            }
                        />
                        <Route
                            path="/plan/recapitulatif"
                            element={
                                <SubscriptionRecap
                                    plan={plan}
                                    acceptedUsedTerms={acceptedUsedTerms}
                                    setAcceptedUsedTerms={setAcceptedUsedTerms}
                                    goToStripe={() => goToStripe(true)}
                                    isCreditSms={false}
                                />
                            }
                        />
                        <Route
                            exact
                            path="/credit/recapitulatif"
                            element={
                                <SubscriptionRecap
                                    plan={plan}
                                    acceptedUsedTerms={acceptedUsedTerms}
                                    setAcceptedUsedTerms={setAcceptedUsedTerms}
                                    goToStripe={() => goToStripe(false)}
                                    isCreditSms={true}
                                />
                            }
                        />
                        <Route
                            path="/paiement/cancel"
                            element={<SubscriptionCancel />}
                        />
                        <Route
                            path="/paiement/success"
                            element={<SubscriptionSuccess />}
                        />
                    </Routes>
                </div>
            )}
        </div>
    )
}

export default SubscriptionContainer
