import VehicleTypes from './VehicleTypes'
import API from './api'
import { ACTIONS } from './Reducers/WelcomeModalReducer'
export const formatOptions = (list) => {
    const options = []
    if (list === undefined || list === null) {
        list = VehicleTypes
    }
    list.forEach((option) => {
        const data = {
            value: option.label,
            label: option.label,
            ['@id']: `/types/${option.label}`,
        }

        options.push(data)
    })
    return options
}

export const getParameters = (dispatch) => {
    API.Parameter.get(localStorage.getItem('society')).then((response) => {
        response.json().then((data) => {
            const parameters = data
            parameters.defaultVehicleType = {
                label: data.defaultVehicleType.label,
                value: data.defaultVehicleType.label,
                ['@id']: data.defaultVehicleType['@id'],
            }

            if (parameters.defaultVehiclesTypes) {
                parameters.defaultVehiclesTypes = formatOptions(
                    parameters.defaultVehiclesTypes
                )
                parameters.vehicleOptions = VehicleTypes.filter(function (
                    type
                ) {
                    return !parameters.defaultVehiclesTypes.some(function (
                        type2
                    ) {
                        return type['@id'] === type2['@id']
                    })
                })
            } else {
                parameters.defaultVehiclesTypes = VehicleTypes
            }

            dispatch({
                type: ACTIONS.SET_PARAMETERS,
                payload: parameters,
            })
        })
    })
}
