import classNames from 'classnames'
import React from 'react'

const VerticalDashedLine = ({ dashboard, timesIntervalSizeStyle }) => {
    return (
        <>
            {dashboard.times.map((time, index) => {
                return (
                    <div
                        key={index}
                        style={timesIntervalSizeStyle()}
                        className="relative"
                    >
                        <div className="vertical-dashed-line absolute" />
                        <div
                            className={classNames(
                                'h-800vh ml-1-4rem absolute',
                                {
                                    'linear-dashboard-gradient':
                                        index % 2 === 0,
                                    'linear-dashboard-gradient-bis':
                                        index % 2 === 1,
                                }
                            )}
                            style={timesIntervalSizeStyle()}
                        />
                    </div>
                )
            })}
            ,
        </>
    )
}

export default VerticalDashedLine
