import { apiClient, withErrorHandling } from '../apiClient'
import { formatHydraData } from '../../api'
import { formatNameForSelect } from '../../../tools/Utility'
import { concat } from '../pathsAndFilters'

const PATH_KEY = '/partners'

export const Partner = {
    fetchAll: async (pagination, itemsPerPage, search) => {
        let path = PATH_KEY
        if (typeof pagination === 'boolean')
            path = concat(path, 'pagination=' + pagination)
        if (itemsPerPage) path = concat(path, 'itemsPerPage=' + itemsPerPage)
        if (search) path = concat(path, 'search=' + search)
        return formatHydraData(await apiClient.get(path))
    },
    fetchAllForDebounceSearch: async (search) => {
        const partners = await Partner.fetchAll(true, 5, search)
        return partners?.map((partner) => formatNameForSelect(partner))
    },
    delete: withErrorHandling(async (id) => {
        return await apiClient.delete(`${PATH_KEY}/${id}`)
    }),
    create: withErrorHandling(async (partner) => {
        return await apiClient.post(PATH_KEY, partner)
    }),
    update: withErrorHandling(async (partner) => {
        return await apiClient.put(`${PATH_KEY}/${partner.id}`, partner)
    }),
    fetchOne: async (id) => {
        return formatHydraData(await apiClient.get(`${PATH_KEY}/${id}`))
    },
}
