import React, { useEffect } from 'react'
import RunCard from './Invoice/RunCard'
import { INVOICE_CPAM_ACTIONS } from '../Reducers/InvoiceCpamReducer'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import {
    formatPriceBasis,
    formatSpreadObjectOptions,
} from '../../../../tools/Utility'
import PriceBasisPanel from './Invoice/PriceBasisPanel'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { handleResetInvoiceFormHolder } from '../../../../services/InvoiceUtils'
import {
    formatTimeLocal,
    removeTimeZoneToDate,
} from '../../../../services/dateUtils'

const InvoiceCpamModalForm = ({
    priceBases,
    allowedToChangePriceBasis,
    dispatch,
    invoiceCpamForm,
    runs,
    ACTION = INVOICE_CPAM_ACTIONS,
}) => {
    const [reload, setReload] = React.useState(true)
    const isSimpleRun = runs.length === 1
    const sortedRuns = runs.sort(function (a, b) {
        return (
            new Date(a.dateString) - new Date(b.dateString) ||
            new Date(removeTimeZoneToDate(a.departureTime)) -
                new Date(removeTimeZoneToDate(b.departureTime))
        )
    })

    const getRunInvoiceDetailsByRunId = (runId) => {
        return invoiceCpamForm.runsInvoiceFormsDetailsHolder.find(
            (runInvoiceDetail) => runInvoiceDetail.runId === runId
        )
    }

    const handleChangePriceBasis = (e) => {
        dispatch({
            type: ACTION.UPDATE_FORM_PRICE_BASIS,
            payload: e,
        })
        handleReset(e)
    }

    const handleReset = (priceBasisForm = null) => {
        // reset form initial value -> invoiceCpamForm.runInvoiceDetails
        if (priceBasisForm) {
            invoiceCpamForm.priceBasisForm = priceBasisForm
        }

        dispatch({
            type: ACTION.UPDATE_ALL_RUNS_INVOICE_DETAILS,
            payload: invoiceCpamForm.runsInvoiceFormsDetails,
        })

        const newForfaitAndSupplement = handleResetInvoiceFormHolder(
            invoiceCpamForm,
            invoiceCpamForm.priceBasisForm,
            runs
        )

        dispatch({
            type: ACTION.UPDATE_FORM_HOLDER,
            payload: newForfaitAndSupplement,
        })
        dispatch({
            type: ACTION.UPDATE_FORM_PRICE_BASIS,
            payload: invoiceCpamForm.priceBasisForm,
        })
        setReload(!reload)
    }

    useEffect(() => {
        if (!reload)
            setTimeout(() => {
                setReload(true)
            }, 100)
    }, [reload])

    return (
        <div className={'flex w-full flex-col px-4'}>
            <div className={'pt-4'}>
                <div
                    className={
                        'flex w-full flex-col justify-between space-y-2 sm:flex-row sm:items-center sm:space-y-0'
                    }
                >
                    {allowedToChangePriceBasis && (
                        <div className={'w-1/2'}>
                            <SelectDropDownListV2
                                label={'tarif'}
                                options={formatSpreadObjectOptions(priceBases)}
                                value={formatPriceBasis(
                                    invoiceCpamForm.priceBasisForm
                                )}
                                handleOptionChange={handleChangePriceBasis}
                            />
                        </div>
                    )}
                    <div className={'mt-auto'}>
                        <SecondaryButton
                            label={
                                'Réinitialiser le calcul + forfait/supplément'
                            }
                            action={() => {
                                handleReset()
                            }}
                        />
                    </div>
                </div>
                <PriceBasisPanel priceBasis={invoiceCpamForm.priceBasisForm} />
            </div>
            {reload ? (
                <div className="flex w-full flex-col space-y-4 py-10 sm:px-5">
                    {sortedRuns.map((run, index) => (
                        <RunCard
                            run={run}
                            runInvoiceDetails={getRunInvoiceDetailsByRunId(
                                run.id
                            )}
                            index={index}
                            dispatch={dispatch}
                            isSimpleRun={isSimpleRun}
                            ACTION={ACTION.UPDATE_FORM_RUN_INVOICE_DETAILS}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default InvoiceCpamModalForm
