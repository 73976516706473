import Loader from '../../../Commons/Loaders/Loader'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { MailIn, SendDiagonal } from 'iconoir-react'
import { ReactComponent as Cpam } from './../../../../files/cpam.svg'
import { LotStatus } from '../../../../services/InvoiceStatus'
import { formatEuro } from '../Manage'

const NoemieListComponent = ({ noemies, loading, totalItems }) => {
    let history = useNavigate()
    const historyPush = (id) => {
        history('/facturation/noemie/' + id)
    }

    const findNoemiesBorderByStatusType = (status) => {
        const found = LotStatus.find((item) => item.value === status)
        return found ? found.border : 'border-l-8 border-gray-200' // Retourne 'bg-gray-200' comme couleur par défaut si le tarifType n'est pas trouvé
    }

    const noemiesGroupedByPayingCenter = noemies.reduce((groups, noemie) => {
        const label = noemie.cpamInvoiceGroups[0].payingCenter.label
        if (!groups[label]) {
            groups[label] = []
        }
        groups[label].push(noemie)
        return groups
    }, {})

    const calculateTotalCpamInvoiceGroupsAmount = (cpamInvoiceGroups) => {
        return cpamInvoiceGroups.reduce((acc, group) => {
            return acc + group.totalInvoiceAmount
        }, 0)
    }

    return (
        <div className="px-2 xl:pt-5">
            <div className="flex py-2">
                <div className="ml-auto text-xs font-bold">
                    {totalItems} Retours noemie
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                Object.entries(noemiesGroupedByPayingCenter).map(
                    ([payingCenterLabel, noemies]) => (
                        <div key={payingCenterLabel}>
                            <h2 className={'py-2 text-xl'}>
                                {payingCenterLabel}
                            </h2>
                            <div className="flex w-full flex-col space-y-2 lg:space-y-0 ">
                                {noemies.map((noemie) => (
                                    <div
                                        className="parents relative flex w-full pb-2"
                                        key={noemie.id}
                                        onClick={() => historyPush(noemie.id)}
                                    >
                                        <div
                                            className={`grid w-full  ${findNoemiesBorderByStatusType(
                                                noemie.status
                                            )}
                                cursor-pointer select-none rounded bg-white shadow-mobile lg:select-auto`}
                                        >
                                            <div
                                                className={`parents row-span-1 flex grid-rows-1  flex-wrap items-center justify-between px-2 py-2  text-sm lg:text-base`}
                                            >
                                                <div className="flex  w-1/2 truncate sm:w-1/5 ">
                                                    <SendDiagonal
                                                        className={'mr-2'}
                                                    />
                                                    {dayjs(
                                                        noemie.createdAt
                                                    ).format('DD/MM/YYYY')}
                                                </div>
                                                <div className="flex w-1/2 items-center truncate sm:w-1/5 ">
                                                    <Cpam
                                                        width={24}
                                                        className={'mr-2'}
                                                    />
                                                    {
                                                        noemie
                                                            .cpamInvoiceGroups[0]
                                                            .payingCenter.label
                                                    }
                                                </div>
                                                <div className="w-1/2 truncate font-bold sm:w-1/5">
                                                    <span
                                                        className={
                                                            'font-semibold'
                                                        }
                                                    >
                                                        {formatEuro(
                                                            calculateTotalCpamInvoiceGroupsAmount(
                                                                noemie.cpamInvoiceGroups
                                                            )
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="w-1/2 truncate font-bold sm:w-1/5">
                                                    {/* show all cpamInvoiceGroups id max 3*/}
                                                    {noemie.cpamInvoiceGroups
                                                        .slice(0, 3)
                                                        .map(
                                                            (
                                                                cpamInvoiceGroup
                                                            ) => (
                                                                <div
                                                                    key={
                                                                        cpamInvoiceGroup.groupId
                                                                    }
                                                                >
                                                                    {
                                                                        cpamInvoiceGroup.groupId
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                                <div className="flex w-1/2 truncate sm:w-1/5 ">
                                                    {noemie.noemieReceivedAt && (
                                                        <>
                                                            <MailIn
                                                                className={
                                                                    'mr-2'
                                                                }
                                                            />
                                                            {dayjs(
                                                                noemie.noemieReceivedAt
                                                            ).format(
                                                                'DD/MM/YYYY'
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )
                )
            )}
        </div>
    )
}

export default NoemieListComponent
