import { SERIES_ACTIONS } from '../../../../services/Reducers/SeriesReducer'
import TimeField from 'react-simple-timefield'
import dayjs from 'dayjs'
import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import RunTypes from '../../../../services/RunTypes'
import WaitingTimeInput from '../../../Commons/Inputs/WaitingTimeInput'
import React, { useEffect } from 'react'
import { ArrowRight } from 'iconoir-react'
import {
    formatTimeLocal,
    formatTimeInput,
    handleTimeToDate,
} from '../../../../services/dateUtils'

const TimesAndType = ({ series, dispatch, day, index }) => {
    const handleDayDepartureTime = (value) => {
        dispatch({
            type: SERIES_ACTIONS.SET_DAY_DEPARTURE_TIME,
            payload: {
                day: day.day,
                departureTime: handleTimeToDate(value),
            },
        })
    }
    const handleDayArrivingTime = (value) => {
        dispatch({
            type: SERIES_ACTIONS.SET_DAY_ARRIVING_TIME,
            payload: {
                day: day.day,
                arrivingTime: handleTimeToDate(value),
            },
        })
    }
    const handleDayReturnTime = (value) => {
        dispatch({
            type: SERIES_ACTIONS.SET_DAY_RETURN_TIME,
            payload: {
                day: day.day,
                returnTime: handleTimeToDate(value),
            },
        })
    }

    return (
        <div
            className={`min-h-row-date flex flex-wrap items-center space-x-2 space-y-5 lg:justify-between lg:space-x-10 lg:space-y-1`}
        >
            <div className="flex w-full justify-center sm:w-1/2 lg:w-1/6">
                <SelectDropDownListV2
                    options={series.daysOptions}
                    value={series.daysDefaultList.find(
                        (d) => d.value === day.day
                    )}
                    isClearable={false}
                    handleOptionChange={(e) =>
                        dispatch({
                            type: SERIES_ACTIONS.SET_DAY,
                            payload: {
                                oldDay: day.day,
                                newDay: e.value,
                            },
                        })
                    }
                    showLabel={true}
                    error={null}
                    isSearchable={false}
                    isDisabled={index === 0}
                />
            </div>
            <div className="flex">
                <div className="inline-block w-1/2 text-center">
                    <label className="block text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                        Départ
                    </label>
                    <TimeField
                        value={formatTimeInput(day.departureTime)}
                        onChange={(e) => handleDayDepartureTime(e.target.value)}
                        input={
                            <input
                                className={`manage-max-w-hours h-10 w-full border-b-2 text-center leading-tight focus:outline-none ${
                                    day.departureTimeError
                                        ? 'border-red-500'
                                        : 'border-gray-300 focus:border-lighter-blue-green'
                                }
                                               `}
                            />
                        }
                        colon=":"
                    />
                </div>
                <div className="flex items-center justify-center px-3 pt-3 ">
                    <ArrowRight />
                </div>
                <div className="inline-block w-1/2 text-center">
                    <label className="block text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                        Arrivée
                    </label>
                    <TimeField
                        value={formatTimeInput(day.arrivingTime)}
                        onChange={(e) => handleDayArrivingTime(e.target.value)}
                        input={
                            <input
                                className={`manage-max-w-hours h-10 w-full border-b-2 text-center leading-tight focus:outline-none ${
                                    day.arrivingTimeError
                                        ? 'border-red-500'
                                        : 'border-gray-300 focus:border-lighter-blue-green'
                                }
                                               `}
                            />
                        }
                        colon=":"
                    />
                </div>
            </div>
            <div className="min-w-select-types flex w-full lg:w-auto">
                <SelectDropDownListV2
                    options={RunTypes}
                    value={day.runType}
                    label="Type de transport"
                    handleOptionChange={(e) =>
                        dispatch({
                            type: SERIES_ACTIONS.SET_DAY_RUN_TYPE,
                            payload: {
                                day: day.day,
                                runType: e,
                            },
                        })
                    }
                    isClearable={false}
                    isSearchable={false}
                />
            </div>
            {day.runType.value === 4 && (
                <div className="inline-block w-full text-center lg:w-auto">
                    <label className="block text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                        Retour
                    </label>
                    <TimeField
                        value={formatTimeInput(day.returnTime)}
                        onChange={(e) => handleDayReturnTime(e.target.value)}
                        input={
                            <input
                                className={`manage-max-w-hours h-10 w-full border-b-2 text-center leading-tight focus:outline-none ${
                                    day.returnTimeError
                                        ? 'border-red-500'
                                        : 'border-gray-300 focus:border-lighter-blue-green'
                                }
                                               `}
                            />
                        }
                        colon=":"
                    />
                </div>
            )}
            {day.runType.value === 3 && (
                <div className="inline-block w-full text-center lg:w-auto">
                    <WaitingTimeInput
                        label="Temps d'attente"
                        value={day.waitingTime}
                        type="number"
                        required={true}
                        setValue={(e) =>
                            dispatch({
                                type: SERIES_ACTIONS.SET_DAY_WAITING_TIME,
                                payload: {
                                    day: day.day,
                                    waitingTime: parseInt(e.target.value),
                                },
                            })
                        }
                        placeholder="20"
                        id="waitingtime"
                        showLabel={true}
                        min={0}
                    />
                </div>
            )}
        </div>
    )
}

export default TimesAndType
