import React, { useEffect, useState } from 'react'
import { Erase, Settings } from 'iconoir-react'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { ACTIONS } from '../../../services/Reducers/PlanningReducer'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import ClassicSwitchV2 from '../../Commons/Inputs/ClassicSwitchV2'

const ConfigPlanningModal = ({ dispatch, planning }) => {
    const [config, setConfig] = useState({
        showEffectiveHours: false,
        showPartners: false,
        teams: [],
        displayVehicleColor: false,
    })

    useEffect(() => {
        setConfig({
            showEffectiveHours: planning.showEffectiveHours,
            isPatientFilter: planning.isPatientFilterList.find(
                (val) => val.value === planning.isPatientFilter.value
            ),
            showPartners: planning.showPartners,
            displayVehicleColor: planning.displayVehicleColor,
        })
    }, [planning])

    const handleCloseFilterPlanningModal = () => {
        dispatch({ type: ACTIONS.SET_OPEN_CONFIG_MODAL, payload: false })
    }

    const handlePatientFilterChange = (e) => {
        setConfig({ ...config, isPatientFilter: e })
    }

    const handleConfig = () => {
        dispatch({ type: ACTIONS.SET_CONFIG, payload: config })
    }

    const handleResetConfig = () => {
        dispatch({ type: ACTIONS.RESET_CONFIG })
    }

    return (
        <div className="invisible-scrollbar fullscreen-dashboard-planning fixed left-0 bottom-0 flex h-full w-full overflow-y-scroll bg-gray-600 bg-opacity-50 shadow-md">
            <div className="mx-auto w-full max-w-lg pt-20">
                <div className="min-h-filter-planning-modal flex flex-col rounded-xl bg-white p-5">
                    <div className="mb-6 flex justify-between border-b py-2">
                        <div className="flex items-center space-x-2">
                            <h1 className="items-center text-2xl font-semibold">
                                Configurer
                            </h1>
                            <Settings />
                        </div>
                        <SecondaryButton
                            icon={<Erase />}
                            label="Réinitialiser"
                            title="Réinitialiser"
                            action={handleResetConfig}
                        />
                    </div>
                    <div className=" flex flex-col space-y-4">
                        <SelectDropDownList
                            icon=""
                            options={planning.isPatientFilterList}
                            isSearchable={false}
                            showLabel={true}
                            label="Type de fiches"
                            value={config.isPatientFilter}
                            handleOptionChange={(e) =>
                                handlePatientFilterChange(e)
                            }
                        />
                        <ClassicSwitchV2
                            labelUppercase={false}
                            label="Afficher les partenaires"
                            id="partners"
                            value={config.showPartners}
                            setValue={() => {
                                setConfig({
                                    ...config,
                                    showPartners: !config.showPartners,
                                })
                            }}
                        />
                        <ClassicSwitchV2
                            labelUppercase={false}
                            label="Afficher les horaires effectifs plutôt que les horaires"
                            id="partners"
                            value={config.showEffectiveHours}
                            setValue={() => {
                                setConfig({
                                    ...config,
                                    showEffectiveHours:
                                        !config.showEffectiveHours,
                                })
                            }}
                        />
                        <ClassicSwitchV2
                            labelUppercase={false}
                            label="Afficher la couleur du véhicule sous chaque équipe"
                            id="background-color"
                            value={config.displayVehicleColor}
                            setValue={() => {
                                setConfig({
                                    ...config,
                                    displayVehicleColor:
                                        !config.displayVehicleColor,
                                })
                            }}
                        />
                    </div>
                    <div className="mt-auto flex justify-between border-t pt-5">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            action={handleCloseFilterPlanningModal}
                        />
                        <PrimaryButton
                            label="Valider"
                            title="Valider"
                            action={handleConfig}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfigPlanningModal
