import { formatHydraData } from '../../api'
import { apiClient, withErrorHandling } from '../apiClient'
import { concat } from '../pathsAndFilters'

const PATH_KEY = '/paying_centers'

export const PayingCenters = {
    fetchAll: async () => {
        let path = PATH_KEY + '?pagination=false'
        return formatHydraData(await apiClient.get(path))
    },
    fetchAllList: async () => {
        let path = '/paying_centers_lists'
        path = concat(path, 'pagination=false')
        return formatHydraData(await apiClient.get(path))
    },
    post: async (payingCenter) => {
        return await apiClient.post(PATH_KEY, payingCenter)
    },
    put: withErrorHandling(async (payingCenter) => {
        const { id, address } = payingCenter
        const data = { address: address }
        return await apiClient.put(PATH_KEY + '/' + id, data)
    }),
    fetchOne: async (id) => {
        return formatHydraData(await apiClient.get(PATH_KEY + '/' + id))
    },
}
