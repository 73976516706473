import React from 'react'
import { PATIENT_ACTIONS } from '../../../services/Reducers/PatientReducer'

const FormSwitch = ({
    label,
    onClickLeft,
    onClickRight,
    className,
    classNameLabel,
    infoLabel,
    valueLeft,
    valueRight,
    leftLabel = 'Patient',
    rightLabel = 'Client',
    disabled = false,
}) => {
    return (
        <div
            className={`flex w-full flex-col items-center space-y-2 lg:flex-row lg:space-y-0 ${className}`}
        >
            {label && (
                <div
                    className={`flex w-full items-center justify-between font-semibold lg:w-1/3 ${classNameLabel}`}
                >
                    <label>
                        {label}
                        {infoLabel && (
                            <i
                                className="far fa-question-circle fa-lg cursor-pointer pl-2"
                                title={infoLabel}
                            />
                        )}
                    </label>
                </div>
            )}

            <div className="flex h-16 w-full font-bold lg:h-12 lg:w-auto">
                <button
                    className={`choice rounded-l-lg border-r p-2 ${
                        valueLeft
                            ? 'bg-primary-color font-bold text-white'
                            : 'border bg-white hover:border-lighter-blue-green hover:font-bold'
                    }`}
                    onClick={onClickLeft}
                    disabled={disabled}
                >
                    {leftLabel}
                </button>
                <button
                    className={`choice rounded-r-lg p-2 ${
                        valueRight
                            ? 'bg-primary-color font-bold text-white'
                            : 'border bg-white hover:border-lighter-blue-green hover:font-bold'
                    }`}
                    onClick={onClickRight}
                    disabled={disabled}
                >
                    {rightLabel}
                </button>
            </div>
        </div>
    )
}

export default FormSwitch
