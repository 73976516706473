import React, { useEffect, useReducer } from 'react'
import AutoComplete from '../AutoComplete'
import StyledInput from '../../Commons/StyledInput'
import { ESTABLISHMENT_ACTIONS } from '../../../services/Reducers/EstablishmentReducer'
import {
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../services/Reducers/AddressAutoCompleteReducer'
import StyledTextarea from '../../Commons/StyledTextArea'

const EstablishmentForm = ({ establishmentForm, dispatch }) => {
    const addressItem = establishmentForm.address
    const [address, addressDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { addressItem },
        getAddressInitialState
    )

    useEffect(() => {
        dispatch({
            type: ESTABLISHMENT_ACTIONS.SET_ADDRESS,
            payload: address,
        })
    }, [address])

    return (
        <div className="flex w-full flex-col rounded-xl bg-white px-5 py-10 ">
            <div className="px-5 lg:px-20">
                <div className="grid grid-cols-2 gap-4">
                    <div className="col-span-2">
                        <StyledInput
                            label="Label"
                            value={establishmentForm.label}
                            onChange={(e) =>
                                dispatch({
                                    type: ESTABLISHMENT_ACTIONS.SET_LABEL,
                                    payload: e.target.value,
                                })
                            }
                            id="label"
                            placeholder="Saint-Louis"
                            required={true}
                        />
                    </div>
                    <div className="col-span-1">
                        <StyledInput
                            label="Téléphone"
                            value={establishmentForm.phoneNumber}
                            onChange={(e) =>
                                dispatch({
                                    type: ESTABLISHMENT_ACTIONS.SET_PHONE_NUMBER,
                                    payload: e.target.value,
                                })
                            }
                            id="phoneNumber"
                            placeholder="XXXXXXXXXX"
                        />
                    </div>
                    <div className="col-span-1">
                        <StyledInput
                            label="Email"
                            type="email"
                            value={establishmentForm.mail}
                            onChange={(e) =>
                                dispatch({
                                    type: ESTABLISHMENT_ACTIONS.SET_MAIL,
                                    payload: e.target.value,
                                })
                            }
                            id="email"
                            placeholder="@"
                        />
                    </div>
                    <div className="col-span-2">
                        <StyledTextarea
                            label="Description"
                            value={establishmentForm.description}
                            onChange={(e) =>
                                dispatch({
                                    type: ESTABLISHMENT_ACTIONS.SET_DESCRIPTION,
                                    payload: e.target.value,
                                })
                            }
                            placeholder=""
                        />
                    </div>
                </div>
                <div className="flex w-full flex-col items-center pt-2 lg:flex-row">
                    <AutoComplete
                        address={address}
                        dispatch={addressDispatch}
                        label={'Adresse'}
                        required={true}
                    />
                </div>
            </div>
        </div>
    )
}

export default EstablishmentForm
