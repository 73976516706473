import React from 'react'
import { formatTimeLocal } from '../../../../services/dateUtils'

const Times = ({ row, isComputer }) => {
    return (
        <div
            className={`flex w-1/6 items-center justify-between align-middle ${
                isComputer ? 'px-2 text-lg' : 'pt-2 text-base '
            } ${row.approximateTime && row.isReturnPath && 'italic'}`}
        >
            <div
                className={`flex w-full justify-center ${
                    isComputer ? 'flex-row' : 'flex-col'
                }`}
            >
                <div className="flex justify-center">
                    {formatTimeLocal(row.departureTime)}
                </div>
                <div className={`${isComputer ? 'block' : 'hidden'}`}>-</div>
                <div className="flex justify-center">
                    {formatTimeLocal(row.arrivingTime)}
                </div>
                <div className="flex items-center">
                    {row.userComments && row.isUserCommentsRed ? (
                        <i
                            className={`fas fa-comment-slash pl-2 ${
                                isComputer ? 'block' : 'hidden'
                            }`}
                        />
                    ) : row.userComments && !row.isUserCommentsRed ? (
                        <i
                            className={`far fa-comment pl-2 ${
                                isComputer ? 'block' : 'hidden'
                            }`}
                        />
                    ) : (
                        <span className="w-6 sm:hidden" />
                    )}
                </div>
            </div>
        </div>
    )
}

export default Times
