import React, { useState } from 'react'
import SeriesForm from './Forms/SeriesForm'

const ManageDashboardContainer = ({
    date,
    setOpenSeriesManageModal,
    setOpenNewSeriesManageModal,
    reRender,
    seriesId,
}) => {
    return (
        <div className="w-11/12">
            <SeriesForm
                date={date}
                fromModal={true}
                setOpenSeriesManageModal={setOpenSeriesManageModal}
                setOpenNewSeriesManageModal={setOpenNewSeriesManageModal}
                reRender={reRender}
                seriesId={seriesId}
            />
        </div>
    )
}

export default ManageDashboardContainer
