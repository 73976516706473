import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ActionModal from '../../Modal/ActionModal'
import MobilePagination from '../../Commons/MobilePagination'
import CardPagination from '../../Commons/Pagination/CardPagination'
import { useLongPress } from 'use-long-press'
import TOOLS from '../../../services/tools'
import MobileOption from '../../Commons/MobileOption'
import DefaultOptions from '../../Commons/DefaultOptions'
import Loader from '../../Commons/Loaders/Loader'

const EstablishmentCardTable = ({
    rows,
    setPage,
    page,
    totalItems,
    handleDeleteElement,
    loading,
    lastPage,
}) => {
    let history = useNavigate()
    const location = useLocation()
    const [itemPerPage] = useState(50)
    const [totalPages, setTotalPages] = useState(1)
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [styleId, setStyleId] = useState(null)
    const [label, setLabel] = useState('')
    const [labelAction, setLabelAction] = useState('')
    const [actionButton, setActionButton] = useState('')

    useEffect(() => {
        setTotalPages(Math.ceil(totalItems / itemPerPage))
    }, [totalItems, loading])

    const historyPush = (id) => {
        history(location.pathname + '/' + id)
    }

    const handleAction = () => {
        setModalOpen(false)
        handleDeleteElement(selectedRowId)
    }

    const handleModalDelete = (rowId) => {
        let row = rows.find((r) => r.id === rowId)
        setLabel(
            "Êtes-vous sûr de vouloir supprimer l'établissement " +
                row['label'] +
                ' ?'
        )
        setActionButton('Supprimer')
        setLabelAction('Suppression')
        setModalOpen(true)
    }

    const handlePageClick = ({ selected: selectedPage }) => {
        setPage(selectedPage + 1)
    }

    const optionsRef = useRef()

    TOOLS.useOnClickOutside(optionsRef, () => {
        setShowOptions(false)
        setStyleId(null)
    })

    const callback = useCallback((event) => {}, [])

    const bind = useLongPress(callback, {
        onStart: (event) => {
            setSelectedRowId(parseInt(event.currentTarget.id))
            setStyleId(parseInt(event.currentTarget.id))
        },
        onFinish: (event) => setShowMobileOptions(true),
        onCancel: (event) => {
            setSelectedRowId(null)
            setStyleId(null)
            setShowMobileOptions(false)
        },
        onMove: (event) => console.log('Detected mouse or touch movement'),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'touch',
    })

    return (
        <div className="px-2 pt-5 text-lg lg:text-base">
            {loading ? (
                <Loader />
            ) : (
                <div className="min-height-70 flex flex-col ">
                    {rows.map((row) => (
                        <div
                            className="parents relative flex h-20 w-full pb-2"
                            key={row.id}
                        >
                            <div
                                key={row.id}
                                className={`grid w-full cursor-pointer  select-none rounded bg-white shadow-mobile lg:select-auto ${
                                    row.special
                                        ? 'border-l-8 border-blue-200'
                                        : null
                                } ${row.id === styleId ? ' ring' : null} `}
                                id={row.id}
                                {...bind}
                                onClick={() => historyPush(row.id)}
                            >
                                <div
                                    className={`parents row-span-1 flex grid-rows-1  flex-wrap items-center justify-between px-2 py-2`}
                                >
                                    <div
                                        className="w-1/2 truncate sm:w-1/4 "
                                        title={row.label}
                                    >
                                        {row.label}
                                    </div>
                                    <div
                                        className="w-1/2 truncate sm:w-1/4 "
                                        title={row.address}
                                    >
                                        {row.address}
                                    </div>
                                    <div
                                        className="w-1/2 truncate sm:w-1/4 "
                                        title={row.mail}
                                    >
                                        {row.mail}
                                    </div>

                                    <div
                                        className="flex w-1/2 align-middle text-base sm:w-1/4 lg:px-2 lg:text-lg xl:items-center"
                                        title={row.phoneNumber}
                                    >
                                        <div className="flex items-center align-middle xl:w-1/2">
                                            <div className="flex w-full items-center align-middle">
                                                <i className="fa fa-phone pr-2" />
                                                <div className="truncate">
                                                    {row.phoneNumber}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`absolute right-0 hidden h-10 cursor-pointer rounded pt-2 xl:block   ${
                                        row.id === selectedRowId
                                            ? null
                                            : 'options '
                                    } `}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setShowOptions(true)
                                        setSelectedRowId(row.id)
                                        setStyleId(row.id)
                                    }}
                                >
                                    <i
                                        className={`fas fa-ellipsis-h w-20 pr-4 pt-1 text-right ${
                                            showOptions ? 'hidden' : ''
                                        } `}
                                    />
                                    {row.id === selectedRowId && showOptions ? (
                                        <DefaultOptions
                                            setShowMobileOptions={
                                                setShowOptions
                                            }
                                            rowId={selectedRowId}
                                            handleModalDelete={
                                                handleModalDelete
                                            }
                                            historyPushUpdate={historyPush}
                                            historyPush={historyPush}
                                            optionsRef={optionsRef}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ))}
                    {totalItems >= itemPerPage && (
                        <MobilePagination
                            title={'Patients'}
                            setPage={setPage}
                            page={page}
                            totalItems={totalItems}
                            lastPage={lastPage}
                        />
                    )}
                </div>
            )}
            {totalItems >= itemPerPage && (
                <CardPagination
                    totalPages={totalPages}
                    handlePageClick={handlePageClick}
                    totalItems={totalItems}
                    title={'Patients'}
                />
            )}
            {showMobileOptions ? (
                <MobileOption
                    setShowMobileOptions={setShowMobileOptions}
                    rowId={selectedRowId}
                    handleModalDelete={handleModalDelete}
                    historyPush={historyPush}
                    historyPushUpdate={historyPush}
                    historyPushCopy={null}
                    setStyleId={setStyleId}
                />
            ) : null}
            {modalOpen ? (
                <ActionModal
                    setModalOpen={setModalOpen}
                    label={label}
                    action={handleAction}
                    labelAction={labelAction}
                    actionButton={actionButton}
                />
            ) : null}
        </div>
    )
}

export default EstablishmentCardTable
