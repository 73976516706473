import { getDateInitialState } from './Reducers/DateReducer'
import dayjs from 'dayjs'
import {
    formatDateFromApiToTimeObject,
    formatDateToApi,
    formatDateYYYYMMDD,
} from './dateUtils'
export const formatTime = (time) => {
    if (!time) return null

    try {
        // Si c'est une chaîne au format ISO
        if (typeof time === 'string' && time.includes('T')) {
            const parsed = dayjs(time)
            if (parsed.isValid()) {
                return parsed.format('YYYY-MM-DDTHH:mm')
            }
        }

        // Si c'est une chaîne au format HH:mm
        if (typeof time === 'string' && /^\d{2}:\d{2}$/.test(time)) {
            const parsed = dayjs(`1970-01-01T${time}`, 'YYYY-MM-DDTHH:mm')
            if (parsed.isValid()) {
                return parsed.format('YYYY-MM-DDTHH:mm')
            }
        }

        // Si c'est un objet Date valide
        if (time instanceof Date && !isNaN(time)) {
            return dayjs(time).format('YYYY-MM-DDTHH:mm')
        }

        // Si aucun format ne correspond, on retourne null
        console.warn(`Format de temps non reconnu : ${time}`)
        return null
    } catch (error) {
        console.error(`Erreur lors du formatage du temps : ${error.message}`)
        return null
    }
}

export const isDateValid = (date, isBirthDate = true, isLunarDate = false) => {
    if (!date) return false
    if (hasAllDateParts(date)) {
        const dayValid = validateDay(
            date.day,
            date.month,
            date.year,
            isBirthDate,
            isLunarDate
        )
        const monthValid = validateMonth(date.month, isLunarDate)
        const yearValid = validateYear(date.year, isBirthDate, isLunarDate)
        if (isLunarDate || isBirthDate) {
            return dayValid && monthValid && yearValid
        } else {
            return (
                dayValid &&
                monthValid &&
                yearValid &&
                isDateWithinValidRange(
                    new Date(date.year, date.month - 1, date.day),
                    date.day,
                    date.month,
                    date.year
                )
            )
        }
    }

    return false
}

export const checkLunarDate = (date) => {
    const day = parseInt(date.day, 10)
    const month = parseInt(date.month, 10)

    return day >= 32 || month >= 13
}

export const getDateFromDateReducer = (date) => {
    return new Date(date.year, date.month - 1, date.day)
}

const validateDay = (
    day,
    month,
    year,
    isBirthDate = true,
    lunarDate = false
) => {
    if (day.length !== 2) return false
    const dayNum = parseInt(day, 10)
    if (isBirthDate) {
        return dayNum >= 1 && dayNum <= 31
    } else if (lunarDate) {
        return dayNum >= 1 && dayNum <= 99
    } else {
        const monthNum = parseInt(month, 10)
        const yearNum = parseInt(year, 10)
        const daysInMonth = getDaysInMonth(monthNum, yearNum)
        return dayNum >= 1 && dayNum <= daysInMonth
    }
}

const validateMonth = (month, isLunarDate = false) => {
    if (month.length !== 2) return false
    const monthNum = parseInt(month, 10)
    if (isLunarDate) {
        return monthNum >= 1 && monthNum <= 99
    } else {
        return monthNum >= 1 && monthNum <= 12
    }
}

export const hasAllDateParts = (date) => {
    return date.day !== '' && date.month !== '' && date.year !== ''
}

const getDaysInMonth = (month, year) => {
    const daysInFeb =
        year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0) ? 29 : 28
    const daysInMonth = [31, daysInFeb, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
    return daysInMonth[month - 1]
}

export const hasOneDateParts = (date) => {
    return date.day !== '' || date.month !== '' || date.year !== ''
}

export const jsDateToObject = (jsDate) => {
    const day = String(jsDate.getDate()).padStart(2, '0')
    const month = String(jsDate.getMonth() + 1).padStart(2, '0') // JavaScript months are 0-indexed
    const year = jsDate.getFullYear().toString()

    return { day, month, year }
}

export const dateStringToObject = (dateString) => {
    if (dateString) {
        if (dateString instanceof Date) {
            dateString = formatDateToApi(dateString)
        }
        // split date string into an object with day, month, year
        let lunarArray = []
        let lunarDateObject = {}
        // cas date dd/mm/yy
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) {
            lunarArray = dateString.split('/')
            lunarDateObject = {
                day: lunarArray[0],
                month: lunarArray[1],
                year: lunarArray[2],
            }
        } else {
            // cas date iso yyyy-mm-dd
            const lunarArray = dateString.split('T')[0].split('-')
            lunarDateObject = {
                day: lunarArray[2],
                month: lunarArray[1],
                year: lunarArray[0],
            }
        }
        if (checkLunarDate(lunarDateObject)) {
            console.log('une date lunaire')
            return lunarDateObject
        }

        let formatedDate = formatDateFromApiToTimeObject(dateString)
        const date = dayjs(formatedDate ? formatedDate : dateString)
        return {
            day: date.format('DD'),
            month: date.format('MM'),
            year: date.format('YYYY'),
        }
    } else {
        return getDateInitialState()
    }
}

const isDateWithinValidRange = (inputDate, day, month, year) => {
    return (
        inputDate.getDate() === parseInt(day, 10) &&
        inputDate.getMonth() === parseInt(month, 10) - 1 &&
        inputDate.getFullYear() === parseInt(year, 10)
    )
}

// cas 1 Si c'est une date normal on autorise jusqu'à -10 ans et +10 ans de l'année actuelle
// cas 2 Si c'est une date de naissance on autorise pas une année supérieur à l'actuel
const validateYear = (year, isBirthDate, isLunarDate = false) => {
    if (year.length !== 4) return false
    const currentYear = new Date().getFullYear()
    const currentYearMinus10 = new Date().getFullYear() - 10
    const currentYearPlus10 = new Date().getFullYear() + 10
    const yearNum = parseInt(year, 10)
    if (isLunarDate) {
        return yearNum >= currentYear - 120 && yearNum <= currentYear
    } else if (isBirthDate) {
        return yearNum >= currentYear - 120 && yearNum <= currentYear
    } else {
        return yearNum >= currentYearMinus10 && yearNum <= currentYearPlus10
    }
}

// Only useAble for date from reducer
export const getDateReducerFormatForApi = (date, isBirthDate = false) => {
    if (isDateValid(date, isBirthDate)) {
        return dayjs(getDateFromDateReducer(date)).format('YYYY-MM-DD')
    } else {
        return null
    }
}

export const getNormalDateFormatForApi = (date) => {
    if (isDateValid(jsDateToObject(date), false)) {
        return dayjs(date).format('YYYY-MM-DD')
    } else {
        return null
    }
}

// Tableau des jours fériés en France (format YYYY-MM-DD)
const fixedHolidays = [
    '01-01', // Jour de l'An
    '05-01', // Fête du Travail
    '05-08', // Victoire 1945
    '07-14', // Fête Nationale
    '08-15', // Assomption
    '11-01', // Toussaint
    '11-11', // Armistice 1918
    '12-25', // Noël
]

// Fonction pour vérifier si une date est un jour férié fixe
function isFixedHoliday(date) {
    const formattedDate = dayjs(date).format('MM-DD')
    return fixedHolidays.includes(formattedDate)
}

// Fonction pour vérifier si une date est un dimanche
export function isSunday(date) {
    return dayjs(date).day() === 0
}

// Fonction pour calculer le lundi de Pâques
function getEasterMonday(year) {
    const easter = getEasterDate(year)
    return easter.add(1, 'day')
}

// Fonction pour calculer la date de Pâques (algorithme de Meeus/Jones/Butcher)
function getEasterDate(year) {
    const f = Math.floor,
        G = year % 19,
        C = f(year / 100),
        H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        L = I - J,
        month = 3 + f((L + 40) / 44),
        day = L + 28 - 31 * f(month / 4)
    return dayjs(`${year}-${month}-${day}`)
}

// Fonction pour calculer le lundi de Pentecôte
function getPentecostMonday(year) {
    const easter = getEasterDate(year)
    return easter.add(50, 'day')
}

// Fonction pour vérifier si une date est un jour férié (incluant les jours fériés mobiles) ou un dimanche
export function isHolidayOrSunday(date) {
    const checkIfDateIsSunday = formatDateYYYYMMDD(date)
    if (isSunday(checkIfDateIsSunday)) {
        console.log("c'est un dimanche", checkIfDateIsSunday)
        return true
    }

    const year = dayjs(date).year()
    const formattedDate = dayjs(date).format('MM-DD')

    // Vérifier les jours fériés fixes
    if (isFixedHoliday(date)) {
        console.log("c'est un jour férié fixe")
        return true
    }

    // Vérifier le lundi de Pâques
    if (formattedDate === getEasterMonday(year).format('MM-DD')) {
        console.log("c'est le lundi de Pâques")
        return true
    }

    // Vérifier le lundi de Pentecôte
    if (formattedDate === getPentecostMonday(year).format('MM-DD')) {
        console.log("c'est le lundi de Pentecôte")
        return true
    }

    return false
}
