import React from 'react'

const BigSwitch = ({
    options,
    selectedOption,
    setSelectedOption,
    className = '',
}) => {
    const renderIcon = (icon) => {
        // If icon is already a JSX element, return it directly
        if (React.isValidElement(icon)) {
            return <span className="mr-2">{icon}</span>
        }
        // If icon is a component, render it
        if (icon) {
            const IconComponent = icon
            return <IconComponent className="mr-2" />
        }
        return null
    }

    return (
        <div className={`grid grid-cols-2 gap-5 ${className}`}>
            {options.map((option) => (
                <button
                    key={option.value}
                    onClick={() => setSelectedOption(option.value)}
                    className={`flex items-center rounded-lg px-4 py-2 ${
                        selectedOption === option.value
                            ? 'bg-secondary-color text-white'
                            : 'bg-gray-200 text-gray-700'
                    }`}
                >
                    {renderIcon(option.icon)}
                    <div className="flex flex-col items-start">
                        <span className="font-semibold">{option.label}</span>
                        {option.description && (
                            <span className="text-sm">
                                {option.description}
                            </span>
                        )}
                    </div>
                </button>
            ))}
        </div>
    )
}

export default BigSwitch
