import React from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { formatEuro } from '../Manage'
import {
    findBorderByStatusType,
    findColorByStatusType,
    findValueByStatus,
} from '../Lots/LotListComponent'
import InvoiceDetails from './InvoiceDetails'

const NoemieLot = ({
    lot,
    openedCardId,
    setOpenedCardId,
    isReceived,
    handleShowLotResolvingModal,
}) => (
    <>
        <div
            className="parents relative flex w-full pb-2"
            onClick={() =>
                setOpenedCardId(openedCardId === lot.id ? null : lot.id)
            }
        >
            <div
                className={`grid w-full ${findBorderByStatusType(lot.status)}
                cursor-pointer select-none rounded bg-white shadow-mobile lg:select-auto`}
            >
                <div
                    className={`parents row-span-1 flex grid-rows-1  flex-wrap items-center justify-between px-2 py-2  text-sm lg:text-base`}
                >
                    <div className="w-1/2 truncate sm:w-1/4">
                        <span className={'font-semibold'}>
                            Lot {lot.groupId}
                        </span>
                    </div>
                    <div
                        className={`flex w-1/2 space-x-4 divide-x-2 truncate text-center sm:w-1/4`}
                    >
                        <div className="w-1/2">
                            <div>{lot?.payingCenter.label}</div>
                            <span className="font-semibold">
                                {lot.totalInvoiceAmountRefundedByAmo}€
                            </span>
                        </div>
                        <div className="w-1/2">
                            <div>AMC / Assuré</div>
                            <span className="font-semibold">
                                {lot.totalInvoiceRefundedByComplementary}€
                            </span>
                        </div>
                    </div>
                    <div className="flex w-1/2 flex-col truncate sm:w-1/4 sm:text-center">
                        <div>{lot.cpamInvoices.length} factures</div>
                        <span className={'font-semibold'}>
                            {formatEuro(lot.totalInvoiceAmount)}
                        </span>
                    </div>
                    <div className="flex w-1/2 justify-center truncate sm:w-1/4 ">
                        <span
                            className={`flex w-28 justify-center rounded-full ${findColorByStatusType(
                                lot.status
                            )}`}
                        >
                            {findValueByStatus(lot.status)}
                        </span>
                    </div>
                </div>
                {isReceived && (
                    <div
                        className={`absolute left-0 -ml-10 h-10 w-10 cursor-pointer rounded pt-1 text-center`}
                        onClick={() =>
                            setOpenedCardId(
                                openedCardId === lot.id ? null : lot.id
                            )
                        }
                    >
                        {openedCardId === lot.id ? (
                            <ChevronUpIcon className="h-10 w-10" />
                        ) : (
                            <ChevronDownIcon className="h-10 w-10" />
                        )}
                    </div>
                )}
            </div>
        </div>
        {isReceived && (
            <InvoiceDetails
                lot={lot}
                handleShowLotResolvingModal={handleShowLotResolvingModal}
                openedCardId={openedCardId}
            />
        )}
    </>
)

export default NoemieLot
