import SkeletonCard from './SkeletonCard'

export const SkeletonLoaderCards = () => {
    return (
        <div className="pt-12">
            <div className="mb-4 ml-auto flex h-4 w-20 animate-pulse items-end rounded bg-gray-300"></div>
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
        </div>
    )
}
