import { RegimeList } from '../../../../services/RegimeList'
import { formatItemForSelect } from '../../../../tools/Utility'

export const PAYING_CENTER_FORM_ACTIONS = {
    SET_PEC_RESPONSE: 'SET_PEC_RESPONSE',
    SET_CODE_REGIME: 'SET_CODE_REGIME',
    SET_CODE_CAISSE: 'SET_CODE_CAISSE',
    SET_CODE_CENTRE_GESTIONNAIRE: 'SET_CODE_CENTRE_GESTIONNAIRE',
    SET_ORGANISME_DESTINATAIRE: 'SET_ORGANISME_DESTINATAIRE',
    SET_CODE_CENTRE_INFORMATIQUE: 'SET_CODE_CENTRE_INFORMATIQUE',
    SET_LABEL: 'SET_PAYING_CENTER_LABEL',
    SET_SHOW_MODAL: 'SET_PAYING_CENTER_SHOW_MODAL',
    FETCH_PAYING_CENTER: 'FETCH_PAYING_CENTER',
    SET_ADDRESS: 'SET_ADDRESS',
    CLEAR_INPUT: 'CLEAR_INPUT',
}

export const PayingCenterFormReducer = (state, action) => {
    switch (action.type) {
        case PAYING_CENTER_FORM_ACTIONS.SET_PEC_RESPONSE: {
            return {
                ...state,
                ...action.payload,
            }
        }
        case PAYING_CENTER_FORM_ACTIONS.FETCH_PAYING_CENTER: {
            if (!action.payload)
                return getPayingCenterInitialState({ undefined })
            if (action.payload.address) {
                // remove the id from the address
                action.payload.address.id = undefined
                action.payload.address['@id'] = undefined
            }
            return {
                ...state,
                ...action.payload,
                codeRegime: RegimeList.find(
                    (regime) => regime.value === action?.payload?.codeRegime
                ),
            }
        }
        case PAYING_CENTER_FORM_ACTIONS.SET_CODE_REGIME:
            return {
                ...state,
                codeRegime: action.payload,
            }
        case PAYING_CENTER_FORM_ACTIONS.SET_CODE_CENTRE_GESTIONNAIRE:
            return {
                ...state,
                codeCentreGestionnaire: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .substring(0, 4),
            }
        case PAYING_CENTER_FORM_ACTIONS.SET_ORGANISME_DESTINATAIRE:
            return {
                ...state,
                organismeDestinataire: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .substring(0, 3),
                codeCaisse: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .substring(0, 3),
            }
        case PAYING_CENTER_FORM_ACTIONS.SET_CODE_CENTRE_INFORMATIQUE:
            return {
                ...state,
                codeCentreInformatique: action.payload
                    .replace(/[^\dA-Z]/g, '')
                    .substring(0, 3),
            }
        case PAYING_CENTER_FORM_ACTIONS.SET_LABEL:
            return {
                ...state,
                label: action.payload,
            }
        case PAYING_CENTER_FORM_ACTIONS.SET_SHOW_MODAL:
            return {
                ...state,
                showModal: action.payload,
            }
        case PAYING_CENTER_FORM_ACTIONS.SET_ADDRESS:
            return {
                ...state,
                address: action.payload,
            }
        case PAYING_CENTER_FORM_ACTIONS.CLEAR_INPUT:
            return {
                codeRegime: '',
                codeCaisse: '',
                codeCentreGestionnaire: '',
                organismeDestinataire: '',
                codeCentreInformatique: '',
                label: '',
                address: null,
            }
        default:
            return state
    }
}

export const getPayingCenterInitialState = ({ payingCenter }) => {
    if (payingCenter) {
        return {
            ...payingCenter,
            codeRegime: formatItemForSelect(
                payingCenter.codeRegime,
                RegimeList
            ),
        }
    }
    return {
        codeRegime: '',
        codeCaisse: '',
        codeCentreGestionnaire: '',
        organismeDestinataire: '',
        codeCentreInformatique: '',
        label: '',
        address: null,
    }
}
