import React, { useEffect, useRef } from 'react'
import DateRangePicker from 'react-date-range/dist/components/DateRangePicker'
import { fr } from 'date-fns/locale'
import {
    defaultInputRanges,
    defaultStaticRanges,
} from '../../services/defaultRangesPicker'
import DateRange from 'react-date-range/dist/components/DateRange'
import dayjs from 'dayjs'

const useOnClickUserMenuOutside = (ref, outsideHandler, clickHandler) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                // clickHandler(event)
            } else {
                outsideHandler(event)
            }
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, outsideHandler, clickHandler])
}

const SelectDateRangePicker = ({
    openSelectDatePicker,
    setOpenSelectDatePicker,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    topRight,
    setPage,
    page,
    setAction,
    action,
    unauthorizedPreviousDate,
}) => {
    const handleSelect = (ranges) => {
        const dateNow = new Date()

        if (unauthorizedPreviousDate && ranges.selection.startDate < dateNow) {
            setStartDate(dateNow)
        } else {
            setStartDate(ranges.selection.startDate)
        }
        if (unauthorizedPreviousDate && ranges.selection.endDate < dateNow) {
            setEndDate(dateNow)
        } else {
            setEndDate(ranges.selection.endDate)
        }

        if (ranges.selection.startDate !== ranges.selection.endDate) {
            setOpenSelectDatePicker(false)
        }
        if (page !== undefined && page > 1) {
            setPage(1)
        } else if (action !== undefined) {
            setAction(!action)
        }
    }

    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    }
    const datePickerRef = useRef()

    useOnClickUserMenuOutside(
        datePickerRef,
        () => setOpenSelectDatePicker(false),
        () => setOpenSelectDatePicker(true)
    )

    return (
        <div>
            {openSelectDatePicker && (
                <div
                    className={`absolute z-50 ${topRight && 'right-0 top-0'}`}
                    ref={datePickerRef}
                >
                    <div className="hidden lg:block">
                        <DateRangePicker
                            editableDateInputs={false}
                            onChange={handleSelect}
                            moveRangeOnFirstSelection={false}
                            showSelectionPreview={true}
                            ranges={[selectionRange]}
                            direction="horizontal"
                            rangeColors={['#8aafb0']}
                            locale={fr}
                            staticRanges={defaultStaticRanges}
                            inputRanges={defaultInputRanges}
                        />
                    </div>
                    <div className="lg:hidden">
                        <DateRange
                            editableDateInputs={false}
                            onChange={handleSelect}
                            moveRangeOnFirstSelection={false}
                            showSelectionPreview={true}
                            ranges={[selectionRange]}
                            className="rdrCalendarWrapper"
                            direction="horizontal"
                            rangeColors={['#8aafb0']}
                            locale={fr}
                            staticRanges={defaultStaticRanges}
                            inputRanges={defaultInputRanges}
                        />
                    </div>
                </div>
            )}
            <div
                className="flex min-w-12 cursor-pointer items-center justify-center rounded-lg border bg-white p-2 text-sm font-semibold shadow-md hover:border-lighter-blue-green"
                onClick={() => setOpenSelectDatePicker(!openSelectDatePicker)}
            >
                <i className="fas fa-calendar-alt fa-lg pr-3 " />
                {dayjs(startDate).format('D MMM') ===
                dayjs(endDate).format('D MMM') ? (
                    <p className="capitalize">
                        {dayjs(startDate).format('ddd D MMM')}
                    </p>
                ) : (
                    <span className="flex items-center space-x-2">
                        <p className="text-center capitalize">
                            {dayjs(startDate).format('ddd D MMM')}
                        </p>
                        <i className="fas fa-arrow-right" />
                        <p className="text-center capitalize">
                            {dayjs(endDate).format('ddd D MMM')}
                        </p>
                    </span>
                )}
            </div>
        </div>
    )
}

export default SelectDateRangePicker
