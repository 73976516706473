import { NavArrowDown } from 'iconoir-react'
import { useState, useEffect, useRef } from 'react'
import {
    getTextAndBackgroundColor,
    getTextAndBackgroundColorWithBorders,
    hoverColorMap,
} from '../../../services/RunStatus'

const RunSelectStatus = ({ status, handleStatusChange, statusOptions }) => {
    const [openStatusList, setOpenStatusList] = useState(false)
    const dropdownRef = useRef(null)

    const currentStatusObj = statusOptions.find(
        (option) => option.value === status
    )

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setOpenStatusList(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    const toggleStatusList = () => {
        setOpenStatusList(!openStatusList)
    }

    const onStatusChange = (option) => {
        handleStatusChange(option)
        setOpenStatusList(false)
    }

    console.log(currentStatusObj)

    return (
        <div ref={dropdownRef} className="relative inline-block text-left">
            <button
                className={`text-md align-center flex w-48 justify-center rounded-full border-2 px-4 py-1 font-bold hover:scale-[1.02] ${getTextAndBackgroundColorWithBorders(
                    currentStatusObj ? currentStatusObj.label : status
                )}`}
                onClick={toggleStatusList}
            >
                <p>{currentStatusObj ? currentStatusObj.label : status}</p>
                <NavArrowDown className={'ml-auto flex'} />
            </button>
            {openStatusList && (
                <div className="z-1000 absolute right-0 mt-2 w-56 origin-top-right rounded-md border border-gray-200 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div
                        className=""
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {statusOptions.map((option) => (
                            <button
                                key={option.value}
                                className={`w-full px-2 py-2 text-left ${
                                    hoverColorMap[option.value] ||
                                    'hover:bg-gray-100'
                                }`}
                                role="menuitem"
                                onClick={() => onStatusChange(option.value)}
                            >
                                <span
                                    className={`flex w-full rounded p-2 font-semibold ${getTextAndBackgroundColor(
                                        option.value
                                    )}`}
                                >
                                    {option.label}
                                </span>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default RunSelectStatus
