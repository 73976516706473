import React from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { InfoCircle, MapPin } from 'iconoir-react'
import { RunObjectColors } from '../../../../../services/RunObjectColors'

const PcShuffledRunCard = ({
    run,
    onCardClicked,
    onInfoClicked,
    fromGlobalView = false,
    showRunObjectColors,
}) => {
    return (
        <div
            key={run.id}
            className={classNames(
                `sorted-pc-run-car-grid grid rounded border-l-4 shadow-mobile ${run.statusBorderColor}`,
                {
                    stripeBg: run.partner && run.isGiven,
                    stripeBg_canceled:
                        run.status.id === 'canceled' && !run.selected,
                    'bg-white text-gray-800':
                        (!run.selected && !showRunObjectColors) ||
                        fromGlobalView,
                    'bg-light-dark-green text-white':
                        run.selected && !fromGlobalView,
                    [`${RunObjectColors[run.runObject.color]}`]:
                        showRunObjectColors && !run.selected,
                }
            )}
            id={run.id}
        >
            <div
                className={classNames(
                    'h-42 col-span-1 flex items-center justify-between px-2 py-1 text-base',
                    {
                        'cursor-pointer': !fromGlobalView,
                    }
                )}
                onClick={() => {
                    if (!fromGlobalView) {
                        onCardClicked(run)
                    }
                }}
            >
                <div className="flex w-2/12 justify-between px-2">
                    <div className="flex w-full flex-row font-semibold">
                        <div className="flex justify-center">
                            {run.departureTimeHour}
                        </div>
                        <div className="">-</div>
                        <div className="flex justify-center">
                            {run.arrivingTimeHour}
                        </div>
                    </div>
                </div>

                <div className="flex w-1/12 uppercase">
                    {run.masterRun ? (
                        <div
                            className="rounded-full px-2 py-1 font-semibold text-white"
                            style={{
                                backgroundColor: run.masterRun.vehicle.color,
                            }}
                        >
                            {run.masterRun.users[0].firstname.substring(0, 3)}.
                            {run.masterRun.users[0].lastname.substring(0, 1)}
                        </div>
                    ) : run.partner && run.isGiven ? (
                        <div
                            className="w-16 rounded-full px-2 py-1 font-semibold"
                            // style={{
                            //     color: run.masterRun.vehicle.color,
                            // }}
                        >
                            {run.partner.firstname.substring(0, 3)}.
                            {run.partner.lastname.substring(0, 1)}
                        </div>
                    ) : (
                        'N/A'
                    )}
                </div>
                <div className="w-3/12 truncate pt-2 lg:pt-0">
                    <div className="truncate text-sm font-semibold ">
                        {run.patientName}
                    </div>
                    <div className="truncate text-sm font-light">
                        {run.pickUpAddress}
                    </div>
                </div>
                <div className="flex w-2/12 items-center justify-center">
                    <i
                        className={`${
                            run.isReturnPath
                                ? 'far fa-registered fa-lg '
                                : 'hidden'
                        }`}
                    />
                </div>
                <div className="flex w-4/12">
                    <div className="flex space-x-2 truncate">
                        <span>
                            <MapPin />
                        </span>
                        <span className="truncate">{run.depositAddress}</span>
                    </div>
                </div>
            </div>
            {!fromGlobalView && (
                <div className="col-span-1 flex cursor-pointer items-center justify-center hover:scale-110">
                    <div
                        className="text-xl"
                        onClick={() => {
                            onInfoClicked(run)
                        }}
                    >
                        <InfoCircle />
                    </div>
                </div>
            )}
        </div>
    )
}

export default PcShuffledRunCard
