export const MODAL_FORM_ACTIONS = {
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',
    SET_ACTION_BUTTON: 'SET_ACTION_BUTTON',
    SET_LABEL_ACTION: 'SET_LABEL_ACTION',
    SET_LABEL: 'SET_LABEL',
    OPEN_MODAL_AR_TO_A: 'SET_OPEN_AR_TO_A',
    // Add more actions as needed
}

export const getModalInitialState = () => {
    return {
        modalOpen: false,
        modalOpenArToA: false,
        labelAction: '',
        actionButton: '',
        label: '',
    }
}

export const ModalFormReducer = (state, action) => {
    switch (action.type) {
        case MODAL_FORM_ACTIONS.OPEN_MODAL:
            return {
                ...state,
                modalOpen: true,
                labelAction: action.payload.labelAction,
                actionButton: action.payload.actionButton,
                label: action.payload.label,
            }
        case MODAL_FORM_ACTIONS.CLOSE_MODAL:
            return {
                ...state,
                modalOpen: false,
                modalOpenArToA: false,
            }

        case MODAL_FORM_ACTIONS.OPEN_MODAL_AR_TO_A:
            return {
                ...state,
                modalOpenArToA: true,
                labelAction: action.payload.labelAction,
                actionButton: action.payload.actionButton,
                label: action.payload.label,
            }
        // Handle other actions
        default:
            return state
    }
}
