import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import API from '../services/api'
import { formatRegulationRuns } from '../services/run'

export const REGULATION_KEYS = {
    LIST: ['regulation', 'list'],
    PARAMETERS: ['regulation', 'parameters'],
}

// Hook pour récupérer la liste des runs
export const useRunsList = ({
    search,
    dateFrom,
    dateTo,
    type,
    page,
    patient,
    status,
    object,
    user,
    partner,
    shareFilter,
    excludeStatus,
    seriesId,
    enabled = true,
}) => {
    return useQuery({
        queryKey: [
            ...REGULATION_KEYS.LIST,
            {
                search,
                dateFrom,
                dateTo,
                type,
                page,
                patient,
                status,
                object,
                user,
                partner,
                shareFilter,
                excludeStatus,
                seriesId,
            },
        ],
        queryFn: async () => {
            const between = {
                strictlyBefore: dayjs(dateTo).format('YYYY-MM-DD'),
                strictlyAfter: dayjs(dateFrom).format('YYYY-MM-DD'),
            }

            if (seriesId) {
                const data = await API.Runs.list(
                    search,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    false,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    seriesId
                )
                return {
                    rows: formatRegulationRuns(data['hydra:member']),
                    totalItems: data['hydra:totalItems'],
                    lastPage: 1,
                }
            }
            console.log('search', search, partner, patient)

            const data = await API.Runs.list(
                search,
                null,
                null,
                null,
                patient?.id || '',
                null,
                page,
                100,
                null,
                null,
                null,
                null,
                between,
                type?.value || null,
                null,
                null,
                null,
                null,
                null,
                status?.value || null,
                object?.value || null,
                user?.value || null,
                partner?.value || null,
                null,
                null,
                shareFilter?.filtre || null,
                null,
                excludeStatus?.value || null
            )

            const lastPage = data['hydra:view']?.['hydra:last']
                ? parseInt(
                      data['hydra:view']['hydra:last'].split('=').reverse()[0]
                  )
                : 1

            return {
                rows: formatRegulationRuns(data['hydra:member']),
                totalItems: data['hydra:totalItems'],
                lastPage,
            }
        },
        enabled,
        keepPreviousData: true,
        staleTime: 30000, // 30 seconds
    })
}

// Hook pour les mutations (delete, share, removeShare)
export const useRunMutations = () => {
    const queryClient = useQueryClient()

    const invalidateList = () => {
        queryClient.invalidateQueries({ queryKey: REGULATION_KEYS.LIST })
    }

    const deleteRun = useMutation({
        mutationFn: (id) => API.Runs.delete(id),
        onSuccess: () => {
            toast.success('Le transport a bien été supprimé.')
            invalidateList()
        },
        onError: (error) => {
            console.error(error)
            toast.error('Erreur lors de la suppression')
        },
    })

    const shareRun = useMutation({
        mutationFn: ({ id, data }) => API.Runs.share(id, data),
        onSuccess: () => {
            toast.success('Le transport a bien été partagé.')
            invalidateList()
        },
        onError: () => {
            toast.error(
                'La limite de partage de transport par SMS a été dépassée.'
            )
        },
    })

    const removeShare = useMutation({
        mutationFn: (id) => API.Runs.removeShare(parseInt(id)),
        onSuccess: () => {
            toast.success('Partage supprimé avec succès')
            invalidateList()
        },
        onError: () => {
            toast.error('Erreur lors de la suppression du partage')
        },
    })

    return {
        deleteRun,
        shareRun,
        removeShare,
    }
}
