import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import PayingCenter from './PayingCenter'
import PayingCenterWrapper from './PayingCenterWrapper'

const PayingCenterContainer = () => {
    const [search, setSearch] = useState('')
    return (
        <Routes>
            <Route path="/:id" element={<PayingCenterWrapper />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <PayingCenter search={search} setSearch={setSearch} />
                    </div>
                }
            />
        </Routes>
    )
}

export default PayingCenterContainer
