import { Route, Routes } from 'react-router-dom'
import React, { useState } from 'react'
import Establishment from './Establishment'
import Manage from '../Establishment/Manage'
import ClassicFilter from '../../Commons/ClassicFilter'

const EstablishmentContainer = () => {
    const [search, setSearch] = useState('')
    return (
        <Routes>
            <Route path=":id" element={<Manage />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <ClassicFilter
                            search={search}
                            setSearch={setSearch}
                            icon={'fa-hotel'}
                            title={'Nouvel établissement'}
                            link={'/facturation/etablissements'}
                        />
                        <Establishment search={search} />
                    </div>
                }
            />
        </Routes>
    )
}

export default EstablishmentContainer
