import React from 'react'
import API from '../../../services/api'
import SeriesListContainer from './SeriesListContainer'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'

const SeriesList = ({ archived, setArchived, rows }) => {
    const queryClient = useQueryClient()
    const handleDeleteSeries = (id) => {
        API.Series.delete(id).then((response) => {
            toast.success('La série a bien été supprimée.')
            queryClient.invalidateQueries({ queryKey: ['series'] })
        })
    }
    const handleArchivedSeries = (series) => {
        const data = { archived: !series.archived, fromArchivedUpdate: true }
        API.Series.put(series.id, data).then((response) => {
            toast.success('La série a bien été archivée.')
            queryClient.invalidateQueries({ queryKey: ['series'] })
        })
    }

    return (
        <SeriesListContainer
            rows={rows}
            handleDeleteSeries={handleDeleteSeries}
            handleArchivedSeries={handleArchivedSeries}
            archived={archived}
            setArchived={setArchived}
        />
    )
}

export default SeriesList
