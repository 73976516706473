import React, {
    createContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react'
import {
    ACTIONS,
    getInitialState,
    PlanningReducer,
} from '../../../services/Reducers/PlanningReducer'
import { useStickyDateState } from '../../../hooks/LocalStorageDatesHooks'
import dayjs from 'dayjs'
import { Plannings } from '../../../services/API/Entities/plannings'
import usePlanningStore from '../../../stores/Planning'
import API from '../../../services/api'
import { toast } from 'react-toastify'
import { checkSameGroup } from '../../../tools/Utility'
import { saveAs } from 'file-saver'
import {
    flatGroupedRunsAndRuns,
    handleCreateGroups,
} from './PlanningGroupUtility'
import { Regulation } from '../../../services/API/Entities/regulation'
import { SortRunsOneAfterAnother } from '../../../tools/Sort'
import { useMutation, useQuery } from '@tanstack/react-query'
import { formatAllData, formatRuns, formatTeams } from './PlanningUtility'
import {
    formatDateLocal,
    formatDateToApi,
    formatDateToApiWithTimezone,
} from '../../../services/dateUtils'

const PlanningContext = createContext()

export const PlanningProvider = ({ children }) => {
    const [planningDate, setPlanningDate] = useStickyDateState(
        new Date(dayjs().add(1, 'day')),
        'storedDate'
    )
    const [planning, dispatch] = useReducer(
        PlanningReducer,
        { date: planningDate },
        getInitialState
    )

    const verticalTimeLineRef = useRef()

    const handleClearTeamAndUnassignedRun = () => {
        dispatch({
            type: ACTIONS.SET_UNSELECTED_ALL_RUNS,
        })
    }
    const dashboardHidedTeams = usePlanningStore(
        (state) => state.dashboardHidedTeams
    )
    const setDashboardHidedTeams = usePlanningStore(
        (state) => state.setDashboardHidedTeams
    )

    const {
        isLoading,
        isError,
        data: fetchAllData,
        error,
        refetch: refetchAllData,
        isFetching,
    } = useQuery({
        queryKey: ['masterRunsUnassignedRunsAndPartnersRuns'],
        queryFn: async () =>
            Regulation.fetchMasterRunsUnassignedRunsAndPartnersRuns({
                date: planningDate,
                isPatient: planning.isPatientFilter.value,
            }),
        enabled: false,
    })

    useEffect(() => {
        if (fetchAllData) {
            formatAllData({
                data: fetchAllData,
                planning,
                dispatch,
                dashboardHidedTeams,
            })
        }
    }, [fetchAllData])

    const { mutate: addRunToMasterRun } = useMutation({
        mutationKey: 'addRunsToMasterRun',
        mutationFn: Regulation.addRunsToMasterRun,
        onSuccess: (data) => {
            onAddRunToMasterRunSuccess(data)
        },
        onError: (error) => {
            if (error?.refresh) {
                handleOpenCompetitionModal(true)
            }
        },
    })

    const onAddRunToMasterRunSuccess = (data) => {
        if (data.unassignedRuns) {
            const unassignedRuns = SortRunsOneAfterAnother(
                data.unassignedRuns
            ).map((runs) => {
                return formatRuns(runs, null, planning, false)
            })

            dispatch({
                type: ACTIONS.SET_UNASSIGNED_RUNS,
                payload: unassignedRuns,
            })
        }

        dispatch({
            type: ACTIONS.REFRESH_UPDATED_TEAMS,
            payload: formatTeams(
                dispatch,
                data.masterRuns,
                dashboardHidedTeams,
                planning
            ),
        })
        toast.success('Succès.')
    }

    const reRender = async () => {
        dispatch({
            type: ACTIONS.SET_IS_FIRST_LOADING_PLANNING,
            payload: true,
        })
        Plannings.fetchAllLockedForMonth(planningDate).then((data) => {
            dispatch({
                type: ACTIONS.SET_PLANNINGS,
                payload: data['hydra:member'],
            })
            // check if the actual planning is locked
            const planning = data['hydra:member'].find((planning) => {
                return dayjs(planning.date).isSame(planningDate, 'day')
            })
            dispatch({
                type: ACTIONS.SET_PLANNING,
                payload: planning,
            })
        })
        dispatch({
            type: ACTIONS.SET_LOADING,
            payload: true,
        })

        await refetchAllData()

        dispatch({
            type: ACTIONS.SET_LOADING,
            payload: false,
        })
    }

    const totalRunsTeam = (team) => {
        let totalRuns = 0
        team.runs.forEach((r) => {
            if (r.groupedRun) {
                totalRuns = totalRuns + r.groupedRuns.length
            } else {
                totalRuns = totalRuns + 1
            }
        })
        return totalRuns
    }

    const handleOpenRunManageModal = (value) => {
        dispatch({ type: ACTIONS.SET_OPEN_RUN_MANAGE_MODAL, payload: value })
    }

    const { mutate: unassignedRunFromMasterRun } = useMutation({
        mutationKey: 'unassignedRunFromMasterRun',
        mutationFn: Regulation.unassignedRunsFromMasterRun,
        onSuccess: (data) => {
            onUnassignedRunFromMasterRunSuccess(data)
        },
        onError: (error) => {
            if (error?.refresh) {
                handleOpenCompetitionModal(true)
            }
        },
    })

    const onUnassignedRunFromMasterRunSuccess = (data) => {
        const unassignedRuns = SortRunsOneAfterAnother(data.unassignedRuns).map(
            (runs) => {
                return formatRuns(runs, null, planning, false)
            }
        )

        dispatch({
            type: ACTIONS.SET_UNASSIGNED_RUNS,
            payload: unassignedRuns,
        })
        dispatch({
            type: ACTIONS.REFRESH_UPDATED_TEAMS,
            payload: formatTeams(
                dispatch,
                data.masterRuns,
                dashboardHidedTeams,
                planning
            ),
        })
        toast.success(
            data.runsToUnGroup && data.runsToUnGroup.length > 1
                ? 'Les transports ont été désassignés.'
                : 'Le transport a été désassigné.'
        )
    }

    const unassignSelectedTeamsRuns = async (team) => {
        // refresh de l'équipe
        // refresh des runs non assignés
        handleDisabledButtons(true)
        const data = {
            runs: team.runs
                .filter((r) => r.selected)
                .flatMap((run) => {
                    if (run.groupedRun) {
                        return run.groupedRuns.flatMap((groupedRun) => {
                            return {
                                id: groupedRun.id,
                            }
                        })
                    } else {
                        return {
                            id: run.id,
                        }
                    }
                }),
            clientOpenedPageDatetime: formatDateToApiWithTimezone(
                planning.clientOpenedPageDatetime,
                true
            ),
            date: formatDateToApi(planningDate),
            isPatient: planning.isPatientFilter.value,
        }
        dispatch({
            type: ACTIONS.SET_LOADING,
            payload: true,
        })
        unassignedRunFromMasterRun({ id: team.id, data })
    }

    const handleTeamClicked = async (team) => {
        handleDisabledButtons(true)
        if (!team.isPartner) {
            //add unassignedRunToTeam
            let unassignedRunToAdd = []
            planning.unassignedRuns.forEach((groupRuns) => {
                unassignedRunToAdd = [
                    ...unassignedRunToAdd,
                    ...groupRuns.filter((r) => r.selected),
                ]
            })

            let runsToAdd = unassignedRunToAdd

            const teamRunsSelected = []
            planning.teams.forEach((t) => {
                const selectedRuns = t.runs.filter((r) => r.selected)
                if (selectedRuns.length > 0) {
                    selectedRuns.forEach((selectedRun) => {
                        if (selectedRun.masterRun.id !== team.id) {
                            // add if not the same team

                            if (selectedRun.groupedRun) {
                                selectedRun.groupedRuns.forEach((r) => {
                                    teamRunsSelected.push(r)
                                })
                            } else {
                                teamRunsSelected.push(selectedRun)
                            }
                        }
                    })
                }
            })

            runsToAdd = [...runsToAdd, ...teamRunsSelected]

            if (runsToAdd.length > 0) {
                //check if runs with exact same hours
                runsToAdd = checkIfRunOnExactSameTimes(team, runsToAdd)
                const groupsOfRunsToGroup = handleCreateGroups(team, runsToAdd)

                if (groupsOfRunsToGroup.length) {
                    toast.warn('Des transports se superposent.')
                }

                // console.log('groupsOfRunsToGroup', groupsOfRunsToGroup)
                if (runsToAdd.length) {
                    const teamRuns = [
                        ...flatGroupedRunsAndRuns(team.runs),
                        ...runsToAdd,
                    ]
                    const data = {
                        runs: teamRuns.map((r) => {
                            return {
                                // id: r['@id'],
                                id: r.id,
                                masterRunId: r.masterRun?.id,
                            }
                        }),
                        clientOpenedPageDatetime: formatDateToApiWithTimezone(
                            planning.clientOpenedPageDatetime,
                            true
                        ),
                        date: formatDateToApi(planningDate),
                        isPatient: planning.isPatientFilter.value,
                    }
                    dispatch({
                        type: ACTIONS.SET_LOADING,
                        payload: true,
                    })

                    //todoChange
                    console.log('DATA', data)
                    addRunToMasterRun({ id: team.id, data })
                } else {
                    handleDisabledButtons(false)
                }
            }
        }
    }

    const checkIfRunOnExactSameTimes = (team, runsToAdd) => {
        let runsNotAdded = []
        team.runs.forEach((run) => {
            const runsWithSameHours = runsToAdd.filter(
                (runToAdd) =>
                    runToAdd.departureTimeHour === run.departureTimeHour &&
                    runToAdd.arrivingTimeHour === run.arrivingTimeHour
            )
            if (runsWithSameHours.length) {
                runsNotAdded = [...runsNotAdded, ...runsWithSameHours]
            }
        })

        runsToAdd.forEach((runToAdd) => {
            const runsWithSameHours = runsToAdd.filter(
                (runToAdd2) =>
                    runToAdd.id !== runToAdd2.id &&
                    runToAdd2.departureTimeHour ===
                        runToAdd.departureTimeHour &&
                    runToAdd2.arrivingTimeHour === runToAdd.arrivingTimeHour &&
                    !checkSameGroup(runToAdd, runToAdd2)
            )
            if (runsWithSameHours.length) {
                runsNotAdded = [...runsNotAdded, ...runsWithSameHours]
            }
        })

        if (runsNotAdded.length) {
            toast.error(
                "Un ou des transports n'ont pas pu être ajoutés car des horaires sont similaires. Veuillez grouper les transports pour les assigner."
            )
        }

        runsNotAdded = [...new Set(runsNotAdded)]
        runsToAdd = runsToAdd.filter((x) => !runsNotAdded.includes(x))

        return runsToAdd
    }

    const handleGroupIconClicked = async (team) => {
        handleDisabledButtons(true)
        let unassignedRunSelected = []
        planning.unassignedRuns.forEach((groupRuns) => {
            unassignedRunSelected = [
                ...unassignedRunSelected,
                ...groupRuns.filter((r) => r.selected),
            ]
        })
        let teamRunsSelected = []
        planning.teams.forEach((team) => {
            const selectedRuns = team.runs.filter((r) => r.selected)
            if (selectedRuns) {
                teamRunsSelected = [...teamRunsSelected, ...selectedRuns]
            }
        })

        await handleGroupRuns(
            [...teamRunsSelected, ...unassignedRunSelected],
            team
        )
    }

    const { mutate: groupMasterRunRuns } = useMutation({
        mutationKey: 'addRunsToMasterRun',
        mutationFn: Regulation.groupMasterRunRuns,
        onSuccess: (data) => {
            onGroupMasterRunRunsSuccess(data)
        },
        onError: (error) => {
            if (error?.refresh) {
                handleOpenCompetitionModal(true)
            }
        },
    })

    const onGroupMasterRunRunsSuccess = (data) => {
        if (data.unassignedRuns) {
            const unassignedRuns = SortRunsOneAfterAnother(
                data.unassignedRuns
            ).map((runs) => {
                return formatRuns(runs, null, planning, false)
            })

            dispatch({
                type: ACTIONS.SET_UNASSIGNED_RUNS,
                payload: unassignedRuns,
            })
        }
        dispatch({
            type: ACTIONS.REFRESH_UPDATED_TEAMS,
            payload: formatTeams(
                dispatch,
                data.masterRuns,
                dashboardHidedTeams,
                planning
            ),
        })
        toast.success('Les transports ont été groupés.')
    }

    const handleGroupRuns = async (runs, team) => {
        const flatRunsToGroup = flatGroupedRunsAndRuns(runs)

        //group runs
        flatRunsToGroup.forEach((r, index) => {
            r.departureOrder = index + 1
            if (index !== 0) {
                r.parent = flatRunsToGroup[0]
            }
        })

        const data = {
            runs: flatRunsToGroup.map((r) => {
                return {
                    id: r.id,
                    masterRunId: r.masterRun?.id,
                }
            }),
            clientOpenedPageDatetime: formatDateToApiWithTimezone(
                planning.clientOpenedPageDatetime,
                true
            ),
            date: formatDateToApi(planningDate),
            isPatient: planning.isPatientFilter.value,
        }

        dispatch({
            type: ACTIONS.SET_LOADING,
            payload: true,
        })

        groupMasterRunRuns({ id: team.id, data })
    }

    const handleInformationRunModalOpen = (value) => {
        dispatch({
            type: ACTIONS.SET_INFORMATION_RUN_MODAL_OPEN,
            payload: value,
        })
    }

    const handlePdfGeneration = () => {
        const data = {
            date: formatDateToApi(planningDate),
        }
        dispatch({ type: ACTIONS.SET_DOWNLOAD_BUTTON_LOADING, payload: true })
        API.Pdf.planning(data)
            .then((response) => {
                return response.blob()
            })
            .then((blob) => {
                saveAs(blob, `planning-${formatDateLocal(planning.date)}.pdf`)
                dispatch({
                    type: ACTIONS.SET_DOWNLOAD_BUTTON_LOADING,
                    payload: false,
                })
            })
            .catch((error) => {
                dispatch({
                    type: ACTIONS.SET_DOWNLOAD_BUTTON_LOADING,
                    payload: false,
                })
            })
    }

    const handleOpenCompetitionModal = (value) => {
        dispatch({ type: ACTIONS.SET_OPEN_COMPETITION_MODAL, payload: value })
    }

    const handleDisabledButtons = (value) => {
        dispatch({
            type: ACTIONS.SET_DISABLED_BUTTONS,
            payload: value,
        })
    }

    const countTeamsRunsSelected = () => {
        return planning.teams.reduce((total, team) => {
            const selectedRunsInTeam = team.runs.filter(
                (run) => run.selected
            ).length
            return total + selectedRunsInTeam
        }, 0)
    }

    return (
        <PlanningContext.Provider
            value={{
                planning,
                dispatch,
                planningDate,
                setPlanningDate,
                reRender,
                handleClearTeamAndUnassignedRun,
                totalRunsTeam,
                handleOpenRunManageModal,
                unassignSelectedTeamsRuns,
                handleTeamClicked,
                handleGroupIconClicked,
                flatGroupedRunsAndRuns,
                handleInformationRunModalOpen,
                handlePdfGeneration,
                handleOpenCompetitionModal,
                handleDisabledButtons,
                handleGroupRuns,
                countTeamsRunsSelected,
                verticalTimeLineRef,
            }}
        >
            {children}
        </PlanningContext.Provider>
    )
}

export function usePlanningContext() {
    return React.useContext(PlanningContext)
}

export default PlanningContext
