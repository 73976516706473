import _ from 'lodash'
import {
    SortRunsByGroupAndDepartureTime,
    SortRunsOneAfterAnother,
} from '../../../tools/Sort'
import { getBorderColor } from '../../../services/RunStatus'
import { ACTIONS } from '../../../services/Reducers/PlanningReducer'
import {
    formatTimeLocal,
    removeTimeZoneToDate,
} from '../../../services/dateUtils'

export const formatAllData = ({
    data,
    planning,
    dispatch,
    dashboardHidedTeams,
}) => {
    const masterRuns = data.masterRuns
    const teams = formatTeams(
        dispatch,
        masterRuns,
        dashboardHidedTeams,
        planning
    )

    const partnersTeams = formatPartnersTeams(
        dispatch,
        data.partnersRuns,
        planning,
        dashboardHidedTeams,
        teams.length
    )

    dispatch({
        type: ACTIONS.SET_TEAMS,
        payload: [...teams, ...partnersTeams],
    })

    const unassignedRuns = SortRunsOneAfterAnother(data.unassignedRuns).map(
        (runs) => {
            return formatRuns(runs, null, planning, false)
        }
    )

    dispatch({
        type: ACTIONS.SET_UNASSIGNED_RUNS,
        payload: unassignedRuns,
    })
}

const formatPartnersTeams = (
    dispatch,
    runs,
    dashboard,
    dashboardHidedTeams,
    nbTeams
) => {
    const partnerTeams = []
    const runsByPartners = _.groupBy(runs, (run) => run.partner.id)

    dispatch({
        type: ACTIONS.SET_NB_PARTNERS_RUNS,
        payload: runs.length,
    })

    Object.entries(runsByPartners).map(([key, runs]) => {
        const partnerTeam = {
            names: null,
            vehicle: null,
            firstRunHour: null,
            lastRunHour: null,
            runs: [],
            isPartner: true,
            position: nbTeams + parseInt(key),
        }

        if (runs.length > 0) {
            partnerTeam.names = `${runs[0].partner.firstname} ${runs[0].partner.lastname}`
            partnerTeam.id = runs[0].partner.id
            partnerTeam.hide = dashboardHidedTeams.some(
                (id) => id === partnerTeam.id
            )

            partnerTeam.runs = SortRunsByGroupAndDepartureTime(runs)
            partnerTeam.firstRunHour = formatTimeLocal(runs[0].departureTime)
            if (runs.length >= 1) {
                partnerTeam.lastRunHour = formatTimeLocal(
                    runs[runs.length - 1].arrivingTime
                )
            }

            partnerTeam.runs = formatRuns(
                partnerTeam.runs,
                null,
                dashboard,
                true
            )
        }
        partnerTeams.push(partnerTeam)
    })

    return partnerTeams
}

export const formatTeams = (
    dispatch,
    masterRuns,
    dashboardHidedTeams,
    dashboard
) => {
    let totalNbTeamsRuns = 0
    let totalNbCanceledTeamsRuns = 0
    const teams = masterRuns
        .sort((a, b) => a.vehicleId - b.vehicleId)
        .map((team) => {
            const format = {
                ...team,
                names: team.users.map(
                    (u, index) =>
                        `${index === 0 && team.users.length > 1 ? ' ' : ''} ${
                            u.firstname
                        } ${u.lastname}`
                ),
                vehicle: team.vehicle.label,
                licensePlate: team.vehicle.licensePlate,
                position: team.position,
                firstRunHour: null,
                lastRunHour: null,
                isPartner: false,
                color: team.vehicle.color,
                value: team['@id'],
                label: `${team.vehicle.label} - ${team.users.map(
                    (u, index) =>
                        `${index === 0 && team.users.length > 1 ? ' ' : ''} ${
                            u.firstname
                        } ${u.lastname}`
                )}`,
                hide: dashboardHidedTeams.some((id) => id === team.id),
                completelyHided: team.hided,
            }
            totalNbTeamsRuns =
                totalNbTeamsRuns +
                format.runs.filter((run) => run.status.id !== 'canceled').length
            totalNbCanceledTeamsRuns =
                totalNbCanceledTeamsRuns +
                format.runs.filter((run) => run.status.id === 'canceled').length
            if (format.runs.length > 0) {
                format.runs = SortRunsByGroupAndDepartureTime(format.runs)
                format.firstRunHour = formatTimeLocal(
                    format.runs[0].departureTime
                )
                if (format.runs.length >= 1) {
                    format.lastRunHour = formatTimeLocal(
                        format.runs[format.runs.length - 1].arrivingTime
                    )
                }

                let runsGroup = format.runs.filter((run) => run.departureOrder)
                format.runs = format.runs.filter((x) => !runsGroup.includes(x))

                const runsByGroup = []
                let i = 0
                while (runsGroup.length > 0 && i < 20) {
                    if (runsGroup[0].departureOrder === 1) {
                        const childRuns = runsGroup.filter(
                            (r) => r.parent && r.parent.id === runsGroup[0].id
                        )
                        const newGroup = [runsGroup[0], ...childRuns]
                        runsByGroup.push(newGroup)

                        runsGroup = runsGroup.filter(
                            (x) => !newGroup.includes(x)
                        )
                    }
                    i = i + 1
                }
                format.runs = [...format.runs, ...runsByGroup]
                format.runs = formatRuns(format.runs, team, dashboard)
            }
            return format
        })

    dispatch({
        type: ACTIONS.SET_NB_TEAMS_RUNS_AND_CANCELED_TEAMS_RUNS,
        payload: {
            nbTeamsRuns: totalNbTeamsRuns,
            nbCanceledTeamsRuns: totalNbCanceledTeamsRuns,
        },
    })

    return teams
}

export const formatRuns = (runs, team, dashboard, partner = false) => {
    let totalCardsWidth = 0

    let formatedRuns = runs.map((run, index) => {
        if (!Array.isArray(run)) {
            //run non groupé
            const positions = calculateCardPositions(
                new Date(
                    removeTimeZoneToDate(
                        dashboard.showEffectiveHours &&
                            run.effectiveDepartureTime
                            ? run.effectiveDepartureTime
                            : run.departureTime
                    )
                ),
                new Date(
                    removeTimeZoneToDate(
                        dashboard.showEffectiveHours &&
                            run.effectiveArrivingTime
                            ? run.effectiveArrivingTime
                            : run.arrivingTime
                    )
                ),
                dashboard,
                totalCardsWidth
            )
            // ne pas déduire de width pour les runs non assignés
            totalCardsWidth = totalCardsWidth + positions.width
            return formatRun(run, team, positions)
        } else {
            //run est un tableau de runs groupés
            const parentRun = run.find((r) => r.departureOrder === 1)
            const groupedRunsSortByDepartureTime = [
                ...run.sort(
                    (a, b) =>
                        new Date(
                            removeTimeZoneToDate(
                                dashboard.showEffectiveHours &&
                                    a.effectiveDepartureTime
                                    ? a.effectiveDepartureTime
                                    : a.departureTime
                            )
                        ) -
                        new Date(
                            removeTimeZoneToDate(
                                dashboard.showEffectiveHours &&
                                    b.effectiveDepartureTime
                                    ? b.effectiveDepartureTime
                                    : b.departureTime
                            )
                        )
                ),
            ]
            const groupedRunsSortByArrivingTime = [
                ...run
                    .sort(
                        (a, b) =>
                            new Date(
                                removeTimeZoneToDate(
                                    dashboard.showEffectiveHours &&
                                        a.effectiveArrivingTime
                                        ? a.effectiveArrivingTime
                                        : a.arrivingTime
                                )
                            ) -
                            new Date(
                                removeTimeZoneToDate(
                                    dashboard.showEffectiveHours &&
                                        b.effectiveArrivingTime
                                        ? b.effectiveArrivingTime
                                        : b.arrivingTime
                                )
                            )
                    )
                    .reverse(),
            ]

            const positions = calculateCardPositions(
                new Date(
                    removeTimeZoneToDate(
                        dashboard.showEffectiveHours &&
                            groupedRunsSortByDepartureTime[0]
                                .effectiveDepartureTime
                            ? groupedRunsSortByDepartureTime[0]
                                  .effectiveDepartureTime
                            : groupedRunsSortByDepartureTime[0].departureTime
                    )
                ),
                new Date(
                    removeTimeZoneToDate(
                        dashboard.showEffectiveHours &&
                            groupedRunsSortByArrivingTime[0]
                                .effectiveArrivingTime
                            ? groupedRunsSortByArrivingTime[0]
                                  .effectiveArrivingTime
                            : groupedRunsSortByArrivingTime[0].arrivingTime
                    )
                ),
                dashboard,
                totalCardsWidth
            )
            // ne pas déduire de width pour les runs non assignés
            totalCardsWidth = totalCardsWidth + positions.width

            const groupedRun = {
                ...parentRun,
                departureTimeHour: formatTimeLocal(
                    groupedRunsSortByDepartureTime[0].departureTime
                ),
                arrivingTimeHour: formatTimeLocal(
                    dashboard.showEffectiveHours &&
                        groupedRunsSortByArrivingTime[0].effectiveArrivingTime
                        ? groupedRunsSortByArrivingTime[0].effectiveArrivingTime
                        : groupedRunsSortByArrivingTime[0].arrivingTime
                ),
                statusBorderColor: getBorderColor(parentRun.status.id),
                masterRun: team,
                left: positions.left,
                width: positions.width,
                zIndex: 0,
                groupedRun: true,
                groupedRuns: SortRunsByGroupAndDepartureTime(run).map((r) => {
                    return formatRun(r, team, positions)
                }),
            }

            if (
                dashboard.showEffectiveHours &&
                groupedRunsSortByDepartureTime[0].effectiveDepartureTime
            ) {
                groupedRun.effectiveDepartureTimeHour = formatTimeLocal(
                    groupedRunsSortByDepartureTime[0].effectiveDepartureTime
                )
            }

            if (
                dashboard.showEffectiveHours &&
                groupedRunsSortByArrivingTime[0].effectiveArrivingTime
            ) {
                groupedRun.effectiveArrivingTimeHour = formatTimeLocal(
                    groupedRunsSortByArrivingTime[0].effectiveArrivingTime
                )
            }
            return groupedRun
        }
    })

    if (team || partner) {
        let runsToSort = [...formatedRuns]
        runsToSort = runsToSort
            .sort((a, b) => {
                return a.width - b.width
            })
            .reverse()
            .map((r, index) => {
                return {
                    ...r,
                    zIndex: index + 1,
                }
            })

        formatedRuns = formatedRuns.map((r) => {
            return {
                ...r,
                zIndex: runsToSort.find((rts) => rts.id === r.id).zIndex,
            }
        })
    }

    return formatedRuns
}

const calculatePosition = (hours, minutes, dashboard) => {
    //padding correspondant au padding de la barre sous les heures
    const sizePerRange =
        dashboard.intervalSize.size * dashboard.intervalSize.range

    return (
        hours * sizePerRange + (sizePerRange / 60) * minutes + dashboard.padding
    )
}

const calculateCardPositions = (
    departureTime,
    arrivingTime,
    dashboard,
    totalCardsWidth
) => {
    const departureTimeHours = departureTime.getHours()
    const departureTimeMinutes = departureTime.getMinutes()

    const arrivingTimeHours = arrivingTime.getHours()
    const arrivingTimeMinutes = arrivingTime.getMinutes()

    const departurePosition = calculatePosition(
        departureTimeHours,
        departureTimeMinutes,
        dashboard
    )

    const arrivingPosition = calculatePosition(
        arrivingTimeHours,
        arrivingTimeMinutes,
        dashboard
    )

    //calculate width
    const cardWidth = arrivingPosition - departurePosition

    return {
        left: departurePosition - totalCardsWidth,
        width: cardWidth,
    }
}

const formatRun = (run, team, positions) => {
    return {
        ...run,
        patientName: `${run.patient.lastname} ${run.patient.firstname}`,
        patientAddress: run.pickUpLocation,
        departureTimeHour: formatTimeLocal(run.departureTime),
        arrivingTimeHour: formatTimeLocal(run.arrivingTime),
        effectiveDepartureTimeHour: run.effectiveDepartureTime
            ? formatTimeLocal(run.effectiveDepartureTime)
            : null,
        effectiveArrivingTimeHour: run.effectiveArrivingTime
            ? formatTimeLocal(run.effectiveArrivingTime)
            : null,
        pickUpAddress: run.pickUpLocation.street
            ? `${run.pickUpLocation.street}, ${run.pickUpLocation.city}`
            : run.pickUpLocation.city,
        depositAddress: run.depositLocation.street
            ? `${run.depositLocation.street}, ${run.depositLocation.city}`
            : run.depositLocation.city,
        statusBorderColor: getBorderColor(run.status.id),
        masterRun: team,
        left: positions.left,
        width: positions.width,
    }
}
