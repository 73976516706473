export const ACTIONS = {
    SET_VEHICLE: 'setVehicle',
    SET_LICENSE_PLATE: 'setLicensePlate',
    SET_TYPE: 'setType',
    SET_TYPES: 'setTypes',
    SET_ACTIVE: 'setActive',
    SET_LABEL: 'setLabel',
    SET_AVAILABLE_COLORS: 'setAvailableColors',
    SET_COLOR: 'setColor',
    SET_OPEN_COLOR_CHOICES: 'setOpenColorChoices',
    SET_LOADING: 'setLoading',
    SET_TECHNICAL_CONTROL: 'setTechnicalControl',
    SET_TAXIMETER_CONTROL: 'setTaximeterControl',
    SET_NUM_ADS: 'setNumAds',
    SET_END_DATE: 'setEndDate',
    SET_START_DATE: 'setStartDate',
    SET_OPEN_SELECT_START_DATE_PICKER: 'setOpenSelectStartDatePicker',
    SET_OPEN_SELECT_END_DATE_PICKER: 'setOpenSelectEndDatePicker',
    SET_CONVENTION_PROTECTION: 'setConventionProtection',
}

export const getInitialState = () => {
    return {
        id: null,
        licensePlate: '',
        type: null,
        types: [],
        active: true,
        label: '',
        availableColors: [],
        vehicleColor: '',
        openColorChoices: false,
        loading: true,
        manageTitle: "Ajout d'un nouveau véhicule",
        numAds: null,
        openSelectEndDatePicker: false,
        openSelectStartDatePicker: false,
        endConventionDate: new Date('2025-01-01'),
        startConventionDate: new Date('2022-01-01'),
        conventionProtection: false,
    }
}

export const VehicleReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_VEHICLE:
            return {
                ...state,
                licensePlate: action.payload.licensePlate,
                type: {
                    value: action.payload.type['@id'],
                    label: action.payload.type.label,
                },
                active: action.payload.active,
                label: action.payload.label,
                color: action.payload.color,
                numAds: action.payload.numAds,
                loading: false,
                manageTitle: `Modification du véhicule ${
                    action.payload.label
                        ? action.payload.label
                        : action.payload.licensePlate
                }`,
                conventionProtection: !!action.payload.conventionDateFrom,
                startConventionDate: action.payload.conventionDateFrom
                    ? new Date(action.payload.conventionDateFrom)
                    : new Date(),
                endConventionDate: action.payload.conventionDateTo
                    ? new Date(action.payload.conventionDateTo)
                    : new Date(),
            }
        case ACTIONS.SET_LICENSE_PLATE:
            return {
                ...state,
                licensePlate: action.payload,
            }
        case ACTIONS.SET_TYPE:
            return {
                ...state,
                type: action.payload,
                taxiMeterControl: null,
            }
        case ACTIONS.SET_TYPES:
            return {
                ...state,
                types: action.payload,
            }
        case ACTIONS.SET_ACTIVE:
            return {
                ...state,
                active: action.payload,
            }
        case ACTIONS.SET_LABEL:
            return {
                ...state,
                label: action.payload,
            }
        case ACTIONS.SET_AVAILABLE_COLORS:
            return {
                ...state,
                availableColors: action.payload,
            }
        case ACTIONS.SET_COLOR:
            return {
                ...state,
                color: action.payload,
                openColorChoices: false,
            }
        case ACTIONS.SET_OPEN_COLOR_CHOICES:
            return {
                ...state,
                openColorChoices: action.payload,
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case ACTIONS.SET_NUM_ADS:
            return {
                ...state,
                numAds: action.payload,
            }
        case ACTIONS.SET_END_DATE:
            return {
                ...state,
                endConventionDate: action.payload,
                openSelectEndDatePicker: false,
            }
        case ACTIONS.SET_START_DATE:
            return {
                ...state,
                startConventionDate: action.payload,
                openSelectStartDatePicker: false,
            }

        case ACTIONS.SET_OPEN_SELECT_START_DATE_PICKER:
            return {
                ...state,
                openSelectStartDatePicker: action.payload,
            }
        case ACTIONS.SET_OPEN_SELECT_END_DATE_PICKER:
            return {
                ...state,
                openSelectEndDatePicker: action.payload,
            }
        case ACTIONS.SET_CONVENTION_PROTECTION:
            return {
                ...state,
                conventionProtection: action.payload,
            }

        default:
            throw new Error('Action non reconnue')
    }
}
