// SupplementSection.jsx
import React from 'react'

import { Plus, Trash } from 'iconoir-react'
import ClassicInputV2 from '../../../../Commons/Inputs/ClassicInputV2'

import { formatPrice } from '../../../../../tools/Utility'
import PrimaryButton from '../../../../Commons/Buttons/PrimaryButton'
import ClassicSwitch from '../../../../Commons/Inputs/ClassicSwitch'
import { RUN_INVOICE_FORM_DETAILS_ACTIONS } from './RunInvoiceFormDetailsReducer'

const SupplementSection = ({ dispatch, invoiceCpamForm }) => {
    return (
        <>
            <div>
                <PrimaryButton
                    label="Ajouter un supplément"
                    title="tarif"
                    action={() => {
                        dispatch({
                            type: RUN_INVOICE_FORM_DETAILS_ACTIONS.ADD_SUPPLEMENT_LINE,
                        })
                    }}
                    icon={<Plus className="text-xl" />}
                />
            </div>
            {invoiceCpamForm.runInvoiceSupplements?.map((sup, index) => (
                <div key={index} className="col-span-2 flex w-full py-4">
                    <div className={'flex flex-col space-y-2'}>
                        <div className={'flex w-full space-x-4'}>
                            <ClassicInputV2
                                label="Détail du supplément"
                                type="text"
                                value={sup.label}
                                setValue={(e) =>
                                    dispatch({
                                        type: RUN_INVOICE_FORM_DETAILS_ACTIONS.UPDATE_SUPPLEMENT_LINE,
                                        payload: {
                                            ...sup,
                                            label: e.target.value,
                                        },
                                    })
                                }
                                placeholder="..."
                                id="kilometer"
                                showLabel={true}
                            />
                            <ClassicInputV2
                                label="Montant du supplément"
                                type="text"
                                value={sup.price}
                                setValue={(e) =>
                                    dispatch({
                                        type: RUN_INVOICE_FORM_DETAILS_ACTIONS.UPDATE_SUPPLEMENT_LINE,
                                        payload: {
                                            ...sup,
                                            price: e.target.value,
                                        },
                                    })
                                }
                                placeholder="..."
                                id="kilometer"
                                showLabel={true}
                            />
                        </div>
                        <div>
                            <ClassicSwitch
                                label={'Inclus dans la remise'}
                                value={sup.isDiscounted}
                                setValue={() =>
                                    dispatch({
                                        type: RUN_INVOICE_FORM_DETAILS_ACTIONS.UPDATE_SUPPLEMENT_LINE,
                                        payload: {
                                            ...sup,
                                            isDiscounted: !sup.isDiscounted,
                                        },
                                    })
                                }
                            />
                        </div>
                    </div>
                    <button
                        className={`ml-auto`}
                        onClick={() => {
                            dispatch({
                                type: RUN_INVOICE_FORM_DETAILS_ACTIONS.REMOVE_SUPPLEMENT_LINE,
                                payload: sup.index,
                            })
                        }}
                    >
                        <div className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-700">
                            <Trash />
                        </div>
                    </button>
                </div>
            ))}
        </>
    )
}

export default SupplementSection
