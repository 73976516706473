export const VehicleColors = [
    '#1C1C1C',
    '#641C34',
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#734222',
    '#2196F3',
    '#00BCD4',
    '#9C9C9C',
    '#009688',
    '#A98307',
    '#4CAF50',
    '#587246',
    '#6A5D4D',
    '#49678D',
    '#E6D690',
    '#FFEB3B',
    '#FF9800',
    '#8B8C7A',
    '#606E8C',
]

export default VehicleColors
