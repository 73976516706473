import React, { useState } from 'react'
import CalendarDatePicker from '../Commons/CalendarDatePickers/CalendarDatePicker'

const ScheduleRunsFilter = ({
    onlyUserRuns,
    setOnlyUserRuns,
    date,
    setDate,
    authorizedShowAllRuns,
    setShowUnassignedRuns,
    showUnassignedRuns,
    authorizedShowUnassignedRuns,
}) => {
    const handleDate = (date) => {
        setDate(date)
    }
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)

    return (
        <div className="px-2 pt-3">
            <div className="flex  items-center justify-between pb-5">
                <div className="flex flex-col items-center justify-center">
                    <CalendarDatePicker
                        openSelectDatePicker={openSelectDatePicker}
                        setOpenSelectDatePicker={setOpenSelectDatePicker}
                        date={date}
                        setDate={(date) => handleDate(date)}
                        customDisplay="dddd D MMMM"
                        fontSize="text-lg"
                    />
                </div>
                {authorizedShowUnassignedRuns || authorizedShowAllRuns ? (
                    <div className="flex flex-col items-center  text-gray-600 ">
                        <label className="pb-1 font-semibold" htmlFor="switch ">
                            {authorizedShowAllRuns
                                ? 'Tous les transports'
                                : 'Non-assignées'}
                        </label>
                        <div className="flex justify-end px-2">
                            <div
                                id="switch"
                                className={`flex w-12 cursor-pointer rounded-full border ${
                                    !onlyUserRuns || showUnassignedRuns
                                        ? 'bg-color-light-dark-green justify-end border-light-dark-green'
                                        : 'border-grey justify-start bg-gray-200'
                                }`}
                                onClick={() => {
                                    authorizedShowAllRuns
                                        ? setOnlyUserRuns(!onlyUserRuns)
                                        : setShowUnassignedRuns(
                                              !showUnassignedRuns
                                          )
                                }}
                            >
                                <span className="shadow-inner h-6 w-6 rounded-full bg-white shadow" />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default ScheduleRunsFilter
