import React, { useState } from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import { Link, useNavigate } from 'react-router-dom'
import CalendarDatePicker from '../../Commons/CalendarDatePickers/CalendarDatePicker'
import { DragHandGesture, Plus, Search } from 'iconoir-react'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import InputWithLoader from '../../Commons/Inputs/InputWithLoader'
import { ACTIONS } from '../../../services/Reducers/TeamsReducer'

const TeamFilter = ({ dateFilter, setDateFilter, teams, dispatch }) => {
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)

    let history = useNavigate()

    const handleSearch = (value) => {
        dispatch({ type: ACTIONS.SET_SEARCH, payload: value })
    }

    return (
        <div className="grid grid-cols-2 justify-between gap-4 px-2 pb-2 pt-2 lg:grid-cols-3">
            <div className="col-span-1 flex">
                {!teams.defaultTeamsView && (
                    <div className="flex items-center">
                        <CalendarDatePicker
                            openSelectDatePicker={openSelectDatePicker}
                            setOpenSelectDatePicker={setOpenSelectDatePicker}
                            date={dateFilter}
                            fontSize="text-lg lg:text-base"
                            setDate={setDateFilter}
                        />
                    </div>
                )}
            </div>
            <div className="col-span-1 flex items-center justify-center">
                <InputWithLoader
                    value={teams.search}
                    onChange={(e) => {
                        handleSearch(e.target.value)
                    }}
                    icon={<Search />}
                    refreshing={teams.refreshingSearchInput}
                    placeholder="Rechercher"
                />
            </div>
            <div className="col-span-1 flex justify-center lg:col-start-auto lg:justify-end ">
                <PrimaryButton
                    label={
                        teams.defaultTeamsView
                            ? 'Nouvelle équipe par défaut'
                            : 'Nouvelle équipe'
                    }
                    title={
                        teams.defaultTeamsView
                            ? 'Nouvelle équipe par défaut'
                            : 'Nouvelle équipe'
                    }
                    action={() =>
                        dispatch({
                            type: ACTIONS.SET_IS_OPENED_MANAGE,
                            payload: true,
                        })
                    }
                    icon={<Plus className="text-xl" />}
                    hiddenLabelOnMobile={true}
                />
            </div>
        </div>
    )
}

export default TeamFilter
