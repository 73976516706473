export const RunObjectColors = {
    blue: 'bg-blue-200',
    red: 'bg-red-200',
    green: 'bg-green-200',
    yellow: 'bg-yellow-200',
    purple: 'bg-purple-200',
    violet: 'bg-violet-200',
    pink: 'bg-pink-200',
    indigo: 'bg-indigo-200',
    teal: 'bg-teal-200',
    cyan: 'bg-cyan-200',
    gray: 'bg-gray-200',
    orange: 'bg-orange-200',
    white: 'bg-white',
}
