import React from 'react'
import WelcomeTemplate from './WelcomeTemplate'
import { ReactComponent as User } from '../../../files/user.svg'

const WelcomeUsers = ({ welcome, nextStep, previousStep }) => {
    const content = () => {
        return (
            <div className="flex h-full flex-col space-y-4">
                <div>
                    Il y a deux types d'utilisateurs dans l'applications. Les
                    administrateurs et les chauffeurs.
                </div>
                <div className="font-bold">Administrateurs</div>
                <div>
                    Les administrateurs ont accès à toute l'application. De la
                    régulation à la facturation en passant également par
                    visualisation des courses en mode chauffeur.
                </div>
                <div className="font-bold">Chauffeurs</div>
                <div>
                    Les chauffeurs, ont un accès limité à l'application. Ils
                    peuvent accéder à leurs plannings sur un espace dédié. Ils
                    peuvent consulter les courses qu'ils ont à faire et faire
                    des retours au(x) régulateur(s). Retour qui sont ensuite
                    visibles dans l'espace de régulation.
                </div>
                <div className="font-bold">
                    La connexion des différents utilisateurs
                </div>
                <div>
                    Lors de sa première connexion à l'application, un nouvel
                    utilisateur doit cliquer sur "première connexion" sur la
                    page de connexion. Il doit ensuite saisir son identifiant
                    (numéro de téléphone ou email) et choisir un mot de passe.
                    Une fois cette étape complétée, l'utilisateur peut utiliser
                    pleinement l'application, dans les limites de son rôle.
                </div>

                <div className="flex justify-center">
                    <User />
                </div>
                <div>
                    Vous êtes maintenant prêt pour ajouter votre premier
                    utilisateur. Cela se passe sur le prochain écran.
                </div>
            </div>
        )
    }
    return (
        <WelcomeTemplate
            nextStep={nextStep}
            previousStep={previousStep}
            content={content()}
            title="Utilisateurs"
            label="Ajouter mon premier utilisateur"
        />
    )
}

export default WelcomeUsers
