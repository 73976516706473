import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import VehicleManage from './VehicleManage'
import Vehicle from './Vehicle'
import ClassicFilter from '../../Commons/ClassicFilter'

const VehicleContainer = (props) => {
    const [search, setSearch] = useState('')

    return (
        <Routes>
            <Route path=":id" element={<VehicleManage fromWelcome={false} />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <ClassicFilter
                            {...props}
                            search={search}
                            setSearch={setSearch}
                            icon={'fa-ambulance'}
                            title={'Nouveau véhicule'}
                            link={'/ressources/vehicules'}
                        />
                        <Vehicle
                            {...props}
                            search={search}
                            setSearch={setSearch}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default VehicleContainer
