import SecondaryButton from '../Commons/Buttons/SecondaryButton'
import DeleteButton from '../Commons/Buttons/DeleteButton'
import React from 'react'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'

const CompetitionModal = ({ handleRefresh }) => {
    return (
        <div className="z-1000 fixed left-0 bottom-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="min-h-action-modal mx-2 flex flex-col items-center rounded-lg bg-white px-2 py-3 text-lg sm:mx-0 sm:w-2/3 lg:w-1/3 lg:text-base">
                <div className="flex h-full w-full flex-col p-2 text-xl">
                    <div className="flex justify-between font-bold text-gray-700">
                        <div>Rechargement nécessaire</div>
                    </div>
                </div>
                <div className="my-auto flex w-full flex-col p-2">
                    <p>
                        Une autre personne a déjà mis à jour ces données.
                        Veuillez recharger la page avant d'effectuer de
                        nouvelles modifications.
                    </p>
                </div>
                <div className="mr-auto flex w-full justify-end pr-4">
                    <PrimaryButton
                        title="Rafraîchir"
                        label="Rafraîchir"
                        action={handleRefresh}
                        hiddenLabelOnMobile={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default CompetitionModal
