import dayjs from 'dayjs'
import {
    checkIfTeamRunOnExactSameTimes,
    verifyKeyType1And2,
} from '../../../../tools/Utility'
import {
    getDateFromDateReducer,
    hasOneDateParts,
    isDateValid,
} from '../../../../services/DateService'
import { MODAL_FORM_ACTIONS } from '../Reducers/ModalReducer'
import { toast } from 'react-toastify'

const checkErrors = (
    run,
    pickUpAddress,
    depositAddress,
    prsDateInput,
    prescription,
    dateMaternity,
    workAccidentDate,
    priorApprovalDate
) => {
    run.pickUpLocation = pickUpAddress
    run.depositLocation = depositAddress
    let printedErrors = []
    let arriving = parseFloat(dayjs(run.arrivingTime).format('HH.mm'))
    let departure = parseFloat(dayjs(run.departureTime).format('HH.mm'))
    if (arriving <= departure) {
        printedErrors.push({
            id: 'sameTimes',
            message:
                "Veuillez renseigner un horaire d'arrivée supérieur à l'horaire de départ",
        })
    }

    if (run.runType.value === 4 && !run.isReturnPath) {
        //  Permet de ne pas vérifier les horaires si c'est un AR qui n'ont pas la même date
        if (
            run.linkedRun &&
            !dayjs(run.linkedRun.date).isSame(dayjs(run.date))
        ) {
        } else if (run.runType.value === 4 && !run.isReturnPath) {
            checkReturnTime(run, arriving, departure, printedErrors)
        }
    }

    if (run.runType.value === 3 && !run.isReturnPath) {
        if (run.waitingTime === null || run.waitingTime === 0) {
            printedErrors.push({
                id: 'returnTime',
                message: "Veuillez renseigner temps d'attente",
            })
        }
    }
    if (!run.pickUpLocation.city) {
        printedErrors.push({
            id: 'pickUpLocation',
            message: 'Veuillez renseigner une ville de départ',
        })
    }
    if (!run.depositLocation.city) {
        printedErrors.push({
            id: 'depositLocation',
            message: 'Veuillez renseigner une ville de destination.',
        })
    }
    if (!run.patient) {
        printedErrors.push({
            id: 'patient',
            message: 'Veuillez renseigner une personne à transporter.',
        })
    }

    if (run.showSharingOptions && !run.partner) {
        printedErrors.push({
            id: 'partner',
            message:
                "Veuillez renseigner un partenaire ou désactiver l'option de partage.",
        })
    }
    if (run.showSharingOptions && run.partner) {
        if (!run.partner.email && run.sharingOption.value === 'mail') {
            printedErrors.push({
                id: 'no mail',
                message: 'Le partenaire sélectionné n’a pas d’adresse mail.',
            })
        }
    }

    if (run.team && run.teams && run.teams.length) {
        let teamRuns = []
        if (run.team.runs) {
            teamRuns = run.team.runs
        } else {
            const foundTeam = run.teams.find((t) => t['@id'] === run.team.value)
            if (foundTeam && foundTeam.runs) {
                teamRuns = foundTeam.runs
            }
        }

        if (teamRuns.length) {
            if (
                checkIfTeamRunOnExactSameTimes(
                    teamRuns,
                    run.id,
                    run.departureTime,
                    run.arrivingTime
                )
            ) {
                printedErrors.push({
                    id: 'EquipeAttribuee',
                    message:
                        'Un transport avec exactement les mêmes horaires est déjà attribué à cette équipe.',
                })
            }
        }
    }

    const runDate = run.date
    if (hasOneDateParts(prsDateInput)) {
        if (!isDateValid(prsDateInput, true)) {
            printedErrors.push({
                id: 'Prescription',
                message: 'La date de prescription est incorrecte',
            })
        } else if (getDateFromDateReducer(prsDateInput) > runDate) {
            printedErrors.push({
                id: 'Prescription',
                message:
                    'La date de prescription ne peut pas être supérieur à la date du transport',
            })
        }
    }
    if (prescription.natureAssurance?.value === '41') {
        if (hasOneDateParts(workAccidentDate)) {
            if (!isDateValid(workAccidentDate, true)) {
                printedErrors.push({
                    id: 'WorkAccident',
                    message: 'La date de l’accident de travail est incorrecte',
                })
            } else if (getDateFromDateReducer(workAccidentDate) > runDate) {
                printedErrors.push({
                    id: 'WorkAccident',
                    message:
                        'La date de l’accident de travail ne peut pas être supérieur à la date du transport',
                })
            }
        }
        if (prescription.workAccidentNumber) {
            if (!verifyKeyType1And2(prescription.workAccidentNumber)) {
                printedErrors.push({
                    id: 'WorkAccidentNumber',
                    message: "Le numéro d'accident est incorrect",
                })
            }
        }
        if (
            !isDateValid(workAccidentDate) &&
            !prescription.workAccidentNumber
        ) {
            printedErrors.push({
                id: 'WorkAccidentBoth',
                message:
                    'Veuillez renseigner une date ou un numéro d’accident de travail',
            })
        }
    }
    if (prescription.natureAssurance?.value === '30') {
        if (hasOneDateParts(dateMaternity)) {
            if (!isDateValid(dateMaternity, true)) {
                printedErrors.push({
                    id: 'Maternity',
                    message: 'La date de la grossesse est incorrecte',
                })
            } else if (getDateFromDateReducer(dateMaternity) > runDate) {
                printedErrors.push({
                    id: 'Maternity',
                    message:
                        'La date de la grossesse ne peut pas être supérieur à la date du transport',
                })
            }
        } else {
            printedErrors.push({
                id: 'Maternity',
                message: 'Veuillez renseigner une date de grossesse',
            })
        }
    }

    if (prescription.isPriorApproval) {
        if (hasOneDateParts(priorApprovalDate)) {
            if (!isDateValid(priorApprovalDate, true)) {
                printedErrors.push({
                    id: 'PriorApproval',
                    message: 'La date de l’accord préalable est incorrecte',
                })
            } else if (getDateFromDateReducer(priorApprovalDate) > runDate) {
                printedErrors.push({
                    id: 'PriorApproval',
                    message:
                        'La date de l’accord préalable ne peut pas être supérieur à la date du transport',
                })
            }
        } else {
            printedErrors.push({
                id: 'PriorApproval',
                message: 'Veuillez renseigner une date d’accord préalable',
            })
        }
    }

    if (run.signature) {
        if (!run.signatureDate || !run.signatureLocation) {
            printedErrors.push({
                id: 'signature',
                message:
                    'Veuillez renseigner la date et le lieu de la signature',
            })
        }
    }

    if (printedErrors.length === 0) {
        return false
    } else {
        printedErrors.map((error) => {
            toast.error(error.message)
        })
        return true
    }
}

export const handleValidations = (
    run,
    globalDispatch,
    pickUpAddress,
    depositAddress,
    prsDateInput,
    id,
    prescription,
    dateMaternity,
    workAccidentDate,
    priorApprovalDate
) => {
    if (
        !checkErrors(
            run,
            pickUpAddress,
            depositAddress,
            prsDateInput,
            prescription,
            dateMaternity,
            workAccidentDate,
            priorApprovalDate
        )
    ) {
        let action = {}
        if (run.status === 'closed') {
            action = {
                type: MODAL_FORM_ACTIONS.OPEN_MODAL,
                payload: {
                    labelAction: 'Modifier le transport',
                    actionButton: 'Modifier',
                    label: 'Êtes-vous sûr de vouloir modifier ce transport déjà facturée ?',
                },
            }
        } else if (
            (id === '0' && !run.timesChanged) ||
            (id === undefined && !run.timesChanged)
        ) {
            action = {
                type: MODAL_FORM_ACTIONS.OPEN_MODAL,
                payload: {
                    labelAction: 'Vérifiez les horaires du transport',
                    actionButton: 'Créer le transport',
                    label: "Vous n'avez pas modifié les horaires du transport, Êtes-vous sûr de vouloir la créer avec les horaires prédéfinis ?",
                },
            }
        } else if (
            run.id &&
            run.runType !== run.oldRunType &&
            run.runType.value === 1 &&
            (run.oldRunType.value === 3 || run.oldRunType.value === 4)
        ) {
            //run type as changed from AR to A
            action = {
                type: MODAL_FORM_ACTIONS.OPEN_MODAL_AR_TO_A,
                payload: {
                    labelAction: 'Changement du type de transport',
                    actionButton: 'Supprimer le retour',
                    label: 'Souhaitez-vous supprimer le retour ou le laisser en temps que retour simple ?',
                },
            }
        } else if (
            run.id &&
            run.runType !== run.oldRunType &&
            run.runType.value === 2 &&
            (run.oldRunType.value === 3 || run.oldRunType.value === 4)
        ) {
            //run type as changed from AR to R
            action = {
                type: MODAL_FORM_ACTIONS.OPEN_MODAL,
                payload: {
                    labelAction: 'Changement du type de transport',
                    actionButton: 'Confirmer',
                    label: 'En changeant un A/R en retour simple, cela entraîne la suppression du retour initial.',
                },
            }
            // check si l'objet action est vide sinon envoie le form
        }
        if (action.type !== undefined) {
            globalDispatch(action)
            return true
        } else {
            return false
        }
    } else {
        return true
    }
}

function checkReturnTime(run, arriving, departure, printedErrors) {
    let returnTime = parseFloat(dayjs(run.returnTime).format('HH.mm'))
    if (arriving >= returnTime || departure >= returnTime) {
        printedErrors.push({
            id: 'sameTimes return arriving',
            message:
                "Veuillez renseigner un horaire de retour supérieur à l'horaire d'arrivée.",
        })
    }
}
