import React, { useRef, useState } from 'react'
import SecondaryButton from '../Buttons/SecondaryButton'
import { ZoomIn } from 'iconoir-react'
import PrimaryButton from '../Buttons/PrimaryButton'
import { ACTIONS } from '../../../services/Reducers/PlanningReducer'

const SelectDropDownListAddButtons = ({ label, dispatch, icon, title }) => {
    const [open, setOpen] = useState(false)
    const dropdownMenuRef = useRef(label)

    return (
        <div className="group relative flex w-full" tabIndex="-1">
            <PrimaryButton
                action={() => {
                    setOpen(true)
                }}
                label={label}
                hiddenLabelOnMobile={true}
                icon={icon}
                title={title}
            />
            {open && (
                <div className="invisible origin-top-right -translate-y-2 scale-95 transform pt-10 text-white opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100">
                    <div
                        ref={dropdownMenuRef}
                        className="width-add-drop-down absolute right-0 z-20 mt-1 flex min-h-full flex-col rounded-lg border border-secondary-color bg-secondary-color py-1 text-base font-bold"
                    >
                        <button
                            className="h-10 text-white hover:bg-primary-color"
                            onClick={() => {
                                setOpen(false)
                                dispatch({
                                    type: ACTIONS.ADD_TRANSPORT_BUTTON_CLICKED,
                                })
                            }}
                            title="Transport"
                        >
                            Transport
                        </button>
                        <button
                            className="h-10 text-white hover:bg-primary-color"
                            onClick={() => {
                                setOpen(false)
                                dispatch({
                                    type: ACTIONS.ADD_SERIES_BUTTON_CLICKED,
                                })
                            }}
                            title="Série"
                        >
                            Série
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SelectDropDownListAddButtons
