import React from 'react'
import { useNavigate } from 'react-router-dom'
import Cancel from '../../../Commons/Cancel'
import Success from '../../../Commons/Success'

const SubscriptionCancel = () => {
    let history = useNavigate()

    const goBack = () => {
        history('/parametres/abonnement')
    }

    return <Cancel goBack={goBack} labelButton="Retour" />
}

export default SubscriptionCancel
