import { apiClient, withErrorHandling } from '../apiClient'

export const Signature = {
    saveSignature: withErrorHandling(async (params) => {
        const { runId, data } = params
        if (data.signatureId) {
            return apiClient.put(
                `/signatures/${data.signatureId}/update_signature`,
                data
            )
        } else {
            return apiClient.post(`/signatures/${runId}/save_signature`, data)
        }
    }),

    getSignature: withErrorHandling(async (signatureId) => {
        return apiClient.get(`/signatures/${signatureId}/get_signature`)
    }),

    deleteSignature: withErrorHandling(async (signatureId) => {
        return apiClient.delete(`/signatures/${signatureId}/delete_signature`)
    }),
}
