import React from 'react'

const Patient = ({ row, isComputer }) => {
    return (
        <div className={`${isComputer ? 'w-1/4' : 'w-1/2 pt-2'} truncate`}>
            <div className="truncate font-semibold text-sm ">
                {row.fullname}
            </div>
            <div className="text-sm font-light truncate">{row.address}</div>
        </div>
    )
}

export default Patient
