export const Documents = [
    {
        value: 'Aucun',
        label: 'Aucun',
        ['@id']: '/documents/Aucun',
    },
    {
        value: 'Annexe',
        label: 'Annexe',
        ['@id']: '/documents/Annexe',
    },
    {
        value: 'Bon de transport',
        label: 'Bon de transport',
        ['@id']: '/documents/Bon de transport',
    },
    {
        value: 'Complet',
        label: 'Complet',
        ['@id']: '/documents/Complet',
    },
]
