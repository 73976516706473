import React from 'react'

const MobilePagination = ({ title, page, setPage, totalItems, lastPage }) => {
    const handleMobilePageClick = () => {
        setPage(page + 1)
    }

    const handlePreviousMobilePageClick = () => {
        if (page === 1) {
            setPage(1)
        }
        setPage(page - 1)
    }
    return (
        <div className="mt-auto flex w-full items-center justify-between px-2 py-5 pb-5 lg:hidden">
            <div
                className={` ${
                    page <= 1
                        ? 'hidden'
                        : 'w-30 cursor-pointer rounded-lg border border-light-dark-green px-4 py-2 text-xl text-light-dark-green'
                } `}
                onClick={handlePreviousMobilePageClick}
            >
                <i className="fa fa-chevron-left" />
            </div>
            <div className="lg:hidden">
                <span>
                    Total : {totalItems} {title}
                </span>
            </div>
            <div
                className={` ${
                    lastPage === page
                        ? 'hidden'
                        : 'w-30 cursor-pointer rounded-lg border border-light-dark-green px-4 py-2 text-xl text-light-dark-green'
                } `}
                onClick={handleMobilePageClick}
            >
                <i className="fa fa-chevron-right " />
            </div>
        </div>
    )
}

export default MobilePagination
