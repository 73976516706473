import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ClassicInput from './Inputs/ClassicInput'
import PrimaryButton from './Buttons/PrimaryButton'
import { Plus } from 'iconoir-react'

const ClassicFilter = ({ search, setSearch, setPage, title, icon, link }) => {
    let history = useNavigate()

    return (
        <div className="flex w-full justify-between px-2 pb-2 pt-2 text-gray-800 ">
            <div className="flex items-center  pr-2">
                <i className="fa fa-search pr-2" />
                <ClassicInput
                    label="Rechercher"
                    value={search}
                    setValue={(e) => {
                        setSearch(e.target.value)
                        {
                            setPage && setPage(1)
                        }
                    }}
                    id="search"
                    placeholder="Rechercher..."
                />
            </div>
            <div className="flex pr-2">
                <PrimaryButton
                    label={title}
                    title={title}
                    action={() => history(`${link}/0`)}
                    icon={<Plus className="text-xl" />}
                    hiddenLabelOnMobile={true}
                />
            </div>
        </div>
    )
}

export default ClassicFilter
