import React from 'react'
import { formatTimeLocal } from '../../../../services/dateUtils'

const TimesAndTimeValidation = ({ row, isComputer }) => {
    return (
        <div
            className={`flex w-1/6 items-center justify-between align-middle ${
                isComputer ? 'lg:px-2 lg:text-lg' : 'pt-2 text-base '
            } ${row.approximateTime && row.isReturnPath && 'italic'}`}
        >
            {row.status.id === 'done' ? (
                <div
                    className={
                        row.status.id === 'done'
                            ? 'flex w-full items-center justify-center text-center font-semibold'
                            : ''
                    }
                >
                    {formatTimeLocal(row.updateStatusAt)}
                    {row.userComments && row.isUserCommentsRed ? (
                        <i
                            className={`fas fa-comment-slash pl-2 ${
                                isComputer ? 'block' : 'hidden'
                            }`}
                        />
                    ) : row.userComments && !row.isUserCommentsRed ? (
                        <i
                            className={`far fa-comment pl-2 ${
                                isComputer ? 'block' : 'hidden'
                            }`}
                        />
                    ) : (
                        <span className="w-6 sm:hidden" />
                    )}
                </div>
            ) : (
                <div
                    className={`flex ${
                        isComputer ? 'flex-row' : 'flex-col'
                    } w-full justify-center`}
                >
                    <div className="flex justify-center">
                        {formatTimeLocal(row.departureTime)}
                    </div>
                    <div className={`${isComputer ? 'block' : 'hidden'}`}>
                        -
                    </div>
                    <div className="flex justify-center">
                        {formatTimeLocal(row.arrivingTime)}
                    </div>
                    <div className="flex items-center">
                        {row.userComments && row.isUserCommentsRed ? (
                            <i
                                className={`fas fa-comment-slash pl-2 ${
                                    isComputer ? 'block' : 'hidden'
                                }`}
                            />
                        ) : row.userComments && !row.isUserCommentsRed ? (
                            <i
                                className={`far fa-comment pl-2 ${
                                    isComputer ? 'block' : 'hidden'
                                }`}
                            />
                        ) : (
                            <span
                                className={`w-6 ${!isComputer && 'hidden'}`}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default TimesAndTimeValidation
