import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useStickyDateState } from '../../../hooks/LocalStorageDatesHooks'
import { SERIES_ACTIONS } from '../../../services/Reducers/SeriesReducer'
import SeriesForm from './Manage/Forms/SeriesForm'
import SeriesList from './SeriesList'
import { Series } from '../../../services/API/Entities/series'
import { useQuery } from '@tanstack/react-query'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'
import NoData from '../NoData'

const SeriesContainer = (props) => {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)
    const [archived, setArchived] = useState(false)
    const [dateFrom, setDateFrom] = useStickyDateState(new Date(), 'storedDate')

    const {
        data: series = [],
        isLoading: isLoading,
        isError,
    } = useQuery({
        queryKey: ['series', 'archived', search, archived],
        queryFn: () => Series.fetchAll(search, archived),
        keepPreviousData: true,
        staleTime: 30000,
    })

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return (
            <NoData message="Une erreur s'est produite lors du chargement des séries." />
        )
    }

    if (series.length === 0) return <NoData message="Aucune série disponible" />

    return (
        <div className="h-full w-full">
            <Routes>
                <Route
                    path="/"
                    element={
                        <SeriesList
                            {...props}
                            search={search}
                            setPage={setPage}
                            page={page}
                            archived={archived}
                            setArchived={setArchived}
                            rows={series}
                        />
                    }
                />
                <Route
                    path=":id"
                    element={
                        <SeriesForm date={dateFrom} ACTIONS={SERIES_ACTIONS} />
                    }
                />
            </Routes>
        </div>
    )
}

export default SeriesContainer
