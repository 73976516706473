import React from 'react'
import { HelpCircle } from 'iconoir-react'

const StyledTextarea = ({
    label = '',
    id = '',
    required = false,
    helpText = '',
    disabled = false,
    rows = 5,
    placeholder = '',
    value = '',
    onChange,
    className = '',
    ...props
}) => {
    return (
        <div>
            <label
                htmlFor={id}
                className="block text-sm font-medium text-gray-700"
            >
                {label} {required && <span className="text-red-500">*</span>}
                {helpText && (
                    <HelpCircle
                        className="ml-1 inline-block text-gray-400"
                        size={16}
                    />
                )}
            </label>
            <textarea
                id={id}
                name={id}
                rows={rows}
                className={`mt-1 block w-full resize-none rounded-md border px-3 py-2 shadow-sm hover:border-secondary-color focus:outline-none ${
                    disabled
                        ? 'cursor-not-allowed border-gray-300 bg-gray-100 text-gray-500'
                        : 'border-gray-300 focus:border-secondary-color focus:ring-secondary-color'
                } ${className}`}
                placeholder={placeholder}
                required={required}
                disabled={disabled}
                value={value}
                onChange={onChange}
                {...props}
            />
        </div>
    )
}

export default StyledTextarea
