import React from 'react'
import WelcomeTemplate from './WelcomeTemplate'
import { ReactComponent as Vehicle } from '../../../files/vehicle.svg'

const WelcomeVehicles = ({ welcome, nextStep, previousStep }) => {
    const content = () => {
        return (
            <div className="h-full flex flex-col space-y-4">
                <div>
                    Vos transports sont attribués à des équipes composées d'un
                    véhicule et d'un ou deux chauffeurs.
                </div>

                <div>
                    Un véhicule est composé d'une plaque d'immatriculation, d'un
                    label et d'une couleur de votre choix qui permettra de le
                    différencier rapidement des autres véhicules sur les
                    différents écrans de l'application.
                </div>
                <div className="flex justify-center">
                    <Vehicle />
                </div>
                <div>
                    Avant d'ajouter une première équipe il faut donc ajouter un
                    véhicule ! C'est ce que vous allez faire dans le prochain
                    écran.
                </div>
            </div>
        )
    }
    return (
        <WelcomeTemplate
            nextStep={nextStep}
            previousStep={previousStep}
            content={content()}
            title="Véhicules"
            label="Ajouter mon premier véhicule"
        />
    )
}

export default WelcomeVehicles
