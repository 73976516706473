import React from 'react'
import LoaderModal from '../Loaders/LoaderModal'
import { Search } from 'iconoir-react'

const InputWithLoader = ({
    refreshing,
    value,
    onChange,
    icon,
    placeholder,
}) => {
    return (
        <div className="flex w-full items-center rounded-md border bg-white px-3 leading-tight text-gray-700 focus-within:border-lighter-blue-green hover:border-lighter-blue-green">
            <input
                className={`h-14 w-full appearance-none bg-white text-lg focus:outline-none lg:h-10 lg:text-sm `}
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
            />
            {refreshing ? (
                <LoaderModal customWidth={'w-4'} customHeight="h-4" />
            ) : (
                <span>{icon}</span>
            )}
        </div>
    )
}

export default InputWithLoader
