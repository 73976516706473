import React from 'react'
import StandardPlanCard from '../../../Auth/Registration/Plans/StandardPlanCard'
import ExpertPlanCard from '../../../Auth/Registration/Plans/ExpertPlanCard'
import UnlimitedPlanCard from '../../../Auth/Registration/Plans/UnlimitedPlanCard'
import { useNavigate } from 'react-router-dom'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'

const SubscriptionPlans = ({ handlePlan, plans }) => {
    let history = useNavigate()
    const goBack = () => {
        history('/parametres/abonnement')
    }

    return (
        <div className="">
            <div className="pb-10 text-xl font-bold text-light-dark-green">
                Choisissez le plan qui vous correspond :
            </div>
            <div className="flex items-center justify-center">
                <div className="mx-auto flex grid w-full max-w-6xl grid-cols-1 gap-4 px-4 pb-6 text-gray-700 sm:grid-cols-2 lg:grid-cols-3 xl:px-0">
                    <StandardPlanCard
                        handlePlan={handlePlan}
                        plan={plans.find((plan) => plan.id === 'standard')}
                    />
                    <ExpertPlanCard
                        handlePlan={handlePlan}
                        plan={plans.find((plan) => plan.id === 'expert')}
                    />
                    <UnlimitedPlanCard
                        handlePlan={handlePlan}
                        plan={plans.find((plan) => plan.id === 'unlimited')}
                    />
                </div>
            </div>
            <div className="absolute bottom-0 left-0 right-0">
                <div className="w-full">
                    <div className="px-10 pb-20">
                        <hr className="pb-5" />
                        <div className="flex justify-between">
                            <div className="">
                                <SecondaryButton
                                    label="Retour"
                                    title="Retour"
                                    action={goBack}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlans
