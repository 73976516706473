import React from 'react'
import * as dayjs from 'dayjs'

const PatientGroupedRunsView = ({ history, parentDetailsRun }) => {
    return (
        <div className="flex w-full flex-col space-y-6 py-2">
            <div className="flex w-full items-center justify-between py-5 text-2xl text-gray-700 ">
                <h2 className="font-bold">Fiches personne transportées</h2>
                {/*<button*/}
                {/*    className="bg-light-dark-green transition-color duration-200 hover:bg-dark-green font-bold text-white py-2 px-4 rounded text-base "*/}
                {/*    title="Modifier le patient"*/}
                {/*    onClick={() => history(`/patients/${run.patient.id}`)}*/}
                {/*>*/}
                {/*    <span className="hidden lg:flex">Modifier</span>*/}
                {/*    <i className="fa fa-edit lg:hidden" />*/}
                {/*</button>*/}
            </div>
            {parentDetailsRun.groupedRuns.map((run, index) => (
                <div className="flex w-full flex-col space-y-6">
                    <div className="flex flex-col lg:flex-row">
                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                            Prénom
                        </label>
                        <p>{run.patient.firstname}</p>
                    </div>
                    <div className="flex flex-col lg:flex-row">
                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                            Nom
                        </label>
                        <p className=" ">{run.patient.lastname}</p>
                    </div>
                    <div className="flex flex-col lg:flex-row">
                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                            Domicile
                        </label>
                        <p className="cursor-pointer">
                            {run.patient?.defaultPickUpAddress?.street
                                ? run.patient?.defaultPickUpAddress?.street +
                                  ' - '
                                : ''}
                            {run.patient?.defaultPickUpAddress?.city}
                        </p>
                    </div>
                    <div className="flex flex-col lg:flex-row">
                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                            Téléphone
                        </label>
                        <p>{run.patient.phoneNumber}</p>
                    </div>
                    <div className="flex flex-col lg:flex-row">
                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                            Date de naissance
                        </label>
                        <p className=" ">
                            {run.patient.dateOfBirth
                                ? dayjs(run.patient.dateOfBirth).format(
                                      'DD/MM/YYYY'
                                  )
                                : null}
                        </p>
                    </div>
                    <div className="flex flex-col lg:flex-row">
                        <label className="pb-2 font-bold lg:w-1/3 lg:p-0">
                            Description
                        </label>
                        <p>{run.patient.description}</p>
                    </div>
                    {index < parentDetailsRun.groupedRuns.length - 1 && (
                        <div className="mx-20 py-6">
                            <hr />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}

export default PatientGroupedRunsView
