import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../Commons/Loaders/Loader'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'

const SubscriptionRecap = ({
    plan,
    acceptedUsedTerms,
    setAcceptedUsedTerms,
    goToStripe,
    isCreditSms,
}) => {
    let history = useNavigate()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (plan === null) {
            if (isCreditSms) {
                history('/parametres/abonnement')
            } else {
                history('/parametres/abonnement/plans')
            }
        } else {
            setLoading(false)
        }
    }, [])

    const goBack = () => {
        history(-1)
    }

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <div className="mx-auto flex w-full flex-col">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="col-span-1">
                            <div className="pb-4 text-xl font-semibold text-light-dark-green lg:pb-10 lg:text-3xl">
                                Récapitulatif de commande
                            </div>
                            <div className="mb-4 flex flex-col space-y-6 rounded bg-gray-200 p-3 lg:hidden">
                                <div className="text-lg font-semibold text-light-dark-green">
                                    {isCreditSms
                                        ? 'Partages par sms'
                                        : `Plan ${plan.label}`}
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Montant total soumis à la TVA 20%</div>
                                    <div>{plan.price} €</div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Montant de la TVA</div>
                                    <div>
                                        {parseFloat(
                                            (plan.price * plan.tva) / 100
                                        ).toFixed(2)}{' '}
                                        €
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Montant TTC</div>
                                    <div>
                                        {parseFloat(
                                            plan.price +
                                                (plan.price * plan.tva) / 100
                                        ).toFixed(2)}{' '}
                                        €
                                    </div>
                                </div>
                                <hr />
                                <div className="flex flex-row justify-between">
                                    <div className="font-semibold text-light-dark-green">
                                        Total
                                    </div>
                                    <div className="font-semibold text-green-500">
                                        {parseFloat(
                                            plan.price +
                                                (plan.price * plan.tva) / 100
                                        ).toFixed(2)}{' '}
                                        €
                                    </div>
                                </div>
                            </div>
                            <div className="pb-3 pl-3 text-lg font-semibold text-light-dark-green lg:pb-6 lg:pl-0">
                                Conditions générales
                            </div>
                            <div className="flex space-x-2 px-4 lg:px-0">
                                <input
                                    className="scale-1 mr-4 cursor-pointer"
                                    type="checkbox"
                                    value={acceptedUsedTerms}
                                    checked={acceptedUsedTerms}
                                    onChange={(e) =>
                                        setAcceptedUsedTerms(e.target.checked)
                                    }
                                />
                                <label className="">
                                    En cochant cette case vous acceptez les{' '}
                                    <Link
                                        to="/files/CGV.pdf"
                                        target="_blank"
                                        className="text-blue-600 hover:underline"
                                        download
                                    >
                                        conditions générales de vente
                                    </Link>{' '}
                                    et les{' '}
                                    <Link
                                        to="/files/CGU.pdf"
                                        target="_blank"
                                        className="text-blue-600 hover:underline"
                                        download
                                    >
                                        conditions générales d'utilisation
                                    </Link>{' '}
                                    du logiciel Heroad
                                </label>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="mx-10 mx-4 mt-8 hidden flex-col space-y-6  rounded bg-gray-200 p-10 lg:flex">
                                <div className="text-lg font-semibold text-light-dark-green">
                                    {isCreditSms
                                        ? 'Partages par sms'
                                        : `Plan ${plan.label}`}
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Montant total soumis à la TVA 20%</div>
                                    <div>{plan.price} €</div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Montant de la TVA</div>
                                    <div>
                                        {parseFloat(
                                            (plan.price * plan.tva) / 100
                                        ).toFixed(2)}{' '}
                                        €
                                    </div>
                                </div>
                                <div className="flex flex-row justify-between">
                                    <div>Montant TTC</div>
                                    <div>
                                        {parseFloat(
                                            plan.price +
                                                (plan.price * plan.tva) / 100
                                        ).toFixed(2)}{' '}
                                        €
                                    </div>
                                </div>
                                <hr />
                                <div className="flex flex-row justify-between">
                                    <div className="font-semibold text-light-dark-green">
                                        Total
                                    </div>
                                    <div className="font-semibold text-green-500">
                                        {parseFloat(
                                            plan.price +
                                                (plan.price * plan.tva) / 100
                                        ).toFixed(2)}{' '}
                                        €
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="absolute bottom-0 left-0 right-0">
                        <div className="w-full">
                            <div className="px-10 pb-20">
                                <hr className="pb-5" />
                                <div className="flex justify-between">
                                    <SecondaryButton
                                        label="Retour"
                                        title="Retour"
                                        action={goBack}
                                    />
                                    <PrimaryButton
                                        label="Confirmer et payer"
                                        title="Confirmer et payer"
                                        action={goToStripe}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SubscriptionRecap
