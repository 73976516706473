import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import React from 'react'

export const SignIn = ({
    username,
    setUsername,
    usernameRef,
    passwordRef,
    password,
    setPassword,
    passwordShown,
    setPasswordShown,
    error,
    handleLogin,
    loading,
}) => {
    return (
        <div>
            <div className="pb-8 text-3xl font-bold lg:text-2xl">
                Connectez-vous à votre compte
            </div>
            <div className="mb-6 text-xl lg:text-base">
                <label className="mb-2 block font-bold" htmlFor="username">
                    Identifiant
                </label>
                <input
                    className={`block h-12 w-full appearance-none rounded border pl-4 leading-tight focus:border-lighter-blue-green focus:outline-none lg:h-10
                     ${error ? 'border-red-500' : ''}`}
                    id="username"
                    type="username"
                    name="username"
                    value={username}
                    onChange={(e) => {
                        usernameRef.current = e.target.value
                        setUsername(e.target.value)
                    }}
                />
            </div>
            <div className="mb-4 text-xl lg:text-base">
                <label className="mb-2 block  font-bold" htmlFor="password">
                    Mot de passe
                </label>
                <input
                    value={password}
                    onChange={(e) => {
                        passwordRef.current = e.target.value
                        setPassword(e.target.value)
                    }}
                    className={`block h-12 w-full appearance-none rounded border pl-4 leading-tight focus:border-lighter-blue-green focus:outline-none lg:h-10
                   ${error ? 'border-red-500' : ''}`}
                    id="password"
                    type={passwordShown ? 'text' : 'password'}
                    name="password"
                />
            </div>
            <div
                className="flex pb-8"
                onClick={() => setPasswordShown(!passwordShown)}
            >
                <input type="checkbox" className="" checked={passwordShown} />
                <p className="pl-2 text-xl lg:text-base">
                    Afficher le mot de passe
                </p>
            </div>

            <div className="pb-2 text-xl font-bold text-red-500 lg:text-base">
                {error && <p className="py-1">{error}</p>}
            </div>
            <div className="mb-5 flex items-center">
                <PrimaryButton
                    action={() => handleLogin()}
                    label="Continuer"
                    title="Se connecter"
                    loader={loading}
                    hiddenLabelOnMobile={false}
                    fullWidth={true}
                />
            </div>
        </div>
    )
}
