import React from 'react'

const CustomRadioButton = ({
    label,
    id,
    value,
    name,
    checked,
    onChange,
    disabled,
}) => {
    return (
        <div className="flex items-center">
            <label htmlFor={id} className="flex cursor-pointer items-center">
                <div className="relative">
                    <input
                        type="radio"
                        id={id}
                        value={value}
                        name={name}
                        checked={checked}
                        onChange={onChange}
                        disabled={disabled}
                        className="sr-only" // Cache le vrai bouton radio
                    />
                    <div
                        className={`h-6 w-6 rounded-full border-2 bg-white transition-colors ${
                            checked
                                ? 'border-secondary-color'
                                : 'border-gray-300'
                        } ${disabled ? 'opacity-50' : ''}`}
                    >
                        {checked && (
                            <div className="absolute inset-0 flex items-center justify-center">
                                <div className="h-3 w-3 rounded-full bg-secondary-color"></div>
                            </div>
                        )}
                    </div>
                </div>
                <span className="ml-2 text-gray-700">{label}</span>
            </label>
        </div>
    )
}

export default CustomRadioButton
