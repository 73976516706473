import React from 'react'
import Select, { components } from 'react-select'

const SelectDropDownList = ({
    error,
    isMulti,
    isSearchable,
    value,
    isClearable,
    isDisabled,
    handleOptionChange,
    id,
    options,
    label,
    handleSearchInputPatientChange,
    icon,
    addOption,
    placeholder,
}) => {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            boxShadow: 'none',
            border: `1px solid ${
                error ? '#ef4444' : value === null ? '#E2E8F0' : '#80B0C2'
            }`,
            '&:hover': { borderColor: '#80B0C2' },
            borderRadius: '0.75rem',
            cursor: 'pointer',
            borderColor: '#80B0C2',
            display: 'flex',
        }),
        option: (provided, state) => ({
            ...provided,
            cursor: 'pointer',
            backgroundColor: state.isSelected
                ? '#36aba4'
                : state.isFocused
                ? '#bde4dd'
                : 'inherit',
            '&:active': { backgroundColor: '#7AC8C1' },
        }),
    }

    const { Option, SingleValue } = components
    const IconOption = (props) => {
        return (
            <Option {...props}>
                {props.data.icon ? (
                    <div className="flex items-center space-x-2">
                        <div>{props.data.icon}</div>
                        <div>{props.data.label}</div>
                    </div>
                ) : (
                    props.data.label
                )}
            </Option>
        )
    }
    const IconValue = (props) => {
        return (
            <SingleValue {...props}>
                {props.data.icon ? (
                    <div className="flex items-center space-x-2">
                        <div>{props.data.icon}</div>
                        <div>{props.data.label}</div>
                    </div>
                ) : (
                    props.data.label
                )}
            </SingleValue>
        )
    }

    return (
        <div className={`relative w-full ${addOption ? 'pr-10' : null}`}>
            {(icon || label) && (
                <div className="flex space-x-2 py-2">
                    {icon && (
                        <i className={`fa ml-2 ${icon} min-w-icon fa-lg`} />
                    )}
                    {label && (
                        <label className="block text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                            {label}
                        </label>
                    )}
                </div>
            )}
            <Select
                maxMenuHeight={220}
                className="my-custom-select"
                classNamePrefix="select"
                styles={customStyles}
                isMulti={isMulti}
                isSearchable={isSearchable}
                value={value}
                isClearable={isClearable}
                isDisabled={isDisabled}
                onChange={handleOptionChange}
                noOptionsMessage={() => 'Tapez pour rechercher'}
                name="selectDropdown"
                id={id}
                options={options}
                placeholder={`${placeholder ? placeholder : label}...`}
                onInputChange={handleSearchInputPatientChange}
                isOptionDisabled={(option) => option.isDisabled}
                components={{ Option: IconOption, SingleValue: IconValue }}
            />
            {addOption && (
                <i
                    className="fas fa-plus-circle fa-lg lg:fa-lg absolute bottom-0 right-0  mb-4 cursor-pointer"
                    onClick={addOption}
                />
            )}
        </div>
    )
}

export default SelectDropDownList
