import React from 'react'

export const DriverLegendModal = ({
    setShowLegendModal,
    userColor,
    onlyUserRuns,
    uniqueMasterRuns,
}) => {
    return (
        <div className="modal fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50 px-2 pb-20 text-gray-700 md:px-6">
            <div
                className={`flex w-full max-w-2xl flex-col justify-between space-y-4 rounded-lg bg-white p-2 px-4`}
            >
                <div
                    className={'flex items-center border-b py-4 text-xl'}
                    title="legende"
                >
                    <div className="font-bold">Légende</div>
                    <svg
                        className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 18"
                        onClick={() => setShowLegendModal(false)}
                    >
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                    </svg>
                </div>
                <div className="flex flex-col space-y-12 p-2">
                    <div className="flex w-full items-center space-x-8 ">
                        <div className="flex w-24 max-w-full justify-center ">
                            <i className="far fa-question-circle text-2xl" />
                        </div>
                        <p>Horaire incertain </p>
                    </div>

                    <div className="flex w-full items-center space-x-8 ">
                        <div className="flex w-24 max-w-full justify-center ">
                            <i className="far fa-registered text-2xl " />
                        </div>
                        <p>Transport retour </p>
                    </div>
                    <div className="flex w-full items-center space-x-8 ">
                        <div className="flex w-24 max-w-full justify-center ">
                            <span className="inline-block h-6 w-6 rounded bg-green-500 pt-1 text-center font-bold text-white">
                                <i className="fa fa-check text-white " />
                            </span>
                        </div>
                        <p>Transport validé</p>
                    </div>
                    <div className="flex w-full items-center space-x-8 ">
                        <div className="flex w-24 max-w-full justify-center ">
                            <div className="h-6 w-6 rounded-full bg-gray-500"></div>
                        </div>
                        <p>Transport non-assigné</p>
                    </div>
                    <div className="flex w-full items-center space-x-8 ">
                        <div className="flex w-24 max-w-full justify-center ">
                            <div className="h-6 w-6 rounded-full bg-purple-500"></div>
                        </div>
                        <p>Transport facturé</p>
                    </div>
                    {!onlyUserRuns && (
                        <>
                            <div className="flex w-full space-x-8">
                                <div className="flex w-24 max-w-full justify-center  ">
                                    <div
                                        className="h-6 w-6 rounded-full "
                                        style={{
                                            backgroundColor: userColor,
                                        }}
                                    ></div>
                                </div>
                                <div>Mes transports</div>
                            </div>
                            {uniqueMasterRuns.length > 0 ? (
                                <div className="flex w-full flex-col space-y-12 pt-12">
                                    {uniqueMasterRuns.map(
                                        (uniqueMasterRun, key) => (
                                            <div
                                                key={key}
                                                className="flex w-full flex-col"
                                            >
                                                <div className="flex w-full space-x-8">
                                                    <div className="flex w-24  max-w-full justify-center ">
                                                        <div
                                                            className="rounded-full p-1 text-sm font-semibold uppercase text-white"
                                                            style={{
                                                                backgroundColor:
                                                                    !uniqueMasterRun
                                                                        ? 'border-gray-500'
                                                                        : uniqueMasterRun
                                                                              .vehicle
                                                                              .color,
                                                            }}
                                                        >
                                                            {uniqueMasterRun.users[0]?.firstname.charAt(
                                                                0
                                                            ) +
                                                                uniqueMasterRun.users[0]?.lastname.charAt(
                                                                    0
                                                                )}
                                                        </div>
                                                    </div>
                                                    <span>
                                                        {
                                                            uniqueMasterRun
                                                                ?.users[0]
                                                                .firstname
                                                        }{' '}
                                                        {
                                                            uniqueMasterRun
                                                                ?.users[0]
                                                                .lastname
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            ) : null}
                        </>
                    )}
                    <div className="flex w-full items-center space-x-8">
                        <div>
                            <i
                                className="stripeBg_canceled flex h-5 w-24 max-w-full
                 justify-center rounded-full border px-2 text-base font-semibold  leading-5"
                            />
                        </div>
                        <div>
                            Bandes oranges correspondant a un transport annulé
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
