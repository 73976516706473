import React from 'react'
import { ERROR_ACTIONS } from '../../services/Reducers/ErrorReducer'

export default function Errors({ error, dispatch }) {
    return (
        <div
            key={error.id}
            className={`modal-shadow rounded-modal bg-white  w-notif transform translate-y-6 duration-700'
          `}
        >
            <div className="flex flex-col w-full">
                <div className="flex border-b text-sm">
                    <div className="flex items-center justify-between p-2 px-4 w-full">
                        <div className="flex items-center">
                            <i
                                className={`fas fa-times-circle text-2xl text-red-500 items-center justify-center pr-4`}
                            />
                            <div className="flex font-semibold text-gray-700">
                                Erreur
                            </div>
                        </div>
                        <div>
                            <i
                                className="fas fa-times text-xl cursor-pointer text-gray-700 items-center justify-center pr-4"
                                onClick={() =>
                                    dispatch({
                                        type: ERROR_ACTIONS.CLEAR_ERROR,
                                        payload: {
                                            id: error.id,
                                        },
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="flex items-center text-sm">
                    <div className="p-4">{error.message}</div>
                </div>
                <div className={`w-full h-1 border-line bg-red-500`} />
            </div>
        </div>
    )
}
