import React, { useEffect, useState } from 'react'
import Loader from '../Commons/Loaders/Loader'
import SelectDropDownList from '../Commons/DropDownLists/SelectDropDownList'
import API from '../../services/api'
import useConfiguratorStore from '../../stores/Configurator'
import { toast } from 'react-toastify'

const Preferences = () => {
    const [loading, setLoading] = useState(true)
    const [reload, setReload] = useState(false)
    const [planningViews, setPlanningViews] = useState([])
    const [planningView, setPlanningView] = useState([])
    const [userPreferences, setUserPreferences] = useState([])

    const getPreferences = () => {
        API.Users.get(localStorage.getItem('id')).then((response) => {
            response.json().then((data) => {
                setUserPreferences(data.preferences)
                if (data.preferences && data.preferences.planningView) {
                    setPlanningView({
                        value: data.preferences.planningView,
                        label: data.preferences.planningView,
                    })
                } else {
                    setPlanningView({
                        value: 'Dashboard',
                        label: 'Dashboard',
                    })
                }

                setLoading(false)
            })
        })
    }

    const getPlanningViews = () => {
        API.PlanningViews.list().then((response) => {
            response.json().then((data) => {
                setPlanningViews(
                    data['hydra:member'].map((d) => {
                        return { value: d.label, label: d.label }
                    })
                )
                getPreferences()
            })
        })
    }

    const handlePlanningViewChange = (value) => {
        setPlanningView(value)
    }

    const handleSubmit = () => {
        const data = {
            preferences: {
                ...userPreferences,
                planningView: planningView.label,
            },
        }
        localStorage.setItem('planningView', planningView.label)
        API.Users.put(localStorage.getItem('id'), data).then((response) => {
            response.json().then((data) => {
                // la redirection est réalisée pour que le menu se reload afin de recharger la vue de planning par défaut
                // idéalement il faudrait changer ça -> set le menu lors du changement de préférences
                window.location.href = '/preferences'
                toast.success('Préférences mises à jour avec succès.')
            })
        })
    }

    useEffect(() => {
        getPlanningViews()
    }, [reload])

    return (
        <div className="pt-5">
            {loading ? (
                <Loader />
            ) : (
                <div className="flex flex-col space-y-5 px-10 lg:w-4/5 lg:px-0">
                    <div className="rounded-xl bg-white p-5 shadow-md lg:p-10">
                        <h1 className="font-bold">Préférences utilisateur</h1>
                        <h2 className="font-light text-gray-600">
                            Préférence vous concernant
                        </h2>
                    </div>
                    <div className="flex flex-col space-y-5 rounded-xl bg-white p-5 shadow-md lg:p-10">
                        <div className="flex  w-full flex-col items-center space-y-2 lg:flex-row lg:space-y-0">
                            <label className="flex w-full items-center justify-between font-semibold lg:w-1/3 ">
                                Vue par défaut du planning
                            </label>
                            <div className="w-full lg:w-2/3">
                                <SelectDropDownList
                                    value={planningView}
                                    handleOptionChange={
                                        handlePlanningViewChange
                                    }
                                    options={planningViews}
                                    isSearchable={false}
                                    showLabel={false}
                                    icon=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex w-full justify-between pt-20">
                        <button
                            className="ml-2 rounded border border-dark-green bg-transparent py-2 px-4 font-semibold text-dark-green hover:border-transparent hover:bg-light-blue-green hover:text-white"
                            onClick={() => getPlanningViews()}
                        >
                            Annuler
                        </button>
                        <button
                            className="rounded bg-light-dark-green px-4 py-2 font-bold text-white hover:bg-dark-green"
                            onClick={handleSubmit}
                        >
                            Modifier
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Preferences
