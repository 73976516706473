import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LogOut, OpenInBrowser, SmartphoneDevice } from 'iconoir-react'
import classNames from 'classnames'
import { DeviceManager } from '../../DeviceManager'

const UserMenu = ({ userMenuRef, driver = false }) => {
    const [label, setLabel] = useState('')
    const location = useLocation()
    const handleLogout = () => {
        DeviceManager.clearAllExceptDeviceId()
        window.location.href = '/' // redirect
    }

    useEffect(() => {
        if (location.pathname.startsWith('/chauffeur')) {
            setLabel('Administration')
        } else {
            setLabel('Mode route')
        }
    }, [])

    const handleSwitch = () => {
        if (location.pathname.startsWith('/chauffeur')) {
            window.location.href = '/transport'
        } else {
            window.location.href = '/chauffeur'
        }
    }

    return (
        <div
            className={classNames(
                'z-1000 absolute mr-2 flex w-56 flex-col rounded-md border bg-white text-xl font-bold lg:w-48 lg:text-base ',
                {
                    'right-0 mt-2': driver,
                    'bottom-2 left-52 sm:left-64 md:bottom-20 ': !driver,
                }
            )}
            ref={userMenuRef}
        >
            <div
                onClick={() => (window.location.href = '/profil')}
                className="block cursor-pointer  px-4 py-2 pt-4 capitalize text-gray-700 hover:bg-gray-200 hover:font-bold"
            >
                Profil
            </div>
            {localStorage.getItem('role') === 'ADMIN' && (
                <div
                    onClick={() => (window.location.href = '/preferences')}
                    className="block cursor-pointer  px-4 py-2 capitalize text-gray-700 hover:bg-gray-200 hover:font-bold"
                >
                    Préférences
                </div>
            )}
            {localStorage.getItem('role') === 'ADMIN' ? (
                <div
                    onClick={handleSwitch}
                    className="block  cursor-pointer  px-4  py-2 text-gray-700 hover:bg-gray-200 hover:font-bold"
                >
                    <div className={'flex w-full items-center justify-between'}>
                        <p>{label}</p>
                        {label === 'Mode route' ? (
                            <SmartphoneDevice />
                        ) : (
                            <OpenInBrowser />
                        )}
                    </div>
                </div>
            ) : null}
            <div
                className={
                    'block cursor-pointer px-4 pb-4 pt-6 capitalize text-gray-700  hover:bg-gray-200 hover:font-bold'
                }
                onClick={handleLogout}
            >
                <div className="flex items-center justify-between text-red-500 ">
                    <p>Déconnexion</p> <LogOut />
                </div>
            </div>
        </div>
    )
}

export default UserMenu
