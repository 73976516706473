import React from 'react'

const FreePlanCard = ({ handlePlan, plan }) => {
    return (
        <div
            className="flex flex-shrink-0 snap-center flex-col w-full mx-auto rounded bg-white border-2 border-t-8 border-black transition duration-150
         ease-in-out transform hover:-translate-y-1 min-h-pricing-card"
        >
            <div className="flex flex-col pt-8 sm:pt-16 pb-4 sm:pb-8 items-center px-4">
                <h3 className="text-3xl font-bold text-black">Découverte</h3>
                <div className="text-lg">Véhicules illimités</div>
                <div className="pt-4 xl:text-4xl text-xl font-bold text-black">
                    Gratuit
                </div>
                <div className="text-lg">14 jours</div>
                <button
                    onClick={() => handlePlan(plan)}
                    className="mt-4 border-2 font-bold rounded-lg py-2 border-black w-full text-lg
                        bg-white text-center text-black transition duration-150
         ease-in-out transform hover:-translate-y-1"
                >
                    Démarrer l'essai
                </button>
                <div className="pt-4 w-full text-left font-bold">
                    <ul className="flex flex-col space-y-4">
                        <li>
                            <div className="flex">
                                <i className="fa fa-solid fa-circle custom-fa-size mr-2 mt-2"></i>
                                <div>Fonctionalités de bases</div>
                            </div>
                        </li>
                        <li>
                            <div className="flex">
                                <i className="fa fa-solid fa-circle custom-fa-size mr-2 mt-2"></i>
                                <div>Partage des courses par email</div>
                            </div>
                        </li>
                        <li>
                            <div className="flex">
                                <i className="fa fa-solid fa-circle custom-fa-size mr-2 mt-2"></i>
                                <div>Véhicules illimités</div>
                            </div>
                        </li>
                        <li>
                            <div className="flex ">
                                <i className="fas fa-times fa-sm text-red-500 mr-2 mt-2"></i>
                                <div>Partage des courses par sms</div>
                            </div>
                        </li>
                        <li>
                            <div className="flex">
                                <i className="fas fa-times fa-sm text-red-500 mr-2 mt-2"></i>
                                <div>
                                    Accès à la facturation libre uniquement sur
                                    demande
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default FreePlanCard
