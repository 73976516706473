import React from 'react'
import { v4 as uuidv4 } from 'uuid'

const Team = ({ row, isComputer }) => {
    return (
        <div
            className={`flex flex-col justify-center ${
                isComputer ? 'w-1/6 px-6 text-base' : 'text-xs'
            }`}
        >
            {row?.masterRun?.users.map((user) => (
                <div key={uuidv4()}>{`${user.firstname} ${user.lastname}`}</div>
            ))}
        </div>
    )
}

export default Team
