import React, { useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import InvoiceFilter from './InvoiceFilter'
import Invoice from './Invoice'
import FreeInvoiceWrapper from './Free/FreeInvoiceWrapper'

const InvoiceContainer = () => {
    const location = useLocation()
    const [search, setSearch] = useState('')
    const [archived, setArchived] = useState(false)
    const [openFilterModal, setOpenFilterModal] = useState(false)
    // if there is devis in url set isDevis to true
    const isQuote = location.pathname.includes('devis')

    return (
        <Routes>
            <Route path=":id" element={<FreeInvoiceWrapper />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <InvoiceFilter
                            search={search}
                            setSearch={setSearch}
                            setArchived={setArchived}
                            archived={archived}
                            setOpenFilterModal={setOpenFilterModal}
                            isQuote={isQuote}
                        />
                        <Invoice
                            search={search}
                            archived={archived}
                            setOpenFilterModal={setOpenFilterModal}
                            openFilterModal={openFilterModal}
                            isQuote={isQuote}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default InvoiceContainer
