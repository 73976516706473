import React from 'react'
import SecondaryButton from './Buttons/SecondaryButton'

const Cancel = ({ goBack }) => {
    return (
        <div className="h-screen">
            <div className="bg-white p-6 md:mx-auto">
                <div className="text-center">
                    <i className="fas fa-times-circle fa-5x my-6 text-red-600" />
                    <h3 className="text-center text-base font-semibold text-gray-900 md:text-2xl">
                        Paiement échoué !
                    </h3>
                    <p className="my-2 text-gray-600">
                        Veuillez réessayer ou contacter le support à{' '}
                        <a
                            href="mailto:contact@heroad.io"
                            className="hover:text-dark-green"
                        >
                            contact@heroad.io
                        </a>
                        .
                    </p>
                    <p>Nous vous répondons très vite !</p>
                    <div className="py-10 text-center">
                        <SecondaryButton
                            label="Retour"
                            title="Retour"
                            action={goBack}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cancel
