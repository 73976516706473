import React from 'react'
import { ACTIONS } from '../../../services/Reducers/SalesReducer'

const PersonalInformations = ({ sales, dispatch }) => {
    const handleEmail = (value) => {
        dispatch({ type: ACTIONS.SET_EMAIL, payload: value })
    }

    const handleFirstname = (value) => {
        dispatch({ type: ACTIONS.SET_FIRSTNAME, payload: value })
    }

    const handleLastname = (value) => {
        dispatch({ type: ACTIONS.SET_LASTNAME, payload: value })
    }

    const handlePhone = (value) => {
        dispatch({ type: ACTIONS.SET_PHONE, payload: value })
    }

    return (
        <div className="col-span-1 px-6 flex flex-col space-y-4 w-full mx-auto max-w-md pb-5">
            <div className="py-5 font-bold text-2xl">
                Informations personnelles
            </div>
            <div className="w-full">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                >
                    Email
                </label>
                <input
                    value={sales.email}
                    className={`appearance-none block w-full text-gray-700 h-10 pl-4 leading-tight border rounded-lg focus:outline-none focus:border-lighter-blue-green ${
                        !sales.validEmail && sales.error
                            ? 'border-red-500'
                            : 'border-gray-300'
                    }`}
                    id="email"
                    type="text"
                    name="email"
                    onChange={(e) => handleEmail(e.target.value)}
                    placeholder="john.doe@gmail.com"
                />
            </div>
            <div className="w-full">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="firstname"
                >
                    Prénom
                </label>
                <input
                    value={sales.firstname}
                    className={`appearance-none block w-full text-gray-700 h-10 pl-4 leading-tight border rounded-lg focus:outline-none focus:border-lighter-blue-green ${
                        !sales.firstname && sales.error
                            ? 'border-red-500'
                            : 'border-gray-300'
                    }`}
                    id="firstname"
                    type="text"
                    name="firstname"
                    onChange={(e) => handleFirstname(e.target.value)}
                    placeholder="John"
                />
            </div>
            <div className="w-full">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="lastname"
                >
                    Nom
                </label>
                <input
                    value={sales.lastname}
                    className={`appearance-none block w-full text-gray-700 h-10 pl-4 leading-tight border rounded-lg focus:outline-none focus:border-lighter-blue-green ${
                        !sales.lastname && sales.error
                            ? 'border-red-500'
                            : 'border-gray-300'
                    }`}
                    id="lastname"
                    type="text"
                    name="lastname"
                    onChange={(e) => handleLastname(e.target.value)}
                    placeholder="Doe"
                />
            </div>
            <div className="w-full">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="phone"
                >
                    Téléphone
                </label>
                <input
                    value={sales.phone}
                    className={`appearance-none block w-full text-gray-700 h-10 pl-4 leading-tight border rounded-lg focus:outline-none focus:border-lighter-blue-green ${
                        sales.phone.length !== 10 && sales.error
                            ? 'border-red-500'
                            : 'border-gray-300'
                    }`}
                    id="phone"
                    type="text"
                    name="phone"
                    onChange={(e) => handlePhone(e.target.value)}
                    placeholder="xxxxxxxxxx"
                />
            </div>
        </div>
    )
}

export default PersonalInformations
