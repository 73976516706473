import React, { useState, useEffect } from 'react'
import PayingCenter, { getRegimeLabel } from './PayingCenter'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import ClassicInputV2 from '../../../Commons/Inputs/ClassicInputV2'

const PayingCenterList = ({
    payingCenters,
    handleCloseModal,
    handleSelectPayingCenter,
    isPendingPayinCenter,
}) => {
    const [selectedCenter, setSelectedCenter] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [filteredCenters, setFilteredCenters] = useState(payingCenters)

    useEffect(() => {
        const filtered = payingCenters.filter((center) =>
            center.label.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredCenters(filtered)
    }, [searchTerm, payingCenters])

    return (
        <div className="modal absolute bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50 text-gray-700 lg:py-20">
            <div className="shadow-modal invisible-scrollbar lg:min-h-modal mx-auto flex h-screen w-full max-w-4xl flex-col overflow-y-auto rounded-xl bg-white lg:h-full">
                <div className="flex flex-col items-start px-2 sm:px-6 lg:px-8">
                    <div className="sticky top-0 z-10 flex w-full flex-col space-y-4 bg-white py-4">
                        <div className="flex items-center border-b pb-4 text-3xl">
                            <div className="flex space-x-4 text-center font-semibold">
                                Sélectionner une caisse
                            </div>
                            <svg
                                className="ml-auto h-10 w-10 cursor-pointer fill-current text-gray-700"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 18"
                                onClick={handleCloseModal}
                            >
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                            </svg>
                        </div>
                        <ClassicInputV2
                            type="text"
                            placeholder="Rechercher une caisse..."
                            className="w-full rounded-md border border-gray-300 p-2"
                            value={searchTerm}
                            setValue={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col">
                        <div className="overflow-x-auto">
                            <div className="inline-block min-w-full py-2 align-middle">
                                <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                >
                                                    Label
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 lg:block"
                                                >
                                                    Organisme Destinataire
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                >
                                                    Régime
                                                </th>
                                                <th
                                                    scope="col"
                                                    className="hidden px-2 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 lg:block"
                                                >
                                                    Centre Gestionnaire
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {filteredCenters.map(
                                                (center, index) => (
                                                    <PayingCenter
                                                        key={index}
                                                        payingCenter={center}
                                                        isSelected={
                                                            selectedCenter ===
                                                            center
                                                        }
                                                        onSelect={() =>
                                                            setSelectedCenter(
                                                                center
                                                            )
                                                        }
                                                    />
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {selectedCenter && (
                            <div className="mt-4 rounded-lg bg-light-verdois/50 p-4">
                                <h3 className="text-lg font-medium text-primary-color">
                                    Caisse sélectionnée :
                                </h3>
                                <p className="mt-2 text-primary-color">
                                    {selectedCenter.label} -{' '}
                                    {getRegimeLabel(selectedCenter.codeRegime)}
                                </p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="sticky bottom-0 mt-auto w-full bg-white">
                    <div className="px-2 py-4 text-xl lg:px-10 lg:text-base">
                        <hr className="mb-4" />
                        <div className="flex justify-between">
                            <SecondaryButton
                                label="Annuler"
                                title="Annuler"
                                action={handleCloseModal}
                            />
                            <PrimaryButton
                                label="Valider"
                                loader={isPendingPayinCenter}
                                action={() =>
                                    handleSelectPayingCenter(selectedCenter)
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayingCenterList
