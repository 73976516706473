import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Patient from './Patient'
import ClassicFilter from '../../Commons/ClassicFilter'
import Manage from './Manage'

const PatientContainer = (props) => {
    const [search, setSearch] = useState('')
    const [page, setPage] = useState(1)

    return (
        <Routes>
            <Route path=":id" element={<Manage />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <ClassicFilter
                            {...props}
                            search={search}
                            setSearch={setSearch}
                            setPage={setPage}
                            icon={'fa-user-injured'}
                            title={'Nouvelle fiche'}
                            link={'/ressources/repertoire'}
                        />
                        <Patient
                            {...props}
                            search={search}
                            setSearch={setSearch}
                            page={page}
                            setPage={setPage}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default PatientContainer
