import PrimaryButton from '../../../../Commons/Buttons/PrimaryButton'
import {
    Copy,
    DataTransferBoth,
    NavArrowDown,
    Printer,
    FloppyDiskArrowIn,
} from 'iconoir-react'
import React from 'react'
import {
    findValueByStatus,
    findColorByStatusType,
} from '../../../Invoice/Cpam/InvoiceCpamListComponent'
import SecondaryButton from '../../../../Commons/Buttons/SecondaryButton'

export const InvoiceStatusDisplay = ({ invoice, handleSuspendedInvoice }) => {
    const totalAmount = parseFloat(invoice.totals.totalAmount?.toFixed(2))
    const difference = Math.abs(totalAmount - invoice.totalInvoiceAmount)

    const checkIfRateChanged =
        invoice.totals.totalAmo?.toFixed(2) ===
        invoice.payingCenterAmount?.toFixed(2)

    if (!invoice.id) {
        return (
            <>
                <h1 className="pr-2 text-2xl font-semibold">Facturation</h1>
                <p>Transformez votre transport en facture</p>
            </>
        )
    }

    if (invoice.status?.label === 'Rejetée') {
        return (
            <div
                className="w-full rounded-b border-t-4 border-red-500 bg-red-100 px-4 py-3 text-red-600 shadow-md"
                role="alert"
            >
                <div className="flex items-center">
                    <DataTransferBoth className="mr-4" />
                    <div>
                        <p className="text-xl font-semibold">Rejetée</p>
                        <p className="text-md">{invoice.rejectionReason}</p>
                    </div>
                </div>
            </div>
        )
    }

    if ((difference !== 0 && difference >= 0.01) || !checkIfRateChanged) {
        return (
            <div
                className="w-full rounded-b border-t-4 border-red-500 bg-red-100 px-4 py-3 text-red-600 shadow-md"
                role="alert"
            >
                <div className="flex items-center">
                    <FloppyDiskArrowIn className="mr-4 hidden sm:flex" />
                    <div>
                        <p className="text-xl font-semibold">
                            Enregistrez vos modifications
                        </p>
                        <p className="text-md">
                            Si vous souhaitez confirmer vos modifications,
                            n'oubliez pas de modifier la facture
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={'flex space-x-4'}>
            <span
                className={`flex items-center justify-center rounded-full px-2 py-1 text-xl ${findColorByStatusType(
                    invoice.status
                )}`}
            >
                {findValueByStatus(invoice.status)}
            </span>
            {invoice.id && invoice?.status?.label === 'Créée' ? (
                <SecondaryButton
                    label={'Suspendre'}
                    action={handleSuspendedInvoice}
                />
            ) : invoice.id && invoice?.status?.label === 'Suspendue' ? (
                <SecondaryButton
                    label={'Autoriser la télétransmission'}
                    action={handleSuspendedInvoice}
                />
            ) : null}
        </div>
    )
}

export const InvoiceActions = ({
    invoice,
    checkAbleToInvoice,
    prescription,
    run,
    checkAndHandleInvoiceForm,
    patient,
    dateOfBirth,
}) => {
    if (invoice.id && invoice?.status?.label !== 'Créée') {
        return
        //     return (
        //         <InvoiceActionMenu
        //             invoice={invoice}
        //             dispatch={dispatch}
        //             action={action}
        //         />
        //     )
    }

    return (
        <PrimaryButton
            label={
                invoice.id
                    ? 'Modifier la facture'
                    : !checkAbleToInvoice(
                          patient,
                          invoice,
                          prescription,
                          run,
                          false,
                          dateOfBirth
                      )
                    ? 'Facturer le transport'
                    : 'Verifier les informations'
            }
            action={checkAndHandleInvoiceForm}
        />
    )
}

const InvoiceActionMenu = ({ invoice, dispatch, action }) => {
    return (
        <div className="px-2 py-5">
            <div className="group relative flex w-fit text-right" tabIndex="-1">
                <div className={'w-32 '}>
                    <PrimaryButton
                        label={'Action'}
                        fullWidth={true}
                        action={() =>
                            dispatch({
                                type: action.OPEN_ACTION_MENU,
                                payload: true,
                            })
                        }
                        icon={<NavArrowDown />}
                    />
                </div>
                {invoice.openActionMenu && (
                    <div className="invisible z-50 origin-top-right -translate-y-2 scale-95 transform opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100">
                        <div
                            className={`absolute right-0 top-12 w-56 origin-top-right divide-y divide-gray-100 rounded-md border border-gray-200 bg-white shadow-lg outline-none`}
                            role="menu"
                        >
                            <button
                                role="menuitem"
                                className="flex w-full px-4 py-2 text-left  leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-light-blue-green"
                            >
                                <Printer className={'mr-2'} />
                                Imprimer
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
