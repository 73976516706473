import React from 'react'

const ManageTitle = ({ label }) => {
    return (
        <div className="px-5 lg:px-20 font-bold text-2xl pb-10 text-gray-700">
            {label}
        </div>
    )
}

export default ManageTitle
