import React, { useEffect, useReducer, useState } from 'react'
import API from '../../../services/api'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import VehicleTypes from '../../../services/VehicleTypes'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import { ACTIONS } from '../../../services/Reducers/WelcomeModalReducer'
import WelcomeTitle from '../../Commons/WelcomeTitle'
import PreviousButton from '../../Commons/PreviousButton'
import NextButton from '../../Commons/NextButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'

const WelcomeParametersConfig = ({
    welcome,
    dispatch,
    nextStep,
    previousStep,
    handleLoading,
}) => {
    const handleUsersShowAllRuns = () => {
        dispatch({
            type: ACTIONS.SET_PARAMETERS_USERS_SHOW_ALL_RUNS,
            payload: !welcome.parameters.usersShowAllRuns,
        })
    }
    const handleUsersShowUnassignedRuns = () => {
        dispatch({
            type: ACTIONS.SET_PARAMETERS_USERS_SHOW_UNASSIGNED_RUNS,
            payload: !welcome.parameters.usersShowUnassignedRuns,
        })
    }
    const handleSelectVehicleTypes = (e) => {
        let payload = {}
        if (e === null) {
            payload = {
                vehicleOptions: VehicleTypes,
                defaultVehiclesTypes: null,
                defaultVehicleType: null,
            }
        } else {
            const vehicleOptions = VehicleTypes.filter(function (type) {
                return !e.some(function (type2) {
                    return type['@id'] === type2['@id']
                })
            })
            payload = {
                defaultVehiclesTypes: e,
                defaultVehicleType: welcome.parameters.defaultVehicleType,
                vehicleOptions: vehicleOptions,
            }
            if (
                welcome.parameters.defaultVehicleType &&
                !e.some(
                    (type) =>
                        type['@id'] ===
                        welcome.parameters.defaultVehicleType['@id']
                )
            ) {
                payload.defaultVehicleType = e[0]
            }
        }
        dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE_TYPES, payload: payload })
    }

    const handleSelectVehicleType = (e) => {
        dispatch({ type: ACTIONS.SET_SELECTED_VEHICLE_TYPE, payload: e })
    }

    const handleSaveParameters = () => {
        handleLoading(true)
        const data = {
            usersShowAllRuns: welcome.parameters.usersShowAllRuns,
            usersShowUnassignedRuns: welcome.parameters.usersShowUnassignedRuns,
            defaultVehicleType: welcome.parameters.defaultVehicleType['@id'],
            defaultVehiclesTypes: welcome.parameters.defaultVehiclesTypes,
        }
        API.Parameter.put(localStorage.getItem('society'), data)
            .then((response) => {
                handleLoading(false)
                nextStep()
            })
            .catch((error) => {
                if (error.json) {
                    // setLoading(false)
                    error.json().then((data) => {})
                }
            })
    }

    return (
        <div>
            <div className="width-child-container-welcome-modal flex flex-col rounded-xl bg-white px-4 pt-6 shadow-md lg:px-16">
                <WelcomeTitle label="Modifier vos paramètres" />
                <div className="height-31-rem flex flex-col pt-10">
                    <div className="flex flex-col space-y-3 border-gray-200 p-4 sm:space-y-5 sm:pt-3">
                        <div className="pt-2 ">
                            <ClassicSwitch
                                id="showAllRuns"
                                label="Autoriser les chauffeurs à visualiser les courses de leurs collègues"
                                setValue={handleUsersShowAllRuns}
                                value={welcome.parameters.usersShowAllRuns}
                            />
                        </div>
                        <div className="pt-2 ">
                            <ClassicSwitch
                                id="showUnassignedRuns"
                                label="Autoriser les chauffeurs à visualiser les courses non assignées"
                                setValue={handleUsersShowUnassignedRuns}
                                value={
                                    welcome.parameters.usersShowUnassignedRuns
                                }
                            />
                        </div>
                        <SelectDropDownList
                            icon={''}
                            label="Types de véhicules"
                            options={welcome.parameters.vehicleOptions}
                            value={welcome.parameters.defaultVehiclesTypes}
                            id="role"
                            handleOptionChange={(e) =>
                                handleSelectVehicleTypes(e)
                            }
                            isDisabled={false}
                            isMulti={true}
                            showLabel={true}
                            isSearchable={false}
                        />

                        <SelectDropDownList
                            icon={''}
                            label="Type de véhicule par défaut"
                            options={welcome.parameters.defaultVehiclesTypes}
                            value={welcome.parameters.defaultVehicleType}
                            id="vehicleType"
                            handleOptionChange={(e) =>
                                handleSelectVehicleType(e)
                            }
                            isClearable={false}
                            isDisabled={
                                welcome.parameters.defaultVehiclesTypes === null
                            }
                            showLabel={true}
                            isSearchable={false}
                        />
                    </div>
                </div>
            </div>
            <div className="flex w-full items-center justify-between py-4">
                <SecondaryButton
                    label="Précédent"
                    title="Précédent"
                    action={previousStep}
                    hiddenLabelOnMobile={false}
                />
                <PrimaryButton
                    label="Suivant"
                    title="Suivant"
                    action={handleSaveParameters}
                    hiddenLabelOnMobile={false}
                    loader={welcome.loading}
                />
            </div>
        </div>
    )
}

export default WelcomeParametersConfig
