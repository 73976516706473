import { apiClient, withErrorHandling } from '../apiClient'
import { concat, formatHydraData } from '../../api'

const PATH_KEY = '/notifications'

export const Notifications = {
    fetchAll: async (pagination, itemsPerPage, search) => {
        let path = PATH_KEY
        if (typeof pagination === 'boolean')
            path = concat(path, 'pagination=' + pagination)
        if (itemsPerPage) path = concat(path, 'itemsPerPage=' + itemsPerPage)
        if (search) path = concat(path, 'search=' + search)
        return formatHydraData(await apiClient.get(path))
    },
    put: withErrorHandling(async (notificationId, data) => {
        return await apiClient.put(PATH_KEY + '/' + notificationId, data)
    }),
    putAllSeen: withErrorHandling(async () => {
        return await apiClient.put(PATH_KEY + '-seen')
    }),
}
