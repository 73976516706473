import create from 'zustand'

const usePlanningStore = create((set) => ({
    modifiedRuns: [],
    openPopUp: false,
    date: new Date(),
    setModifiedRuns: (run) => set((state) => [...state.modifiedRuns, run]),
    setOpenPopUp: (openPopUp) => set({ openPopUp: openPopUp }),
    setDate: (date) => set({ date: date }),
    dashboardHidedTeams: [],
    setDashboardHidedTeams: (dashboardHidedTeams) =>
        set({ dashboardHidedTeams: dashboardHidedTeams }),
    dashboardCompletelyHidedTeams: [],
    setDashboardCompletelyHidedTeams: (dashboardCompletelyHidedTeams) =>
        set({ dashboardCompletelyHidedTeams: dashboardCompletelyHidedTeams }),
}))

export default usePlanningStore
