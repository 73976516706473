import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import SimpleSelectDropDownList from '../../Commons/SimpleSelectDropDownList'
import API from '../../../services/api'
import ClassicIntervalTimePickers from '../../Commons/Inputs/ClassicIntervalTimePickers'
import TimeField from 'react-simple-timefield'
import ActionModal from '../ActionModal'
import { useDebounce } from 'use-debounce'
import ModalTextArea from '../../Commons/ModalTextArea'
import SharingOptions from '../../../services/SharingOptions'
import { ArrowRight, Drag, MinusCircle } from 'iconoir-react'

const ActionGroupedRunsView = ({
    run,
    setHasChanged,
    setGroupRunOrderChanged,
    groupedRuns,
    setGroupedRuns,
}) => {
    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result
        if (!destination) {
            return
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        const newGroupedRuns = Array.from(groupedRuns)
        newGroupedRuns.splice(source.index, 1)
        newGroupedRuns.splice(
            destination.index,
            0,
            run.groupedRuns.find((r) => r.id === parseInt(draggableId))
        )

        const newGroupedRunsOrdered = newGroupedRuns.map(
            (groupedRun, index) => {
                if (index === 0) {
                    return {
                        ...groupedRun,
                        departureOrder: index + 1,
                        parent: null,
                    }
                } else {
                    return {
                        ...groupedRun,
                        departureOrder: index + 1,
                        parent: newGroupedRuns[0]['@id'],
                    }
                }
            }
        )
        setGroupedRuns(newGroupedRunsOrdered)

        setHasChanged(true)
        setGroupRunOrderChanged(true)
    }

    const handleRemoveRunFromGroup = (runToRemove) => {
        const newGroupedRuns = Array.from(groupedRuns).filter(
            (r) => r.id !== runToRemove.id
        )

        const newGroupedRunsOrdered = newGroupedRuns.map(
            (groupedRun, index) => {
                if (index === 0) {
                    return {
                        ...groupedRun,
                        departureOrder: index + 1,
                        parent: null,
                    }
                } else {
                    return {
                        ...groupedRun,
                        departureOrder: index + 1,
                        parent: newGroupedRuns[0]['@id'],
                    }
                }
            }
        )
        setHasChanged(true)
        setGroupRunOrderChanged(true)

        setGroupedRuns(newGroupedRunsOrdered)
    }

    return (
        <div className="flex h-full w-full flex-col space-y-6 py-2">
            <div className="flex w-full items-center justify-between py-5 text-2xl">
                <h2 className="font-bold">Options</h2>
            </div>
            <div className="w-full">
                <h2 className="w-full pb-4 text-2xl font-bold">Ordre</h2>
                <div className="flex">
                    <div className="w-full">
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="column-1" key="column-1">
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        className="space-y-2"
                                    >
                                        {groupedRuns.map((r, index) => (
                                            <Draggable
                                                draggableId={r.id.toString()}
                                                index={index}
                                                key={r.id}
                                            >
                                                {(provided) => (
                                                    <div
                                                        className="grid-cols-action-grouped-runs-2 grid"
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <div className="col-span-1 flex h-12 items-center justify-between rounded-lg border bg-white px-2 shadow-mobile">
                                                            <div className="w-1/12">
                                                                {
                                                                    r.departureOrder
                                                                }
                                                            </div>
                                                            <div className="flex w-4/12 font-semibold">
                                                                {r.patientName}
                                                            </div>
                                                            <div className="flex w-6/12 space-x-2 truncate">
                                                                <div className="flex items-center space-x-2 truncate text-sm">
                                                                    <span>
                                                                        {
                                                                            r
                                                                                .pickUpLocation
                                                                                .city
                                                                        }
                                                                    </span>
                                                                    <ArrowRight className="text-xxxs" />
                                                                    <span>
                                                                        {
                                                                            r
                                                                                .depositLocation
                                                                                .city
                                                                        }
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <Drag />
                                                        </div>
                                                        <div className="col-span-1 col-start-2 flex items-center justify-center">
                                                            <button
                                                                title="Retirer"
                                                                onClick={() => {
                                                                    handleRemoveRunFromGroup(
                                                                        r
                                                                    )
                                                                }}
                                                            >
                                                                <MinusCircle className=" cursor-pointer text-2xl text-delete-color hover:text-primary-color md:text-xl" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActionGroupedRunsView
