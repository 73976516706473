import React from 'react'
import { useNavigate } from 'react-router-dom'
import Cancel from '../../Commons/Cancel'

const SignupCancel = () => {
    let history = useNavigate()

    const goToOrderRecap = () => {
        history('/inscription/recapitulatif')
    }

    return (
        <Cancel goBack={goToOrderRecap} labelButton="Retour à l'application" />
    )
}

export default SignupCancel
