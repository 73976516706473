import React from 'react'
import { ACTIONS } from '../../services/Reducers/TeamsReducer'
import SecondaryButton from '../Commons/Buttons/SecondaryButton'
import DeleteButton from '../Commons/Buttons/DeleteButton'
import SelectDateRangePicker from '../Commons/SelectDateRangePicker'
import { ACTIONS as DATE_RANGE_ACTIONS } from '../../services/Reducers/DateRangeReducer'
import { WarningTriangle } from 'iconoir-react'

const ResetModal = ({
    fromDefaultTeams,
    labelAction,
    action,
    dispatch,
    secondaryAction = () => dispatch({ type: ACTIONS.CLOSE_MODAL }),
    dateRange,
    dateRangeDispatch,
}) => {
    return (
        <div className="fixed right-0 bottom-0 z-40 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="min-h-action-modal lg:w-3/8 mx-2 flex flex-col items-center rounded-lg bg-white p-2 text-lg sm:mx-0 sm:w-2/5 lg:text-base">
                <div className="flex h-full w-full flex-col p-2 text-xl">
                    <div className="flex justify-between font-bold text-gray-700">
                        <div>{labelAction}</div>
                        <svg
                            className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() =>
                                dispatch({
                                    type: ACTIONS.CLOSE_MODAL,
                                })
                            }
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                </div>
                <div className="my-auto flex w-full flex-col space-y-6 p-2 pb-8">
                    <p>
                        Attention, les transports des équipes qui ne sont pas
                        présentes dans les équipes par défaut seront
                        désassignés.
                    </p>
                    {fromDefaultTeams && (
                        <>
                            <div className="mx-auto w-1/2">
                                <SelectDateRangePicker
                                    openSelectDatePicker={
                                        dateRange.openSelectDatePicker
                                    }
                                    setOpenSelectDatePicker={(value) => {
                                        dateRangeDispatch({
                                            type: DATE_RANGE_ACTIONS.SET_OPEN_SELECT_DATE_PICKER,
                                            payload: value,
                                        })
                                    }}
                                    startDate={dateRange.dateFrom}
                                    endDate={dateRange.dateTo}
                                    setStartDate={(value) => {
                                        dateRangeDispatch({
                                            type: DATE_RANGE_ACTIONS.SET_DATE_FROM,
                                            payload: value,
                                        })
                                    }}
                                    setEndDate={(value) => {
                                        dateRangeDispatch({
                                            type: DATE_RANGE_ACTIONS.SET_DATE_TO,
                                            payload: value,
                                        })
                                    }}
                                />
                            </div>
                            <p className="flex items-end space-x-2 text-sm italic">
                                <WarningTriangle />
                                <span>
                                    Vous ne pouvez pas réinitialiser des jours
                                    déjà passés.
                                </span>
                            </p>
                        </>
                    )}
                </div>
                <div className="mt-auto flex w-full justify-between">
                    <SecondaryButton
                        title="Annuler"
                        label="Annuler"
                        hiddenLabelOnMobile={false}
                        action={secondaryAction}
                    />
                    <DeleteButton
                        title="Réinitialiser"
                        label="Réinitialiser"
                        action={action}
                        hiddenLabelOnMobile={false}
                    />
                </div>
            </div>
        </div>
    )
}

export default ResetModal
