import React, { useEffect, useState } from 'react'
import API from '../../../services/api'
import NoData from '../NoData'
import Loader from '../../Commons/Loaders/Loader'
import useConfiguratorStore from '../../../stores/Configurator'
import VehicleCardTable from './VehicleCardTable'
import { toast } from 'react-toastify'

const Vehicle = ({ search }) => {
    const [rows, setRows] = useState([])
    const [totalItems, setTotalItems] = useState(1)
    const [firstLoading, setFirstLoading] = useState(true)
    const [loading, setLoading] = useState(true)

    function fetchVehiclesList() {
        setLoading(true)
        API.Vehicles.list(null, null, false, search).then((response) => {
            response.json().then((data) => {
                setRows(data['hydra:member'])
                setTotalItems(data['hydra:totalItems'])
                setFirstLoading(false)
                setLoading(false)
            })
        })
    }

    useEffect(() => {
        fetchVehiclesList()
    }, [search])

    const handleDeleteVehicle = (id) => {
        API.Vehicles.delete(id)
            .then((response) => {
                toast.success('Vehicule supprimé.')
                fetchVehiclesList()
            })
            .catch((error) => {
                toast.warn(
                    'Impossible de supprimer le véhicule car il a été utilisé.'
                )
            })
    }

    return (
        <div>
            {firstLoading ? (
                <Loader />
            ) : rows.length > 0 ? (
                <VehicleCardTable
                    rows={rows}
                    totalItems={totalItems}
                    handleDeleteElement={handleDeleteVehicle}
                    loading={loading}
                />
            ) : (
                <NoData message="Aucun véhicule disponible" />
            )}
        </div>
    )
}

export default Vehicle
