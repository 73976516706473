import {
    CpamInvoiceStatus,
    LotStatus,
} from '../../../../services/InvoiceStatus'

export const LOT_FORM_ACTIONS = {
    FETCH_LOT_FORM: 'FETCH_LOT_FORM',
    ADD_CPAM_INVOICE: 'ADD_CPAM_INVOICE',
    REMOVE_CPAM_INVOICE: 'REMOVE_CPAM_INVOICE',
    SET_PAYING_CENTER: 'SET_PAYING_CENTER',
    FETCH_PAYING_CENTER_LIST: 'FETCH_PAYING_CENTER_LIST',
    FETCH_CPAM_INVOICE_LIST: 'FETCH_CPAM_INVOICE_LIST',
    ADD_TO_GROUPED_CPAM_INVOICES: 'ADD_TO_GROUPED_CPAM_INVOICES',
    REMOVE_FROM_GROUPED_CPAM_INVOICES: 'REMOVE_FROM_GROUPED_CPAM_INVOICES',
}

export const getLotFormInitialState = () => {
    return {
        cpamInvoicesList: [],
        cpamInvoices: [],
        payingCenter: null,
        payingCenters: [],
        loading: false,
        disableActions: false,
    }
}

export const LotFormReducer = (state, action) => {
    switch (action.type) {
        case LOT_FORM_ACTIONS.FETCH_LOT_FORM: {
            return {
                ...state,
                ...action.payload,
                disableActions: action.payload.status.label !== 'Créé',
            }
        }
        case LOT_FORM_ACTIONS.ADD_TO_GROUPED_CPAM_INVOICES:
            return {
                ...state,
                cpamInvoices: [...state.cpamInvoices, ...action.payload],
                cpamInvoicesList: state.cpamInvoicesList.filter(
                    (invoice) =>
                        !action.payload.some((p) => p.id === invoice.id)
                ),
            }
        case LOT_FORM_ACTIONS.REMOVE_FROM_GROUPED_CPAM_INVOICES:
            const idsToRemove = action.payload.map((invoice) => invoice.id)

            return {
                ...state,
                cpamInvoicesList: [
                    ...state.cpamInvoicesList,
                    ...action.payload,
                ],
                cpamInvoices: state.cpamInvoices.filter(
                    (item) => !idsToRemove.includes(item.id)
                ),
            }

        case LOT_FORM_ACTIONS.ADD_CPAM_INVOICE: {
            return {
                ...state,
                cpamInvoices: [...state.cpamInvoices, action.payload],
            }
        }
        case LOT_FORM_ACTIONS.REMOVE_CPAM_INVOICE: {
            return {
                ...state,
                cpamInvoices: state.cpamInvoices.filter(
                    (item) => item.id !== action.payload
                ),
            }
        }
        case LOT_FORM_ACTIONS.SET_PAYING_CENTER: {
            return {
                ...state,
                payingCenter: action.payload,
                cpamInvoices: [],
            }
        }
        case LOT_FORM_ACTIONS.FETCH_PAYING_CENTER_LIST: {
            return {
                ...state,
                payingCenters: action.payload,
            }
        }
        case LOT_FORM_ACTIONS.FETCH_CPAM_INVOICE_LIST: {
            return {
                ...state,
                cpamInvoicesList: action.payload,
            }
        }
        default:
            return state
    }
}
