import DownloadButton from 'components/Commons/Buttons/DownloadButton'
import React, { useEffect, useState } from 'react'
import { SortRunsByGroupAndDepartureTime } from 'tools/Sort'
import { ACTIONS } from 'services/Reducers/PlanningReducer'
import { toast } from 'react-toastify'
import RunModalContainer from 'components/Modal/RunModal/RunModalContainer'
import SelectPlanningTeamsModal from 'components/Modal/PlanningModal/SelectPlanningTeamsModal'
import { Minus, Plus } from 'iconoir-react'
import ShuffledGroupedRunsCard from './Mobile/ShuffledGroupedRunsCard'
import PcShuffledGroupedRunsCard from './Pc/PcShuffledGroupedRunsCard'
import ShuffledRunCard from './Mobile/ShuffledRunCard'
import PcShuffledRunCard from './Pc/PcShuffledRunCard'
import API from 'services/api'
import Loader from 'components/Commons/Loaders/Loader'
import { usePlanningContext } from '../PlanningContext'

const PlanningShuffled = ({ showRunObjectColors }) => {
    const {
        planning,
        dispatch,
        reRender,
        handleClearTeamAndUnassignedRun,
        totalRunsTeam,
        handleOpenRunManageModal,
        handleTeamClicked,
        handleInformationRunModalOpen,
        handleOpenCompetitionModal,
        handleGroupRuns,
        countTeamsRunsSelected,
        handlePdfGeneration,
        handleDisabledButtons,
    } = usePlanningContext()

    const [runs, setRuns] = useState([])
    // const showRunObjectColors = planning.showRunObjectColors
    useEffect(() => {
        const shuffledRuns = planning.teams
            .filter((team) => !team.completelyHided)
            .map((team) => team.runs)
            .reduce((acc, runs) => acc.concat(runs), [])

        setRuns([...shuffledRuns, ...planning.unassignedRuns.flat()])
    }, [planning.teams, planning.unassignedRuns])

    useEffect(() => {
        handleClearTeamAndUnassignedRun()
    }, [])

    const unassignSelectedTeamsRuns = () => {
        // TODO change that to do only one call
        const teamsWithAtLeastOneSelectedRun = planning.teams.filter((team) =>
            team.runs.some((run) => run.selected)
        )

        teamsWithAtLeastOneSelectedRun.forEach((team, index) => {
            const data = {
                unGroup: true,
                runsToUnGroup: team.runs
                    .filter((r) => r.selected)
                    .flatMap((run) => {
                        if (run.groupedRun) {
                            return run.groupedRuns.flatMap((groupedRun) => {
                                return {
                                    id: groupedRun.id,
                                }
                            })
                        } else {
                            return {
                                id: run.id,
                            }
                        }
                    }),
            }
            API.MasterRuns.put(team.id, data).then((data) => {
                if (index === teamsWithAtLeastOneSelectedRun.length - 1) {
                    reRender()
                    toast.success(
                        data.runsToUnGroup.length ||
                            teamsWithAtLeastOneSelectedRun.length > 1
                            ? 'Les transports ont été désassignés.'
                            : 'Le transport a été désassigné.'
                    )
                }
            })
        })
    }

    const handleGroupIconClicked = (team) => {
        let unassignedRunSelected = []
        planning.unassignedRuns.forEach((groupRuns) => {
            unassignedRunSelected = [
                ...unassignedRunSelected,
                ...groupRuns.filter((r) => r.selected),
            ]
        })
        let teamRunsSelected = []
        planning.teams.forEach((team) => {
            const selectedRuns = team.runs.filter((r) => r.selected)
            if (selectedRuns) {
                teamRunsSelected = [...teamRunsSelected, ...selectedRuns]
            }
        })

        handleGroupRuns([...teamRunsSelected, ...unassignedRunSelected], team)
    }

    const onCardClicked = (run) => {
        if (run.partner && run.isGiven) {
            //partner run
            toast.error('Impossible de sélectionner un transport partenaire.')
        } else {
            //team run
            // dispatch({
            //     type: ACTIONS.SET_SELECTED_TEAM_RUN_SELECTED,
            //     payload: {
            //         id: run.id,
            //         selected: !run.selected,
            //     },
            // })
            //team run
            if (run.masterRun) {
                dispatch({
                    type: ACTIONS.SET_TEAM_RUN_SELECTED,
                    payload: {
                        id: run.id,
                        selected: !run.selected,
                        teamId: run.masterRun.id,
                    },
                })
            } else {
                dispatch({
                    type: ACTIONS.SET_UNASSIGNED_RUN_SELECTED,
                    payload: {
                        id: run.id,
                        selected: !run.selected,
                    },
                })
            }
        }
    }

    const onInfoClicked = (run) => {
        dispatch({
            type: ACTIONS.SET_INFO_CARD_CLICKED,
            payload: {
                run: run,
                seriesId: run.series ? run.series.id : 0,
            },
        })
    }

    const countUnassignedRunsSelected = () => {
        return planning.unassignedRuns.reduce((total, unassignedRuns) => {
            const selectedRunsInUnassignedRuns = unassignedRuns.filter(
                (run) => run.selected
            ).length
            return total + selectedRunsInUnassignedRuns
        }, 0)
    }

    const atLeastOneTeamRunAndAnotherRunSelected = () => {
        return countTeamsRunsSelected() + countUnassignedRunsSelected() >= 2
    }

    const atLeastOneRunSelected = () => {
        return countTeamsRunsSelected() + countUnassignedRunsSelected() >= 1
    }

    const atLeastOneTeamRunSelected = () => {
        return countTeamsRunsSelected() >= 1
    }

    const openTeamsList = () => {
        dispatch({ type: ACTIONS.OPEN_TEAMS_LIST, payload: true })
    }

    const openGroupTeamsList = () => {
        dispatch({ type: ACTIONS.OPEN_GROUP_TEAMS_LIST, payload: true })
    }

    return (
        <div>
            {planning.loading ? (
                <Loader />
            ) : (
                <div className="flex flex-col space-y-6 pt-10">
                    <div className="flex flex-row items-center justify-between px-6 lg:pr-5">
                        <div className="text-xl font-bold">Transports</div>
                        <DownloadButton
                            action={handlePdfGeneration}
                            loading={planning.downloadButtonLoading}
                        />
                    </div>
                    <div className="flex flex-col space-y-4 px-4 pb-36 lg:space-y-1 lg:pb-0">
                        {SortRunsByGroupAndDepartureTime(runs).map((run) => {
                            if (run.groupedRun) {
                                return (
                                    <div key={run.id}>
                                        <div className="block lg:hidden">
                                            <ShuffledGroupedRunsCard
                                                run={run}
                                                onCardClicked={onCardClicked}
                                                onInfoClicked={onInfoClicked}
                                                showRunObjectColors={
                                                    showRunObjectColors
                                                }
                                            />
                                        </div>
                                        <div className="hidden lg:block">
                                            <PcShuffledGroupedRunsCard
                                                run={run}
                                                onCardClicked={onCardClicked}
                                                onInfoClicked={onInfoClicked}
                                                showRunObjectColors={
                                                    showRunObjectColors
                                                }
                                            />
                                        </div>
                                    </div>
                                )
                            } else {
                                return (
                                    <div key={run.id}>
                                        <div className="block lg:hidden">
                                            <ShuffledRunCard
                                                run={run}
                                                onCardClicked={onCardClicked}
                                                onInfoClicked={onInfoClicked}
                                                showRunObjectColors={
                                                    showRunObjectColors
                                                }
                                            />
                                        </div>
                                        <div className="hidden lg:block">
                                            <PcShuffledRunCard
                                                run={run}
                                                onCardClicked={onCardClicked}
                                                onInfoClicked={onInfoClicked}
                                                showRunObjectColors={
                                                    showRunObjectColors
                                                }
                                            />
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    </div>
                    <div>
                        {planning.informationRunModalOpen && (
                            <RunModalContainer
                                setModalOpen={handleInformationRunModalOpen}
                                run={planning.clickedRun}
                                masterRunsList={planning.teams.filter(
                                    (team) => !team.isPartner
                                )}
                                statusList={planning.statusList}
                                reRender={reRender}
                                setOpenRunManageModal={handleOpenRunManageModal}
                                setOpenCompetitionModal={
                                    handleOpenCompetitionModal
                                }
                                planning={planning}
                                setDisabledButtons={handleDisabledButtons}
                                disabledButtons={planning.disabledButtons}
                            />
                        )}
                        {planning.openTeamsList && (
                            <SelectPlanningTeamsModal
                                planning={planning}
                                dispatch={dispatch}
                                handleTeamClicked={handleTeamClicked}
                                totalRunsTeam={totalRunsTeam}
                            />
                        )}
                        {planning.openGroupTeamsList && (
                            <SelectPlanningTeamsModal
                                planning={planning}
                                dispatch={dispatch}
                                handleTeamClicked={handleGroupIconClicked}
                                totalRunsTeam={totalRunsTeam}
                                groupSelection={true}
                            />
                        )}
                    </div>
                    <div className="absolute bottom-0 flex w-full flex-row items-center justify-center space-x-4 pb-10 text-5xl lg:right-0 lg:justify-end lg:pr-20 lg:text-3xl">
                        {atLeastOneTeamRunAndAnotherRunSelected() && (
                            <button
                                className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-light-dark-green text-white hover:scale-110 hover:bg-dark-green lg:h-16 lg:w-16"
                                onClick={openGroupTeamsList}
                            >
                                <i className="fas fa-layer-group" />
                            </button>
                        )}
                        {atLeastOneRunSelected() && (
                            <button
                                className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-light-dark-green text-white hover:scale-110 hover:bg-dark-green lg:h-16 lg:w-16"
                                onClick={openTeamsList}
                            >
                                <Plus />
                            </button>
                        )}

                        {atLeastOneTeamRunSelected() && (
                            <button
                                className="flex h-24 w-24 cursor-pointer items-center justify-center rounded-full bg-light-dark-green text-white hover:scale-110 hover:bg-dark-green lg:h-16 lg:w-16"
                                onClick={(e) =>
                                    unassignSelectedTeamsRuns(
                                        e,
                                        planning.selectedTeam
                                    )
                                }
                            >
                                <Minus />
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlanningShuffled
