import React from 'react'

const TextAreaForms = ({
    label,
    infoLabel,
    classNameLabel,
    value,
    id,
    placeholder,
    onChange,
    required,
    className,
    disabled,
    error,
}) => {
    return (
        <div
            className={`flex flex-col lg:flex-row w-full items-center space-y-2 lg:space-y-0 ${className}`}
        >
            {label && (
                <label
                    className={`flex lg:w-1/3 w-full font-semibold ${classNameLabel}`}
                >
                    {label}
                    {infoLabel && (
                        <i
                            className="far fa-question-circle pl-2 fa-lg cursor-pointer"
                            title={infoLabel}
                        />
                    )}
                </label>
            )}
            <textarea
                className={`flex appearance-none w-full text-gray-700 h-40 pl-4 leading-tight border rounded focus:outline-none py-4
                     form
                focus:outline-none border-gray-300 focus:border-lighter-blue-green hover:border-lighter-blue-green
                     ${error ? ' border-red-500 ' : ''}
                  ${label ? ' lg:w-2/3 ' : ''}`}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required={required}
                disabled={disabled}
            />
        </div>
    )
}

export default TextAreaForms
