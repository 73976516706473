import React from 'react'
import { Download, PerspectiveView } from 'iconoir-react'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import DownloadButton from '../../../Commons/Buttons/DownloadButton'

const TeamsList = ({
    planning,
    handleSelectedTeam,
    totalRunsTeam,
    handleSelectedUnassignedRuns,
    handleGlobalView,
    handlePdfGeneration,
}) => {
    return (
        <div className="flex flex-col space-y-8">
            <div className="flex flex-col space-y-6 px-6 pt-10">
                <div className="flex flex-row items-center justify-between lg:pr-5">
                    <div className="text-xl font-bold">Équipes</div>
                    <div className="flex space-x-4">
                        <SecondaryButton
                            icon={<PerspectiveView />}
                            action={handleGlobalView}
                            label="Vue globale"
                        />

                        <DownloadButton
                            action={handlePdfGeneration}
                            loading={planning.downloadButtonLoading}
                        />
                    </div>
                </div>
                {planning.teams
                    .sort((a, b) => a.position - b.position)
                    .filter((team) => !team.completelyHided)
                    .map((team) => (
                        <div
                            className="flex cursor-pointer flex-row rounded bg-white py-8 px-3 shadow-lg"
                            onClick={() => handleSelectedTeam(team)}
                        >
                            <div className="flex w-2/5 flex-col space-y-2 font-bold">
                                <div className="truncate capitalize">
                                    {team.names}
                                </div>
                                <div className="truncate capitalize">
                                    {team.vehicle}
                                </div>
                            </div>
                            <div className="flex w-1/5 flex-col items-center justify-center space-y-2">
                                <div>{team.firstRunHour}</div>
                                <div>{team.lastRunHour}</div>
                            </div>
                            <div className="flex w-2/5 flex-col items-center justify-center space-y-2">
                                {totalRunsTeam(team)} transports
                            </div>
                        </div>
                    ))}

                <div
                    className="flex cursor-pointer flex-row justify-between rounded-lg bg-white py-8 px-3 shadow-lg"
                    onClick={() => handleSelectedUnassignedRuns(true)}
                >
                    <div className="flex w-2/5 flex-col space-y-2">
                        <div className="truncate font-bold">
                            Transports non assignés
                        </div>
                    </div>
                    <div className="flex w-2/5 flex-col items-center justify-center space-y-2">
                        {planning.nbUnassignedRuns} transports
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamsList
