import React, { useEffect, useState } from 'react'
import { Erase, Filter, Settings } from 'iconoir-react'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { ACTIONS } from '../../../services/Reducers/PlanningReducer'
import SimpleSelectDropDownList from '../../Commons/SimpleSelectDropDownList'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import ClassicSwitchV2 from '../../Commons/Inputs/ClassicSwitchV2'
import Checkbox from '../../Commons/Checkboxes/Checkbox'
import InlineCheckbox from '../../Commons/InlineCheckbox'
import usePlanningStore from '../../../stores/Planning'
import Teams from '../../App/Team/Teams'

const TeamsPlanningModal = ({ dispatch, reRender }) => {
    const handleCloseTeamsPlanningModal = () => {
        reRender()
        dispatch({ type: ACTIONS.SET_OPEN_TEAMS_MODAL, payload: false })
    }

    const handleTeams = () => {
        reRender()
        dispatch({ type: ACTIONS.SET_OPEN_TEAMS_MODAL, payload: false })
    }

    return (
        <div className="invisible-scrollbar fullscreen-dashboard-planning fixed left-0 bottom-0 flex h-full w-full overflow-y-scroll bg-gray-600 bg-opacity-50 shadow-md">
            <div className="mx-auto my-auto w-full px-2 md:px-6 lg:px-12">
                <div className="min-height-90  flex flex-col rounded-xl bg-white p-5">
                    <div className=" flex flex-col space-y-8">
                        <Teams />
                    </div>
                    <div className="mt-auto flex justify-between border-t pt-5">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            action={handleCloseTeamsPlanningModal}
                        />
                        <PrimaryButton
                            label="Valider"
                            title="Valider"
                            action={handleTeams}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamsPlanningModal
