import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import React, { useCallback, useEffect, useReducer } from 'react'
import Options from '../../Commons/Options'
import {
    ACTIONS as OPTIONS_ACTIONS,
    OptionsReducer,
    getInitialState,
} from '../../../services/Reducers/OptionsReducer'
import { LongPressDetection } from '../../../tools/LongPressDetection'
import NewActionModal from '../../Modal/NewActionModal'
import { useLongPress } from 'use-long-press'
import { useNavigate, useLocation } from 'react-router-dom'
import { Drag, DragHandGesture } from 'iconoir-react'
import { returnVehicleTypeColorFromVehicleType } from '../../../tools/Utility'
import classNames from 'classnames'
import { ACTIONS } from '../../../services/Reducers/TeamsReducer'
import TabButton from '../../Commons/Buttons/TabButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import API from '../../../services/api'
import { toast } from 'react-toastify'
import NoData from '../NoData'
import Loader from '../../Commons/Loaders/Loader'
import ResetModal from '../../Modal/ResetModal'
import {
    DateRangeReducer,
    getInitialState as getInitialStateDateRange,
} from '../../../services/Reducers/DateRangeReducer'
import { formatDateToApi } from '../../../services/dateUtils'

const TeamListContainer = ({
    teams,
    dispatch,
    handleDeleteElement,
    fetchMasterRunsList,
    fetchDefaultMasterRunsList,
    dateFilter,
}) => {
    let location = useLocation()

    const [dateRange, dateRangeDispatch] = useReducer(
        DateRangeReducer,
        { date: dateFilter },
        getInitialStateDateRange
    )

    const [options, optionsDispatch] = useReducer(
        OptionsReducer,
        {},
        getInitialState
    )

    const handleModalDelete = (rowId) => {
        let row = teams.rows.find((r) => r.id === rowId)
        optionsDispatch({
            type: OPTIONS_ACTIONS.SET_DELETE_MODAL,
            payload: row.usernames,
        })
    }

    const handleHideTeam = (rowId) => {
        const hided = !teams.rows.find((r) => r.id === rowId).hided
        const data = {
            id: rowId,
            hided: hided,
        }
        API.MasterRuns.putHided(data).then((data) => {
            toast.success(
                hided
                    ? 'Équipe cachée avec succès.'
                    : 'Équipe affichée avec succès.'
            )
            fetchMasterRunsList(false)
            optionsDispatch({
                type: OPTIONS_ACTIONS.CANCEL_SHOW_OPTIONS,
            })
        })
    }

    const onDragEnd = (result) => {
        const { destination, source, draggableId } = result
        if (!destination) {
            return
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }

        dispatch({ type: ACTIONS.REORDER_TEAMS, payload: result })
    }

    const handleAction = () => {
        optionsDispatch({
            type: OPTIONS_ACTIONS.SET_MODAL_OPEN,
            payload: false,
        })
        handleDeleteElement(options.selectedRowId)
    }

    const callback = useCallback((event) => {}, [])

    const bind = useLongPress(callback, {
        onStart: (event) => {
            optionsDispatch({
                type: OPTIONS_ACTIONS.MOBILE_SET_SELECTED_ROW_ID,
                payload: parseInt(event.currentTarget.id),
            })
        },
        onFinish: (event) => {
            optionsDispatch({ type: OPTIONS_ACTIONS.SET_SHOW_MOBILE_OPTIONS })
        },
        // onCancel: (event) =>
        //     optionsDispatch({ type: ACTIONS.CANCEL_SHOW_MOBILE_OPTIONS }),
        onMove: (event) => console.log('Detected mouse or touch movement'),
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: true,
        detect: 'touch',
    })

    const teamCard = (team) => {
        return (
            <div
                className={classNames(
                    'parents grid h-14 items-center justify-between rounded-lg border px-4 shadow-mobile hover:bg-gray-50',
                    {
                        'team-drag-and-drop-card-grid-5': teams.dragAndDropView,
                        'team-card-grid-5 cursor-pointer':
                            !teams.dragAndDropView,
                        'bg-white': !team.hided,
                        'bg-gray-100': team.hided,
                    }
                )}
            >
                {teams.dragAndDropView && <Drag />}
                <div>{team.position}</div>
                <div className="text-center">{team.usernames}</div>
                <div className="text-center">
                    <span
                        className={classNames(
                            'rounded-full px-2 text-xs font-semibold',
                            returnVehicleTypeColorFromVehicleType(
                                team.vehicle.type.label
                            ),
                            {}
                        )}
                    >
                        {team.vehicle.label}
                    </span>
                </div>
                <div className="text-center">
                    <span>
                        {team.nbRuns}{' '}
                        {team.nbRuns > 1 ? 'transports' : 'transport'}
                    </span>
                </div>
                {!teams.dragAndDropView && (
                    <div className="relative">
                        <Options
                            dispatch={optionsDispatch}
                            options={options}
                            row={team}
                            handleModalDelete={handleModalDelete}
                            historyPush={() => handleOpenModal(team.id)}
                            handleHideTeam={handleHideTeam}
                            isHidedTeam={team.hided}
                        />
                    </div>
                )}
            </div>
        )
    }

    const handleOpenModal = (id) => {
        dispatch({
            type: ACTIONS.SET_IS_OPENED_MANAGE_WITH_TEAM_ID,
            payload: {
                open: true,
                id: id,
            },
        })
    }
    const defaultTeamCard = (team) => {
        return (
            <div
                className={classNames(
                    'parents grid h-14 items-center justify-between rounded-lg border bg-white px-4 shadow-mobile',
                    {
                        'team-drag-and-drop-card-grid-4': teams.dragAndDropView,
                        'team-card-grid-4 cursor-pointer':
                            !teams.dragAndDropView,
                    }
                )}
            >
                {teams.dragAndDropView && <Drag />}
                <div>{team.position}</div>
                <div className="text-center">{team.usernames}</div>
                <div className="text-center">
                    <span
                        className={classNames(
                            'rounded-full px-2 text-xs font-semibold',
                            returnVehicleTypeColorFromVehicleType(
                                team.vehicle.type.label
                            ),
                            {}
                        )}
                    >
                        {team.vehicle.label}
                    </span>
                </div>
                {!teams.dragAndDropView && (
                    <div className="relative">
                        <Options
                            dispatch={optionsDispatch}
                            options={options}
                            row={team}
                            handleModalDelete={handleModalDelete}
                            historyPush={() => handleOpenModal(team.id)}
                        />
                    </div>
                )}
            </div>
        )
    }

    const saveChangedTeamsPositions = () => {
        const data = teams.rows.map((r) => {
            return {
                id: r.id,
                position: r.position,
            }
        })
        if (teams.defaultTeamsView) {
            API.DefaultMasterRuns.putPositions(data).then((data) => {
                toast.success('Les positions ont été mises à jour')
                fetchDefaultMasterRunsList(false)
            })
        } else {
            API.MasterRuns.putPositions(data).then((data) => {
                toast.success('Les positions ont été mises à jour')
                fetchMasterRunsList(false)
            })
        }
    }

    const handleDefaultTeamsView = (value) => {
        dispatch({
            type: ACTIONS.SWITCH_DEFAULT_TEAMS_VIEW,
            payload: value,
        })
    }

    const handleResetTeams = () => {
        const data = {
            date: formatDateToApi(dateFilter, true),
        }

        API.MasterRuns.reset(data).then((d) => {
            fetchMasterRunsList(false)
            toast.success('Les équipes ont été réinitialisées.')
            dispatch({
                type: ACTIONS.SET_OPEN_RESET_TEAM_MODAL,
                payload: false,
            })
        })
    }
    const handleResetFromDefaultTeams = () => {
        const data = {
            dateFrom: formatDateToApi(dateRange.dateFrom, true),
            dateTo: formatDateToApi(dateRange.dateTo, true),
        }

        API.DefaultMasterRuns.reset(data).then((d) => {
            fetchMasterRunsList(false)
            toast.success('Les équipes ont été réinitialisées.')
            dispatch({
                type: ACTIONS.SET_OPEN_RESET_TEAM_MODAL,
                payload: false,
            })
        })
    }

    return (
        <div className="px-2 xl:pt-5 ">
            <div>
                <div className="flex w-full items-center justify-between pb-4">
                    <div className="flex space-x-4 text-center text-lg font-semibold">
                        <TabButton
                            value={!teams.defaultTeamsView}
                            action={() => handleDefaultTeamsView(false)}
                            label="Journalières"
                        />
                        <TabButton
                            value={teams.defaultTeamsView}
                            action={() => handleDefaultTeamsView(true)}
                            label="Par défaut"
                        />
                    </div>
                    <div className="flex space-x-2">
                        {!teams.dragAndDropView ? (
                            <div className="col-span-1 flex lg:col-start-auto lg:justify-end ">
                                {!teams.dragAndDropView && (
                                    <SecondaryButton
                                        label="Modifier l'ordre"
                                        title="Modifier l'ordre"
                                        action={() =>
                                            dispatch({
                                                type: ACTIONS.SET_DRAG_AND_DROP_VIEW,
                                                payload: true,
                                            })
                                        }
                                        icon={<DragHandGesture />}
                                        hiddenLabelOnMobile={true}
                                    />
                                )}
                            </div>
                        ) : (
                            <div className="flex space-x-2">
                                <SecondaryButton
                                    label="Annuler l'ordre"
                                    title="Annuler l'ordre"
                                    action={() =>
                                        dispatch({
                                            type: ACTIONS.SET_DRAG_AND_DROP_VIEW,
                                            payload: false,
                                        })
                                    }
                                    hiddenLabelOnMobile={false}
                                />
                                <PrimaryButton
                                    label="Valider l'ordre"
                                    title="Valider l'ordre"
                                    action={saveChangedTeamsPositions}
                                    hiddenLabelOnMobile={false}
                                />
                            </div>
                        )}
                        <SecondaryButton
                            label={
                                teams.defaultTeamsView
                                    ? 'Réinitialiser globalement'
                                    : 'Réinitialiser'
                            }
                            title="Réinitialiser"
                            action={() => {
                                dispatch({
                                    type: ACTIONS.SET_OPEN_RESET_TEAM_MODAL,
                                    payload: true,
                                })
                            }}
                            hiddenLabelOnMobile={false}
                        />
                    </div>
                </div>
            </div>
            {teams.loading ? (
                <Loader />
            ) : !teams.rows.length && teams.errorMessage ? (
                <NoData message={teams.errorMessage} />
            ) : (
                <>
                    <div className="flex py-2">
                        <div className="ml-auto text-lg font-bold lg:text-xs">
                            {teams.rows.length} équipes{' '}
                            {teams.defaultTeamsView && 'par défaut'}
                        </div>
                    </div>
                    <div
                        className={classNames('', {
                            'modal-teams-height overflow-y-scroll':
                                location.pathname.startsWith('/regulation'),
                        })}
                    >
                        {teams.dragAndDropView ? (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable
                                    droppableId="teamDragAndDrop"
                                    key="teamDragAndDrop"
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                            className="space-y-2"
                                        >
                                            {teams.rows.map((team, index) => (
                                                <div
                                                    {...bind}
                                                    id={team.id}
                                                    key={team.id}
                                                >
                                                    <Draggable
                                                        draggableId={team.id.toString()}
                                                        index={index}
                                                        key={team.id}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                className=""
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                {...provided.placeholder}
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                            >
                                                                {teams.defaultTeamsView
                                                                    ? defaultTeamCard(
                                                                          team
                                                                      )
                                                                    : teamCard(
                                                                          team
                                                                      )}
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                </div>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        ) : (
                            <div className="space-y-2">
                                {teams.rows.map((team, index) => (
                                    <div
                                        {...bind}
                                        id={team.id}
                                        key={team.id}
                                        onClick={() => handleOpenModal(team.id)}
                                    >
                                        {teams.defaultTeamsView
                                            ? defaultTeamCard(team)
                                            : teamCard(team)}
                                    </div>
                                ))}
                            </div>
                        )}
                        {options.modalOpen && (
                            <NewActionModal
                                options={options}
                                action={handleAction}
                                optionsDispatch={optionsDispatch}
                            />
                        )}
                        {teams.openedResetTeamModal && (
                            <ResetModal
                                fromDefaultTeams={teams.defaultTeamsView}
                                labelAction={
                                    teams.defaultTeamsView
                                        ? 'Réinitialiser les équipes selon une plage de date'
                                        : 'Réinitialiser les équipes du jour'
                                }
                                dispatch={dispatch}
                                action={
                                    teams.defaultTeamsView
                                        ? handleResetFromDefaultTeams
                                        : handleResetTeams
                                }
                                dateRange={dateRange}
                                dateRangeDispatch={dateRangeDispatch}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default TeamListContainer
