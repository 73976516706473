import React, { useCallback, useRef, useState } from 'react'
import { ReactComponent as SeriesLogo } from '../../../../files/series_logo.svg'
import { useNavigate, useLocation } from 'react-router-dom'
import { useLongPress } from 'use-long-press'
import TOOLS from '../../../../services/tools'
import Loader from '../../../Commons/Loaders/Loader'
import * as dayjs from 'dayjs'
import { formatEuro } from '../Manage'
import { CpamInvoiceStatus } from '../../../../services/InvoiceStatus'
import { RenderInvoiceId } from '../../../../services/InvoiceUtils'

export const findBorderByStatusType = (status) => {
    const found = CpamInvoiceStatus.find((item) => item.value === status.label)
    return found ? found.border : 'border-l-8 border-gray-200' // Retourne 'bg-gray-200' comme couleur par défaut si le tarifType n'est pas trouvé
}

export const findColorByStatusType = (status) => {
    const found = CpamInvoiceStatus.find((item) => item.value === status.label)
    return found ? found.color : 'bg-gray-200' // Retourne 'bg-gray-200' comme couleur par défaut si le tarifType n'est pas trouvé
}

export const findValueByStatus = (status) => {
    const found = CpamInvoiceStatus.find((item) => item.value === status.label)
    return found ? found.value : 'Créée' // Retourne 'bg-gray-200' comme couleur par défaut si le tarifType n'est pas trouvé
}

const InvoiceCpamListComponent = ({ invoices, loading, totalItems }) => {
    let history = useNavigate()
    const historyPush = (invoice) => {
        const { id } = invoice.runs[0]
        if (!invoice.series) {
            history('/transport/' + id + '?tab=Facture')
        } else {
            history('/series/' + invoice.series.id + '?Series_tab=Facture')
        }
    }

    return (
        <div className="px-2 xl:pt-5">
            <div className="flex py-2">
                <div className="ml-auto text-xs font-bold">
                    {totalItems} Factures
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div className="flex w-full flex-col space-y-2 lg:space-y-0 ">
                    {invoices.map((invoice) => (
                        <div
                            className="parents relative flex w-full pb-2"
                            key={invoice.id}
                            onClick={() => historyPush(invoice)}
                        >
                            <div
                                className={`grid w-full bg-white ${findBorderByStatusType(
                                    invoice.status
                                )} cursor-pointer select-none rounded shadow-mobile lg:select-auto`}
                            >
                                <div
                                    className={`parents row-span-1 flex grid-rows-1  flex-wrap items-center justify-between px-2 py-2  text-sm lg:text-base`}
                                >
                                    <div className="w-1/2 truncate sm:w-1/4 ">
                                        <div className={'font-bold'}>
                                            {invoice.patient.lastname}{' '}
                                            {invoice.patient.firstname}
                                        </div>
                                        <div className={'flex'}>
                                            {invoice.runObject.label}
                                            <div className="flex items-center align-middle">
                                                {invoice.series ? (
                                                    <div className={'pl-3'}>
                                                        <SeriesLogo />
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div>
                                            {invoice.date
                                                ? dayjs(invoice.date).format(
                                                      'DD/MM/YYYY'
                                                  )
                                                : 'Dates multiples'}
                                        </div>
                                    </div>

                                    <div className=" flex w-1/2 truncate  font-bold sm:w-1/4 ">
                                        <div className="flex flex-col items-center">
                                            <RenderInvoiceId
                                                invoiceId={
                                                    invoice.cpamInvoiceId
                                                }
                                            />
                                            {invoice.prescription.rate ===
                                            '100' ? (
                                                <span
                                                    className={
                                                        'flex w-20 justify-center rounded-full bg-blue-100 text-center font-bold text-blue-800'
                                                    }
                                                >
                                                    {invoice.prescription.rate}%
                                                </span>
                                            ) : (
                                                <span
                                                    className={
                                                        'flex w-20 justify-center rounded-full bg-amber-100 font-bold text-amber-800'
                                                    }
                                                >
                                                    {invoice.prescription.rate}%
                                                </span>
                                            )}
                                            {invoice.cpamInvoiceGroup ? (
                                                <span>
                                                    Lot{' '}
                                                    {
                                                        invoice.cpamInvoiceGroup
                                                            .groupId
                                                    }
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div
                                        className={`flex w-1/2 space-x-4 divide-x-2 truncate text-center sm:w-1/4
                                            `}
                                    >
                                        <div className="w-1/2">
                                            <div>
                                                {invoice.payingCenter.label}
                                            </div>
                                            <span className="font-semibold">
                                                {invoice.payingCenterAmount}€
                                            </span>
                                        </div>
                                        {invoice.prescription.rate !== '100' ? (
                                            <div className="jusitfy-center  w-1/2  text-center">
                                                <div>
                                                    {invoice.healthComplementary
                                                        ? invoice
                                                              .healthComplementary
                                                              .label
                                                        : 'Assuré'}
                                                </div>
                                                <div className={'font-bold'}>
                                                    {formatEuro(
                                                        invoice.healthComplementaryAmount
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="flex w-1/2 justify-center truncate sm:w-1/4">
                                        <span
                                            className={`flex w-32 justify-center rounded-full ${findColorByStatusType(
                                                invoice.status
                                            )}
                                            `}
                                        >
                                            {findValueByStatus(invoice.status)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default InvoiceCpamListComponent
