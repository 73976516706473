export const ACTIONS = {
    SET_LOADING: 'setLoading',
    SET_TEAMS: 'setTeams',
    SET_SEARCH: 'setSearch',
    SET_DRAG_AND_DROP_VIEW: 'setDragAndDropView',
    SWITCH_DEFAULT_TEAMS_VIEW: 'setDefaultTeamsView',
    REORDER_TEAMS: 'reorderTeams',
    REORDER_DEFAULT_TEAMS: 'reorderDefaultTeams',
    SET_IS_OPENED_MANAGE: 'setIsOpenedManage',
    SET_IS_OPENED_MANAGE_WITH_TEAM_ID: 'setIsOpenedManageWithTeamId',
    SET_ERROR_MESSAGE: 'setErrorMessage',
    SET_ERROR_MESSAGE_AND_CLEAR_TEAMS: 'SetErrorMessageAndClearTeams',
    SET_OPEN_RESET_TEAM_MODAL: 'setOpenResetTeamModal',
    SET_OPEN_RESET_DEFAULT_TEAM_MODAL: 'setOpenResetDefaultTeamModal',
    CLOSE_MODAL: 'closeModal',
}

export const getInitialState = () => {
    return {
        rows: [],
        oldRows: [],
        loading: true,
        hided: false,
        search: '',
        refreshingSearchInput: false,
        dragAndDropView: false,
        defaultTeamsView: false,
        positionsChanged: false,
        isOpenedManage: false,
        teamId: 0,
        errorMessage: null,
        openedResetTeamModal: false,
        openedResetDefaultTeamModal: false,
    }
}

export const TeamsReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_TEAMS:
            return {
                ...state,
                loading: false,
                rows: action.payload,
                oldRows: [],
                refreshingSearchInput: false,
                positionsChanged: false,
                dragAndDropView: false,
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case ACTIONS.SET_SEARCH:
            return {
                ...state,
                search: action.payload,
                refreshingSearchInput: true,
            }
        case ACTIONS.SET_DRAG_AND_DROP_VIEW:
            return {
                ...state,
                dragAndDropView: action.payload,
                rows: state.positionsChanged ? state.oldRows : state.rows,
            }
        case ACTIONS.SWITCH_DEFAULT_TEAMS_VIEW:
            return {
                ...state,
                defaultTeamsView: action.payload,
                errorMessage: null,
                rows: [],
            }
        case ACTIONS.REORDER_TEAMS:
            const { destination, source, draggableId } = action.payload
            const newTeamsList = Array.from(state.rows)

            const [movedItem] = newTeamsList.splice(source.index, 1)
            newTeamsList.splice(destination.index, 0, movedItem)

            return {
                ...state,
                oldRows: state.oldRows.length ? state.oldRows : state.rows,
                rows: newTeamsList.map((r, index) => {
                    return {
                        ...r,
                        position: index + 1,
                    }
                }),
                positionsChanged: true,
            }
        case ACTIONS.SET_IS_OPENED_MANAGE:
            return {
                ...state,
                isOpenedManage: action.payload,
                teamId: 0,
            }
        case ACTIONS.SET_IS_OPENED_MANAGE_WITH_TEAM_ID:
            return {
                ...state,
                isOpenedManage: action.payload.open,
                teamId: action.payload.id,
            }
        case ACTIONS.SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            }
        case ACTIONS.SET_ERROR_MESSAGE_AND_CLEAR_TEAMS:
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
                rows: [],
            }
        case ACTIONS.SET_OPEN_RESET_TEAM_MODAL:
            return {
                ...state,
                openedResetTeamModal: action.payload,
            }
        case ACTIONS.SET_OPEN_RESET_DEFAULT_TEAM_MODAL:
            return {
                ...state,
                openedResetDefaultTeamModal: action.payload,
            }
        case ACTIONS.CLOSE_MODAL:
            return {
                ...state,
                openedResetTeamModal: false,
                openedResetDefaultTeamModal: false,
            }
        default:
            throw new Error('Action non reconnue')
    }
}
