import React, { useRef, useState } from 'react'
import SecondaryButton from './Buttons/SecondaryButton'
import { ZoomIn } from 'iconoir-react'
import classNames from 'classnames'
import DoughnutChart from './Charts/DoughnutChart'

const PlanningStats = ({
    label,
    icon,
    data,
    backgroundColor,
    hoverBackgroundColor,
    cutout,
    labels,
    title,
}) => {
    const [open, setOpen] = useState(false)
    const ref = useRef(label)

    return (
        <div className="group relative flex w-full">
            <SecondaryButton
                action={() => {
                    setOpen(true)
                }}
                label={label}
                hiddenLabelOnMobile={true}
                icon={icon}
                title={title}
            />
            {open && (
                <div className="z-1000 invisible origin-top-right -translate-y-2 scale-95 transform pt-10 opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100">
                    <div
                        ref={ref}
                        className="min-h-ful absolute right-0 z-20 mt-1 flex h-64 w-64 flex-col rounded-lg border border-secondary-color bg-white py-1 text-base"
                    >
                        <DoughnutChart
                            labels={labels}
                            data={data}
                            backgroundColor={backgroundColor}
                            hoverBackgroundColor={hoverBackgroundColor}
                            cutout={cutout}
                            aspectRatio="2"
                        />
                        <div className="flex w-full flex-col space-y-1 pl-6 pt-6">
                            {labels.map((label, index) => (
                                <div className="flex flex-row items-center space-x-2 text-sm">
                                    <div
                                        key={index}
                                        className="h-2 w-2 rounded-full"
                                        style={{
                                            backgroundColor:
                                                backgroundColor[index],
                                        }}
                                    />
                                    <div>{label}</div> <div>{data[index]}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlanningStats
