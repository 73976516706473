import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'
import React from 'react'

const NoemieFilter = ({ archived, setArchived }) => {
    return (
        <div className="grid grid-cols-2 items-center justify-between gap-4 px-2 pb-2 pt-2 lg:grid-cols-4 ">
            <div className="col-span-1 flex items-center justify-center">
                <ClassicSwitch
                    label="Archives"
                    value={archived}
                    setValue={() => {
                        setArchived(!archived)
                    }}
                    info={true}
                    infoLabel={'Afficher la liste des retours archivés'}
                />
            </div>
        </div>
    )
}

export default NoemieFilter
