import create from 'zustand'

const useSignupStore = create((set) => ({
    password: '',
    passwordConfirmation: '',
    error: null,
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    societyName: '',
    societyId: 0,
    plan: '',
    siren: '',
    nbVehicles: '',
    storeCleared: true,
    existingProcess: false,
    setPassword: (password) => set({ password: password }),
    setPasswordConfirmation: (passwordConfirmation) =>
        set({ passwordConfirmation: passwordConfirmation }),
    setError: (error) => set({ error: error }),
    setEmail: (email) => set({ email: email }),
    setFirstname: (firstname) => set({ firstname: firstname }),
    setLastname: (lastname) => set({ lastname: lastname }),
    setPhone: (phone) => set({ phone: phone }),
    setSocietyName: (societyName) => set({ societyName: societyName }),
    setSocietyId: (societyId) => set({ societyId: societyId }),
    setPlan: (plan) => set({ plan: plan }),
    setSiren: (siren) => set({ siren: siren }),
    setNbVehicles: (nbVehicles) => set({ nbVehicles: nbVehicles }),
    setStoreCleared: (storeCleared) => set({ storeCleared: storeCleared }),
    setExistingProcess: (existingProcess) =>
        set({ existingProcess: existingProcess }),
}))

export default useSignupStore
