export const TarifList = [
    {
        value: 'A',
        label: 'Tarif A',
        color: 'bg-white font-bold',
    },
    {
        value: 'B',
        label: 'Tarif B',
        color: 'bg-yellow-300 text-yellow-800 font-bold',
    },
    {
        value: 'C',
        label: 'Tarif C',
        color: 'bg-blue-300 text-blue-600 font-bold',
    },
    {
        value: 'D',
        label: 'Tarif D',
        color: 'bg-green-300 text-green-600 font-bold',
    },
]
