import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useConfiguratorStore from '../../../stores/Configurator'

const OutstandingBalanceChart = ({ outstandingBalance }) => {
    let navigate = useNavigate()
    const setFilterPatient = useConfiguratorStore(
        (state) => state.setFilterPatient
    )
    const setFilterHealthComplementary = useConfiguratorStore(
        (state) => state.setFilterHealthComplementary
    )
    const setFilterOutstandingBalance = useConfiguratorStore(
        (state) => state.setFilterOutstandingBalance
    )

    const formatData = () => {
        let balanceArray = []

        if (outstandingBalance) {
            // Ajouter les complémentaires santé
            if (outstandingBalance?.healthComplementaries) {
                Object.values(outstandingBalance.healthComplementaries).forEach(
                    (hc) => {
                        balanceArray.push({
                            label: hc.label,
                            value: hc.amount,
                            type: 'healthComplementary',
                            id: hc.id,
                        })
                    }
                )
            }

            // Ajouter les patients
            if (outstandingBalance.patients) {
                Object.values(outstandingBalance?.patients).forEach(
                    (patient) => {
                        balanceArray.push({
                            label: patient.label,
                            value: patient.amount,
                            type: 'patient',
                            id: patient.id,
                        })
                    }
                )
            }

            // Trier par montant décroissant
            balanceArray.sort((a, b) => b.value - a.value)
        }

        return balanceArray
    }

    const balanceArray = formatData()
    const [selectedBar, setSelectedBar] = useState(null)
    const maxValue = Math.max(...balanceArray.map((item) => item.value), 0)

    const handleBarClick = (item) => {
        if (item.type === 'healthComplementary') {
            setFilterPatient(null)
            setFilterHealthComplementary({
                value: item.id,
                label: item.label,
                id: item.id,
            })
        } else {
            setFilterHealthComplementary(null)
            setFilterPatient({
                value: item.id,
                label: item.label,
                id: item.id,
            })
        }
        setFilterOutstandingBalance(true)
        navigate('/facturation/sanitaires')
    }

    return (
        <div className="rounded-lg bg-white p-4 shadow-lg">
            <div className="space-y-2">
                {balanceArray.map((item) => (
                    <div
                        key={`${item.type}-${item.id}`}
                        className="flex items-center"
                    >
                        <div
                            className="relative mr-4 w-full cursor-pointer rounded-sm hover:ring-1 hover:ring-secondary-color"
                            onClick={() => handleBarClick(item)}
                        >
                            <div
                                className={`relative h-10 cursor-pointer rounded-sm bg-secondary-color transition-all duration-300`}
                                style={{
                                    width: `${(item.value / maxValue) * 100}%`,
                                }}
                            ></div>
                            <span className="absolute left-2 top-1/2 w-full -translate-y-1/2 transform text-sm font-medium">
                                {item.label}
                            </span>
                        </div>
                        <span className="w-24 text-right text-sm font-medium">
                            {item.value.toLocaleString('fr-FR', {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 2,
                            })}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OutstandingBalanceChart
