import React from 'react'
import MaskedInput from 'react-text-mask'
import { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'

registerLocale('fr', fr)
const DateInput = ({
    date,
    handleDate,
    showLabel,
    showIcon,
    error,
    disabled,
    placeholder,
}) => {
    const ref = React.createRef()
    return (
        <div className="flex items-center ">
            {showIcon ? <i className="far fa-calendar-alt fa-lg pr-5" /> : ''}
            <MaskedInput
                mask={[
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    '/',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                ]}
                value={date}
                onChange={handleDate}
                ref={ref}
                className={`bg-transparent w-32 h-10 text-gray-700 border-b-2 px-5 focus:appearance-none focus:outline-none ${
                    error
                        ? 'border-red-500'
                        : 'border-gray-300 border-lighter-blue-green'
                } ${
                    disabled ? 'text-opacity-75 bg-gray-300 bg-opacity-25' : ''
                }`}
                placeholder={placeholder}
                disabled={disabled}
            />
        </div>
    )
}

export default DateInput
