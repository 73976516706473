import React from 'react'
import DatePickerButton from './Inputs/DatePickerButton'
import DatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'

registerLocale('fr', fr)

const ClassicDatePicker = ({
    date,
    handleDate,
    showLabel,
    showIcon = true,
}) => {
    const ref = React.createRef()
    return (
        <div className="w-full">
            {showIcon && <i className="far fa-calendar-alt fa-lg pr-2" />}
            <label
                className={`block text-xs font-bold uppercase tracking-wide text-gray-700 ${
                    showLabel ? '' : 'hidden'
                }`}
                htmlFor="taking-day"
            >
                Jour
            </label>
            <DatePicker
                locale="fr"
                selected={date}
                dateFormat="dd/MM/yyyy"
                onChange={handleDate}
                customInput={<DatePickerButton ref={ref} />}
            />
        </div>
    )
}

export default ClassicDatePicker
