import React from 'react'
import dayjs from 'dayjs'
import { formatDateLocal } from '../../../../services/dateUtils'

const DateMobileFirstFormat = ({ row }) => {
    return (
        <div className="flex w-28 justify-center">
            {formatDateLocal(row.date)}
        </div>
    )
}

export default DateMobileFirstFormat
