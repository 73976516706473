import React from 'react'
import WelcomeTemplate from './WelcomeTemplate'
import { ReactComponent as WelcomeSvg } from '../../../files/welcome.svg'

const Welcome = ({ welcome, nextStep }) => {
    const content = () => {
        return (
            <div className="flex h-full flex-col justify-between space-y-4">
                <div className="flex flex-col space-y-4">
                    <div>
                        Merci beaucoup de la confiance que vous nous portez.
                        Vous n'allez pas être déçu 🌟
                    </div>
                    <div>
                        Dans ce court tutoriel nous allons vous aider à
                        configurer votre compte !
                    </div>
                </div>
                <div className="flex justify-center">
                    <WelcomeSvg />
                </div>

                <div className="pb-4">
                    Si vous avez des questions, vous pouvez nous contacter par
                    téléphone au <b>06 61 62 92 60</b> ou par email à l'adresse
                    : <b>contact@heroad.io.</b>
                </div>
            </div>
        )
    }
    return (
        <WelcomeTemplate
            nextStep={nextStep}
            content={content()}
            title="Bienvenue sur Heroad"
            label="Commencer"
        />
    )
}

export default Welcome
