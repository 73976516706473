import API from './api'
import { INVOICE_ACTIONS } from './Reducers/InvoiceReducer'

const getEstablishments = async (dispatch) => {
    const response = await API.Establishments.list()
    const data = await response.json()
    const formatted = data['hydra:member'].map((e) => {
        return {
            value: e['@id'],
            label: `${e.label}`,
            establishment: e,
        }
    })
    dispatch({
        type: INVOICE_ACTIONS.SET_ESTABLISHMENTS,
        payload: formatted,
    })
}

export const FetchInvoiceInformations = {
    getInvoice: async (id, dispatch, location, setInvoiceRuns) => {
        await getEstablishments(dispatch)
        let idToGet = location.search.includes('?copy=')
            ? location.search.split('?copy=')[1]
            : id != 0
            ? id
            : null
        if (idToGet) {
            API.Invoices.get(id)
                .then((response) => {
                    response.json().then((data) => {
                        setInvoiceRuns(data.runs)
                        dispatch({
                            type: INVOICE_ACTIONS.FETCH_INVOICE,
                            payload: data,
                        })
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        } else {
        }
    },
}
