import SendFormButtons from '../../../Commons/Buttons/SendFormButtons'
import React from 'react'
import CalendarDatePicker from '../../../Commons/CalendarDatePickers/CalendarDatePicker'

const SelectDateModalForm = ({
    handleForm,
    handleCloseModal,
    handleDateChange,
    date,
}) => {
    const [openSelectDatePicker, setOpenSelectDatePicker] =
        React.useState(false)
    return (
        <div className="modal fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="min-h-action-modal mx-2 flex flex-col rounded-lg bg-white p-2 text-lg sm:mx-0 sm:w-1/3 lg:text-base">
                <div className="flex flex-col items-start  p-2">
                    <div className="flex w-full pb-4 text-3xl lg:pl-4">
                        <div className="flex space-x-4 text-center font-semibold">
                            Sélectionner une date
                        </div>
                    </div>
                    <div className={'pb-4 pl-4'}>
                        <CalendarDatePicker
                            openSelectDatePicker={openSelectDatePicker}
                            setOpenSelectDatePicker={setOpenSelectDatePicker}
                            date={date}
                            setDate={handleDateChange}
                            customDisplay={'dddd D MMM YYYY'}
                            fullWidth={true}
                            fontSize={'text-md'}
                            mobileLeftWidth={true}
                        />
                    </div>
                </div>
                <SendFormButtons
                    handleForm={handleForm}
                    cancelAction={handleCloseModal}
                    // disabledSubmit={disabledSubmit}
                />
            </div>
        </div>
    )
}

export default SelectDateModalForm
