import React, { useState } from 'react'
import ClassicInput from '../../../Commons/Inputs/ClassicInput'
import ClassicSwitch from '../../../Commons/Inputs/ClassicSwitch'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import { Filter } from 'iconoir-react'
import ModalSkeleton from '../../../Modal/ModalSkeleton'
import CpamInvoiceFilterModal from './CpamInvoiceFilterModal'

const InvoiceCpamFilter = ({
    search,
    status,
    setStatus,
    setSearch,
    archived,
    setArchived,
    openFilterModal,
    setOpenFilterModal,
    patient,
    setPatient,
    payingCenter,
    setPayingCenter,
    healthComplementary,
    setHealthComplementary,
    outstandingBalance,
    setOutstandingBalance,
    applyFilters,
    clearFilters,
}) => {
    const handleForm = () => {
        applyFilters({
            search,
            archived,
            patient,
            payingCenter,
            healthComplementary,
            outstandingBalance,
        })
    }

    const activeFilter =
        search !== '' ||
        patient !== null ||
        status !== null ||
        payingCenter !== null ||
        healthComplementary !== null ||
        outstandingBalance !== null ||
        archived === true

    return (
        <div className="grid grid-cols-2 items-center justify-between gap-4 px-2 pb-2 pt-2 lg:grid-cols-4 ">
            <div className="col-span-1 flex items-center justify-center">
                <i className="fa fa-search pr-2" />
                <ClassicInput
                    label="Rechercher"
                    value={search}
                    setValue={(e) => {
                        setSearch(e.target.value)
                    }}
                    id="search"
                    placeholder="Rechercher..."
                />
            </div>
            <div className="col-span-1 flex w-full justify-between pl-4">
                <div className="flex px-2 ">
                    <SecondaryButton
                        icon={<Filter />}
                        action={() => setOpenFilterModal(true)}
                        label="Filtrer"
                        active={activeFilter}
                    />
                    <button
                        className={`fa fa-times cursor-pointer pl-2 ${
                            activeFilter ? null : 'hidden'
                        } `}
                        onClick={clearFilters}
                    />
                </div>
            </div>
            <div className="col-span-1 flex justify-center">
                <ClassicSwitch
                    label="Archives"
                    value={archived}
                    setValue={() => {
                        setArchived(!archived)
                    }}
                    info={true}
                    infoLabel={'Afficher la liste des factures archivées'}
                />
            </div>
            {openFilterModal && (
                <ModalSkeleton
                    Component={CpamInvoiceFilterModal}
                    componentProps={{
                        patient,
                        setPatient,
                        status,
                        setStatus,
                        setPayingCenter,
                        payingCenter,
                        healthComplementary,
                        setHealthComplementary,
                        outstandingBalance,
                        setOutstandingBalance,
                    }}
                    handleCloseModal={() => setOpenFilterModal(false)}
                    handleForm={handleForm}
                    title={'Filtrer les factures'}
                    buttonLabel={'Confirmer'}
                    label={'Filtrer les factures'}
                />
            )}
        </div>
    )
}

export default InvoiceCpamFilter
