import React from 'react'

const ModalTextArea = ({
    label,
    value,
    placeholder,
    disable,
    setValue,
    id,
    required,
    error,
    height,
}) => {
    return (
        <div className="flex flex-col lg:flex-row w-full items-center space-y-2 lg:space-y-0">
            <label className="flex lg:w-1/3 w-full font-semibold">
                {label}
            </label>
            <textarea
                onChange={setValue}
                className={`flex appearance-none w-full lg:w-2/3 text-gray-700 ${
                    height ? height : 'h-40'
                } pl-4 leading-tight border-2 rounded-xl focus:outline-none py-4
                    ${
                        value === '' || value === undefined
                            ? 'border-gray-300 focus:border-lighter-blue-green'
                            : 'border-lighter-blue-green'
                    } ${error ? 'border-red-500' : ''}`}
                id={id}
                placeholder={placeholder}
                value={value}
                required={required}
                disabled={disable}
            />
        </div>
    )
}

export default ModalTextArea
