import React from 'react'

const FormInput = ({
    label,
    id,
    type,
    placeholder,
    value,
    onChange,
    required,
    min,
    max,
    disabled,
    className,
    classNameLabel,
    error,
    pattern,
    infoLabel,
    inputClassName,
    inputMode,
}) => {
    return (
        <div
            className={`flex w-full flex-col items-center space-y-2 lg:flex-row lg:space-y-0 ${className}`}
        >
            {label && (
                <div
                    className={`flex w-full items-center justify-between font-semibold lg:w-1/3 ${classNameLabel}`}
                >
                    <label>
                        {label}
                        {infoLabel && (
                            <i
                                className="far fa-question-circle fa-lg cursor-pointer pl-2"
                                title={infoLabel}
                            />
                        )}
                    </label>
                </div>
            )}

            <input
                name="form"
                className={`form flex h-14 w-full appearance-none rounded border border-gray-300 pl-4 leading-tight text-gray-700
                hover:border-lighter-blue-green focus:border-lighter-blue-green focus:outline-none lg:h-10 ${inputClassName}
                     ${error ? ' border-red-500 ' : ''}
                  ${label ? ' lg:w-2/3 ' : ''}`}
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required={required}
                min={min}
                max={max}
                disabled={disabled}
                pattern={pattern}
                inputMode={inputMode}
            />
        </div>
    )
}

export default FormInput
