import React, { useEffect, useReducer, useState } from 'react'
import useConfiguratorStore from '../../../stores/Configurator'
import CalendarDatePicker from '../../Commons/CalendarDatePickers/CalendarDatePicker'
import {
    getInitialState,
    INVOICE_ACTIONS,
    InvoiceReducer,
} from '../../../services/Reducers/InvoiceReducer'
import SelectDropDownListV2 from '../../Commons/DropDownLists/SelectDropDownListV2'
import { PaymentMethods } from '../../../services/PaymentMethods'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import ClassicTextarea from '../../Commons/Inputs/ClassicTextarea'
import { InvoiceLine } from './InvoiceLine'
import Loader from '../../Commons/Loaders/Loader'
import API from '../../../services/api'
import * as dayjs from 'dayjs'
import Annexes from './Annexes'
import {
    ERROR_ACTIONS,
    ErrorReducer,
} from '../../../services/Reducers/ErrorReducer'
import Error from '../../Commons/Error'
import { formatRunData } from '../../../services/FormatRuns'
import { FetchInvoiceInformations } from '../../../services/fetchInvoiceInformations'
import maths from 'lodash'
import { toast } from 'react-toastify'
import { PATIENT_ACTIONS } from '../../../services/Reducers/PatientReducer'
import FormSwitch from '../../Commons/Buttons/FormSwitch'
import { List, Plus } from 'iconoir-react'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'

export const formatEuro = (number) => {
    if (isNaN(number)) return ''
    return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    }).format(number)
}

export default function Manage({ setDateFrom, dateFrom, setDateTo, dateTo }) {
    const [openSelectDatePicker, setOpenSelectDatePicker] = useState(false)
    const invoiceRuns = useConfiguratorStore((state) => state.invoiceRuns)
    const setInvoiceRuns = useConfiguratorStore((state) => state.setInvoiceRuns)
    const { id } = useParams()
    const location = useLocation()

    const [errors, dispatchError] = useReducer(ErrorReducer, [])
    const patient = invoiceRuns[0]?.patient
    const history = useNavigate()
    const [invoice, dispatch] = useReducer(
        InvoiceReducer,
        { patient, invoiceRuns, dateTo },
        getInitialState
    )
    const fetchInformations = () => {
        FetchInvoiceInformations.getInvoice(
            id,
            dispatch,
            location,
            setInvoiceRuns
        ).then((response) => {})
    }
    useEffect(() => {
        fetchInformations()
    }, [])

    const calculTTC = (quantity, unitPrice, discount) => {
        return quantity * unitPrice - (discount / 100) * unitPrice * quantity
    }

    const calculHTFromTTC = (ttc, vat) => {
        return ttc / (1 + vat / 100)
    }

    const calculTVAFromTTC = (ttc, vat) => {
        return ttc - ttc / (1 + vat / 100)
    }

    const getTTC = invoice.lines.reduce(
        (acc, line) =>
            acc +
            maths.round(
                calculTTC(line.quantity, line.unitPrice, line.discount),
                2
            ),
        0
    )

    const getTotalHT = invoice.lines.reduce(
        (acc, line) =>
            acc +
            maths.round(
                calculHTFromTTC(
                    calculTTC(line.quantity, line.unitPrice, line.discount),
                    line.vat
                ),
                2
            ),
        0
    )

    const getTotalTVA = invoice.lines.reduce(
        (acc, line) =>
            acc +
            maths.round(
                calculTVAFromTTC(
                    calculTTC(line.quantity, line.unitPrice, line.discount),
                    line.vat
                ),
                2
            ),
        0
    )

    const uniqueVat = [...new Set(invoice.lines.map((line) => line.vat))]

    const getTotalLinesTva = (lines, vat) => {
        return lines.reduce(
            (acc, line) =>
                acc +
                calculTVAFromTTC(
                    calculTTC(line.quantity, line.unitPrice, line.discount),
                    vat
                ),
            0
        )
    }

    const totalLinesTva = () => {
        return uniqueVat.map((vat) =>
            getTotalLinesTva(
                invoice.lines.filter((line) => line.vat === vat),
                vat
            ).toFixed(2)
        )
    }

    const checkErrors = () => {
        let printedErrors = []
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })
        if (!invoice.paymentMethod) {
            printedErrors.push({
                id: 'bad payment method',
                message: 'Veuillez renseigner une méthode de paiement.',
            })
        }
        if (invoice.lines.some((line) => line.label === '')) {
            printedErrors.push({
                id: 'bad label',
                message: 'Veuillez renseigner un label pour chaque ligne.',
            })
        }
        if (invoice.lines.some((line) => line.label.length >= 250)) {
            printedErrors.push({
                id: 'label too long',
                message:
                    'Veuillez renseigner un label en moins de 250 caractères pour chaque ligne',
            })
        }
        if (invoice.patient.patientsAddresses.length === 0) {
            printedErrors.push({
                id: 'bad address',
                message:
                    'Veuillez renseigner une addresse par défaut au client. Patient -> Adresses enregistrée -> Cliquer sur le bouton "Addresse + " -> Renseigner les champs',
            })
        }
        if (printedErrors.length === 0) {
            return false
        } else {
            printedErrors.map((error) => {
                dispatchError({
                    type: ERROR_ACTIONS.SET_ERROR,
                    payload: error,
                })
            })
            return true
        }
    }

    const handleInvoiceSubmit = () => {
        if (!checkErrors()) {
            invoice.lines.forEach((line) => {
                line.totalPrice =
                    line.quantity * line.unitPrice -
                    line.discount * 0.01 * line.unitPrice * line.quantity
            })
            invoice.totalTTC = getTTC
            invoice.totalTVA = getTotalTVA
            invoice.paymentMethod = invoice.paymentMethod.value
                ? invoice.paymentMethod.value
                : invoice.paymentMethod
            invoice.annexes = formatRunData(invoice.annexes)
            invoice.linesVat = totalLinesTva()
            invoice.dateFrom = dayjs(invoiceRuns[0]?.date).format('YYYY-MM-DD')
            invoice.dateTo = dayjs(
                invoiceRuns[invoiceRuns.length - 1]?.date
            ).format('YYYY-MM-DD')

            API.Pdf.invoice(invoice)
                .then((response) => {
                    return response.blob()
                })
                .then((blob) => {
                    toast.success('Facture créée avec succès.')

                    history('/facturation/factures')
                })
                .catch((error) => {
                    console.log('error', error)
                })
        }
    }

    return (
        <div className="container mx-auto flex pb-20 pt-5 text-lg text-gray-800 lg:pb-0 lg:text-sm">
            {invoice.loading ? (
                <Loader />
            ) : (
                <div className="flex w-full flex-col">
                    <div
                        className={`min-h-header-invoice flex flex-wrap items-center justify-between space-x-2 space-y-5 rounded-xl bg-white  p-5 shadow-md lg:space-x-5 lg:space-y-0 lg:px-10 `}
                    >
                        <div className="flex w-full lg:w-auto">
                            <CalendarDatePicker
                                openSelectDatePicker={openSelectDatePicker}
                                setOpenSelectDatePicker={
                                    setOpenSelectDatePicker
                                }
                                date={invoice.date}
                                setDate={(e) => {
                                    setDateFrom(e)
                                    setDateTo(e)
                                    dispatch({
                                        type: INVOICE_ACTIONS.SET_DATE,
                                        payload: e,
                                    })
                                }}
                                label={'Date de facturation'}
                                customDisplay="dddd D MMMM YYYY"
                                fullWidth={true}
                            />
                        </div>
                        <div className="invoice-select flex flex-col truncate">
                            <div className="block pb-2 text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                                {invoice.patient?.isPatient
                                    ? 'Patient'
                                    : 'Client'}
                            </div>
                            <div>
                                {invoice.patient?.lastname}{' '}
                                {invoice.patient?.firstname}
                            </div>

                            <div
                                className={`truncate font-extralight ${
                                    !invoice.patient?.patientsAddresses[0] &&
                                    'font-bold text-orange-400'
                                }`}
                            >
                                {invoice.patient?.patientsAddresses[0]
                                    ? invoice.patient.patientsAddresses[0]
                                          .address.city
                                    : 'Aucune adresse enregistrée'}{' '}
                                {
                                    invoice.patient?.patientsAddresses[0]
                                        ?.address.street
                                }
                            </div>
                        </div>
                        <div className="flex w-full flex-col justify-center lg:w-auto">
                            <div className="block pb-2 text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                                Qui facturer ?
                            </div>
                            <div className="flex h-12 w-full justify-center font-bold lg:w-auto lg:justify-evenly">
                                <FormSwitch
                                    leftLabel={
                                        invoice.patient?.isPatient
                                            ? 'Client'
                                            : 'Patient'
                                    }
                                    rightLabel="Autre"
                                    valueLeft={invoice.isAPersonToBill}
                                    valueRight={!invoice.isAPersonToBill}
                                    onClickLeft={() => {
                                        dispatch({
                                            type: INVOICE_ACTIONS.IS_A_PERSON_TO_BILL,
                                            payload: true,
                                        })
                                    }}
                                    onClickRight={() => {
                                        dispatch({
                                            type: INVOICE_ACTIONS.IS_A_PERSON_TO_BILL,
                                            payload: false,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        {!invoice.isAPersonToBill && (
                            <div className="invoice-select flex flex-col">
                                <SelectDropDownListV2
                                    label={'Etablissement ou tuteur'}
                                    placeholder={'établissement'}
                                    showLabel={true}
                                    value={invoice.invoicer}
                                    options={invoice.establishments}
                                    handleOptionChange={(e) => {
                                        dispatch({
                                            type: INVOICE_ACTIONS.SET_INVOICER,
                                            payload: e,
                                        })
                                    }}
                                />
                            </div>
                        )}

                        <div className="invoice-select flex flex-col">
                            <SelectDropDownListV2
                                label={'Mode de règlement'}
                                placeholder={'Règlement'}
                                showLabel={true}
                                options={PaymentMethods}
                                title={'Mode de règlement'}
                                value={invoice.paymentMethod}
                                handleOptionChange={(e) => {
                                    dispatch({
                                        type: INVOICE_ACTIONS.SET_PAYMENT_METHOD,
                                        payload: e,
                                    })
                                }}
                            />
                        </div>
                        {invoice.invoicer && (
                            <div className="pt-2">
                                <div className="flex w-full flex-col ">
                                    <div className="block text-sm font-bold uppercase tracking-wide text-gray-700 lg:text-xs">
                                        Etablissement ou tuteur
                                    </div>
                                    <div className="flex pt-2">
                                        <label className="w-10  font-bold font-bold  tracking-wide text-gray-700">
                                            Ville
                                        </label>
                                        <p className="pl-2">
                                            {invoice.invoicer.address?.city}
                                        </p>
                                    </div>
                                    <div className="flex">
                                        <label className="w-10  font-bold font-bold   tracking-wide text-gray-700">
                                            Rue
                                        </label>
                                        <p className="pl-2">
                                            {invoice.invoicer.address?.street}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div
                        className={`min-height-52 mt-5 flex w-full flex-wrap items-center space-y-5 rounded-xl  bg-white  p-5 py-10 shadow-md`}
                    >
                        <div className="flex w-full lg:px-5">
                            <div className="flex w-full ">
                                <ClassicTextarea
                                    setValue={(e) =>
                                        dispatch({
                                            type: INVOICE_ACTIONS.SET_COMMENTS,
                                            payload: e.target.value,
                                        })
                                    }
                                    value={invoice.comments}
                                    label={'Observations sur la facture'}
                                />
                            </div>
                        </div>
                        <div className="block flex w-full pb-2 text-sm font-bold uppercase tracking-wide text-gray-700 lg:px-5 lg:text-xs">
                            Facturation des transports du{' '}
                            {dayjs(invoiceRuns[0]?.date).format('DD/MM/YYYY')}{' '}
                            au{' '}
                            {dayjs(
                                invoiceRuns[invoiceRuns.length - 1]?.date
                            ).format('DD/MM/YYYY')}
                        </div>
                        <div className="flex w-full justify-between px-5">
                            <PrimaryButton
                                label="Ligne"
                                title="Ligne"
                                action={() => {
                                    dispatch({
                                        type: INVOICE_ACTIONS.ADD_NEW_LINE,
                                    })
                                }}
                                icon={<Plus className="text-xl" />}
                            />
                            <PrimaryButton
                                label={`Annexes (${
                                    invoice.annexes?.length
                                        ? invoice.annexes.length
                                        : 0
                                })`}
                                title={`Annexes (${
                                    invoice.annexes?.length
                                        ? invoice.annexes.length
                                        : 0
                                })`}
                                action={() => {
                                    dispatch({
                                        type: INVOICE_ACTIONS.SHOW_ANNEXES,
                                        payload: true,
                                    })
                                }}
                                icon={<List />}
                            />
                        </div>

                        <div className="flex w-full flex-col space-y-8 lg:space-y-4 lg:px-5">
                            {invoice.lines.map((line, index) => {
                                return (
                                    <InvoiceLine
                                        key={line.id}
                                        line={line}
                                        dispatch={dispatch}
                                        index={index}
                                        calculTTC={calculTTC}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    <div className="my-5 ml-auto flex flex-col items-center space-x-10 space-y-5 rounded-xl bg-white p-5 shadow-md lg:flex-row lg:space-y-0 lg:p-10">
                        <div className="flex w-56 flex-col space-y-2">
                            <div className="flex justify-between">
                                <label>Total net HT</label>
                                <div>{formatEuro(getTotalHT)}</div>
                            </div>

                            {uniqueVat.map((vat) => (
                                <div className="flex justify-between">
                                    <label>TVA {vat}%</label>
                                    <div>
                                        {getTotalLinesTva(
                                            invoice.lines.filter(
                                                (line) => line.vat === vat
                                            ),
                                            vat
                                        )
                                            .toFixed(2)
                                            .replace('.', ',')}
                                        €
                                    </div>
                                </div>
                            ))}
                            <div className="flex justify-between">
                                <label>Total TVA</label>
                                <div>{formatEuro(getTotalTVA)}</div>
                            </div>
                        </div>
                        <div className="flex w-48 justify-between font-bold">
                            <div className="flex justify-center">TOTAL TTC</div>
                            <div>{formatEuro(getTTC)}</div>
                        </div>
                    </div>
                    <div className="mt-auto flex w-full justify-between border-t px-2 py-5 lg:px-5">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            action={() => history(-1)}
                        />
                        <PrimaryButton
                            label={
                                id !== '0'
                                    ? 'Modifier la facture'
                                    : 'Valider et facturer'
                            }
                            title={
                                id !== '0'
                                    ? 'Modifier la facture'
                                    : 'Valider et facturer'
                            }
                            action={handleInvoiceSubmit}
                            hiddenLabelOnMobile={true}
                        />
                        <div className="fixed right-0 top-0 z-50 mr-5 flex flex flex-col space-y-2 text-gray-700">
                            {errors.map((error) => {
                                return (
                                    <Error
                                        key={error.id}
                                        error={error}
                                        dispatch={dispatchError}
                                    />
                                )
                            })}
                        </div>
                    </div>
                    {invoice.showAnnexes && (
                        <Annexes dispatch={dispatch} invoice={invoice} />
                    )}
                </div>
            )}
        </div>
    )
}
