import React, { useState, useRef, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { debounce } from 'lodash'
import { EstablishmentList } from '../../../services/API/Entities/establishmentList'
import SelectDropDownListV3 from '../../Commons/DropDownLists/SelectDropDownListV3'

const EstablishmentListDropdown = ({
    value,
    onChange,
    label,
    isEstablishment,
}) => {
    const [searchTerm, setSearchTerm] = useState('')

    const debouncedSearchRef = useRef(
        debounce((searchTerm) => {
            setSearchTerm(searchTerm)
        }, 600)
    ).current

    const { data: options, isLoading } = useQuery({
        queryKey: ['establishments', searchTerm],
        queryFn: () =>
            EstablishmentList.fetchAllForDebounceSearch(
                searchTerm,
                isEstablishment
            ),
        enabled: searchTerm.trim() !== '',
        keepPreviousData: true,
    })

    // Fonction de debounce pour la recherche de patients
    const handlePatientSearchChange = useCallback(
        (inputValue) => {
            debouncedSearchRef(inputValue)
        },
        [debouncedSearchRef]
    )

    return (
        <SelectDropDownListV3
            label={label}
            value={value}
            handleOptionChange={onChange}
            options={options || []}
            isLoading={isLoading}
            handleSearchInputPatientChange={handlePatientSearchChange}
            isSearchable={true}
            isClearable={true}
            placeholder={`Rechercher un ${
                isEstablishment ? 'établissement' : 'praticien'
            }`}
        />
    )
}

export default EstablishmentListDropdown
