import React from 'react'
import PrimaryButton from '../../../../Commons/Buttons/PrimaryButton'
import { findColorByTarifType } from '../../../../../services/InvoiceUtils'
import { formatEuro } from '../../../Invoice/Manage'
import { PlusCircle } from 'iconoir-react'

const SeriesRunPricePanel = ({ totals, handleChangePrices, invoice }) => {
    const renderTotalKilometers = (type) => {
        const totalKilometers = totals[`totalKilometer${type}`]
        const color = findColorByTarifType(type)

        return (
            totalKilometers !== 0 && (
                <div className={'col-span-2 w-full flex-col space-y-4 pl-2'}>
                    <div className={'flex w-full justify-between'}>
                        <div className={'flex items-center space-x-2'}>
                            <div
                                className={`h-6 w-6 rounded-full border text-center ${color}`}
                            >
                                {type}
                            </div>
                            <p className="">{totalKilometers} km</p>
                        </div>
                        <p>{formatEuro(totals[`totalAmountPrice${type}`])}</p>
                    </div>
                </div>
            )
        )
    }

    return (
        <div className="mt-auto grid grid-cols-2 gap-4 rounded-lg border-2 bg-white px-8 py-4 shadow-md">
            <div
                className={
                    'col-span-2 flex h-14 items-center justify-between border-b py-2'
                }
            >
                <h2 className={'text-xl font-semibold'}>Série </h2>
                {!invoice.id || invoice?.status?.label === 'Créée' ? (
                    <div className={''}>
                        <PrimaryButton
                            label={'Modifier le calcul'}
                            action={handleChangePrices}
                        />
                    </div>
                ) : null}
            </div>
            <div className="col-span-2  font-semibold">
                <div className={'flex w-full justify-between'}>
                    <p>Tarifs {invoice.priceBasis.label}</p>
                    <p>
                        {totals.totalKilometerA +
                            totals.totalKilometerB +
                            totals.totalKilometerC +
                            totals.totalKilometerD}
                        km
                    </p>
                </div>
            </div>
            {renderTotalKilometers('A')}
            {renderTotalKilometers('B')}
            {renderTotalKilometers('C')}
            {renderTotalKilometers('D')}
            {totals.totalSupplements.length > 0 && (
                <>
                    <div className="col-span-2 border-t pt-4 font-semibold">
                        <div className={'flex w-full justify-between'}>
                            <p>Supplément remboursables </p>
                            {totals.totalSupplementsAmount > 0 && (
                                <p className="font-semibold">
                                    {formatEuro(totals.totalSupplementsAmount)}
                                </p>
                            )}
                        </div>
                    </div>
                    <div
                        className={'col-span-2 w-full flex-col space-y-4 pl-2'}
                    >
                        {totals.totalSupplements.map((supplement, index) => (
                            <div
                                key={index}
                                className={'flex w-full justify-between'}
                            >
                                <p className={'flex items-center'}>
                                    <PlusCircle className={'mr-2'} />
                                    {supplement.label}
                                </p>
                                <p>{formatEuro(supplement.price)}</p>
                            </div>
                        ))}
                    </div>
                </>
            )}
            <div className="col-span-2 border-t pt-4 font-semibold">
                <div className={'flex w-full justify-between'}>
                    <p>Total prise en charge </p>
                    <p className="font-semibold">
                        {formatEuro(totals.totalPecAmount)}
                    </p>
                </div>
            </div>
            {totals.totalNbForfait ? (
                <div className="col-span-2 border-t pt-4 font-semibold">
                    <div className={'flex w-full justify-between'}>
                        <p>Nombre de forfait</p>
                        <p className="font-semibold">{totals.totalNbForfait}</p>
                    </div>
                </div>
            ) : null}
            {totals.totalNbHorokilometric ? (
                <div className="col-span-2 border-t pt-4 font-semibold">
                    <div className={'flex w-full justify-between'}>
                        <p>Nombre de tp au compteur</p>
                        <p className="font-semibold">
                            {totals.totalNbHorokilometric}
                        </p>
                    </div>
                </div>
            ) : null}
            {totals.totalNbMinimumPerception ? (
                <div className="col-span-2 flex items-center justify-between border-t pt-4">
                    <p className="font-semibold"> Minimum de perception</p>
                    <p className="font-semibold">
                        {totals.totalNbMinimumPerception}
                    </p>
                </div>
            ) : null}
            {totals.totalNbKilometric ? (
                <div className="col-span-2 border-t pt-4 font-semibold">
                    <div className={'flex w-full justify-between'}>
                        <p>Nombre de tp kilométrique</p>
                        <p className="font-semibold">
                            {totals.totalNbKilometric}
                        </p>
                    </div>
                </div>
            ) : null}
            {totals.totalNbSharedRun ? (
                <div className="col-span-2 border-t pt-4 font-semibold">
                    <div className={'flex w-full justify-between'}>
                        <p>Nombre de transport partagé</p>
                        <p className="font-semibold">
                            {totals.totalNbSharedRun}
                        </p>
                    </div>
                </div>
            ) : null}
            <div className="col-span-2 border-t  pt-4 font-semibold">
                <div className={'flex w-full justify-between'}>
                    <p>Remise</p>
                    <p> -{formatEuro(totals.totalDiscount)}</p>
                </div>
            </div>
            {totals.totalAbatementAmount > 0 && (
                <div className="col-span-2 border-t  pt-4 font-semibold">
                    <div className={'flex w-full justify-between'}>
                        <p>Abatement</p>
                        <p> -{formatEuro(totals.totalAbatementAmount)}</p>
                    </div>
                </div>
            )}
            <div className="col-span-2 border-t pt-4 font-semibold">
                <div className="-mx-4 flex justify-between rounded-lg bg-gray-100  p-4">
                    <p>Total du calcul </p>
                    <p>{formatEuro(totals.totalAmount)}</p>
                </div>
            </div>
        </div>
    )
}

export default SeriesRunPricePanel
