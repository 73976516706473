import React from 'react'

const TabComponent = ({
    tabs,
    activeTab,
    setActiveTab,
    runForm,
    patientForm,
}) => {
    const isTabDisabled = (tab) => {
        if (tab.disabled) return true
        switch (tab.name) {
            case 'Patient':
                return !patientForm['@id']
            case 'Facture':
                return (
                    !runForm.id ||
                    runForm.series ||
                    (runForm.partner?.id && runForm.isGiven)
                )
            case 'Prescription':
                return runForm.series
            case 'Options':
                return !runForm.id
            default:
                return false
        }
    }

    return (
        <div className="container mx-auto pt-2">
            <div className="flex justify-between border-b lg:justify-normal">
                {tabs.map((tab) => (
                    <button
                        key={tab.name}
                        onClick={() =>
                            !isTabDisabled(tab) && setActiveTab(tab.name)
                        }
                        className={`
              tab-btn flex items-center
              ${activeTab === tab.name ? 'tab-active' : ''}
              ${isTabDisabled(tab) ? 'tab-disabled' : ''}
            `}
                    >
                        <span>{tab.icon}</span>
                        <span className="hidden lg:block">{tab.name}</span>
                    </button>
                ))}
            </div>
            <div>{tabs.find((tab) => tab.name === activeTab)?.component}</div>
        </div>
    )
}

export default TabComponent
