export const concat = (path, param) => {
    if (path.includes('?')) path += '&' + param
    else path += '?' + param

    return path
}

export const dateBetween = (path, between) => {
    return concat(
        path,
        'date[before]=' +
            between.strictlyBefore +
            '&date[after]=' +
            between.strictlyAfter
    )
}

export const addEntityFilter = (path, item, filter) => {
    if (item && typeof item === 'object') {
        if (item.id) {
            return concat(path, filter + '=' + item.id)
        } else if (item['@id']) {
            return concat(
                path,
                filter + '=' + item['@id'].split('/').reverse()[0]
            )
        }
    } else {
        return concat(path, filter + '=' + item)
    }
}

export const addEntityIriFilter = (path, filter) => {
    return concat(path, filter.split('/').reverse()[0])
}
