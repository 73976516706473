import { useState, useCallback, useRef } from 'react'
import { useLongPress } from 'use-long-press'
import { useNavigate, useLocation } from 'react-router-dom'
import TOOLS from '../services/tools'
import { SharingOptionsByType } from '../services/SharingOptions'

export const useRegulationList = () => {
    const navigate = useNavigate()
    const location = useLocation()

    // States
    const [showMobileOptions, setShowMobileOptions] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [showLegendModal, setShowLegendModal] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [selectedRow, setSelectedRow] = useState(null)
    const [styleId, setStyleId] = useState(null)
    const [modalStates, setModalStates] = useState({
        shareModal: false,
        actionModal: false,
        addToSeriesModal: false,
    })

    // Share states
    const [shareData, setShareData] = useState({
        partner: null,
        note: '',
        shareOption: SharingOptionsByType.whatsapp,
        isClearablePartner: false,
        sharedRun: null,
        hasChanged: false,
    })

    // Modal states
    const [modalData, setModalData] = useState({
        label: '',
        warningLabel: '',
        labelAction: '',
        actionButton: '',
        addToSeriesRun: null,
    })

    // Refs
    const optionsRef = useRef()

    // Click outside handler
    TOOLS.useOnClickOutside(optionsRef, () => {
        setShowOptions(false)
        setStyleId(null)
    })

    // Navigation handlers
    const navigationHandlers = {
        historyPush: useCallback(
            (id) => {
                navigate(`${location.pathname}/informations/${id}`)
            },
            [navigate, location]
        ),

        historyPushUpdate: useCallback(
            (id) => {
                navigate(`${location.pathname}/${id}`)
            },
            [navigate, location]
        ),

        historyPushCopy: useCallback(
            (id) => {
                navigate(`/transport/0?copy=${id}`)
            },
            [navigate]
        ),
    }

    // Long press handler
    const bind = useLongPress(
        useCallback(() => {}, []),
        {
            onStart: (event) => {
                const id = parseInt(event.currentTarget.id)
                setSelectedRowId(id)
                setSelectedRow(event.currentTarget)
                setStyleId(id)
            },
            onFinish: () => setShowMobileOptions(true),
            onCancel: () => {
                setSelectedRowId(null)
                setStyleId(null)
                setShowMobileOptions(false)
            },
            threshold: 300,
            captureEvent: true,
            cancelOnMovement: true,
            detect: 'touch',
        }
    )

    // Modal handlers
    const modalHandlers = {
        openShareModal: useCallback((row) => {
            let partnerData = null
            if (row.partner) {
                partnerData = {
                    value: `partners/${row.partner.id}`,
                    label: `${row.partner.firstname} ${row.partner.lastname}`,
                }
            }
            console.log(row)
            setShareData({
                partner: partnerData,
                note: row.note,
                isClearablePartner: !!row.partner,
                sharedRun: row,
                shareOption: SharingOptionsByType.whatsapp,
                hasChanged: false,
            })

            setModalStates((prev) => ({ ...prev, shareModal: true }))
            setShowMobileOptions(false)
        }, []),

        openDeleteModal: useCallback((row) => {
            setModalData({
                label: `Êtes-vous sûr de vouloir supprimer la course ${row.id} ?`,
                actionButton: 'Supprimer',
                labelAction: 'Supprimer la course',
            })
            setModalStates((prev) => ({ ...prev, actionModal: true }))
            setShowMobileOptions(false)
        }, []),

        openAddToSeriesModal: useCallback((row) => {
            setModalData((prev) => ({ ...prev, addToSeriesRun: row }))
            setModalStates((prev) => ({ ...prev, addToSeriesModal: true }))
            setShowMobileOptions(false)
        }, []),
    }

    return {
        states: {
            showMobileOptions,
            showOptions,
            showLegendModal,
            selectedRowId,
            selectedRow,
            styleId,
            modalStates,
            shareData,
            modalData,
        },
        setters: {
            setShowMobileOptions,
            setShowOptions,
            setShowLegendModal,
            setSelectedRowId,
            setSelectedRow,
            setStyleId,
            setModalStates,
            setShareData,
            setModalData,
        },
        handlers: {
            ...navigationHandlers,
            ...modalHandlers,
        },
        refs: {
            optionsRef,
        },
        bind,
    }
}
