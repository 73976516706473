import { dateStringToObject, jsDateToObject } from '../DateService'
import { formatDateFromApiToTimeObject } from '../dateUtils'
import dayjs from 'dayjs'

export const DATE_ACTIONS = {
    SET_DAY: 'setDay',
    SET_MONTH: 'setMonth',
    SET_YEAR: 'setYear',
    SET_DATE: 'setDate',
    ERASE_DATE: 'eraseDate',
}

export const getDateInitialState = () => {
    return { day: '', month: '', year: '' }
}

export const getDateInitialStateFormDate = ({ date }) => {
    date = dayjs(date)
    return {
        day: date.format('DD'),
        month: date.format('MM'),
        year: date.format('YYYY'),
    }
}

export const DateReducer = (state, action) => {
    switch (action.type) {
        case DATE_ACTIONS.SET_DATE:
            return dateStringToObject(action.payload)
        case DATE_ACTIONS.SET_DAY:
            return { ...state, day: action.payload }
        case DATE_ACTIONS.SET_MONTH:
            return { ...state, month: action.payload }
        case DATE_ACTIONS.SET_YEAR:
            return { ...state, year: action.payload }
        case DATE_ACTIONS.ERASE_DATE:
            return getDateInitialState()
        default:
            return state
    }
}
