import React from 'react'
import { ACTIONS } from '../../../services/Reducers/SalesReducer'

const SocietyInformations = ({ sales, dispatch }) => {
    const handleSiren = (value) => {
        dispatch({ type: ACTIONS.SET_SIREN, payload: value })
    }

    const handleNbVehicles = (value) => {
        dispatch({ type: ACTIONS.SET_NB_VEHICLES, payload: value })
    }

    const handleSocietyName = (value) => {
        dispatch({ type: ACTIONS.SET_SOCIETY_NAME, payload: value })
    }

    return (
        <div className="col-span-1 px-6 flex flex-col space-y-4 w-full mx-auto max-w-md pb-5">
            <div className="py-5 font-bold text-2xl">Société</div>
            <div className="w-full">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="societyName"
                >
                    Numéro SIREN de la société
                </label>
                <input
                    value={sales.siren}
                    className={`appearance-none block w-full text-gray-700 h-10 pl-4 leading-tight border rounded-lg focus:outline-none focus:border-lighter-blue-green ${
                        sales.siren.toString().length !== 9 && sales.error
                            ? 'border-red-500'
                            : 'border-gray-300'
                    }`}
                    id="societySiren"
                    type="text"
                    name="societySiren"
                    onChange={(e) => handleSiren(e.target.value)}
                    placeholder="900890789"
                />
            </div>
            <div className="w-full">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="societyName"
                >
                    Dénomination de la société
                </label>
                <input
                    value={sales.societyName}
                    className={`appearance-none block w-full text-gray-700 h-10 pl-4 leading-tight border rounded-lg focus:outline-none focus:border-lighter-blue-green ${
                        !sales.societyName && sales.error
                            ? 'border-red-500'
                            : 'border-gray-300'
                    }`}
                    id="societyName"
                    type="text"
                    name="societyName"
                    onChange={(e) => handleSocietyName(e.target.value)}
                    placeholder="Ambulances/taxis du soleil"
                />
            </div>

            <div className="w-full">
                <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="societyName"
                >
                    Nombre de véhicules de la société
                </label>
                <input
                    value={sales.nbVehicles}
                    className={`appearance-none block w-full text-gray-700 h-10 pl-4 leading-tight border rounded-lg focus:outline-none focus:border-lighter-blue-green ${
                        sales.nbVehicles <= 0 && sales.error
                            ? 'border-red-500'
                            : 'border-gray-300'
                    }`}
                    id="societySiren"
                    type="text"
                    name="societySiren"
                    onChange={(e) => handleNbVehicles(e.target.value)}
                    placeholder="4"
                />
            </div>
        </div>
    )
}

export default SocietyInformations
