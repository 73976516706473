import ClassicInput from '../../Commons/Inputs/ClassicInput'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import React, { useEffect } from 'react'
import { ACTIONS } from '../../../services/Reducers/WelcomeModalReducer'
import UserRoles from '../../../services/UserRoles'
import API from '../../../services/api'
import WelcomeTitle from '../../Commons/WelcomeTitle'
import PreviousButton from '../../Commons/PreviousButton'
import NextButton from '../../Commons/NextButton'
import { ERROR_ACTIONS } from '../../../services/Reducers/ErrorReducer'
import { toast } from 'react-toastify'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'

const WelcomeUserManage = ({
    dispatch,
    welcome,
    nextStep,
    previousStep,
    dispatchError,
    handleLoading,
}) => {
    const handleFirstname = (value) => {
        dispatch({ type: ACTIONS.SET_USER_FIRSTNAME, payload: value })
    }

    const handleLastname = (value) => {
        dispatch({ type: ACTIONS.SET_USER_LASTNAME, payload: value })
    }

    const handlePhone = (value) => {
        dispatch({ type: ACTIONS.SET_USER_PHONE, payload: value })
    }

    const handleEmail = (value) => {
        dispatch({ type: ACTIONS.SET_USER_EMAIL, payload: value })
    }

    const handleRoles = (value) => {
        dispatch({ type: ACTIONS.SET_USER_ROLES, payload: value })
    }

    const handleVehicle = (value) => {
        dispatch({ type: ACTIONS.SET_USER_VEHICLE, payload: value })
    }

    const handleUserId = (user) => {
        dispatch({ type: ACTIONS.SET_USER_ID, payload: user })
    }

    const checkErrors = () => {
        let printedErrors = []
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })

        if (welcome.user.firstname === '') {
            printedErrors.push({
                id: 'emptyFirstname',
                message: 'Veuillez renseigner un prénom.',
            })
        }
        if (welcome.user.lastname === '') {
            printedErrors.push({
                id: 'emptyLastname',
                message: 'Veuillez renseigner un nom.',
            })
        }

        let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
        if (welcome.user.email && !regex.test(welcome.user.email)) {
            printedErrors.push({
                id: 'emptyEmail',
                message: 'Veuillez renseigner un email valide.',
            })
        }

        if (!welcome.user.phone || welcome.user.phone.length < 10) {
            printedErrors.push({
                id: 'badPhone',
                message: 'Veuillez renseigner un numéro de téléphone valide.',
            })
        }
        if (
            !welcome.user.selectedRoles ||
            welcome.user.selectedRoles.length === 0
        ) {
            printedErrors.push({
                id: 'emptyRoles',
                message: 'Veuillez ajouter un rôle à votre utilisateur.',
            })
        }

        if (printedErrors.length === 0) {
            return false
        } else {
            printedErrors.map((error) => {
                dispatchError({
                    type: ERROR_ACTIONS.SET_ERROR,
                    payload: error,
                })
            })
            return true
        }
    }

    const handleAddUser = () => {
        if (!checkErrors()) {
            handleLoading(true)
            let userRolesValues = []

            welcome.user.selectedRoles.forEach((userRole) => {
                userRolesValues.push(userRole.value)
            })

            const data = {
                firstname: welcome.user.firstname,
                lastname: welcome.user.lastname,
                phoneNumber: welcome.user.phone,
                email: welcome.user.email.trim(),
                active: true,
                roles: userRolesValues,
                defaultVehicle: welcome.user.selectedVehicle.value,
            }

            if (!welcome.user.id) {
                API.Users.addUser(data)
                    .then((response) => {
                        response.json().then((user) => {
                            handleLoading(false)
                            handleUserId(user)
                            nextStep()
                        })
                    })
                    .catch((error) => {
                        if (error.json) {
                            error.json().then((data) => {
                                toast.error(
                                    'Cette adresse email est déjà utilisée.',
                                    {
                                        autoClose: 8000,
                                    }
                                )
                            })
                        }
                        handleLoading(false)
                    })
            } else {
                API.Users.put(welcome.user.id, data)
                    .then((response) => {
                        response.json().then((user) => {
                            handleLoading(false)
                            handleUserId(user)
                            nextStep()
                        })
                    })
                    .catch((error) => {
                        console.log('error')
                        if (error.json) {
                            error.json().then((data) => {
                                toast.error(
                                    'Cette adresse email est déjà utilisée.',
                                    {
                                        autoClose: 8000,
                                    }
                                )
                            })
                        }
                        handleLoading(false)
                    })
            }
        }
    }

    return (
        <div>
            <div className="width-child-container-welcome-modal flex flex-col rounded-xl bg-white px-4 pt-6 shadow-md lg:px-16">
                <WelcomeTitle label="Nouvel utilisateur" />
                <div className="height-31-rem flex flex-col pt-10">
                    <div className="sm:grid sm:grid-cols-2 sm:gap-4 ">
                        <div className="col-span-1 py-2">
                            <ClassicInput
                                label="Prénom *"
                                value={welcome.user.firstname}
                                setValue={(e) =>
                                    handleFirstname(e.target.value)
                                }
                                id="firstname"
                                placeholder="Jane"
                                showLabel={true}
                            />
                        </div>
                        <div className="col-span-1 py-2">
                            <ClassicInput
                                label="Nom de famille *"
                                value={welcome.user.lastname}
                                setValue={(e) => handleLastname(e.target.value)}
                                id="lastname"
                                placeholder="Dupont"
                                showLabel={true}
                            />
                        </div>
                        <div className="col-span-1 py-2">
                            <ClassicInput
                                label="Téléphone*"
                                value={welcome.user.phone}
                                setValue={(e) => handlePhone(e.target.value)}
                                id="phone"
                                placeholder="0699876709"
                                showLabel={true}
                            />
                        </div>
                        <div className="col-span-1 py-2">
                            <ClassicInput
                                label="addresse email"
                                value={welcome.user.email}
                                setValue={(e) => handleEmail(e.target.value)}
                                id="email"
                                placeholder="jean@dupont"
                                showLabel={true}
                            />
                        </div>
                        <div className="col-span-1 py-2">
                            <SelectDropDownList
                                icon={''}
                                label="Roles"
                                options={UserRoles}
                                value={welcome.user.selectedRoles}
                                id="role"
                                handleOptionChange={(e) => handleRoles(e)}
                                isClearable={false}
                                isDisabled={false}
                                isMulti={true}
                                showLabel={true}
                                isSearchable={false}
                            />
                        </div>
                        <div className="col-span-1 py-2">
                            <SelectDropDownList
                                icon={''}
                                id="vehicle"
                                label="Véhicule par défaut"
                                options={welcome.vehicleOptions}
                                value={welcome.user.selectedVehicle}
                                handleOptionChange={(event) =>
                                    handleVehicle(event)
                                }
                                isClearable={false}
                                isSearchable={false}
                                isDisabled={false}
                                showLabel={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex w-full items-center justify-between py-4">
                <SecondaryButton
                    label="Précédent"
                    title="Précédent"
                    action={previousStep}
                    hiddenLabelOnMobile={false}
                />
                <PrimaryButton
                    label="Suivant"
                    title="Suivant"
                    action={handleAddUser}
                    hiddenLabelOnMobile={false}
                    loader={welcome.loading}
                />
            </div>
        </div>
    )
}

export default WelcomeUserManage
