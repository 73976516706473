export const ACTIONS = {
    SET_USER: 'setUser',
    SET_USER_SELECTED_ROLES: 'setUserRoles',
    SET_FIRSTNAME: 'setFirstname',
    SET_LASTNAME: 'setLastname',
    SET_PHONE: 'setPhone',
    SET_EMAIL: 'setEmail',
    SET_ACTIVE: 'setActive',
    SET_LOADING: 'setLoading',
    HAS_SCHEDULE_CONFIGURATION: 'setScheduleConfiguration',
    SET_CAN_VIEW_UNASSIGNED_RUNS: 'setCanViewUnassignedRuns',
    SET_CAN_VIEW_ALL_RUNS: 'setCanViewAllRuns',
    SET_HIDE_FUTUR_PLANNING_DAYS_FOR_USER: 'setHideFuturPlanningDaysForUser',
    SET_SHOW_RUNS_STATUS_UPDATES: 'setShowRunsStatusUpdates',
}

export const getInitialState = () => {
    return {
        id: null,
        selectedRoles: [],
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        active: true,
        loading: true,
        roleAdmin: '',
        manageTitle: "Création d'un nouvel utilisateur",
        hasScheduleConfiguration: false,
        canViewUnassignedRuns: null,
        canViewAllRuns: null,
        hideFuturPlanningDaysForUser: false,
        showRunsStatusUpdates: false,
    }
}

export const UserReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_USER:
            return {
                ...state,
                loading: false,
                manageTitle: `Modification de l'utilisateur ${action.payload.lastname} ${action.payload.firstname}`,
                firstname: action.payload.firstname,
                lastname: action.payload.lastname,
                email: action.payload.email,
                active: action.payload.active,
                phone: action.payload.phoneNumber,
                selectedRoles: action.payload.roles,
                hasScheduleConfiguration:
                    action.payload.hasScheduleConfiguration,
                canViewAllRuns: action.payload.canViewAllRuns,
                canViewUnassignedRuns: action.payload.canViewUnassignedRuns,
                showRunsStatusUpdates: action.payload.showRunsStatusUpdates,
                hideFuturPlanningDaysForUser:
                    action.payload.hideFuturPlanningDaysForUser,
            }
        case ACTIONS.SET_FIRSTNAME:
            return {
                ...state,
                firstname: action.payload,
            }
        case ACTIONS.SET_LASTNAME:
            return {
                ...state,
                lastname: action.payload,
            }
        case ACTIONS.SET_PHONE:
            return {
                ...state,
                phone: action.payload,
            }
        case ACTIONS.SET_EMAIL:
            return {
                ...state,
                email: action.payload,
            }
        case ACTIONS.SET_USER_SELECTED_ROLES:
            return {
                ...state,
                selectedRoles: action.payload,
                showRunsStatusUpdates: false,
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case ACTIONS.SET_ACTIVE:
            return {
                ...state,
                active: !state.active,
            }
        case ACTIONS.HAS_SCHEDULE_CONFIGURATION:
            return {
                ...state,
                hasScheduleConfiguration: !state.hasScheduleConfiguration,
            }
        case ACTIONS.SET_CAN_VIEW_ALL_RUNS:
            return {
                ...state,
                canViewAllRuns: !state.canViewAllRuns,
            }
        case ACTIONS.SET_CAN_VIEW_UNASSIGNED_RUNS:
            return {
                ...state,
                canViewUnassignedRuns: !state.canViewUnassignedRuns,
            }
        case ACTIONS.SET_SHOW_RUNS_STATUS_UPDATES:
            return {
                ...state,
                showRunsStatusUpdates: !state.showRunsStatusUpdates,
            }
        case ACTIONS.SET_HIDE_FUTUR_PLANNING_DAYS_FOR_USER:
            return {
                ...state,
                hideFuturPlanningDaysForUser:
                    !state.hideFuturPlanningDaysForUser,
            }

        default:
            throw new Error('Action non reconnue')
    }
}
