import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as Logo } from '../../files/HEROAD2.svg'
import UserMenu from '../Commons/UserMenu'

const MobileHeader = () => {
    const [openUserMenu, setOpenUserMenu] = useState(false)
    const userMenuRef = useRef()
    useOnClickOutside(userMenuRef, () => setOpenUserMenu(false))
    return (
        <div className="mobile-header flex w-full items-center justify-between bg-light-dark-green px-2 py-6 text-xl text-white">
            <Logo className={'w-48'} />
            <div className="flex items-center text-xl text-white">
                <div className="ml-auto flex space-x-2 px-2">
                    <div className="rounded-lg pr-2">
                        <i className="fas fa-bell  fa-lg pr-4" />
                    </div>
                    <div
                        className="cursor-pointer rounded-lg"
                        onClick={() => setOpenUserMenu(!openUserMenu)}
                    >
                        <i className="fas fa-user-cog fa-lg" />
                        {openUserMenu && (
                            <UserMenu userMenuRef={userMenuRef} driver={true} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

// Hook
function useOnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return
            }

            handler(event)
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, handler])
}

export default MobileHeader
