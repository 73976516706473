import React from 'react'

const DepositLocationMobile = ({ row }) => {
    return (
        <div className="flex max-w-xs w-1/2">
            <div className="truncate">
                <i className="fas fa-map-marker-alt pr-2" />
                <span className="truncate">
                    {row.depositLocation.label != null
                        ? row.depositLocation.label
                        : `${
                              row.depositLocation.street
                                  ? row.depositLocation.street + ', '
                                  : ''
                          }
                                                          ${
                                                              row
                                                                  .depositLocation
                                                                  .city
                                                          }`}
                </span>
            </div>
        </div>
    )
}

export default DepositLocationMobile
