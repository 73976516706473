import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import useSignupStore from '../../../stores/Signup'
import API from '../../../services/api'
import gtag from 'ga-gtag'
import Loader from '../../Commons/Loaders/Loader'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'

const OrderRecap = () => {
    let history = useNavigate()
    const setPLan = useSignupStore((state) => state.setPlan)
    const email = useSignupStore((state) => state.email)
    const firstname = useSignupStore((state) => state.firstname)
    const lastname = useSignupStore((state) => state.lastname)
    const phone = useSignupStore((state) => state.phone)
    const societyName = useSignupStore((state) => state.societyName)
    const plan = useSignupStore((state) => state.plan)
    const siret = useSignupStore((state) => state.siret)
    const setError = useSignupStore((state) => state.setError)

    const [loading, setLoading] = useState(false)
    const [acceptedUsedTerms, setAcceptedUsedTerms] = useState(false)

    const handleReturn = () => {
        setLoading(true)
        const data = {
            plan: null,
        }
        API.Authentication.handleSocietyPlan(localStorage.getItem('id'), data)
            .then((response) => {
                response.json().then((token) => {
                    localStorage.setItem('token', token.token)
                    localStorage.setItem(
                        'societyWelcomePresentationPassed',
                        'false'
                    )
                    setPLan('')
                    setError(false)
                    history('/inscription/plans')
                })
            })
            .catch((error) => {
                if (error.json) {
                    error.json().then((data) => {
                        setLoading(false)
                    })
                }
            })
    }

    const handleFreePlan = () => {
        setLoading(true)
        if (checkAcceptedUsedTerms()) {
            API.Authentication.completeInscriptionFreePlan(
                localStorage.getItem('id')
            )
                .then((response) => {
                    response.json().then((token) => {
                        localStorage.setItem('token', token.token)
                        localStorage.setItem(
                            'societyWelcomePresentationPassed',
                            'false'
                        )
                        gtag('event', 'conversion', {
                            send_to: 'AW-10784756791/ZTu2CIDx9aYDELeoyZYo',
                            page_title: 'Abonnement gratuit',
                        })
                        history('/transport')
                    })
                })
                .catch((error) => {
                    if (error.json) {
                        error.json().then((data) => {
                            setLoading(false)
                        })
                    }
                })
        }
    }

    const checkAcceptedUsedTerms = () => {
        if (!acceptedUsedTerms) {
            setError(
                "Veuillez acceptez nos conditions générale de vente et d'utilisation"
            )
            setLoading(false)
            return false
        }
        return true
    }

    const goToStripe = () => {
        setLoading(true)
        if (checkAcceptedUsedTerms()) {
            const data = {
                priceId: plan.stripePriceId,
                societyId: localStorage.getItem('society').replace(/\D/g, ''),
                fromApp: false,
                userId: localStorage.getItem('id'),
                planIri: `/plans/${plan.id}`,
            }
            API.Stripe.CreateCheckout(data, true).then((response) =>
                response.json().then((data) => {
                    window.location.href = data.session.url
                })
            )
        }
    }

    return (
        <div className="px-4">
            <div className="mx-auto w-full max-w-7xl">
                <div className="py-5 text-3xl text-gray-700">
                    Merci de votre confiance.
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <div className="mx-auto w-full max-w-7xl rounded-md bg-white p-4 shadow-md">
                    <div className="flex flex-row-reverse pb-4 pr-4 lg:pb-10 lg:pr-10">
                        <button
                            className="text-blue-600 hover:underline"
                            onClick={handleReturn}
                        >
                            Annuler et modifier mes informations
                        </button>
                    </div>
                    <div className="px-4 md:px-10 lg:px-24">
                        <div className="grid grid-cols-1 lg:grid-cols-2">
                            <div className="col-span-1">
                                <div className="pb-4 text-xl font-semibold text-light-dark-green lg:pb-10 lg:text-3xl">
                                    Récapitulatif de commande
                                </div>
                                <div className="mb-4 flex flex-col space-y-6 rounded bg-gray-200 p-3 lg:hidden">
                                    <div className="text-lg font-semibold text-light-dark-green">
                                        {plan.id === 'free'
                                            ? 'Essai de 14 jours'
                                            : `Plan ${plan.label}`}
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            Montant total soumis à la TVA 20%
                                        </div>
                                        <div>{plan.price} €</div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div>Montant de la TVA</div>
                                        <div>
                                            {plan.id !== 'free'
                                                ? `${parseFloat(
                                                      (plan.price * plan.tva) /
                                                          100
                                                  ).toFixed(2)} €`
                                                : '0 €'}
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div>Montant TTC</div>
                                        <div>
                                            {plan.id !== 'free'
                                                ? `${parseFloat(
                                                      plan.price +
                                                          (plan.price *
                                                              plan.tva) /
                                                              100
                                                  ).toFixed(2)} €`
                                                : '0 €'}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex flex-row justify-between">
                                        <div className="font-semibold text-light-dark-green">
                                            Total
                                        </div>
                                        <div className="font-semibold text-green-500">
                                            {plan.id !== 'free'
                                                ? `${parseFloat(
                                                      plan.price +
                                                          (plan.price *
                                                              plan.tva) /
                                                              100
                                                  ).toFixed(2)} €`
                                                : 'Gratuit'}
                                        </div>
                                    </div>
                                </div>
                                <div className="pb-3 pl-3 text-lg font-semibold text-light-dark-green lg:pb-6 lg:pl-0">
                                    Informations client
                                </div>
                                <div className="grid grid-cols-2 gap-2 pb-16 pl-3 lg:pl-0">
                                    <div className="col-span-1">
                                        <span className="text-sm font-bold text-gray-700">
                                            Email :
                                        </span>{' '}
                                        {email}
                                    </div>
                                    <div className="col-span-1">
                                        <span className="text-sm font-bold text-gray-700">
                                            Société :
                                        </span>{' '}
                                        {societyName}
                                    </div>
                                    <div className="col-span-1">
                                        <span className="text-sm font-bold text-gray-700">
                                            Prénom :
                                        </span>{' '}
                                        {firstname}
                                    </div>

                                    <div className="col-span-1">
                                        <span className="text-sm font-bold text-gray-700">
                                            siret :
                                        </span>{' '}
                                        {siret}
                                    </div>
                                    <div className="col-span-1">
                                        <span className="text-sm font-bold text-gray-700">
                                            Nom :
                                        </span>{' '}
                                        {lastname}
                                    </div>
                                    <div className="col-span-1">
                                        <span className="text-sm font-bold text-gray-700">
                                            Téléphone :
                                        </span>{' '}
                                        {phone}
                                    </div>
                                </div>
                                <div className="flex space-x-2 px-4 lg:px-0">
                                    <input
                                        className="scale-1 mr-4 cursor-pointer"
                                        type="checkbox"
                                        value={acceptedUsedTerms}
                                        checked={acceptedUsedTerms}
                                        onChange={(e) =>
                                            setAcceptedUsedTerms(
                                                e.target.checked
                                            )
                                        }
                                    />
                                    <label className="lg:w-3/5">
                                        En cochant cette case vous acceptez les{' '}
                                        <Link
                                            to="/files/CGV.pdf"
                                            target="_blank"
                                            className="text-blue-600 hover:underline"
                                            download
                                        >
                                            conditions générales de vente
                                        </Link>{' '}
                                        et les{' '}
                                        <Link
                                            to="/files/CGU.pdf"
                                            target="_blank"
                                            className="text-blue-600 hover:underline"
                                            download
                                        >
                                            conditions générales d'utilisation
                                        </Link>{' '}
                                        du logiciel Heroad
                                    </label>
                                </div>
                            </div>
                            <div className="col-span-1">
                                <div className="mx-10 mx-4 mt-8 hidden flex-col space-y-6  rounded bg-gray-200 p-10 lg:flex">
                                    <div className="text-lg font-semibold text-light-dark-green">
                                        {plan.id === 'free'
                                            ? 'Essai de 14 jours'
                                            : `Plan ${plan.label}`}
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            Montant total soumis à la TVA 20%
                                        </div>
                                        <div>{plan.price} €</div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div>Montant de la TVA</div>
                                        <div>
                                            {plan.id !== 'free'
                                                ? `${parseFloat(
                                                      (plan.price * plan.tva) /
                                                          100
                                                  ).toFixed(2)} €`
                                                : '0 €'}
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div>Montant TTC</div>
                                        <div>
                                            {plan.id !== 'free'
                                                ? `${parseFloat(
                                                      plan.price +
                                                          (plan.price *
                                                              plan.tva) /
                                                              100
                                                  ).toFixed(2)} €`
                                                : '0 €'}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex flex-row justify-between">
                                        <div className="font-semibold text-light-dark-green">
                                            Total
                                        </div>
                                        <div className="font-semibold text-green-500">
                                            {plan.id !== 'free'
                                                ? `${parseFloat(
                                                      plan.price +
                                                          (plan.price *
                                                              plan.tva) /
                                                              100
                                                  ).toFixed(2)} €`
                                                : 'Gratuit'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="min-h-50 flex items-center justify-center pt-10 lg:pt-20">
                            <PrimaryButton
                                action={
                                    plan.id === 'free'
                                        ? handleFreePlan
                                        : goToStripe
                                }
                                label={
                                    plan.id === 'free'
                                        ? 'Commencer gratuitement'
                                        : 'Confirmer et payer'
                                }
                                title={
                                    plan.id === 'free'
                                        ? 'Commencer gratuitement'
                                        : 'Confirmer et payer'
                                }
                                loader={loading}
                                hiddenLabelOnMobile={false}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default OrderRecap
