import React, { useEffect, useReducer } from 'react'
import API from '../../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import Loader from '../../Commons/Loaders/Loader'
import useConfiguratorStore from '../../../stores/Configurator'

import { toast } from 'react-toastify'
import {
    DATE_ACTIONS,
    DateReducer,
    getDateInitialState,
} from '../../../services/Reducers/DateReducer'
import {
    hasAllDateParts,
    hasOneDateParts,
    isDateValid,
} from '../../../services/DateService'
import dayjs from 'dayjs'
import { RegimeList } from '../../../services/RegimeList'
import { BirthRank } from '../../../services/BirthRank'
import PatientFormContent from './PatientFormContent'
import {
    fetchHealthComplementaries,
    fetchPayingCenters,
} from '../Regulation/RunTabs'
import {
    PATIENT_FORM_ACTIONS,
    PatientFormReducer,
} from '../Regulation/Reducers/PatientFormReducer'
import { handlePatientForm } from './handlePatientForm'

const Manage = ({ idfromRun = null }) => {
    const setFilterPatient = useConfiguratorStore(
        (state) => state.setFilterPatient
    )
    const [date, dateDispatch] = useReducer(
        DateReducer,
        [],
        getDateInitialState
    )
    let history = useNavigate()
    let { id } = useParams()
    if (idfromRun) {
        id = idfromRun
    }

    const [patient, dispatch] = useReducer(PatientFormReducer, {
        id: null,
        firstname: null,
        lastname: null,
        phoneNumber: null,
        dateOfBirth: null,
        description: null,
        socialNumber: null,
        isPatient: true,
        hasChanged: false,
        loading: id != 0,
        errors: '',
        create: false,
        addresses: null,
        birthRank: BirthRank[0],
        isLunarDate: false,
        beneficiaries: [],
        beneficiaryLink: null,
        patientsAddresses: [],
        patientsContacts: [],
    })

    const checkErrors = () => {
        let msg = []
        if (!patient.firstname) {
            msg.push('Veuillez renseigner un prénom.')
        }
        if (!patient.lastname) {
            msg.push('Veuillez renseigner un nom.')
        }
        if (hasOneDateParts(date)) {
            if (!isDateValid(date, !patient.isLunarDate, patient.isLunarDate)) {
                msg.push('Veuillez renseigner une date de naissance correcte')
            }
        }
        if (
            patient.patientsAddresses.some(
                (address) => !address.address.city || !address.label
            )
        ) {
            msg.push('Veuillez renseigner une adresse complète.')
        }
        const checkIfAtLeastOneDefaultAddressIsChecked =
            patient.patientsAddresses.filter(
                (address) => address.defaultAddress === true
            )
        if (
            patient.patientsAddresses.length > 0 &&
            checkIfAtLeastOneDefaultAddressIsChecked.length !== 1
        ) {
            msg.push('Veuillez renseigner une adresse par défaut')
        }
        if (
            patient.patientsContacts.some(
                (address) =>
                    address.label === '' || address.phoneNumber.length < 14
            )
        ) {
            msg.push('Veuillez renseigner un contact valide')
        }
        if (msg.length > 0) {
            msg.forEach((e) => {
                toast.error(e)
            })
            return true
        }
        return false
    }

    const historyPush = () => {
        history('/ressources/repertoire')
    }

    const getPatient = () => {
        API.Patients.get(id)
            .then((response) => {
                response.json().then((data) => {
                    dispatch({
                        type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                        payload: data,
                    })
                    dateDispatch({
                        type: DATE_ACTIONS.SET_DATE,
                        payload: data.dateOfBirth
                            ? data.dateOfBirth
                            : data.stringDateOfBirth,
                    })
                })
            })
            .catch((error) => {
                if (error.json) {
                    error.json().then((data) => {})
                }
            })
    }

    useEffect(() => {
        fetchHealthComplementaries(dispatch)
        fetchPayingCenters(dispatch)
        if (id != 0) {
            getPatient()
        }
    }, [])

    function handleShowPatientRuns() {
        setFilterPatient({
            value: '/patients/' + id,
            label: patient.firstname + patient.lastname,
        })
        history('/transport')
    }

    return (
        <div className="min-height-90-x container mx-auto flex flex-col pb-20 pt-5 text-lg text-gray-800 lg:pb-0 lg:text-sm">
            {patient.loading ? (
                <Loader />
            ) : (
                <PatientFormContent
                    dispatch={dispatch}
                    patient={patient}
                    date={date}
                    dateDispatch={dateDispatch}
                    id={id}
                    handleShowPatientRuns={handleShowPatientRuns}
                />
            )}
            <SendFormButtons
                historyPush={historyPush}
                handleForm={() => handlePatientForm(patient, date, historyPush)}
            />
        </div>
    )
}

export default Manage
