export const ACTIONS = {
    SET_TEAM: 'setTeam',
    SET_DATE: 'setDate',
    SET_OPEN_SELECT_DATE_PICKER: 'setOpenSelectDatePicker',
    SET_VEHICLES: 'setVehicles',
    SET_SELECTED_VEHICLE: 'setSelectedVehicle',
    SET_USERS: 'setUsers',
    SET_USERS_NOT_SELECTED: 'setUsersNotSelected',
    SET_PARAMEDIC_ONE: 'setParamedicOne',
    SET_PARAMEDIC_TWO: 'setParamedicTwo',
    SET_IS_DISABLED: 'setIsDisabled',
    SET_LOADING: 'setLoading',
}

export const getInitialState = ({ date }) => {
    return {
        id: null,
        loading: true,
        manageTitle: "Ajout d'une nouvelle équipe",
        openSelectDatePicker: false,
        vehicles: [],
        selectedVehicle: null,
        users: [],
        usersNoneSelected: [],
        paramedicOne: null,
        paramedicTwo: null,
        isDisabled: false,
        date: date,
    }
}

export const TeamReducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.SET_TEAM:
            let teamName = action.payload.paramedicOne.label
            if (action.payload.paramedicTwo) {
                teamName = `${teamName} - ${action.payload.paramedicTwo.label}`
            }

            return {
                ...state,
                loading: false,
                manageTitle: `Modification de l'équipe ${teamName}`,
                vehicle: action.payload.vehicle,
                selectedVehicle: action.payload.selectedVehicle,
                paramedicOne: action.payload.paramedicOne,
                paramedicTwo: action.payload.paramedicTwo,
                isDisabled: action.payload.isDisabled,
                date: action.payload.date,
                comments: action.payload.comments,
            }
        case ACTIONS.SET_DATE:
            return {
                ...state,
                date: action.payload,
            }
        case ACTIONS.SET_OPEN_SELECT_DATE_PICKER:
            return {
                ...state,
                openSelectDatePicker: action.payload,
            }
        case ACTIONS.SET_VEHICLES:
            return {
                ...state,
                vehicles: action.payload,
            }
        case ACTIONS.SET_SELECTED_VEHICLE:
            return {
                ...state,
                selectedVehicle: action.payload,
            }
        case ACTIONS.SET_USERS:
            return {
                ...state,
                users: action.payload,
            }
        case ACTIONS.SET_USERS_NOT_SELECTED:
            return {
                ...state,
                usersNotSelected: action.payload,
            }
        case ACTIONS.SET_PARAMEDIC_ONE:
            return {
                ...state,
                paramedicOne: action.payload,
                usersNotSelected: state.users.filter(
                    (user) =>
                        user.value !== action.payload?.value &&
                        user.value !== state.paramedicTwo?.value
                ),
            }
        case ACTIONS.SET_PARAMEDIC_TWO:
            return {
                ...state,
                paramedicTwo: action.payload,
                usersNotSelected: state.users.filter(
                    (user) =>
                        user.value !== action.payload?.value &&
                        user.value !== state.paramedicOne?.value
                ),
            }
        case ACTIONS.SET_IS_DISABLED:
            return {
                ...state,
                isDisabled: action.payload,
            }
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            }

        default:
            throw new Error('Action non reconnue')
    }
}
