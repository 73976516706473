// Custom hook to manage states
import {
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../services/Reducers/AddressAutoCompleteReducer'
import {
    DateReducer,
    getDateInitialState,
    getDateInitialStateFormDate,
} from '../../../services/Reducers/DateReducer'
import FetchRunsInformations from '../../../services/fetchRunsInformations'
import { useEffect, useReducer } from 'react'
import { TRANSPORT_ACTIONS } from './Reducers/TransportFormReducer'

export const useManageStatesAndFetchRunInfo = (
    id,
    run,
    location,
    globalDispatch,
    date,
    fetchHealthComplementaries,
    fetchPayingCenters
) => {
    useEffect(() => {
        globalDispatch({
            type: TRANSPORT_ACTIONS.SET_LOADING,
            payload: true,
        })
        const fetchInformations = async () => {
            await FetchRunsInformations.getRun(
                id,
                globalDispatch,
                run,
                location,
                pickUpDispatch,
                pickUpAddress,
                depositAddress,
                depositDispatch,
                dateDispatch,
                prsDateDispatch,
                dateMaternityDispatch,
                workAccidentDateDispatch,
                priorApprovalDateDispatch
            )
        }
        fetchInformations().then(() => {})
        fetchHealthComplementaries(globalDispatch)
        fetchPayingCenters(globalDispatch)
    }, [id])

    const [dateInput, dateDispatch] = useReducer(
        DateReducer,
        [],
        getDateInitialState
    )
    const [prsDateInput, prsDateDispatch] = useReducer(
        DateReducer,
        { undefined },
        getDateInitialState
    )
    const [realDateInput, realDateDispatch] = useReducer(
        DateReducer,
        { date },
        getDateInitialStateFormDate
    )

    const [workAccidentDate, workAccidentDateDispatch] = useReducer(
        DateReducer,
        { undefined },
        getDateInitialState
    )

    const [priorApprovalDate, priorApprovalDateDispatch] = useReducer(
        DateReducer,
        { undefined },
        getDateInitialState
    )

    const [dateMaternity, dateMaternityDispatch] = useReducer(
        DateReducer,
        { undefined },
        getDateInitialState
    )

    const [pickUpAddress, pickUpDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )
    const [depositAddress, depositDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )

    return {
        dateInput,
        dateDispatch,
        prsDateInput,
        prsDateDispatch,
        realDateInput,
        realDateDispatch,
        pickUpAddress,
        pickUpDispatch,
        depositAddress,
        depositDispatch,
        workAccidentDate,
        workAccidentDateDispatch,
        dateMaternity,
        dateMaternityDispatch,
        priorApprovalDate,
        priorApprovalDateDispatch,
    }
}
