import React, { Fragment } from 'react'
import { formatDateLocal } from '../../../../services/dateUtils'

const PecResponse = ({ data }) => {
    console.log(data)
    const parsedData = data

    const isSanitaryTransport = data?.FACTURE?.isSanitaryTransport
    // [RG_LPS_350] TP AMO OUI
    const isCP1 = data?.FACTURE?.COUVERTURE?.CONTRATS?.NatureAssurance === '10'

    const labels = {
        GrandRegime: 'Grand régime',
        Organisme: 'N° Caisse gestionnaire',
        EntiteGestion: 'N° Centre gestionnaire',
        NatureAssurance: 'Code nature assurance',
        DateMaternite: 'Date Maternité',
        RangBen: 'Rang de Bénéficiaire',
        '@attributes': 'Indicateur de situation particulière du bénéficiaire',
        Contrat: 'Type de contrat',
        IdOrg: 'N° Organisme Complémentaire',
        SituationParticuliere:
            'Indicateur de situation particulière du contrat',
        CadreReglement: 'Destinataire de règlement',
        Exo: 'Justification Exo Ticket Modérateur',
        Tux: 'Taux applicable à la Prestation',
        RMB: 'Tiers Payant Réglementaire AMO',
        // Assurez-vous que "CONTRAT" est lié au libellé approprié.
        // J'ai ajouté "Tiers Payant Réglementaire AMC" pour l'instant.
        // Ajoutez ici d'autres clés et libellés au besoin
    }

    const renderObject = (object, depth = 0, parentKey = '') => {
        console.log(object)
        return Object.entries(object).map(([key, value], index) => {
            if (key === '@attributes') {
                return null // ne pas afficher les propriétés liées à l'id
            }
            if (typeof value === 'object' && value !== null) {
                const header =
                    depth === 1 ? (
                        <div
                            key={key}
                            className="mb-2 mt-4 text-lg font-semibold capitalize underline"
                        >
                            {key.toLowerCase()}
                        </div>
                    ) : null
                return (
                    <Fragment key={`${parentKey}.${key}`}>
                        {header}
                        {renderObject(value, depth + 1, `${parentKey}.${key}`)}
                    </Fragment>
                )
            } else {
                let displayValue = value
                if (key === 'isSanitaryTransport') {
                    return null
                }
                if (key === 'DateMaternite') {
                    const cleanValue = value.split('+')[0] // enlève "+02:00" de la date
                    const date = new Date(cleanValue)
                    displayValue = formatDateLocal(date)
                }
                if (
                    parentKey === '.FACTURE.COUVERTURE.0.CONTRATS' &&
                    key === 'CadreReglement' &&
                    value === 'OUI'
                ) {
                    return null
                }
                if (value === '00') {
                    return null
                }
                if (
                    isCP1 &&
                    isSanitaryTransport &&
                    key === 'CadreReglement' &&
                    value === 'NON'
                ) {
                    console.log('CP')
                }
                if (key === 'CadreReglement' && value === 'OUI') {
                    key = 'Tiers payant réglementaire AMO/AMC'
                }
                if (key === 'CadreReglement' && value === 'NON') {
                    key = 'Tiers payant réglementaire AMO'
                    if (isSanitaryTransport) {
                        displayValue = 'Oui'
                    } else {
                        displayValue = 'Non'
                    }
                }

                return (
                    <div
                        key={`${parentKey}.${key}`}
                        className="flex w-full justify-between py-1 text-lg"
                    >
                        <span className="font-semibold  lg:w-2/4">
                            {labels[key] || key}
                        </span>
                        <span className="capitalize lg:w-2/4">
                            {displayValue.toLowerCase()}
                        </span>
                    </div>
                )
            }
        })
    }
    return (
        <div className="my-2 mb-4 flex flex-col rounded bg-white px-8 pb-8 pt-6 shadow-mobile">
            <p className="text-xl font-bold">
                Information de prise en charge par l'Assurance Maladie :
            </p>
            {renderObject(parsedData)}
        </div>
    )
}

export default PecResponse
