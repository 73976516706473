import React from 'react'
import Loader from '../../../Commons/Loaders/Loader'
import NoData from '../../NoData'
import InvoiceCpamListComponent from './InvoiceCpamListComponent'
import { useQuery } from '@tanstack/react-query'
import { CpamInvoice } from '../../../../services/API/Entities/cpamInvoice'
import { SkeletonLoaderCards } from '../../../Commons/Loaders/SkeletonLoaderCards'

const formatInvoices = (invoices) => {
    return invoices?.map((invoice) => {
        const runs = invoice.runs
        const dates = runs.map((run) => run.date)
        const allDatesAreTheSame = dates.every((date) => date === dates[0])
        invoice.date = allDatesAreTheSame ? dates[0] : null
        return invoice
    })
}

const InvoiceCpamList = ({
    search,
    status,
    archived,
    patient,
    payingCenter,
    healthComplementary,
    outstandingBalance,
}) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [
            'invoices',
            search,
            status,
            archived,
            payingCenter,
            healthComplementary,
            patient,
            outstandingBalance,
        ],
        queryFn: () =>
            CpamInvoice.fetchAll(
                search,
                status,
                archived,
                payingCenter,
                healthComplementary,
                patient,
                outstandingBalance
            ),
        keepPreviousData: true,
    })

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return (
            <NoData message="Une erreur s'est produite lors du chargement des factures." />
        )
    }

    const invoices = data || []
    const totalItems = data?.length || 0

    return (
        <div>
            {invoices?.length > 0 ? (
                <InvoiceCpamListComponent
                    invoices={formatInvoices(invoices)}
                    totalItems={totalItems}
                    loading={isLoading}
                />
            ) : (
                <NoData message="Aucune facture disponible" />
            )}
        </div>
    )
}

export default InvoiceCpamList
