import React, { useRef, useState } from 'react'
import SecondaryButton from '../Buttons/SecondaryButton'
import { ZoomIn } from 'iconoir-react'
import classNames from 'classnames'

const SelectDropDownListForNumber = ({
    label,
    dispatch,
    options,
    handleOptionChange,
    value,
    icon,
    title,
}) => {
    const [open, setOpen] = useState(false)
    const dropdownMenuRef = useRef(label)

    return (
        <div className="group relative flex w-full" tabIndex="-1">
            <SecondaryButton
                action={() => {
                    setOpen(true)
                }}
                label={label}
                hiddenLabelOnMobile={true}
                icon={icon}
                title={title}
            />
            {open && (
                <div className="invisible origin-top-right -translate-y-2 scale-95 transform pt-10 opacity-0 transition-all duration-300 group-focus-within:visible group-focus-within:translate-y-0 group-focus-within:scale-100 group-focus-within:opacity-100">
                    <div
                        ref={dropdownMenuRef}
                        className="min-h-ful width-range-drop-down absolute right-0 z-20 mt-1 flex flex-col rounded-lg border border-secondary-color bg-white py-1 text-base"
                    >
                        {options.map((option) => (
                            <button
                                className={classNames(
                                    'h-8 hover:bg-secondary-color hover:text-white',
                                    {
                                        'bg-secondary-color text-white':
                                            option.value === value.value,
                                    }
                                )}
                                onClick={() => {
                                    handleOptionChange(option)
                                    setOpen(false)
                                }}
                            >
                                <div className="flex justify-center">
                                    <div className="flex w-1/3 justify-start">
                                        <div>{option.label}</div>
                                    </div>
                                </div>
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default SelectDropDownListForNumber
