import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useSignupStore from '../../../stores/Signup'
import API from '../../../services/api'
import Loader from '../../Commons/Loaders/Loader'
import StandardPlanCard from './Plans/StandardPlanCard'
import ExpertPlanCard from './Plans/ExpertPlanCard'
import FreePlanCard from './Plans/FreePlanCard'

const Plans = () => {
    let history = useNavigate()
    const setPLan = useSignupStore((state) => state.setPlan)
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(true)

    const historyBack = () => {
        history('/inscription/societe')
    }

    const handlePlan = (plan) => {
        setPLan(plan)
        const data = {
            plan: plan['@id'],
        }

        API.Authentication.handleSocietyPlan(localStorage.getItem('id'), data)
            .then((response) => {
                response.json().then((token) => {
                    localStorage.setItem('token', token.token)
                    history('/inscription/recapitulatif')
                })
            })
            .catch((error) => {
                if (error.json) {
                    error.json().then((data) => {})
                }
            })
    }

    useEffect(() => {
        API.Plans.get().then((response) => {
            response.json().then((data) => {
                setPlans(data['hydra:member'])
                setLoading(false)
            })
        })
    }, [])

    return (
        <div className="">
            {loading ? (
                <Loader />
            ) : (
                <div>
                    <div className="flex flex-row-reverse pb-4 pr-4 pt-4 lg:pb-10 lg:pr-10">
                        <button
                            className="text-blue-600 hover:underline"
                            onClick={historyBack}
                        >
                            Modifier les informations de ma société
                        </button>
                    </div>
                    <div className="mx-auto max-w-6xl pb-10">
                        <h3 className="pb-4 text-lg font-semibold">
                            Si vous souhaitez payer votre abonnement
                            annuellement :
                        </h3>
                        <p>
                            Veuillez nous contacter par email :{' '}
                            <a
                                className="font-bold"
                                href="mailto:contact@heroad.io"
                            >
                                contact@heroad.io
                            </a>
                        </p>
                        <p>
                            ou par téléphone : Nicolas au{' '}
                            <a class="font-bold" href="tel:06 79 99 97 87">
                                06 79 99 97 87
                            </a>{' '}
                            ou Youenn au{' '}
                            <a
                                className="font-bold"
                                href="tel:06 61 62 92 60"
                                onClick="mobileGtag()"
                            >
                                06 61 62 92 60
                            </a>
                            .
                        </p>
                    </div>
                    <div className="mx-auto grid w-full max-w-6xl grid-cols-1 gap-4 px-4 pb-6 text-gray-700 sm:grid-cols-2 lg:grid-cols-3 xl:px-0">
                        <FreePlanCard
                            handlePlan={handlePlan}
                            plan={plans.find((plan) => plan.id === 'free')}
                        />
                        <StandardPlanCard
                            handlePlan={handlePlan}
                            plan={plans.find((plan) => plan.id === 'standard')}
                        />
                        <ExpertPlanCard
                            handlePlan={handlePlan}
                            plan={plans.find((plan) => plan.id === 'expert')}
                        />
                        {/*<UnlimitedPlanCard*/}
                        {/*    handlePlan={handlePlan}*/}
                        {/*    plan={plans.find((plan) => plan.id === 'unlimited')}*/}
                        {/*/>*/}
                    </div>
                </div>
            )}
        </div>
    )
}

export default Plans
