import React, { useEffect, useReducer, useState } from 'react'
import classNames from 'classnames'
import Welcome from './Welcome'
import WelcomeParameters from './WelcomeParameters'
import WelcomeUsers from './WelcomeUsers'
import WelcomeVehicles from './WelcomeVehicles'
import WelcomeTeams from './WelcomeTeams'
import WelcomeEnds from './WelcomeEnds'
import { getParameters } from '../../../services/fetchParametersInformations'
import {
    ACTIONS,
    WelcomeModalReducer,
    getInitialState,
} from '../../../services/Reducers/WelcomeModalReducer'
import WelcomeParametersConfig from './WelcomeParametersConfig'
import WelcomeVehicleManage from './WelcomeVehicleManage'
import WelcomeUserManage from './WelcomeUserManage'
import WelcomeTeamManage from './WelcomeTeamManage'
import API from '../../../services/api'
import {
    ERROR_ACTIONS,
    ErrorReducer,
} from '../../../services/Reducers/ErrorReducer'
import Error from '../../Commons/Error'
import { toast } from 'react-toastify'

const WelcomeContainer = ({ setWelcomeModalOpen }) => {
    const [welcome, dispatch] = useReducer(
        WelcomeModalReducer,
        [],
        getInitialState
    )

    const [errors, dispatchError] = useReducer(ErrorReducer, [])

    useEffect(() => {
        getParameters(dispatch)
    }, [])

    const nextStep = () => {
        const actualStepId = welcome.steps.find((step) => step.show).id
        if (actualStepId === welcome.steps.length) {
            //last step
            handleCloseModal()
        }
        const newSteps = welcome.steps.map((step) => {
            if (step.show && welcome.steps.length > step.id) {
                step.show = false
            }
            if (step.id === actualStepId + 1) {
                step.show = true
                dispatch({
                    type: ACTIONS.SET_STEP_TO_SHOW,
                    payload: step.id,
                })
            }
            return step
        })

        dispatch({
            type: ACTIONS.SET_STEPS,
            payload: newSteps,
        })
    }

    const previousStep = () => {
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })
        const actualStepId = welcome.steps.find((step) => step.show).id
        const newSteps = welcome.steps.map((step) => {
            if (step.show && step.id > 1) {
                step.show = false
            }
            if (step.id === actualStepId - 1) {
                step.show = true
                dispatch({
                    type: ACTIONS.SET_STEP_TO_SHOW,
                    payload: step.id,
                })
            }
            return step
        })

        dispatch({
            type: ACTIONS.SET_STEPS,
            payload: newSteps,
        })
    }

    const showBorder = (step) => {
        const firstStep = step.id === 1
        const stepJustBeforeActualDisplayedStep =
            step.id - 1 === welcome.stepToShow &&
            welcome.stepToShow !== welcome.steps.length - 1
        const allStepsBeforeShowingStep = step.id <= welcome.stepToShow
        const lastStep = welcome.stepToShow === welcome.steps.length

        return (
            firstStep ||
            allStepsBeforeShowingStep ||
            stepJustBeforeActualDisplayedStep ||
            lastStep
        )
    }

    const handleLoading = (value) => {
        dispatch({
            type: ACTIONS.SET_LOADING,
            payload: value,
        })
    }

    const handleCloseModal = () => {
        const data = {
            welcomePresentationPassed: true,
        }
        API.Society.put(
            localStorage.getItem('society').split('/').reverse()[0],
            data
        )
            .then((res) => {
                toast.success('Tutoriel passé avec succès !')
                localStorage.setItem('societyWelcomePresentationPassed', 'true')
                setWelcomeModalOpen(false)
            })
            .catch(() => {})
    }

    return (
        <div className="fullscreen-dashboard-planning fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50 px-2 lg:px-12">
            <div className="bg-color-light-gray min-h-action-modal max-height-90 min-height-90 flex min-w-full flex-col items-center overflow-y-scroll rounded-lg">
                <div className="flex w-full flex-row items-center justify-center px-20 pb-12 pt-12">
                    {welcome.steps
                        .filter((step) => step.displayed)
                        .map((step) => (
                            <div
                                className={classNames(
                                    'flex h-4 w-full transform justify-end'
                                )}
                            >
                                <div
                                    className={classNames(
                                        'step absolute mt-3 h-1 w-full',
                                        {
                                            stepTransition: showBorder(step),
                                        }
                                    )}
                                />
                                <div
                                    className={`z-10 ml-1 h-8 w-8 rounded-full ${
                                        step.show ||
                                        welcome.stepToShow > step.id
                                            ? 'step stepTransition bg-heroad-pink'
                                            : 'bg-color-light-gray step'
                                    }`}
                                />
                            </div>
                        ))}
                </div>
                <div className="">
                    {
                        {
                            1: (
                                <Welcome
                                    welcome={welcome}
                                    nextStep={nextStep}
                                />
                            ),
                            2: (
                                <WelcomeParameters
                                    welcome={welcome}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            ),
                            3: (
                                <WelcomeParametersConfig
                                    welcome={welcome}
                                    dispatch={dispatch}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                    handleLoading={handleLoading}
                                />
                            ),
                            4: (
                                <WelcomeVehicles
                                    welcome={welcome}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            ),
                            5: (
                                <WelcomeVehicleManage
                                    welcome={welcome}
                                    dispatch={dispatch}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                    dispatchError={dispatchError}
                                    handleLoading={handleLoading}
                                />
                            ),
                            6: (
                                <WelcomeUsers
                                    welcome={welcome}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            ),
                            7: (
                                <WelcomeUserManage
                                    welcome={welcome}
                                    dispatch={dispatch}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                    dispatchError={dispatchError}
                                    handleLoading={handleLoading}
                                />
                            ),
                            8: (
                                <WelcomeTeams
                                    welcome={welcome}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            ),
                            9: (
                                <WelcomeTeamManage
                                    welcome={welcome}
                                    dispatch={dispatch}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                    handleLoading={handleLoading}
                                />
                            ),
                            10: (
                                <WelcomeEnds
                                    welcome={welcome}
                                    previousStep={previousStep}
                                    nextStep={nextStep}
                                />
                            ),
                        }[welcome.stepToShow]
                    }
                </div>

                <div className="fixed right-0 top-0 z-50 mr-5 flex flex-col space-y-2 text-gray-700">
                    {errors.map((error) => {
                        return (
                            <Error
                                key={error.id}
                                error={error}
                                dispatch={dispatchError}
                            />
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default WelcomeContainer
