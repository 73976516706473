import { ReactComponent as Cpam } from '../../../../files/cpam.svg'
import { Bank, Lock, Healthcare, SendEuros } from 'iconoir-react'
import { formatEuro } from '../Manage'
import StatCard from '../Lots/StatCard'
import React from 'react'
import NoemieStatCard from './NoemieStatCard'

export function calculateTotalCPAM(data) {
    return data.cpamInvoiceGroups.reduce((acc, group) => {
        return acc + group.totalInvoiceAmountRefundedByAmo
    }, 0)
}

export function calculateTotalComplementaire(data) {
    return data.cpamInvoiceGroups.reduce((acc, group) => {
        return acc + group.totalInvoiceRefundedByComplementary
    }, 0)
}

function calculateTotal100Percent(data) {
    return data.cpamInvoiceGroups.reduce((acc, group) => {
        return (
            acc +
            group.cpamInvoices.reduce((innerAcc, invoice) => {
                return (
                    innerAcc +
                    (invoice.totalInvoiceAmount === invoice.payingCenterAmount
                        ? 1
                        : 0)
                )
            }, 0)
        )
    }, 0)
}

function calculateTotalInvoicesPaidByPayingCenter(data) {
    return data.cpamInvoiceGroups.reduce((acc, group) => {
        return (
            acc +
            group.cpamInvoices.reduce((innerAcc, invoice) => {
                return (
                    innerAcc +
                    (invoice.paidByPayingCenter
                        ? invoice.healthComplementaryAmount
                        : 0)
                )
            }, 0)
        )
    }, 0)
}

function calculateNbInvoicesPaidByPayingCenter(data) {
    return data.cpamInvoiceGroups.reduce((acc, group) => {
        return (
            acc +
            group.cpamInvoices.reduce((innerAcc, invoice) => {
                return innerAcc + (invoice.paidByPayingCenter ? 1 : 0)
            }, 0)
        )
    }, 0)
}

function calculateTotalAmountRejectedInvoices(noemie) {
    return noemie.cpamInvoiceGroups.reduce((acc, group) => {
        return (
            acc +
            group.cpamInvoices.reduce((innerAcc, invoice) => {
                return (
                    innerAcc +
                    (invoice.status.label === 'Rejetée'
                        ? invoice.totalInvoiceAmount
                        : 0)
                )
            }, 0)
        )
    }, 0)
}

const NoemieStats = ({
    noemie,
    handleReceivedPaymentAMO,
    handleReceivedPaymentAMC,
    handleClosedNoemie,
}) => {
    const totalNbInvoicesPaidByPayingCenter =
        calculateNbInvoicesPaidByPayingCenter(noemie)
    const totalInvoicesPaidByPayingCenter =
        calculateTotalInvoicesPaidByPayingCenter(noemie)
    const totalCPAM =
        calculateTotalCPAM(noemie) + totalInvoicesPaidByPayingCenter
    const totalComplementaire =
        calculateTotalComplementaire(noemie) - totalInvoicesPaidByPayingCenter
    const total100Percent = calculateTotal100Percent(noemie)
    const totalNot100Percent =
        noemie.cpamInvoiceGroups.reduce(
            (acc, group) => acc + group.cpamInvoices.length,
            0
        ) -
        total100Percent -
        totalNbInvoicesPaidByPayingCenter
    const totalGeneral = totalCPAM + totalComplementaire
    const secondSubtitle = totalNbInvoicesPaidByPayingCenter
        ? `${totalNbInvoicesPaidByPayingCenter} facture(s) en tier payant réglé par la caisse = ${formatEuro(
              totalInvoicesPaidByPayingCenter
          )}`
        : null
    // compter le nombre de factures rejetées et additionner les montants
    const totalAmountRejectedInvoices =
        calculateTotalAmountRejectedInvoices(noemie)
    const totalRejectedInvoices =
        totalAmountRejectedInvoices > 0
            ? `-${formatEuro(totalAmountRejectedInvoices)}`
            : null

    return (
        <div className="grid gap-4 sm:grid-cols-3">
            <NoemieStatCard
                title={noemie.cpamInvoiceGroups[0].payingCenter.label}
                value={formatEuro(totalCPAM)}
                subtitle={`${total100Percent} facture(s) en 100%`}
                secondSubtitle={secondSubtitle}
                icon={<Cpam height={36} width={36} />}
                handleAction={handleReceivedPaymentAMO}
                actionLabel={'Virement AMO reçu'}
                date={noemie.payingCenterStatusPaidAt}
                status={noemie.payingCenterStatus}
                actionIcon={
                    <>
                        <SendEuros />
                        <Bank className={'mr-2'} />
                    </>
                }
            />
            {totalNot100Percent > 0 ? (
                <NoemieStatCard
                    title="AMC/Assuré"
                    value={formatEuro(totalComplementaire)}
                    subtitle={`${totalNot100Percent} facture(s) en 55%`}
                    icon={<Healthcare height={36} width={36} />}
                    handleAction={handleReceivedPaymentAMC}
                    status={noemie.healthComplementaryStatus}
                    actionLabel={'Virement AMC reçu'}
                    actionIcon={
                        <>
                            <SendEuros />
                            <Bank className={'mr-2'} />
                        </>
                    }
                />
            ) : null}
            <NoemieStatCard
                title="Montant général"
                value={`${formatEuro(totalGeneral)}`}
                subValue={totalRejectedInvoices}
                subtitle={`Pour un total de ${noemie.cpamInvoiceGroups.reduce(
                    (acc, group) => acc + group.cpamInvoices.length,
                    0
                )} facture(s)`}
                icon={<Bank height={36} width={36} />}
                handleAction={handleClosedNoemie}
                actionLabel={'Clôturer les lots'}
                actionIcon={<Lock className={'mr-2'} />}
                primary={true}
            />
        </div>
    )
}

export default NoemieStats
