import React, { useEffect, useReducer } from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import API from '../../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import ManageTitle from '../../Commons/ManageTitle'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import Loader from '../../Commons/Loaders/Loader'
import UserRoles from '../../../services/UserRoles'
import {
    ERROR_ACTIONS,
    ErrorReducer,
} from '../../../services/Reducers/ErrorReducer'
import {
    getInitialState,
    UserReducer,
    ACTIONS,
} from '../../../services/Reducers/UserReducer'
import Error from '../../Commons/Error'
import { toast } from 'react-toastify'

const UserManage = () => {
    const [errors, dispatchError] = useReducer(ErrorReducer, [])
    const [user, dispatch] = useReducer(UserReducer, [], getInitialState)

    let history = useNavigate()
    let { id } = useParams()
    id = parseInt(id)

    const checkErrors = () => {
        let printedErrors = []
        dispatchError({
            type: ERROR_ACTIONS.CLEAR_ALL_ERRORS,
        })

        if (user.firstname === '') {
            printedErrors.push({
                id: 'emptyFirstname',
                message: 'Veuillez renseigner un prénom.',
            })
        }
        if (user.lastname === '') {
            printedErrors.push({
                id: 'emptyLastname',
                message: 'Veuillez renseigner un nom.',
            })
        }

        let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}')
        if (user.email && !regex.test(user.email)) {
            printedErrors.push({
                id: 'emptyEmail',
                message: 'Veuillez renseigner un email valide.',
            })
        }
        if (!user.phone || user.phone.length < 10) {
            printedErrors.push({
                id: 'emptyPhone',
                message: 'Veuillez renseigner un numéro de téléphone valide.',
            })
        }
        if (!user.selectedRoles || user.selectedRoles.length === 0) {
            printedErrors.push({
                id: 'emptyRoles',
                message: 'Veuillez ajouter un rôle à votre utilisateur.',
            })
        }

        if (printedErrors.length === 0) {
            return false
        } else {
            printedErrors.map((error) => {
                dispatchError({
                    type: ERROR_ACTIONS.SET_ERROR,
                    payload: error,
                })
            })
            return true
        }
    }

    const handleForm = () => {
        if (!checkErrors()) {
            handleLoading(true)
            const data = {
                firstname: user.firstname,
                lastname: user.lastname,
                phoneNumber: user.phone,
                active: user.active,
                hasScheduleConfiguration: user.hasScheduleConfiguration,
                canViewAllRuns: user.canViewAllRuns,
                showRunsStatusUpdates: user.showRunsStatusUpdates,
                canViewUnassignedRuns: user.canViewUnassignedRuns,
                hideFuturPlanningDaysForUser: user.hideFuturPlanningDaysForUser,
                roles: user.selectedRoles.map((role) => {
                    return role.value
                }),
            }
            if (user.email?.trim().length) {
                data['email'] = user.email.trim().length
                    ? user.email.trim()
                    : null
            }
            if (id === 0) {
                API.Users.addUser(data)
                    .then((response) => {
                        toast.success('Utilisateur ajouté.')
                        historyPush()
                    })
                    .catch((error) => {
                        //errors are handle in api.js
                        handleLoading(false)
                    })
            } else {
                API.Users.put(id, data)
                    .then((response) => {
                        toast.success('Utilisateur modifié.')
                        historyPush()
                    })
                    .catch((error) => {
                        handleLoading(false)
                        toast.success("Une erreur s'est produite.")
                    })
            }
        }
    }

    const historyPush = () => {
        history('/ressources/utilisateurs')
    }

    useEffect(() => {
        if (id !== 0) {
            getUser()
        } else {
            handleLoading(false)
        }
    }, [])

    const getUser = () => {
        API.Users.get(id).then((response) => {
            response.json().then((data) => {
                let dataRoles = []

                data['roles'].forEach((r) => {
                    switch (r) {
                        case 'ROLE_ADMIN':
                            dataRoles.push({
                                value: 'ROLE_ADMIN',
                                label: 'Administrateur',
                            })
                            break
                        case 'ROLE_USER':
                            dataRoles.push({
                                value: 'ROLE_USER',
                                label: 'Chauffeur',
                            })
                            break
                    }
                })
                data.roles = dataRoles
                handleUser(data)
            })
        })
    }

    const handleUser = (user) => {
        dispatch({ type: ACTIONS.SET_USER, payload: user })
    }

    const handleFirstname = (value) => {
        dispatch({ type: ACTIONS.SET_FIRSTNAME, payload: value })
    }

    const handleLastname = (value) => {
        dispatch({ type: ACTIONS.SET_LASTNAME, payload: value })
    }

    const handlePhone = (value) => {
        dispatch({ type: ACTIONS.SET_PHONE, payload: value })
    }

    const handleEmail = (value) => {
        dispatch({ type: ACTIONS.SET_EMAIL, payload: value })
    }

    const handleSelectedRoles = (value) => {
        dispatch({ type: ACTIONS.SET_USER_SELECTED_ROLES, payload: value })
    }

    const handleIsActive = () => {
        dispatch({ type: ACTIONS.SET_ACTIVE })
    }

    const handleLoading = (event) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: event })
    }

    const handleCanViewAllRuns = (event) => {
        dispatch({ type: ACTIONS.SET_CAN_VIEW_ALL_RUNS, payload: event })
    }

    const handleCanViewUnassignedRuns = (event) => {
        dispatch({ type: ACTIONS.SET_CAN_VIEW_UNASSIGNED_RUNS, payload: event })
    }

    const handleHasScheduleConfiguration = (event) => {
        dispatch({ type: ACTIONS.HAS_SCHEDULE_CONFIGURATION, payload: event })
    }

    const handleHideFuturPlanningDaysForUser = () => {
        dispatch({ type: ACTIONS.SET_HIDE_FUTUR_PLANNING_DAYS_FOR_USER })
    }

    const showRunsStatusUpdates = () => {
        dispatch({ type: ACTIONS.SET_SHOW_RUNS_STATUS_UPDATES })
    }

    return (
        <div
            className={
                'min-height-90-x container mx-auto flex flex-col pb-20 pt-5 text-lg lg:pb-0 lg:text-sm '
            }
        >
            {user.loading ? (
                <Loader />
            ) : (
                <div className="flex w-full flex-col rounded-xl bg-white px-5 py-10 shadow-md">
                    <ManageTitle label={user.manageTitle} />
                    <div className="px-5 lg:px-20">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4 ">
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Prénom *"
                                    value={user.firstname}
                                    setValue={(e) =>
                                        handleFirstname(e.target.value)
                                    }
                                    id="firstname"
                                    placeholder="Jane"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Nom de famille *"
                                    value={user.lastname}
                                    setValue={(e) =>
                                        handleLastname(e.target.value)
                                    }
                                    id="lastname"
                                    placeholder="Dupont"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Téléphone *"
                                    value={user.phone}
                                    setValue={(e) =>
                                        handlePhone(e.target.value)
                                    }
                                    id="phone"
                                    placeholder="0699876709"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="addresse email"
                                    value={user.email}
                                    setValue={(e) =>
                                        handleEmail(e.target.value)
                                    }
                                    id="email"
                                    placeholder="jean@dupont"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <SelectDropDownList
                                    icon={''}
                                    label="Roles"
                                    options={UserRoles}
                                    value={user.selectedRoles}
                                    id="role"
                                    handleOptionChange={handleSelectedRoles}
                                    isClearable={false}
                                    isDisabled={false}
                                    isMulti={true}
                                    showLabel={true}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="col-span-1  col-start-1 py-4">
                                <ClassicSwitch
                                    labelUppercase={false}
                                    label="Actif"
                                    id="special"
                                    value={user.active}
                                    setValue={handleIsActive}
                                />
                            </div>
                            <div className="col-span-1 py-4 text-xl font-semibold">
                                <ClassicSwitch
                                    labelUppercase={false}
                                    id="configuration"
                                    info={true}
                                    infoLabel="L'affichage spécifique prend le dessus sur les paramètres globaux"
                                    label="Gérer l'affichage personnalisé du planning"
                                    setValue={handleHasScheduleConfiguration}
                                    value={user.hasScheduleConfiguration}
                                />
                            </div>
                            <div className="col-span-1 py-4 text-xl font-semibold">
                                <ClassicSwitch
                                    labelUppercase={false}
                                    id="planning"
                                    info={true}
                                    infoLabel="Le chauffeur peut visualiser le prochain jour du planning à partir de 18h"
                                    label="Empêcher le chauffeurs de visualiser les prochains jours du planning"
                                    setValue={
                                        handleHideFuturPlanningDaysForUser
                                    }
                                    value={user.hideFuturPlanningDaysForUser}
                                />
                            </div>
                            {user.selectedRoles?.some((item) => {
                                return item.value === 'ROLE_ADMIN'
                            }) && (
                                <div className="col-span-1 py-4 text-xl font-semibold">
                                    <ClassicSwitch
                                        labelUppercase={false}
                                        id="planning"
                                        info={true}
                                        infoLabel="Recevoir une notification push lors de la prise en charge et finalisation d'une course"
                                        label="Notifiation push de prise en charge et finalisation d'une course"
                                        setValue={showRunsStatusUpdates}
                                        value={user.showRunsStatusUpdates}
                                    />
                                </div>
                            )}
                            {user.hasScheduleConfiguration && (
                                <div className="col-span-2 grid sm:gap-4">
                                    <div className="col-span-2 py-4 text-xl font-semibold">
                                        Affichage personnalisé du planning
                                    </div>
                                    <div className="col-span-1 pt-2">
                                        <ClassicSwitch
                                            labelUppercase={false}
                                            id="viewAllRuns"
                                            label="Voir les transports des autres chauffeurs"
                                            setValue={handleCanViewAllRuns}
                                            value={user.canViewAllRuns}
                                        />
                                    </div>
                                    <div className="col-span-1 pt-2">
                                        <ClassicSwitch
                                            labelUppercase={false}
                                            id="viewUnassignedRuns"
                                            label="Voir les transports non-assignées"
                                            setValue={
                                                handleCanViewUnassignedRuns
                                            }
                                            value={user.canViewUnassignedRuns}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <SendFormButtons
                historyPush={historyPush}
                handleForm={handleForm}
            />
            <div className="fixed right-0 top-0 z-50 mr-5 flex flex flex-col space-y-2 text-gray-700">
                {errors.map((error) => {
                    return (
                        <Error
                            key={error.id}
                            error={error}
                            dispatch={dispatchError}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default UserManage
