import LoaderModal from '../../../../Commons/Loaders/LoaderModal'
import InvoiceCpamModalForm from '../../../Regulation/Forms/InvoiceCpamModalForm'
import React from 'react'
import { SERIES_CPAM_INVOICE_FORM_ACTION } from '../SeriesCpamInvoiceFormReducer'

const SeriesCpamInvoiceFormModal = ({
    priceBases,
    allowedToChangePriceBasis,
    invoiceForm,
    dispatch,
}) => {
    return (
        <>
            {invoiceForm.isFetchingRuns ? (
                <div className={'flex flex-col'}>
                    <p>Chargement des courses</p>
                    <LoaderModal customHeight={'w-10'} customWidth={'h-10'} />
                </div>
            ) : (
                <InvoiceCpamModalForm
                    priceBases={priceBases}
                    allowedToChangePriceBasis={allowedToChangePriceBasis}
                    dispatch={dispatch}
                    invoiceCpamForm={invoiceForm}
                    runs={invoiceForm.runs}
                    ACTION={SERIES_CPAM_INVOICE_FORM_ACTION}
                />
            )}
        </>
    )
}

export default SeriesCpamInvoiceFormModal
