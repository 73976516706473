import { ReactComponent as Desassigner } from '../../../../files/desassigner.svg'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import UseLongPress from '../../../../hooks/UseLongPress'
import { Minus, Plus, User } from 'iconoir-react'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'

const PlanningDashboardTeamInfo = ({
    team,
    atLeastTwoRunsAreSelected,
    totalRunsTeam,
    handleGroupIconClicked,
    dashboard,
    handleTeamClicked,
    otherRunsThanTeamRunsSelected,
    unassignSelectedTeamsRuns,
    handleHideTeam,
    disabledButtons,
}) => {
    const titleRef = useRef(null)

    useEffect(() => {
        const handleResize = () => {
            const sidenav = document.getElementsByClassName('sidenav')[0]

            let screenWidth = window.innerWidth
            if (screenWidth >= 1250 && !dashboard.focusMode) {
                screenWidth = screenWidth - sidenav.offsetWidth
            }

            titleRef.current.style.width = `${screenWidth}px`
        }

        handleResize() // Appel initial pour définir la largeur de la div de titre
        window.addEventListener('resize', handleResize) // Écouteur d'événement de redimensionnement de fenêtre

        return () => {
            window.removeEventListener('resize', handleResize) // Nettoyage de l'écouteur d'événement lors du démontage du composant
        }
    }, [])

    return (
        <div
            className="sticky left-0 mx-4 mb-2 flex flex-row items-center border-b pr-2 pl-4 pb-2 lg:pl-2"
            ref={titleRef}
        >
            <div>
                <i
                    className={`${
                        team.hide
                            ? 'fas fa-plus fa-lg'
                            : 'fas fa-caret-down fa-2x'
                    } cursor-pointer`}
                    onClick={() => handleHideTeam(team)}
                />
            </div>
            {team.isPartner ? (
                <div className="flex w-1/2 items-center lg:w-1/3">
                    <i className="fas fa-hands-helping pr-2" />
                    <span className="pr-1">Partenaire</span>
                    <span className="px-1 font-semibold">{team.names}</span>
                </div>
            ) : (
                <div className="flex w-1/2 items-center lg:w-1/3">
                    <User />
                    <span className="px-1 font-semibold">{team.names}</span>
                    <span>({team.vehicle})</span>
                </div>
            )}
            {!team.isPartner ? (
                <div className="flex h-10 w-1/4 cursor-pointer flex-row items-center space-x-4 pl-2">
                    <SecondaryButton
                        icon={<Plus />}
                        action={() => handleTeamClicked(team)}
                        title="Ajouter"
                        disabled={
                            !otherRunsThanTeamRunsSelected(team) ||
                            disabledButtons
                        }
                    />
                    <SecondaryButton
                        icon={<i className="fas fa-layer-group" />}
                        action={() => handleGroupIconClicked(team)}
                        title="Grouper"
                        disabled={
                            !atLeastTwoRunsAreSelected() || disabledButtons
                        }
                    />
                    <SecondaryButton
                        icon={<Minus />}
                        action={() => unassignSelectedTeamsRuns(team)}
                        title="Désassigner"
                        disabled={
                            !team.runs.some((r) => r.selected) ||
                            disabledButtons
                        }
                    />
                </div>
            ) : (
                <div className="w-1/4"></div>
            )}
            <div className="hidden w-1/3 items-center justify-end space-x-4 text-end text-sm lg:flex">
                {totalRunsTeam(team) >= 1 && (
                    <div className="flex space-x-4">
                        <span>
                            Premier transport :{' '}
                            <span className="font-semibold">
                                {team.firstRunHour}
                            </span>
                        </span>
                        <span>
                            Dernier transport :{' '}
                            <span className="font-semibold">
                                {team.lastRunHour}
                            </span>
                        </span>
                    </div>
                )}
                <span className="font-semibold">({totalRunsTeam(team)})</span>
            </div>
        </div>
    )
}

export default PlanningDashboardTeamInfo
