import { useQuery } from '@tanstack/react-query'
import { PayingCenters } from '../../../services/API/Entities/payingCenters'
import { SkeletonLoaderCards } from '../../Commons/Loaders/SkeletonLoaderCards'
import NoData from '../NoData'
import React from 'react'
import PayingCenterListComponent from './PayingCenterListComponent'

const PayingCenter = ({ search, setSearch }) => {
    const {
        data: payingCenters = [],
        isLoading: isLoading,
        isError,
    } = useQuery({
        queryKey: ['payingCenters', search],
        queryFn: () => PayingCenters.fetchAll(search),
        keepPreviousData: true,
    })

    if (isLoading) {
        return <SkeletonLoaderCards />
    }

    if (isError) {
        return (
            <NoData message="Une erreur s'est produite lors du chargement des caisses." />
        )
    }

    if (payingCenters.length === 0)
        return <NoData message="Aucune caisse disponible" />

    const totalItems = payingCenters?.length || 0

    return (
        <PayingCenterListComponent
            payingCenters={payingCenters}
            totalItems={totalItems}
        />
    )
}

export default PayingCenter
