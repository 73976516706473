import { apiClient } from '../apiClient'
import { formatHydraData } from '../../api'

const PATH_KEY = '/health_complementaries'

export const HealthComplementaries = {
    fetchAll: async (search = '') => {
        let path = PATH_KEY + '?pagination=false'
        return formatHydraData(await apiClient.get(path))
    },
    fetchOne: async (id) => {
        let path = PATH_KEY + '/' + id
        return formatHydraData(await apiClient.get(path))
    },
    post: async (healthComplementary) => {
        return await apiClient.post(PATH_KEY, healthComplementary)
    },
    put: async (id, healthComplementary) => {
        return await apiClient.put(PATH_KEY + '/' + id, healthComplementary)
    },
}

export const handleHealthComplementaryForm = async (
    healthComplementaryForm
) => {
    try {
        if (healthComplementaryForm.id) {
            return await HealthComplementaries.put(
                healthComplementaryForm.id,
                healthComplementaryForm
            )
        } else {
            return await HealthComplementaries.post(healthComplementaryForm)
        }
    } catch (error) {
        // L'erreur est déjà gérée dans les fonctions post et put
        throw error
    }
}
