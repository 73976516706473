import React, { useEffect, useReducer, useState } from 'react'
import ParametersConfig from './ParameterConfig'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import SubscriptionContainer from './Subscription/SubscriptionContainer'
import ConfiguratorContainer from './Configurator/ConfiguratorContainer'
import API from '../../../services/api'
import Loader from '../../Commons/Loaders/Loader'
import { SocietyContainer } from './Society/SocietyContainer'
import {
    getInitialState,
    SOCIETY_ACTIONS,
    SocietyReducer,
} from '../../../services/Reducers/SocietyReducer'
import {
    ADDRESS_ACTIONS,
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../services/Reducers/AddressAutoCompleteReducer'
import TabButton from '../../Commons/Buttons/TabButton'
import { Building, CreditCard, EditPencil, Settings } from 'iconoir-react'

const ParameterContainer = () => {
    let history = useNavigate()
    let location = useLocation()
    const [society, dispatch] = useReducer(
        SocietyReducer,
        undefined,
        getInitialState
    )
    const [address, addressDispatch] = useReducer(
        AddressAutoCompleteReducer,
        { undefined },
        getAddressInitialState
    )
    const [loading, setLoading] = useState(true)
    const [parameters, setParameters] = useState(null)
    const [computerConfigurator, setComputerConfigurator] = useState(null)
    const [mobileConfigurator, setMobileConfigurator] = useState(null)

    const isUserMaintainer = JSON.parse(localStorage.getItem('isMaintainer'))

    const getParameters = () => {
        API.Parameter.get(localStorage.getItem('society')).then((response) => {
            response.json().then((data) => {
                setParameters(data)
                setComputerConfigurator(data.computerConfigurator)
                setMobileConfigurator(data.mobileConfigurator)
                getSociety()
            })
        })
    }

    const getSociety = () => {
        API.Society.get(
            localStorage.getItem('society').split('/').reverse()[0]
        ).then((response) => {
            response.json().then((data) => {
                dispatch({
                    type: SOCIETY_ACTIONS.GET_SOCIETY,
                    payload: data,
                })
                addressDispatch({
                    type: ADDRESS_ACTIONS.CLICK_ON_PREDICTION,
                    payload: data.address,
                })
                setLoading(false)
            })
        })
    }

    useEffect(() => {
        getParameters()
        if (!isUserMaintainer) {
            history('/parametres/configurateur')
        }
    }, [])

    const selected =
        'border-b-2 border-light-dark-green text-light-dark-green pb-1 transition duration-500 ease-in-out'
    const hover =
        'hover:border-light-dark-green border-transparent transition duration-500 ease-in-out transform hover:-translate-y-1'

    return (
        <div className="flex flex-col">
            <div className="flex items-center font-semibold text-gray-700 sm:text-base lg:col-span-2  ">
                <div className="flex grid h-10 w-full cursor-pointer grid-cols-4 justify-between gap-4 rounded-xl border-dark-green lg:w-auto ">
                    {isUserMaintainer ? (
                        <>
                            <TabButton
                                value={location.pathname === '/parametres'}
                                action={() => history('/parametres')}
                                label="General"
                                icon={<Settings />}
                            />

                            <TabButton
                                value={location.pathname.startsWith(
                                    '/parametres/societe'
                                )}
                                action={() => history('/parametres/societe')}
                                label="Société"
                                icon={<Building />}
                            />
                            {/*<TabButton*/}
                            {/*    value={location.pathname.startsWith(*/}
                            {/*        '/parametres/abonnement'*/}
                            {/*    )}*/}
                            {/*    action={() => history('/parametres/abonnement')}*/}
                            {/*    label="Abonnement"*/}
                            {/*    icon={<CreditCard />}*/}
                            {/*/>*/}
                        </>
                    ) : null}
                    <TabButton
                        value={location.pathname.startsWith(
                            '/parametres/configurateur'
                        )}
                        action={() => history('/parametres/configurateur')}
                        label="Configurateur"
                        icon={<EditPencil />}
                    />
                </div>
            </div>
            {loading ? (
                <Loader />
            ) : (
                <Routes>
                    <Route
                        path="/"
                        element={<ParametersConfig parameters={parameters} />}
                    />
                    <Route
                        path="/societe"
                        element={
                            <SocietyContainer
                                society={society}
                                action={SOCIETY_ACTIONS}
                                dispatch={dispatch}
                                address={address}
                                addressDispatch={addressDispatch}
                            />
                        }
                    />
                    <Route
                        path="/abonnement"
                        element={<SubscriptionContainer />}
                    />
                    <Route
                        path="/configurateur/*"
                        element={
                            <ConfiguratorContainer
                                computerConfigurator={computerConfigurator}
                                mobileConfigurator={mobileConfigurator}
                                setComputerConfigurator={
                                    setComputerConfigurator
                                }
                                setMobileConfigurator={setMobileConfigurator}
                                selected={selected}
                                hover={hover}
                                setLoading={setLoading}
                            />
                        }
                    />
                </Routes>
            )}
        </div>
    )
}

export default ParameterContainer
