import dayjs from 'dayjs'
import { MailIn } from 'iconoir-react'

export const NoemieNotification = ({ noemieNotification }) => {
    return (
        <div className="flex w-full items-center">
            <MailIn className={'min-w-2-5 h-6 w-6 text-purple-500'} />
            <div className="flex flex-col pl-2 pr-1">
                <div className="text-notif wrapper flex text-left text-gray-700">
                    <a className={'font-semibold'}>Retour NOEMIE </a>
                    {/*ajouter logo accepté refusée*/}
                    <a>{noemieNotification.text}</a>
                </div>
                <p className="text-left text-xs text-gray-500">
                    {dayjs(noemieNotification.createdAt).fromNow()}
                </p>
            </div>
            {!noemieNotification.seen && (
                <div className="ml-auto">
                    <div className={`h-2 w-2 rounded-full bg-blue-500`} />
                </div>
            )}
        </div>
    )
}
