import { ReactComponent as Desassigner } from '../../../../files/desassigner.svg'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import UseLongPress from '../../../../hooks/UseLongPress'
import { Minus, Plus, User } from 'iconoir-react'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'

const UnassignedRunsTitle = ({ dashboard }) => {
    const titleRef = useRef(null)

    useEffect(() => {
        const handleResize = () => {
            const sidenav = document.getElementsByClassName('sidenav')[0]

            let screenWidth = window.innerWidth
            if (screenWidth >= 1250 && !dashboard.focusMode) {
                screenWidth = screenWidth - sidenav.offsetWidth
            }

            titleRef.current.style.width = `${screenWidth}px`
        }

        handleResize() // Appel initial pour définir la largeur de la div de titre
        window.addEventListener('resize', handleResize) // Écouteur d'événement de redimensionnement de fenêtre

        return () => {
            window.removeEventListener('resize', handleResize) // Nettoyage de l'écouteur d'événement lors du démontage du composant
        }
    }, [])

    return (
        <div
            className="sticky left-0 mx-4 mb-2 flex flex-row items-center border-b px-2 pb-2"
            ref={titleRef}
        >
            <div className="mr-5 flex w-1/3 items-center font-semibold">
                Transports non assignés
            </div>
            <div className="w-1/4"></div>

            <div className="hidden w-1/3 items-center justify-end space-x-4 text-end text-sm lg:flex">
                {dashboard.unassignedRuns.length > 0 &&
                    dashboard.unassignedRuns[0].length > 0 && (
                        <div className="flex space-x-4">
                            <span>
                                Premier transport :{' '}
                                <span className="font-semibold">
                                    {
                                        dashboard.unassignedRuns[0][0]
                                            .departureTimeHour
                                    }
                                </span>
                            </span>
                            <span>
                                Dernier transport :{' '}
                                <span className="font-semibold">
                                    {
                                        dashboard.unassignedRuns[
                                            dashboard.unassignedRuns.length - 1
                                        ][
                                            dashboard.unassignedRuns[
                                                dashboard.unassignedRuns
                                                    .length - 1
                                            ].length - 1
                                        ].departureTimeHour
                                    }
                                </span>
                            </span>
                        </div>
                    )}
                <span className="font-bold">
                    ({dashboard.nbUnassignedRuns})
                </span>
            </div>
        </div>
    )
}

export default UnassignedRunsTitle
