import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import RegulationFilter from './RegulationFilter'
import Regulation from './Regulation'
import RegulationSeriesFilter from './RegulationSeriesFilter'
import RunTabs from './RunTabs'
import {
    useStickyDateState,
    useStickyEndDateState,
} from '../../../hooks/LocalStorageDatesHooks'
import { useRegulationStore } from '../../../hooks/useRegulationStore'
import { useParameters } from '../../../context/ParametersContext'

const RegulationContainer = ({ isSocietyTaxi }) => {
    const [dateFrom, setDateFrom] = useStickyDateState(new Date(), 'storedDate')
    const [dateTo, setDateTo] = useStickyEndDateState(
        new Date(),
        'storedDate',
        'storedEndDate'
    )

    // Global state from store
    const {
        search,
        setSearch,
        refreshing,
        setRefreshing,
        page,
        setPage,
        patient,
        setPatient,
        partner,
        setPartner,
        type,
        setType,
        object,
        setObject,
        status,
        setStatus,
        user,
        setUser,
        shareFilter,
        setShareFilter,
        excludeStatus,
        setExcludeStatus,
        transferLabel,
        setTransferLabel,
        transferGroup,
        updateTransferGroup,
        selectAll,
        setSelectAll,
        handleErase,
        handleTransferSelect,
        handleSelectAll,
    } = useRegulationStore()

    // Get series ID from URL if present
    const seriesId = new URLSearchParams(useLocation().search).get('series')

    // Fetch society configuration
    const societyConfig = useParameters()
    const parameters = societyConfig.parameters

    const sharedProps = {
        search,
        setSearch,
        dateFrom,
        dateTo,
        setDateFrom,
        setDateTo,
        refreshing,
        setRefreshing,
        page,
        setPage,
        patient,
        setPatient,
        partner,
        setPartner,
        type,
        setType,
        object,
        setObject,
        status,
        setStatus,
        user,
        setUser,
        shareFilter,
        setShareFilter,
        excludeStatus,
        setExcludeStatus,
        transferLabel,
        setTransferLabel,
        transferGroup,
        updateTransferGroup,
        selectAll,
        setSelectAll,
        handleErase,
        parameters,
    }

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <div className="flex flex-col pb-10">
                        {seriesId ? (
                            <RegulationSeriesFilter
                                search={search}
                                setSearch={setSearch}
                                seriesId={seriesId}
                            />
                        ) : (
                            <RegulationFilter
                                {...sharedProps}
                                handleTransferSelect={handleTransferSelect}
                                handleSelectAll={handleSelectAll}
                            />
                        )}
                        <Regulation
                            {...sharedProps}
                            seriesId={seriesId}
                            isSocietyTaxi={isSocietyTaxi}
                        />
                    </div>
                }
            />
            <Route
                path=":id"
                element={
                    <RunTabs
                        date={dateFrom}
                        setDate={setDateFrom}
                        setDateTo={setDateTo}
                    />
                }
            />
        </Routes>
    )
}

export default RegulationContainer
