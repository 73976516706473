import React from 'react'
import SendFormButtons from '../Commons/Buttons/SendFormButtons'

const ModalSkeleton = ({
    label,
    handleCloseModal,
    Component,
    componentProps,
    handleForm,
    disabledSubmit = false,
    buttonLabel,
    hideSubmitButton = false,
    stickyHeader = true,
    stickyFooter = true,
    loader = false,
}) => {
    return (
        <div className="modal absolute bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-500 bg-opacity-50 text-gray-700 lg:py-20">
            <div className="shadow-modal invisible-scrollbar lg:min-h-modal mx-auto flex h-screen w-full max-w-4xl flex-col overflow-y-auto rounded-xl bg-white lg:h-full">
                <div className="flex flex-col items-start">
                    <div
                        className={`w-full ${
                            stickyHeader ? 'sticky top-0 z-10 bg-white' : ''
                        }`}
                    >
                        <div className="flex items-center border-b p-4 text-3xl">
                            <div className="flex space-x-4 text-center font-semibold">
                                {label}
                            </div>
                            <svg
                                className="ml-auto h-10 w-10 cursor-pointer fill-current text-gray-700"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 18 18"
                                onClick={handleCloseModal}
                            >
                                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                            </svg>
                        </div>
                    </div>
                    <div className="min-height-60 w-full overflow-y-auto">
                        <Component {...componentProps} />
                    </div>
                    <div
                        className={`w-full ${
                            stickyFooter ? 'sticky bottom-0 bg-white' : ''
                        }`}
                    >
                        <div className="p-4">
                            <SendFormButtons
                                hideSubmitButton={hideSubmitButton}
                                handleForm={handleForm}
                                cancelAction={handleCloseModal}
                                disabledSubmit={disabledSubmit}
                                buttonLabel={buttonLabel}
                                loader={loader}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalSkeleton
