import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { ACTIONS } from '../../../services/Reducers/PlanningReducer'
import PlanningDashboard from './Dashboard/PlanningDashboard'
import {
    useStickyDateState,
    useStickyEndDateState,
} from '../../../hooks/LocalStorageDatesHooks'
import dayjs from 'dayjs'
import {
    Enlarge,
    Group,
    Plus,
    Settings,
    StatsUpSquare,
    ZoomIn,
} from 'iconoir-react'
import API from '../../../services/api'
import usePlanningStore from '../../../stores/Planning'
import PlanningSorted from './Sorted/PlanningSorted'
import { toast } from 'react-toastify'
import ManageRunModal from '../../Modal/ManageRunModal'
import ManageSeriesModal from '../../Modal/ManageSeriesModal'
import CalendarDatePickerV2 from '../../Commons/CalendarDatePickers/CalendarDatePickerV2'
import PlanningShuffled from './Shuffled/PlanningShuffled'

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import DoughnutChart from '../../Commons/Charts/DoughnutChart'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import SelectDropDownListForNumber from '../../Commons/DropDownLists/SelectDropDownListForNumber'
import SelectDropDownListAddButtons from '../../Commons/DropDownLists/SelectDropDownListAddButtons'
import ConfigPlanningModal from '../../Modal/PlanningModal/ConfigPlanningModal'
import TeamsPlanningModal from '../../Modal/PlanningModal/TeamsPlanningModal'
import classNames from 'classnames'
import PlanningStats from '../../Commons/PlanningStats'
import CompetitionModal from '../../Modal/CompetitionModal'
import { RunStatus } from '../../../services/RunStatus'
import { Plannings } from '../../../services/API/Entities/plannings'
import ActionModalWithLoader from '../../Modal/ActionModalWithLoader'
import { Regulation } from '../../../services/API/Entities/regulation'
import { usePlanningContext } from './PlanningContext'
import { formatDateToApi } from '../../../services/dateUtils'

ChartJS.register(ArcElement, Tooltip, Legend)

const useOnClickAddMenuOutside = (ref, outsideHandler, clickHandler) => {
    useEffect(() => {
        const listener = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                // clickHandler(event)
            } else {
                outsideHandler(event)
            }
        }

        document.addEventListener('mousedown', listener)
        document.addEventListener('touchstart', listener)

        return () => {
            document.removeEventListener('mousedown', listener)
            document.removeEventListener('touchstart', listener)
        }
    }, [ref, outsideHandler, clickHandler])
}

const PlanningContainer = ({ showRunObjectColors }) => {
    const {
        planning,
        dispatch,
        planningDate,
        setPlanningDate,
        reRender,
        handleOpenRunManageModal,
    } = usePlanningContext()

    const location = useLocation()
    let history = useNavigate()

    const isDashboardView = location.pathname === '/regulation/dashboard'

    const addMenuRef = useRef()
    useOnClickAddMenuOutside(
        addMenuRef,
        () => handleOpenAddMenu(false),
        () => handleOpenAddMenu(true)
    )
    const [dateFrom, setDateFrom] = useStickyDateState(
        new Date(dayjs().add(1, 'day')),
        'storedDate'
    )
    const [dateTo, setDateTo] = useStickyEndDateState(
        new Date(),
        'storedDate',
        'storedEndDate'
    )

    const isLockedLabel = planning?.isLocked ? 'Invalider' : 'Valider'

    const dashboardCompletelyHidedTeams = usePlanningStore(
        (state) => state.dashboardCompletelyHidedTeams
    )
    const setDashboardCompletelyHidedTeams = usePlanningStore(
        (state) => state.setDashboardCompletelyHidedTeams
    )

    const setUp = async () => {
        //check if teams are already created
        const masterRunExists = await Regulation.checkIfMasterRunsAreCreated(
            planningDate
        )
        if (!masterRunExists['exists']) {
            //generate teams
            const data = {
                society: localStorage.getItem('society'),
                date: formatDateToApi(planningDate),
            }
            API.Planning.setUp(data)
                .then((response) => {
                    dispatch({
                        type: ACTIONS.SET_TEAMS_CREATED,
                        payload: true,
                    })
                    start()
                })
                .catch((error) => {
                    if (error.json) {
                        error.json().then((data) => {
                            // setLoading(false)
                            // setErrorMsg(data['error'])
                            start()
                        })
                    }
                })
        } else {
            dispatch({
                type: ACTIONS.SET_TEAMS_CREATED,
                payload: true,
            })
            start()
        }
    }
    //TODO to change
    const [informationRunModalOpen, setInformationRunModalOpen] =
        useState(false)

    //TODO CHANGE
    useEffect(() => {
        const { unassignedRunsLength, canceledUnassignedRunsLength } =
            planning.unassignedRuns.reduce(
                (acc, groupRuns) => {
                    const nonCanceledRuns = groupRuns.filter(
                        (run) => run.status.id !== 'canceled'
                    ).length
                    const canceledRuns = groupRuns.filter(
                        (run) => run.status.id === 'canceled'
                    ).length

                    return {
                        unassignedRunsLength:
                            acc.unassignedRunsLength + nonCanceledRuns,
                        canceledUnassignedRunsLength:
                            acc.canceledUnassignedRunsLength + canceledRuns,
                    }
                },
                { unassignedRunsLength: 0, canceledUnassignedRunsLength: 0 }
            )

        dispatch({
            type: ACTIONS.SET_NB_UNASSIGNED_RUNS,
            payload: {
                nbUnassignedRuns: unassignedRunsLength,
                nbCanceledUnassignedRuns: canceledUnassignedRunsLength,
            },
        })
    }, [planning.unassignedRuns])

    useEffect(() => {
        dispatch({
            type: ACTIONS.SET_NB_ASSIGNED_RUNS,
            payload: planning.nbTeamsRuns,
        })
    }, [planning.nbTeamsRuns])

    useEffect(() => {
        dispatch({
            type: ACTIONS.SET_NB_SHARED_RUNS,
            payload: planning.nbPartnersRuns,
        })
    }, [planning.nbPartnersRuns])

    useEffect(() => {
        dispatch({
            type: ACTIONS.SET_NB_TOTAL_CANCELED_RUNS,
            payload:
                planning.nbCanceledUnassignedRuns +
                planning.nbCanceledTeamsRuns,
        })
    }, [planning.nbCanceledUnassignedRuns, planning.nbCanceledTeamsRuns])

    useEffect(() => {
        let unassignedRunsLength = 0
        planning.unassignedRuns.forEach((groupRuns) => {
            unassignedRunsLength = unassignedRunsLength + groupRuns.length
        })

        dispatch({
            type: ACTIONS.SET_NB_TOTAL_RUNS,
            payload: {
                total:
                    planning.nbPartnersRuns +
                    planning.nbTeamsRuns +
                    unassignedRunsLength +
                    planning.nbTotalCanceledRuns,
                shared: planning.nbPartnersRuns,
            },
        })
    }, [
        planning.unassignedRuns,
        planning.nbTeamsRuns,
        planning.nbPartnersRuns,
        planning.nbTotalCanceledRuns,
    ])
    //TODO CHANGE

    useEffect(() => {
        setUp()
    }, [planningDate])

    const start = () => {
        // const planningView = localStorage.getItem('planningView')
        // if (planningView) {
        //     setView(planningView)
        // }
        dispatch({
            type: ACTIONS.SET_STATUS_LIST,
            payload: RunStatus,
        })
        reRender()
    }
    useEffect(() => {
        if (!planning.firstLoading) {
            reRender()
        }
    }, [
        planning.intervalSize.range,
        planning.isPatientFilter,
        planning.showPartners,
        planning.showEffectiveHours,
    ])

    useEffect(() => {}, [planning.teams])

    const handleDate = (date) => {
        date.setHours(0, 0, 0, 0)
        setPlanningDate(date)
        setDateTo(date)
        setDateFrom(date)
        dispatch({
            type: ACTIONS.SET_LOADING,
            payload: true,
        })
    }

    const handleSelectOpenDatePicker = (value) => {
        dispatch({ type: ACTIONS.SET_OPEN_SELECT_DATE_PICKER, payload: value })
    }

    const handleOpenAddMenu = (value) => {
        dispatch({ type: ACTIONS.SET_OPEN_ADD_MENU, payload: value })
    }

    const refreshMasterRun = async (teamId) => {
        const response = await API.MasterRuns.get(teamId)
    }

    const handleOpenNewRunManageModal = (value) => {
        dispatch({
            type: ACTIONS.SET_OPEN_NEW_RUN_MANAGE_MODAL,
            payload: value,
        })
    }
    const handleOpenSeriesManageModal = (value) => {
        dispatch({ type: ACTIONS.SET_OPEN_SERIES_MANAGE_MODAL, payload: value })
    }
    const handleOpenNewSeriesManageModal = (value) => {
        dispatch({
            type: ACTIONS.SET_OPEN_NEW_SERIES_MANAGE_MODAL,
            payload: value,
        })
    }

    const handleSeriesId = (value) => {
        dispatch({
            type: ACTIONS.SET_SERIES_ID,
            payload: value,
        })
    }

    const labels = ['Assignés', 'Non-assignés', 'Partagés', 'Annulés']
    const data = [
        planning.nbAssignedRuns,
        planning.nbUnassignedRuns,
        planning.nbSharedRuns,
        planning.nbTotalCanceledRuns,
    ]
    const backgroundColor = ['#3B82F6', '#000000', '#9CA3AF', '#FF7316']
    const hoverBackgroundColor = ['#3B82F6', '#000000', '#9CA3AF', '#FF7316']
    const cutout = '88%'

    const handleFocusMode = () => {
        dispatch({
            type: ACTIONS.SET_FOCUS_MODE,
            payload: !planning.focusMode,
        })
    }

    const handleRangeChange = (e) => {
        localStorage.setItem('dashboardRange', e.value)
        dispatch({
            type: ACTIONS.SET_RANGE,
            payload: e.value,
        })
    }

    const handleLockPlanning = async () => {
        dispatch({
            type: ACTIONS.SET_IS_LOADING_PLANNING,
            payload: true,
        })
        const data = {
            isLocked: !planning.planning?.isLocked,
            date: formatDateToApi(planningDate),
        }
        // const response = await API.MasterRuns.protectOrUnlock(data)
        await Plannings.post(data).then((response) => {
            dispatch({
                type: ACTIONS.SET_PLANNING,
                payload: response,
            })
            toast.success(
                `Le planning a été ${
                    !response.isLocked ? 'invalidé' : 'validé'
                } .`
            )
        })
        handleAnimation(data.isLocked)
        dispatch({
            type: ACTIONS.SET_IS_LOADING_PLANNING,
            payload: false,
        })
        showLockedModal()
    }

    const handleAnimation = (isLocked) => {
        if (isLocked) {
            lockRef.current?.playSegments([60, 140])
        } else {
            lockRef.current?.playSegments([1, 60])
        }
    }

    const handleOpenConfigModal = (value) => {
        dispatch({
            type: ACTIONS.SET_OPEN_CONFIG_MODAL,
            payload: value,
        })
    }

    const handleOpenTeamsModal = (value) => {
        dispatch({
            type: ACTIONS.SET_OPEN_TEAMS_MODAL,
            payload: value,
        })
    }

    const showLockedModal = () => {
        dispatch({
            type: ACTIONS.SET_SHOW_LOCK_MODAL,
        })
    }

    const atLeastOneFilterIsActive = () => {
        return (
            planning.defaultConfig.isPatientFilter.value !==
                planning.isPatientFilter.value ||
            planning.defaultConfig.showPartners !== planning.showPartners ||
            planning.showEffectiveHours
        )
    }

    const handleRefresh = () => {
        dispatch({ type: ACTIONS.CLOSE_MODALS })
        reRender()
    }

    const handleLockPlanningAnimation = () => {
        showLockedModal()
    }

    const lockRef = useRef(null)

    return (
        <div
            className={classNames('pt-4', {
                'bg-color-light-gray fullscreen-dashboard-planning absolute left-0 right-0 top-0 h-full overflow-y-scroll pl-4':
                    planning.focusMode,
            })}
            id="planning"
        >
            <div className="flex items-center justify-between px-4 lg:pr-10 xl:pl-0">
                <div className="flex flex-col space-y-6">
                    <CalendarDatePickerV2
                        openSelectDatePicker={planning.openSelectDatePicker}
                        setOpenSelectDatePicker={handleSelectOpenDatePicker}
                        handleLockPlanningAnimation={
                            handleLockPlanningAnimation
                        }
                        isPlanningLocked={planning.planning?.isLocked}
                        isFirstLoadingPlanning={planning.isFirstLoadingPlanning}
                        date={planningDate}
                        plannings={planning.plannings}
                        showLockedModal={showLockedModal}
                        setDate={(date) => handleDate(date)}
                        lockRef={lockRef}
                    />
                    <div className="flex flex-row space-x-1">
                        <div className="hidden lg:flex">
                            <SecondaryButton
                                action={handleFocusMode}
                                active={false}
                                hiddenLabelOnMobile={true}
                                icon={<Enlarge />}
                                title="Mode Focus"
                            />
                        </div>
                        <SecondaryButton
                            action={() => {
                                handleOpenTeamsModal(true)
                            }}
                            active={false}
                            label="Équipes"
                            title="Équipes"
                            hiddenLabelOnMobile={true}
                            icon={<Group />}
                        />
                        <SecondaryButton
                            action={() => {
                                handleOpenConfigModal(true)
                            }}
                            active={atLeastOneFilterIsActive()}
                            label="Configurer"
                            title="Configurer"
                            hiddenLabelOnMobile={true}
                            icon={<Settings />}
                        />
                        {location.pathname === '/regulation/dashboard' && (
                            <div className="z-32">
                                <SelectDropDownListForNumber
                                    icon={<ZoomIn />}
                                    options={planning.rangeList}
                                    isSearchable={false}
                                    showLabel={true}
                                    label="Ratio"
                                    title="Ratio"
                                    value={planning.rangeList.find(
                                        (rangeEl) =>
                                            rangeEl.value ===
                                            planning.intervalSize.range
                                    )}
                                    handleOptionChange={(e) =>
                                        handleRangeChange(e)
                                    }
                                />
                            </div>
                        )}

                        <div className="z-32">
                            <SelectDropDownListAddButtons
                                icon={<Plus className="text-lg" />}
                                options={planning.rangeList}
                                label="Ajouter"
                                dispatch={dispatch}
                                title="Ajouter"
                            />
                        </div>
                        <div className="flex lg:hidden">
                            <PlanningStats
                                label="Stats"
                                icon={<StatsUpSquare />}
                                labels={labels}
                                data={data}
                                backgroundColor={backgroundColor}
                                hoverBackgroundColor={hoverBackgroundColor}
                                cutout={cutout}
                                title="Statistiques"
                            />
                        </div>
                    </div>
                </div>
                <div className="hidden items-center lg:flex">
                    <div className="w-3/4">
                        <DoughnutChart
                            labels={labels}
                            data={data}
                            backgroundColor={backgroundColor}
                            hoverBackgroundColor={hoverBackgroundColor}
                            cutout={cutout}
                            aspectRatio="3.5"
                        />
                    </div>
                    <div className="flex w-full flex-col space-y-1">
                        {labels.map((label, index) => (
                            <div
                                key={index}
                                className="flex flex-row items-center justify-end space-x-2 text-sm"
                            >
                                <div
                                    className="h-2 w-2 rounded-full"
                                    style={{
                                        backgroundColor: backgroundColor[index],
                                    }}
                                />
                                <div>{label}</div>
                                <div>{data[index]}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Routes>
                <Route
                    path="/trie"
                    element={
                        <PlanningSorted
                            useOnClickAddMenuOutside={useOnClickAddMenuOutside}
                            showRunObjectColors={showRunObjectColors}
                        />
                    }
                />
                <Route
                    path="/melange"
                    element={
                        <PlanningShuffled
                            showRunObjectColors={showRunObjectColors}
                        />
                    }
                />
                <Route
                    path="/dashboard"
                    element={
                        <PlanningDashboard
                            showRunObjectColors={showRunObjectColors}
                        />
                    }
                />
            </Routes>
            {(planning.openRunManageModal ||
                planning.openNewRunManageModal) && (
                <ManageRunModal
                    setDateTo={setDateTo}
                    setDateFrom={setDateFrom}
                    dateFrom={planningDate}
                    setOpenRunManageModal={handleOpenRunManageModal}
                    setOpenNewRunManageModal={handleOpenNewRunManageModal}
                    setOpenSeriesManageModal={handleOpenSeriesManageModal}
                    setSeriesId={handleSeriesId}
                    reRender={reRender}
                    runId={
                        planning.openNewRunManageModal
                            ? 0
                            : planning.clickedRun
                            ? planning.clickedRun.id
                            : 0
                    }
                />
            )}
            {(planning.openSeriesManageModal ||
                planning.openNewSeriesManageModal) && (
                <ManageSeriesModal
                    dateFrom={planningDate}
                    setOpenSeriesManageModal={handleOpenSeriesManageModal}
                    setOpenNewSeriesManageModal={handleOpenNewSeriesManageModal}
                    reRender={reRender}
                    seriesId={
                        planning.openNewSeriesManageModal
                            ? 0
                            : planning.seriesId
                    }
                />
            )}
            {planning.openConfigModal && (
                <ConfigPlanningModal
                    dispatch={dispatch}
                    planning={planning}
                    dashboardCompletelyHidedTeams={
                        dashboardCompletelyHidedTeams
                    }
                    setDashboardCompletelyHidedTeams={
                        setDashboardCompletelyHidedTeams
                    }
                />
            )}
            {planning.openTeamsModal && (
                <TeamsPlanningModal
                    dispatch={dispatch}
                    planning={planning}
                    dashboardCompletelyHidedTeams={
                        dashboardCompletelyHidedTeams
                    }
                    setDashboardCompletelyHidedTeams={
                        setDashboardCompletelyHidedTeams
                    }
                    reRender={reRender}
                />
            )}
            {planning.openCompetitionModal && (
                <CompetitionModal handleRefresh={handleRefresh} />
            )}
            {planning.showLockModal && (
                <ActionModalWithLoader
                    closeModal={showLockedModal}
                    action={handleLockPlanning}
                    actionButton={isLockedLabel}
                    label={`Souhaitez-vous ${
                        planning.isLocked ? 'invalider' : 'valider'
                    } ce planning ?`}
                    isRemove={planning.isLocked}
                    isLoading={planning.isLoadingPlanning}
                    labelAction={`${isLockedLabel} le planning`}
                />
            )}
        </div>
    )
}

export default PlanningContainer
