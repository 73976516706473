import React, { useEffect, useReducer, useState } from 'react'
import { fetchTeamsList } from '../../../services/fetchRunsInformations'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Loader from '../../Commons/Loaders/Loader'
import PatientFormContent from '../Patient/PatientFormContent'
import TransportFormContent from './Forms/TransportFormContent'
import ShareForm from './Forms/ShareForm'
import RunModals from './RunModals'
import { useManageStatesAndFetchRunInfo } from './UseManageStates'
import PrescriptionForm, {
    formatCaisseOptions,
    formatPractitionerOptions,
} from './Forms/PrescriptionForm'
import {
    getGlobalInitialState,
    GlobalFormReducer,
} from './Reducers/RegulationGlobalReducer'
import API from '../../../services/api'
import { TRANSPORT_ACTIONS } from './Reducers/TransportFormReducer'
import { toast } from 'react-toastify'
import SecondaryButton from '../../Commons/Buttons/SecondaryButton'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'
import { getDateReducerFormatForApi } from '../../../services/DateService'
import RUN, { runButtonTitle } from '../../../services/run'
import { MODAL_FORM_ACTIONS } from './Reducers/ModalReducer'
import { handleValidations } from './FormHandlers/handleRunForm'
import { handlePatientForm } from '../Patient/handlePatientForm'
import { PRESCRIPTION_FORM_ACTIONS } from './Reducers/PrescriptionFormReducer'
import TransportCpamInvoiceForm from './Forms/TransportCpamInvoiceForm'
import { PATIENT_FORM_ACTIONS } from './Reducers/PatientFormReducer'
import { formatObjectOptions } from '../../../tools/Utility'
import { Car, Notes, PiggyBank, SendDiagonal, Tools, User } from 'iconoir-react'
import SettingsForm from './Forms/SettingsForm'
import { getBgFromStatusLabel } from '../../../services/RunStatus'
import { INVOICE_CPAM_ACTIONS } from './Reducers/InvoiceCpamReducer'
import { getTauxFromDate } from '../../../services/Prescription'
import TabComponent from './TabComponent'
import { REGULATION_KEYS } from '../../../hooks/useRunsList'
import { useQueryClient } from '@tanstack/react-query'

export const fetchHealthComplementaries = async (dispatch) => {
    const response = await API.HealthComplementary.list()
    if (response.ok) {
        const jsonData = await response.json()
        dispatch({
            type: PATIENT_FORM_ACTIONS.SET_HEALTH_COMPLEMENTARY_OPTIONS,
            payload: formatObjectOptions(jsonData['hydra:member']),
        })
    }
}

export const fetchPayingCenters = async (dispatch) => {
    const response = await API.payingCenter.list()
    if (response.ok) {
        const jsonData = await response.json()
        dispatch({
            type: PATIENT_FORM_ACTIONS.SET_PAYING_CENTERS_OPTIONS,
            payload: formatCaisseOptions(jsonData['hydra:member']),
        })
    }
}

const RunTabs = ({
    date,
    setDate,
    setDateTo,
    fromModal,
    setOpenRunManageModal,
    setOpenNewRunManageModal,
    setOpenSeriesManageModal,
    reRender,
    runId,
    setRunId,
    setSeriesId,
}) => {
    const queryClient = useQueryClient()
    const history = useNavigate()
    const location = useLocation()
    const state = location.state

    let { id } = useParams()
    if (fromModal && runId !== 0) {
        id = runId
    }

    const isUserAbleToInvoice = JSON.parse(localStorage.getItem('isInvoicer'))

    const urlParams = new URLSearchParams(location.search)
    const isCopy = urlParams.get('copy')
    const tab = urlParams.get('tab') || 'Transport'

    const [activeTab, setActiveTab] = useState(tab)

    const [global, globalDispatch] = useReducer(
        GlobalFormReducer,
        { date },
        getGlobalInitialState
    )

    // custom hook to manage states / reducers / useEffect
    const {
        dateInput,
        dateDispatch,
        prsDateInput,
        prsDateDispatch,
        realDateInput,
        realDateDispatch,
        pickUpAddress,
        pickUpDispatch,
        depositAddress,
        depositDispatch,
        workAccidentDate,
        workAccidentDateDispatch,
        dateMaternity,
        dateMaternityDispatch,
        priorApprovalDate,
        priorApprovalDateDispatch,
    } = useManageStatesAndFetchRunInfo(
        id,
        global,
        location,
        globalDispatch,
        date,
        fetchHealthComplementaries,
        fetchPayingCenters
    )

    const runForm = global.transportForm
    const invoiceCpam = global.invoiceCpamForm
    const patientForm = global.patientForm
    const prescriptionForm = global.prescriptionForm

    const historyPush = () => {
        if (location.search.includes('?copy=')) {
            history(-1)
        } else if (fromModal) {
            setOpenRunManageModal(false)
            setOpenNewRunManageModal(false)
            reRender()
        } else if (state && state.from && state.from === '/dashboard') {
            history('/regulation/dashboard')
        } else {
            history('/transport')
        }
    }
    const handleRunForm = async (
        doNotDeleteReturnRun = false,
        redirect = true
    ) => {
        // prevent to change global address
        delete pickUpAddress.id
        delete pickUpAddress['@id']
        delete depositAddress.id
        delete depositAddress['@id']
        runForm.pickUpLocation = pickUpAddress
        runForm.depositLocation = depositAddress

        runForm.prsDate = getDateReducerFormatForApi(prsDateInput)
        global.prescriptionForm.dateMaternity = getDateReducerFormatForApi(
            dateMaternity,
            true
        )
        global.prescriptionForm.workAccidentDate = getDateReducerFormatForApi(
            workAccidentDate,
            true
        )
        global.prescriptionForm.priorApprovalDate = getDateReducerFormatForApi(
            priorApprovalDate,
            true
        )
        runForm.doNotDeleteReturnRun = doNotDeleteReturnRun

        globalDispatch({
            type: TRANSPORT_ACTIONS.SET_DISABLE_BUTTONS,
            payload: true,
        })
        const formResponse = await handlePatientForm(
            patientForm,
            dateInput,
            null,
            false
        )
        if (formResponse.success) {
            globalDispatch({
                type: PATIENT_FORM_ACTIONS.GET_PATIENT,
                payload: formResponse.data,
            })
            let result = { success: false, data: null }
            try {
                let response = await RUN.Post(
                    runForm,
                    historyPush,
                    location,
                    global,
                    redirect
                )
                if (response) {
                    result.success = true
                }
            } catch (error) {
                if (error.json) {
                    const data = await error.json()
                    toast.error(data['hydra:description'])
                } else {
                    toast.error('Erreur, veuillez contacter un administrateur')
                }
                result.success = false
                result.data = null
            }
            await queryClient.invalidateQueries({
                queryKey: REGULATION_KEYS.LIST,
            })

            globalDispatch({
                type: TRANSPORT_ACTIONS.SET_DISABLE_BUTTONS,
                payload: false,
            })

            return result
        } else {
            globalDispatch({
                type: TRANSPORT_ACTIONS.SET_DISABLE_BUTTONS,
                payload: false,
            })
        }
    }

    const handleFormValidations = async (redirect = true) => {
        if (
            !handleValidations(
                runForm,
                globalDispatch,
                pickUpAddress,
                depositAddress,
                prsDateInput,
                id,
                prescriptionForm,
                dateMaternity,
                workAccidentDate,
                priorApprovalDate
            )
        ) {
            const response = await handleRunForm(false, redirect)
            if (response?.success) {
                return true
            } else {
                return false
            }
        }
        return false
    }

    const handleRemoveModal = () => {
        globalDispatch({
            type: MODAL_FORM_ACTIONS.OPEN_MODAL,
            payload: {
                labelAction: 'Modifier le transport',
                actionButton: 'Modifier',
                label: 'Êtes-vous sûr de vouloir modifier ce transport déjà facturée ?',
            },
        })
    }

    const handleRemoveShare = async () => {
        const result = await RUN.RemoveShare(runForm.id)
        if (result?.success) {
            history(-1)
            globalDispatch({
                type: TRANSPORT_ACTIONS.REMOVE_SHARE,
            })
        }
    }

    const checkIfHandleFormButtonShouldBePresent = () => {
        if (activeTab === 'Facture') {
            return false
        } else if (invoiceCpam.status) {
            if (invoiceCpam.status.label === 'Créée') {
                return true
            }
        } else {
            return true
        }
        return false
    }

    const handleCancel = () => {
        if (fromModal) {
            setOpenRunManageModal(false)
            setOpenNewRunManageModal(false)
        } else {
            history(-1)
        }
    }

    // TODO REFACTO
    const fetchDistanceData = (manualGet = false) => {
        if (pickUpAddress.city && depositAddress.city) {
            if (!runForm.kilometer || manualGet)
                API.Distance.get(pickUpAddress.city, depositAddress.city).then(
                    (response) => {
                        response.json().then((data) => {
                            const distance = data['hydra:member'][0]
                            if (distance) {
                                globalDispatch({
                                    type: TRANSPORT_ACTIONS.SET_KILOMETER,
                                    payload: distance.kilometer.toString(),
                                })
                                globalDispatch({
                                    type: INVOICE_CPAM_ACTIONS.UPDATE_KM_RUN_INVOICE_DETAILS,
                                    payload: {
                                        runId: id,
                                        kilometer:
                                            distance.kilometer.toString(),
                                    },
                                })
                            } else {
                                globalDispatch({
                                    type: TRANSPORT_ACTIONS.SET_DISTANCE_NOT_FOUND,
                                })
                            }
                        })
                    }
                )
        } else {
            toast.info(
                'Veuillez renseigner les adresses de prise en charge et de dépose'
            )
        }
    }

    const fetchPractitioners = async () => {
        const response = await API.Practitioner.list()
        if (response.ok) {
            const jsonData = await response.json()
            globalDispatch({
                type: PRESCRIPTION_FORM_ACTIONS.SET_PRACTITIONERS_OPTIONS,
                payload: formatPractitionerOptions(jsonData['hydra:member']),
            })
        }
    }
    //
    useEffect(() => {
        // if the user is on the prescription tab fetch the practitioners
        if (
            activeTab === 'Prescription' &&
            global.prescriptionForm.practitionersOptions.length === 0
        ) {
            fetchPractitioners().then(() => {})
        }
    }, [activeTab])

    useEffect(() => {
        if (
            runForm.date &&
            runForm.date instanceof Date &&
            !isNaN(runForm.date)
        ) {
            setDate(runForm.date)
            setDateTo(runForm.date)

            fetchTeamsList(globalDispatch, runForm).catch((error) => {
                console.error('Error fetching teams list:', error)
                // Handle error appropriately
            })

            globalDispatch({
                type: PRESCRIPTION_FORM_ACTIONS.SET_RATE_OPTIONS,
                payload: getTauxFromDate(runForm.date),
            })
        } else {
            console.warn('Invalid or missing date in runForm:', runForm.date)
            // Handle invalid date case
        }
    }, [runForm.date, globalDispatch])

    const handleChangeKilometer = (e) => {
        globalDispatch({
            type: TRANSPORT_ACTIONS.SET_KILOMETER,
            payload: e.target.value,
        })

        // identifier la bonne run avec le runId changer le total kilometer dans le runInvoiceDetails approprié
        if (!runForm.series) {
            globalDispatch({
                type: INVOICE_CPAM_ACTIONS.UPDATE_KM_RUN_INVOICE_DETAILS,
                payload: { runId: id, kilometer: e.target.value },
            })
        }
    }

    const handleChangeAmount = (e) => {
        globalDispatch({
            type: TRANSPORT_ACTIONS.SET_AMOUNT,
            payload: e.target.value,
        })

        // identifier la bonne run avec le runId changer le total kilometer dans le runInvoiceDetails approprié
        globalDispatch({
            type: INVOICE_CPAM_ACTIONS.UPDATE_AMOUNT_RUN_INVOICE_DETAILS,
            payload: { runId: id, amount: e.target.value },
        })
    }

    const tabs = [
        {
            name: 'Transport',
            icon: (
                <div className={'relative'}>
                    <Car className={`mr-2`} />
                    <div>
                        <div
                            className={`${getBgFromStatusLabel(
                                runForm?.status
                            )} absolute right-7 top-0 h-3 w-3 rounded-full border`}
                        ></div>
                    </div>
                </div>
            ),
            component: (
                <TransportFormContent
                    run={runForm}
                    dispatch={globalDispatch}
                    dateDispatch={dateDispatch}
                    pickUpAddress={pickUpAddress}
                    pickUpDispatch={pickUpDispatch}
                    depositAddress={depositAddress}
                    depositDispatch={depositDispatch}
                    setOpenRunManageModal={setOpenRunManageModal}
                    setOpenNewRunManageModal={setOpenNewRunManageModal}
                    setOpenSeriesManageModal={setOpenSeriesManageModal}
                    setSeriesId={setSeriesId}
                    fetchDistanceData={fetchDistanceData}
                    handleChangeKilometer={handleChangeKilometer}
                    handleChangeAmount={handleChangeAmount}
                    navigate={history}
                    setRunId={setRunId}
                    fromModal={fromModal}
                    prescriptionForm={prescriptionForm}
                />
            ),
        },
        {
            name: 'Patient',
            icon: <User className={'mr-2'} />,
            component: (
                <PatientFormContent
                    patient={patientForm}
                    date={dateInput}
                    dateDispatch={dateDispatch}
                    id={patientForm.id}
                    dispatch={globalDispatch}
                    handleShowPatientRuns={null}
                />
            ),
        },
        {
            name: 'Partage',
            icon: <SendDiagonal className={'mr-2'} />,

            component: (
                <ShareForm
                    run={runForm}
                    dispatch={globalDispatch}
                    handleRemoveShare={handleRemoveShare}
                />
            ),
        },
        {
            name: 'Prescription',
            icon: <Notes className={'mr-2'} />,
            component: (
                <PrescriptionForm
                    prescription={prescriptionForm}
                    runForm={runForm}
                    dispatch={globalDispatch}
                    prsDateInput={prsDateInput}
                    prsDateDispatch={prsDateDispatch}
                    realDateInput={realDateInput}
                    realDateDispatch={realDateDispatch}
                    workAccidentDate={workAccidentDate}
                    workAccidentDateDispatch={workAccidentDateDispatch}
                    priorApprovalDate={priorApprovalDate}
                    priorApprovalDateDispatch={priorApprovalDateDispatch}
                    dateMaternity={dateMaternity}
                    dateMaternityDispatch={dateMaternityDispatch}
                    patientForm={patientForm}
                    birthDate={dateInput}
                    practitionerForm={global.practitionerForm}
                />
            ),
        },
        {
            name: 'Facture',
            icon: <PiggyBank className={'mr-2'} />,
            component: (
                <TransportCpamInvoiceForm
                    invoice={invoiceCpam}
                    run={runForm}
                    dateOfBirth={dateInput}
                    prescription={global.prescriptionForm}
                    handleRunFormValidation={handleFormValidations}
                    patient={global.patientForm}
                    dispatch={globalDispatch}
                    pickUpAddress={pickUpAddress}
                    depositAddress={depositAddress}
                />
            ),
            disabled: !isUserAbleToInvoice,
        },
        {
            name: 'Options',
            icon: <Tools className={'mr-2'} />,
            component: <SettingsForm run={runForm} dispatch={globalDispatch} />,
        },
    ]

    return (
        <>
            {runForm?.loading ? (
                <Loader />
            ) : (
                <div className="container mx-auto pt-2">
                    <TabComponent
                        tabs={tabs}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                        runForm={runForm}
                        patientForm={patientForm}
                    />
                    <div className="mt-auto flex w-full justify-between border-t px-2 py-5 lg:px-5">
                        <SecondaryButton
                            label="Annuler"
                            title="Annuler"
                            hiddenLabelOnMobile={false}
                            disabled={runForm.loading}
                            loader={runForm.loading}
                            action={handleCancel}
                        />
                        {checkIfHandleFormButtonShouldBePresent() ? (
                            <PrimaryButton
                                label={runButtonTitle(runForm, id)}
                                title={runButtonTitle(runForm, id)}
                                loader={runForm.disableButtons}
                                disabled={runForm.disableButtons}
                                action={handleFormValidations}
                            />
                        ) : null}
                    </div>
                </div>
            )}
            <RunModals
                run={runForm}
                date={dateInput}
                modal={global.modalReducer}
                dateDispatch={dateDispatch}
                patientForm={global.patientForm}
                dispatch={globalDispatch}
                handleForm={handleRunForm}
            />
        </>
    )
}

export default RunTabs
