import React from 'react'
import { formatEuro } from '../Manage'
import StyledTextarea from '../../../Commons/StyledTextArea'
import { FREE_INVOICE_ACTIONS } from '../../../../services/Reducers/FreeInvoiceReducer'
import { getTotalHT, getTotalTTC, tvaGroups } from './FreeInvoiceWrapper'

const InvoiceViewer = ({ freeInvoiceForm, dispatch }) => {
    let { patient, paymentMethod } = freeInvoiceForm
    paymentMethod = paymentMethod?.label ? paymentMethod?.label : paymentMethod

    const defaultAddress =
        patient?.patientsAddresses?.find((pa) => pa.defaultAddress)?.address ||
        patient?.defaultDepositAddress

    return (
        <div className="mx-auto max-w-7xl space-y-6 p-6">
            <div className="grid grid-cols-2 gap-6">
                <div className="col-span-2 space-y-4 rounded-lg border bg-white p-6 shadow">
                    {freeInvoiceForm.isInvoice ? (
                        <div className="flex items-baseline ">
                            <span className="mr-4 block text-sm font-medium text-gray-700">
                                Réglée le
                            </span>
                            <input
                                type="text"
                                value={freeInvoiceForm.paidAt}
                                placeholder={'jj/mm/aaaa'}
                                onChange={(e) =>
                                    dispatch({
                                        type: FREE_INVOICE_ACTIONS.SET_PAID_AT,
                                        payload: e.target.value,
                                    })
                                }
                                className=" border-b border-secondary-color text-center font-semibold  focus:border-primary-color focus:outline-none"
                            />
                        </div>
                    ) : null}
                    <StyledTextarea
                        label="Annotation (non visible sur le document)"
                        onChange={(e) => {
                            dispatch({
                                type: FREE_INVOICE_ACTIONS.SET_PRIVATE_NOTE,
                                payload: e.target.value,
                            })
                        }}
                        value={freeInvoiceForm.privateNote}
                    />
                </div>
                <div className="space-y-2 rounded-lg border bg-white p-6 shadow">
                    <h2 className="font-semibold">
                        {freeInvoiceForm.society?.label}
                    </h2>
                    <p>{freeInvoiceForm.society?.address?.street}</p>
                    <p>{freeInvoiceForm.society?.address?.secondaryStreet}</p>
                    <p>
                        {freeInvoiceForm.society?.address?.zipCode}{' '}
                        {freeInvoiceForm.society?.address?.city}
                    </p>
                    <p>Tél : {freeInvoiceForm.society?.phoneNumber}</p>
                    <p>{freeInvoiceForm.society?.mail}</p>
                </div>

                <div className="space-y-2 rounded-lg border bg-white p-6 shadow">
                    <h2 className="font-semibold">ADRESSÉ À</h2>
                    {freeInvoiceForm.establishment ? (
                        <>
                            <p>{freeInvoiceForm.establishment.label}</p>
                            <p>
                                {freeInvoiceForm.establishment.address?.street}
                            </p>
                            <p>
                                {
                                    freeInvoiceForm.establishment?.address
                                        ?.secondaryStreet
                                }
                            </p>
                            <p>
                                {freeInvoiceForm.establishment.address?.zipCode}{' '}
                                {freeInvoiceForm.establishment.address?.city}
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                {patient?.firstname} {patient?.lastname}
                            </p>
                            <p>{defaultAddress?.street}</p>
                            <p>
                                {defaultAddress?.zipCode} {defaultAddress?.city}
                            </p>
                        </>
                    )}
                </div>
            </div>
            {freeInvoiceForm.title && freeInvoiceForm.comments ? (
                <div className="space-y-2 rounded-lg border bg-white p-6 shadow">
                    {freeInvoiceForm.title && (
                        <p className="whitespace-pre-line text-lg">
                            {freeInvoiceForm.title}
                        </p>
                    )}
                    {freeInvoiceForm.comments && (
                        <p className="whitespace-pre-line text-gray-600">
                            {freeInvoiceForm.comments}
                        </p>
                    )}
                </div>
            ) : null}
            <div className="rounded-lg border bg-white p-6 shadow">
                <table className="w-full">
                    <thead className="border-b">
                        <tr>
                            <th className="p-2 text-center">Qté</th>
                            <th className="p-2 text-left">Désignation</th>
                            <th className="p-2 text-center">TVA (%)</th>
                            <th className="p-2 text-center">Remise (%)</th>
                            <th className="p-2 text-right">Prix unit. HT</th>
                            <th className="p-2 text-right">Total HT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {freeInvoiceForm.freeInvoiceLines.map((item, index) => (
                            <tr key={index} className="border-b">
                                <td className="p-2 text-center">
                                    {item.quantity}
                                </td>
                                <td className="p-2">{item.label}</td>
                                <td className="p-2 text-center">{item.vat}</td>
                                <td className="p-2 text-center">
                                    {item.discount}
                                </td>
                                <td className="p-2 text-right">
                                    {formatEuro(item.unitPrice)}
                                </td>
                                <td className="p-2 text-right">
                                    {formatEuro(item.quantity * item.unitPrice)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className={'mt-6 flex flex-col space-y-4'}>
                    {freeInvoiceForm.isInvoice && paymentMethod ? (
                        <div>Méthode de paiement : {paymentMethod}</div>
                    ) : null}
                    <div className=" flex justify-between ">
                        <p className="mt-2 w-1/2 whitespace-pre-line">
                            {freeInvoiceForm.note}
                        </p>
                        <div className="w-64 space-y-2">
                            <div className="flex justify-between">
                                <span>Total HT</span>
                                <span>
                                    {formatEuro(
                                        getTotalHT(
                                            freeInvoiceForm.freeInvoiceLines
                                        )
                                    )}
                                </span>
                            </div>
                            {Object.entries(
                                tvaGroups(freeInvoiceForm.freeInvoiceLines)
                            ).map(([rate, amount]) => (
                                <div
                                    key={rate}
                                    className="flex justify-between"
                                >
                                    <span>TVA {rate}%</span>
                                    <span>{formatEuro(amount)}</span>
                                </div>
                            ))}
                            <div className="flex justify-between border-t pt-2 font-bold">
                                <span>Total TTC</span>
                                <span>
                                    {formatEuro(
                                        getTotalTTC(
                                            freeInvoiceForm.freeInvoiceLines
                                        )
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!freeInvoiceForm.isInvoice && (
                <div className="rounded-lg border bg-white p-6 shadow">
                    <p>Validité du devis : 3 mois</p>
                    <p>
                        Conditions de règlement : 40% à la commande, le solde à
                        la livraison
                    </p>
                    <p className="mt-4">
                        Si ce devis vous convient, veuillez nous le retourner
                        signé précédé de la mention :
                    </p>
                    <p className="my-2 font-semibold">
                        « Bon pour accord et exécution de la prestation »
                    </p>
                    <div className="mt-6 grid grid-cols-2 gap-8">
                        <div>
                            <p className="font-semibold">Date :</p>
                            <div className="mt-2 border-b"></div>
                        </div>
                        <div>
                            <p className="font-semibold">Signature :</p>
                            <div className="mt-2 border-b"></div>
                        </div>
                    </div>
                </div>
            )}
            <div className="rounded-lg border bg-white p-6 shadow">
                <h2 className="border-b-4 pb-2 text-xl font-semibold">
                    Informations bancaires
                </h2>
                <div className="grid grid-cols-1 gap-4 py-4 md:grid-cols-3">
                    <div>
                        <p className="font-medium text-gray-700">
                            Titulaire du compte
                        </p>
                        <p className="text-gray-600">
                            {freeInvoiceForm.society?.accountHolderName}
                        </p>
                    </div>
                    <div>
                        <p className="font-medium text-gray-700">IBAN</p>
                        <p className="text-gray-600">
                            {freeInvoiceForm.society?.IBAN}
                        </p>
                    </div>
                    <div>
                        <p className="font-medium text-gray-700">BIC</p>
                        <p className="text-gray-600">
                            {freeInvoiceForm.society?.BIC}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceViewer
