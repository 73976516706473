import React, { useState } from 'react'
import useSignupStore from '../../../stores/Signup'
import { useNavigate } from 'react-router-dom'
import jwt from 'jwt-decode'
import API from '../../../services/api'
import Loader from '../../Commons/Loaders/Loader'
import gtag from 'ga-gtag'
import PrimaryButton from '../../Commons/Buttons/PrimaryButton'

const SignupFormSociety = () => {
    let history = useNavigate()
    const setError = useSignupStore((state) => state.setError)
    const error = useSignupStore((state) => state.error)
    const societyName = useSignupStore((state) => state.societyName)
    const setSocietyName = useSignupStore((state) => state.setSocietyName)
    const siren = useSignupStore((state) => state.siren)
    const setSiren = useSignupStore((state) => state.setSiren)
    const nbVehicles = useSignupStore((state) => state.nbVehicles)
    const setNbVehicles = useSignupStore((state) => state.setNbVehicles)
    const [loading, setLoading] = useState(false)
    const [inputLoading, setInputLoading] = useState(false)
    const isFree = localStorage.getItem('isFree')

    const handleForm = () => {
        setLoading(true)
        const data = {
            societyName: societyName,
            siren: parseInt(siren),
            nbVehicles: parseInt(nbVehicles),
        }
        if (!isFree) {
            API.Authentication.handleSignUpSociety(
                localStorage.getItem('id'),
                data
            )
                .then((response) => {
                    response.json().then((token) => {
                        localStorage.setItem('token', token.token)
                        const user = jwt(token.token)
                        localStorage.setItem('society', user.society)
                        history('/inscription/plans')
                    })
                })
                .catch((error) => {
                    if (error.json) {
                        error.json().then((data) => {
                            setError(data.error)
                            setLoading(false)
                        })
                    }
                })
        } else {
            setInputLoading(true)
            API.Authentication.handleFreeSignup(
                localStorage.getItem('id'),
                data
            )
                .then((response) => {
                    response.json().then((token) => {
                        localStorage.setItem('token', token.token)
                        const user = jwt(token.token)
                        localStorage.setItem('society', user.society)
                        localStorage.setItem(
                            'societyWelcomePresentationPassed',
                            'false'
                        )
                        gtag('event', 'conversion', {
                            send_to: 'AW-10784756791/ZTu2CIDx9aYDELeoyZYo',
                            page_title: 'Abonnement gratuit',
                        })
                        setInputLoading(false)
                        history('/transport')
                    })
                })
                .catch((error) => {
                    if (error.json) {
                        error.json().then((data) => {
                            setError(data.error)
                            setLoading(false)
                            setInputLoading(false)
                        })
                    }
                })
        }
    }

    const historyPush = () => {
        if (!checkErrors()) {
            handleForm()
        }
    }
    const checkErrors = () => {
        setLoading(true)
        let msg = ''

        if (siren.toString().length !== 9) {
            msg += '<div> Veuillez renseigner un siren valide.</div>'
        }

        if (!societyName) {
            msg += '<div> Veuillez renseigner le nom de votre société.</div>'
        }

        if (nbVehicles <= 0) {
            msg +=
                '<div> Veuillez renseigner le nombre de véhicules de votre société.</div>'
        }

        setError(msg)
        setLoading(false)
        return msg !== ''
    }

    const handleSiren = (value) => {
        setSiren(value.replace(/\D/g, '').substring(0, 9))
    }

    const handleNbVehicles = (value) => {
        setNbVehicles(value.replace(/\D/g, ''))
    }

    const historyBack = () => {
        history('/inscription')
    }

    return (
        <div className="mx-auto w-full max-w-xl">
            <div className="py-5 text-3xl text-gray-700">Votre société</div>
            {loading ? (
                <Loader />
            ) : (
                <div className="rounded-md bg-white p-4 shadow-md">
                    <div className="flex flex-row-reverse pb-4 pr-4 lg:pb-10 lg:pr-10">
                        <button
                            className="text-blue-600 hover:underline"
                            onClick={historyBack}
                        >
                            Modifier mes informations
                        </button>
                    </div>
                    <div className="mx-auto flex w-full max-w-md flex-col space-y-4  py-5">
                        <div className="flex flex-col space-y-4">
                            <div className="w-full">
                                <label
                                    className="mb-2 block text-sm font-bold text-gray-700"
                                    htmlFor="societyName"
                                >
                                    Numéro SIREN de la société
                                </label>
                                <input
                                    value={siren}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                        siren.toString().length !== 9 && error
                                            ? 'border-red-500'
                                            : 'border-gray-300'
                                    }`}
                                    id="societySiren"
                                    type="text"
                                    name="societySiren"
                                    onChange={(e) =>
                                        handleSiren(e.target.value)
                                    }
                                    placeholder="900890789"
                                />
                            </div>
                            <div className="w-full">
                                <label
                                    className="mb-2 block text-sm font-bold text-gray-700"
                                    htmlFor="societyName"
                                >
                                    Dénomination de la société
                                </label>
                                <input
                                    value={societyName}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                        !societyName && error
                                            ? 'border-red-500'
                                            : 'border-gray-300'
                                    }`}
                                    id="societyName"
                                    type="text"
                                    name="societyName"
                                    onChange={(e) =>
                                        setSocietyName(e.target.value)
                                    }
                                    placeholder="Ambulances/taxis du soleil"
                                />
                            </div>
                            <div className="w-full">
                                <label
                                    className="mb-2 block text-sm font-bold text-gray-700"
                                    htmlFor="societyName"
                                >
                                    Nombre de véhicules de la société
                                </label>
                                <input
                                    value={nbVehicles}
                                    className={`block h-10 w-full appearance-none rounded-lg border pl-4 leading-tight text-gray-700 focus:border-lighter-blue-green focus:outline-none ${
                                        nbVehicles <= 0 && error
                                            ? 'border-red-500'
                                            : 'border-gray-300'
                                    }`}
                                    id="societySiren"
                                    type="text"
                                    name="societySiren"
                                    onChange={(e) =>
                                        handleNbVehicles(e.target.value)
                                    }
                                    placeholder="4"
                                />
                            </div>
                            <PrimaryButton
                                label={
                                    isFree
                                        ? "Accéder à l'applicaton"
                                        : 'Choisir mon plan'
                                }
                                title={
                                    isFree
                                        ? "Accéder à l'applicaton"
                                        : 'Choisir mon plan'
                                }
                                action={historyPush}
                                hiddenLabelOnMobile={false}
                                fullWidth={true}
                                loader={inputLoading}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default SignupFormSociety
