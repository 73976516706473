import React, { useEffect, useState } from 'react'
import API from '../../services/api'
import { useLocation } from 'react-router-dom'

const Registration = () => {
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [error, setError] = useState('')
    const [user, setUser] = useState([])
    const location = useLocation()
    const token = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
    )

    useEffect(() => {
        API.Authentication.getUserForRegistration(token)
            .then((response) => {
                response.json().then((data) => {
                    setUser(data)
                })
            })
            .catch((error) => {
                window.location.href = '/' // redirect
            })
    }, [])

    const handleRegister = () => {
        if (password === passwordConfirmation && password.length >= 8) {
            API.Authentication.handleRegistration(token, password)
                .then(async (response) => {
                    if (response.ok) {
                        window.location.href = '/' // redirect
                    }
                })
                .catch((error) => {
                    setError(error.statusText)
                })
        } else {
            setError(
                'Les mots de passes saisis ne sont pas identiques ou sont trop courts'
            )
        }
    }

    return (
        <div className="h-screen md:grid md:grid-cols-12">
            <div
                className="bg-color-light-blue-green md:col-span-8 md:h-full
            "
            >
                <h1 className="pl-4 pt-2 font-sans text-3xl font-bold uppercase md:py-10 md:pl-16">
                    Heroad <i className="fas fa-ambulance pl-2" />
                </h1>
                <div className="px-4 py-5 sm:py-10 md:pt-20 lg:max-w-4xl xl:pl-24 xl:pt-32">
                    <h2 className="home-title font-extrabold uppercase md:text-2xl xl:text-4xl">
                        Votre allié du numérique pour une vie simplifiée
                    </h2>
                    <ul className="hidden pt-2 tracking-wide md:block md:pt-8 md:leading-8 xl:text-xl ">
                        <li>
                            Ajoutez les transports de vos patients à tout moment
                        </li>
                        <li>
                            Générez automatiquement votre planning pour le
                            lendemain
                        </li>
                        <li>
                            Modifiez vos transports facilement avec notre
                            système de drag and drop
                        </li>
                    </ul>
                </div>
            </div>
            <div className="md:col-span-4 md:h-full">
                <form className="bg-white pt-5">
                    <div className="px-2 sm:px-6 md:pt-40 xl:px-20 xl:pt-56 ">
                        <div className="rounded bg-red-300 text-center text-red-500">
                            {error && <p>{error}</p>}
                        </div>
                        <div className="mb-4">
                            <label
                                className="mb-2 block text-sm font-bold text-gray-700"
                                htmlFor="password"
                            >
                                Mot de passe
                            </label>
                            <input
                                value={password}
                                className=" w-full appearance-none rounded border px-3  py-2 leading-tight text-gray-700 focus:outline-none focus:ring xl:w-5/6"
                                id="password"
                                type="password"
                                name="password"
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="*********"
                            />
                        </div>
                        <div className="mb-6">
                            <label
                                className="mb-2 block text-sm font-bold text-gray-700"
                                htmlFor="password"
                            >
                                Confirmation de mot de passe
                            </label>
                            <input
                                value={passwordConfirmation}
                                onChange={(e) =>
                                    setPasswordConfirmation(e.target.value)
                                }
                                className=" mb-3 w-full appearance-none rounded border  px-3 py-2 leading-tight text-gray-700 focus:outline-none focus:ring xl:w-5/6"
                                id="passwordConfirmation"
                                type="password"
                                name="passwordConfirmation"
                                placeholder="*********"
                            />
                        </div>
                        <p className="pb-2 text-xs italic text-red-500">
                            Votre mot de passe doit faire au moins 8 charactères
                        </p>
                        <div className="">
                            <button
                                onClick={() => handleRegister()}
                                className="bg-color-light-dark-green w-full rounded px-4 py-2 font-bold text-white focus:outline-none focus:ring xl:w-5/6"
                                type="button"
                            >
                                Confirmation
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Registration
