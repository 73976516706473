import { RUNINFO_ACTIONS } from '../../services/Reducers/RunInformationReducer'
import FormSwitch from '../Commons/Buttons/FormSwitch'
import SimpleSelectDropDownList from '../Commons/SimpleSelectDropDownList'
import { formatOptions } from '../../tools/Utility'

export const ContactModal = ({ runInfo, dispatch, handleForm }) => (
    <div
        className={`
                         modal fixed left-0 bottom-0 flex h-full  w-full items-center justify-center bg-gray-500 bg-opacity-50 text-gray-700`}
    >
        <div className="shadow-modal invisible-scrollbar min-h-modal-mobile m-2 flex w-full flex-col overflow-y-auto rounded-lg bg-white p-4 ">
            <div className="flex flex-col space-y-10">
                <div className="flex w-full items-center border-b  pb-4">
                    <div className="flex space-x-4 text-center font-semibold text-gray-600 ">
                        Contact par SMS
                    </div>
                    <svg
                        className="ml-auto h-10 w-10 cursor-pointer fill-current text-gray-600"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 18 18"
                        onClick={() =>
                            dispatch({
                                type: RUNINFO_ACTIONS.CLOSE_CONTACT_MODAL,
                                payload: false,
                            })
                        }
                    >
                        <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                    </svg>
                </div>
                <div className="flex flex-col justify-center ">
                    <FormSwitch
                        label="Qui contacter ?"
                        className={'text-gray-600'}
                        id="patient"
                        valueLeft={!runInfo.contactAll}
                        valueRight={runInfo.contactAll}
                        leftLabel={'Choisir'}
                        rightLabel={'Tous'}
                        onClickLeft={() =>
                            dispatch({
                                type: RUNINFO_ACTIONS.CONTACT_ALL,
                                payload: !runInfo.contactAll,
                            })
                        }
                        onClickRight={() =>
                            dispatch({
                                type: RUNINFO_ACTIONS.CONTACT_ALL,
                                payload: !runInfo.contactAll,
                            })
                        }
                    />
                </div>
                {runInfo.patient.patientsContacts.map((contact) => (
                    <div className="flex flex-col space-y-1">
                        <p className="font-bold text-gray-600">
                            {contact.label}
                        </p>
                        {contact.phoneNumber && (
                            <div className="flex items-center justify-between">
                                <a href={`tel:${contact.phoneNumber}`}>
                                    {contact.phoneNumber}
                                </a>
                                <i className="fa fa-phone" />
                            </div>
                        )}
                    </div>
                ))}
                {!runInfo.contactAll ? (
                    <div className="flex flex-col">
                        <p className="pb-1 font-bold text-gray-600">
                            Choisir les contacts
                        </p>
                        <SimpleSelectDropDownList
                            options={formatOptions(
                                runInfo.patient.patientsContacts
                            )}
                            placeholder="contact"
                            value={runInfo.personToContact}
                            handleOptionChange={(e) => {
                                dispatch({
                                    type: RUNINFO_ACTIONS.SET_PERSON_TO_CONTACT,
                                    payload: e,
                                })
                            }}
                            isMulti={true}
                        />
                    </div>
                ) : (
                    <div className="flex flex-col space-y-1"></div>
                )}
            </div>
            <div className="mt-auto">
                <div className="flex flex-col space-y-2">
                    <button
                        onClick={() => handleForm('ongoing', false)}
                        className="left-0 ml-2 rounded-md border border-dark-green bg-transparent py-4 text-lg  font-bold text-dark-green shadow-md hover:border-transparent hover:bg-light-blue-green hover:text-white "
                    >
                        Confirmer sans notifier
                    </button>
                    <p className="text-center"> Ou</p>
                    <button
                        onClick={() => handleForm('ongoing')}
                        className="bg-color-light-dark-green flex w-full justify-center rounded-md py-4 shadow-md "
                    >
                        <p className="text-lg font-bold text-white">
                            {runInfo.contactAll
                                ? 'Confirmer et envoyer le SMS à tous'
                                : 'Confirmer et envoyer le SMS'}
                        </p>
                    </button>
                </div>
            </div>
        </div>
    </div>
)
