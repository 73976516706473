import * as dayjs from 'dayjs'
import { ChatBubbleCheck } from 'iconoir-react'

export const MultipleNotification = ({ multiNotif }) => {
    return (
        <div className="flex w-full items-center ">
            {multiNotif.type === 'both_ongoing_and_add_comment' ? (
                <ChatBubbleCheck
                    className={'min-w-2-5 h-6 w-6 text-blue-500'}
                />
            ) : (
                <ChatBubbleCheck
                    className={'min-w-2-5 h-6 w-6 text-green-500'}
                />
            )}
            <div className="flex flex-col pl-2 pr-1">
                <div className="text-notif wrapper flex text-left text-gray-700 ">
                    <a className={'font-bold capitalize'}>
                        {multiNotif.notifier.fullName}
                    </a>
                    <a>
                        {multiNotif.type === 'both_ongoing_and_add_comment'
                            ? ' a pris en charge le transport de'
                            : ' a finalisé le transport de '}
                    </a>
                    <a className="capitalize underline">
                        {multiNotif.run.patient.fullName + ' '}
                    </a>
                    <p>
                        et a ajouté un commentaire. «{' '}
                        {multiNotif.run.userComments}»
                    </p>
                </div>
                <p className="text-left text-xs text-gray-500">
                    {dayjs(multiNotif.createdAt).fromNow()}
                </p>
            </div>
            {!multiNotif.seen && (
                <div className="ml-auto">
                    <div className={`h-2 w-2 rounded-full bg-blue-500 `} />
                </div>
            )}
        </div>
    )
}
