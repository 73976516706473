import ReactPaginate from 'react-paginate'
import React from 'react'

const CardPaginationForcePaged = ({
    totalPages,
    handlePageClick,
    totalItems,
    title,
    page,
}) => {
    return (
        <div className="hidden  w-full pb-2 text-base lg:block">
            <div className="mx-auto flex pb-2 text-center">
                <ReactPaginate
                    previousLabel=<i className="fas fa-chevron-left" />
                    nextLabel=<i className="fas fa-chevron-right" />
                    pageCount={totalPages}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    forcePage={page - 1}
                    containerClassName={'pagination'}
                    previousLinkClassName={'pagination__link'}
                    nextLinkClassName={'pagination__link'}
                    disabledClassName={'pagination__link--disabled'}
                    activeClassName={'pagination__link--active'}
                />
                <div className="w-56 text-xs font-bold">
                    <span>
                        Total : {totalItems} {title}
                    </span>
                </div>
            </div>
        </div>
    )
}
export default CardPaginationForcePaged
