import React from 'react'
import { formatEuro } from '../../../Invoice/Manage'

const InvoiceTotalPanel = ({ totals, rate }) => {
    return (
        <div className="col-span-2">
            <div className={'flex-col gap-4 '}>
                <div className={'grid grid-cols-1 gap-4 sm:grid-cols-3'}>
                    <div className="flex items-center justify-between rounded-lg border-2 border-blue-500 bg-blue-200 p-4 text-blue-700 shadow-md ">
                        <div className="font-semibold">
                            Part de l'organisme {rate}%
                        </div>
                        <div className={'flex items-center justify-between'}>
                            <p className="text-right font-semibold">
                                {formatEuro(totals.totalAmo)}
                            </p>
                        </div>
                    </div>
                    {totals.totalAmc > 0 ? (
                        <div className="flex items-center justify-between  rounded-lg border-2 border-yellow-500 bg-yellow-200  p-4 p-4 text-yellow-700 shadow-md">
                            <div className=" font-semibold">
                                Part de l'assuré {rate && 100 - rate}%
                            </div>
                            <p className="text-right font-semibold">
                                {formatEuro(totals.totalAmc)}
                            </p>
                        </div>
                    ) : null}
                    <div className=" flex justify-between rounded-lg border-2 border-secondary-color bg-secondary-color/20 p-4  text-lg font-semibold shadow-md ">
                        <p>Total général </p>
                        <p>{formatEuro(totals.totalAmount)}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceTotalPanel
