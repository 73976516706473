import { getHeaders } from '../api'
import { toast } from 'react-toastify'

const handleResponse = async (response) => {
    // check if it's json error or html

    let responseData
    if (
        response.headers?.get('content-type')?.includes('application/json') ||
        response.headers?.get('content-type')?.includes('application/ld+json')
    ) {
        responseData = await response.json()
        if (!response.ok) {
            const error = new Error(
                responseData['hydra:description'] ||
                    'Network response was not ok'
            )
            error.status = response.status
            error.statusText = response.statusText
            error.response = responseData
            throw error
        }
    } else if (
        response.headers?.get('content-type')?.includes('application/pdf')
    ) {
        responseData = await response.blob()
        if (!response.ok) {
            const error = new Error('Network response was not ok')
            error.status = response.status
            error.statusText = response.statusText
            error.response = responseData
            throw error
        }
    } else {
        responseData = await response.text()
        if (!response.ok) {
            // get the first line of the html response
            const firstLine = responseData.split('\n')[0]
            const error = new Error(responseData)
            error.status = response.status
            error.statusText = response.statusText
            error.response = firstLine
            throw error
        }
    }
    return responseData
}

export const apiClient = {
    get: async (path) => {
        const headers = await getHeaders(true)
        const response = await fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}${path}`,
            {
                method: 'GET',
                headers,
            }
        )
        return handleResponse(response)
    },
    post: async (path, data) => {
        const headers = await getHeaders(true)
        const response = await fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}${path}`,
            {
                method: 'POST',
                headers,
                body: JSON.stringify(data),
            }
        )
        return handleResponse(response)
    },
    put: async (path, data) => {
        const headers = await getHeaders(true)
        const response = await fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}${path}`,
            {
                method: 'PUT',
                headers,
                body: JSON.stringify(data),
            }
        )
        return handleResponse(response)
    },
    delete: async (path) => {
        const headers = await getHeaders(false)
        const response = await fetch(
            `${process.env.REACT_APP_API_ENTRYPOINT}${path}`,
            {
                method: 'DELETE',
                headers,
            }
        )
        return handleResponse(response)
    },
}

export const withErrorHandling = (apiCall) => {
    return async function (...args) {
        try {
            return await apiCall(...args)
        } catch (error) {
            throw handleApiClientError(error)
        }
    }
}

export const handleApiClientError = (error) => {
    if (error.response && error.response['hydra:description']) {
        error.message = error.response['hydra:description']
    }

    if (error.response?.needsRefresh) {
        return { refresh: true }
    }

    const enhancedError = {
        message: 'Une erreur est survenue, veuillez contacter le support',
        status: error.status || 500,
        originalError: error,
    }

    // Déterminer le message d'erreur le plus pertinent
    if (error.response && error.response.message) {
        enhancedError.message = error.response.message
    } else if (error.message) {
        enhancedError.message = error.message
    }

    // Loguer l'erreur pour le débogage
    console.error(
        `Error ${enhancedError.status}:`,
        enhancedError.message,
        error
    )

    if (enhancedError.message && enhancedError.message?.startsWith('<!--')) {
        enhancedError.message = error.response.split('<!--')[1].split('-->')[0]
    }
    // Afficher le message d'erreur à l'utilisateur
    toast.error(enhancedError.message)

    // Retourner l'erreur améliorée pour une utilisation ultérieure si nécessaire
    return enhancedError
}
