import React from 'react'
import { getTextAndBackgroundColor } from '../../../../services/RunStatus'

const Status = ({ row, isComputer }) => {
    return (
        <div
            className={`flex flex-row justify-center pt-0 ${
                isComputer ? 'text-base px-8' : 'text-xs'
            }`}
        >
            <span
                className={`flex px-2 inline-flex max-w-full justify-center
                 leading-5 font-semibold rounded-full ${
                     isComputer ? 'text-base w-24' : 'text-xs w-16'
                 } ${getTextAndBackgroundColor(row.status.id)}`}
            >
                {row.status.label}
            </span>
        </div>
    )
}

export default Status
