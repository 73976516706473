import React from 'react'
import Select from 'react-select'

const SelectDropDownList = (props) => {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            boxShadow: 'none',
            border: `2px solid ${
                props.error
                    ? '#ef4444'
                    : props.value === null
                    ? '#E2E8F0'
                    : '#80B0C2'
            }`,
            '&:hover': { borderColor: '#80B0C2' },
            borderRadius: '0.75rem',
            cursor: 'pointer',
            borderColor: '#80B0C2',
        }),
        option: (provided) => ({
            ...provided,
            cursor: 'pointer',
        }),
    }

    return (
        <div className="w-full">
            {props.icon !== '' ? (
                <div className="inline-block w-10">
                    <i className={`fas ${props.icon} fa-lg pt-2`} />
                </div>
            ) : (
                ''
            )}
            <div className="inline-block w-5/6 md:w-2/3">
                <label
                    className={`block text-lg font-bold uppercase tracking-wide text-gray-700 lg:text-xs ${
                        props.showLabel ? 'pb-1' : 'hidden'
                    }`}
                    htmlFor={props.id}
                >
                    {props.label}
                </label>
                <Select
                    maxMenuHeight={220}
                    className="basic-single"
                    classNamePrefix="select"
                    styles={customStyles}
                    isMulti={props.isMulti}
                    isSearchable={props.isSearchable}
                    value={props.value}
                    isClearable={props.isClearable}
                    isDisabled={props.isDisabled}
                    onChange={props.handleOptionChange}
                    noOptionsMessage={() => 'Tapez pour rechercher'}
                    name="selectDropdown"
                    menuPlacement={
                        props.menuPlacement ? props.menuPlacement : 'bottom'
                    }
                    id={props.id}
                    options={props.options}
                    placeholder={
                        props.label +
                        (!props.withoutSuspensionPoints ? '...' : '')
                    }
                    onInputChange={props.handleSearchInputPatientChange}
                />
            </div>
        </div>
    )
}

export default SelectDropDownList
