import React, { useState } from 'react'
import RunTabs from '../../Regulation/RunTabs'

const ManageDashboardContainer = ({
    date,
    setDate,
    setDateTo,
    setOpenRunManageModal,
    setOpenNewRunManageModal,
    setOpenSeriesManageModal,
    reRender,
    id,
    setSeriesId,
}) => {
    const [runId, setRunId] = useState(id)
    return (
        <div className=" w-11/12">
            <RunTabs
                date={date}
                setDate={setDate}
                setDateTo={setDateTo}
                fromModal={true}
                setOpenRunManageModal={setOpenRunManageModal}
                setOpenNewRunManageModal={setOpenNewRunManageModal}
                setOpenSeriesManageModal={setOpenSeriesManageModal}
                reRender={reRender}
                runId={runId}
                setRunId={setRunId}
                setSeriesId={setSeriesId}
            />
        </div>
    )
}

export default ManageDashboardContainer
