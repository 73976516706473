import React from 'react'
import { Plus, WarningCircle } from 'iconoir-react'
import PrimaryButton from '../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../Commons/Buttons/SecondaryButton'

const ActionSeriesModal = ({
    setModalOpen,
    label,
    warningLabel,
    action,
    labelAction,
    actionButton,
}) => {
    return (
        <div className="fixed left-0 bottom-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50">
            <div className="mx-2 rounded-lg bg-white sm:mx-0 sm:w-1/3">
                <div className="p-4">
                    <div className="flex w-full items-center pb-4">
                        <div className="text-lg font-bold text-gray-700">
                            <div>{labelAction}</div>
                        </div>
                        <svg
                            className="ml-auto h-6 w-6 cursor-pointer fill-current text-gray-700"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 18 18"
                            onClick={() => setModalOpen(false)}
                        >
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                        </svg>
                    </div>
                    {warningLabel && warningLabel !== '' && (
                        <div className="flex space-x-2 pb-4 text-red-500">
                            <WarningCircle /> <p>{warningLabel}</p>
                        </div>
                    )}
                    <div className="pb-6">{label}</div>

                    <div className="flex justify-between">
                        <SecondaryButton
                            action={() => setModalOpen(false)}
                            label="Annuler"
                            title="Annuler"
                            hiddenLabelOnMobile={false}
                        />
                        <PrimaryButton
                            action={action}
                            label={actionButton}
                            title={actionButton}
                            hiddenLabelOnMobile={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActionSeriesModal
