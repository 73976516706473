import React from 'react'
import ManageDashboardContainer from '../App/Schedule/Dashboard/ManageDashboardContainer'

const ManageRunModal = ({
    dateFrom,
    setDateFrom,
    setDateTo,
    setOpenRunManageModal,
    setOpenNewRunManageModal,
    setOpenSeriesManageModal,
    reRender,
    runId,
    setSeriesId,
}) => {
    return (
        <div className="fullscreen-dashboard-planning fixed bottom-0 left-0 flex h-full w-full items-center justify-center bg-gray-600 bg-opacity-50 px-2 md:px-6 lg:px-12">
            <div className="bg-color-light-gray min-h-action-modal max-height-90 flex min-w-full flex-col items-center overflow-y-scroll rounded-lg">
                <ManageDashboardContainer
                    key={1}
                    date={dateFrom}
                    setDate={setDateFrom}
                    setDateTo={setDateTo}
                    setOpenRunManageModal={setOpenRunManageModal}
                    setOpenNewRunManageModal={setOpenNewRunManageModal}
                    setOpenSeriesManageModal={setOpenSeriesManageModal}
                    reRender={reRender}
                    id={runId}
                    setSeriesId={setSeriesId}
                />
            </div>
        </div>
    )
}

export default ManageRunModal
