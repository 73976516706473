import dayjs from 'dayjs'

export const formatTimeLocal = (date) => {
    if (!date) return null
    if (date instanceof Date) {
        return dayjs(date).format('HH[h]mm')
    }
    const [, time] = date.split('T')
    const [hours, minutes] = time.split(':')
    return `${hours}h${minutes}`
}

export const formatDateFromApiToDateObject = (
    date,
    addNewDateIfNull = false
) => {
    if (!date) {
        if (addNewDateIfNull) {
            return new Date()
        }
        return null
    }

    // Utiliser une regex plus permissive pour extraire la date et l'heure
    const match = date.match(
        /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(?::\d{2}(?:\.\d+)?)?)/
    )
    if (!match) {
        console.error('Format de date invalide:', date)
        return null
    }

    const dateWithoutTimezone = match[1]
    const parsedDate = dayjs(dateWithoutTimezone)

    if (!parsedDate.isValid()) {
        console.error('Impossible de parser la date:', date)
        return null
    }

    return parsedDate.toDate()
}

export const formatDateFromApiToTimeObject = (date) => {
    const match = date.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/)
    if (!match) return null

    const [, dateString, timeString] = match

    const dateTimeString = `${dateString}T${timeString}`

    return dayjs(dateTimeString, 'YYYY-MM-DDTHH:mm').toDate()
}

export const removeTimeZoneToDate = (date, addNewDateIfNull = false) => {
    // check if date have a z at the end
    if (date && date[date.length - 1] === 'Z') {
        date = date.slice(0, -1)
        //     add 1 hour to the date
        date = new Date(date)
        date.setHours(date.getHours() + 2)
        // make it match the format of the api  date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/)
        date = formatDateToApi(date) + ':00+02:00'
    }

    if (!date) {
        return addNewDateIfNull ? new Date() : null
    }

    const match = date.match(/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/)
    if (!match) {
        console.error('Format de date invalide:', date)
        return null
    }

    return match[1]
}

export const formatDateLocal = (date) => {
    if (!date) return null
    if (date instanceof Date) {
        return dayjs(date).format('DD/MM/YYYY')
    }
    const match = date.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/)
    const [, dateString, timeString] = match
    const dateTimeString = `${dateString}T${timeString}`
    return dayjs(dateTimeString, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY')
}

export const formatDateWithFormat = (date, format) => {
    if (!date) return null
    if (date instanceof Date) {
        return dayjs(date).format(format)
    }
    const match = date.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2})/)
    const [, dateString, timeString] = match
    const dateTimeString = `${dateString}T${timeString}`
    return dayjs(dateTimeString, 'YYYY-MM-DDTHH:mm').format(format)
}

export const formatDateToApi = (date, withSeconds = false) => {
    if (!date) return null
    return dayjs(date).format(
        withSeconds ? 'YYYY-MM-DDTHH:mm:ss' : 'YYYY-MM-DDTHH:mm'
    )
}

export const formatDateToApiWithTimezone = (date) => {
    if (!date) return null
    return dayjs(date).toISOString()
}

export const formatTimeInput = (time) => {
    return dayjs(time).format('HH:mm')
}

export const handleTimeToDate = (value) => {
    let hours,
        minutes,
        seconds = 0

    // Vérifier si l'entrée est au format HH:mm ou HH:mm:ss
    const timeMatch = value.match(/^(\d{2}):(\d{2})(:(\d{2}))?$/)
    if (timeMatch) {
        hours = parseInt(timeMatch[1], 10)
        minutes = parseInt(timeMatch[2], 10)
        seconds = timeMatch[4] ? parseInt(timeMatch[4], 10) : 0
    } else {
        console.error('Format de temps invalide:', value)
        return
    }

    // Vérifier si les valeurs sont dans des plages valides
    if (
        hours < 0 ||
        hours > 23 ||
        minutes < 0 ||
        minutes > 59 ||
        seconds < 0 ||
        seconds > 59
    ) {
        console.error('Valeurs de temps invalides:', value)
        return
    }

    // Créer une nouvelle date basée sur 1970-01-01 avec le temps fourni
    const baseDate = new Date(1970, 0, 1, hours, minutes, seconds)

    // Formater la date en ISO string et extraire seulement la partie temps

    return `${baseDate.getFullYear()}-${String(
        baseDate.getMonth() + 1
    ).padStart(2, '0')}-${String(baseDate.getDate()).padStart(2, '0')}T${String(
        baseDate.getHours()
    ).padStart(2, '0')}:${String(baseDate.getMinutes()).padStart(
        2,
        '0'
    )}:${String(baseDate.getSeconds()).padStart(2, '0')}`
}

export const handleDateTimeToDate = (date) => {
    if (!date) return null
    const match = date.match(/^(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})/)
    const [, dateString, timeString] = match
    return handleTimeToDate(timeString)
}

export const mergeDateAndTime = (date, time) => {
    let hours,
        minutes,
        seconds = 0

    // Vérifier si l'entrée est au format HH:mm ou HH:mm:ss
    const timeMatch = time.match(/^(\d{2}):(\d{2})(:(\d{2}))?$/)
    if (timeMatch) {
        hours = parseInt(timeMatch[1], 10)
        minutes = parseInt(timeMatch[2], 10)
        seconds = timeMatch[4] ? parseInt(timeMatch[4], 10) : 0
    } else {
        console.error('Format de temps invalide:', time)
        return null
    }

    // Vérifier si les valeurs sont dans des plages valides
    if (
        hours < 0 ||
        hours > 23 ||
        minutes < 0 ||
        minutes > 59 ||
        seconds < 0 ||
        seconds > 59
    ) {
        console.error('Valeurs de temps invalides:', time)
        return null
    }

    const baseDate = new Date(date)
    console.log('baseDate avant modification', baseDate)

    // Modifier l'heure de la date de base
    baseDate.setHours(hours, minutes, seconds)

    console.log('baseDate après modification', baseDate)

    // Formater la date en ISO string
    return `${baseDate.getFullYear()}-${String(
        baseDate.getMonth() + 1
    ).padStart(2, '0')}-${String(baseDate.getDate()).padStart(2, '0')}T${String(
        baseDate.getHours()
    ).padStart(2, '0')}:${String(baseDate.getMinutes()).padStart(
        2,
        '0'
    )}:${String(baseDate.getSeconds()).padStart(2, '0')}`
}

export const toLocalTimestamp = (dateString, remove1Hour = true) => {
    if (!dateString) return null
    // Créer une date à partir de la chaîne fournie
    let date = null
    if (dateString instanceof Date) {
        date = dateString
    } else {
        date = new Date(dateString)
    }
    // Extraire les composants de la date
    const year = date.getFullYear()
    const month = date.getMonth() // getMonth() retourne déjà 0-11
    const day = date.getDate()
    const hour = date.getHours()
    const minute = date.getMinutes()
    const second = date.getSeconds()
    // Créer un timestamp en ignorant le fuseau
    let utcMinusOneTimestamp = Date.UTC(year, month, day, hour, minute, second) // 3600000 ms = 1 heure
    if (remove1Hour) {
        utcMinusOneTimestamp = utcMinusOneTimestamp - 3600000
    }
    return utcMinusOneTimestamp / 1000 // Convert
}

export const mergeDateAndTimeAndTransformToTimeStamp = (date, time) => {
    const mergedDate = mergeDateAndTime(date, formatTimeInput(time))
    return toLocalTimestamp(mergedDate, false)
}

export const formatDateYYYYMMDD = (date) => {
    return dayjs(date).format('YYYY-MM-DD')
}

export const getTimeDifferenceInMinutes = (laterDate, earlierDate) => {
    const diffInMilliseconds = laterDate.getTime() - earlierDate.getTime()
    return Math.round(diffInMilliseconds / (1000 * 60))
}

export const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000)
}
