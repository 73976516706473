import React, { useEffect, useReducer, useRef } from 'react'
import ClassicInput from '../../Commons/Inputs/ClassicInput'
import ClassicSwitch from '../../Commons/Inputs/ClassicSwitch'
import API from '../../../services/api'
import { useNavigate, useParams } from 'react-router-dom'
import ManageTitle from '../../Commons/ManageTitle'
import SendFormButtons from '../../Commons/Buttons/SendFormButtons'
import SelectDropDownList from '../../Commons/DropDownLists/SelectDropDownList'
import Loader from '../../Commons/Loaders/Loader'
import { TwitterPicker } from 'react-color'
import classNames from 'classnames'
import VehicleColors from '../../../services/VehicleColors'
import {
    ACTIONS,
    VehicleReducer,
    getInitialState,
} from '../../../services/Reducers/VehicleReducer'
import Error from '../../Commons/Error'
import { toast } from 'react-toastify'
import {
    DATE_ACTIONS,
    DateReducer,
    getDateInitialState,
} from '../../../services/Reducers/DateReducer'
import FormDateInput from '../../Commons/Inputs/FormDateInput'
import {
    getDateReducerFormatForApi,
    getNormalDateFormatForApi,
} from '../../../services/DateService'
import UppercaseLabel from '../../Commons/UppercaseLabel'
import CalendarDatePicker from '../../Commons/CalendarDatePickers/CalendarDatePicker'
import { ArrowRight } from 'iconoir-react'
export const useOnClickColorsOutside = (ref, outsideHandler, clickHandler) => {
    useEffect(() => {
        const listenerColors = (event) => {
            // Do nothing if clicking ref's element or descendent elements
            if (!ref.current || ref.current.contains(event.target)) {
                // clickHandler(event)
            } else {
                outsideHandler(event)
            }
        }

        document.addEventListener('mousedown', listenerColors)
        document.addEventListener('touchstart', listenerColors)

        return () => {
            document.removeEventListener('mousedown', listenerColors)
            document.removeEventListener('touchstart', listenerColors)
        }
    }, [ref, outsideHandler, clickHandler])
}

const formatOptions = (types) => {
    const options = []
    types.forEach((type) => {
        const data = {
            value: type['@id'],
            label: type.label,
        }

        options.push(data)
    })
    return options
}

const VehicleManage = () => {
    const [taximeterDate, taximeterDateDispatch] = useReducer(
        DateReducer,
        [],
        getDateInitialState
    )
    const [technicalDate, technicalDateDispatch] = useReducer(
        DateReducer,
        [],
        getDateInitialState
    )
    const [vehicle, dispatch] = useReducer(VehicleReducer, [], getInitialState)

    const colorsPopupRef = useRef()

    useOnClickColorsOutside(
        colorsPopupRef,
        () => handleOpenColorChoices(false),
        () => handleOpenColorChoices(true)
    )

    let history = useNavigate()
    let { id } = useParams()
    id = parseInt(id)

    const getParameters = () => {
        API.Parameter.get(localStorage.getItem('society')).then((response) => {
            response.json().then((data) => {
                handleTypes(formatOptions(data.defaultVehiclesTypes))
                getAllVehiclesColors()
            })
        })
    }

    const pickRandomColor = (array) => {
        return array[Math.floor(Math.random() * array.length)]
    }

    const getAllVehiclesColors = () => {
        API.Vehicles.list(null, null, false).then((response) => {
            response.json().then((data) => {
                const alreadyColorsUsed = [
                    ...new Set(
                        data['hydra:member'].map((vehicle) => vehicle.color)
                    ),
                ]
                let difference = VehicleColors.filter(
                    (x) => !alreadyColorsUsed.includes(x)
                )
                handleAvailableColors(difference)
                if (id === 0) {
                    handleColor(pickRandomColor(difference))
                }
            })
        })
    }

    const handleOpenSelectStartDatePicker = (value) => {
        dispatch({
            type: ACTIONS.SET_OPEN_SELECT_START_DATE_PICKER,
            payload: value,
        })
    }
    const handleOpenSelectEndDatePicker = (value) => {
        dispatch({
            type: ACTIONS.SET_OPEN_SELECT_END_DATE_PICKER,
            payload: value,
        })
    }

    const checkErrors = () => {
        let msg = []
        if (!vehicle.licensePlate) {
            msg.push("Veuillez renseigner l'immatriculation du véhicule.")
        }
        if (!vehicle.type) {
            msg.push('Veuillez renseigner le type du véhicule.')
        }
        if (!vehicle.label) {
            msg.push('Veuillez renseigner le label du véhicule.')
        }
        if (vehicle.conventionProtection) {
            if (!vehicle.startConventionDate) {
                msg.push(
                    'Veuillez renseigner une date de début de convention valide'
                )
            }
            if (!vehicle.endConventionDate) {
                msg.push(
                    'Veuillez renseigner une date de fin de convention valide'
                )
            }
        }

        if (msg.length > 0) {
            msg.forEach((e) => {
                toast.error(e)
            })
            return true
        }
    }

    const handleForm = () => {
        let data = {
            ...vehicle,
            technicalControl: getDateReducerFormatForApi(technicalDate),
            taximeterControl: getDateReducerFormatForApi(taximeterDate),
            type: vehicle.type?.value,
        }

        if (vehicle.conventionProtection) {
            data = {
                ...data,
                conventionDateFrom: getNormalDateFormatForApi(
                    vehicle.startConventionDate
                ),
                conventionDateTo: getNormalDateFormatForApi(
                    vehicle.endConventionDate
                ),
            }
        } else {
            data = {
                ...data,
                conventionDateFrom: null,
                conventionDateTo: null,
            }
        }
        if (!checkErrors()) {
            if (id === 0) {
                API.Vehicles.addVehicle(data)
                    .then((response) => {
                        toast.success('Véhicule ajouté.')
                        historyPush()
                        return Promise.reject(new Error(response.statusText))
                    })
                    .catch((error) => {
                        if (error.json) {
                            toast.error("Erreur lors de l'ajout du véhicule.")
                        }
                    })
            } else {
                API.Vehicles.put(id, data)
                    .then((response) => {
                        toast.success('Véhicule modifié.')

                        historyPush()
                        return Promise.reject(new Error(response.statusText))
                    })
                    .catch((error) => {
                        if (error.json) {
                            error.json().then((data) => {
                                toast.error(
                                    'Veuillez renseigner les champs obligatoires.'
                                )
                            })
                        }
                    })
            }
        } else {
        }
    }

    const historyPush = () => {
        history('/ressources/vehicules')
    }

    const handleChangeComplete = (color) => {
        handleColor(color.hex)
    }

    const getVehicle = () => {
        API.Vehicles.get(id).then((response) => {
            response.json().then((data) => {
                dispatch({ type: ACTIONS.SET_VEHICLE, payload: data })
                technicalDateDispatch({
                    type: DATE_ACTIONS.SET_DATE,
                    payload: data.technicalControl,
                })
                taximeterDateDispatch({
                    type: DATE_ACTIONS.SET_DATE,
                    payload: data.taximeterControl,
                })
            })
        })
    }

    useEffect(() => {
        getParameters()
        if (id !== 0) {
            getVehicle()
        } else {
            handleLoading(false)
        }
    }, [])

    const handleLicensePlate = (value) => {
        dispatch({ type: ACTIONS.SET_LICENSE_PLATE, payload: value })
    }

    const handleActive = () => {
        dispatch({ type: ACTIONS.SET_ACTIVE, payload: !vehicle.active })
    }

    const handleTypeChange = (event) => {
        dispatch({ type: ACTIONS.SET_TYPE, payload: event })
    }

    const handleTypes = (event) => {
        dispatch({ type: ACTIONS.SET_TYPES, payload: event })
    }

    const handleLabel = (value) => {
        dispatch({ type: ACTIONS.SET_LABEL, payload: value })
    }

    const handleAvailableColors = (value) => {
        dispatch({ type: ACTIONS.SET_AVAILABLE_COLORS, payload: value })
    }

    const handleColor = (value) => {
        dispatch({ type: ACTIONS.SET_COLOR, payload: value })
    }

    const handleOpenColorChoices = (value) => {
        dispatch({ type: ACTIONS.SET_OPEN_COLOR_CHOICES, payload: value })
    }

    const handleLoading = (value) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: value })
    }

    return (
        <div
            className={classNames(
                'min-height-90-x container mx-auto flex flex-col pb-20 pt-5 text-lg lg:pb-0 lg:text-sm'
            )}
        >
            {vehicle.loading ? (
                <Loader />
            ) : (
                <div
                    className={classNames(
                        'flex w-full flex-col rounded-xl bg-white px-5 py-10 shadow-md'
                    )}
                >
                    <ManageTitle label={vehicle.manageTitle} />
                    <div className="px-5 lg:px-20">
                        <div className="sm:grid sm:grid-cols-2 sm:gap-4">
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Immatriculation *"
                                    value={vehicle.licensePlate}
                                    setValue={(e) =>
                                        handleLicensePlate(e.target.value)
                                    }
                                    required={true}
                                    id="licensePlate"
                                    placeholder="AV-153-XC"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Label"
                                    value={vehicle.label}
                                    required={true}
                                    setValue={(e) =>
                                        handleLabel(e.target.value)
                                    }
                                    id="label"
                                    placeholder="Chevrolet rouge"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <SelectDropDownList
                                    icon=""
                                    label="Type *"
                                    options={vehicle.types}
                                    value={vehicle.type}
                                    id={id}
                                    handleOptionChange={handleTypeChange}
                                    isDisabled={false}
                                    showLabel={true}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicSwitch
                                    label="Actif"
                                    id="active"
                                    value={vehicle.active}
                                    setValue={handleActive}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <ClassicInput
                                    label="Numéro d'ADS"
                                    value={vehicle.numAds}
                                    setValue={(e) =>
                                        dispatch({
                                            type: ACTIONS.SET_NUM_ADS,
                                            payload: e.target.value,
                                        })
                                    }
                                    placeholder="Bordeaux n°12"
                                    showLabel={true}
                                />
                            </div>
                            <div className="col-span-1 py-2">
                                <label className="block text-xs font-bold uppercase text-gray-700">
                                    Couleur du véhicule
                                </label>
                                <div
                                    className="h-10 w-10 cursor-pointer rounded-xl border"
                                    style={{ backgroundColor: vehicle.color }}
                                    onClick={() =>
                                        handleOpenColorChoices(
                                            !vehicle.openColorChoices
                                        )
                                    }
                                />
                                {vehicle.openColorChoices ? (
                                    <div ref={colorsPopupRef}>
                                        <TwitterPicker
                                            colors={vehicle.availableColors}
                                            onChangeComplete={
                                                handleChangeComplete
                                            }
                                        />
                                    </div>
                                ) : null}
                            </div>
                            <div className="col-span-1 py-2">
                                <label className="block pb-1 text-xs font-bold uppercase text-gray-700">
                                    Date du prochain contrôle technique
                                </label>
                                <FormDateInput
                                    isBirthDate={false}
                                    dispatch={technicalDateDispatch}
                                    date={technicalDate}
                                />
                            </div>
                            {vehicle.type?.label === 'Taxi' && (
                                <>
                                    <div className="col-span-1 py-2">
                                        <div className="col-span-1 py-2">
                                            <label className="block pb-1 text-xs font-bold uppercase text-gray-700">
                                                Date du prochain contrôle
                                                taximètre
                                            </label>
                                            <FormDateInput
                                                isBirthDate={false}
                                                dispatch={taximeterDateDispatch}
                                                date={taximeterDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-1 py-2">
                                        <div className="flex justify-between pb-4">
                                            <div className={'flex flex-col'}>
                                                <p className="text-lg font-semibold">
                                                    Activer la protection de
                                                    conventionnement
                                                </p>
                                                <p className="">
                                                    Éviter la facturation d'un
                                                    transport sur un véhicule
                                                    non conventionné
                                                </p>
                                            </div>
                                            <ClassicSwitch
                                                setValue={() =>
                                                    dispatch({
                                                        type: ACTIONS.SET_CONVENTION_PROTECTION,
                                                        payload:
                                                            !vehicle.conventionProtection,
                                                    })
                                                }
                                                value={
                                                    vehicle.conventionProtection
                                                }
                                            />
                                        </div>
                                    </div>
                                    {vehicle.conventionProtection ? (
                                        <div className="col-span-2 py-2">
                                            <div className="flex flex-col space-y-4">
                                                <UppercaseLabel label="Période de conventionnement" />
                                                <div className="flex flex-row items-center space-x-2">
                                                    <CalendarDatePicker
                                                        openSelectDatePicker={
                                                            vehicle.openSelectStartDatePicker
                                                        }
                                                        setOpenSelectDatePicker={
                                                            handleOpenSelectStartDatePicker
                                                        }
                                                        date={
                                                            vehicle.startConventionDate
                                                        }
                                                        setDate={(date) =>
                                                            dispatch({
                                                                type: ACTIONS.SET_START_DATE,
                                                                payload: date,
                                                            })
                                                        }
                                                        icon={false}
                                                        fontSize="text-lg lg:text-sm"
                                                        customDisplay="D MMM YYYY"
                                                    />
                                                    <ArrowRight />

                                                    <CalendarDatePicker
                                                        openSelectDatePicker={
                                                            vehicle.openSelectEndDatePicker
                                                        }
                                                        setOpenSelectDatePicker={
                                                            handleOpenSelectEndDatePicker
                                                        }
                                                        date={
                                                            vehicle.endConventionDate
                                                        }
                                                        fontSize="text-lg lg:text-sm"
                                                        setDate={(date) =>
                                                            dispatch({
                                                                type: ACTIONS.SET_END_DATE,
                                                                payload: date,
                                                            })
                                                        }
                                                        icon={false}
                                                        customDisplay="D MMM YYYY"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
            <SendFormButtons
                historyPush={historyPush}
                handleForm={handleForm}
            />
        </div>
    )
}

export default VehicleManage
