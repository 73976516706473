import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import { API_ENTRYPOINT } from '../config/entrypoint'
import { getHeaders } from '../services/api'
import { BuildVersion } from '../BuildVersion'
import { DeviceManager } from '../DeviceManager'

const APPLICATION_SERVER_KEY =
    'BIeM5T03Nnw07ahs09QjzH07sPcwZmYZdzC4C6dGTNvhPt6Bzh4SAENSvgKcf6zV48aqHjMibA5K2Axg7xRSa9A'

const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/')
    const rawData = window.atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
}

const PushNotif = () => {
    const [state, setState] = useState('computing')
    const [serviceWorkerError, setServiceWorkerError] = useState(false)
    const deviceId = localStorage.getItem('deviceId')

    const checkBrowserCompatibility = useCallback(() => {
        if (!('serviceWorker' in navigator)) {
            console.error('Service workers are not supported by this browser')
            return false
        }
        if (!('PushManager' in window)) {
            console.error(
                'Push notifications are not supported by this browser'
            )
            return false
        }
        if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
            console.error('Notifications are not supported by this browser')
            return false
        }
        if (Notification.permission === 'denied') {
            console.error('Notifications are denied by the user')
            return false
        }
        return true
    }, [])

    const registerServiceWorker = useCallback(async () => {
        try {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
            await navigator.serviceWorker.register(swUrl)
            console.log('[SW] Service worker has been registered')
            return true
        } catch (error) {
            console.error('[SW] Service worker registration failed', error)
            setServiceWorkerError(true)
            return false
        }
    }, [])

    const pushSendSubscriptionToServer = useCallback(
        async (subscription, method) => {
            const key = subscription.getKey('p256dh')
            const token = subscription.getKey('auth')
            const contentEncoding = (PushManager.supportedContentEncodings || [
                'aesgcm',
            ])[0]

            const response = await fetch(`${API_ENTRYPOINT}/subscribe`, {
                method,
                headers: await getHeaders(),
                body: JSON.stringify({
                    ...DeviceManager.getDeviceInfo(),
                    deviceId: deviceId,
                    endpoint: subscription.endpoint,
                    publicKey: key
                        ? btoa(
                              String.fromCharCode.apply(
                                  null,
                                  new Uint8Array(key)
                              )
                          )
                        : null,
                    authToken: token
                        ? btoa(
                              String.fromCharCode.apply(
                                  null,
                                  new Uint8Array(token)
                              )
                          )
                        : null,
                    contentEncoding,
                }),
            })

            if (!response.ok) {
                throw new Error('Failed to send subscription to server')
            }

            return subscription
        },
        []
    )

    const pushSubscribe = useCallback(async () => {
        setState('computing')
        try {
            await Notification.requestPermission()
            const registration = await navigator.serviceWorker.ready
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(
                    APPLICATION_SERVER_KEY
                ),
            })
            await pushSendSubscriptionToServer(subscription, 'POST')
            setState('enabled')
        } catch (error) {
            console.error('Failed to subscribe to push notifications', error)
            setState(
                Notification.permission === 'denied'
                    ? 'incompatible'
                    : 'disabled'
            )
        }
    }, [pushSendSubscriptionToServer])

    const pushUnsubscribe = useCallback(async () => {
        setState('computing')
        try {
            const registration = await navigator.serviceWorker.ready
            const subscription =
                await registration.pushManager.getSubscription()
            if (subscription) {
                await pushSendSubscriptionToServer(subscription, 'DELETE')
                await subscription.unsubscribe()
            }
            setState('disabled')
        } catch (error) {
            console.error('Error when unsubscribing the user', error)
            setState('disabled')
        }
    }, [pushSendSubscriptionToServer])

    const sendTestNotification = useCallback(async () => {
        try {
            const registration = await navigator.serviceWorker.ready
            const subscription =
                await registration.pushManager.getSubscription()
            if (!subscription) {
                toast.error("Veuillez d'abord activer les notifications")
                return
            }
            const contentEncoding = (PushManager.supportedContentEncodings || [
                'aesgcm',
            ])[0]
            const response = await fetch(`${API_ENTRYPOINT}/send-notif`, {
                method: 'POST',
                headers: await getHeaders(),
                body: JSON.stringify({
                    ...subscription.toJSON(),
                    contentEncoding,
                }),
            })
            if (!response.ok) {
                throw new Error('Failed to send test notification')
            }
            toast.success('Notification test envoyée')
        } catch (error) {
            console.error('Failed to send test notification', error)
            toast.error("Échec de l'envoi de la notification test")
        }
    }, [])

    useEffect(() => {
        const init = async () => {
            if (!checkBrowserCompatibility()) {
                setState('incompatible')
                return
            }
            if (await registerServiceWorker()) {
                const registration = await navigator.serviceWorker.ready
                const subscription =
                    await registration.pushManager.getSubscription()
                setState(subscription ? 'enabled' : 'disabled')
            } else {
                setState('incompatible')
            }
        }
        init()
    }, [checkBrowserCompatibility, registerServiceWorker])

    if (serviceWorkerError) {
        toast.error(
            "Impossible de charger le service worker. Veuillez vérifier que vous n'avez pas bloqué les notifications push."
        )
    }

    return (
        <div className="w-full max-w-md space-y-4">
            <button
                onClick={state === 'enabled' ? pushUnsubscribe : pushSubscribe}
                className={`${
                    state === 'enabled'
                        ? ' bg-red-500 hover:bg-red-700 '
                        : ' bg-primary-color  hover:bg-dark-green '
                } w-full cursor-pointer rounded px-4 py-2 font-bold text-white`}
                disabled={state === 'chargement' || state === 'incompatible'}
            >
                {state === 'enabled' ? 'Désactiver' : 'Activer'} les
                notifications push
            </button>

            <div className="flex items-center justify-between">
                <span className="font-bold">État :</span>
                <span
                    className={`rounded-full px-2 py-1 font-semibold ${
                        state === 'enabled'
                            ? 'bg-green-100 text-green-800'
                            : state === 'disabled'
                            ? 'bg-gray-100 text-gray-800'
                            : state === 'computing'
                            ? 'bg-blue-100 text-blue-800'
                            : 'bg-red-100 text-red-800'
                    }`}
                >
                    {state === 'enabled'
                        ? 'Activé'
                        : state === 'disabled'
                        ? 'Désactivé'
                        : state === 'computing'
                        ? 'Chargement...'
                        : 'Incompatible'}
                </span>
            </div>

            <button
                onClick={sendTestNotification}
                className="w-full cursor-pointer rounded bg-secondary-color px-4 py-2 font-bold text-white hover:bg-primary-color"
                disabled={state !== 'enabled'}
            >
                Envoyer une notification test
            </button>
        </div>
    )
}

export default PushNotif
