import * as dayjs from 'dayjs'
import { ChatLines, PageEdit } from 'iconoir-react'

export const CommentNotification = ({ commentNotification }) => {
    return (
        <div className="flex w-full items-center ">
            {commentNotification.type === 'edit_comment' ? (
                <PageEdit className={'min-w-2-5 h-6 w-6 text-yellow-500'} />
            ) : (
                <ChatLines className={'min-w-2-5 h-6 w-6 text-blue-500'} />
            )}
            <div className="flex flex-col pl-2 pr-1 ">
                <div className="text-notif wrapper flex text-left text-gray-700 ">
                    <a className={'font-bold capitalize'}>
                        {commentNotification.notifier.fullName}
                    </a>
                    <a>
                        {commentNotification.type === 'edit_comment'
                            ? ' a modifié un commentaire sur le transport de '
                            : ' a ajouté un comme ntaire sur le transport de '}
                    </a>
                    <a className="pr-1 capitalize underline">
                        {commentNotification.run.patient.fullName}
                    </a>
                    <p>« {commentNotification.run.userComments} »</p>
                </div>
                <p className="text-left text-xs text-gray-500">
                    {dayjs(commentNotification.createdAt).fromNow()}
                </p>
            </div>
            {!commentNotification.seen && (
                <div className="ml-auto">
                    <div className={`h-2 w-2 rounded-full bg-blue-500 `} />
                </div>
            )}
        </div>
    )
}
