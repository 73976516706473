import SelectDropDownListV2 from '../../../Commons/DropDownLists/SelectDropDownListV2'
import { RegimeList } from '../../../../services/RegimeList'
import ClassicInputV2 from '../../../Commons/Inputs/ClassicInputV2'
import { OpenInWindow } from 'iconoir-react'
import { PAYING_CENTER_FORM_ACTIONS } from '../Reducers/PayingCenterFormReducer'
import React, { useEffect, useReducer, useRef } from 'react'
import {
    AddressAutoCompleteReducer,
    getAddressInitialState,
} from '../../../../services/Reducers/AddressAutoCompleteReducer'
import AutoComplete from '../../AutoComplete'
import { formatItemForSelect } from '../../../../tools/Utility'

const PayingCenterForm = ({ payingCenter, dispatch, addressItem }) => {
    const addressRef = useRef(null)
    console.log(addressItem)
    const [address, addressDispatch] = useReducer(
        AddressAutoCompleteReducer,
        getAddressInitialState({ addressItem })
    )

    useEffect(() => {
        dispatch({
            type: PAYING_CENTER_FORM_ACTIONS.SET_ADDRESS,
            payload: address,
        })
    }, [address])

    return (
        <div className="flex w-full flex-col rounded-xl bg-white py-10 shadow-md">
            <div className="flex flex-col px-5 pb-10 lg:px-20">
                <a
                    className="flex space-x-2 pb-5 underline"
                    target="_blank"
                    href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRtxlibVdzLBRVqO0yVxC7WkpD2GVXnLpqA6lN37GpaV0WcPQSJjPpqC5oQjG7ui4iTq7KYaNg9zeNF/pubhtml"
                >
                    <p>Voir la liste des caisses</p>
                    <OpenInWindow />
                </a>
                <div className="grid grid-cols-2 gap-4">
                    <div className={'col-span-2 w-full'}>
                        <ClassicInputV2
                            label={'label'}
                            value={payingCenter.label}
                            setValue={(e) =>
                                dispatch({
                                    type: PAYING_CENTER_FORM_ACTIONS.SET_LABEL,
                                    payload: e.target.value,
                                })
                            }
                            isDisabled={true}
                        />
                    </div>
                    <div className={'col-span-2 w-full'}>
                        <SelectDropDownListV2
                            label={'Grand régime'}
                            options={RegimeList}
                            value={formatItemForSelect(
                                payingCenter.codeRegime,
                                RegimeList
                            )}
                            handleOptionChange={(e) =>
                                dispatch({
                                    type: PAYING_CENTER_FORM_ACTIONS.SET_CODE_REGIME,
                                    payload: e,
                                })
                            }
                            isDisabled={true}
                        />
                    </div>
                    <ClassicInputV2
                        label={'Organisme destinataire'}
                        value={payingCenter.organismeDestinataire}
                        setValue={(e) =>
                            dispatch({
                                type: PAYING_CENTER_FORM_ACTIONS.SET_ORGANISME_DESTINATAIRE,
                                payload: e.target.value,
                            })
                        }
                        isDisabled={true}
                    />
                    <ClassicInputV2
                        label={'code centre gestionnaire'}
                        value={payingCenter.codeCentreGestionnaire}
                        setValue={(e) =>
                            dispatch({
                                type: PAYING_CENTER_FORM_ACTIONS.SET_CODE_CENTRE_GESTIONNAIRE,
                                payload: e.target.value,
                            })
                        }
                        isDisabled={true}
                    />
                    <div className={'col-span-2 w-full'}>
                        <ClassicInputV2
                            label={'code centre informatique'}
                            value={payingCenter.codeCentreInformatique}
                            setValue={(e) =>
                                dispatch({
                                    type: PAYING_CENTER_FORM_ACTIONS.SET_CODE_CENTRE_INFORMATIQUE,
                                    payload: e.target.value,
                                })
                            }
                            isDisabled={true}
                        />
                    </div>
                    <div className={'col-span-2 w-full pb-5'}>
                        <AutoComplete
                            dispatch={addressDispatch}
                            address={address}
                            label="Adresse"
                            ref={addressRef}
                        />
                    </div>
                    <div>
                        {payingCenter.label} ({payingCenter.codeRegime?.value}-
                        {payingCenter.codeCaisse}-
                        {payingCenter.codeCentreGestionnaire})
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PayingCenterForm
