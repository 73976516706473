import SharedRunSection from './SharedRunSection'
import ForfaitSection from './ForfaitSection'
import TarifSection from './TarifSection'
import SupplementSection from './SupplementSection'
import React, { useEffect, useReducer } from 'react'
import {
    getRunInvoiceFormDetailsInitialState,
    RunInvoiceFormDetailsReducer,
} from './RunInvoiceFormDetailsReducer'
import MinimumPerceptionSection from './MinimumPerceptionSection'

const RunInvoiceDetails = ({
    runInvoiceDetails,
    dispatch,
    openedCardId,
    runId,
    ACTION,
}) => {
    const [runInvoiceFormDetails, runInvoiceDispatch] = useReducer(
        RunInvoiceFormDetailsReducer,
        { runInvoiceDetails, runId },
        getRunInvoiceFormDetailsInitialState
    )

    useEffect(() => {
        dispatch({
            type: ACTION,
            payload: runInvoiceFormDetails,
        })
    }, [runInvoiceFormDetails])

    return (
        openedCardId === runId && (
            <div className={'relative w-full rounded-lg border py-4'}>
                <div className="flex flex-col space-y-4 p-1 lg:px-10">
                    <SharedRunSection
                        dispatch={runInvoiceDispatch}
                        invoiceCpamForm={runInvoiceFormDetails}
                    />
                    <ForfaitSection
                        dispatch={runInvoiceDispatch}
                        invoiceCpamForm={runInvoiceFormDetails}
                    />
                    <MinimumPerceptionSection
                        dispatch={runInvoiceDispatch}
                        invoiceCpamForm={runInvoiceFormDetails}
                    />
                    <TarifSection
                        dispatch={runInvoiceDispatch}
                        invoiceCpamForm={runInvoiceFormDetails}
                    />
                    <SupplementSection
                        dispatch={runInvoiceDispatch}
                        invoiceCpamForm={runInvoiceFormDetails}
                    />
                </div>
            </div>
        )
    )
}

export default RunInvoiceDetails
