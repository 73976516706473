import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import UserManage from './UserManage'
import User from './User'
import ClassicFilter from '../../Commons/ClassicFilter'

const UserContainer = (props) => {
    const [search, setSearch] = useState('')

    return (
        <Routes>
            <Route path=":id" element={<UserManage />} />
            <Route
                path="/"
                element={
                    <div className="pb-10">
                        <ClassicFilter
                            {...props}
                            search={search}
                            setSearch={setSearch}
                            icon={'fa-user'}
                            title={'Nouvel utilisateur'}
                            link={'/ressources/utilisateurs'}
                        />
                        <User
                            {...props}
                            search={search}
                            setSearch={setSearch}
                        />
                    </div>
                }
            />
        </Routes>
    )
}

export default UserContainer
