import React, { useState } from 'react'
import FormInput from '../../../Commons/Inputs/FormInput'
import { useNavigate } from 'react-router-dom'
import API from '../../../../services/api'
import FormTextArea from '../../../Commons/FormTextArea'
// import AutoComplete from '../../AutoComplete'
import { toast } from 'react-toastify'
import PrimaryButton from '../../../Commons/Buttons/PrimaryButton'
import SecondaryButton from '../../../Commons/Buttons/SecondaryButton'
import AutoComplete from '../../AutoComplete'

export const SocietyContainer = ({
    society,
    action,
    dispatch,
    address,
    addressDispatch,
}) => {
    const history = useNavigate()
    const [loading, setLoading] = useState(false)

    const checkErrors = () => {
        society.address = address
        let hasErrors = false
        if (society.siret?.toString().length !== 14) {
            toast.error('Veuillez renseigner un siret valide.')
            hasErrors = true
        }
        if (society.idEmetteur?.toString().length !== 9) {
            toast.error('Veuillez renseigner un numéro de PS valide.')
            hasErrors = true
        }
        if (!society.label) {
            toast.error('Veuillez renseigner le nom de votre entreprise.')
            hasErrors = true
        }
        if (society.phoneNumber?.length !== 14) {
            toast.error('Veuillez renseigner un numéro de téléphone valide.')
            hasErrors = true
        }
        if (!/\S+@\S+\.\S+/.test(society.mail)) {
            toast.error('Veuillez renseigner un mail valide.')
            hasErrors = true
        }
        if (!society.address.city) {
            toast.error('Veuillez renseigner une ville.')
            hasErrors = true
        }
        if (society.VATNumber && society.VATNumber?.length !== 13) {
            toast.error('Veuillez renseigner un numéro de TVA valide.')
            hasErrors = true
        }

        return hasErrors
    }

    const handleSubmit = () => {
        if (!checkErrors()) {
            delete address.id
            delete address['@id']
            society.address = address
            if (
                society.mail.length > 0 &&
                society.phoneNumber.length > 0 &&
                society.address.city.length > 0 &&
                society.label.length > 0 &&
                society.siret
            ) {
                localStorage.setItem('isAbleToInvoice', true)
            } else {
                localStorage.setItem('isAbleToInvoice', false)
            }
            setLoading(true)
            API.Society.put(society.id, society)
                .then((res) => {
                    setLoading(false)
                    toast.success('Informations mises à jour avec succès.')
                })
                .catch(() => {
                    toast.error('Veuillez contacter un administrateur.')
                })
        }
    }

    return (
        <div className="pt-5 text-lg lg:text-base">
            <div className="flex flex-col space-y-5 ">
                <div className="rounded-xl bg-white p-5 shadow-md lg:p-10 ">
                    <h1 className="text-xl font-bold">
                        Paramètres du compte 🏦{' '}
                    </h1>
                    <h2 className="font-light text-gray-600">
                        Informations de votre société
                    </h2>
                </div>
                <div className="flex flex-col space-y-5 rounded-xl bg-white p-5 shadow-md lg:p-10 ">
                    <FormInput
                        label="Nom"
                        type={'text'}
                        placeholder={'Nom'}
                        value={society.label}
                        onChange={(e) =>
                            dispatch({
                                type: action.SET_LABEL,
                                payload: e.target.value,
                            })
                        }
                    />
                    <FormInput
                        label="Téléphone"
                        type={'text'}
                        placeholder={'Téléphone'}
                        value={society.phoneNumber}
                        onChange={(e) =>
                            dispatch({
                                type: action.SET_PHONE_NUMBER,
                                payload: e.target.value,
                            })
                        }
                    />
                    <FormInput
                        label="Mail"
                        type={'email'}
                        placeholder={'Mail'}
                        value={society.mail}
                        onChange={(e) =>
                            dispatch({
                                type: action.SET_MAIL,
                                payload: e.target.value,
                            })
                        }
                    />
                    <FormInput
                        label="Siret"
                        type={'text'}
                        placeholder={'...'}
                        inputMode={'numeric'}
                        value={society.siret}
                        onChange={(e) =>
                            dispatch({
                                type: action.SET_SIRET,
                                payload: e.target.value,
                            })
                        }
                    />
                    <FormInput
                        label="N° TVA"
                        type={'text'}
                        placeholder={'N° TVA'}
                        inputMode={'text'}
                        value={society.VATNumber}
                        onChange={(e) =>
                            dispatch({
                                type: action.SET_VAT_NUMBER,
                                payload: e.target.value,
                            })
                        }
                    />
                    <FormInput
                        label="N° PS"
                        type={'text'}
                        placeholder={'N° PS'}
                        inputMode={'text'}
                        value={society.idEmetteur}
                        onChange={(e) =>
                            dispatch({
                                type: action.SET_ID_EMETTEUR,
                                payload: e.target.value,
                            })
                        }
                    />
                    <FormInput
                        label="Mdp ameli pro"
                        type={'text'}
                        placeholder={'Mdp ameli pro'}
                        infoLabel={'Connexion automatique pour PEC+'}
                        inputMode={'text'}
                        value={society.mdpEmetteur}
                        onChange={(e) =>
                            dispatch({
                                type: action.SET_MDP_EMETTEUR,
                                payload: e.target.value,
                            })
                        }
                    />
                    <div className={'ml-auto w-2/3 rounded-lg bg-blue-200 p-4'}>
                        En renseignant votre mot de passe Ameli Pro, vous
                        autorisez Heroad à se connecter automatiquement à PEC+
                        pour créer les sessions en votre nom.
                    </div>
                    <FormInput
                        label="Plan"
                        type={'text'}
                        infoLabel={'Contactez-nous pour changer de plan'}
                        placeholder={'Nom'}
                        value={society.plan.label + ' 🔒'}
                        disabled={true}
                        inputClassName={'bg-gray-100 font-semibold'}
                    />
                    <div className="flex  w-full flex-col items-center space-y-2 lg:flex-row lg:space-y-0">
                        <label className="flex w-full items-center justify-between font-semibold lg:w-1/3 ">
                            Adresse
                        </label>
                        <div className="w-full lg:w-2/3">
                            <AutoComplete
                                dispatch={addressDispatch}
                                address={address}
                            />
                        </div>
                    </div>
                    <div className="flex w-full flex-col items-center space-y-2 lg:flex-row lg:space-y-0">
                        <label className="flex w-full items-center justify-between font-semibold lg:w-1/3 ">
                            Factures et informations de paiement
                        </label>
                        <div className="w-full space-y-4 lg:w-2/3">
                            <div className={'rounded-lg bg-blue-200 p-4'}>
                                Merci d'utiliser votre adresse email de
                                facturation pour accéder à vos informations de
                                paiement et à votre historique de facturation.
                                Si vous avez oublié quelle adresse vous avez
                                utilisée, veuillez nous contacter par le chat.
                            </div>
                            <PrimaryButton
                                label={'Accéder à mes factures Heroad'}
                                title={'Accéder à mes factures Heroad'}
                                action={() =>
                                    window.open(
                                        'https://billing.stripe.com/p/login/aEU15Cfkx5l68VyaEE',
                                        '_blank'
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col  rounded-xl bg-white p-5 shadow-md lg:p-10 ">
                    <h1 className="font-bold">
                        Informations spécifiques à la facturation
                    </h1>
                    <h2 className="font-light text-gray-600">
                        Le RIB de votre société sera affiché en bas à gauche de
                        vos factures ansi que la note
                    </h2>
                    <div className="flex flex-col space-y-5 pt-5">
                        <FormInput
                            label="Titulaire du compte"
                            type={'text'}
                            placeholder={'Titulaire du compte'}
                            value={society.accountHolderName}
                            onChange={(e) =>
                                dispatch({
                                    type: action.SET_ACCOUNT_HOLDER_NAME,
                                    payload: e.target.value,
                                })
                            }
                        />
                        <FormInput
                            label="IBAN"
                            type={'text'}
                            placeholder={'IBAN'}
                            value={society.IBAN}
                            onChange={(e) =>
                                dispatch({
                                    type: action.SET_IBAN,
                                    payload: e.target.value,
                                })
                            }
                        />
                        <FormInput
                            label="BIC"
                            type={'text'}
                            placeholder={'BIC'}
                            value={society.BIC}
                            onChange={(e) =>
                                dispatch({
                                    type: action.SET_BIC,
                                    payload: e.target.value,
                                })
                            }
                        />
                        <FormTextArea
                            label="Note"
                            type={'text'}
                            infoLabel="Ne pas dépasser 6 lignes de textes afin d'éviter des problèmes de formatage"
                            placeholder={'Note'}
                            value={society.invoiceNote}
                            onChange={(e) =>
                                dispatch({
                                    type: action.SET_INVOICE_NOTE,
                                    payload: e.target.value,
                                })
                            }
                        />
                    </div>
                </div>
                <div className="flex w-full justify-between px-5 py-5 text-xl lg:text-base">
                    <SecondaryButton
                        label="Annuler"
                        title="Annuler"
                        action={() => history(-1)}
                    />
                    <PrimaryButton
                        label="Modifier"
                        title="Modifier"
                        loader={loading}
                        action={handleSubmit}
                    />
                </div>
            </div>
        </div>
    )
}
